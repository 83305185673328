import React from "react";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkPlaceHolder from "../LightDarkPlaceHolder";

const SocietyPlanOverViewPlaceHolder = () => {
  return (
    <LightDarkContainer
      colorLight="white"
      className="radius05  h100 p-3 d-flex flex-column justify-content-evenly gap-4"
    >
      <div className="alignH justify-content-between">
        <LightDarkPlaceHolder customClass="w20" height={12} />
        <LightDarkPlaceHolder customClass="w20" height={12} />
      </div>
      <LightDarkPlaceHolder customClass="w100" height={12} />
      <div className="alignH justify-content-evenly">
        <div className="w20 d-flex flex-column gap-2">
          <LightDarkPlaceHolder customClass="w100" height={12} />
          <LightDarkPlaceHolder customClass="w100" height={12} />
        </div>
        <div className="w20 d-flex flex-column gap-2">
          <LightDarkPlaceHolder customClass="w100" height={12} />
          <LightDarkPlaceHolder customClass="w100" height={12} />
        </div>
        <div className="w20 d-flex flex-column gap-2">
          <LightDarkPlaceHolder customClass="w100" height={12} />
          <LightDarkPlaceHolder customClass="w100" height={12} />
        </div>
        <div className="w20 d-flex flex-column gap-2">
          <LightDarkPlaceHolder customClass="w100" height={12} />
          <LightDarkPlaceHolder customClass="w100" height={12} />
        </div>
      </div>
      <div className="alignH justify-content-end gap-2">
        <LightDarkPlaceHolder customClass="w20" height={40} />
      </div>
    </LightDarkContainer>
  );
};

export default SocietyPlanOverViewPlaceHolder;
