import React from "react";
import { useSelector } from "react-redux";
import ServiceCard from "./ServiceCard";
import EmptyList from "../Common/EmptyList";

const ServicesList = () => {
  const { externalServices } = useSelector(
    (state) => state.externalServicesReducer
  );
  return (
    <div className="mt-4 alignH flex-column gap-3">
      {externalServices.length === 0 ? (
        <EmptyList
          text="Aucun service externe connecté"
          color="light-blue"
          bordered
          type="services"
        />
      ) : (
        externalServices.map((externalService) => (
          <ServiceCard
            externalService={externalService}
            key={externalService.id}
          />
        ))
      )}
    </div>
  );
};

export default ServicesList;
