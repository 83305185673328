import React from "react";
import UserThumbnail from "../../../../../../Common/UserThumbnail";
import moment from "moment";

const ReactUsersList = ({ emo }) => {
  return (
    <div className="reactUserList d-flex flex-column gap-1 radius10 border">
      {emo.users.map((localUser, index) => (
        <div key={index} className="alignH gap-2 p-2">
          <UserThumbnail
            userId={localUser.id}
            connected={localUser.attributes?.connected}
            lastConnected={localUser.attributes?.lastConnected}
            size={40}
            apiImg={
              localUser.attributes?.avatar?.data?.attributes?.image?.data
                ?.attributes?.url
            }
            img={`user${localUser.attributes?.attributes?.genre || "3"}`}
          />
          <div className="f12 text-start bold">
            <div>{`${localUser.attributes.firstName} ${localUser.attributes.lastName}`}</div>
            <div className="f10">
              {moment(localUser.emojiDate).format("DD/MM/YYYY à hh:mm")}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReactUsersList;
