import Api from "../../Api/Api";
import {
  READ_NOTIFICATION,
  UNREAD_NOTIFICATION,
} from "../Graphql/Mutations/notification";
import store from "../store/store";
import client from "./Client";
import { getAllTickets, getTicketItem, refetchTicket } from "./tickets";

export const fetchNotifications =
  (type, withLoading = true) =>
  async (dispatch, getState) => {
    withLoading && dispatch({ type: "NOTIFICATIONS_LOADING", payload: true });

    try {
      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      if (!orgId) {
        return;
      }
      const { data } = await Api.get(
        `/api/fetchNotificationsPerType?organisation=${orgId}&type=${type}`
      );
      dispatch({
        type: "FETCH_NOTIFICATIONS",
        payload: data?.data,
      });
    } catch {}
  };

export const readNotifications = (id) => async (dispatch) => {
  try {
    await client.mutate({
      mutation: READ_NOTIFICATION,
      variables: {
        id,
      },
    });
    dispatch(checkReadNotifications());
    dispatch({ type: "READ_NOTIF", payload: id });
  } catch (error) {
    //
  }
};

export const handleAllNotifications =
  (readNotread, type, ticketId) => async (dispatch) => {
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    dispatch({ type: "NOTIFICATIONS_LOADING", payload: true });
    try {
      await Api.post(`/api/handleAllNotifications?organisation=${orgId}`, {
        data: { readNotread, type, ticketId },
      });
      dispatch(checkReadNotifications());
      dispatch(fetchNotifications(type));
      if (type !== "calendrier" && !ticketId) {
        dispatch(getAllTickets(false));
      }
      if (ticketId) {
        dispatch(getTicketItem(ticketId, () => {}, false));
      }
      // dispatch(closeSideBar());
    } catch (error) {
      //
    }
  };

export const markAsNotReadNotification =
  (id, ticketId, type) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: UNREAD_NOTIFICATION,
        variables: {
          id,
        },
      });
      dispatch({ type: "UNREAD_NOTIF", payload: id });
      dispatch(fetchNotifications(type, false));
      dispatch(checkReadNotifications());
      if (ticketId) {
        dispatch(refetchTicket(ticketId));
      }
    } catch (error) {
      //
    }
  };
export const markTicketAsNotRead = (ticketId) => async (dispatch, getState) => {
  const user = getState().userReducer.user;
  const organisation = getState().organisationsReducer.selectedOrganisation;
  const societyId = getState().ticketsReducer.ticket.attributes.societe.data.id;

  if (!user || !organisation.id) return;
  try {
    await Api.post(`/api/markTicketNotRead?organisation=${organisation.id}`, {
      data: { ticketId: ticketId, societyId },
    });
    dispatch(checkReadNotifications());

    dispatch(refetchTicket(ticketId));
  } catch (error) {
    //
  }
};

export const checkReadNotifications = () => async (dispatch, getState) => {
  const organisationId =
    getState().organisationsReducer.selectedOrganisation.id;
  if (!organisationId) {
    return;
  }
  try {
    const { data } = await Api.get(
      `/api/checkNotifications?organisation=${organisationId}`
    );
    dispatch({ type: "CHECK_READ_NOTIFICATIONS_POINT", payload: data });
  } catch (error) {
    //
  }
};
