import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadFiles } from "../../REDUX/actions/files";
import { closeModal } from "../../REDUX/actions/modal";
import { getFolderItem } from "../../REDUX/actions/cloud";
import ColoredButton from "../Common/Buttons/ColoredButton";
import LighDarkMultiChoice from "../Common/LighDarkMultiChoice";
import LightDarkText from "../Common/LightDarkText";
import { CloseIcon } from "../Common/LightDarkSvg";
import LoadingButton from "../Common/LoadingButton";
import AttachementUploader from "../Common/AttachementUploader";
import LightDarkInput from "../Common/LightDarkInput";

const CloudImportForm = ({ folder, inFolder }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { temporarySelected, uploadLoading } = useSelector(
    (state) => state.filesReducer
  );
  const { user } = useSelector((state) => state.userReducer);
  const { foldersSelect } = useSelector((state) => state.cloudReducer);
  const { canShareTo } = useSelector((state) => state.sharedDataReducer);
  const [fileInputs, setFileInputs] = useState({
    name: "",
    dossier: inFolder
      ? [{ value: folder.id, label: folder.attributes.name }]
      : [],
    sharedUsers: [],
  });
  const handleUpload = () => {
    dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
          name: fileInputs?.name,
          folder: fileInputs?.dossier[0]?.value,
          sharedUsers: fileInputs?.sharedUsers?.map((user) => user?.value),
        },
        temporarySelected,
        true,
        true,
        user
      )
    ).then(() => {
      dispatch(
        getFolderItem(
          inFolder ? folder?.id : fileInputs?.dossier[0]?.value,
          false
        )
      );
    });
  };

  return (
    <div
      className={`${mode === "light" ? "bg-white" : "bg-dark-mode"}
        p-4 radius05`}
      style={{ minHeight: "510px" }}
    >
      <div
        className="d-flex justify-content-end mb-3"
        onClick={() => dispatch(closeModal())}
      >
        <CloseIcon />
      </div>
      <div className="px-2">
        <LightDarkText className="text-center custom-title-font-size bold mx-5">
          Importer un nouveau document
        </LightDarkText>
      </div>
      <div className="container" onSubmit={(e) => e.preventDefault()}>
        <div className="row my-5">
          {temporarySelected?.length < 2 && (
            <div className="col-12 mb-5">
              <LightDarkInput
                label="Nom du document"
                placeholder="Nouveau document"
                type="text"
                totalInputs={fileInputs}
                setValue={setFileInputs}
                champs="name"
              />
            </div>
          )}
          <div className="col-6">
            <LighDarkMultiChoice
              label="Dossier"
              data={foldersSelect}
              totalInputs={fileInputs}
              setValue={setFileInputs}
              multiple={false}
              champs="dossier"
              disabled={inFolder}
              hideArrow={inFolder}
              folderSelectInput={!inFolder}
            />
          </div>
          <div className="col-6">
            <LighDarkMultiChoice
              label="Partager avec"
              data={
                !inFolder
                  ? canShareTo.filter((el) => el.value !== user.id)
                  : canShareTo.filter(
                      (el) =>
                        el.value !== user.id &&
                        folder?.attributes?.sharedUsers?.data?.every(
                          (user) => user.id !== el.value
                        )
                    )
              }
              totalInputs={fileInputs}
              setValue={setFileInputs}
              champs="sharedUsers"
              placeholder="Sélectionner des utilisateurs"
            />
          </div>
        </div>
        <div className="row my-5">
          <div>
            <AttachementUploader label="Fichier(s)" required={true} />
          </div>
        </div>
        <div className={`row mt-5`}>
          <LightDarkText
            className={"text-start bold custom-ticket-font-size mb-2"}
          >
            <span className="text-red">*</span>Champs obligatoires
          </LightDarkText>
        </div>
        <div className="w-100 mt-5 alignC ">
          <div className="col-4 margin-auto">
            {uploadLoading ? (
              <LoadingButton />
            ) : (
              <ColoredButton
                disabled={uploadLoading || !temporarySelected.length}
                textButton="Importer"
                color="light-blue"
                callback={() => handleUpload()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudImportForm;
