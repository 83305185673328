import React from "react";
import Thumbnail from "../../Common/Thumbnail";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";
import oneDriveLogo from "../../../Images/accessAll.svg";
const SocietyStack = ({ societes }) => {
  return societes.length === 0 ? (
    <LightDarkText className="custom-ticket-font-size py-1 ms-3 xbold alignH gap-1">
      <img src={oneDriveLogo} alt={"Agilix"} style={{ marginLeft: "-15px" }} />
      <div> Accès à toutes les sociétés</div>
    </LightDarkText>
  ) : (
    <div className="alignH" style={{ marginLeft: "15px" }}>
      {societes.slice(0, 4).map((societe) => (
        <OverlayTrigger
          key={societe.id}
          placement="bottom"
          overlay={
            <Tooltip style={{ zIndex: 10000 }}>
              <LightDarkText className="custom-ticket-font-size py-1 text-white">
                {societe.attributes.name}
              </LightDarkText>
            </Tooltip>
          }
        >
          <div className="pointer avatars__item border border-1 border-blue radius50">
            <Thumbnail
              apiImg={
                societe?.attributes?.avatar?.data?.attributes?.image?.data
                  ?.attributes?.url
              }
              img="society"
              size={32}
              rounded
            />
          </div>
        </OverlayTrigger>
      ))}
      <LightDarkText className="custom-ticket-font-size py-1 ms-3 bold">
        {societes.length > 4 ? `+ ${societes.length - 4} autres` : ``}
      </LightDarkText>
    </div>
  );
};

export default SocietyStack;
