import {
  ADD_QUESTION_SUCCESS,
  DELETE_QUESTIONS_SUCCESS,
  GET_QUESTIONS_SUCCESS,
  UPDATE_QUESTIONS_SUCCESS,
} from "../ActionsType/formQuestionTypes";
import { DELETE_CONDITION } from "../Graphql/Mutations/formConditions";
import {
  CREATE_ANSWER,
  CREATE_QUESTION,
  DELETE_ANSWER,
  DELETE_QUESTION,
  UPDATE_ANSWER,
  UPDATE_QUESTION,
} from "../Graphql/Mutations/formQuestions";
import {
  GET_QUESTIONS,
  GET_QUESTION_BY_ID,
} from "../Graphql/Queries/FormQuestion";
import client from "./Client";
import { closeModal } from "./modal";
import { handleError, handleInfo } from "./toastMessage";
import store from "../store/store";
import { UPLOAD } from "../Graphql/Mutations/files";

export const createNewQuestion = (question, answers) => async (dispatch) => {
  try {
    let media;

    if (question?.file?.length > 0) {
      media = await client.mutate({
        mutation: UPLOAD,
        variables: {
          file: question.file[0],
        },
      });
    }
    const fileId = media?.data?.upload?.data?.id;
    let answerResponses = [];
    const { data } = await client.mutate({
      mutation: CREATE_QUESTION,
      variables: {
        name: question.name,
        position: Number(question.position),
        input_type: question.input_type ? question.input_type : question.type,
        col: Number(question.col),
        legend: question.legend,
        is_required: question.is_required,
        label_class: question.label_class,
        category: question.category,
        link: question.link,
        image: fileId,
      },
    });
    if (answers) {
      const values = Object.values(answers);
      for (let index = 0; index < values.length; index++) {
        const value = values[index];
        const answerData = await client.mutate({
          mutation: CREATE_ANSWER,
          variables: {
            question: data.createFormQuestion.data.id,
            answer: value,
          },
        });
        answerResponses.push(answerData?.data?.createFormAnswer.data);
      }
    }

    dispatch({
      type: ADD_QUESTION_SUCCESS,
      payload: {
        question: data.createFormQuestion.data,
        answer: answerResponses,
      },
    });
    dispatch(closeModal());
  } catch (error) {}
};

export const GetAllQuestions = () => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: GET_QUESTIONS,
    });
    dispatch({
      type: GET_QUESTIONS_SUCCESS,
      payload: data.formQuestions.data,
    });
  } catch (error) {}
};

export const deleteQuestion = (question) => async (dispatch) => {
  try {
    if (question?.attributes?.answers?.data?.length > 0) {
      const answers = question?.attributes?.answers?.data;
      for (let ans in answers) {
        await client.mutate({
          mutation: DELETE_ANSWER,
          variables: {
            id: answers[ans].id,
          },
        });
      }
    }
    if (question?.attributes?.conditions?.data?.length > 0) {
      const conditions = question?.attributes?.conditions?.data;
      for (let cond in conditions) {
        await client.mutate({
          mutation: DELETE_CONDITION,
          variables: {
            id: conditions[cond].id,
          },
        });
      }
    }

    await client.mutate({
      mutation: DELETE_QUESTION,
      variables: {
        id: question.id,
      },
    });
    dispatch({
      type: DELETE_QUESTIONS_SUCCESS,
      payload: question.id,
    });
    const selectedCat = store.getState().formCategoriesReducer.selectedCategory;
    const listQuestions = selectedCat.attributes.questions.data;
    const updatedQuestions = listQuestions
      .slice()
      .sort((a, b) => a.attributes?.position - b.attributes?.position)
      .map((question, index) => ({
        id: question?.id,
        position: index + 1,
      }));
    for (const newItem of updatedQuestions) {
      dispatch(updateQuestionPosition(newItem.id, newItem.position));
    }
  } catch (error) {}
};

export const updateQuestion =
  (question, formData, answers) => async (dispatch) => {
    try {
      const arrayToUpdate = question?.attributes?.answers?.data;
      const idsToDelete = [];
      let media;

      if (formData?.file?.length > 0) {
        media = await client.mutate({
          mutation: UPLOAD,
          variables: {
            file: formData?.file[0],
          },
        });
      }
      const fileId = media?.data?.upload?.data?.id;

      if (answers) {
        await Promise.all(
          Object.keys(answers).map(async (key) => {
            const index = parseInt(key.replace("answer-", ""));
            const newValue = answers[key];
            if (index >= 0 && index < arrayToUpdate.length) {
              const existingItem = arrayToUpdate[index];
              const existingValue = existingItem.attributes.answer;

              if (existingValue !== newValue) {
                const answer = newValue;
                const response = await updateAnswer(existingItem.id, answer);
                return response;
              }
            } else {
              const answer = answers[key];
              const response = await addAnswer(question.id, answer);
              return response;
            }
          })
        );
        arrayToUpdate.forEach((item, index) => {
          if (!answers[`answer-${index}`]) {
            idsToDelete.push(item.id);
          }
        });
        const deletePromises = idsToDelete.map((id) => deleteAnswer(id));
        await Promise.all(deletePromises);
      }

      const { data } = await client.mutate({
        mutation: UPDATE_QUESTION,
        variables: {
          id: question.id,
          position: parseInt(formData.position) || null,
          name: formData.name,
          input_type:
            formData.type === "question" ? formData.input_type : formData.type,
          col: Number(formData.col),
          legend: formData.legend,
          is_required: formData.is_required,
          label_class: formData.label_class,
          category: formData.category,
          link: formData.link,
          image: fileId,
        },
      });
      dispatch({
        type: UPDATE_QUESTIONS_SUCCESS,
        payload: data.updateFormQuestion.data,
      });
      dispatch(closeModal());
      dispatch(handleInfo("Mise à jour avec succès", 1, "dark-green"));
    } catch (error) {}
  };

const addAnswer = async (questionId, answer) => {
  const response = await client.mutate({
    mutation: CREATE_ANSWER,
    variables: {
      question: questionId,
      answer: answer,
    },
  });
  return response;
};

const updateAnswer = async (answerId, answer) => {
  const response = await client.mutate({
    mutation: UPDATE_ANSWER,
    variables: {
      id: answerId,
      answer: answer,
    },
  });
  return response;
};

const deleteAnswer = async (answerId) => {
  const response = await client.mutate({
    mutation: DELETE_ANSWER,
    variables: {
      id: answerId,
    },
  });
  return response;
};

export const getQuestionById = (id) => async () => {
  try {
    await client.query({
      query: GET_QUESTION_BY_ID(id),
    });
  } catch (error) {}
};

export const updateQuestionPosition = (id, position) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_QUESTION,
      variables: {
        id: id,
        position: Number(position),
      },
    });

    dispatch({
      type: UPDATE_QUESTIONS_SUCCESS,
      payload: data.updateFormQuestion.data,
    });
    dispatch(handleInfo("Mise à jour avec succès", 1, "dark-green"));
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const duplicateQuestion = (question) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_QUESTION,
      variables: {
        name: question.attributes?.name,
        position: question.attributes.position,
        input_type: question.attributes.input_type,
        col: question.attributes.col,
        legend: question.attributes.legend,
        is_required: question.attributes.is_required,
        label_class: question.attributes.label_class,
        category: question.attributes.category.data.id,
        link: question.attributes.link,
      },
    });
    const answers = question.attributes.answers.data.map(
      (el) => el.attributes.answer
    );
    let answerResponses = [];
    if (answers.length !== 0) {
      const values = Object.values(answers);
      for (let index = 0; index < values.length; index++) {
        const value = values[index];
        const answerData = await client.mutate({
          mutation: CREATE_ANSWER,
          variables: {
            question: data.createFormQuestion.data.id,
            answer: value,
          },
        });
        answerResponses.push(answerData?.data?.createFormAnswer.data);
      }
    }
    dispatch({
      type: ADD_QUESTION_SUCCESS,
      payload: {
        question: data.createFormQuestion.data,
        answer: answerResponses,
      },
    });
    dispatch(handleInfo("Question ajouté avec succès", 1, "dark-green"));
  } catch (error) {}
};
