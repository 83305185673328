import React from "react";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import LightDarkInput from "../../Common/LightDarkInput";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";
import { useDispatch, useSelector } from "react-redux";
import LightDarkText from "../../Common/LightDarkText";
import { useEffect } from "react";
import { getDevices } from "../../../REDUX/actions/sharedData";
import { generateSecurePassword } from "../../../Utils/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const UserSettingsForm = ({
  clientInputs,
  setclientInputs,
  client,
  isNew,
  invitation,
  isOp,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const { user } = useSelector((state) => state.userReducer);
  const { devicesPerPrestations, prestations, societes, departements } =
    useSelector((state) => state.sharedDataReducer);
  const { ticketHandleLogic } = useSelector(
    (state) => state.organisationsReducer
  );
  useEffect(() => {
    dispatch(getDevices(clientInputs?.prestations));
  }, [clientInputs?.prestations, dispatch, client]);

  return (
    <>
      <div className={` row `}>
        {user.id !== client?.id && clientInputs.role[0]?.value !== 1 && (
          <div className="">
            <LighDarkMultiChoice
              label="Société(s)"
              data={societes}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs={isOp ? "SocietiesUnderResponsability" : "workIn"}
              userSocietySelectInput
            />
          </div>
        )}
      </div>
      {ticketHandleLogic === 2 ? (
        user.id !== client?.id && clientInputs.role[0]?.value !== 1 ? (
          <div className={`row mt-5`}>
            <div className={`col-12 `}>
              <LighDarkMultiChoice
                label={"Département(s)"}
                data={departements}
                totalInputs={clientInputs}
                multiple={true}
                setValue={setclientInputs}
                champs="departements"
                newField="departements"
                canSelectAll
              />
            </div>
          </div>
        ) : null
      ) : user.id !== client?.id && clientInputs.role[0]?.value === 2 ? (
        <div className={`row mt-5`}>
          <div className={`col-6`}>
            <LighDarkMultiChoice
              label={"Compétence(s)"}
              data={prestations}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs="prestations"
              newField="prestation"
              canSelectAll
            />
          </div>
          <div className={`col-6`}>
            <LighDarkMultiChoice
              label={"Spécialité(s)"}
              data={isNew ? [] : devicesPerPrestations}
              totalInputs={clientInputs}
              setValue={setclientInputs}
              champs="devices"
              newField="device"
              canSelectAll
            />
          </div>
        </div>
      ) : null}

      <div className={`mt-5 row `}>
        {!invitation ? (
          <>
            <div className={` col-6 mb-5`}>
              <LightDarkInput
                placeholder={"Mot de passe"}
                label={"Mot de passe"}
                totalInputs={clientInputs}
                setValue={setclientInputs}
                champs="password"
                required={isNew}
                passwordInput
                type="password"
              />
              <div className="d-flex align-items-center justify-content-between mt-2 gap-3">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip style={{ zIndex: 9999 }}>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        Générer un mot de passe sécurisé de 12 caractères
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div
                    className={`f12 pointer ${
                      mode === "light" ? "text-blue-grey" : "text-white"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      const generatedPassword = generateSecurePassword();
                      setclientInputs({
                        ...clientInputs,
                        password: generatedPassword,
                        password2: generatedPassword,
                      });
                    }}
                  >
                    <i className="bi bi-magic me-1"></i>
                    Générer un mot de passe
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip style={{ zIndex: 9999 }}>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        Copier le mot de passe
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div
                    className={`f12 pointer ${
                      mode === "light" ? "text-blue-grey" : "text-white"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (clientInputs.password) {
                        navigator.clipboard.writeText(clientInputs.password);
                      }
                    }}
                  >
                    <i className="bi bi-clipboard me-1"></i>
                    Copier
                  </div>
                </OverlayTrigger>
              </div>
              {clientInputs.password && clientInputs.password.length < 6 && (
                <span className="field-error f11 position-absolute mt-1 text-red">
                  Le mot de passe doit comporter au moins 6 caractères
                </span>
              )}
            </div>
            <div className={`col-6 mb-5`}>
              <LightDarkInput
                placeholder={"Confirmation du mot de passe"}
                label={"Confirmation du mot de passe"}
                totalInputs={clientInputs}
                setValue={setclientInputs}
                champs="password2"
                required={isNew}
                passwordInput
                type="password"
              />
              {clientInputs.password2 &&
                clientInputs.password2 !== clientInputs.password && (
                  <span className="field-error f11 position-absolute mt-1 text-red">
                    Les mots de passe ne correspondent pas
                  </span>
                )}
            </div>
          </>
        ) : null}
        <div className={`row mt-5 ${!isNew && "mt-5"}`}>
          <LightDarkText
            className={"text-start bold custom-ticket-font-size mb-2"}
          >
            <span className="text-red">*</span>Champs obligatoires
          </LightDarkText>
        </div>
      </div>
    </>
  );
};

export default UserSettingsForm;
