import { gql } from "@apollo/client";
import { INTERVENTION_ITEM_ATTRIBUTES } from "../Attributes/Intervention";
import { USER_ORG_ATTRIBUTES } from "../Attributes/UserOrg";
import { userAttributes } from "../general/userFields";

export const GET_INTERVENTION_ITEM = gql`
  query ($id: ID!, $orgId: ID!) {
    intervention(id: $id) {
      data {
        id
        attributes {
          creator {
            data {
              id 
              attributes {
                user_org_permissions (
                  filters: {
                    organisation: { id: { eq: $orgId} }  
                  }
                ) 
                {
                  data {
                    id
                    attributes {
                      ${USER_ORG_ATTRIBUTES}
                    }
                  }
                }
              }
            }
          }
          actions {
            data {
              id
              attributes {
                by {
                  data {
                    id
                    attributes {
                      ${userAttributes}
                      user_org_permissions (
                        filters: {
                          organisation: { id: { eq: $orgId} }  
                        }
                      ) 
                      {
                        data {
                          id
                          attributes {
                            ${USER_ORG_ATTRIBUTES}
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ${INTERVENTION_ITEM_ATTRIBUTES}
        }
      }
    }
  }
`;
