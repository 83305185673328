import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";
import { imageLoader } from "../../../Utils/utils";
import { useSelector } from "react-redux";

const TicketPrestation = ({ ticket, notRead }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const iconUrl =
    ticket?.attributes?.prestation?.data?.attributes?.logo?.data?.attributes
      ?.icon?.data?.attributes?.url;

  return (
    <div className="alignH custom-ticket-font-size  bold text-start text-ellipsis">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id={`tooltip-${ticket?.id}`}>
            <LightDarkText className="custom-ticket-font-size  py-1 text-white text-ellipsis">
              {ticket?.attributes.stringPrestation}
            </LightDarkText>
          </Tooltip>
        }
      >
        <div className="d-flex alignH gap-1 text-ellipsis">
          {iconUrl && <img src={imageLoader(iconUrl)} />}
          <LightDarkText
            className={`custom-ticket-font-size  bold text-start text-ellipsis ${
              notRead && "xbold "
            }`}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {ticket.attributes.stringPrestation}
          </LightDarkText>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default TicketPrestation;
