import React from "react";

import StatusButton from "../../Common/Buttons/StatusButton";
import InfoLabel from "../TicketCommonComponents/InfoLabel";
import LightDarkPlaceHolder from "../../PlaceHolders/LightDarkPlaceHolder";

import useWindowWidth from "../../../Hooks/useWindowWidth";

const TicketInfoBlock = ({ ticketInfosData, isLoading = true }) => {
  const screenWidth = useWindowWidth();

  return (
    <div className="d-flex mt-3 gap-3">
      <div className="w50 d-flex flex-column gap-3">
        {ticketInfosData?.generalInfos?.map((info, index) =>
          isLoading ? (
            <LightDarkPlaceHolder key={index} customClass="py-1 mb-2" />
          ) : (
            info?.isVisible && (
              <InfoLabel
                key={index}
                label={info?.label}
                value={info?.value}
                isLink={info?.isLink}
                icon={info?.icon}
              />
            )
          )
        )}
      </div>
      <div className="w50 d-flex flex-column gap-3">
        {ticketInfosData?.stateInfos?.map((info, index) =>
          info?.isVisible && isLoading ? (
            <LightDarkPlaceHolder key={index} customClass="h40" />
          ) : (
            info?.isVisible && (
              <StatusButton
                key={index}
                height={screenWidth < 1200 ? "30px" : "40px"}
                type={info?.type}
                text={info?.text}
                background={info?.background}
              />
            )
          )
        )}
      </div>
    </div>
  );
};

export default TicketInfoBlock;
