import React from "react";
import LightDarkPlaceHolder from "../../../../PlaceHolders/LightDarkPlaceHolder";

const InterviewPlaceHolder = () => {
  return (
    <div className="p-4 d-flex flex-column gap-4 scrollable">
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
      <div className="d-flex flex-column gap-2">
        <LightDarkPlaceHolder height={20} width={"80%"} />
        <LightDarkPlaceHolder height={20} width={"50%"} />
      </div>
    </div>
  );
};

export default InterviewPlaceHolder;
