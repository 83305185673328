import React from "react";
import { HandleModeIcons, LogoutIcon } from "../../Common/LightDarkSvg";
import { useDispatch, useSelector } from "react-redux";
import { logout, testDisconnect } from "../../../REDUX/actions/user";
import { changeDisplayMode } from "../../../REDUX/actions/general";
import { useNavigate } from "react-router-dom";

const BottomDrawer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );
  const handleDayNight = () => {
    if (mode === "light") {
      dispatch(changeDisplayMode("dark"));
      localStorage.setItem("mode", "dark");
    } else {
      dispatch(changeDisplayMode("light"));
      localStorage.setItem("mode", "light");
    }
  };
  return (
    <div className="bottomDrawer">
      {/* <button onClick={() => dispatch(testDisconnect())}>test</button> */}
      <div
        className="d-flex align-items-start justify-content-center gap-3 mb-3 pointer t-02"
        onClick={handleDayNight}
      >
        <HandleModeIcons />
      </div>
      <div
        className="pointer logoutIcon t-02 align-self-center"
        onClick={() => {
          dispatch(logout());
          navigate(organisationUrl);
        }}
      >
        <LogoutIcon />
      </div>
    </div>
  );
};

export default BottomDrawer;
