import React from "react";

import Simulateur from "./Simulateur";
const SimulateurView = () => {
  return (
    <div className="container-fluid">
      {/* <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className={`${"col-12"} h100`}>
          <ListHeader showSocityFilter={false}>
            <SearchInput placeholder="Search..." />
          </ListHeader>
        </div>
      </div> */}
      <Simulateur isForm={false} isUpdate={false} />
    </div>
  );
};

export default SimulateurView;
