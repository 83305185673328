import { userAttributes } from "../general/userFields";

export const INTERVENTION_ATTRIBUTES = `
title
statut
dateDebut
duration
description
hors_forfait
createdAt
oldCreatedAt
responsibles {
  data {
    id
    attributes {
      ${userAttributes}
    }
  }
}
societe {
  data {
    id
    attributes {
      name
        avatar {
          data {
            id
            attributes {
              image {
                data {
                  id 
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
relatedMessages {
  data {
    id
  }
}
fichiers(pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
       intervention {
            data {
              id
            }
          }
      file {
        data {
          id
          attributes {
            url
            name
            mime
          }
        }
      }
    }
  }
}
ticket {
  data {
    id
    attributes {
      name
    }
  }
}
`;

export const INTERVENTION_ITEM_ATTRIBUTES = `
title
statut
duration
createdAt
hors_forfait
description
oldCreatedAt
societe {
  data {
    id
    attributes {
      siteWeb
      name
      createdAt
      avatar {
        data {
          id
          attributes {
            image {
              data {
                id 
                attributes {
                  url
                }
              }
            }
          }
        }
  }
    }
  }
}
responsibles {
  data {
    id
    attributes {
      ${userAttributes}
    }
  }
}
creator {
  data {
    id
    attributes {
      ${userAttributes}
      role {
        data {
          id
          attributes {
            name
          }
        }
      }
      poste {
        data {
          id
          attributes {
            name
          }
        }
      }
      avatar {
        data {
          id
          attributes {
            image {
              data {
                id 
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}
ticket {
  data {
    id
    attributes {
      name
      createdAt
      statut
      priority
      additionalFields
      creator {
        data {
          id
          attributes {
            ${userAttributes}
          }
        }
      }
      device {
        data {
          id
          attributes {
            name
            logo {
              data {
                id
                attributes {
                  name
                  icon {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
            deviceIcon {
              data {
                id
                attributes {
                  url
                  name
            
                }
              }
            }
          }
        }
      }
      type {
        data {
          id
          attributes {
            name
          }
        }
      }
      departements(pagination: {page: 1, pageSize: 1000}) {
        data {
          id
          attributes {
            name
            code
          }
        }
      }
      prestation {
        data {
          id
          attributes {
            name
          }
        }
      }
      societe{
        data {
          id 
          attributes {
            name
            siteWeb
          }
        }
      }
    }
  }
}
relatedMessages {
  data {
    id
  }
}
fichiers(pagination: {page: 1, pageSize: 100}) {
  data {
    id
    attributes {
      createdAt
      intervention {
        data {
          id
        }
      }
      file {
        data {
          id
          attributes {
            url
            name
            mime
          }
        }
      }
    }
  }
}
actions {
  data {
    id
    attributes {
      createdAt
      title
      motif
      by {
        data {
          id
          attributes {
            ${userAttributes}
            avatar {
              data {
                id
                attributes {
                  image {
                    data {
                      id 
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
            role {
              data {
                id
                attributes {
                  name
                }
              }
            }
            poste {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
