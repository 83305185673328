import React, { useEffect } from "react";
import FaqAttachementFiles from "../../Components/Faqs/FaqAttachement/FaqAttachementFiles";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../REDUX/actions/modal";
import FaqForm from "../../Components/Faqs/FaqForm/FaqForm";
import { EditIcon } from "../../Components/Common/LightDarkSvg";
import { fetchFaqById } from "../../REDUX/actions/faq";

const FaqItemView = ({ faqId }) => {
  const dispatch = useDispatch();
  const { isSupervisor } = useSelector((state) => state.roleReducer);
  const { faqsCategories, faq } = useSelector((state) => state.faqReducer);

  useEffect(() => {
    dispatch(fetchFaqById(faqId));
  }, [faqId, dispatch]);

  return (
    <div className="p-5">
      <div className="d-flex justify-content-between align-items-start">
        <div className="flex-grow-1">
          <h5
            className="f22 fw-bold text-dark-blue text-start"
            dangerouslySetInnerHTML={{ __html: faq?.attributes?.title }}
          ></h5>
          <p
            className="f16 text-dark-blue regular text-start"
            dangerouslySetInnerHTML={{
              __html: faq?.attributes?.content,
            }}
          ></p>
        </div>
        {isSupervisor && (
          <div
            className="cursor-pointer ms-3"
            onClick={() =>
              dispatch(
                openModal({
                  type: "small",
                  content: (
                    <FaqForm
                      faq={faq}
                      categories={faqsCategories.map((el) => ({
                        value: el.id,
                        label: el.attributes.name,
                      }))}
                    />
                  ),
                })
              )
            }
          >
            <EditIcon size={20} />
          </div>
        )}
      </div>
      <FaqAttachementFiles message={faq} />
    </div>
  );
};

export default FaqItemView;
