import { userAttributes } from "../general/userFields";
import { ATTRIBUTES_PLAN } from "./Plan";
import { USER_ORG_ATTRIBUTES } from "./UserOrg";

export const ATTRIBUTES_EQUIPE = `
${userAttributes}
role {
  data {
    id
    attributes {
      name
    }
  }
}
poste {
  data {
    id
    attributes {
      name
    }
  }
}
devices (pagination: { page: 1, pageSize: 100 } ) {
  data {
    id
    attributes {
      name
    }
  }
}
prestations {
  data {
    id
    attributes {
      name
    }
  }
}
workIn {
  data {
    id
    attributes {
      name
    }
  }
}
SocietiesUnderResponsability {
  data {
    id
    attributes {
      name
    }
  }
}
avatar {
  data {
  id
  attributes {
    image {
      data {
        id 
        attributes {
          url
        }
      }
    }
  }
  }
}
`;

export const ATTRIBUTES_ALL_SOCIETIES = `
  name
  adress
  city
  zipCode
  phoneNumber
  siteWeb
  createdAt
  email
  emailFacture
  monthlyPlan
  monthlyQuota
  archived
  siret
  tickets(sort: "createdAt:desc") {
    data {
      id 
      attributes {
        createdAt
        name
      }
    }
  }
  avatar {
    data {
      id
      attributes {
        image {
          data {
            id 
            attributes {
              url
            }
          }
        }
      }
    }
  }
  workInOrg {
    data {
      id
      attributes {
        ${USER_ORG_ATTRIBUTES}
        user {
          data {
            id
            attributes {
              ${ATTRIBUTES_EQUIPE}
            }
          }
        }
      }
    }
  }
  responsableOperators {
    data {
      id
      attributes {
        ${ATTRIBUTES_EQUIPE}
      }
    }
  }
plans(sort:"date:desc", pagination: {page: 1, pageSize: 4}) {
  data {
    id
    attributes {
      ${ATTRIBUTES_PLAN}
    }
  }
}
`;

export const ATTRIBUTES_ITEM_SOCIETY = `name
adress
city
zipCode
phoneNumber
siteWeb
email
emailFacture
monthlyPlan
monthlyQuota
archived
siret
  tickets(sort: "createdAt:desc") {
    data {
      id 
      attributes {
        createdAt
        name
      }
    }
  }
avatar {
  data {
  id
  attributes {
    image {
      data {
        id 
        attributes {
          url
        }
      }
    }
  }
  }
}
plans(sort:"date:desc", pagination: {page: 1, pageSize: 4}) {
  data {
    id
    attributes {
      ${ATTRIBUTES_PLAN}
    }
  }
}
`;
