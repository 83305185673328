import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";
import { useDispatch, useSelector } from "react-redux";
import { selectorganisation } from "../../../REDUX/actions/organisations";
import Thumbnail from "../../Common/Thumbnail";
import { useNavigate } from "react-router-dom";
import { NotificationNew, RedCircle } from "../../Common/LightDarkSvg";
import OrganisationNotification from "./OrganisationNotification";

const OrganisationItem = ({ organisation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );

  const handleChangeOrganisation = () => {
    if (selectedOrganisation.id === organisation.id) {
      return;
    }
    navigate(`${organisation.attributes.url}`);
    dispatch(selectorganisation(organisation.id));
  };
  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip className="organisationItemTooltip">
          <LightDarkText className="custom-font-size py-1 text-white">
            {organisation.attributes.name}
          </LightDarkText>
        </Tooltip>
      }
    >
      <div
        className={`organisationIcon position-relative border border-2 pointer alignC my-2 t-02 ${
          selectedOrganisation.id === organisation.id ? "border-light-blue" : ""
        }`}
        onClick={handleChangeOrganisation}
      >
        {organisation.attributes.notifications.data.attributes.count &&
        selectedOrganisation.id !== organisation.id ? (
          <OrganisationNotification
            length={organisation.attributes.notifications.data.attributes.count}
          />
        ) : null}
        <Thumbnail
          apiImg={organisation.attributes.logo.data?.attributes?.url}
          rounded
          size={42}
        />
      </div>
    </OverlayTrigger>
  );
};

export default OrganisationItem;
