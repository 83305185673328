import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";
import { CheckIcon } from "../../../../../Common/Icons/CheckIcon";
import { overrideStrings } from "../../../../../Common/LightDarkMultiChoiceOverride";
import InterviewSubmitButton from "./InterviewSubmitButton";

const InterviewSelectInput = ({
  question,
  handleSubmit,
  value,
  setValue,
  step,
  setStep,
  disabled,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const [requirementMet, setRequirementMet] = useState(false);
  const [optionWarning, setOptionWarning] = useState("");
  const [optionWarningColor, setOptionWarningColor] = useState("");
  const [noMoreModifications, setNoMoreModifications] = useState(false);

  useEffect(() => {
    if (question.attributes.required) {
      if (value.length) {
        setRequirementMet(true);
      } else {
        setRequirementMet(false);
      }
    } else {
      setRequirementMet(true);
    }
  }, [value]);
  const handleChange = (e) => {
    if (e.length) {
      if (question.attributes.type === "multiSelect") {
        setValue(e);
      } else {
        setValue([e[e.length - 1]]);
        const selectedOption = question.attributes.options.data.find(
          (el) => +el.id === +e[e.length - 1].value
        );

        setOptionWarning(selectedOption.attributes.actionWarn);
        setOptionWarningColor(selectedOption.attributes.warnColor);
        setNoMoreModifications(selectedOption.attributes.noMoreModifications);
      }
    } else {
      setValue(e);
    }
  };

  return (
    <div className="mt-2">
      <div className="alignH w100 gap-2">
        <div
          className="flex-1"
          onClick={() =>
            step !== question.attributes.order && !disabled
              ? setStep(question.attributes.order)
              : {}
          }
        >
          <MultiSelect
            options={question.attributes.options.data.map((el) => {
              return { value: el.id, label: el.attributes.value };
            })}
            disabled={disabled}
            disableSearch
            ClearSelectedIcon={null}
            closeOnChangedValue={question.attributes.type !== "multiSelect"}
            hasSelectAll={false}
            value={value}
            onChange={handleChange}
            className={`${
              mode === "light" ? "multichoiceLight" : "multichocieDark"
            } radius10 h40 multichoiceContainer`}
            ItemRenderer={(item) => (
              <SelectItem
                checked={item.checked}
                option={item.option}
                onClick={item.onClick}
                question={question}
              />
            )}
            overrideStrings={overrideStrings(
              value.length,
              question.attributes.options.data?.length,
              question.attributes.placeholder
            )}
          />
        </div>
        {step === question.attributes.order && (
          <InterviewSubmitButton
            matchRegex={true}
            requirementMet={requirementMet}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
      <div className={`mt-1 text-${optionWarningColor || "red"}`}>
        {optionWarning}
      </div>
      {noMoreModifications && (
        <div className={`mt-1 bold text-${optionWarningColor || "red"}`}>
          Vous ne pouvez plus modifier vos réponses
        </div>
      )}
    </div>
  );
};
const SelectItem = ({ checked, option, onClick, question }) => {
  return (
    <div className={`item-renderer position-relative `}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={true}
        tabIndex={-1}
        hidden
      />
      <span
        className={`select-checkbox d-flex align-items-center justify-content-center radius05 d-inline-block me-2 ${
          checked ? "checked" : ""
        } ${question.attributes.type === "radio" ? "single" : ""}
  `}
      >
        <CheckIcon />
      </span>
      <span>{option?.label}</span>
    </div>
  );
};
export default InterviewSelectInput;
