import { gql } from "@apollo/client";
import { FORM_CONDITION_ATTRIBUTES } from "../Attributes/formCondition";

export const CREATE_CONDITION = gql`
  mutation ($question:ID!,$condition:ID!,$answer:String) {
    createFormCondition(data: {answer:$answer,question:$question,condition:$condition }) {
      data {
        id
        attributes {
          ${FORM_CONDITION_ATTRIBUTES}
        }
      }
    }
  }
`;

export const DELETE_CONDITION = gql`
  mutation ($id: ID!) {
    deleteFormCondition(id: $id) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_CONDITION = gql`
  mutation ($id:ID!,$answer:String,$condition:ID) {
    updateFormCondition(id:$id,data: {condition:$condition, answer:$answer }) {
      data {
        id
        attributes {
          ${FORM_CONDITION_ATTRIBUTES}
        }
      }
    }
  }
`;
