import React from "react";
import { useSelector } from "react-redux";

import CustomTooltip from "./CustomTooltip";
import LightDarkText from "./LightDarkText";

const MoreInfo = ({ text, right = false }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <LightDarkText
      className={"position-relative customtooltip pointer"}
      color={mode === "light" ? "text-blue-grey" : "text-light-text"}
    >
      <i className="bi ms-2 text-light-text pointer bi-question-circle-fill"></i>
      <CustomTooltip right={right}>
        <div className="custom-ticket-font-size p-2 w-100">{text}</div>
      </CustomTooltip>
    </LightDarkText>
  );
};

export default MoreInfo;
