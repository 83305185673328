import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import LightDarkText from "../Common/LightDarkText";
import LightDarkContainer from "../Common/LightDarkContainer";
import moment from "moment";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
);

const SimpleLineChart = ({ color, title, type, stats, withHour }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const [currStats, setCurrStats] = useState();
  const [labels, setLabels] = useState();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    gridLines: {
      display: false,
      drawOnChartArea: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#86909c80",
          font: {
            weight: "regular",
            size: 10,
          },
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: true,
          color: "#86909c20",
          borderColor: "transparent",
        },
      },
    },
  };

  // const totalLabels = [
  //   { name: "Jan", value: "01" },
  //   { name: "Fév", value: "02" },
  //   { name: "Mar", value: "03" },
  //   { name: "Avr", value: "04" },
  //   { name: "Mai", value: "05" },
  //   { name: "Juin", value: "06" },
  //   { name: "Juil", value: "07" },
  //   { name: "Août", value: "08" },
  //   { name: "Sept", value: "09" },
  //   { name: "Oct", value: "10" },
  //   { name: "Nov", value: "11" },
  //   { name: "Déc", value: "12" },
  // ];

  // const labels = currStats?.map((v) => {
  //   const name = moment(v?.month).format("MMM", "fr");
  //   const value = moment(v?.month).format("MM", "fr");
  //   return { name, value };
  // });
  const data = {
    labels: labels?.map((el) => el?.name),
    datasets: [
      {
        label: title,
        data: currStats,
        backgroundColor: color,
        borderColor: color,
      },
    ],
  };

  const dataWithHour = {
    labels: stats?.map((el) => el?.duration + "h"),
    datasets: [
      {
        label: title,
        data: stats?.map((el) => el.count),
        backgroundColor: color,
        borderColor: color,
      },
    ],
  };

  useEffect(() => {
    const last12Months = [];
    for (let i = 0; i < 12; i++) {
      const monthIndex = moment().subtract(i, "months").format("MM", "fr");
      const month = moment().subtract(i, "months").format("MMM", "fr");
      const year = moment().subtract(i, "months").format("YYYY", "fr");
      last12Months.push({ name: month, value: monthIndex, year });
    }
    setLabels(last12Months);
    if (!stats?.length > 0) {
      setLabels(last12Months?.reverse());
      setCurrStats(Array(12).fill(0));
      return;
    }
    setCurrStats(
      last12Months.reverse()?.map(({ value, year }) => {
        const stat =
          stats.find((v) => {
            const currMonth = moment(v.month).format("MM", "FR");
            const currYear = moment(v.month).format("YYYY", "FR");
            return currMonth === value && currYear === year;
          })?.count || 0;

        return stat;
      })
    );
  }, [stats]);

  return (
    <LightDarkContainer
      className="px-4 py-3 radius05 h100"
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <div
        className={`alignH justify-content-between ${
          withHour ? "mb-1" : "mb-4"
        } `}
      >
        <LightDarkText
          className="text-start bolder custom-font-size"
          lightModeText={mode === "light"}
        >
          {title}
        </LightDarkText>
      </div>
      {withHour && (
        <div className="alignH justify-content-end mb-1">
          <div className="alignH">
            <div
              className="legendBar mx-1"
              style={{
                backgroundColor: color,
              }}
            />
            <LightDarkText
              className="text-start f10"
              lightModeText={mode === "light"}
            >
              Total
            </LightDarkText>
          </div>
        </div>
      )}

      <Line
        options={options}
        data={withHour ? dataWithHour : data}
        className="py-2"
        style={{ maxHeight: "180px" }}
      />
    </LightDarkContainer>
  );
};

export default SimpleLineChart;
