import {
  GET_DEPARTEMENTS_FAILED,
  GET_DEPARTEMENTS_SUCCESS,
  LOADING,
} from "../ActionsType/departementTypes";

const initialState = {
  loading: false,
  departments: [],
  error: undefined,
};

const departmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_DEPARTEMENTS_SUCCESS:
      return {
        ...state,
        departments: payload,
        loading: false,
      };
    case GET_DEPARTEMENTS_FAILED:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

export default departmentReducer;
