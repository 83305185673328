import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import LightDarkText from "./LightDarkText";
import { HappyEmoji, MagicPenIcon, SendMessageIcon } from "./LightDarkSvg";
import "./LightDarkTextEditor.css";
import Api from "../../Api/Api";
import {
  emojisCategories,
  formats,
  handleMentionModule,
  hiddenEmojis,
} from "./TextEditor/textEditorUtils";
import EmojiPicker from "emoji-picker-react";
import OutsideClickHandler from "react-outside-click-handler";
import { handleError } from "../../REDUX/actions/toastMessage";

const LightDarkTextEditor = ({
  internalResponseTo,
  responseTo,
  value = "",
  label,
  placeholder,
  setValue,
  champs,
  totalInputs,
  height,
  showSendButton,
  reformulateText = false,
  callBack,
  disabledBtn,
  messageInput,
  required,
  isLoading,
  autoHeight,
  canMention = false,
  filesAndNoText = false,
  canBePrivate,
  setMentions = () => {},
  alertTextEditor,
  users = [],
  ticketChanged,
}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const tooltipRef = useRef(null);
  const { mode } = useSelector((state) => state.genearlReducer);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const { showPrivateMessages } = useSelector((state) => state.messagesReducer);
  const color = "#ff7f7f";
  const [showTooltip, setShowTooltip] = useState(false);
  const [checked, setChecked] = useState(false);
  const [modules, setModules] = useState({
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "bullet" }, { list: "ordered" }],
        ["clean"],
        ["link"],
      ],
    },
  });
  const handleChangeInput = (value) => {
    if (champs) {
      setValue({ ...totalInputs, [champs]: value });
    } else {
      setValue(value);
    }

    if (canMention) {
      const quillInstance = ref.current.getEditor();
      const content = quillInstance
        .getContents()
        .ops.filter((content) => content?.insert?.mention);
      setMentions(content || []);
    }
  };

  const handleMessage = () => {
    if (disabledBtn || isLoading || reformulateLoading) return;

    callBack();
    setValue({ ...totalInputs, [champs]: "" });
    if (ref?.current) {
      const quillInstance = ref.current.getEditor();
      quillInstance.setContents([]);
    }
  };

  // clear text input when going from a ticket to another
  useEffect(() => {
    if (ticketChanged) {
      setValue({ ...totalInputs, [champs]: "" });
      if (ref?.current) {
        const quillInstance = ref.current.getEditor();
        quillInstance.setContents([]);
      }
    }
  }, [ticketChanged]);
  const [reformulateLoading, setReformulateLoading] = useState(false);
  const handleFocus = () => {
    canMention &&
      setModules({
        ...modules,
        mention: handleMentionModule(
          canMention,
          internalResponseTo || showPrivateMessages
            ? users.filter((user) => user.role === 1 || user.role === 2)
            : users
        ),
      });
  };

  const reformulate = async () => {
    try {
      setReformulateLoading(true);
      const { data } = await Api.post(`/api/reformulate`, {
        data: { message: value[champs] },
      });

      const quillInstance = ref.current.getEditor();
      const newContents = quillInstance.clipboard.convert(data.message);
      quillInstance.setContents(newContents, Quill.sources.SILENT);

      setReformulateLoading(false);
    } catch (error) {
      dispatch(
        handleError(
          "Erreur lors de la reformulation. Veuillez informer votre administrateur",
          1
        )
      );
      setReformulateLoading(false);
    }
  };

  useEffect(() => {
    // SET OLD MESSAGE IN CASE OF EDIT
    if (value[champs]) {
      const quillInstance = ref.current.getEditor();
      const newContents = quillInstance.clipboard.convert(value[champs]);
      quillInstance.setContents(newContents, Quill.sources.SILENT);
    }
  }, []);

  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter" && !event.shiftKey) {
  //     handleMessage();
  //   }
  // };
  const handleCheckGuide = () => {
    setChecked(true);
    localStorage.setItem("guideToggle", true);
  };

  useEffect(() => {
    if (showTooltip) {
      tooltipRef?.current?.focus();
    }
  }, [showTooltip]);

  const addEmoji = (event) => {
    const quillInstance = ref.current.getEditor();
    quillInstance.focus();
    const range = quillInstance.getSelection(); // Get current cursor position

    quillInstance.insertEmbed(
      range?.index || 0,
      "image",
      event.imageUrl,
      Quill.sources.USER
    );
    quillInstance.setSelection(range.index ? range.index + 1 : 1); // Move cursor forward

    const updatedValue = quillInstance.root.innerHTML;
    setEmojiOpen(false);
    handleChangeInput(updatedValue);
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Rendering                                 */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-100 position-relative mb-3 notranslate">
      <LightDarkText
        className={"text-start bold custom-ticket-font-size mb-2"}
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        {label} {required ? <span className="text-red">*</span> : null}
      </LightDarkText>
      <OutsideClickHandler onOutsideClick={() => setEmojiOpen(false)}>
        <EmojiPicker
          className="react-picker-container"
          open={emojiOpen}
          width={350}
          searchPlaceholder="Recherche"
          reactionsDefaultOpen={false}
          allowExpandReactions={false}
          onEmojiClick={(event) => addEmoji(event)}
          hiddenEmojis={hiddenEmojis}
          previewConfig={{
            showPreview: false,
          }}
          categories={emojisCategories}
        />
      </OutsideClickHandler>
      <ReactQuill
        ref={ref}
        formats={formats}
        modules={modules}
        placeholder={placeholder}
        className={`lightDarkTextEditor ${responseTo ? "toolbar-v2" : ""} ${
          mode !== "light" ? "dark" : ""
        } ${
          autoHeight ? "auto-height" : alertTextEditor ? "alert-height" : ""
        } h${height} ${
          showPrivateMessages && canBePrivate
            ? "privateTextEditor"
            : mode === "light"
            ? "lightTextEditor"
            : messageInput
            ? "darkMessageEditor"
            : "darkTextEditor"
        }`}
        theme="snow"
        // onKeyUp={handleKeyDown}
        preserveWhitespace
        onChange={(value) => handleChangeInput(value)}
        // value={value[champs] || ""}
        onFocus={handleFocus}
      ></ReactQuill>
      {showSendButton || reformulateText ? (
        <div
          className="sendButton text-end d-flex gap-2 flex-column w5 align-items-center justify-content-evenly"
          style={{
            backgroundColor: showPrivateMessages
              ? "#fee2e2"
              : mode === "dark"
              ? "#0a122e"
              : "#f4f6fd",
            padding: "5px 0px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="pointer" onClick={() => setEmojiOpen(!emojiOpen)}>
            <HappyEmoji />
          </div>
          {reformulateLoading ? (
            <Spinner animation="border" size="sm" variant="dark-blue"></Spinner>
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ zIndex: 5000 }}>
                  <LightDarkText className="custom-font-size py-1 text-white">
                    Correction conversationnelle générée par l'IA
                  </LightDarkText>
                </Tooltip>
              }
            >
              <div>
                <MagicPenIcon clickCallback={reformulate} />
              </div>
            </OverlayTrigger>
          )}

          {isLoading ? (
            <Spinner animation="border" size="sm" variant="dark-blue"></Spinner>
          ) : (
            showSendButton && (
              <OverlayTrigger
                placement="top"
                overlay={
                  showPrivateMessages ? (
                    <Tooltip style={{ zIndex: 5000 }}>
                      <LightDarkText className="custom-font-size py-1 text-white w-100">
                        Ce message sera envoyé uniquement à vos collaborateurs.
                      </LightDarkText>
                    </Tooltip>
                  ) : (
                    <Tooltip style={{ zIndex: -999 }}></Tooltip>
                  )
                }
              >
                <div className="alignC">
                  <SendMessageIcon
                    color={showPrivateMessages ? color : null}
                    disabled={disabledBtn || isLoading || reformulateLoading}
                    clickCallback={handleMessage}
                  />
                </div>
              </OverlayTrigger>
            )
          )}
        </div>
      ) : null}
    </div>
  );
};

export default LightDarkTextEditor;

{
  /* <OverlayTrigger
                  placement="right"
                  show={
                    (showTooltip &&
                      (localStorage.getItem("guideToggle") === "false" ||
                        !localStorage.getItem("guideToggle"))) ||
                    (showTooltip && filesAndNoText && !isLoading)
                  }
                  overlay={
                    <Tooltip>
                      <div
                        tabIndex="-1"
                        ref={tooltipRef}
                        className="disable-focus"
                        onBlur={(e) => {
                          if (!e.currentTarget.contains(e.relatedTarget)) {
                            setShowTooltip(false);
                          }
                        }}
                      >
                        {filesAndNoText && !isLoading ? (
                          <LightDarkText className="custom-font-size py-1 text-white">
                            Oups il n'y a pas de commentaire avec votre fichier.
                            Voulez-vous vraiment l'envoyer ?
                          </LightDarkText>
                        ) : null
                        // <LightDarkText className="custom-font-size py-1 text-white">
                        //   Retour à la ligne avec <br />
                        //   "Shift + Entrer" <br />
                        //   Envoyez votre message <br />
                        //   avec "Entrer"
                        //   <br />
                        //   <label
                        //     htmlFor="guide-tooltip"
                        //     className="text-white text-start custom-font-size w-100 d-flex align-items-center mt-2 pointer"
                        //   >
                        //     <span
                        //       className={`guide-tooltip-span position-relative d-flex align-items-center justify-content-center ${
                        //         checked ? "checked" : ""
                        //       }`}
                        //     >
                        //       <CheckIcon />
                        //     </span>
                        //     <input
                        //       type="checkbox"
                        //       name="guideTooltip"
                        //       id="guide-tooltip"
                        //       className="guide-tooltip"
                        //       checked={checked}
                        //       onChange={handleCheckGuide}
                        //     />
                        //     J'ai compris
                        //   </label>
                        // </LightDarkText>
                        }
                      </div>
                    </Tooltip>
                  }
                >
                  <div
                    onMouseEnter={() => {
                      setShowTooltip(true);
                    }}
                    onMouseLeave={() => {
                      filesAndNoText && setShowTooltip(false);
                    }}
                    onClick={() => {
                      setShowTooltip(true);
                    }}
                  > */
}
