import React from "react";
import { useSelector } from "react-redux";

import LightDarkText from "../../Common/LightDarkText";
import AttachmentsList from "../../Common/AttachmentsList";
import StatusButton from "../../Common/Buttons/StatusButton";
import DateText from "../../Tickets/TicketCommonComponents/DateText";
import InfoLabel from "../../Tickets/TicketCommonComponents/InfoLabel";
import CreatorName from "../../Tickets/TicketCommonComponents/CreatorName";
import TitleImageBlock from "../../Tickets/TicketCommonComponents/TitleImageBlock";

import { handleDuration } from "../../Tickets/ticketUtils";
import { handleInterventionStatut } from "../interventionUtils";

import useWindowWidth from "../../../Hooks/useWindowWidth";

const InterventionDetails = ({ intervention }) => {
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="directionC justify-content-between mt-3 sideChatListContainer scrollable">
      <TitleImageBlock
        interventionTitle={intervention?.attributes?.title}
        extraData={
          <div className="d-flex flex-1 align-items-center gap-1 mt-1 ">
            <DateText date={intervention?.attributes?.createdAt} />
            <CreatorName name={intervention?.attributes?.creator?.data} />
          </div>
        }
      />
      <div className="d-flex align-items-center justify-content-between gap-5 mt-4">
        <InfoLabel
          label="Durée :"
          value={handleDuration(intervention?.attributes?.duration)}
        />
        <StatusButton
          height={screenWidth < 1200 ? "30px" : "40px"}
          type={
            handleInterventionStatut(intervention?.attributes?.statut).color
          }
          text={handleInterventionStatut(intervention?.attributes?.statut).text}
          background={
            handleInterventionStatut(intervention?.attributes?.statut).bg
          }
        />
      </div>
      <LightDarkText
        insertHtml={intervention?.attributes?.description}
        className="custom-ticket-font-size text-start break-word regular mt-3 p-nomb"
        color={mode === "light" ? "text-blue-grey" : "text-light-text"}
      />
      {intervention?.attributes?.fichiers?.data?.length !== 0 && (
        <AttachmentsList files={intervention?.attributes?.fichiers?.data} />
      )}
    </div>
  );
};

export default InterventionDetails;
