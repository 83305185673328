import { gql } from "@apollo/client";
import { ATTRIBUTES_PLAN } from "../Attributes/Plan";

export const GET_CURRENT_PLAN = gql`
  query getCurrentPlan($filters: PlanFiltersInput) {
    plans(
        sort: "date:desc",
        pagination: {page: 1, pageSize: 1},
        filters: $filters
      ) {
      data {
        id
        attributes {
          ${ATTRIBUTES_PLAN}
        }
      }
    }
  }
`;

export const GET_PLANS = gql`
  query getPlans($filters: PlanFiltersInput) {
    plans(sort: "date:desc", pagination: {page: 1, pageSize: 100}, filters: $filters) {
      data {
        id
        attributes {
          ${ATTRIBUTES_PLAN}
        }
      }
    }
  }
`;
