import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";

import { handleEventStatut } from "../../calendarUtils";
import useWindowWidth from "../../../../Hooks/useWindowWidth";

const EventStatus = ({ statut, eventPopUp }) => {
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {statut > 0 && (
        <div className="mt-4 d-flex align-items-center gap-2 f18">
          {handleEventStatut(statut).icon}
          <LightDarkText
            className={`
          ${screenWidth > 1200 ? "f14" : "f12"}
          text-start ${eventPopUp ? "bold" : "text-white regular"}`}
          >
            {handleEventStatut(statut).text}
          </LightDarkText>
        </div>
      )}
    </>
  );
};

export default EventStatus;
