export const LOADING_DEVIS = "LOADING_DEVIS";
export const GET_DEVIS_SUCCESS = "GET_DEVIS_SUCCESS";
export const GET_DEVIS_FAILED = "GET_DEVIS_FAILED";

export const UPDATE_DEVIS_SUCCESS = "UPDATE_DEVIS_SUCCESS";
export const UPDATE_DEVIS_FAILED = "UPDATE_DEVIS_FAILED";

export const REMOVE_DEVIS_SUCCESS = "REMOVE_DEVIS_SUCCESS";
export const REMOVE_DEVIS_FAILED = "REMOVE_DEVIS_FAILED";

export const SET_DEVIS = "SET_DEVIS";
export const UNSET_DEVIS = "UNSET_DEVIS";
export const CHANGE_FILTER_DEVIS = "CHANGE_FILTER_DEVIS";

export const ADD_DEVIS_SUCCESS = "ADD_DEVIS_SUCCESS";
export const ADD_DEVIS_FAILED = "ADD_DEVIS_FAILED";
export const LOADING_ADD_DEVIS = "LOADING_ADD_DEVIS";

export const UPDATE_FIELDS_DEVIS = "UPDATE_FIELDS_DEVIS";
