import React from "react";
import { useSelector } from "react-redux";

import flag from "../../Images/french-flag.png";
import LightDarkText from "../Common/LightDarkText";

const Copiright = () => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <LightDarkText
      className="d-flex justify-content-center align-items-center regular custom-ticket-font-size w-100"
      color={mode === "light" ? "text-blue-grey" : "text-light-blue-grey"}
    >
      Logiciel édité avec &#9829; par Agilix.io
      <img src={flag} width="20" height="20" className="mx-1" />
      <p className="m-0">Tous droits réservés © Agilix France 2025</p>
    </LightDarkText>
  );
};

export default Copiright;
