import React from "react";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import LightDarkText from "../Common/LightDarkText";
import { useSelector } from "react-redux";
import { ArrowDownIcon } from "../Common/LightDarkSvg";
import { useRef } from "react";
import { CheckIcon } from "../Common/Icons/CheckIcon";
import { useEffect } from "react";

const MultiSelect = ({
  data,
  label,
  required,
  icon,
  placeholder = "select",
  bgWhite,
  champs,
  totalInput,
  setValue,
  selectAll = false,
  extraCss,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const elementRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [direction, setDirection] = useState("top");
  const [containerHeight, setContainerHeight] = useState(0);

  const handleChange = (label, value) => {
    setSelected((prevSelected) => {
      if (prevSelected?.includes(value)) {
        return prevSelected?.filter((item) => item !== value);
      } else {
        return [...prevSelected, value];
      }
    });
    setValue((prevTotalInput) => ({
      ...prevTotalInput,
      [champs]: [...selected, value],
    }));
  };
  const formatSelectedItems = (selectedArray) => {
    if (selectedArray?.length > 3) {
      return `${selectedArray?.length} items selected`;
    } else {
      return selectedArray?.map((item) => item).join(", ");
    }
  };
  useEffect(() => {
    if (totalInput[champs]) {
      setSelected(totalInput[champs]);
    }
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  useEffect(() => {
    if (!elementRef?.current) return;
    const containerHeight =
      elementRef.current.getBoundingClientRect().height * data?.length ?? 0;
    setContainerHeight(containerHeight);
    const top = elementRef.current.getBoundingClientRect().top;
    const availableHeight = window.screen.height - top;
    if (availableHeight < containerHeight + 100) {
      setDirection("top");
    } else {
      setDirection("bottom");
    }
  }, [data?.length]);
  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="w-100" ref={elementRef}>
        <LightDarkText
          className={`text-start bold f12 mb-2 ${extraCss}`}
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          {label} {required ? <span className="text-red">*</span> : null}
          <div></div>
        </LightDarkText>
        <div className="w-100 position-relative">
          <div
            className={`w-100 h40  radius05 d-flex align-items-center  ps-2 ${
              mode === "light" ? "lightInput" : "darkInput"
            } ${bgWhite && mode === "light" && "bg-white"}`}
            onClick={() => setOpen(!open)}
          >
            <div className="w90 d-flex align-items-center ">
              {selected?.length !== 0 ? (
                <LightDarkText
                  className={"text-start f12 ms-1"}
                  color={
                    mode === "light" ? "text-dark-blue" : "text-light-text"
                  }
                >
                  {formatSelectedItems(selected)}
                </LightDarkText>
              ) : (
                <>
                  {icon}
                  <LightDarkText
                    className={"text-start f12 ms-1"}
                    color={
                      mode === "light" ? "text-dark-blue" : "text-light-text"
                    }
                  >
                    {placeholder}
                  </LightDarkText>
                </>
              )}
            </div>
            <div className="w10 d-flex justify-content-end pe-2">
              <ArrowDownIcon />
            </div>
          </div>
          {open && (
            <div
              className={` position-absolute  ${
                mode === "light" ? "bg-light-gray" : "bg-dark-grey-bg"
              }  selectContainer select-direction-${direction} ms-1 `}
              style={{
                width: width - 8,
              }}
            >
              {data?.map(({ id, value, label }, index) => {
                return (
                  <LightDarkText
                    key={index}
                    className={
                      "d-flex justify-content-between bold f12 p-2 selectItem"
                    }
                    style={{
                      backgroundColor: selected.includes(label) && "#59638b26",
                    }}
                    onclickCallback={() => handleChange(label, value)}
                  >
                    {label}
                    {selected?.includes(value) && <CheckIcon />}
                  </LightDarkText>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default MultiSelect;
