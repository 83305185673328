import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";

import LightDarkContainer from "../Common/LightDarkContainer";
import LightDarkText from "../Common/LightDarkText";

import jsonData from "./FranceData.json";

const FranceDepartementsMap = ({ statsData }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const [hovered, setHovered] = useState(false);
  const [tooltipContent, setTooltipContent] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMove = (geo, x, y) => {
    const departmentCode = geo.properties.code;
    const department = statsData?.find((item) => item?.code === departmentCode);

    setHovered(true);
    setTooltipContent({
      depName: `${geo.properties?.code} - ${geo?.properties.nom}`,
      clientsCount: department?.clientsCount || 0,
      operatorsCount: department?.operatorsCount || 0,
      ticketsCount: department?.ticketsCount || 0,
    });
    setTooltipPosition({ x, y });
  };
  const handleLeave = () => {
    setHovered(false);
    setTooltipContent({});
  };
  return (
    <LightDarkContainer
      className="user-map mt-1 radius10"
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <LightDarkText
        className={"text-start ps-4 pt-3 bold f15"}
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        Repartition générale par département
      </LightDarkText>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{
          scale: 1200,
          center: [2.454071, 46.279229],
        }}
        width={900}
        height={350}
        style={{ width: "100%", height: "auto", position: "relative" }}
      >
        <Geographies geography={jsonData}>
          {({ geographies }) =>
            geographies.map((geo, index) => {
              const departmentCode = geo.properties.code;
              const department = statsData.find(
                (item) => item.code === departmentCode
              );
              const totalCount =
                department?.clientsCount +
                department?.operatorsCount +
                department?.ticketsCount;
              let color;
              if (totalCount >= 1 && totalCount < 2) {
                color = "#3365FF";
              } else if (totalCount >= 2 && totalCount < 10) {
                color = "#0047B3";
              } else if (totalCount >= 10) {
                color = "#002080";
              } else {
                color = "#3365FF33";
              }
              return (
                <Fragment key={index}>
                  <Geography
                    geography={geo}
                    onMouseMove={(e) => {
                      handleMove(
                        geo,
                        e.target.getBoundingClientRect().right,
                        e.target.getBoundingClientRect().top
                      );
                    }}
                    onMouseLeave={handleLeave}
                    fill={color}
                    stroke="#fff"
                    strokeWidth="0.5"
                    style={{
                      hover: {
                        fill: "#3365ff60",
                        outline: "none",
                        transition: "all 250ms",
                      },
                      pressed: { outline: "none" },
                      default: { outline: "none" },
                    }}
                  />
                </Fragment>
              );
            })
          }
        </Geographies>
      </ComposableMap>
      {hovered && (
        <div
          style={{
            display: hovered ? "flex" : "none",
            flexDirection: "column",
            position: "fixed",
            zIndex: 9999,
            left: tooltipPosition.x,
            top: tooltipPosition.y,
            color: "#ffff",
            backgroundColor: "#3365ff99",
            padding: "10px",
            alignItems: "flex-start",
            borderRadius: "8px",
          }}
        >
          <span className="bold">{tooltipContent.depName}</span>
          <span className="bold">
            Nombre de commerciaux: {tooltipContent?.clientsCount}{" "}
          </span>
          <span className="bold">
            Nombre de revendeurs: {tooltipContent?.operatorsCount}{" "}
          </span>
          <span className="bold">
            Nombre de tickets: {tooltipContent?.ticketsCount}{" "}
          </span>
        </div>
      )}
    </LightDarkContainer>
  );
};
export default FranceDepartementsMap;
