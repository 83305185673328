import React from "react";

const NotificationsRedPoint = ({ big = false, top = 3, right = 3 }) => {
  return (
    <svg
      style={{ top: top, right: right }}
      className="position-absolute"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {big ? (
        <circle cx="20" cy="4" r="4" fill="#FF5D9E" />
      ) : (
        <circle cx="21" cy="3" r="3" fill="#FF5D9E" />
      )}
    </svg>
  );
};

export default NotificationsRedPoint;
