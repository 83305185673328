import { handleFirstLastName } from "../../Utils/utils";

const initialState = {
  interviewLoading: false,
  responseLoading: false,
  interviewForm: null,
  interviewInformations: null,
  interviewResponses: null,
  userResponded: [],
};

const interviewsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "FETCH_INTERVIEW":
      return {
        ...state,
        interviewLoading: false,
        interviewForm: payload,
        responseLoading: false,
      };
    case "RESPONDING_LOADING":
      return {
        ...state,
        responseLoading: true,
      };
    case "FETCH_INTERVIEW_RESPONSE":
      return {
        ...state,
        interviewLoading: false,
        interviewResponses: payload,
      };
    case "FETCH_INTERVIEW_USERS":
      return {
        ...state,
        interviewLoading: false,
        userResponded: payload.map((el) => {
          return {
            title: handleFirstLastName(el),
            toggleValue: el.id,
            isVisible: true,
          };
        }),
      };
    case "FETCH_INTERVIEW_INFORMATIONS":
      return {
        ...state,
        interviewLoading: false,
        interviewInformations: payload,
      };
    case "INTERVIEW_LOADING":
      return { ...state, interviewLoading: true };
    default:
      return state;
  }
};

export default interviewsReducer;
