import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OverviewTab from "../../Components/Dashboard/OverviewTab";
import StatisticsTab from "../../Components/Dashboard/Statistics";
import LightDarkFilterSelect from "../../Components/Common/LightDarkFilterSelect";
import "./DashBoardView.css";
import StatisticsLog from "../../Components/Dashboard/StatisticsLog";
import StatisticsProspects from "../../Components/Dashboard/StatisticsProspects";
import StatisticsInterviews from "../../Components/Dashboard/StatisticsInterviews";
import MultiTabsMenu from "../../Components/Common/MultiTabsMenu";

const DashBoardView = () => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { societes } = useSelector((state) => state.sharedDataReducer);
  const { isOperator, isSupervisor, role } = useSelector(
    (state) => state.roleReducer
  );
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const [values, setValues] = useState(
    societes.length === 1
      ? { societes: societes }
      : {
          societes: [],
        }
  );
  const [isToggle, setIsToggle] = useState("Overview");
  console.log(selectedOrganisation);
  const DashboardTabs = [
    {
      title: "Général",
      toggleValue: "Overview",
      isVisible: true,
    },
    {
      title: "Statistiques",
      toggleValue: "Statistics",
      isVisible: true,
    },
    {
      title: "Connexions",
      toggleValue: "Connexion",
      isVisible: isSupervisor,
    },
    {
      title: "Prospects",
      toggleValue: "prospects",
      isVisible: selectedOrganisation.attributes.withProspects,
    },
    {
      title: "Sondages",
      toggleValue: "interviews",
      isVisible: isSupervisor && selectedOrganisation.id === 2,
    },
  ];
  const connexionMenu = [
    { value: "days", label: "Jours" },
    { value: "months", label: "Mois" },
    { value: "years", label: "Année" },
  ];

  useEffect(() => {
    if (isSupervisor || isOperator) {
      setValues((currState) => ({ ...currState, societe: societes[0] }));
    }
  }, [role, societes]);

  const [connexionFields, setConnexionFields] = useState({
    duration: [{ value: "years", label: "Année" }],
  });

  return (
    <div className="container-fluid d-flex flex-column gap-4">
      <div
        className={`radius05 d-flex justify-content-between align-items-center t-02 ${
          mode === "dark" ? "bg-dark-grey-bg" : "bg-white"
        }`}
      >
        <MultiTabsMenu
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          allTabs={DashboardTabs}
          align="start"
          px="px-4"
        />
        {isToggle === "Statistics" && (
          <div className="me-2">
            <LightDarkFilterSelect
              data={societes}
              multiple
              canSelectAll
              newField="societes"
              champs="societes"
              totalInputs={values}
              setValue={setValues}
              placeholder="Toutes les sociétés"
              societySelectInput
            />
          </div>
        )}
        {isToggle === "Connexion" && (
          <div className="me-2">
            <LightDarkFilterSelect
              data={connexionMenu}
              champs="duration"
              multiple={false}
              totalInputs={connexionFields}
              setValue={setConnexionFields}
              placeholder="Durée"
            />
          </div>
        )}
      </div>
      <div className="w-100" style={{ height: "80vh" }}>
        {isToggle === "Overview" && <OverviewTab />}
        {isToggle === "Statistics" && (
          <StatisticsTab values={values} setValues={setValues} />
        )}
        {isToggle === "Connexion" && (
          <StatisticsLog duration={connexionFields.duration[0]} />
        )}
        {isToggle === "prospects" && (
          <StatisticsProspects duration={connexionFields.duration[0]} />
        )}
        {isToggle === "interviews" && (
          <StatisticsInterviews duration={connexionFields.duration[0]} />
        )}
      </div>
    </div>
  );
};

export default DashBoardView;
