import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Thumbnail from "../Common/Thumbnail";
import PreviewModal from "../Common/PreviewModal";
import LightDarkText from "../Common/LightDarkText";
import TooltipActions from "../Common/Tooltip/TooltipActions";
import LightDarkContainer from "../Common/LightDarkContainer";
import { DeleteIcon, Download, EyeIcon } from "../Common/LightDarkSvg";

import {
  downloadInvoice,
  fileUrl,
  getValueByAccessor,
} from "../../Utils/utils";

import useWindowWidth from "../../Hooks/useWindowWidth";

import { deleteInvoice } from "../../REDUX/actions/facture";
import { openModal, closeModal } from "../../REDUX/actions/modal";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";

import "./Factures.css";
import { extractPreviewData } from "../Cloud/cloudUtils";

const FacturesCard = ({ invoice, tableList, index }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { canCreateInvoices } = useSelector((state) => state.roleReducer);

  const [fileData] = useState({
    name: invoice?.attributes?.media?.data[0]?.attributes?.name,
    url: fileUrl(invoice?.attributes?.media?.data[0]?.attributes?.url),
    mime: invoice?.attributes?.media?.data[0]?.attributes?.mime,
    date: invoice?.attributes.date,
    size: invoice?.attributes?.media?.data[0]?.attributes?.size,
    societyName: invoice.attributes.stringSociety,
    societyAvatar:
      invoice?.attributes?.societe?.data?.attributes?.avatar?.data?.attributes
        ?.image?.data?.attributes?.url,
  });

  const openPreview = () => {
    dispatch(
      openModal({
        type: `${
          invoice?.attributes?.media?.data[0]?.attributes?.mime?.includes(
            "image"
          )
            ? ""
            : "pdfFile"
        }`,
        content: <PreviewModal fileData={fileData} />,
      })
    );
  };

  const screenWidth = useWindowWidth();

  const [showTooltip, setshowTooltip] = useState(false);
  const tooltipData = [
    {
      id: 1,
      text: "Voir les détails",
      icon: <EyeIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openModal({
            type: "pdfFile",
            content: <PreviewModal fileData={fileData} />,
          })
        );
      },
      condition: true,
    },
    {
      id: 2,
      text: "Télécharger",
      icon: <Download className="me-2" />,
      action: () => {
        setshowTooltip(false);
        downloadInvoice(invoice);
      },
      condition: true,
    },
    {
      id: 3,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        openPreview();
      },
      condition: canCreateInvoices,
    },
  ];

  return (
    <LightDarkContainer
      key={index}
      className="d-flex py-2 px-3 pointer"
      colorLight="white"
      colorDark="dark-grey-bg"
      cardContainer={true}
      hover
    >
      <div className="w-100 d-flex">
        {tableList.map(({ id, accessor, width }) => {
          const columnCol = width;
          return (
            <div
              className={`alignH w${columnCol} p-0`}
              key={id}
              onClick={() => openPreview()}
            >
              {
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        {getValueByAccessor(invoice, accessor)}
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div className={`d-flex align-items-center w95 gap-2`}>
                    {accessor.includes("stringSociety") && (
                      <Thumbnail
                        size={screenWidth < 1200 ? 30 : 40}
                        apiImg={
                          invoice?.attributes?.societe?.data?.attributes?.avatar
                            ?.data?.attributes?.image?.data?.attributes?.url
                        }
                        img={"society"}
                        rounded
                      />
                    )}
                    <LightDarkText
                      className={`custom-ticket-font-size text-start text-ellipsis ${
                        accessor.includes("name") ? "bolder" : "bold"
                      }`}
                      color={
                        accessor.includes("name") && mode === "light"
                          ? "text-dark-blue"
                          : accessor.includes("name") && mode !== "light"
                          ? "text-white"
                          : mode === "light"
                          ? "text-blue-grey"
                          : "text-light-text"
                      }
                    >
                      {accessor.includes("number") ? "#" : null}
                      {getValueByAccessor(invoice, accessor)}
                      {accessor.includes("HT") || accessor.includes("TTC")
                        ? "€"
                        : accessor.includes("TVA")
                        ? "%"
                        : null}
                    </LightDarkText>
                  </div>
                </OverlayTrigger>
              }
            </div>
          );
        })}
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <TooltipActions
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
        />
      </div>
    </LightDarkContainer>
  );
};

export default FacturesCard;
