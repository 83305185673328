import React from "react";
import LightDarkText from "../Common/LightDarkText";
import { useDispatch, useSelector } from "react-redux";
import FileUploader from "../Simulateur/FileUploader";
import { useState } from "react";
import { useEffect } from "react";
import { fileUrl, handleUpdateStatus, mapOperator } from "../../Utils/utils";
import TextAreaInput from "../Simulateur/TextAreaInput";
import Select from "../Simulateur/Select";
import ColoredButton from "../Common/Buttons/ColoredButton";
import useWindowWidth from "../../Hooks/useWindowWidth";
import {
  archiveProspect,
  updateProspect,
  updateStatus,
} from "../../REDUX/actions/prospect";
import { saveAs } from "file-saver";
import { getConditionalUsers } from "../../REDUX/actions/clients";

const DetailsDevis = ({ choosenItem }) => {
  const { user } = useSelector((state) => state.userReducer);
  const [file, setFile] = useState({});
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const [note, setNote] = useState({ note: choosenItem?.note });
  const { mode } = useSelector((state) => state.genearlReducer);
  const { clients } = useSelector((state) => state.clientsReducer);
  const { isSupervisor, isOperator, isClientAdmin } = useSelector(
    (state) => state.roleReducer
  );
  const [selectedRevendeur, setSelectedRevendeur] = useState({
    revendeur: null,
  });
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    dispatch(getConditionalUsers());
  }, [dispatch]);
  useEffect(() => {
    const selected = mapOperator(clients).find(
      (el) => el?.label === choosenItem?.actor
    );
    setSelectedRevendeur({ revendeur: selected?.value || null });
  }, []);

  const handleStatusUpdate = () => {
    dispatch(
      updateProspect(
        choosenItem?.id,
        {
          note: note.note,
          acteurs:
            choosenItem.status === "NEW_SELLER"
              ? choosenItem.actors.map((el) => el.id)
              : [
                  ...choosenItem.actors
                    ?.filter(
                      (el) =>
                        el?.attributes?.user_org_permissions?.data[0]
                          ?.attributes?.role === 2
                    )
                    .map((el) => el.id)
                    .filter((el) => el),
                  selectedRevendeur.revendeur,
                ],
        },
        undefined,
        "devis"
      )
    );
    dispatch(
      updateStatus(choosenItem?.id, {
        status: handleUpdateStatus(choosenItem?.status)?.newStatus,
      })
    );
    // setSelectedComm({ commercial: null });
    setChecked(false);
  };
  const cancel = () => {
    dispatch(archiveProspect(choosenItem?.id, true, note.note, "devis"));
  };
  const ButtonData = [
    {
      id: 1,
      text:
        choosenItem?.status === "NEW"
          ? "Oui, valider la demande de tarif"
          : "Oui, transformer en bon de commande",
      color: "green",
      icon: null,
      disabled:
        !checked ||
        (choosenItem.status === "NEW" && !selectedRevendeur.revendeur),
      action: handleStatusUpdate,
      shouldShow: true,
    },
    {
      id: 2,
      text: "Non, annuler la demande de tarif",
      color: "red",
      icon: null,
      shouldShow: true,
      action: cancel,
    },
  ];

  const filtredClient = clients.filter((item) =>
    item.attributes.user_org_permissions[0].departements.some(
      (department) => department.name === choosenItem?.department
    )
  );

  const uploadFile = () => {
    dispatch(
      updateProspect(choosenItem?.id, undefined, file?.file[0], "devis")
    );
  };
  const downloadDocument = (url, name) => {
    saveAs(fileUrl(url), name);
  };
  const updateDocument = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";
    fileInput.addEventListener("change", (e) => {
      const selectedFile = e.target.files;
      if (selectedFile) {
        setFile({ file: selectedFile });
      }
    });

    fileInput.click();
  };
  return (
    <div className="d-flex flex-column w-100 h-100 ">
      <div
        className="mt-3 m-2 py-2 showScroll"
        style={{ height: "70%", overflow: "auto" }}
      >
        <div className="my-2">
          <LightDarkText className={`text-center bold py-1 f18`}>
            Affecter le devis de{" "}
            {choosenItem?.firstname && choosenItem?.firstname}{" "}
            {choosenItem?.lastname && choosenItem?.lastname}
          </LightDarkText>
          <LightDarkText
            className={`text-center bold py-1 f12 my-3`}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {choosenItem?.status === "NEW_SELLER"
              ? "Transformation de la demande de tarif en bon de commande"
              : "Validation de la demande de tarif"}
          </LightDarkText>
        </div>
        {filtredClient.length === 0 && choosenItem?.status !== "NEW_SELLER" ? (
          <LightDarkText
            className={`text-center bold py-1 f12 mt-3`}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            Aucun revendeur n'existe dans la région {choosenItem?.department}
          </LightDarkText>
        ) : (
          <>
            {(choosenItem?.status === "NEW" ||
              choosenItem?.status === "NEW_SELLER") &&
              (choosenItem?.document?.length > 0 ? (
                <div className="d-flex">
                  {file?.file ? (
                    <ColoredButton
                      textButton={
                        screenWidth > 600 && "Enregistrer la piece jointe"
                      }
                      color={"dark-grey"}
                      disabled={!file?.file}
                      callback={uploadFile}
                    />
                  ) : (
                    <>
                      <div className="m-2">
                        <ColoredButton
                          textButton={
                            screenWidth > 600 && "Télécharger la piece jointe"
                          }
                          color={"dark-grey"}
                          disabled={!choosenItem?.document}
                          callback={() =>
                            downloadDocument(
                              choosenItem?.document[0]?.attributes?.url,
                              choosenItem?.document[0]?.attributes?.name
                            )
                          }
                        />
                      </div>
                      <div className="m-2">
                        <ColoredButton
                          textButton={
                            screenWidth > 600 && "Modifier la piece jointe"
                          }
                          color={"dark-grey"}
                          callback={updateDocument}
                        />
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="my-3">
                  {file?.file ? (
                    <ColoredButton
                      textButton={
                        screenWidth > 600 && "Enregistrer la piece jointe"
                      }
                      color={"dark-grey"}
                      disabled={!file?.file}
                      callback={uploadFile}
                    />
                  ) : (
                    <FileUploader
                      label={"Ajouter une piece jointe"}
                      multiple={false}
                      champs={`file`}
                      totalInputs={file}
                      setValue={setFile}
                    />
                  )}
                </div>
              ))}

            <div className=" w-100 ">
              <TextAreaInput
                bgwhite={false}
                placeholder="Note interne (ou raison d’annulation)"
                champs={"note"}
                rows={7}
                totalInput={note}
                setValue={setNote}
              />
            </div>

            {choosenItem?.status !== "KO_NEW" &&
              ((choosenItem?.status === "NEW_SELLER" && isClientAdmin) ||
              isSupervisor ||
              (choosenItem?.status === "NEW" && isOperator) ? (
                <div className="d-flex align-items-center py-3 ">
                  <div className="d-flex me-2 ">
                    <input
                      type="checkbox"
                      id="check"
                      name="check"
                      value={checked}
                      onChange={() => setChecked(!checked)}
                      checked={checked}
                    />
                  </div>
                  <LightDarkText
                    className={`text-start bold py-1 f12 mt-1 lh18 `}
                    color="text-light-text"
                  >
                    {choosenItem?.status === "NEW_SELLER"
                      ? " En cochant cette case, vous confirmez avoir l'accord du client"
                      : " En cochant cette case, vous allez affecter le devis au revendeur"}
                  </LightDarkText>
                </div>
              ) : null)}
            {choosenItem?.status === "NEW_SELLER" && !isClientAdmin && (
              <LightDarkText
                className={`text-start bold py-1 f12 mt-1 lh18 `}
                color="text-light-text"
              >
                {" "}
                La demande de tarif sera transformé en bon de commande et envoyé
                en production
              </LightDarkText>
            )}
            {(choosenItem?.status === "NEW" && isOperator) || isSupervisor ? (
              <div className="">
                <Select
                  data={mapOperator(filtredClient)}
                  bgWhite={false}
                  position="absolute"
                  champs={`revendeur`}
                  totalInput={selectedRevendeur}
                  isTop={true}
                  setValue={setSelectedRevendeur}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
      {choosenItem?.status !== "KO_NEW" &&
        ((choosenItem?.status === "NEW_SELLER" && isClientAdmin) ||
        isSupervisor ||
        (choosenItem?.status === "NEW" && isOperator) ? (
          <div className="w-100 h30 d-flex flex-column justify-content-end">
            {ButtonData.map(
              ({ id, text, color, icon, disabled, action, shouldShow }) =>
                shouldShow && (
                  <div key={id} className="m-2">
                    <ColoredButton
                      textButton={screenWidth > 600 && text}
                      color={color}
                      iconSvg={icon}
                      disabled={disabled}
                      callback={action}
                    />
                  </div>
                )
            )}
          </div>
        ) : null)}
    </div>
  );
};

export default DetailsDevis;
