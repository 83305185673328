import React from "react";
import { useSelector } from "react-redux";

import LightDarkText from "../LightDarkText";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Tooltipitem = ({id,text,action,icon,disabled,toolTipText,isParent,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <div onClick={disabled || isParent ? () => {} : (e) => action(e)}>
      <OverlayTrigger
        placement="top"
        overlay={
          disabled ? (
            <Tooltip style={{ zIndex: 1050 }}>
              <LightDarkText className="custom-ticket-font-size py-1 text-white">
                {toolTipText}
              </LightDarkText>
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        <div className={`tooltip-item p-3`} key={id}>
          <LightDarkText
            className={`text-start custom-ticket-font-size  alignH bold ${!isParent ? "clickable-text t-02 pointer" : ""} ${disabled ? "text-disabled" : ""}`}
            color={mode === "light" ? "text-dark-blue" : "text-light-text"}
          >
            <div>{icon}</div>
            <div className={`bolder ${disabled ? "text-disabled" : ""}`}>{text}</div>
          </LightDarkText>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default Tooltipitem;
