import { useSelector } from "react-redux";

import LightDarkText from "../LightDarkText";

const FormRequiredMessage = () => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <LightDarkText className={"text-start bold custom-ticket-font-size"}>
      <span className="text-red">*</span>Champs obligatoires
    </LightDarkText>
  );
};

export default FormRequiredMessage;
