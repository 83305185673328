import { gql } from "@apollo/client";
import {
  INTERVENTION_ATTRIBUTES,
  INTERVENTION_ITEM_ATTRIBUTES,
} from "../Attributes/Intervention";

export const CREATE_INTERVENTION = gql`
  mutation ($title: String, $description: String, $duration: Float, $statut: Int, $creator: ID, $dateDebut: DateTime, $hors_forfait:Boolean, $societe: ID, $ticket: ID, $fichiers: [ID],$organisation: ID) {
    createIntervention(
      data: {
        title: $title
        description: $description
        duration: $duration
        statut: $statut
        dateDebut: $dateDebut
        societe: $societe
        hors_forfait: $hors_forfait
        ticket: $ticket
        fichiers: $fichiers
        creator: $creator
        organisation: $organisation
      }
    ) {
      data {
        id
        attributes {
          ${INTERVENTION_ATTRIBUTES}
        }
      }
    }
  }
`;

export const UPDATE_INTERVENTION = gql`
  mutation ($id: ID!, $title: String, $description: String, $motifRefus: String, $duration: Float, $statut: Int, $acceptDate: DateTime,$hors_forfait: Boolean, $refusDate: DateTime, $revisionDate: DateTime, $validateDate: DateTime, $fichiers: [ID]) {
    updateIntervention(
      id: $id, 
      data: { 
        title: $title,
        description: $description,
        motifRefus: $motifRefus,
        duration: $duration,
        hors_forfait: $hors_forfait
        fichiers: $fichiers
        statut: $statut, 
        acceptDate: $acceptDate, 
        refusDate: $refusDate, 
        revisionDate: $revisionDate, 
        validateDate: $validateDate 
      }
    ) {
      data {
        id
        attributes {
          ${INTERVENTION_ITEM_ATTRIBUTES}
        }
      }
    }
  }
`;

export const CREATE_ACTION = gql`
  mutation (
    $title: String
    $motif: String
    $intervention: ID
    $ticket: ID
    $event: ID
    $by: ID
    $statut: Int
  ) {
    createAction(
      data: {
        title: $title
        motif: $motif
        intervention: $intervention
        ticket: $ticket
        event: $event
        by: $by
        statut: $statut
      }
    ) {
      data {
        id
      }
    }
  }
`;
