export const LOADING_PROSPECT = "LOADING_PROSPECT";
export const GET_PROSPECTS_SUCCESS = "GET_PROSPECTS_SUCCESS";
export const GET_PROSPECTS_FAILED = "GET_PROSPECTS_FAILED";

export const UPDATE_PROSPECT_SUCCESS = "UPDATE_PROSPECT_SUCCESS";
export const UPDATE_PROSPECT_FAILED = "UPDATE_PROSPECT_FAILED";

export const UPDATE_DEVIS_SUCCESS = "UPDATE_DEVIS_SUCCESS";
export const UPDATE_DEVIS_FAILED = "UPDATE_DEVIS_FAILED";

export const REMOVE_PROSPECT_SUCCESS = "REMOVE_PROSPECT_SUCCESS";
export const REMOVE_PROSPECT_FAILED = "REMOVE_PROSPECT_FAILED";

export const SET_PROSPECT = "SET_PROSPECT";
export const UNSET_PROSPECT = "UNSET_PROSPECT";

export const GET_PROSPECT_SUCCESS = "GET_PROSPECT_SUCCESS";
export const GET_PROSPECT_FAILED = "GET_PROSPECT_FAILED";

export const UPDATE_PROSPECT_FIELD_SUCCESS = "UPDATE_PROSPECT_FIELD_SUCCESS";
export const UPDATE_PROSPECT_FIELD_FAILED = "UPDATE_PROSPECT_FIELD_FAILED";

export const UPDATE_DEVIS_FIELD_SUCCESS = "UPDATE_DEVIS_FIELD_SUCCESS";
export const UPDATE_DEVIS_FIELD_FAILED = "UPDATE_DEVIS_FIELD_FAILED";

export const CHANGE_FILTER_PROSPECT = "CHANGE_FILTER_PROSPECT";
export const CHANGE_FILTER_DATE = "CHANGE_FILTER_DATE";
export const ARCHIVE_PROSPECT_SUCCESS = "ARCHIVE_PROSPECT_SUCCESS";

export const ADD_PROSPECT_SUCCESS = "ADD_PROSPECT_SUCCESS";
export const ADD_PROSPECT_FAILED = "ADD_PROSPECT_FAILED";
export const LOADING_ADD_PROSPECT = "LOADING_ADD_PROSPECT";

export const UPDATE_FIELDS_PROSPECT = "UPDATE_FIELDS_PROSPECT";
