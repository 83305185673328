import Api from "../../Api/Api";
import {
  REMOVE_COMMANDE_SUCCESS,
  UPDATE_COMMANDE_SUCCESS,
  UPDATE_FIELDS_COMMANDE,
} from "../ActionsType/commandeTypes";
import {
  REMOVE_DEVIS_SUCCESS,
  UPDATE_DEVIS_SUCCESS,
  UPDATE_FIELDS_DEVIS,
} from "../ActionsType/devisTypes";
import {
  ADD_PROSPECT_FAILED,
  ADD_PROSPECT_SUCCESS,
  GET_PROSPECTS_SUCCESS,
  GET_PROSPECT_SUCCESS,
  LOADING_ADD_PROSPECT,
  LOADING_PROSPECT,
  REMOVE_PROSPECT_SUCCESS,
  SET_PROSPECT,
  UNSET_PROSPECT,
  UPDATE_DEVIS_FIELD_SUCCESS,
  UPDATE_FIELDS_PROSPECT,
  UPDATE_PROSPECT_FIELD_SUCCESS,
  UPDATE_PROSPECT_SUCCESS,
} from "../ActionsType/prospectTypes";
import { UPLOAD } from "../Graphql/Mutations/files";
import {
  ARCHIVE_PROSPECT,
  CREATE_PROSPECT,
  CREATE_PROSPECT_FIELD,
  UPDATE_PROSPECT_FIELD,
} from "../Graphql/Mutations/prospect";
import { GET_PROSPECT_BY_ID } from "../Graphql/Queries/Prospects";
import store from "../store/store";
import client from "./Client";
import { GetAllCommandes } from "./commande";
import { GetAllDevis } from "./devis";
import { closeSideBarCard } from "./general";
import { closeModal } from "./modal";
import { handleError, handleInfo } from "./toastMessage";

export const GetAllProspects =
  (page, status, sortProspect, search, selectedDate) => async (dispatch) => {
    const prospectArchived = store.getState().filtersReducer.prospectArchived;
    dispatch({
      type: LOADING_PROSPECT,
    });
    const organisation =
      store.getState().organisationsReducer.selectedOrganisation;

    if (!organisation.id) {
      return;
    }
    const dateParam = selectedDate ? `&date=${selectedDate}` : "";
    try {
      const { data } = await Api.get(
        `/api/fetchConditionaProspects?organisation=${
          organisation.id
        }&archived=${prospectArchived}&page=${page}&status=${status}&sortBy=${
          sortProspect.champs
        }&sortOrder=${
          sortProspect.asc === true ? "asc" : "desc"
        }&search=${search}${dateParam}`
      );
      dispatch({
        type: GET_PROSPECTS_SUCCESS,
        payload: {
          data: data.prospects.data,
          page: page,
        },
      });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };
export const GetProspectById =
  (id, isDevis = false) =>
  async (dispatch) => {
    try {
      const { data } = await client.query({
        query: GET_PROSPECT_BY_ID(id),
      });
      dispatch({
        type: isDevis ? "GET_ONE_DEVIS_SUCCESS" : GET_PROSPECT_SUCCESS,
        payload: data?.prospects?.data[0],
      });
    } catch (error) {}
  };

export const updateStatus = (id, status, from) => async (dispatch) => {
  try {
    const { data } = await Api.put(`/api/updateStatus/${id}`, status);
    if (
      ["DRAFT_COMMERCIAL", "DRAFT_SELLER", "DRAFT"].includes(status?.status)
    ) {
      dispatch({
        type: UPDATE_PROSPECT_SUCCESS,
        payload: { data: data.prospect, withGraph: false },
      });
    } else if (status?.status === "NEW_SELLER") {
      dispatch({
        type: UPDATE_DEVIS_SUCCESS,
        payload: { data: data.prospect, withGraph: false },
      });
    } else if (status?.status === "NEW") {
      dispatch({ type: REMOVE_PROSPECT_SUCCESS, payload: id });
      dispatch(unsetProspect());
      dispatch(closeSideBarCard());
    } else if (status?.status === "TO_VALIDATE") {
      dispatch({
        type: from ? REMOVE_PROSPECT_SUCCESS : REMOVE_DEVIS_SUCCESS,
        payload: id,
      });
    } else if (status?.status === "VALID") {
      dispatch({
        type: UPDATE_COMMANDE_SUCCESS,
        payload: { data: data.prospect, withGraph: false },
      });
    }
    dispatch(handleInfo("Mise à jour avec succès", 1, "dark-green"));
  } catch (error) {}
};
export const updateProspect = (id, newData, file, type) => async (dispatch) => {
  try {
    let media;
    let fileId;
    if (file) {
      media = await client.mutate({
        mutation: UPLOAD,
        variables: {
          file: file,
        },
      });
      fileId = media?.data?.upload?.data?.id;
    }
    const { data } = await Api.put(
      `/api/prospects/${id}?populate=prospect_fields.question,prospect_fields.form_answers,acteurs,document`,
      { data: { ...newData, document: fileId } }
    );
    dispatch({
      type:
        type === "devis"
          ? UPDATE_DEVIS_SUCCESS
          : type === "prospect"
          ? UPDATE_PROSPECT_SUCCESS
          : UPDATE_COMMANDE_SUCCESS,
      payload: { data: data.data, withGraph: true },
    });
    dispatch(closeModal());
    dispatch(closeSideBarCard());
    dispatch(handleInfo("Mise à jour avec succès", 1, "dark-green"));
  } catch (error) {}
};

export const newProspect = (formData, navigate) => async (dispatch) => {
  dispatch({ type: LOADING_ADD_PROSPECT });
  const organisation =
    store.getState().organisationsReducer.selectedOrganisation;
  const role = store.getState().roleReducer;
  if (!organisation.id) {
    return;
  }
  try {
    const questions = store.getState().formQuestionsReducer.questions;
    const user = store.getState().userReducer.user;

    let fieldsIds = [];
    const findAnswer = (question, answerValue) =>
      question.attributes.answers.data.find(
        (ans) => ans.attributes.answer === answerValue
      );

    for (const questionKey in formData) {
      if (formData.hasOwnProperty(questionKey)) {
        const answerValue = formData[questionKey];

        const question = questions.find(
          (q) => +q.id === parseInt(questionKey.split("-")[1])
        );
        if (question) {
          const inputType = question.attributes.input_type;

          if (["select", "radio"].includes(inputType)) {
            const answer = findAnswer(question, answerValue);
            if (answer) {
              const createdField = await client.mutate({
                mutation: CREATE_PROSPECT_FIELD,
                variables: {
                  question: parseInt(questionKey.split("-")[1]),
                  value: answer.attributes.answer,
                  form_answers: answer.id,
                },
              });
              fieldsIds.push(createdField.data.createProspectField.data.id);
            }
          } else if (inputType === "file") {
            let media;
            media = await client.mutate({
              mutation: UPLOAD,
              variables: {
                file: answerValue[0],
              },
            });
            const fileId = media?.data?.upload?.data?.id;

            const createdField = await client.mutate({
              mutation: CREATE_PROSPECT_FIELD,
              variables: {
                question: parseInt(questionKey.split("-")[1]),
                value: media?.data?.upload?.data.attributes.url,
                file: fileId,
              },
            });
            fieldsIds.push(createdField.data.createProspectField.data.id);
          } else if (["multi_select", "checkbox"].includes(inputType)) {
            let answerIds = [];
            let answerValues = [];
            answerValue.forEach((value) => {
              const answer = findAnswer(question, value);

              if (answer) {
                answerIds.push(answer.id);
                answerValues.push(answer.attributes.answer);
              }
            });
            const createdField = await client.mutate({
              mutation: CREATE_PROSPECT_FIELD,
              variables: {
                question: parseInt(questionKey.split("-")[1]),
                value: answerValues.join(", "),
                form_answers: answerIds,
              },
            });
            fieldsIds.push(createdField.data.createProspectField.data.id);
          } else {
            const createdField = await client.mutate({
              mutation: CREATE_PROSPECT_FIELD,
              variables: {
                question: parseInt(questionKey.split("-")[1]),
                value: answerValue.toString(),
              },
            });
            fieldsIds.push(createdField.data.createProspectField.data.id);
          }
        }
      }
    }

    const { data } = await client.mutate({
      mutation: CREATE_PROSPECT,
      variables: {
        origin: "INTERNAL",
        status: "DRAFT",
        user: user.id,
        organisation: organisation.id,
        prospect_fields: fieldsIds,
      },
    });
    const prosp = await client.query({
      query: GET_PROSPECT_BY_ID(data.createProspect.data.id),
    });
    if (role.role === 1) {
      dispatch({
        type: ADD_PROSPECT_SUCCESS,
        payload: prosp.data.prospects.data[0],
      });
    } else {
      dispatch({ type: ADD_PROSPECT_FAILED });
    }

    dispatch(handleInfo("Prospect créée", 1, "dark-green"));
    navigate(-1);
  } catch (error) {
    dispatch({ type: ADD_PROSPECT_FAILED });
  }
};

export const setProspect = (data) => async (dispatch) => {
  dispatch({
    type: SET_PROSPECT,
    payload: data,
  });
};
export const unsetProspect = () => async (dispatch) => {
  dispatch({
    type: UNSET_PROSPECT,
  });
};

export const updateProspectFields =
  (prospectId, newData, navigate) => async (dispatch, getState) => {
    try {
      let bodyData = newData;
      const actualRoute = getState().socketReducer.route;
      const { prospetsRoute, devisRoute, commandesRoute } =
        getState().organisationsReducer;
      for (const [key, value] of Object.entries(newData)) {
        if (value && value[0]?.name) {
          try {
            const media = await client.mutate({
              mutation: UPLOAD,
              variables: {
                file: value[0],
              },
            });

            bodyData[key] = media?.data?.upload?.data?.id;
          } catch (error) {
            console.error(`Error uploading file for key ${key}:`, error);
          }
        }
      }
      await Api.post(`/api/updateProspectFields/${prospectId}`, bodyData);
      const { data } = await client.query({
        query: GET_PROSPECT_BY_ID(prospectId),
      });

      if (`${devisRoute}/${prospectId}` === actualRoute) {
        dispatch({
          type: UPDATE_FIELDS_DEVIS,
          payload: data?.prospects?.data[0],
        });
        navigate(devisRoute);
      } else if (`${commandesRoute}/${prospectId}` === actualRoute) {
        dispatch({
          type: UPDATE_FIELDS_COMMANDE,
          payload: data?.prospects?.data[0],
        });

        navigate(commandesRoute);
      } else {
        dispatch({
          type: UPDATE_FIELDS_PROSPECT,
          payload: data?.prospects?.data[0],
        });
        navigate(prospetsRoute);
      }

      dispatch(handleInfo("Mise à jour avec succès", 1, "dark-green"));
    } catch (error) {}
  };
// DELETEME
export const updateProspectField =
  (prospectId, newData, navigate, route) => async (dispatch, getState) => {
    const { prospetsRoute, devisRoute, commandesRoute } =
      getState().organisationsReducer;
    try {
      const findAnswer = (question, answerValue) =>
        question.attributes.answers.data.find(
          (ans) => ans.attributes.answer === answerValue
        );
      const prospects = store.getState().prospectReducer.prospects;
      const devis = store.getState().devisReducer.devis;
      const commande = store.getState().commandeReducer.commandes;

      const oldProspectfields = route?.includes(devisRoute)
        ? devis.find((el) => +el.id === +prospectId)?.attributes
            ?.prospect_fields?.data
        : route?.includes(prospetsRoute)
        ? prospects.find((el) => +el.id === +prospectId)?.attributes
            ?.prospect_fields?.data
        : commande.find((el) => +el.id === +prospectId)?.attributes
            ?.prospect_fields?.data;
      for (const prospect of oldProspectfields) {
        const questionId = `question-${prospect.attributes.question.data.id}`;
        const targetValue = newData[questionId];
        const questionType =
          prospect.attributes.question.data.attributes.input_type;

        const question = prospect?.attributes?.question?.data;
        if (
          [
            "text",
            "textarea",
            "boolean",
            "number",
            "number_range",
            "departement",
            "date",
          ].includes(questionType) &&
          prospect.attributes.value !== targetValue
        ) {
          dispatch(
            updateFieldValue(prospectId, prospect?.id, targetValue, route)
          );
        } else if (["select", "radio"].includes(questionType)) {
          const answer = findAnswer(question, targetValue);
          if (answer) {
            dispatch(
              updateFieldFormQUestion(
                prospectId,
                prospect?.id,
                answer?.id,
                route
              )
            );
          }
        } else if (["multi_select", "checkbox"].includes(questionType)) {
          const answerIds = [];
          targetValue.forEach((value) => {
            const answer = findAnswer(question, value);

            if (answer) {
              answerIds.push(answer.id);
            }
          });
          dispatch(
            updateFieldFormQUestion(prospectId, prospect?.id, answerIds, route)
          );
        }
      }

      const { data } = await client.query({
        query: GET_PROSPECT_BY_ID(prospectId),
      });

      dispatch(handleInfo("Mise à jour avec succès", 1, "dark-green"));
      if (route?.includes("/devis")) {
        dispatch({
          type: UPDATE_FIELDS_DEVIS,
          payload: data?.prospects?.data[0],
        });
        navigate(devisRoute);
      } else if (route?.includes("/prospets")) {
        dispatch({
          type: UPDATE_FIELDS_PROSPECT,
          payload: data?.prospects?.data[0],
        });
        navigate(prospetsRoute);
      } else {
        dispatch({
          type: UPDATE_FIELDS_COMMANDE,
          payload: data?.prospects?.data[0],
        });
        navigate(commandesRoute);
      }
    } catch (error) {}
  };

const updateFieldValue = (prospectId, id, value, route) => async (dispatch) => {
  const { devisRoute } = store.getState().organisationsReducer;
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_PROSPECT_FIELD,
      variables: {
        id: id,
        value: value,
      },
    });
    route?.includes(devisRoute)
      ? dispatch({
          type: UPDATE_DEVIS_FIELD_SUCCESS,
          payload: { id: prospectId, newData: data.updateProspectField.data },
        })
      : dispatch({
          type: UPDATE_PROSPECT_FIELD_SUCCESS,
          payload: { id: prospectId, newData: data.updateProspectField.data },
        });
  } catch (error) {}
};
const updateFieldFormQUestion =
  (prospectId, id, value, route) => async (dispatch) => {
    const { devisRoute } = store.getState().organisationsReducer;

    try {
      const { data } = await client.mutate({
        mutation: UPDATE_PROSPECT_FIELD,
        variables: {
          id: id,
          form_answers: value,
        },
      });
      route?.includes(devisRoute)
        ? dispatch({
            type: UPDATE_DEVIS_FIELD_SUCCESS,
            payload: { id: prospectId, newData: data.updateProspectField.data },
          })
        : dispatch({
            type: UPDATE_PROSPECT_FIELD_SUCCESS,
            payload: { id: prospectId, newData: data.updateProspectField.data },
          });
    } catch (error) {}
  };

export const archiveMultipleProspect =
  (items, isArchived, type) => async (dispatch) => {
    try {
      await Api.post(`/api/toggleMultipleArchivesProspects`, {
        data: {
          items,
          isArchived,
          type,
        },
      });

      if (type === "prospects") {
        dispatch(GetAllProspects(1, [], { champs: "id", asc: false }, ""));
      }
      if (type === "devis") {
        dispatch(GetAllDevis(1, [], { champs: "id", asc: false }, ""));
      }
      if (type === "commandes") {
        dispatch(GetAllCommandes(1, [], { champs: "id", asc: false }, ""));
      }
    } catch (error) {}
  };

export const archiveProspect =
  (id, archived, note, type) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: ARCHIVE_PROSPECT,
        variables: {
          id: id,
          status:
            type === "prospect"
              ? "KO_DRAFT"
              : type === "devis"
              ? "KO_NEW"
              : "KO",
          archived: archived,
          note: note,
        },
      });
      dispatch({
        type:
          type === "prospect"
            ? REMOVE_PROSPECT_SUCCESS
            : type === "devis"
            ? REMOVE_DEVIS_SUCCESS
            : REMOVE_COMMANDE_SUCCESS,
        payload: id,
      });
      dispatch(closeModal());
      dispatch(closeSideBarCard());
    } catch (error) {}
  };

export const cancelProspect = (id, status) => async () => {
  try {
    await client.mutate({
      mutation: ARCHIVE_PROSPECT,
      variables: {
        id: id,
        status: status,
        archived: true,
      },
    });
  } catch (error) {}
};

export const exporterTous =
  (exportType, status, ids, prospectArchived, callback = () => {}) =>
  async (dispatch) => {
    const organisation =
      store.getState().organisationsReducer.selectedOrganisation;

    if (!organisation.id) {
      return;
    }
    try {
      dispatch({
        type: "EXPORT_LOADING",
      });

      const { data } = await Api.post(
        `/api/exportAsXl?organisation=${organisation.id}`,
        {
          type: exportType,
          status: status,
          ids: ids,
          archived: prospectArchived,
        }
      );
      dispatch({
        type: "EXPORT_SUCCESS",
      });
      dispatch(closeModal());
      callback(data?.path);
    } catch (error) {
      dispatch({
        type: "EXPORT_FAILED",
      });
    }
  };
