import { useDispatch, useSelector } from "react-redux";
import TicketTitle from "../../TicketCommonComponents/TicketTitle";
import LightDarkPlaceHolder from "../../../PlaceHolders/LightDarkPlaceHolder";
import SwitchItem from "../../../Layout/Notification/SwitchItem";
import LightDarkText from "../../../Common/LightDarkText";
import { togglePrivateMessage } from "../../../../REDUX/actions/messages";
import InfoQuestion from "../../../Common/InfoQuestion";
import { useEffect, useState } from "react";
import NotificationsRedPoint from "../../../Common/MenuIcons/NotificationsRedPoint";
import { SwitchIcon } from "../../../Common/LightDarkSvg";

const ChatHeader = () => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { ticket } = useSelector((state) => state.ticketsReducer);

  const { messagesLoading, showPrivateMessages } = useSelector(
    (state) => state.messagesReducer
  );
  const { isSupervisor, isOperator } = useSelector(
    (state) => state.roleReducer
  );
  const [canSendPrivate, setCanSendPrivate] = useState(false);
  useEffect(() => {
    setCanSendPrivate(isSupervisor || isOperator);
  }, [isSupervisor, isOperator]);

  const dispatch = useDispatch();

  return (
    <>
      <div className="d-flex align-items-center justify-content-between pt-4 pb-2">
        {messagesLoading ? (
          <LightDarkPlaceHolder customClass={"py-1 w70"} />
        ) : (
          <div className="d-flex align-items-center gap-2">
            <TicketTitle
              id={ticket?.id}
              title={ticket?.attributes?.name}
              textsize="custom-font-size"
            />
          </div>
        )}

        {canSendPrivate ? (
          <div
            className="alignH gap-2 mx-3 pointer"
            onClick={() => dispatch(togglePrivateMessage(!showPrivateMessages))}
          >
            <LightDarkText
              className={"bold custom-ticket-font-size "}
              color={mode === "light" ? "text-blue-grey" : "text-white"}
            >
              Public
            </LightDarkText>
            <SwitchIcon
              value={showPrivateMessages}
              fill2={showPrivateMessages ? "#ff7f7f" : null}
            />

            <LightDarkText
              className={"t-02 bold custom-ticket-font-size position-relative"}
              color={
                showPrivateMessages
                  ? "text-light-red"
                  : mode === "light"
                  ? "text-blue-grey"
                  : "text-white"
              }
            >
              Privée
              {ticket.attributes.privateNotifications && (
                <NotificationsRedPoint top={-2} right={-5} />
              )}
            </LightDarkText>
            <div className="question-mark">
              <InfoQuestion
                bgColor={showPrivateMessages ? "bg-light-red" : null}
                small
                htmlMessage={
                  "Discussion privée, échangez uniquement avec vos coloborateurs"
                }
                placement={"top"}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div
        className="my-2"
        style={{
          borderBottom: `2px solid ${
            mode === "light"
              ? showPrivateMessages
                ? "#fee2e2"
                : "#F8F9FF"
              : showPrivateMessages
              ? "#fee2e2"
              : "#252C45"
          }`,
        }}
      />
    </>
  );
};

export default ChatHeader;
