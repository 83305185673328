import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SocietyInformations from "../../Components/Societies/NewSociety/SocietyOnglets/SocietyInformations";
import SocietyCollaborateurs from "../../Components/Societies/NewSociety/SocietyOnglets/SocietyCollaborateurs";
import SocietyOperators from "../../Components/Societies/NewSociety/SocietyOnglets/SocietyOperators";
import SocietyItemRightButtons from "../../Components/Societies/NewSociety/SocietyItemRightButtons";
import {
  resetClientFilters,
  resetOperatorFilters,
} from "../../REDUX/actions/filters";
import SocietyLinkedSocieties from "../../Components/Societies/NewSociety/SocietyOnglets/SocietyLinkedSocieties";
import SocietyVerticalList from "../../Components/Societies/NewSociety/SocietyVerticalList";
import { useParams } from "react-router-dom";
import { getSocietyItem, getSocietyStats } from "../../REDUX/actions/societe";
import SocietyCalendar from "../../Components/Societies/NewSociety/SocietyOnglets/SocietyCalendar";
import SearchInput from "../../Components/Common/SearchInput";
import ListHeader from "../../Components/Common/ListHeader";
import MultiTabsMenu from "../../Components/Common/MultiTabsMenu";

const SocietyItemView = ({ isToggle, setIsToggle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { canSeeOperators, isClientAdmin, canSeeCollabs, isSupervisor } =
    useSelector((state) => state.roleReducer);

  const { canCreateCollabs, canCreateOperators } = useSelector(
    (state) => state.roleReducer
  );

  const [search, setSearch] = useState("");
  const SocietyTabs = [
    {
      title: "Informations",
      toggleValue: "overview",
      isVisible: true,
    },
    {
      title: "Collaborateurs",
      toggleValue: "collab",
      isVisible: canSeeCollabs || isClientAdmin,
    },
    {
      title: "Opérateurs",
      toggleValue: "ops",
      isVisible: canSeeOperators,
    },
    {
      title: "Sociétés liées",
      toggleValue: "linkedSocieties",
      isVisible: isClientAdmin || isSupervisor,
    },
    // {
    //   title: "Agenda",
    //   toggleValue: "agenda",
    //   isVisible: true,
    // },
  ];
  useEffect(() => {
    dispatch(getSocietyItem(params.societyId));
    dispatch(getSocietyStats(params.societyId));
  }, [params.societyId]);

  useEffect(() => {
    dispatch(resetClientFilters());
    dispatch(resetOperatorFilters());
  }, [isToggle]);

  return (
    <div className="d-flex gap-3 notScrollable h100">
      <div>
        <SocietyVerticalList />
      </div>
      <div className="d-flex flex-column gap-2  flex-1 notScrollable">
        <div className="row justify-content-between   m-0">
          <div className="col px-1">
            <MultiTabsMenu
              isToggle={isToggle}
              setIsToggle={setIsToggle}
              allTabs={SocietyTabs}
              align="start"
              px="px-4"
            />
          </div>
          {(isToggle === "collab" || isToggle === "ops") && (
            <div className="col px-1">
              <ListHeader
                showCollabsArchive={isToggle === "collab"}
                showOperatorsArchive={isToggle === "ops"}
                showSocityFilter={false}
              >
                <SearchInput
                  placeholder="ID, nom et prénom, email, téléphone..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  width={"w60"}
                />
              </ListHeader>
            </div>
          )}

          {((isToggle === "collab" && canCreateCollabs) ||
            (isToggle === "ops" && canCreateOperators) ||
            isToggle === "linkedSocieties") && (
            <div className="col-2 px-1">
              <SocietyItemRightButtons
                isToggle={isToggle}
                setSearch={setSearch}
              />
            </div>
          )}
        </div>
        <div
          className="w-100 notScrollable d-flex flex-column flex-1"
          style={{ height: "80vh" }}
        >
          {isToggle === "overview" && <SocietyInformations />}
          {isToggle === "collab" && <SocietyCollaborateurs search={search} />}
          {isToggle === "ops" && <SocietyOperators search={search} />}
          {isToggle === "linkedSocieties" && (
            <SocietyLinkedSocieties setIsToggle={setIsToggle} />
          )}
          {isToggle === "agenda" && <SocietyCalendar />}
        </div>
      </div>
    </div>
  );
};

export default SocietyItemView;
