import { gql } from "@apollo/client";

export const GET_AVATAR_USER = gql`
  query ($userId: ID) {
    avatars(filters: { user: { id: { eq: $userId } } }) {
      data {
        id
        attributes {
          image {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_AVATAR_SOCIETE = gql`
  query ($societeId: ID) {
    avatars(filters: { societe: { id: { eq: $societeId } } }) {
      data {
        id
        attributes {
          image {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_AVATAR_CALENDAR = gql`
  query ($calendarId: ID) {
    avatars(filters: { calendar: { id: { eq: $calendarId } } }) {
      data {
        id
        attributes {
          image {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const DELETE_AVATAR = gql`
  mutation ($id: ID!) {
    deleteAvatar(id: $id) {
      data {
        id
      }
    }
  }
`;
