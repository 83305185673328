import React from "react";
import LightDarkContainer from "../Common/LightDarkContainer";
import CountUp from "react-countup";
import LightDarkText from "../Common/LightDarkText";
import { useSelector } from "react-redux";

const StatBox = ({ unit, Icon, count, title }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <LightDarkContainer
      className="d-flex justify-content-start align-items-center px-2 radius05 w-100"
      colorLight="white"
      colorDark="dark-grey-bg"
      overviewContainer={true}
    >
      {Icon}
      <div className="ms-lg-4 ">
        <LightDarkText className={"bolder custom-bold-font-size text-start"}>
          <CountUp duration={1} end={count} />
          {unit}
        </LightDarkText>
        <LightDarkText
          className={"bold custom-font-size"}
          color={mode === "light" ? "text-blue-grey" : "text-light-blue-grey"}
        >
          {title}
        </LightDarkText>
      </div>
    </LightDarkContainer>
  );
};

export default StatBox;
