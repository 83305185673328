import { useSelector } from "react-redux";

import { Tickets } from "../../../Common/LightDarkSvg";
import LightDarkText from "../../../Common/LightDarkText";

import useWindowWidth from "../../../../Hooks/useWindowWidth";

const EventRelatedTicket = ({ ticket }) => {
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { ticketRoute } = useSelector((state) => state.organisationsReducer);

  return (
    <>
      {ticket && (
        <LightDarkText
          className={`${
            screenWidth > 1200 ? "f14" : "f12"
          } pointer clickable-text t-02 mt-4 text-start bold d-flex align-items-center`}
          onclickCallback={() => window.open(`${ticketRoute}/${ticket?.id}`)}
        >
          <Tickets
            darkColor={mode === "light" ? "#354074" : "#bbc5ee"}
            size={24}
            className="me-2"
          />
          {ticket?.attributes?.name} #{ticket?.id}
        </LightDarkText>
      )}
    </>
  );
};

export default EventRelatedTicket;
