import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { handleError } from "../../../REDUX/actions/toastMessage";
import { CloseIcon } from "../../Common/LightDarkSvg";
import LightDarkText from "../../Common/LightDarkText";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import logo from "../../../Images/Logo agilix.io.svg";

import "./Notification.css";

const NotificationPopup = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(true);

  const hideModal = () => {
    setIsOpen(false);
  };

  function askNotificationPermission() {
    if (!("Notification" in window)) {
      dispatch(
        handleError(
          "Votre navigateur ne prend pas en charge les notifications du bureau",
          1
        )
      );
    } else {
      Notification.requestPermission();
      hideModal();
    }
  }

  return (
    <Modal
      show={isOpen}
      onHide={hideModal}
      size="sm"
      style={{ overflowY: "hidden" }}
    >
      <div className="bg-dark-blue radius05 p-3">
        <div className={`d-flex gap-3 align-items-center mb-3`}>
          <img src={logo} />
          <div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <LightDarkText className={`text-start f14 bold text-white`}>
                ✌️ AGILIX.io
              </LightDarkText>
              <CloseIcon clickCallback={hideModal} dimension={23} />
            </div>

            <LightDarkText
              className={`text-start custom-ticket-font-size lh-base text-white`}
            >
              Recevez les notifications même <br /> lorsque vous n'êtes pas
              connecté <br />
              sur l'application
            </LightDarkText>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <ColoredButton
            textButton="Valider 🤩"
            color={"#0CCE91"}
            background="#0CCE9126"
            square
            // eslint-disable-next-line react/jsx-no-bind
            callback={askNotificationPermission}
          />
          <ColoredButton
            textButton="Refuser définitivement ☹️"
            background="#FF2C2C70"
            color="red"
            square
            // eslint-disable-next-line react/jsx-no-bind
            callback={askNotificationPermission}
          />
        </div>
      </div>
    </Modal>
  );
};
export default NotificationPopup;
