import React from "react";
import { useSelector } from "react-redux";
import { UserFormTabs } from "./userFormUtils";
import MultiTabsMenu from "../../Common/MultiTabsMenu";

const UserFormHeader = ({
  isToggle,
  setIsToggle,
  isNew,
  client,
  clientInputs,
  emailInfo,
}) => {
  const { user } = useSelector((state) => state.userReducer);
  const {
    canModifySupervisorPermissions,
    canModifyAdminPermissions,
    canCreateCollabs,
    canCreateOperators,
  } = useSelector((state) => state.roleReducer);

  const availableTabs = UserFormTabs(
    isNew,
    emailInfo,
    user,
    client,
    clientInputs?.role?.[0]?.value,
    canModifySupervisorPermissions,
    canCreateOperators,
    canModifyAdminPermissions,
    canCreateCollabs,
    clientInputs
  );
  return (
    <div className="alignH">
      <MultiTabsMenu
        isToggle={isToggle}
        setIsToggle={setIsToggle}
        allTabs={availableTabs}
        align="start"
      />
    </div>
  );
};

export default UserFormHeader;
