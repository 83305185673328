import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../REDUX/actions/user";
import useWindowWidth from "../../Hooks/useWindowWidth";

import logoIconColor from "../../Images/agilix-logo-nom.png";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import {
  DesktopIcon,
  LetterIcon,
  TabletteIcon,
} from "../../Components/Common/LightDarkSvg";

import "./LoginView.css";
import LightDarkText from "../../Components/Common/LightDarkText";
import { useParams } from "react-router-dom";
import { fetchLoginViewData } from "../../REDUX/actions/loginData";
import Loading from "../../Components/Common/Loading";
import LoginCopyRights from "../../Components/Common/LoginCopyRights";

const LoginView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { error } = useSelector((state) => state.userReducer);
  const { loginLogo, loginDataLoading, loginImageSelected } = useSelector(
    (state) => state.loginViewDataReducer
  );

  const screenWidth = useWindowWidth();

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    dispatch(fetchLoginViewData(params["*"]));
  }, []);

  const onFormSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return loginDataLoading ? (
    <Loading />
  ) : (
    <div
      className="loginContainer position-relative bg-light-mode"
      style={{
        backgroundImage: `url(${loginImageSelected})`,
      }}
    >
      {loginImageSelected.includes("mp4") ? (
        <div className="videoLoginContainer">
          <video autoPlay={true} loop={true} muted={true}>
            <source src={loginImageSelected} type="video/mp4" />
          </video>
        </div>
      ) : null}
      {screenWidth > 1000 ? (
        <div className="h100 leftSideForm px-3 bg-white">
          <div className="loginInner w-100 h100">
            <div className="directionC w-100 loginForm">
              <img
                className="align-self-center loginImage"
                src={loginLogo}
                alt="logo-icon"
              />
              <form onSubmit={onFormSubmit}>
                <div className="my-4">
                  <div className="custom-ticket-font-size pb-2 bold text-dark-blue text-start">
                    E-mail
                  </div>
                  <div className="input-content">
                    <div className="inputIcons">
                      <LetterIcon darkColor="#414A6E" />
                    </div>
                    <input
                      className="loginInput text-dark-blue px-3 custom-font-size bold"
                      type={"text"}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="my-4 directionC">
                  <div className="custom-ticket-font-size pb-2 bold text-dark-blue text-start">
                    Mot de passe
                  </div>
                  <div className="input-content align-items-center">
                    <div
                      className="inputIcons pointer"
                      onClick={() => setPasswordShown(!passwordShown)}
                    >
                      {passwordShown ? (
                        <i className="bi f24 text-light-message bi-eye-slash-fill"></i>
                      ) : (
                        <i className="bi f24 text-light-message bi-eye-fill"></i>
                      )}
                    </div>
                    <input
                      className="loginInput text-dark-blue px-3 custom-font-size bold"
                      type={passwordShown ? "text" : "password"}
                      onChange={(e) => setpassword(e.target.value)}
                    />
                  </div>
                  <a
                    className={
                      "text-light-blue bold custom-ticket-font-size forgottenPassword mt-2"
                    }
                    href={"/forgot-password"}
                  >
                    Mot de passe oublié ?
                  </a>
                </div>
                <div className="mt-4">
                  <ColoredButton
                    textButton={"Se Connecter"}
                    color="light-blue"
                    loginBtn
                  />
                </div>
                {error && (
                  <div className="mt-3">
                    <div
                      className={`alert alert-danger text-light-message regular custom-ticket-font-size`}
                      role="alert"
                    >
                      {!email
                        ? "E-mail est un champ obligatoire"
                        : !password
                        ? "Mot de passe est un champ obligatoire"
                        : error ===
                          "Your account has been blocked by an administrator"
                        ? "Votre compte a été bloqué"
                        : error === "Invalid identifier or password"
                        ? "Identifiant ou mot de passe invalide"
                        : "Erreur réseau"}
                    </div>
                  </div>
                )}
              </form>
            </div>
            <LoginCopyRights />
          </div>
        </div>
      ) : (
        <div className="directionC justify-content-center align-items-center h100">
          <div
            className={`bg-white radius05 ${
              screenWidth <= 780 ? "px-2 py-4 w80" : "p-4 w40"
            }`}
            style={{ opacity: "90%" }}
          >
            <img
              className="align-self-center mb-4"
              src={logoIconColor}
              alt="logo-icon"
              width={100}
            />
            <LightDarkText className="f16" color="text-dark-blue">
              Accessible sur ordinateur et iPad.
            </LightDarkText>
            <LightDarkText className="f16" color="text-dark-blue">
              Prochainement disponible sur mobile
            </LightDarkText>
            <br />
            <DesktopIcon className="mx-1" />
            <TabletteIcon className="mx-1" />
          </div>
          <div className="mt-2">
            <LoginCopyRights color="text-white" />
          </div>
        </div>
      )}

      {/* <p
        className="p-0 m-0 f12 text-center text-white imageText"
        style={{ position: "absolute", bottom: 10, right: 10, zIndex: 10 }}
      >
        Image générée par l'IA prochainement disponible sur Agilix.io
      </p> */}
    </div>
  );
};

export default LoginView;
