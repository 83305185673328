const initialState = {
  oneDriveLoading: false,
  oneDriveSynched: false,
  oneDriveFiles: [],
  oneDriveInitialFetch: false,
  sharePointSiteItem: {},
  sharePointSites: [],
  sharePointSiteFolders: [],
  sharePointSiteFiles: [],
  synchedServices: [],
  oneDriveFolders: [],
  externalServices: [],
  showExternalLoader: false,
  externalItems: [],
  copiedItems: [],
  externalLoaderTitle: "Importation de vos éléments...",
  oneDriveChildFiles: [],
  oneDriveChildFolders: [],
  fileInfo: {},
};

const externalServicesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_INITIAL_DATA":
      if (payload.userRole.data.attributes.external_services) {
        return {
          ...state,
          oneDriveSynched:
            payload.userRole.data.attributes.external_services.some(
              (element) => element.type.tag === "oneDrive"
            ),
          externalServices: payload.userRole.data.attributes.external_services,
        };
      } else {
        return { ...state };
      }
    case "ITEM_COPIED":
      return { ...state, copiedItems: [...state.copiedItems, payload] };

    case "ONE_DRIVE_LOADING":
      return { ...state, oneDriveLoading: true };
    case "FETCH_ONEDRIVE":
      return {
        ...state,
        oneDriveInitialFetch: true,
        oneDriveLoading: false,
        oneDriveFiles: payload.value.filter((el) => el.file),
        oneDriveFolders: payload.value.filter((el) => el.folder),
      };
    case "SYNCH_ERROR":
      return {
        ...state,
        [payload]: false,
        oneDriveLoading: false,
      };

    case "FETCH_SHAREPOINT":
      return {
        ...state,
        oneDriveLoading: false,
        sharePointSites: payload.value,
      };
    case "NO_SHAREPOINT":
      return {
        ...state,

        sharePointSites: [],
      };
    case "FETCH_ONEDRIVE_FOLDER":
      return {
        ...state,
        oneDriveLoading: false,
        oneDriveChildFiles: payload.value.filter((el) => el.file),
        oneDriveChildFolders: payload.value.filter((el) => el.folder),
        fileInfo: payload.fileInfo,
      };
    case "FETCH_SHAREPOINT_SITE":
      return {
        ...state,
        oneDriveLoading: false,
        sharePointSiteFiles: payload.filesInfo.filter((el) => el.file),
        sharePointSiteFolders: payload.filesInfo.filter((el) => el.folder),
        sharePointSiteItem: payload.siteInfo,
      };
    case "SHOW_EXTERNAL_LOADER":
      return {
        ...state,
        showExternalLoader: true,
        externalItems: payload.filesArray,
        externalLoaderTitle: payload.title,
        copiedItems: [],
      };

    case "HIDE_EXTERNAL_LOADER":
      return {
        ...state,
        showExternalLoader: false,
        externalItems: [],
        copiedItems: [],
      };
    default:
      return state;
  }
};

export default externalServicesReducer;
