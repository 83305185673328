import React from "react";
import { useSelector } from "react-redux";

const NotificationInvoice = ({ notification }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {notification?.facture?.data?.id && (
        <>
          {notification?.facture?.data?.attributes?.type === "Avoir"
            ? "Un nouvel avoir"
            : "Une nouvelle facture"}{" "}
          de la societé
          <span className="text-light-blue">
            {" "}
            #{notification?.societe?.data?.id}
          </span>
          <span
            className={`${
              mode === "light" ? "text-dark-blue" : "text-light-blue-grey"
            } `}
          >
            {` ${notification?.societe?.data?.attributes?.name} `}
          </span>
          a été ajouté
          {notification?.facture?.data?.attributes?.type !== "Avoir" ? "e" : ""}
          .
        </>
      )}
    </>
  );
};

export default NotificationInvoice;
