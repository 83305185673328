import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pdfjs } from "react-pdf";
import { donwloadFile, fileUrl } from "../../../Utils/utils";
import LightDarkText from "../../Common/LightDarkText";
import { openModal } from "../../../REDUX/actions/modal";
import PreviewModal from "../../Common/PreviewModal";
import { imageEndPoint } from "../../../Api/endPoint";
import PdfCard from "../../Cloud/PdfCard";
import ConditionalFileIcon from "../../Cloud/ConditionalFileIcon";

const FaqAttachementFiles = ({ message }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);

  const handlePreview = (file) => {
    const fileData = {
      url: fileUrl(file.attributes?.file?.data?.attributes?.url),
      name:file.attributes?.name || file.attributes?.file?.data?.attributes?.name,
      size: file.attributes?.file?.data?.attributes?.size,
      mime: file.attributes?.file?.data?.attributes?.mime,
      date: file.attributes?.file?.data?.attributes?.createdAt,
    };

    dispatch(
      openModal({
        type: "pdfFile",
        content: <PreviewModal fileData={fileData} />,
      })
    );
  };

  const getFilePreview = (file) => {
    const fileType = file?.attributes?.file?.data?.attributes?.mime;
    const fileUrl = `${imageEndPoint}${file?.attributes?.file?.data?.attributes?.url}`;

    if (fileType?.startsWith("image/")) {
      return (
        <img
          className="rounded-3 w-100 "
          src={fileUrl}
          alt={file?.attributes?.name}
          style={{
            height: "300px",
            objectFit: "contain",
          }}
        />
      );
    } else if (fileType === "application/pdf") {
      return <PdfCard file={file} />;
    } else {
      return <ConditionalFileIcon file={file} size={80} mode={mode} />;
    }
  };

  return (
    <div className="mt-4">
      <div className="d-flex flex-column gap-3">
        {message?.attributes?.fichiers?.data?.map((file) => (
          <div
            className="w-100"
            key={file.id}
            style={{ height: "400px" }}
          >
            <div
              className={`card h-100 ${mode === "light" ? "bg-light" : "bg-dark"}`}
              style={{
                cursor: "pointer",
                border: `1px solid ${mode === "light" ? "#dee2e6" : "#454d55"}`,
                borderRadius: "10px",
              }}
              onClick={() => {
                file?.attributes?.file?.data?.attributes?.mime?.includes(
                  "image"
                ) ||
                file?.attributes?.file?.data?.attributes?.mime?.includes("pdf")
                  ? handlePreview(file)
                  : donwloadFile(file);
              }}
            >
              <div className="card-body d-flex flex-column justify-content-center align-items-center p-4">
                <div className="text-center" style={{ maxWidth: "100%" }}>
                  {getFilePreview(file)}
                </div>
                <div className="mt-3 text-center">
                  <LightDarkText className="f16">
                    {file.attributes?.name ||
                      file.attributes?.file?.data?.attributes?.name}
                  </LightDarkText>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqAttachementFiles;
