import { useSelector } from "react-redux";

import Thumbnail from "../../Common/Thumbnail";
import LightDarkText from "../../Common/LightDarkText";
import { Spinner } from "react-bootstrap";

const CalendarName = ({
  id,
  name = "Agilix",
  icon,
  className,
  isSelectedCalendar,
  headerCalendars,
  searchList = false,
}) => {
  const { calendarSelectLoader } = useSelector(
    (state) => state.calendarsReducer
  );
  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <div
      className="d-flex align-items-center justify-content-between gap-3"
      style={{ minWidth: "max-content" }}
    >
      <Thumbnail
        loader={calendarSelectLoader.some((el) => el === id)}
        size={32}
        calendarIcon
        apiImg={icon?.data?.attributes?.image?.data?.attributes?.url}
      />

      <LightDarkText
        className={`${className} text-start`}
        color={
          mode === "light" &&
          (isSelectedCalendar || headerCalendars || searchList)
            ? "text-dark-blue"
            : mode === "light" &&
              (!isSelectedCalendar || !headerCalendars || !searchList)
            ? "text-blue-grey"
            : "text-white"
        }
      >
        {name}
      </LightDarkText>
    </div>
  );
};

export default CalendarName;
