import React, { useState } from "react";
import { useSelector } from "react-redux";
import SocietyPlanOverview from "./SocietyPlanOverview";
import MultipleBarChart from "../../../Charts/MultipleBarChart";
import { transformPlansData } from "../../../Charts/chartsUtils";
import TicketLineStats from "../../../Dashboard/TicketLineStats";
import InterventionsAndClicksLineStats from "../../../Dashboard/InterventionsAndClicksLineStats";
import SocietyDetails from "./SocietyDetails";
import SocietyNotes from "./SocietyNotes";
import MultiTabsMenu from "../../../Common/MultiTabsMenu";

const SocietyInformations = () => {
  const [isToggle, setIsToggle] = useState("details");

  const { societeStats } = useSelector((state) => state.societesReducer);
  const { isSupervisor, isClientAdmin } = useSelector(
    (state) => state.roleReducer
  );
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );

  const plansData = transformPlansData(societeStats.plansStats);

  const SocietyInfoTabs = [
    {
      title: "Détails",
      toggleValue: "details",
      isVisible: true,
    },
    {
      title: "Notes Internes",
      toggleValue: "internal_notes",
      isVisible: true,
    },
  ];
  return (
    <div className="d-flex h100 gap-2 notScrollable">
      <div className="w25 h100 border-3 notScrollable d-flex flex-column">
        <MultiTabsMenu
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          allTabs={SocietyInfoTabs}
          align="center"
        />

        <div className="scrollable bg-white flex-1">
          {isToggle === "details" ? <SocietyDetails /> : null}
          {isToggle === "internal_notes" ? <SocietyNotes /> : null}
        </div>
      </div>
      <div className="d-flex flex-column scrollable flex-1 gap-3">
        {(isSupervisor || isClientAdmin) &&
        selectedOrganisation.attributes.withForfait ? (
          <div>
            <SocietyPlanOverview plansData={plansData} />
          </div>
        ) : null}
        {(isSupervisor || isClientAdmin) &&
        selectedOrganisation.attributes.withForfait ? (
          <div className={`statisticsLine alignH w100`}>
            <div className="w-100">
              <MultipleBarChart
                dataSets={plansData}
                title="Forfait les 12 derniers mois"
                customToolTip={{
                  callbacks: {
                    label: function (context) {
                      const value = context.raw;
                      return ` ${value} heures`;
                    },
                  },
                }}
              />
            </div>
          </div>
        ) : null}
        <TicketLineStats stats={societeStats} />
        <InterventionsAndClicksLineStats stats={societeStats} />
      </div>
    </div>
  );
};

export default SocietyInformations;
