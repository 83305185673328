import React, { useState } from "react";
import ColoredButton from "../Common/Buttons/ColoredButton";
import LightDarkText from "../Common/LightDarkText";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon, SwitchIcon, ToggleStateIcon } from "../Common/LightDarkSvg";
import { closeModal } from "../../REDUX/actions/modal";

const types = [
  { id: 1, label: "Xlsx", value: "xlsx" },
  { id: 2, label: "CSV", value: "csv" },
];
const ExportModal = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const [exportType, setExportType] = useState("csv");
  const { loadingExport } = useSelector((state) => state.prospectReducer);
  return (
    <div className="p-2 mb-3 mt-2 d-flex flex-column ">
      <div className="d-flex justify-content-between">
        <LightDarkText className="text-center custom-title-font-size bold w-100 mt-2">
          Exporter
        </LightDarkText>
        <div
          className="justify-self-end"
          onClick={() => dispatch(closeModal())}
        >
          <CloseIcon />
        </div>
      </div>
      <LightDarkText
        className={
          "pointer fitContent f14  text-center w70 align-self-center mt-3"
        }
        color={mode === "light" ? "text-blue-grey" : "text-light-text"}
      >
        Veuillez choisir le format d'exportation
      </LightDarkText>
      <div className="w70    d-flex align-items-center justify-content-center mt-3 align-self-center">
        <LightDarkText className={"pointer fitContent f18 bold py-2"}>
          Xlsx
        </LightDarkText>
        <div
          className="px-3"
          onClick={() => setExportType(exportType === "csv" ? "xlsx" : "csv")}
        >
          <SwitchIcon value={exportType === "csv"} />
        </div>

        <LightDarkText className={"pointer fitContent f18 bold  py-2"}>
          Csv
        </LightDarkText>
        {/* {types.map(({ id, label, value }) => {
          return (
            <div
              className=" d-flex justify-content-between align-items-center  my-1"
              onClick={() => setExportType(value)}
            >
              <LightDarkText
                key={id}
                className={"pointer fitContent f18 bold text-start py-2"}
                
              >
                {label}
              </LightDarkText>
              {exportType === value ? <CheckIcon /> : null}
            </div>
          );
        })} */}
      </div>

      <div className="w40 mt-3 align-self-center">
        <ColoredButton
          textButton={"Exporter"}
          color="light-blue"
          callback={() => handleSubmit(exportType)}
          loading={loadingExport}
          disabled={!exportType}
        />
      </div>
    </div>
  );
};

export default ExportModal;
