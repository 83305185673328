import React from "react";
import { useSelector } from "react-redux";

const CopyIcon = ({
  color = "",
  width = "42",
  height = "42",
  className = "",
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const fill = color ? color : mode === "light" ? "#354074" : "#a9bcd3";
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 22.575V29.925C28 36.05 25.55 38.5 19.425 38.5H12.075C5.95 38.5 3.5 36.05 3.5 29.925V22.575C3.5 16.45 5.95 14 12.075 14H19.425C25.55 14 28 16.45 28 22.575Z"
        fill={fill}
      />
      <path
        d="M29.9251 3.5H22.5751C16.8944 3.5 14.3897 5.6225 14.0552 10.8004C14.0193 11.3562 14.4726 11.8125 15.0295 11.8125H19.4251C26.7751 11.8125 30.1876 15.225 30.1876 22.575V26.9706C30.1876 27.5275 30.6439 27.9808 31.1996 27.9449C36.3776 27.6104 38.5001 25.1057 38.5001 19.425V12.075C38.5001 5.95 36.0501 3.5 29.9251 3.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default CopyIcon;
