import React from "react";
import { useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import LightDarkInput from "../../Common/LightDarkInput";
import Uploader from "../../Common/Uploader";
import { validMail } from "../../../Utils/utils";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";

const SocietyInfosForm = ({
  setAvatar,
  avatar,
  currentImage,
  societyInputs,
  setsocietyInputs,
}) => {
  const { societyTypesValues } = useSelector(
    (state) => state.sharedDataReducer
  );
  return (
    <div className="mt-4 d-flex flex-column gap-3 scrollable">
      <div>
        <Uploader
          setState={setAvatar}
          state={avatar}
          id="image"
          name="image"
          placeholder="Ajouter une photo de profil"
          defaultImage={currentImage}
          squareUploader
        />
      </div>
      <div>
        <LightDarkInput
          placeholder={"Nom de la société"}
          label={"Nom de la société"}
          totalInputs={societyInputs}
          setValue={setsocietyInputs}
          champs="name"
          required
        />
      </div>
      <div className="alignH gap-2">
        <div className="flex-1">
          <LightDarkInput
            placeholder={"contact@societe.com"}
            label={"E-mail"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="email"
            required
          />
          {societyInputs.email && !validMail(societyInputs.email) && (
            <span className="field-error f11 position-absolute mt-1 w-100 text-red">
              Adresse mail non valide
            </span>
          )}
        </div>
        <div className="flex-1">
          <LightDarkInput
            placeholder={"contact@societe.com"}
            label={"E-mail de facturation"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="emailFacture"
            required
          />
          {societyInputs.emailFacture &&
            !validMail(societyInputs.emailFacture) && (
              <span className="field-error f11 position-absolute mt-1 w-100 text-red">
                Adresse mail non valide
              </span>
            )}
        </div>
      </div>
      <div className="alignH gap-2">
        <div className="flex-1">
          <LightDarkInput
            placeholder={"Siret / RCI"}
            label={"Siret / RCI"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="siret"
          />
        </div>
        <div className="flex-1">
          <LightDarkInput
            placeholder={"www.societe.com"}
            label={"Site internet"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="siteWeb"
          />
        </div>
      </div>
      <div className="alignH gap-2 position-relative">
        <div className="flex-1 ">
          <LightDarkInput
            placeholder={"Numéro de téléphone"}
            label={"Téléphone"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            value={societyInputs.phoneNumber}
            champs="phoneNumber"
            phoneInput
          />
          {societyInputs.phoneNumber &&
            (isValidPhoneNumber(societyInputs.phoneNumber) ? undefined : (
              <span
                className="field-error f11 position-absolute mt-1 text-red"
                style={{
                  minWidth: "max-content",
                }}
              >
                Numéro de téléphone non valide
              </span>
            ))}
        </div>
        {societyTypesValues.length !== 0 && (
          <div className="flex-1">
            <LighDarkMultiChoice
              label="Type"
              data={societyTypesValues}
              totalInputs={societyInputs}
              setValue={setsocietyInputs}
              multiple={false}
              champs="society_type"
              required
            />
          </div>
        )}
      </div>
      <div className="alignH gap-2">
        <div className="flex-1">
          <LightDarkInput
            placeholder={"83, avenue Lorem ipsum"}
            label={"Adresse"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="adress"
          />
        </div>
        <div className="flex-1">
          <LightDarkInput
            placeholder={"00000"}
            label={"Code postal"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="zipCode"
          />
        </div>
      </div>

      <div className="alignH gap-2">
        <div className="flex-1">
          <LightDarkInput
            placeholder={"Ville"}
            label={"Ville"}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs="city"
          />
        </div>
      </div>
    </div>
  );
};

export default SocietyInfosForm;
