import React from "react";

export const SocieteStatSvg = ({ fill = "grey" }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7507 4.64035L6.3207 2.45035C3.9307 1.28035 1.9707 2.47035 1.9707 5.09035V19.9303C1.9707 21.0703 2.9207 22.0003 4.0807 22.0003H11.5007C12.0507 22.0003 12.5007 21.5503 12.5007 21.0003V7.41035C12.5007 6.36035 11.7107 5.11035 10.7507 4.64035ZM8.9707 13.7503H5.5007C5.0907 13.7503 4.7507 13.4103 4.7507 13.0003C4.7507 12.5903 5.0907 12.2503 5.5007 12.2503H8.9707C9.3807 12.2503 9.7207 12.5903 9.7207 13.0003C9.7207 13.4103 9.3907 13.7503 8.9707 13.7503ZM8.9707 9.75035H5.5007C5.0907 9.75035 4.7507 9.41035 4.7507 9.00035C4.7507 8.59035 5.0907 8.25035 5.5007 8.25035H8.9707C9.3807 8.25035 9.7207 8.59035 9.7207 9.00035C9.7207 9.41035 9.3907 9.75035 8.9707 9.75035Z"
        fill={fill}
      />
      <path
        d="M22 18.0391V19.4991C22 20.8791 20.88 21.9991 19.5 21.9991H14.97C14.43 21.9991 14 21.5691 14 21.0291V18.8691C15.07 18.9991 16.2 18.6891 17.01 18.0391C17.69 18.5891 18.56 18.9191 19.51 18.9191C20.44 18.9191 21.31 18.5891 22 18.0391Z"
        fill={fill}
      />
      <path
        d="M22 15.0505V15.0605C21.92 16.3705 20.85 17.4205 19.51 17.4205C18.12 17.4205 17.01 16.2905 17.01 14.9205C17.01 16.4505 15.6 17.6805 14 17.3705V12.0005C14 11.3605 14.59 10.8805 15.22 11.0205L17.01 11.4205L17.49 11.5305L19.53 11.9905C20.02 12.0905 20.47 12.2605 20.86 12.5105C20.86 12.5205 20.87 12.5205 20.87 12.5205C20.97 12.5905 21.07 12.6705 21.16 12.7605C21.62 13.2205 21.92 13.8905 21.99 14.8705C21.99 14.9305 22 14.9905 22 15.0505Z"
        fill={fill}
      />
    </svg>
  );
};

export const UsersStatSvg = ({ fill1 = "grey", fill2 = "grey" }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
        fill={fill1}
      />
      <path
        d="M14.0809 14.1489C11.2909 12.2889 6.74094 12.2889 3.93094 14.1489C2.66094 14.9989 1.96094 16.1489 1.96094 17.3789C1.96094 18.6089 2.66094 19.7489 3.92094 20.5889C5.32094 21.5289 7.16094 21.9989 9.00094 21.9989C10.8409 21.9989 12.6809 21.5289 14.0809 20.5889C15.3409 19.7389 16.0409 18.5989 16.0409 17.3589C16.0309 16.1289 15.3409 14.9889 14.0809 14.1489Z"
        fill={fill1}
      />
      <path
        d="M19.9894 7.33815C20.1494 9.27815 18.7694 10.9781 16.8594 11.2081C16.8494 11.2081 16.8494 11.2081 16.8394 11.2081H16.8094C16.7494 11.2081 16.6894 11.2081 16.6394 11.2281C15.6694 11.2781 14.7794 10.9681 14.1094 10.3981C15.1394 9.47815 15.7294 8.09815 15.6094 6.59815C15.5394 5.78815 15.2594 5.04815 14.8394 4.41815C15.2194 4.22815 15.6594 4.10815 16.1094 4.06815C18.0694 3.89815 19.8194 5.35815 19.9894 7.33815Z"
        fill={fill2}
      />
      <path
        d="M21.9883 16.5904C21.9083 17.5604 21.2883 18.4004 20.2483 18.9704C19.2483 19.5204 17.9883 19.7804 16.7383 19.7504C17.4583 19.1004 17.8783 18.2904 17.9583 17.4304C18.0583 16.1904 17.4683 15.0004 16.2883 14.0504C15.6183 13.5204 14.8383 13.1004 13.9883 12.7904C16.1983 12.1504 18.9783 12.5804 20.6883 13.9604C21.6083 14.7004 22.0783 15.6304 21.9883 16.5904Z"
        fill={fill2}
      />
    </svg>
  );
};
export const ProspectsStatSvg = ({ fill1 = "grey", fill2 = "grey" }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 14C6.99016 14 2.91016 17.36 2.91016 21.5C2.91016 21.78 3.13016 22 3.41016 22H20.5902C20.8702 22 21.0902 21.78 21.0902 21.5C21.0902 17.36 17.0102 14 12.0002 14Z"
        fill={fill1}
      />
      <path
        d="M16.72 10.35L15.76 9.39C16.26 8.64 16.55 7.74 16.55 6.77C16.55 4.13 14.41 2 11.78 2C9.15 2 7 4.14 7 6.77C7 9.4 9.14 11.54 11.77 11.54C12.74 11.54 13.64 11.25 14.39 10.75L15.35 11.71C15.54 11.9 15.79 11.99 16.03 11.99C16.28 11.99 16.53 11.9 16.71 11.71C17.09 11.34 17.09 10.73 16.72 10.35Z"
        fill={fill2}
      />
    </svg>
  );
};
export const DevisStatSvg = ({ fill1 = "grey", fill2 = "grey" }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 2H6C3 2 2 3.79 2 6V7V21C2 21.83 2.94 22.3 3.6 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.29 22.29C8.68 22.68 9.32 22.68 9.71 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7ZM5.97 14.01C5.42 14.01 4.97 13.56 4.97 13.01C4.97 12.46 5.42 12.01 5.97 12.01C6.52 12.01 6.97 12.46 6.97 13.01C6.97 13.56 6.52 14.01 5.97 14.01ZM5.97 10.01C5.42 10.01 4.97 9.56 4.97 9.01C4.97 8.46 5.42 8.01 5.97 8.01C6.52 8.01 6.97 8.46 6.97 9.01C6.97 9.56 6.52 10.01 5.97 10.01ZM12 13.76H9C8.59 13.76 8.25 13.42 8.25 13.01C8.25 12.6 8.59 12.26 9 12.26H12C12.41 12.26 12.75 12.6 12.75 13.01C12.75 13.42 12.41 13.76 12 13.76ZM12 9.76H9C8.59 9.76 8.25 9.42 8.25 9.01C8.25 8.6 8.59 8.26 9 8.26H12C12.41 8.26 12.75 8.6 12.75 9.01C12.75 9.42 12.41 9.76 12 9.76Z"
        fill={fill1}
      />
      <path
        d="M18.01 2V3.5C18.67 3.5 19.3 3.77 19.76 4.22C20.24 4.71 20.5 5.34 20.5 6V8.42C20.5 9.16 20.17 9.5 19.42 9.5H17.5V4.01C17.5 3.73 17.73 3.5 18.01 3.5V2ZM18.01 2C16.9 2 16 2.9 16 4.01V11H19.42C21 11 22 10 22 8.42V6C22 4.9 21.55 3.9 20.83 3.17C20.1 2.45 19.11 2.01 18.01 2C18.02 2 18.01 2 18.01 2Z"
        fill={fill1}
      />
    </svg>
  );
};
export const CommandesStatSvg = ({ fill1 = "grey", fill2 = "grey" }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 22.5C17.2165 22.5 18 21.7165 18 20.75C18 19.7835 17.2165 19 16.25 19C15.2835 19 14.5 19.7835 14.5 20.75C14.5 21.7165 15.2835 22.5 16.25 22.5Z"
        fill={fill1}
      />
      <path
        d="M8.25 22.5C9.2165 22.5 10 21.7165 10 20.75C10 19.7835 9.2165 19 8.25 19C7.2835 19 6.5 19.7835 6.5 20.75C6.5 21.7165 7.2835 22.5 8.25 22.5Z"
        fill={fill1}
      />
      <path
        d="M4.84 3.94L4.64 6.39C4.6 6.86 4.97 7.25 5.44 7.25H20.75C21.17 7.25 21.52 6.93 21.55 6.51C21.68 4.74 20.33 3.3 18.56 3.3H6.27C6.17 2.86 5.97 2.44 5.66 2.09C5.16 1.56 4.46 1.25 3.74 1.25H2C1.59 1.25 1.25 1.59 1.25 2C1.25 2.41 1.59 2.75 2 2.75H3.74C4.05 2.75 4.34 2.88 4.55 3.1C4.76 3.33 4.86 3.63 4.84 3.94Z"
        fill={fill1}
      />
      <path
        d="M20.5101 8.75H5.17005C4.75005 8.75 4.41005 9.07 4.37005 9.48L4.01005 13.83C3.87005 15.54 5.21005 17 6.92005 17H18.0401C19.5401 17 20.8601 15.77 20.9701 14.27L21.3001 9.6C21.3401 9.14 20.9801 8.75 20.5101 8.75Z"
        fill={fill1}
      />
    </svg>
  );
};
