import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import useWindowWidth from "../../../Hooks/useWindowWidth";

import "./ColoredButton.css";

const ColoredButton = ({
  color,
  iconColor,
  iconSize,
  callback,
  textButton,
  disabled,
  icon = null,
  link = null,
  outlined,
  square,
  loginBtn,
  height = "40",
  alertBtn = false,
  background,
  border,
  iconSvg,
  loading,
}) => {
  const [hovered, sethovered] = useState(false);
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <button
      className={`w-100 alignC h${height} ${
        iconColor ? "" : border ? "px-3" : "px-3"
      } ${
        !square && !border && `border border-1 border-${color}`
      } radius05 bold custom-ticket-font-size  t-02 coloredButton ${
        loginBtn ? "loginButton" : "scaledColoredButton"
      }
      ${
        outlined
          ? `${
              hovered
                ? `bg-${color} text-white`
                : `bg-transparent text-${color}`
            } `
          : !square &&
            `${
              hovered && alertBtn && mode === "light"
                ? `bg-white text-${color}`
                : hovered && alertBtn && mode === "dark"
                ? `bg-dark-grey-bg text-${color}`
                : hovered
                ? `bg-transparent text-${color}`
                : `bg-${color} text-white`
            } `
      }
      
      `}
      style={{
        backgroundColor: `${square && `${background}`}`,
        border: `${
          border
            ? `1px solid ${mode === "light" ? "#F4F6FD" : "#252c45"}`
            : "none"
        }`,
        color: `${color}`,
      }}
      onClick={callback}
      disabled={disabled}
      onMouseEnter={() => sethovered(true)}
      onMouseLeave={() => sethovered(false)}
    >
      {icon ? (
        <i
          className={`${textButton ? "me-3" : ""} ${
            screenWidth < 1200 ? "f20" : "f22"
          }  bi bi-${icon}  ${
            iconColor &&
            `text-${iconColor} ${iconSize ? `f${iconSize}` : "f30"}`
          }`}
        ></i>
      ) : iconSvg ? (
        <div className="me-2" style={{ marginLeft: "-10px" }}>
          {iconSvg}
        </div>
      ) : null}
      {link ? (
        <a href={`${link}`} target="_blank" rel="noreferrer" className="w-100">
          {textButton}
        </a>
      ) : loading ? (
        <div
          className="spinner-border text-light"
          style={{ width: "25px", height: "25px" }}
        ></div>
      ) : (
        textButton
      )}
    </button>
  );
};

export default ColoredButton;
