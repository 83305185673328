import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInterviewsStats } from "../../REDUX/actions/stats";
import Loading from "../Common/Loading";
import LightDarkText from "../Common/LightDarkText";
import PieChart from "../Charts/PieChart";
import { transformticketsToStatsPie } from "../Charts/chartsUtils";
import StatBox from "./StatBox";
import { QuestionIcon } from "../Common/LightDarkSvg";

const StatisticsInterviews = () => {
  const dispatch = useDispatch();

  const { interviewStats, interviewStatsLoading } = useSelector(
    (state) => state.statsReducer
  );

  useEffect(() => {
    dispatch(getInterviewsStats());
  }, []);

  return interviewStatsLoading ? (
    <Loading showLoader />
  ) : (
    <div className="d-flex flex-column gap-3 scrollable">
      {interviewStats.map((model) => (
        <div className=" radius05 my-5" key={model.id}>
          <LightDarkText className={"xbold f32 mb-4"}>
            {model.name}
          </LightDarkText>
          <div className="my-3 alignH gap-3">
            <StatBox
              Icon={<QuestionIcon size={40} color={"#3365ff"} />}
              count={model.nbInterviews}
              title={"Sondages Crées"}
            />
            <StatBox
              Icon={<QuestionIcon size={40} color={"#00dbc8"} />}
              count={model.nbResponded}
              title={"Sondages répondus"}
            />
            <StatBox
              Icon={<QuestionIcon size={40} color={"#ff3a89"} />}
              count={model.nbNotResponded}
              title={"Sondage non répondus"}
            />
          </div>
          <div className="grid3">
            {model.questions.map((question, index) => {
              const stats = transformticketsToStatsPie(question.responses);
              return stats.length ? (
                <div key={index} className="w100 radius05">
                  <PieChart
                    title={question.question}
                    infos={stats}
                    tootltipLabel="réponses"
                    cutout="0%"
                    showInside
                  />
                </div>
              ) : null;
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatisticsInterviews;
