import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";
import Thumbnail from "../../../Common/Thumbnail";

import useWindowWidth from "../../../../Hooks/useWindowWidth";

const EventCalendar = ({
  calendar,
  eventSearch = false,
  eventsList = false,
  sideBar,
}) => {
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {calendar && (
        <div
          className={`d-flex align-items-center ${
            sideBar ? "gap-3" : "gap-2"
          } ${!eventSearch && !eventsList && "mt-4"}`}
        >
          <Thumbnail
            apiImg={
              calendar?.attributes?.societe?.data
                ? calendar?.attributes?.societe?.data?.attributes?.avatar?.data
                    ?.attributes?.image?.data?.attributes?.url
                : calendar?.attributes?.avatar?.data?.attributes?.image?.data
                    ?.attributes?.url
            }
            calendarIcon
            size={eventSearch || sideBar ? 32 : 24}
          />
          {!eventSearch && !eventsList && (
            <LightDarkText
              className={`${
                screenWidth > 1200 ? "f14" : "f12"
              } text-start bold`}
            >
              Ajouté sur {calendar?.attributes?.name}
            </LightDarkText>
          )}
        </div>
      )}
    </>
  );
};

export default EventCalendar;
