import { gql } from "@apollo/client";
import { EVENT_ATTRIBUTES } from "../Attributes/Events";
import { SHARED_USERS_ATTRIBUTES } from "../Attributes/cloud";
import { userAttributes } from "../general/userFields";

export const CREATE_CALENDAR = gql`
  mutation (
    $name: String
    $avatar: ID
    $owner: ID
    $societe: ID
    $sharedUsers: [ID]
    $orgId: ID!
  ) {
    createCalendar(
      data: {
        name: $name
        avatar: $avatar
        owner: $owner
        societe: $societe
        sharedUsers: $sharedUsers
        organisation: $orgId
      }
    ) {
      data {
        id
        attributes {
          name
          organisation {
            data {
              id
            }
          }
          owner {
            data {
              id
              attributes {
                ${userAttributes}
              }
            }
          }
          ${SHARED_USERS_ATTRIBUTES}
          societe {
            data {
              id
              attributes {
                name
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          avatar {
            data {
              id
              attributes {
                image {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_CALENDAR = gql`
  mutation ($id: ID!, $name: String, $societe: ID, $sharedUsers: [ID], $user: [ID], $orgId: ID!) {
    updateCalendar(
      id: $id
      data: { name: $name, societe: $societe, sharedUsers: $sharedUsers, organisation: $orgId }
    ) {
      data {
        id
        attributes {
          name
          owner {
            data {
              id
              attributes {
                ${userAttributes}
              }
            }
          }
          events ( 
            pagination: {page: 1, pageSize: 10000}
            filters: { 
              or: [
                { creator: { id: { in: $user } } } 
                { participants: { id : { in: $user } } } 
                { calendar: { owner: { id: { in: $user } } } }
              ]
            }) {
            data {
              id
              attributes {
                ${EVENT_ATTRIBUTES}
              }
            }
          }
          ${SHARED_USERS_ATTRIBUTES}
          societe {
            data {
              id
              attributes {
                name
                tickets (pagination: {page: 1, pageSize: 100}) {
                  data {
                    id
                    attributes {
                      name
                      archived
                    }
                  }
                }
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id 
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          avatar {
            data {
              id
              attributes {
                image {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_CALENDAR = (id) => gql`
mutation {
  deleteCalendar(id: ${id})
    {
      data {
        id
      }
    }
}
`;

export const CREATE_EVENT = gql`
  mutation ($orgId: ID!, $name: String, $facebook: String, $instagram: String, $twitter: String, $linkedIn: String, $tikTok: String, $global: String, $type: String, $startDate: Date, $endDate: Date, $startTime: Time, $endTime: Time, $place: String, $description: String, $color: String, $statut: Int, $recurrence: Int, $recurrenceEndDate: Date, $Reminder: Int, $calendar: ID, $creator: ID, $participants: [ID], $validatingUsers: [ID], $ticket: ID, $shareInTicket: Boolean, $fichiers: [ID], $facebookFiles: [ID], $instagramFiles: [ID], $linkedInFiles: [ID], $twitterFiles: [ID], $tiktokFiles: [ID], $globalFiles: [ID]) {
    createEvent(
      data: { 
        organisation: $orgId
        name: $name
        facebook: $facebook
        facebookFiles: $facebookFiles
        instagram: $instagram
        instagramFiles: $instagramFiles
        twitter: $twitter
        twitterFiles: $twitterFiles
        linkedIn: $linkedIn
        linkedInFiles: $linkedInFiles
        tikTok: $tikTok
        tiktokFiles: $tiktokFiles
        global: $global
        globalFiles: $globalFiles
        type: $type
        startDate: $startDate
        endDate: $endDate
        startTime: $startTime
        endTime: $endTime
        place: $place
        description: $description
        color: $color
        statut: $statut
        calendar: $calendar
        creator: $creator
        participants: $participants
        validatingUsers: $validatingUsers
        ticket: $ticket
        recurrence: $recurrence
        recurrenceEndDate: $recurrenceEndDate
        Reminder: $Reminder
        shareInTicket: $shareInTicket
        fichiers: $fichiers
      }
    ) 
    {
      data {
        id
        attributes {
          ${EVENT_ATTRIBUTES}
        }
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation (
    $id: ID!
    $name: String
    $facebook: String
    $instagram: String
    $twitter: String
    $linkedIn: String
    $tikTok: String
    $global: String
    $type: String
    $startTime: Time
    $endTime: Time
    $startDate: Date
    $endDate: Date,
    $place: String
    $description: String
    $color: String
    $statut: Int
    $recurrence: Int
    $recurrenceEndDate: Date
    $Reminder: Int
    $shareInTicket: Boolean
    $participants: [ID]
    $validatingUsers: [ID]
    $ticket: ID
    $fichiers: [ID]
    $facebookFiles: [ID]
    $instagramFiles: [ID]
    $linkedInFiles: [ID]
    $twitterFiles: [ID]
    $tiktokFiles: [ID]
    $globalFiles: [ID]
    $orgId: ID!
  ) {
    updateEvent(
      id: $id
      data: {
        organisation: $orgId
        name: $name
        facebook: $facebook
        facebookFiles: $facebookFiles
        instagram: $instagram
        instagramFiles: $instagramFiles
        twitter: $twitter
        twitterFiles: $twitterFiles
        linkedIn: $linkedIn
        linkedInFiles: $linkedInFiles
        tikTok: $tikTok
        tiktokFiles: $tiktokFiles
        global: $global
        globalFiles: $globalFiles
        type: $type
        startDate: $startDate
        endDate: $endDate
        startTime: $startTime
        endTime: $endTime
        place: $place
        description: $description
        color: $color
        statut: $statut
        recurrence: $recurrence
        recurrenceEndDate: $recurrenceEndDate
        participants: $participants
        validatingUsers: $validatingUsers
        Reminder: $Reminder
        shareInTicket: $shareInTicket
        ticket: $ticket
        fichiers: $fichiers
      }
    ) 
    {
      data {
        id
        attributes {
          ${EVENT_ATTRIBUTES}
        }
      }
    }
  }
`;

export const VALIDATE_EVENT = gql`
  mutation (
    $id: ID!
    $statut: Int
    $confirmedParticipants: [ID]
    $declinedParticipants: [ID]
    $orgId: ID!
  ) {
    updateEvent(
      id: $id
      data: {
        statut: $statut
        confirmedParticipants: $confirmedParticipants
        declinedParticipants: $declinedParticipants
      }
    ) 
    {
      data {
        id
        attributes {
          ${EVENT_ATTRIBUTES}
        }
      }
    }
  }
`;

export const DELETE_EVENT = (id) => gql`
mutation {
  deleteEvent(id: ${id})
    {
      data {
        id
      }
    }
}
`;
