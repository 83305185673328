import Api from "../../Api/Api";
import store from "../store/store";

export const getConditionalStats = (societies) => async (dispatch) => {
  dispatch({ type: "STATS_LOADING" });
  try {
    const organisation =
      store.getState().organisationsReducer.selectedOrganisation;

    const societiesIds = societies.map((el) => el.value);

    const data = await Api.get(
      `/api/users-permissions/user/getConditionalStats?organisation=${
        organisation.id
      }&societies=${societiesIds.length ? societiesIds : 0}`
    );
    dispatch({ type: "STATS_SUCCESS", payload: data?.data });
  } catch (error) {}
};

export const getHistoryLogStats = (duration) => async (dispatch) => {
  dispatch({ type: "HISTORY_STATS_LOADING" });
  try {
    const organisation =
      store.getState().organisationsReducer.selectedOrganisation;

    const data = await Api.get(
      `/api/history-logs/getHistoryLogsStats?organisation=${organisation.id}&duration=${duration}`
    );
    dispatch({ type: "HISTORY_STATS_SUCCESS", payload: data?.data });
  } catch (error) {}
};

export const getOperatorStats = (user) => async (dispatch) => {
  try {
    if (!user) return;
    const data = await Api(
      `/api/users-permissions/user/op-stats?userId=${user}`
    );

    dispatch({ type: "OPERATOR_STATS_SUCCESS", payload: data?.data });
  } catch (error) {}
};

export const getInterviewsStats = (duration) => async (dispatch) => {
  dispatch({ type: "INTERVIEW_STATS_LOADING" });
  try {
    const organisation =
      store.getState().organisationsReducer.selectedOrganisation;

    const data = await Api.get(
      `/api/getInterviewsStats?organisation=${organisation.id}&duration=${duration}`
    );
    dispatch({ type: "INTERVIEW_STATS_SUCCESS", payload: data?.data });
  } catch (error) {}
};

export const getProspectsStats = (duration) => async (dispatch) => {
  dispatch({ type: "PROSPECT_STATS_LOADING" });
  try {
    const organisation =
      store.getState().organisationsReducer.selectedOrganisation;

    const data = await Api.get(
      `/api/getProspectsStats?organisation=${organisation.id}&duration=${duration}`
    );

    dispatch({ type: "PROSPECT_STATS_SUCCESS", payload: data?.data });
  } catch (error) {}
};
