export const generateUserAdditionalFields = (
  organisationsAdditionalData,
  userAdditionalData,
  orgId
) => {
  return organisationsAdditionalData.map((orgAddData) => {
    return {
      name: orgAddData.attributes.title,
      label: orgAddData.attributes.title,
      value:
        (userAdditionalData || []).filter(
          (userAdd) =>
            userAdd.name === orgAddData.attributes.name &&
            userAdd.organisation === orgId.toString()
        )[0]?.value || "Non mentionné",
    };
  });
};
