import { FORM_QUESTION_ATTRIBUTES } from "./formQuestion";

export const FORM_CONDITION_ATTRIBUTES = `
answer

question{
    data{
        id
        attributes{
           ${FORM_QUESTION_ATTRIBUTES}

        }
    }
}
condition{
    data{
        id
        attributes{
           ${FORM_QUESTION_ATTRIBUTES}

        }
    }
}
`;
