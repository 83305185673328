import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OneDriveFoderCard from "./OneDriveFoderCard";
import { Spinner } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";
import oneDriveLogo from "../../../Images/apiIcons/oneDrive.svg";
import sharePointLogo from "../../../Images/apiIcons/sharePoint.svg";
import SelectCloudBox from "../SelectCloudBox";

const OneDriveFodlerList = ({ oneDriveFolders, siteId = null, search }) => {
  const { oneDriveLoading } = useSelector(
    (state) => state.externalServicesReducer
  );
  const [filteredFolders, setFilteredFolders] = useState(oneDriveFolders);
  useEffect(() => {
    setFilteredFolders(
      oneDriveFolders.filter((el) =>
        el.name?.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, oneDriveFolders]);
  const [selected, setselected] = useState([]);
  return (
    <div className="mt-5 position-relative">
      <LightDarkText className="custom-title-font-size mt-5  bold text-start alignH gap-2">
        <div
          className="d-flex gap-3 alignC"
          style={{ width: "20px", height: "30px" }}
        >
          {oneDriveLoading ? (
            <Spinner size="sm" />
          ) : (
            <img
              src={siteId ? sharePointLogo : oneDriveLogo}
              alt={"oneDrive"}
            />
          )}
        </div>
        Dossiers {siteId ? "SharePoint" : "OneDrive"}
      </LightDarkText>
      <SelectCloudBox
        key={3}
        selected={selected}
        setselected={setselected}
        allItems={oneDriveFolders.map((el) => {
          return {
            name: el.name,
            id: el.id,
          };
        })}
        type="oneDriveFolder"
        siteId={siteId}
      />
      <div className="d-flex flex-wrap gap-4 ps-1 pt-4">
        {filteredFolders.map((folder) => (
          <OneDriveFoderCard
            key={folder.id}
            folder={folder}
            selected={selected}
            setselected={setselected}
            siteId={siteId}
          />
        ))}
      </div>
    </div>
  );
};

export default OneDriveFodlerList;
