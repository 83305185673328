import { useSelector } from "react-redux";
import LightDarkText from "../../Common/LightDarkText";

const TicketTitle = ({
  id,
  title,
  className,
  color,
  textsize,
  notRead = false,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { ticket } = useSelector((state) => state.ticketsReducer);

  const { showPrivateMessages } = useSelector((state) => state.messagesReducer);
  const textColor = color
    ? color
    : showPrivateMessages && ticket.id === id
    ? "text-light-red"
    : mode === "light"
    ? "text-blue-grey"
    : "text-light-text";

  return (
    <LightDarkText
      className={`t-02 ${textsize} d-flex gap-1 ${className} bolder text-start`}
      color={
        showPrivateMessages && ticket.id === id
          ? "text-light-red"
          : "text-light-blue"
      }
    >
      #{id}
      <span className={`bold t-02 ${textColor} ${notRead && "xbold"}`}>
        | {title}
      </span>
    </LightDarkText>
  );
};

export default TicketTitle;
