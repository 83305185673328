import React from "react";
import { useSelector } from "react-redux";
import NotificationUserName from "./NotificationUserName";

const NotificationCloudItemName = ({ notification }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {notification?.type === "File" ? "Le fichier " : "Le dossier "}
      <span
        className={`${
          mode === "light" ? "text-dark-blue" : "text-light-blue-grey"
        }`}
      >
        {notification?.type === "File"
          ? notification?.fichier?.data?.attributes?.file?.data?.attributes
              ?.name
          : notification?.folder?.data?.attributes?.name}{" "}
      </span>
      {notification?.type === "Folder_update"
        ? "a été mis à jour"
        : "a été partagé avec vous par"}
      {notification?.type !== "Folder_update" && (
        <NotificationUserName notification={notification} />
      )}
    </>
  );
};

export default NotificationCloudItemName;
