export const userModel = {
  password: "",
  password2: "",
  devices: [],
  prestations: [],
  SocietiesUnderResponsability: [],
  lastName: "",
  firstName: "",
  email: "",
  adress: "",
  city: "",
  zipCode: "",
  birthDate: "",
  phoneNumber: "",
  poste: [],
  role: [],
  departements: [],
  preferredLanguage: [],
  genre: [],
  workIn: [],
};
