import moment from "moment";

import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkText from "../../../../Common/LightDarkText";
import { Calendar } from "../../../../Common/LightDarkSvg";

const InterventionCreationDate = ({ createdAt }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          <LightDarkText className="custom-ticket-font-size py-1 text-white">
            Date de création
          </LightDarkText>
        </Tooltip>
      }
    >
      <div className="alignH gap-2 custom-ticket-font-size text-start">
        <Calendar darkColor="#59638b" />
        <LightDarkText
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          {moment(createdAt).format("DD.MM.YYYY")}
        </LightDarkText>
      </div>
    </OverlayTrigger>
  );
};

export default InterventionCreationDate;
