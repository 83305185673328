import React, { useState } from "react";
import LightDarkContainer from "../../../Common/LightDarkContainer";
import LightDarkText from "../../../Common/LightDarkText";
import {
  handleFirstLastName,
  showPoste,
  showRoleIdByUser,
} from "../../../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import TooltipActions from "../../../Common/Tooltip/TooltipActions";
import {
  DeleteIcon,
  EditIcon,
  LockPasswordIcon,
  SharedFolder,
} from "../../../Common/LightDarkSvg";
import { openConfirmationModal } from "../../../../REDUX/actions/confirmationModal";
import { deleteSocietyNote } from "../../../../REDUX/actions/societe";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SocietyNoteItem = ({
  note,
  setNewNote,
  setEditedNoteId,
  setShowNewNoteForm,
  visibleToData,
}) => {
  const dispatch = useDispatch();

  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const { societe } = useSelector((state) => state.societesReducer);
  const { user } = useSelector((state) => state.userReducer);

  const [showTooltip, setshowTooltip] = useState(false);

  const tooltipData = [
    {
      id: 1,
      text: "Modifier",
      icon: <EditIcon className="me-2" />,
      action: () => {
        setEditedNoteId(note.id);
        setShowNewNoteForm(true);
        setNewNote({
          title: note.attributes.title,
          content: note.attributes.content,
          visibleTo: note.attributes.showToMeOnly
            ? []
            : visibleToData.filter((el) => note.attributes[el.field]),
        });
        setshowTooltip(false);
      },
      condition: +note.attributes.creator.data.id === +user.id,
    },

    {
      id: 3,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(deleteSocietyNote(note.id));
            },
            message: `Voulez vous vraiment supprimer la note?`,
            confirmButtonText: "Je confirme la suppression",
          })
        );
        setshowTooltip(false);
      },
      condition: +note.attributes.creator.data.id === +user.id,
    },
  ];
  return (
    <div key={note.id} className="d-flex gap-3">
      <div className="flex-1">
        <LightDarkContainer className="f12 radius05 text-start p-2 bold">
          <div>
            <div className="alignH justify-content-between mb-2">
              <LightDarkText className={"xbold "}>
                {note.attributes.title}
              </LightDarkText>
              {+note.attributes.creator.data.id === +user.id ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip style={{ zIndex: `400` }}>
                      {note.attributes.showToMeOnly ? (
                        <LightDarkText className="custom-ticket-font-size py-1 text-white">
                          Moi Uniquement
                        </LightDarkText>
                      ) : (
                        <LightDarkText className="custom-ticket-font-size py-1 text-white">
                          Partagée avec{" "}
                          {note.attributes.showToOperators && (
                            <div>
                              {`les opérateurs de ${selectedOrganisation.attributes.name}`}
                              <br />
                            </div>
                          )}
                          {note.attributes.showToCollaborators && (
                            <div>
                              {`les collaborateurs de ${societe.attributes.name}`}
                              <br />
                            </div>
                          )}
                          {note.attributes.showToLectors && (
                            <div>
                              les invités
                              <br />
                            </div>
                          )}
                        </LightDarkText>
                      )}
                    </Tooltip>
                  }
                >
                  <div>
                    {note.attributes.showToMeOnly ? (
                      <LockPasswordIcon size="20" darkColor={"#A9BCD3"} />
                    ) : (
                      <SharedFolder />
                    )}
                  </div>
                </OverlayTrigger>
              ) : null}
            </div>

            <LightDarkText
              insertHtml={note.attributes.content?.replace(/\n/g, "<br />")}
            ></LightDarkText>
          </div>
        </LightDarkContainer>
        <LightDarkText
          color="text-light-text"
          className="f10 mt-1 text-start px-1"
        >
          {handleFirstLastName(note.attributes.creator.data)} -{" "}
          {showRoleIdByUser(note.attributes.creator.data.attributes) <= 2 ? (
            <span className="bolder text-light-blue">
              {selectedOrganisation?.attributes?.name}
            </span>
          ) : (
            societe.attributes.name
          )}{" "}
          - {showPoste(note.attributes.creator.data.attributes)}
        </LightDarkText>
      </div>

      <TooltipActions
        setshowTooltip={setshowTooltip}
        showTooltip={showTooltip}
        tooltipData={tooltipData}
        isVertical
        left
      />
    </div>
  );
};

export default SocietyNoteItem;
