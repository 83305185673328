export const handleInfo = (message, type, color) => async (dispatch) => {
  dispatch({ type: "HIDE_INFO" });
  dispatch({
    type: type ? "SHOW_INFO" : "HIDE_INFO",
    payload: { message: message, color: color },
  });
};

export const handleError =
  (message = "Erreur réseau", type = 1) =>
  async (dispatch) => {
    dispatch({ type: "HIDE_ERROR" });

    dispatch({
      type: type ? "SHOW_ERROR" : "HIDE_ERROR",
      payload: message,
    });
  };

export const closeNotification = () => async (dispatch) => {
  dispatch({
    type: "CLOSE_NOTIFICATION",
  });
};
