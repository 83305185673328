import LightDarkContainer from "../../Common/LightDarkContainer";
import MenuTabs from "../../Common/MenuTabs";
import { SocietyFormTabs } from "./societyFormUtils";

const SocietyFormHeader = ({ isToggle, currentStep, isLinked }) => {
  const activeLinkedTab = "link_societies";

  const getTabStyle = (index, toggleValue) => {
    if (isLinked) {
      return toggleValue === activeLinkedTab ? {} : { opacity: 0.5 };
    }

    if (index > currentStep) {
      return { opacity: 0.5, cursor: "not-allowed" };
    }
    if (index < currentStep) {
      return { opacity: 0.7 };
    }
    return {};
  };

  return (
    <div
      className={`d-flex justify-content-between align-items-center tabRadius t-02 bg-white`}
      style={{ height: "50px" }}
    >
      <LightDarkContainer
        colorLight="white"
        colorDark="dark-grey-bg"
        className="d-flex justify-content-start px-4 w-100 gap-4 align-items-center menuHeaderTabs"
        style={{ whiteSpace: "nowrap" }}
      >
        {SocietyFormTabs().map((tab, index) => (
          <div
            key={tab.toggleValue}
            style={getTabStyle(index, tab.toggleValue)}
          >
            <MenuTabs
              tabTitle={tab.title}
              toggleValue={tab.toggleValue}
              isToggle={isToggle}
              isVisible={tab.isVisible}
              disabled={isLinked && tab.toggleValue !== activeLinkedTab}
            />
          </div>
        ))}
      </LightDarkContainer>
    </div>
  );
};

export default SocietyFormHeader;
