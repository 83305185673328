const colors = [
  {
    value: 1,
    label: "#3365FF",
  },
  {
    value: 2,
    label: "#0CCE91",
  },
  {
    value: 3,
    label: "#FF5D9E",
  },
  {
    value: 4,
    label: "#17DCCD",
  },
  {
    value: 5,
    label: "#FFBC20",
  },
  {
    value: 6,
    label: "#FF721B",
  },
  {
    value: 7,
    label: "#FF2C2C",
  },
  {
    value: 9,
    label: "#7E25FF",
  },
  {
    value: 9,
    label: "#354074",
  },
];
export default colors;
