import React, { useEffect, useState } from "react";
import LightDarkContainer from "../Common/LightDarkContainer";
import { CloseIcon, CopyAgilix } from "../Common/LightDarkSvg";
import TransparentButton from "../Common/Buttons/TransparentButton";
import { useDispatch } from "react-redux";
import {
  copyFileInAgilix,
  downloadMultipleFiles,
  copyFolderInAgilix,
  downloadMultipleOneDriveFolders,
} from "../../REDUX/actions/externalServices";
import { deleteMultipleFiles } from "../../REDUX/actions/files";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";

const SelectCloudBox = ({
  selected,
  setselected,
  allItems,
  type,
  siteId = null,
}) => {
  const dispatch = useDispatch();
  const [allSelected, setallSelected] = useState(
    selected.length === allItems.length
  );

  useEffect(() => {
    setallSelected(selected.length === allItems.length);
  }, [selected, allItems]);

  const handleDeleteMultiple = () => {
    dispatch(
      openConfirmationModal({
        callBack: () =>
          dispatch(deleteMultipleFiles(selected)).then(() => setselected([])),
        icon: "archive",
        message: "Voulez vous vraiment Supprimer les fichiers séléctionnés",
        message2: `${selected.length} fichiers`,
        confirmButtonText: "Supprimer le fichier",
      })
    );
  };

  const handleSelectAll = () => {
    if (allItems.length === selected.length) {
      setselected([]);
      setallSelected(false);
    } else {
      setselected(allItems);
      setallSelected(true);
    }
  };

  const buttons = [
    {
      id: 1,
      callBack: () => {
        dispatch(downloadMultipleFiles(selected));
        setselected([]);
      },
      texte: "Télécharger",
      visibleOn: ["agilix", "oneDriveFile"],
      icon: null,
      remove: false,
    },
    {
      id: 6,
      callBack: () => {
        dispatch(downloadMultipleOneDriveFolders(selected, siteId));
        setselected([]);
      },
      texte: "Télécharger",
      visibleOn: ["oneDriveFolder"],
      icon: null,
      remove: false,
    },
    {
      id: 2,
      callBack: () => {
        dispatch(copyFileInAgilix(selected));
        setselected([]);
      },
      texte: "Copier dans Agilix", //files
      visibleOn: ["oneDriveFile"],
      icon: <CopyAgilix className="me-2" />,
      remove: false,
    },
    {
      id: 3,
      callBack: handleDeleteMultiple,
      texte: "Supprimer",
      visibleOn: ["agilix"],
      icon: null,
      remove: true,
    },
    {
      id: 4,
      callBack: () => {
        dispatch(copyFolderInAgilix(selected));
        setselected([]);
      },
      texte: "Copier dans Agilix", //folders ,
      visibleOn: ["oneDriveFolder"],
      icon: <CopyAgilix className="me-2" />,
      remove: false,
    },

    {
      id: 5,
      callBack: () => {
        handleSelectAll();
      },
      texte: allSelected ? "Désélectionner tous" : "Sélectionner tous",
      visibleOn: ["all"],
      icon: null,
      remove: false,
    },
  ];

  return selected.length ? (
    <div className="cloudActionsMenu w-100">
      <LightDarkContainer
        colorLight="white"
        className={"cloudActionsMenuInner alignH  p-4 w65 radius05"}
      >
        <div className="alignH flex-1">
          {buttons.map((button) =>
            button.visibleOn.includes(type) ||
            button.visibleOn.includes("all") ? (
              <TransparentButton
                key={button.id}
                remove={button.remove}
                callback={button.callBack}
              >
                {button.icon} <div>{button.texte}</div>{" "}
              </TransparentButton>
            ) : null
          )}
        </div>
        <div className="alignH gap-3">
          <div className="f12 bold ">
            {selected.length} éléments séléctionnés{" "}
          </div>
          <div
            className="d-flex justify-content-end "
            onClick={() => setselected([])}
          >
            <CloseIcon fill="#354074" />
          </div>
        </div>
      </LightDarkContainer>
    </div>
  ) : null;
};

export default SelectCloudBox;
