import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSocietyCollaborateurs } from "../../../../REDUX/actions/societe";
import UsersSquareList from "../../../Users/UsersSquareList";
import UsersSquareListPlaceHolder from "../../../PlaceHolders/Users/UsersSquareListPlaceHolder";

const SocietyCollaborateurs = ({ search }) => {
  const dispatch = useDispatch();
  const { clientArchived } = useSelector((state) => state.filtersReducer);
  const { societyCollaborators, societyItemLoading, societe } = useSelector(
    (state) => state.societesReducer
  );

  useEffect(() => {
    if (societe) {
      dispatch(getSocietyCollaborateurs(false));
    }
  }, [clientArchived, societe.id]);
  const [filteredUsers, setFilteredUsers] = useState(societyCollaborators);

  useEffect(() => {
    setFilteredUsers(
      societyCollaborators.filter(
        (el) =>
          el.attributes.user.data.attributes.firstName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          el.attributes.user.data.attributes.lastName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          el.attributes.user.data.id?.toString()?.includes(search) ||
          el.attributes.user.data.attributes.email
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          el.attributes.user.data.attributes.phoneNumber
            ?.toLowerCase()
            .includes(search.toLowerCase())
      )
    );
  }, [search, societyCollaborators, societyCollaborators.length, societe]);

  return societyItemLoading ? (
    <UsersSquareListPlaceHolder />
  ) : (
    <UsersSquareList listOfUserOrgs={filteredUsers} type="client" />
  );
};

export default SocietyCollaborateurs;
