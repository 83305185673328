import { societyModel } from "../../Models/society";

const initialState = {
  societes: [],
  societe: null,
  societeLoading: true,
  societyCollaborators: [],
  SocietyOperators: [],
  SocietyLinkedSocieties: [],
  societySideBar: false,
  societeStats: {},
  societyItemLoading: false,
  societyFormLoading: false,
  societyForm: societyModel,
  societyCalendars: [],
  societyNotes: [],
};

const societesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_SOCIETE":
      return { ...state, societes: payload, societeLoading: false };
    case "SOCIETE_ITEM_LOADING":
      return { ...state, societyItemLoading: true };
    case "SOCIETE_FROM_LOADING":
      return { ...state, societyFormLoading: true };
    case "FETCH_SOCIETE_FORM":
      return { ...state, societyFormLoading: false, societyForm: payload };
    case "RESET_SIDE_BAR_FORM":
      return { ...state, societyFormLoading: false, societyForm: societyModel };
    case "FETCH_NEW_COMPANY":
      return { ...state, societes: [payload, ...state.societes] };
    case "FETCH_SOCIETE_ITEM":
      return {
        ...state,
        societe: payload,
        societyItemLoading: false,
      };
    case "FETCH_SOCIETE_COLLABORATORS":
      return {
        ...state,
        societyCollaborators: payload,
        societyItemLoading: false,
      };
    case "FETCH_SOCIETE_NOTES":
      return {
        ...state,
        societyNotes: payload,
        societyItemLoading: false,
      };
    case "FETCH_SOCIETES_CALENDARS":
      return {
        ...state,
        societyCalendars: payload,
        societyItemLoading: false,
      };
    case "FETCH_SOCIETE_OPERATORS":
      return {
        ...state,
        SocietyOperators: payload,
        societyItemLoading: false,
      };
    case "FETCH_LINKED_SOCIETIES":
      return {
        ...state,
        SocietyLinkedSocieties: payload,
        societyItemLoading: false,
      };
    case "SOCIETY_CREATED": {
      if (!payload) {
        return {
          ...state,
          societeLoading: false,
        };
      }

      return {
        ...state,
        societes: [payload, ...state.societes],
        societeLoading: false,
      };
    }
    case "SOCIETE_UPDATED":
      return {
        ...state,
        societes: state.societes.map((el) =>
          el.id === payload.id ? payload : el
        ),
        societe: state.societe?.id === payload.id ? payload : state.societe,
        societeLoading: false,
      };
    case "DELETE_SOCIETE":
      return {
        ...state,
        societes: state.societes.filter((item) => item.id !== payload),
        societeLoading: false,
      };
    case "SOCIETE_LOADING":
      return { ...state, societeLoading: true };
    case "SOCIETE_STATS_SUCCESS":
      return { ...state, societeStats: payload, societyItemLoading: false };
    default:
      return state;
  }
};
export default societesReducer;
