import { gql } from "@apollo/client";
import { ATTRIBUTES_FILES, ATTRIBUTES_FOLDER } from "../Attributes/cloud";

export const GET_FOLDER = gql`
  query ($id: ID!,$orgId: ID!) {
    folder(id: $id) {
      data {
        id
        attributes {
            ${ATTRIBUTES_FOLDER}
        }
      }
    }
  }
`;

export const GET_FILE = gql`
  query ($id: ID!,$orgId: ID!) {
    fichier(id: $id) {
      data {
        id
        attributes {
            ${ATTRIBUTES_FILES}
        }
      }
    }
  }
`;

// export const GET_ATTACHEMENTS = (filters) => gql`
//   query($page:Int, $pageSize: Int, $sort: String,$orgId: ID!) {
//     fichiers(filters:${filters}, sort:[$sort], pagination:{pageSize:$pageSize ,page:$page}) {
//       data {
//         id
//         attributes {
//           ${ATTRIBUTES_FILES}
//           ticket {
//             data {
//               id
//               attributes {
//                 name
//                 societe {
//                   data {
//                     id
//                     attributes {
//                       name
//                     }
//                   }
//                 }
//               }
//             }
//           }
//           message {
//             data {
//               id
//               attributes {
//                 is_internal
//               }
//             }
//           }
//         }
//       }
//       meta {
//         pagination {
//           total
//           pageSize
//           page
//         }
//       }
//     }
//   }
// `;

export const GET_SHARED_FILES = gql`
  query ($userId: ID!) {
    fichiers(filters: {sharedUsers: {id: {eq: $userId}}}) {
      data {
        id
        attributes {
          ${ATTRIBUTES_FILES}
        }
      }
    }
  }
`;
