import React from "react";
import "./Form.css";
const Separator = ({ callback }) => {
  return (
    <div className="separator separatorLight mt-3 position-relative">
      <div className="absolute-center">
        <div
          className="addIcon  bg-light-gray radius05 pointer"
          onClick={callback}
          style={{
            width: "40px",
            height: "40px",
          }}
        >
          <i className={`f22 bi bi-plus`} style={{ color: "#59638B" }}></i>
        </div>
      </div>
    </div>
  );
};

export default Separator;
