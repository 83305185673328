import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SocietyAvatar from "./SocietyAvatar";
import LightDarkContainer from "../../Common/LightDarkContainer";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";
import { SocietyViewIconBack } from "../../Common/LightDarkSvg";
// import { CloseIcon, SearchIcon } from "../../Common/LightDarkSvg";
// import SearchInput from "../../Common/SearchInput";

const SocietyVerticalList = () => {
  const navigate = useNavigate();
  const { societes, societe } = useSelector((state) => state.societesReducer);
  const { societeRoute } = useSelector((state) => state.organisationsReducer);
  // const [searchSociety, setSearchSociety] = useState("");
  const [filteredSocieties, setFilteredSocieties] = useState(societes);
  // const [showSearchSociety, setShowSearchSociety] = useState(false);

  // useEffect(() => {
  //   setFilteredSocieties(
  //     societes.filter(
  //       (el) =>
  //         el.id?.toString()?.includes(searchSociety) ||
  //         el.attributes?.name
  //           ?.toLowerCase()
  //           .includes(searchSociety.toLowerCase()) ||
  //         el.attributes?.siteWeb
  //           ?.toLowerCase()
  //           .includes(searchSociety.toLowerCase()) ||
  //         el.attributes?.email
  //           ?.toLowerCase()
  //           .includes(searchSociety.toLowerCase()) ||
  //         el.attributes?.phoneNumber
  //           ?.toLowerCase()
  //           .includes(searchSociety.toLowerCase()) ||
  //         el.attributes?.city
  //           ?.toLowerCase()
  //           .includes(searchSociety.toLowerCase()) ||
  //         el.attributes?.zipCode
  //           ?.toLowerCase()
  //           .includes(searchSociety.toLowerCase()) ||
  //         el.attributes?.adress
  //           ?.toLowerCase()
  //           .includes(searchSociety.toLowerCase())
  //     )
  //   );
  // }, [searchSociety, societes]);

  return (
    <div className="scrollable d-flex flex-column gap-2 ">
      <div className="searchSocietyIconContainer">
        <LightDarkContainer
          colorLight="white"
          className={`verticalSocietyElt pointer radius05 border border-2 alignC flex-1 border-white position-fixed`}
        >
          <SocietyViewIconBack
            size={30}
            className={"societyViewIconBack"}
            callBack={() => navigate(`${societeRoute}`)}
          />
        </LightDarkContainer>
      </div>
      {/* <div
        className="searchSocietyIconContainer"
        onClick={() => {
          setShowSearchSociety(!showSearchSociety);
        }}
      >
        <LightDarkContainer
          colorLight="white"
          className={`verticalSocietyElt pointer radius05 border border-2 alignC flex-1 border-white position-fixed`}
        >
          <SearchIcon size={25} />
        </LightDarkContainer>
      </div> */}
      {/* <div
        className={`searchSociety pointer position-absolute radius05 bg-white ${
          !showSearchSociety ? "d-none" : ""
        }`}
      >
        <SearchInput
          placeholder="ID, Name, Adresse, Email, Site Web, téléphone..."
          value={searchSociety} 
          onChange={(e) => {
            setSearchSociety(e.target.value);
          }}
        />
        <div
          className="searchSocietyCloseIcon position-absolute"
          onClick={() => {
            setShowSearchSociety(false);
          }}
        >
          <CloseIcon />
        </div>
      </div> */}
      {filteredSocieties.length === 0 ? (
        <div>
          <LightDarkContainer
            colorLight=""
            className={`verticalSocietyElt`}
          ></LightDarkContainer>
        </div>
      ) : (
        filteredSocieties.map((society) => (
          <OverlayTrigger
            key={society.id}
            placement="right"
            overlay={
              <Tooltip style={{ zIndex: 5000 }}>
                <LightDarkText className="custom-font-size py-1 text-white">
                  {society.attributes.name}
                </LightDarkText>
              </Tooltip>
            }
          >
            <div>
              <LightDarkContainer
                colorLight="white"
                callBack={() => navigate(`${societeRoute}/${society.id}`)}
                className={`verticalSocietyElt pointer radius05 border border-2 alignC flex-1 ${
                  societe?.id === society.id
                    ? "border-light-blue"
                    : "border-white"
                }`}
              >
                <SocietyAvatar
                  width="40px"
                  height="40px"
                  imageUrl={
                    society.attributes.avatar.data?.attributes?.image?.data
                      ?.attributes?.url
                  }
                />
              </LightDarkContainer>
            </div>
          </OverlayTrigger>
        ))
      )}
    </div>
  );
};

export default SocietyVerticalList;
