import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";
import {
  FacebookIcon,
  GlobalIcon,
  InstagramIcon,
  LinkedInIcon,
  TikTokIcon,
  TwitterIcon,
} from "../../../Common/LightDarkSvg";
import EventRelatedFiles from "./EventRelatedFiles";

const EventSocialMedia = ({ event }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {(event?.facebook ||
        event?.instagram ||
        event?.linkedIn ||
        event?.twitter ||
        event?.tikTok ||
        event?.global) && (
        <div>
          <LightDarkText
            className="mt-4 f12 text-start bold"
            color="text-light-text"
          >
            Réseaux sociaux
          </LightDarkText>
          {event?.facebook && (
            <>
              <LightDarkText
                className="mt-4 mb-2 f14 text-start bold d-flex align-items-center gap-2"
                color={mode === "light" ? "text-dark-blue" : "text-light-text"}
              >
                <FacebookIcon dark />
                Facebook
              </LightDarkText>
              <LightDarkText
                className="bold custom-ticket-font-size text-start"
                insertHtml={event?.facebook}
                color={mode === "light" ? "text-blue-grey" : "text-white"}
              ></LightDarkText>
              {event?.facebookFiles?.data?.length ? (
                <EventRelatedFiles
                  files={event?.facebookFiles?.data}
                  socialMediaFiles={"Fichiers liés à Facebook"}
                />
              ) : null}
            </>
          )}
          {event?.instagram && (
            <>
              <LightDarkText
                className="mt-4 mb-2 f14 text-start bold d-flex align-items-center gap-2"
                color={mode === "light" ? "text-dark-blue" : "text-light-text"}
              >
                <InstagramIcon dark />
                Instagram
              </LightDarkText>
              <LightDarkText
                className="bold custom-ticket-font-size text-start"
                insertHtml={event?.instagram}
                color={mode === "light" ? "text-blue-grey" : "text-white"}
              ></LightDarkText>
              {event?.instagramFiles?.data?.length ? (
                <EventRelatedFiles
                  files={event?.instagramFiles?.data}
                  socialMediaFiles={"Fichiers liés à Instagram"}
                />
              ) : null}
            </>
          )}
          {event?.linkedIn && (
            <>
              <LightDarkText
                className="mt-4 mb-2 f14 text-start bold d-flex align-items-center gap-2"
                color={mode === "light" ? "text-dark-blue" : "text-light-text"}
              >
                <LinkedInIcon dark />
                LinkedIn
              </LightDarkText>
              <LightDarkText
                className="bold custom-ticket-font-size text-start"
                insertHtml={event?.linkedIn}
                color={mode === "light" ? "text-blue-grey" : "text-white"}
              ></LightDarkText>
              {event?.linkedInFiles?.data?.length ? (
                <EventRelatedFiles
                  files={event?.linkedInFiles?.data}
                  socialMediaFiles={"Fichiers liés à LinkedIn"}
                />
              ) : null}
            </>
          )}
          {event?.twitter && (
            <>
              <LightDarkText
                className="mt-4 mb-2 f14 text-start bold d-flex align-items-center gap-2"
                color={mode === "light" ? "text-dark-blue" : "text-light-text"}
              >
                <TwitterIcon dark />X
              </LightDarkText>
              <LightDarkText
                className="bold custom-ticket-font-size text-start"
                insertHtml={event?.twitter}
                color={mode === "light" ? "text-blue-grey" : "text-white"}
              ></LightDarkText>
              {event?.twitterFiles?.data?.length ? (
                <EventRelatedFiles
                  files={event?.twitterFiles?.data}
                  socialMediaFiles={"Fichiers liés à X"}
                />
              ) : null}
            </>
          )}
          {event?.tikTok && (
            <>
              <LightDarkText
                className="mt-4 mb-2 f14 text-start bold d-flex align-items-center gap-2"
                color={mode === "light" ? "text-dark-blue" : "text-light-text"}
              >
                <TikTokIcon dark />
                TikTok
              </LightDarkText>
              <LightDarkText
                className="bold custom-ticket-font-size text-start"
                insertHtml={event?.tikTok}
                color={mode === "light" ? "text-blue-grey" : "text-white"}
              ></LightDarkText>
              {event?.tiktokFiles?.data?.length ? (
                <EventRelatedFiles
                  files={event?.tiktokFiles?.data}
                  socialMediaFiles={"Fichiers liés à Tiktok"}
                />
              ) : null}
            </>
          )}
          {event?.global && (
            <>
              <LightDarkText
                className="mt-4 mb-2 f14 text-start bold d-flex align-items-center gap-2"
                color={mode === "light" ? "text-dark-blue" : "text-light-text"}
              >
                <GlobalIcon dark />
                Global
              </LightDarkText>
              <LightDarkText
                className="bold custom-ticket-font-size text-start"
                insertHtml={event?.global}
                color={mode === "light" ? "text-blue-grey" : "text-white"}
              ></LightDarkText>
              {event?.globalFiles?.data?.length ? (
                <EventRelatedFiles
                  files={event?.globalFiles?.data}
                  socialMediaFiles={"Fichiers liés à Global"}
                />
              ) : null}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default EventSocialMedia;
