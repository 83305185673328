const initialState = {
  io: null,
  route: "",
  connected: false,
};
const socketReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "CONNECT_SOCKET":
      return { ...state, io: payload.MyIO };
    case "CONNECTED_SOCKET":
      return { ...state, connected: payload };
    case "CLEAR_SOCKET":
      return { ...state, io: null };
    case "UPDATE_ROUTE":
      return { ...state, route: payload };
    default:
      return state;
  }
};
export default socketReducer;
