import { useSelector } from "react-redux";

import LightDarkText from "../../Common/LightDarkText";

import { handleFirstLastName } from "../../../Utils/utils";

const CreatorName = ({ name }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <LightDarkText
      className="custom-ticket-font-size text-start bold"
      color={mode === "light" ? "text-blue-grey" : "text-light-text"}
    >
      par{" "}
      <span className="text-light-blue xbold">{handleFirstLastName(name)}</span>
    </LightDarkText>
  );
};

export default CreatorName;
