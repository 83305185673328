export const LOADING = "LOADING";

export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAILED = "ADD_QUESTION_FAILED";

export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAILED = "GET_QUESTIONS_FAILED";

export const DELETE_QUESTIONS_SUCCESS = "DELETE_QUESTIONS_SUCCESS";
export const DELETE_QUESTIONS_FAILED = "DELETE_QUESTIONS_FAILED";

export const UPDATE_QUESTIONS_SUCCESS = "UPDATE_QUESTIONS_SUCCESS";
export const UPDATE_QUESTIONS_FAILED = "UPDATE_QUESTIONS_FAILED";

export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAILED = "GET_QUESTION_FAILED";
