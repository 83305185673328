export const overrideStrings = (inputsLength, dataLength, placeholder = "") => {
  return {
    allItemsAreSelected: "Tous les éléments sont séléctionnés",
    clearSearch: "Effacer la recherche",
    clearSelected: "Effacer séléctionnés",
    noOptions: "Pas de resultat",
    search: "Recherche",
    selectAll:
      inputsLength === dataLength ? "Désélectionner tout" : "Séléctionner tout",
    selectAllFiltered: "Séléctionner tout (Filtré)",
    selectSomeItems: placeholder,
    create: "Créer",
  };
};
