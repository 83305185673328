import LightDarkText from "./LightDarkText";

import { useSelector } from "react-redux";

const MenuTabs = ({
  tabTitle,
  toggleValue,
  isToggle,
  setIsToggle,
  isVisible,
  fontSize = "f12",
  borderPosition = 13,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return isVisible ? (
    <LightDarkText
      className={`text-start ${fontSize} bold  pointer clickable-text t-02`}
      color={
        isToggle === toggleValue
          ? "text-light-blue"
          : mode === "light"
          ? "text-blue-grey"
          : "text-light-text"
      }
      onclickCallback={setIsToggle}
    >
      {tabTitle}
      {isToggle === toggleValue && (
        <div
          className={`radius20 `}
          style={{
            borderBottom: "4px solid #3365ff",
            position: "relative",
            top: borderPosition,
          }}
        ></div>
      )}
    </LightDarkText>
  ) : null;
};

export default MenuTabs;
