import moment from "moment";

import LightDarkText from "../../Common/LightDarkText";

const DateText = ({ date }) => {
  return (
    <LightDarkText
      className="custom-date-font-size bold text-start"
      color="text-light-text"
    >
      {moment(date).format("DD.MM.YYYY")}
    </LightDarkText>
  );
};

export default DateText;
