import React from "react";

const Eye = ({ fill = "#a9bcd3", width = "24", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.1875 16.0139C33.145 9.6614 27.23 6.00391 21 6.00391C17.885 6.00391 14.8575 6.91391 12.0925 8.61141C9.3275 10.3264 6.8425 12.8289 4.8125 16.0139C3.0625 18.7614 3.0625 23.2239 4.8125 25.9714C8.855 32.3414 14.77 35.9814 21 35.9814C24.115 35.9814 27.1425 35.0714 29.9075 33.3739C32.6725 31.6589 35.1575 29.1564 37.1875 25.9714C38.9375 23.2414 38.9375 18.7614 37.1875 16.0139ZM21 28.0714C17.08 28.0714 13.93 24.9039 13.93 21.0014C13.93 17.0989 17.08 13.9314 21 13.9314C24.92 13.9314 28.07 17.0989 28.07 21.0014C28.07 24.9039 24.92 28.0714 21 28.0714Z"
        fill={fill}
      />
      <path
        d="M20.9992 15.9961C18.2517 15.9961 16.0117 18.2361 16.0117 21.0011C16.0117 23.7486 18.2517 25.9886 20.9992 25.9886C23.7467 25.9886 26.0042 23.7486 26.0042 21.0011C26.0042 18.2536 23.7467 15.9961 20.9992 15.9961Z"
        fill={fill}
      />
    </svg>
  );
};

export default Eye;
