import React from "react";
import { useSelector } from "react-redux";
import LightDarkText from "../../Common/LightDarkText";
import AlertCard from "./AlertCard";

const AlertList = () => {
  const { alerts } = useSelector((state) => state.alertsReducer);

  return (
    <>
      {alerts?.length > 0 ? (
        alerts
          .sort((a, b) => a.date - b.date)
          .map((el, index) => (
            <AlertCard key={index} index={index} alert={el} />
          ))
      ) : (
        <LightDarkText className="f14 h100 alignC text-center">
          Vous n'avez pas d'annonces
        </LightDarkText>
      )}
    </>
  );
};

export default AlertList;
