export const ADD_DIFFUSION_SUCCESS = "ADD_DIFFUSION_SUCCESS";
export const ADD_DIFFUSION_FAILED = "ADD_DIFFUSION_FAILED";
export const LOADING_ADD_DIFFUSION = "LOADING_ADD_DIFFUSION";

export const GET_DIFFUSION_SUCCESS = "GET_DIFFUSION_SUCCESS";
export const GET_DIFFUSION_FAILED = "GET_DIFFUSION_FAILED";

export const DELETE_DIFFUSION_SUCCESS = "DELETE_DIFFUSION_SUCCESS";
export const DELETE_DIFFUSION_FAILED = "DELETE_DIFFUSION_FAILED";

export const UPDATE_DIFFUSION_SUCCESS = "UPDATE_DIFFUSION_SUCCESS";
export const UPDATE_DIFFUSION_FAILED = "UPDATE_DIFFUSION_FAILED";
