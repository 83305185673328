export const ATTRIBUTES_DEPARTEMENT = `
code
name
users{
    data{
        id
        attributes {
            user_org_permissions {
                data {
                    id
                    attributes{
                        role
                    }
                }
            }
        }
    }
}
userOrgDepartment{
    data{
        id
        attributes{
            name
            user{
                data{
                    id
                }
            }
            organisation{
                data{
                    id
                }
            }
        }
    }
}
`;
