import React from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";

import LoginView from "../Views/LoginView/LoginView";
import ForgotPasswordView from "../Views/ForgotPasswordView/ForgotPasswordView";
import ResetPasswordView from "../Views/ResetPasswordView/ResetPasswordView";

const OpenRoutes = () => {
  return (
    <Switch>
      {/* ---------------------------------- open Paths ------------------------------------- */}
      <Route path="/*" element={<LoginView />}></Route>

      <Route
        path="forgot-password"
        exact
        element={<ForgotPasswordView />}
      ></Route>
      <Route
        path="reset-password"
        exact
        element={<ResetPasswordView />}
      ></Route>
      <Route path="*" exact element={<Navigate to="/" />}></Route>
    </Switch>
  );
};

export default OpenRoutes;
