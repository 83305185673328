const initialState = {
  mode: "light",

  isNew: true,
  isEdit: false,
  fromCalendar: false,
  eventDetails: null,
  openSideBar: false,
  sideBarContent: null,
};

const genearlReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "DARK_MODE":
      return { ...state, mode: "dark" };
    case "LIGHT_MODE":
      return { ...state, mode: "light" };

    case "OPEN_SIDE_BAR_CARD":
      return { ...state, openSideBar: true };
    case "HIDE_SIDE_BAR_CARD":
      return { ...state, openSideBar: false, sideBarContent: null };
    default:
      return state;
  }
};
export default genearlReducer;
