import React from "react";
import SimpleLineChart from "../../Charts/SimpleLineChart";
import DoubleLineChart from "../../Charts/DoubleLineChart";
import DoubleBarChart from "../../Charts/DoubleBarChart";
import { useDispatch, useSelector } from "react-redux";
import { getOperatorStats } from "../../../REDUX/actions/stats";
import { useEffect } from "react";

const UserOpStats = ({ operator }) => {
  const dispatch = useDispatch();
  const { operatorStats } = useSelector((state) => state.statsReducer);
  useEffect(() => {
    dispatch(getOperatorStats(operator?.id));
  }, [operator]);

  return (
    <div className="row">
      <div className="col-12 w-100 mb-1">
        <DoubleBarChart
          title={"Nombre d’interventions"}
          operatorFormChart={true}
          color="#3365FF"
          color2="#0CCE91"
          customHeight={"180px"}
          stats={{
            openTickets: operatorStats?.totalInterventions,
            resolvedTickets: operatorStats?.resolvedInterventions,
          }}
        />
      </div>
      <div className={`d-flex flex-row justify-content-between `}>
        <div className={`col-12 col-lg-6 `}>
          <SimpleLineChart
            color="#7E25FF"
            withHour={true}
            title="Interventions par heure"
            type={"tickets"}
            stats={operatorStats?.interventionPerHour}
          />
        </div>
        <div className={`col-12 col-lg-6 `}>
          <DoubleLineChart
            color="#FFBC20"
            color2="#0CCE91"
            title="Répartition des tickets"
            type={"tickets"}
            stats={{
              openTickets: operatorStats?.totalTickets,
              resolvedTickets: operatorStats?.resolvedTickets,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserOpStats;
