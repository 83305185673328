import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

const NotificationEventFromNow = ({ notification }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div
      className={`xbold firstLetterCapital ${
        mode === "light" ? "text-light-blue" : "text-light-blue-grey"
      } `}
    >
      {moment(notification?.event?.data?.attributes?.startDate).fromNow()}
    </div>
  );
};

export default NotificationEventFromNow;
