import { saveAs } from "file-saver";
import Api from "../../Api/Api";
import { getCloud } from "./cloud";
import { closeConfirmationModal } from "./confirmationModal";
import { closeModal } from "./modal";
import { handleError, handleInfo } from "./toastMessage";
import moment from "moment";
import { handleRefetchCloud } from "./files";
import { getInitialData } from "./sharedData";

export const loginToOneDrive = () => async () => {
  const response = await Api.get(`api/external-service/loginToOneDrive`);
  window.location.href = response.data;
};

export const getOneDriveToken =
  (code, service) => async (dispatch, getState) => {
    const { documentsRoute } = getState().organisationsReducer;
    try {
      const organisation = getState().organisationsReducer.selectedOrganisation;

      if (!organisation.id) {
        return;
      }

      await Api.get(
        `api/external-service/getOneDriveToken?authorizationCode=${code}&service=${service}&organisation=${organisation.id}`
      );
      window.location.href = documentsRoute;
    } catch (error) {
      window.location.href = documentsRoute;

      dispatch(
        handleError(
          "Problème lors de la synchronisation OneDrive, veuillez réessayer plus tard.",
          "SHOW_ERROR"
        )
      );
    }
  };

export const fetchOneDrive =
  (withLoading = true) =>
  async (dispatch, getState) => {
    try {
      if (withLoading) {
        dispatch({ type: "ONE_DRIVE_LOADING" });
      }

      const organisation = getState().organisationsReducer.selectedOrganisation;

      if (!organisation.id) {
        return;
      }
      const { data } = await Api.get(
        `api/external-service/fetchOneDriveFiles?organisation=${organisation.id}`
      );
      dispatch({ type: "FETCH_ONEDRIVE", payload: data.onedriveData });
      if (data.sharePointData) {
        dispatch({ type: "FETCH_SHAREPOINT", payload: data.sharePointData });
      } else {
        dispatch({ type: "NO_SHAREPOINT" });
      }
    } catch (error) {
      dispatch({ type: "SYNCH_ERROR", payload: "oneDriveSynched" });

      if (error.response.data.error.message === "InvalidAuthenticationToken") {
        // dispatch(handledesynchronize(concernedService.id, "oneDriveSynched"));
        dispatch(
          handleError(
            "Service oneDrive déconnecté. Veuillez synchroniser de nouveau"
          )
        );
      } else {
        dispatch(handleError());
      }
    }
  };

export const renameOneDriveFolder =
  (folderId, name) => async (dispatch, getState) => {
    try {
      const organisation = getState().organisationsReducer.selectedOrganisation;

      if (!organisation.id) {
        return;
      }
      await Api.post(
        `api/external-service/renameOneDriveFolder?organisation=${organisation.id}`,
        {
          data: { folderId, name },
        }
      );
      dispatch(handleRefetchCloud("oneDrive"));
      dispatch(closeModal());
    } catch (error) {
      if (error?.response?.data?.error?.message === "Nom dèja existant") {
        dispatch(
          handleError(error?.response?.data?.error?.message, "SHOW_ERROR")
        );
      } else {
        dispatch(handleError());
      }
    }
  };

export const deleteOneDriveFolder =
  (folderId) => async (dispatch, getState) => {
    try {
      const organisation = getState().organisationsReducer.selectedOrganisation;

      if (!organisation.id) {
        return;
      }
      await Api.post(
        `api/external-service/deleteOneDriveItem?organisation=${organisation.id}`,
        {
          data: { folderId },
        }
      );
      dispatch(handleRefetchCloud("oneDrive"));
      dispatch(closeConfirmationModal());
      dispatch(
        handleInfo("Elément supprimé de Onedrive", "SHOW_INFO", "oneDriveBlue")
      );
    } catch (error) {
      dispatch(handleError());
    }
  };

export const copyFileInAgilix = (filesArray) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "SHOW_EXTERNAL_LOADER",
      payload: { filesArray, title: "Importation de vos éléments..." },
    });
    const organisation = getState().organisationsReducer.selectedOrganisation;
    if (!organisation.id) {
      return;
    }
    await Api.post(
      `api/external-service/copyFileInAgilix?organisation=${organisation.id}`,
      {
        data: {
          filesArray,
        },
      }
    );
    dispatch(getCloud(false));
    setTimeout(() => {
      dispatch({ type: "HIDE_EXTERNAL_LOADER" });
    }, 5000);
    dispatch(
      handleInfo("Fichier copié dans agilix", "SHOW_INFO", "oneDriveBlue")
    );
  } catch (error) {
    dispatch(handleError());
    setTimeout(() => {
      dispatch({ type: "HIDE_EXTERNAL_LOADER" });
    }, 5000);
  }
};

export const downloadMultipleFiles =
  (filesArray) => async (dispatch, getState) => {
    try {
      dispatch({
        type: "SHOW_EXTERNAL_LOADER",
        payload: { filesArray, title: "Téléchargement de vos éléments..." },
      });
      const organisation = getState().organisationsReducer.selectedOrganisation;
      if (!organisation.id) {
        return;
      }
      const response = await Api.post(
        `api/external-service/downloadMultipleFiles?organisation=${organisation.id}`,
        {
          data: {
            filesArray,
          },
        },
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response.data], { type: "application/zip" });

      saveAs(blob, `Agilix-${moment().format("DD-MM-YYYY")}.zip`);

      setTimeout(() => {
        dispatch({ type: "HIDE_EXTERNAL_LOADER" });
      }, 5000);
    } catch (error) {
      dispatch(handleError());
      setTimeout(() => {
        dispatch({ type: "HIDE_EXTERNAL_LOADER" });
      }, 5000);
    }
  };
export const downloadMultipleOneDriveFolders =
  (foldersArray, siteId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: "SHOW_EXTERNAL_LOADER",
        payload: {
          filesArray: foldersArray,
          title: "Téléchargement de vos éléments...",
        },
      });
      const organisation = getState().organisationsReducer.selectedOrganisation;
      if (!organisation.id) {
        return;
      }
      const response = await Api.post(
        `api/external-service/downloadMultipleOneDriveFolders?organisation=${
          organisation.id
        }&siteId=${siteId || ""}`,
        {
          data: {
            foldersArray,
          },
        },
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response.data], { type: "application/zip" });

      if (foldersArray.length === 1) {
        saveAs(blob, `Agilix-${foldersArray[0].name}.zip`);
      } else {
        saveAs(blob, `Agilix-${moment().format("DD-MM-YYYY")}.zip`);
      }

      setTimeout(() => {
        dispatch({ type: "HIDE_EXTERNAL_LOADER" });
      }, 5000);
    } catch (error) {
      dispatch(handleError());
      setTimeout(() => {
        dispatch({ type: "HIDE_EXTERNAL_LOADER" });
      }, 5000);
    }
  };
export const copyFolderInAgilix =
  (foldersArray) => async (dispatch, getState) => {
    dispatch({
      type: "SHOW_EXTERNAL_LOADER",
      payload: {
        filesArray: foldersArray,
        title: "Importation de vos éléments...",
      },
    });
    // dispatch({ type: "LOADING_CLOUD" });
    try {
      const organisation = getState().organisationsReducer.selectedOrganisation;
      if (!organisation.id) {
        return;
      }
      await Api.post(
        `api/external-service/copyFolderInAgilix?organisation=${organisation.id}`,
        {
          data: foldersArray,
        }
      );
      dispatch(getCloud(false));
      dispatch(
        handleInfo("Dossier copié dans agilix", "SHOW_INFO", "oneDriveBlue")
      );
      setTimeout(() => {
        dispatch({ type: "HIDE_EXTERNAL_LOADER" });
      }, 5000);
    } catch (error) {
      dispatch(handleError());
      setTimeout(() => {
        dispatch({ type: "HIDE_EXTERNAL_LOADER" });
      }, 5000);
    }
  };

export const getOneDriveFolder =
  (folderId, withLoading = true, siteId) =>
  async (dispatch, getState) => {
    const { documentsRoute } = getState().organisationsReducer;
    try {
      if (withLoading) {
        dispatch({ type: "ONE_DRIVE_LOADING" });
      }

      const organisation = getState().organisationsReducer.selectedOrganisation;

      if (!organisation.id) {
        return;
      }
      const { data } = await Api.get(
        `api/external-service/getOneDriveFolder?organisation=${
          organisation.id
        }&folderOneDriveId=${folderId}&siteId=${siteId || ""}`
      );
      dispatch({ type: "FETCH_ONEDRIVE_FOLDER", payload: data });
    } catch (error) {
      window.location.href = documentsRoute;
      dispatch(handleError());
      // window.location.href = false;
    }
  };

export const getSharePointSite =
  (siteId, withLoading = true) =>
  async (dispatch, getState) => {
    try {
      if (withLoading) {
        dispatch({ type: "ONE_DRIVE_LOADING" });
      }

      const organisation = getState().organisationsReducer.selectedOrganisation;

      if (!organisation.id) {
        return;
      }
      const { data } = await Api.get(
        `api/external-service/getSharePointSite?organisation=${organisation.id}&siteId=${siteId}`
      );
      dispatch({ type: "FETCH_SHAREPOINT_SITE", payload: data });
    } catch (error) {
      dispatch(handleError());
    }
  };

export const handledesynchronize =
  (serviceId, serviceType) => async (dispatch, getState) => {
    try {
      const organisation = getState().organisationsReducer.selectedOrganisation;

      if (!organisation.id) {
        return;
      }
      await Api.post(
        `api/external-service/handledesynchronize?organisation=${organisation.id}`,
        {
          data: { serviceId },
        }
      );
      if (serviceType) {
        dispatch({ type: "DESYNCHRONIZE", payload: serviceType });
      }

      dispatch(getInitialData(false));
    } catch (error) {
      dispatch(handleError());
    }
  };
