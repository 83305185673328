import moment from "moment";

export const handleEventStatut = (statut) => {
  switch (statut) {
    case 1:
      return { text: "En attente", icon: "🔜" };
    case 2:
      return { text: "À Valider", icon: "⌛" };
    case 3:
      return { text: "Validé", icon: "✅" };
    case 4:
      return { text: "Programmé", icon: "🕓" };

    default:
      return { text: "En attente", icon: "🔜" };
  }
};

export const handleEventDuration = (start, end) => {
  const minutes = moment(end).diff(moment(start)) / 1000 / (60 * 60);
  var sign = minutes < 0 ? "-" : "";
  var min = Math.floor(Math.abs(minutes));
  var sec = Math.floor((Math.abs(minutes) * 60) % 60);

  const duration =
    sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
  return duration;
};

export const handleEventRecurrence = (recurrence) => {
  switch (recurrence) {
    case 1:
      return "";
    case 2:
      return "daily";
    case 3:
      return "daily";
    case 4:
      return "weekly";
    case 5:
      return "monthly";
    case 6:
      return "yearly";
    default:
      return "";
  }
};
