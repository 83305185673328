import React from "react";
import { useSelector } from "react-redux";
import AttachmentPlaceHolder from "../../PlaceHolders/AttachmentPlaceHolder";
import AttachementUploader from "../../Common/AttachementUploader";
import AttachmentsList from "../../Common/AttachmentsList";

const RightAttachedFiles = ({ ticket }) => {
  const { canSendMessages } = useSelector((state) => state.roleReducer);
  const { messagesLoading, showPrivateMessages } = useSelector(
    (state) => state.messagesReducer
  );
  const { open } = useSelector((state) => state.modalReducer);

  return (
    <div className="directionC justify-content-between notScrollable h100">
      <div className="sideChatListContainer mb-3 overflow-auto scrollable">
        <div className="d-flex flex-column gap-2">
          {messagesLoading ? (
            <AttachmentPlaceHolder />
          ) : (
            <AttachmentsList
              files={ticket.attributes?.fichiers.data}
              ticketAttachedFiles
              ticketNotArchived={!ticket.attributes.archived}
            />
          )}
        </div>
      </div>
      {open || ticket?.attributes?.archived || !canSendMessages ? null : (
        <div className="">
          <AttachementUploader
            ticketUploader
            label={"Joindre un fichier"}
            ticket={ticket}
            textColor={showPrivateMessages ? "text-light-red" : null}
            borderColor={showPrivateMessages ? "#ff7f7f" : null}
            bgColor={showPrivateMessages ? "#fee2e2" : null}
          />
        </div>
      )}
    </div>
  );
};

export default RightAttachedFiles;
