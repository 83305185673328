import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LightDarkText from "./LightDarkText";
import "./LightDarkInput.css";
import { Calendar, LinkIcon, GlobalIcon } from "./LightDarkSvg";
import Input from "react-phone-number-input/input";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const LightDarkInput = ({
  label,
  placeholder,
  setValue,
  champs,
  totalInputs,
  required,
  disabled = false,
  type = "text",
  eventType,
  passwordInput,
  packageMessage = false,
  bgWhite = false,
  min = 0,
  max,
  link,
  label_class,
  calendarForm,
  phoneInput = false,
  customClass = "",
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const [showPassword, setShowPassword] = useState(false);
  const [currentValue, setcurrentValue] = useState(totalInputs[champs] || "");

  useEffect(() => {
    setcurrentValue(totalInputs[champs] || "");
  }, [totalInputs]);

  const handleChangeInput = (value) => {
    if (type === "number") {
      if (parseFloat(value) > max) {
        setValue({ ...totalInputs, [champs]: max });
      } else if (parseFloat(value) < min) {
        setValue({ ...totalInputs, [champs]: min });
      } else {
        setValue({ ...totalInputs, [champs]: parseFloat(value) });
      }
    } else {
      setValue({ ...totalInputs, [champs]: value });
    }
  };

  return (
    <div className="w-100">
      <LightDarkText
        className={`text-start bold custom-ticket-font-size mb-2 ${label_class}`}
        color={
          label_class === "ques_title"
            ? "text-light-blue"
            : mode === "light"
            ? "text-blue-grey"
            : "text-white"
        }
      >
        {label} {required ? <span className="text-red">*</span> : null}
        <div></div>
      </LightDarkText>

      <div className="d-flex position-relative z-index-0 justify-content-end align-items-center">
        {passwordInput && (
          <div
            className="pointer z-index-1"
            style={{ position: "absolute", margin: "10px" }}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <i className="bi f24 text-light-text bi-eye-slash-fill"></i>
            ) : (
              <i className="bi f24 text-light-text bi-eye-fill"></i>
            )}
          </div>
        )}
        <form
          autoComplete="new-password"
          className="w-100 d-flex align-items-center"
        >
          {eventType && (
            <div className="ms-2 z-index-1" style={{ position: "absolute" }}>
              {eventType === "Marketing" ? (
                <GlobalIcon eventInput light />
              ) : (
                <Calendar />
              )}
            </div>
          )}
          {phoneInput ? (
            <Input
              placeholder="Numéro de téléphone"
              value={currentValue}
              onChange={(e) => handleChangeInput(e)}
              className={`LightDarkInputContainer ${customClass} no-resize text-start t-02 w-100 radius05 custom-ticket-font-size px-2 py-3 ${
                bgWhite
                  ? "bg-white "
                  : mode === "light"
                  ? "lightInput"
                  : "darkInput"
              } ${disabled && "disabled"}`}
              style={{
                height: "40px",
              }}
              disabled={disabled}
            />
          ) : (
            <input
              autoComplete="nope"
              type={showPassword ? "text" : type}
              placeholder={placeholder}
              className={`LightDarkInputContainer ${customClass} no-resize text-start t-02 w-100 radius05 custom-ticket-font-size pe-2 py-3 ${
                !eventType && "ps-2"
              } ${
                bgWhite
                  ? "bg-white "
                  : mode === "light"
                  ? "lightInput"
                  : "darkInput"
              } ${disabled && "disabled"}`}
              style={{
                height: `${packageMessage ? "130px" : "40px"}`,
                paddingLeft: `${eventType && "40px"}`,
              }}
              disabled={disabled}
              onChange={(e) => handleChangeInput(e.target.value)}
              value={calendarForm ? totalInputs.name : currentValue}
              min={min ? min : undefined}
              max={max ? max : undefined}
            />
          )}
        </form>

        {link ? (
          <div className="ms-3 pointer">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  <LightDarkText
                    className={
                      "custom-ticket-font-size bold pointer text-white"
                    }
                  >
                    {link}
                  </LightDarkText>
                </Tooltip>
              }
            >
              <a href={link} target="_blank" rel="noreferrer">
                <LinkIcon />
              </a>
            </OverlayTrigger>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LightDarkInput;
