import moment from "moment";
import { sortFunction } from "../../Utils/utils";

const splitEvents = (allEvents) => {
  const ongoingEvents = sortFunction(allEvents, "startDate", true)?.filter(
    (ev) =>
      moment().isBetween(ev?.attributes?.startDate, ev?.attributes?.endDate)
  );
  const upcomingEvents = sortFunction(allEvents, "startDate", true)?.filter(
    (ev) =>
      +ev?.attributes?.startDate?.slice(5, 7) ===
        +(moment().month() + 1).toString() &&
      ((ev?.attributes?.startDate).toString() >=
        moment().format("YYYY-MM-DD").toString() ||
        ev?.attributes?.recurrenceEndDate?.toString() >=
          moment().format("YYYY-MM-DD").toString())
  );

  const nextMonthEvents = sortFunction(allEvents, "startDate", true)?.filter(
    (ev) => +ev?.attributes?.startDate?.slice(5, 7) === +(moment().month() + 2)
  );

  const afterNextMonthEvents = sortFunction(
    allEvents,
    "startDate",
    true
  )?.filter(
    (ev) => +ev?.attributes?.startDate?.slice(5, 7) === +(moment().month() + 3)
  );
  return {
    ongoingEvents,
    upcomingEvents,
    nextMonthEvents,
    afterNextMonthEvents,
  };
};

export default splitEvents;
