import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import MessagePlaceHolder from "../../../../PlaceHolders/MessagePlaceHolder";
import StatusMessage from "../../MessagesTypes/StatusMessage";
import MessageCard from "./MessageCard/MessageCard";

import useOnScreen from "../../../../../Hooks/useScreen";

const MessagesList = ({
  responseTo,
  isReplying,
  setIsReplying,
  setResponseTo,
  handleFocusToRespond,
  setInternalResponseTo,
}) => {
  const { messages, messagesLoading } = useSelector(
    (state) => state.messagesReducer
  );

  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { measureRef } = useOnScreen();

  useEffect(() => {
    if (!isReplying && document?.querySelector("#baliseBottomMessage")) {
      document?.querySelector("#baliseBottomMessage").scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [messages[0]?.id, isReplying]);
  return (
    <div
      className="messagesContainer position-relative t-02 d-flex flex-column flex-1 overflow-hidden"
      id="scrollBottom"
    >
      {messagesLoading ? (
        <div className="scrollbarY d-flex flex-column justify-content-end h100">
          {["", "", "", ""].map((el, index) => (
            <MessagePlaceHolder key={index} index={index} />
          ))}
        </div>
      ) : (
        <div
          id="messages-scroll"
          className="hide-scrollbar d-flex flex-column-reverse overflow-auto flex-1"
        >
          <div id="baliseBottomMessage"></div>
          {ticket?.attributes?.archived &&
            ticket?.attributes?.actions?.data?.find(
              (el) => el?.attributes?.statut == 0
            ) && (
              <StatusMessage
                color="grey"
                statut="archivé"
                date={
                  ticket?.attributes?.actions?.data?.find(
                    (el) => el?.attributes?.statut == 0
                  )?.attributes?.createdAt
                }
                by={
                  ticket?.attributes?.actions?.data?.find(
                    (el) => el?.attributes?.statut == 0
                  )?.attributes?.by?.data
                }
              />
            )}
          {ticket?.attributes?.statut === 2 && (
            <>
              <StatusMessage
                color="green"
                icon="💪"
                statut="résolu"
                date={
                  ticket?.attributes?.actions?.data?.find(
                    (el) => el?.attributes?.statut == 2
                  )?.attributes?.createdAt
                }
                by={
                  ticket?.attributes?.actions?.data?.find(
                    (el) => el?.attributes?.statut == 2
                  )?.attributes?.by?.data
                }
              />
            </>
          )}
          {messages
            .filter((el) => !el.attributes.is_internal)
            ?.map((message, index) => {
              return (
                <MessageCard
                  responseTo={responseTo}
                  setIsReplying={setIsReplying}
                  handleFocusToRespond={handleFocusToRespond}
                  setResponseTo={setResponseTo}
                  setInternalResponseTo={setInternalResponseTo}
                  message={message}
                  key={index}
                  ticketId={ticket.id}
                  isArchived={ticket.attributes.archived}
                  measureRef={
                    index === messages?.length - 1 ? measureRef : null
                  }
                />
              );
            })}
          <StatusMessage
            color="purple"
            statut="ouvert"
            autoCreated={ticket.attributes?.autoCreated}
            date={
              ticket.attributes?.oldCreatedAt || ticket.attributes?.createdAt
            }
            by={ticket.attributes?.creator?.data}
          />
        </div>
      )}
    </div>
  );
};

export default MessagesList;
