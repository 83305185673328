import { gql } from "@apollo/client";
import {
  ATTRIBUTES_EQUIPE,
  ATTRIBUTES_ITEM_SOCIETY,
} from "../Attributes/Societies";
import { USER_ORG_ATTRIBUTES } from "../Attributes/UserOrg";

export const GET_SOCIETE_ITEM = (id) => gql`
  query {
    societe(id: ${id}) {
      data {
        id
        attributes {
          responsibleOperatorsOrg {
            data {
              id
              attributes {
                ${USER_ORG_ATTRIBUTES}
                user {
                  data {
                    id
                    attributes {
                      ${ATTRIBUTES_EQUIPE}
                    }
                  }
                }
              }
            }
          }
          workInOrg {
            data {
              id
              attributes {
                ${USER_ORG_ATTRIBUTES}
                user {
                  data {
                    id
                    attributes {
                      ${ATTRIBUTES_EQUIPE}
                    }
                  }
                }
              }
            }
          }
          ${ATTRIBUTES_ITEM_SOCIETY}
        }
      }
    }
  }
`;
