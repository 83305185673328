import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getHistoryLog } from "../../../REDUX/actions/clients";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import LightDarkInput from "../../Common/LightDarkInput";

const LogHistoryList = ({ selectedUser }) => {
  const { historyLogs } = useSelector((state) => state.clientsReducer);
  const [intervalInputs, setIntervalInputs] = useState({
    startDate: "",
    endDate: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHistoryLog(selectedUser.id, intervalInputs));
  }, [selectedUser.id, intervalInputs]);

  return (
    <div className="notScrollable">
      <div
        className="d-flex gap-2 flex-wrap bg-white py-4"
        style={{ position: "sticky", top: "0px" }}
      >
        <LightDarkInput
          type="datetime-local"
          label={"de"}
          champs="startDate"
          totalInputs={intervalInputs}
          setValue={setIntervalInputs}
        />
        <LightDarkInput
          type="datetime-local"
          label={"jusqu'au"}
          champs="endDate"
          totalInputs={intervalInputs}
          setValue={setIntervalInputs}
        />
      </div>

      <div className="d-flex flex-column gap-2 scrollable">
        {historyLogs.map((log, index) => (
          <LightDarkContainer
            key={index}
            className="radius10 text-start p-3 f12 d-flex align-items-start gap-2 "
          >
            <LightDarkText className={"f12 bold px-1 radius10 "}>
              {moment(log.day).format("DD/MM/YYYY")}
            </LightDarkText>
            <div className="flex-1">
              {log.logs.map((logDay) => (
                <div className="d-flex gap-3 gap-1 w-100" key={logDay.id}>
                  <LightDarkText className={""}>
                    {moment(logDay.createdAt).format("HH:mm")}
                  </LightDarkText>
                  <LightDarkText className={"flex-1 bold"}>
                    {moment.duration(logDay.duration).humanize()}
                  </LightDarkText>
                </div>
              ))}
            </div>
          </LightDarkContainer>
        ))}
      </div>
    </div>
  );
};

export default LogHistoryList;
