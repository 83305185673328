import React from "react";
import LightDarkPlaceHolder from "./LightDarkPlaceHolder";

const AttachmentPlaceHolder = () => {
  return (
    <div className="d-flex flex-column alignC gap-4">
      <LightDarkPlaceHolder customClass={"w-100 py-4"} />
      <LightDarkPlaceHolder customClass={"w-100 py-4"} />
      <LightDarkPlaceHolder customClass={"w-100 py-4"} />
      <LightDarkPlaceHolder customClass={"w-100 py-4"} />
      <LightDarkPlaceHolder customClass={"w-100 py-4"} />
      <LightDarkPlaceHolder customClass={"w-100 py-4"} />
    </div>
  );
};

export default AttachmentPlaceHolder;
