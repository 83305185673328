import React, { useEffect, useRef } from "react";
import SimulatorListHeader from "./SimulatorListHeader";
import QuestionItem from "./QuestionItem";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../REDUX/actions/modal";
import QuestionForm from "./questions/QuestionForm/QuestionForm";
import ColoredButton from "../Common/Buttons/ColoredButton";
import { SwitchCategory } from "../../REDUX/actions/form_category";
import { useState } from "react";
import {
  newProspect,
  updateProspectFields,
} from "../../REDUX/actions/prospect";
import { useLocation, useNavigate } from "react-router-dom";
import { newDevis } from "../../REDUX/actions/devis";
import { newCommande } from "../../REDUX/actions/commande";
import { getHighestPosition } from "../../Utils/utils";
import Recap from "./Recap";

const SimulateurQuestionList = ({
  selectedCategory,
  isForm,
  prospect,
  prospectId,
  validStep,
  setValidStep,
}) => {
  const scrollRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationError] = useState({});
  const { categories } = useSelector((state) => state.formCategoriesReducer);
  const { loadingAdd } = useSelector((state) => state.prospectReducer);
  const { loadingAddDevis } = useSelector((state) => state.devisReducer);
  const { loadingAddCommande } = useSelector((state) => state.commandeReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);

  const { devisRoute, prospetsRoute } = useSelector(
    (state) => state.organisationsReducer
  );
  const { mode } = useSelector((state) => state.genearlReducer);
  const currentIndex = categories?.findIndex(
    (item) => item?.id === selectedCategory?.id
  );

  const handleNewQuestion = () => {
    dispatch(openModal({ content: <QuestionForm /> }));
  };
  const validateForm = () => {
    let valid = true;
    let errors = {};
    const requiredFields = selectedCategory?.attributes?.questions?.data
      ?.filter(
        (item) =>
          item?.attributes?.is_required &&
          item?.attributes?.conditions?.data.length === 0 &&
          [
            "text",
            "select",
            "multi_select",
            "radio",
            "checkbox",
            "departement",
            "date",
            "number",
            "number_range",
            "boolean",
          ].includes(item?.attributes?.input_type)
      )
      ?.map((item) => ({ field: `question-${item.id}` }));
    requiredFields?.forEach(({ field }) => {
      if (!formData[field]) {
        errors = { ...errors, [field]: "Ce champ est obligatoire !" };
        valid = false;
      }
    });
    setValidationError(errors);

    return valid;
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [selectedCategory.id]);

  useEffect(() => {
    if (prospect) {
      setFormData(prospect);
    }
  }, [prospect]);
  const handleNext = () => {
    if (selectedCategory !== "recap") {
      scrollRef.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    const currentIndex = categories?.findIndex(
      (item) => item?.id === selectedCategory?.id
    );

    if (validateForm() || isSupervisor) {
      setValidStep([...validStep, currentIndex + 1]);
      if (currentIndex === -1) {
        if (prospect) {
          dispatch(updateProspectFields(prospectId, formData, navigate));
        } else {
          location.pathname === `${prospetsRoute}/new`
            ? dispatch(newProspect(formData, navigate))
            : location.pathname === `${devisRoute}/new`
            ? dispatch(newDevis(formData, navigate))
            : dispatch(newCommande(formData, navigate));
        }
      } else if (currentIndex === categories?.length - 1) {
        dispatch(SwitchCategory("recap"));
      } else {
        dispatch(SwitchCategory(categories[currentIndex + 1]));
      }
    }
  };
  const handlePrevious = () => {
    scrollRef.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const currentIndex = categories?.findIndex(
      (item) => item?.id === selectedCategory?.id
    );
    const lastindex = categories?.findIndex(
      (item) => item?.attributes.position === getHighestPosition(categories)
    );
    if (currentIndex === 0) {
      return null;
    } else if (currentIndex === -1) {
      dispatch(SwitchCategory(categories[lastindex]));
    } else {
      dispatch(SwitchCategory(categories[currentIndex - 1]));
    }
  };

  function conditionsFulfilled(conditions) {
    if (conditions?.length !== 0) {
      for (const condition of conditions?.data) {
        const { answer } = condition?.attributes;
        if (
          formData[`question-${condition?.attributes?.condition?.data?.id}`] ===
          answer
        ) {
          return true;
        }
      }
    }

    return false;
  }
  return (
    <div
      className={`h-100 position-relative px-3 ${
        mode === "light" ? "bg-white" : "bg-dark-grey-bg"
      }`}
    >
      {!isForm && (
        <SimulatorListHeader
          title={selectedCategory?.attributes?.name}
          position={selectedCategory?.attributes?.position}
          callback={handleNewQuestion}
          isForm={isForm}
        />
      )}
      {isForm && selectedCategory === "recap" ? (
        <div className="h100">
          <Recap formData={formData} />
          <div
            className="position-absolute d-flex justify-content-end "
            style={{ bottom: 0, right: 20, height: "60px" }}
          >
            <div className="me-3 h10">
              <ColoredButton
                textButton={"Précédent"}
                color="light-text"
                callback={handlePrevious}
              />
            </div>
            <div style={{ width: "100px" }}>
              <ColoredButton
                loading={loadingAdd || loadingAddDevis || loadingAddCommande}
                textButton={currentIndex === -1 ? "Valider" : "Suivant"}
                color="light-blue"
                callback={handleNext}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          ref={scrollRef}
          className={`row h90  simulateurQuestionlist   ${
            isForm ? " align-content-start pt-3" : "mt-3"
          }`}
        >
          {selectedCategory?.attributes?.questions?.data
            ?.filter((item) => {
              if (isForm) {
                if (item?.attributes?.conditions?.data?.length !== 0) {
                  return conditionsFulfilled(item?.attributes?.conditions);
                }
                return true;
              }
              return true;
            })
            ?.sort((a, b) => a.attributes.position - b.attributes.position)
            .map((item) => {
              return (
                <QuestionItem
                  item={item}
                  key={item?.id}
                  isForm={isForm}
                  formData={formData}
                  setFormData={setFormData}
                  validationErrors={validationErrors}
                />
              );
            })}
          {isForm && (
            <div
              className="position-absolute d-flex justify-content-end "
              style={{ bottom: 0, right: 20, height: "60px" }}
            >
              <div className="me-3">
                <ColoredButton
                  textButton={"Précédent"}
                  color="light-text"
                  callback={handlePrevious}
                />
              </div>
              <div style={{ width: "100px" }}>
                <ColoredButton
                  loading={loadingAdd || loadingAddDevis || loadingAddCommande}
                  textButton={currentIndex === -1 ? "Submit" : "Suivant"}
                  color="light-blue"
                  callback={handleNext}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SimulateurQuestionList;
