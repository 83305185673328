import React from "react";

const MentionsLock = ({ fill = "#a9bcd3", width = "30", height = "30" }) => {
  return (
    <svg
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 41.4 35.7"
      //   style="enable-background:new 0 0 41.4 35.7;"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M7.4,2.4c-3.2,0-5.7,2.6-5.7,5.7v8.6c0,3.2,2.6,5.7,5.7,5.7h8.6c3.2,0,5.7-2.6,5.7-5.7V8.1
	c0-3.2-2.6-5.7-5.7-5.7H7.4z M13.8,6C12.4,5.5,10.9,5.5,9.4,6C8,6.4,6.8,7.3,5.8,8.4c-0.9,1.2-1.4,2.6-1.5,4.1c0,1.5,0.4,3,1.2,4.2
	c0.8,1.2,2,2.2,3.4,2.7c1.4,0.5,2.9,0.6,4.4,0.2c0.4-0.1,0.6-0.5,0.5-0.9c-0.1-0.4-0.5-0.6-0.9-0.5c-1.2,0.3-2.4,0.2-3.5-0.2
	C8.4,17.7,7.4,17,6.8,16c-0.7-1-1-2.2-1-3.4c0-1.2,0.5-2.3,1.2-3.3c0.7-0.9,1.7-1.6,2.9-2C11,7,12.2,7,13.4,7.4
	c1.1,0.4,2.1,1.1,2.8,2.1s1.1,2.1,1.1,3.3v1.1c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3
	c-0.2-0.2-0.3-0.5-0.3-0.8v-1.1c0,0,0,0,0,0c0,0,0,0,0,0v-2.1c0-0.4-0.3-0.7-0.7-0.7c-0.2,0-0.4,0.1-0.5,0.2
	c-0.6-0.6-1.5-0.9-2.4-0.9c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6c1,0,1.9-0.4,2.6-1.1c0.1,0.1,0.2,0.3,0.3,0.4
	c0.5,0.5,1.1,0.7,1.8,0.7c0.7,0,1.3-0.3,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8v-1.1c0-1.5-0.5-2.9-1.3-4.2C16.5,7.4,15.2,6.5,13.8,6z
	 M13.7,12.8C13.7,12.8,13.7,12.8,13.7,12.8C13.7,12.8,13.7,12.8,13.7,12.8c0-1.2-1-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1
	C12.7,14.9,13.7,14,13.7,12.8z"
      />
      <path
        fill={fill}
        d="M36.6,20v2c-0.4-0.1-0.9-0.1-1.4-0.1V20c0-3-0.8-5-5-5s-5,2-5,5v1.9c-0.5,0-1,0-1.4,0.1v-2
	c0-2.7,0.7-6.4,6.4-6.4S36.6,17.2,36.6,20z"
      />
      <path
        fill={fill}
        d="M36.6,21.9c-0.4-0.1-0.9-0.1-1.4-0.1h-9.9c-0.5,0-1,0-1.4,0.1c-2.4,0.3-3.1,1.5-3.1,4.6v1.9
	c0,3.8,0.9,4.7,4.7,4.7H35c3.8,0,4.7-0.9,4.7-4.7v-1.9C39.7,23.4,39,22.2,36.6,21.9z M27.2,28.2c-0.2,0.2-0.4,0.3-0.7,0.3
	c-0.1,0-0.2,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.1,0-0.2,0.1-0.4c0-0.1,0.1-0.2,0.2-0.3
	c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.2,0.8-0.1,1,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4C27.4,27.8,27.3,28,27.2,28.2z
	 M31.1,27.9c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.1-0.2-0.2-0.2-0.3
	c0-0.1-0.1-0.2-0.1-0.4c0-0.3,0.1-0.5,0.3-0.7c0.3-0.3,1-0.3,1.3,0c0.2,0.2,0.3,0.4,0.3,0.7C31.2,27.6,31.2,27.8,31.1,27.9z
	 M34.7,28.2c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.4-0.3,1-0.3,1.3,0
	c0,0,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2C35,27.8,34.9,28,34.7,28.2z"
      />
    </svg>
  );
};

export default MentionsLock;
