import React from "react";
import { useSelector } from "react-redux";

const NotificationSocietyName = ({ notification }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <span
      className={`xbold ${
        mode === "light" ? "text-light-blue" : "text-light-blue-grey"
      }`}
    >
      {` ${
        notification?.type === "Message"
          ? notification.ticket?.data?.attributes?.societe?.data?.attributes
              ?.name || ""
          : notification.societe?.data?.attributes.name || ""
      } `}
    </span>
  );
};

export default NotificationSocietyName;
