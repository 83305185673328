import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import SocietyRow from "./SocietyRow";
import TableList from "../Common/TableComponents/TableList";

import { sortFunction } from "../../Utils/utils";
import { societyTableData } from "../../Utils/tableStaticData";
import EmptyList from "../Common/EmptyList";

const SocietyList = ({
  searchSociety,
  selectedItem,
  setSelectedItem,
  clickOnSocietyCard = () => {},
}) => {
  const location = useLocation();

  const { openSideBar } = useSelector((state) => state.genearlReducer);
  const { societes, societeLoading } = useSelector(
    (state) => state.societesReducer
  );
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );

  const [sortSociete, setsortSociete] = useState({ champs: "id", asc: false });
  const [filteredSocieties, setFilteredSocieties] = useState(societes);

  const filteredTableListData =
    location.pathname === "/"
      ? societyTableData({
          withForfait: selectedOrganisation.attributes.withForfait,
          isDashboardView: true,
        }).filter((item) => item.dashboardView)
      : openSideBar
      ? societyTableData({
          withForfait: selectedOrganisation.attributes.withForfait,
          isDashboardView: false,
        }).filter((item) => item.alwaysVisible)
      : societyTableData({
          withForfait: selectedOrganisation.attributes.withForfait,
          isDashboardView: false,
        });

  useEffect(() => {
    setFilteredSocieties(
      sortFunction(societes, sortSociete.champs, sortSociete.asc).filter(
        (el) =>
          el.id?.toString()?.includes(searchSociety) ||
          el.attributes?.name
            ?.toLowerCase()
            .includes(searchSociety.toLowerCase()) ||
          el.attributes?.siteWeb
            ?.toLowerCase()
            .includes(searchSociety.toLowerCase()) ||
          el.attributes?.email
            ?.toLowerCase()
            .includes(searchSociety.toLowerCase()) ||
          el.attributes?.phoneNumber
            ?.toLowerCase()
            .includes(searchSociety.toLowerCase()) ||
          el.attributes?.city
            ?.toLowerCase()
            .includes(searchSociety.toLowerCase()) ||
          el.attributes?.zipCode
            ?.toLowerCase()
            .includes(searchSociety.toLowerCase()) ||
          el.attributes?.adress
            ?.toLowerCase()
            .includes(searchSociety.toLowerCase())
      )
    );
  }, [searchSociety, societes, sortSociete.champs, sortSociete.asc]);

  return (
    <TableList
      headerItems={filteredTableListData}
      setSort={setsortSociete}
      sort={sortSociete}
      loading={societeLoading}
    >
      {filteredSocieties.length === 0 ? (
        <EmptyList type="societe" />
      ) : (
        filteredSocieties.map((society, index) => (
          <SocietyRow
            index={index}
            key={index}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            society={society}
            tableList={filteredTableListData}
            clickOnSocietyCard={clickOnSocietyCard}
          />
        ))
      )}
    </TableList>
  );
};

export default SocietyList;
