import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import LightDarkText from "./LightDarkText";
import "./PDFComponent.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const PDFComponent = ({ pdfFile, documentRef, filesCarousel }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const { mode } = useSelector((state) => state.genearlReducer);

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [renderNabButtons, setRenderPageButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileScale, setFileScale] = useState(1);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(+1);

  const handleLoadSuccess = async () => {
    let loadingTask = await pdfjs.getDocument(pdfFile);
    loadingTask.promise.then((pdf) => {
      pdf.getPage(pageNumber).then(function (page) {
        var scale = 1;
        var viewport = page.getViewport({ scale: scale });
        if (viewport.width > 3000) {
          setFileScale(0.12);
        } else if (viewport.width > 2000) {
          setFileScale(0.35);
        } else if (viewport.width > 1000) {
          setFileScale(0.55);
        }
      });
    });
  };

  useEffect(() => {
    setLoading(true);
  }, [pageNumber]);

  return (
    <div
      className="d-flex flex-column align-items-center position-relative"
      ref={documentRef}
    >
      <Document
        file={pdfFile}
        onLoadSuccess={({ numPages }) => {
          setNumPages(numPages);
          setRenderPageButton(true);
        }}
        loading={
          <div className={`d-flex align-items-center justify-content-center `}>
            <LightDarkText className="text-center custom-font-size bold text-white">
              Chargement du PDF...
            </LightDarkText>
          </div>
        }
        error={
          <LightDarkText className="text-center custom-font-size bold text-white">
            Échec du chargement du PDF.
          </LightDarkText>
        }
      >
        <div className={`${filesCarousel ? "pdfInCarousel" : "pdfContainer"}`}>
          <Page
            scale={fileScale}
            renderMode="canvas"
            pageNumber={pageNumber}
            loading={
              <div
                className={`d-flex align-items-center justify-content-center `}
              >
                <LightDarkText className="text-center custom-font-size bold text-white">
                  Chargement de la page...
                </LightDarkText>
              </div>
            }
            onLoadSuccess={() => {
              setLoading(false);
              handleLoadSuccess();
            }}
          />
        </div>
        {renderNabButtons && !loading && (
          <div className="d-flex justify-content-center align-items-center mt-3">
            <button
              disabled={pageNumber <= 1}
              onClick={previousPage}
              className="bg-transparent border-0"
            >
              <i
                className={`bi bi-caret-left-fill ${
                  mode === "light" ? "text-light-text" : "text-light-blue-grey"
                }`}
                style={{ opacity: `${pageNumber <= 1 ? "0.3" : "1"}` }}
              ></i>
            </button>
            <span className="f16 w-24 text-center text-white">
              {pageNumber}
            </span>
            <button
              disabled={pageNumber === numPages}
              onClick={nextPage}
              className="bg-transparent border-0"
            >
              <i
                className={`bi bi-caret-right-fill ${
                  mode === "light" ? "text-light-text" : "text-light-blue-grey"
                }`}
                style={{
                  opacity: `${pageNumber === numPages ? "0.3" : "1"}`,
                }}
              ></i>
            </button>
          </div>
        )}
      </Document>
    </div>
  );
};

export default PDFComponent;
