import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TooltipActions from "../../../Common/Tooltip/TooltipActions";
import SocietyAvatar from "../SocietyAvatar";
import LightDarkText from "../../../Common/LightDarkText";
import moment from "moment";
import {
  FacebookSvg,
  InstagramSvg,
  LinkedinSvg,
  TikTokSvg,
  XSvg,
} from "../../../Common/SocielMediaSVGs";
import SocietyInfoPlaceHolder from "../../../PlaceHolders/Society/SocietyInfoPlaceHolder";
import LightDarkContainer from "../../../Common/LightDarkContainer";
import { openSideBar } from "../../../../REDUX/actions/sideBar";
import SocietyForm from "../../SocietyForm/SocietyForm";
import { DeleteIcon, EditIcon } from "../../../Common/LightDarkSvg";
import { openConfirmationModal } from "../../../../REDUX/actions/confirmationModal";
import { UpdateSociete } from "../../../../REDUX/actions/societe";
import { closeModal } from "../../../../REDUX/actions/modal";
import CopyIcon from "../../../Common/MenuIcons/CopyIcon";
import { handleInfo } from "../../../../REDUX/actions/toastMessage";

const SocietyDetails = () => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { societeRoute } = useSelector((state) => state.organisationsReducer);

  const { canCreateSociety, isClientAdmin } = useSelector(
    (state) => state.roleReducer
  );
  const { societe, societyItemLoading } = useSelector(
    (state) => state.societesReducer
  );
  const [showTooltip, setshowTooltip] = useState(false);

  const societyInfoData = [
    {
      attribute: "createdAt",
      title: "Créé le",
      type: "date",
    },
    {
      attribute: "siret",
      title: "Siret",
    },
    {
      attribute: "phoneNumber",
      title: "Téléphone",
      type: "tel",
    },
    {
      attribute: "email",
      title: "Email",
      type: "mailto",
    },
    {
      attribute: "emailFacture",
      title: "Email de facturation",
      type: "mailto",
    },
    {
      attribute: "adress",
      title: "Adresse",
    },
    {
      attribute: "city",
    },
    {
      attribute: "zipCode",
    },
    {
      attribute: "siteWeb",
      title: "Site internet",
      type: "link",
    },
  ];
  const societySocialData = [
    {
      attribute: "instagramLink",
      component: InstagramSvg,
    },
    {
      attribute: "xLink",
      component: XSvg,
    },
    {
      attribute: "facebookLink",
      component: FacebookSvg,
    },
    {
      attribute: "linkedinLink",
      component: LinkedinSvg,
    },
    {
      attribute: "tikTokLink",
      component: TikTokSvg,
    },
  ];

  const tooltipData = [
    {
      id: 2,
      text: "Éditer",
      icon: <EditIcon className={"me-2"} />,
      action: () => {
        dispatch(
          openSideBar({
            content: <SocietyForm societyId={societe.id} />,
            title: `Éditer la société ${societe.attributes.name}`,
            type: "w40",
            isToggle: "isToggle value",
          })
        );
        setshowTooltip(false);
      },
      condition: canCreateSociety || isClientAdmin,
    },
    {
      id: 3,
      text: societe?.attributes.archived ? "Désarchiver" : "Archiver",
      icon: <DeleteIcon className="me-2" />,
      action: (e) => {
        e.stopPropagation();
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(
                UpdateSociete({
                  id: societe.id,
                  archived: societe.attributes.archived ? false : true,
                })
              );
              dispatch(closeModal());
            },
            message: `Voulez vous vraiment ${
              societe.attributes.archived ? "désarchiver" : "archiver"
            } la société?`,
            message2: `#${societe.id} ${societe.attributes.name}`,
            confirmButtonText: societe.attributes.archived
              ? "Désarchiver la société"
              : "Archiver la société",
          })
        );
        setshowTooltip(false);
      },
      condition: canCreateSociety || isClientAdmin,
    },
    {
      id: 5,
      text: "Copier le lien",
      icon: <CopyIcon width="18" height="18" className="me-2" />,
      action: (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(
          `${window.location.origin}${societeRoute}/${societe.id}`
        );
        setshowTooltip(false);
        dispatch(handleInfo("Lien copié", 1, "green"));
      },
      condition: true,
    },
  ];

  return societyItemLoading || !societe ? (
    <SocietyInfoPlaceHolder />
  ) : (
    <LightDarkContainer
      colorLight="white"
      style={{ zIndex: 200 }}
      className="p-3 notScrollable d-flex flex-column h100 justify-content-between"
    >
      <div className="d-flex justify-content-between ">
        <div className="alignC flex-column gap-3 w95 ps-4">
          <SocietyAvatar
            imageUrl={
              societe?.attributes?.avatar?.data?.attributes?.image?.data
                ?.attributes?.url
            }
            width="90px"
            height="90px"
          />
          <LightDarkText className={"alignC flex-column f18 bold mb-2 "}>
            {societe.attributes.name}
          </LightDarkText>
        </div>
        <TooltipActions
          left
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
        />
      </div>
      <div className="d-flex flex-column scrollable">
        {societyInfoData.map((el, index) => (
          <div key={index} className="text-start f12 bold">
            {el.title && (
              <LightDarkText
                className={"mt-3"}
                color={mode === "light" ? "text-light-text" : "text-white"}
              >
                {el.title}
              </LightDarkText>
            )}
            <LightDarkText>
              {el.type === "date" ? (
                moment(societe.attributes[el.attribute]).format("DD/MM/YYYY")
              ) : el.type === "link" &&
                societe.attributes[el.attribute] &&
                societe.attributes[el.attribute] !== "Non mentionnée" ? (
                <a
                  href={societe.attributes[el.attribute]}
                  className="text-blue"
                  rel="noreferrer"
                  target="_blank"
                >
                  {societe.attributes[el.attribute]}
                </a>
              ) : el.type === "mailto" &&
                societe.attributes[el.attribute] &&
                societe.attributes[el.attribute] !== "Non mentionnée" ? (
                <a
                  href={`mailto:${societe.attributes[el.attribute]}`}
                  className="text-blue"
                  rel="noreferrer"
                >
                  {societe.attributes[el.attribute]}
                </a>
              ) : el.type === "tel" &&
                societe.attributes[el.attribute] &&
                societe.attributes[el.attribute] !== "Non mentionnée" ? (
                <a
                  href={`tel:${societe.attributes[el.attribute]}`}
                  className="text-blue"
                  rel="noreferrer"
                >
                  {societe.attributes[el.attribute]}
                </a>
              ) : (
                societe.attributes[el.attribute] || "Non mentionné"
              )}
            </LightDarkText>
          </div>
        ))}
      </div>
      <div className="d-flex gap-3">
        {societySocialData.map((el, index) => {
          const Component = el.component; // Store the component as a variable
          return societe.attributes[el.attribute] ? (
            <a
              key={index}
              className={`pointer`}
              href={societe.attributes[el.attribute]}
              target="_blank"
              rel="noreferrer"
            >
              <Component
                key={index}
                colored={societe.attributes[el.attribute]}
              />
            </a>
          ) : (
            <div key={index} className={`disable-pointer`}>
              <Component
                key={index}
                colored={societe.attributes[el.attribute]}
              />
            </div>
          );
        })}
      </div>
    </LightDarkContainer>
  );
};

export default SocietyDetails;
