import { gql } from "@apollo/client";

export const GET_FACTURES = gql`
  query ($societe: [ID]) {
    factures(
      pagination: { page: 1, pageSize: 100 }
      filters: { and: { societe: { id: { in: $societe } } } }
    ) {
      data {
        id
        attributes {
          number
          type
          date
          TVA
          TTC
          HT
          media {
            data {
              id
              attributes {
                url
                name
                size
                mime
              }
            }
          }
          societe {
            data {
              id
              attributes {
                name
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
