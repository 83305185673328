import React, { useEffect } from "react";
import { getInterviewResponses } from "../../../../../REDUX/actions/interviews";
import { useDispatch, useSelector } from "react-redux";
import InterviewPlaceHolder from "./InterviewPlaceHolder";
import LightDarkContainer from "../../../../Common/LightDarkContainer";
import LightDarkText from "../../../../Common/LightDarkText";

const InterviewResponsesList = ({ interviewModelId, interviewUserId }) => {
  const dispatch = useDispatch();
  const { interviewResponses, interviewLoading } = useSelector(
    (state) => state.interviewsReducer
  );
  useEffect(() => {
    dispatch(getInterviewResponses(interviewModelId, interviewUserId));
  }, [interviewUserId]);

  return interviewLoading || !interviewResponses ? (
    <InterviewPlaceHolder />
  ) : interviewResponses.attributes.responded ? (
    <div className="flex-1  p-4 d-flex flex-column gap-4">
      {interviewResponses.attributes.interview_questions.data.map((el) => {
        const foundResponse =
          interviewResponses.attributes.interview_responses.data.find(
            (response) =>
              response.attributes.interview_question.data.id === el.id
          );
        return (
          foundResponse && (
            <LightDarkContainer className="f12 p-3 radius10" key={el.id}>
              <LightDarkText
                className={"f14 bold"}
                color={"text-light-blue xbold"}
              >
                {`${el.attributes.logicOrder} - ${el.attributes.order} - ${el.attributes.title} `}
                {el.attributes.required && <span className="text-red">*</span>}
              </LightDarkText>
              <LightDarkText className={"bold ms-4 mt-1"}>
                {foundResponse?.attributes?.value}
              </LightDarkText>
            </LightDarkContainer>
          )
        );
      })}
    </div>
  ) : (
    <LightDarkText className="flex-1  p-4 d-flex flex-column gap-4 bold text-center">
      L'utilisateur n'a pas encore répondu au sondage
    </LightDarkText>
  );
};

export default InterviewResponsesList;
