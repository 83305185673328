import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../REDUX/actions/modal";
import ColoredButton from "../Common/Buttons/ColoredButton";
import LighDarkMultiChoice from "../Common/LighDarkMultiChoice";
import LightDarkText from "../Common/LightDarkText";
import { CloseIcon } from "../Common/LightDarkSvg";
import { addFolder } from "../../REDUX/actions/cloud";
import LightDarkInput from "../Common/LightDarkInput";

const CreateFolderForm = ({ subfolder = false, parentFolderId = null }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { canShareTo } = useSelector((state) => state.sharedDataReducer);

  const [folderInputs, setFolderInputs] = useState({
    name: "",
    sharedUsers: [],
  });

  return (
    <div
      className={`${mode === "light" ? "bg-white" : "bg-dark-mode"}
        p-4 pb-5 radius05 h-60vh d-flex flex-column`}
    >
      <div
        className="d-flex justify-content-end mb-3"
        onClick={() => dispatch(closeModal())}
      >
        <CloseIcon />
      </div>
      <div className="px-2">
        <LightDarkText className="text-center custom-title-font-size bold mx-5">
          {subfolder
            ? "Créer un nouveau sous dossier"
            : "Créer un nouveau dossier"}
        </LightDarkText>
      </div>
      <div
        className="flex-grow-1 d-flex flex-column justify-content-between"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="row my-5">
          <div className="col-12">
            <LightDarkInput
              label="Nom du dossier"
              placeholder="Nouveau dossier"
              type="text"
              totalInputs={folderInputs}
              setValue={setFolderInputs}
              champs="name"
              required={true}
            />
          </div>
          <div className="col-12 mt-5">
            <LighDarkMultiChoice
              label="Partager avec"
              data={canShareTo.filter((el) => el.value !== user.id)}
              totalInputs={folderInputs}
              setValue={setFolderInputs}
              champs="sharedUsers"
              placeholder="Sélectionner un ou plusieurs utilisateur(s)"
            />
          </div>
          <div className={`row mt-3`}>
            <LightDarkText
              className={"text-start bold custom-ticket-font-size mb-1"}
            >
              <span className="text-red">*</span>Champs obligatoires
            </LightDarkText>
          </div>
        </div>
        <div className="w-100 row mt-1 justify-content-center">
          <div className="col-5">
            <ColoredButton
              disabled={!folderInputs.name}
              textButton={"Créer le dossier"}
              color="light-blue"
              callback={() => {
                dispatch(
                  addFolder({
                    name: folderInputs?.name,
                    sharedUsers: folderInputs?.sharedUsers?.map(
                      (user) => user?.value
                    ),
                    parentFolderId: parentFolderId,
                    subFolder: subfolder,
                  })
                );
                dispatch(closeModal());
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFolderForm;
