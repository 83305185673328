import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteFile } from "../../REDUX/actions/cloud";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import { openModal } from "../../REDUX/actions/modal";
import {
  donwloadFile,
  fileExtention,
  formatDate,
  getFileSize,
} from "../../Utils/utils";
import LightDarkContainer from "../Common/LightDarkContainer";
import {
  CardActionsIcon,
  DeleteIcon,
  Download,
  EditIcon,
  EyeIcon,
  ShareIcon,
  Tickets,
} from "../Common/LightDarkSvg";
import LightDarkText from "../Common/LightDarkText";
import PreviewModal from "../Common/PreviewModal";

import ShareFile from "./ShareFile";
import useWindowWidth from "../../Hooks/useWindowWidth";
import RenameCloudItem from "./RenameCloudItem";
import { useNavigate } from "react-router";
import SettingTooltip from "../Common/Tooltip/SettingTooltip";
import TooltipActions from "../Common/Tooltip/TooltipActions";
import { extractPreviewData } from "./cloudUtils";

const AttachementListCard = ({ fichier, index, measureRef }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { ticketRoute } = useSelector((state) => state.organisationsReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);

  const navigate = useNavigate();
  const screenWidth = useWindowWidth();
  const [showTooltip, setshowTooltip] = useState(false);
  const [fileSize, setfileSize] = useState("");

  useEffect(() => {
    const getSize = async () => {
      setfileSize(await getFileSize(fichier));
    };
    getSize();
  }, []);

  const handleDeleteFile = () => {
    dispatch(
      openConfirmationModal({
        callBack: () => dispatch(deleteFile(fichier)),
        icon: "archive",
        message: "Voulez vous vraiment Supprimer le fichier",
        message2: `#${fichier.id} ${fichier?.attributes?.file?.data?.attributes?.name}`,
        confirmButtonText: "Supprimer le fichier",
      })
    );
  };
  const tooltipData = [
    {
      id: 1,
      text: "Voir",
      icon: <EyeIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openModal({
            type: "pdfFile",
            content: <PreviewModal fileData={extractPreviewData(fichier)} />,
          })
        );
      },
      condition:
        fichier?.attributes?.file?.data?.attributes?.mime?.includes("image") ||
        fichier?.attributes?.file?.data?.attributes?.mime?.includes("pdf"),
    },
    // {
    //   id: 2,
    //   text: "Partager",
    //   icon: <ShareIcon className="me-2" />,
    //   action: () => {
    //     setshowTooltip(false);
    //     dispatch(
    //       openModal({
    //         type: "small",
    //         content: (
    //           <ShareFile
    //             fileId={fichier?.id}
    //             fileSharedUsers={fichier?.attributes?.sharedUsers}
    //             fileFolder={fichier?.attributes?.folder?.data}
    //           />
    //         ),
    //       })
    //     );
    //   },
    //   condition:
    //     user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud?.data
    //       ?.id === fichier?.attributes?.cloud?.data?.id || isSupervisor,
    // },
    {
      id: 3,
      text: "Télécharger",
      icon: <Download className="me-2" size={16} />,
      action: () => {
        setshowTooltip(false);
        donwloadFile(fichier);
      },
      condition: true,
    },
    {
      id: 4,
      text: "Voir le ticket",
      icon: <Tickets className="me-2" size={16} darkColor="#354074" />,
      action: () => {
        setshowTooltip(false);
        window.open(`${ticketRoute}/${fichier.attributes.ticket.data.id}`);
      },
      condition: true,
    },
    // {
    //   id: 5,
    //   text: "Renommer",
    //   icon: <EditIcon className="me-2" />,
    //   action: () => {
    //     setshowTooltip(false);
    //     dispatch(
    //       openModal({
    //         type: "small",
    //         content: (
    //           <RenameCloudItem
    //             id={fichier.id}
    //             name={
    //               fichier?.attributes?.name
    //                 ? fichier?.attributes?.name
    //                 : fichier?.attributes.file.data?.attributes?.name?.replace(
    //                     `.${fileExtention(fichier)}`,
    //                     ""
    //                   )
    //             }
    //             isFile={true}
    //             folderId={fichier?.attributes?.folder?.data?.id}
    //           />
    //         ),
    //       })
    //     );
    //   },
    //   condition:
    //     user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud?.data
    //       ?.id === fichier?.attributes?.cloud?.data?.id ||
    //     user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud?.data
    //       ?.id ===
    //       fichier?.attributes?.folder?.data?.attributes?.cloud?.data?.id ||
    //     isSupervisor,
    // },
    // {
    //   id: 6,
    //   text: "Supprimer",
    //   icon: <DeleteIcon className="me-2" />,
    //   action: () => {
    //     setshowTooltip(false);
    //     handleDeleteFile();
    //   },
    //   condition:
    //     user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud?.data
    //       ?.id === fichier?.attributes?.cloud?.data?.id ||
    //     user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud?.data
    //       ?.id ===
    //       fichier?.attributes?.folder?.data?.attributes?.cloud?.data?.id ||
    //     isSupervisor,
    // },
  ];

  return (
    <div ref={measureRef}>
      <LightDarkContainer
        key={index}
        className="d-flex py-3 px-3 pointer"
        colorLight="white"
        colorDark="dark-grey-bg"
        hover={true}
        cardContainer={true}
      >
        <div className="col-1 w5  alignH ">
          <LightDarkText
            className={"custom-ticket-font-size bold text-start"}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            #{fichier.id}
          </LightDarkText>
        </div>

        <div className="col-1 alignH  w5">
          <LightDarkText
            className={"custom-ticket-font-size bold text-start"}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            #{fichier.attributes.ticket?.data?.id}
          </LightDarkText>
        </div>
        <div className="col-2 alignH ">
          <LightDarkText
            className={"custom-ticket-font-size bold text-start"}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {fichier.attributes.ticket?.data?.attributes?.name}
          </LightDarkText>
        </div>
        <div className="col-2 alignH ">
          <LightDarkText
            className={"custom-ticket-font-size bold text-start"}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {
              fichier.attributes.ticket?.data?.attributes?.societe?.data
                ?.attributes?.name
            }
          </LightDarkText>
        </div>

        <div className={`col-3  alignH `}>
          <LightDarkText
            className={"custom-ticket-font-size bold text-start text-ellipsis"}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {fichier?.attributes?.name
              ? `${fichier?.attributes?.name}.${fileExtention(fichier)}`
              : fichier?.attributes.file.data?.attributes.name}
          </LightDarkText>
        </div>
        <div className={`col-2  alignH `}>
          <LightDarkText
            className={"custom-ticket-font-size bold text-start text-ellipsis"}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {formatDate(fichier?.attributes?.file?.data?.attributes?.createdAt)}
          </LightDarkText>
        </div>
        <div className={`col-1 alignC `}>
          <LightDarkText
            className={"custom-ticket-font-size bold text-start"}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {fileSize}
          </LightDarkText>
        </div>
        <div className="col  alignH  justify-content-end">
          <div
            className="pointer position-relative"
            onClick={() => setshowTooltip(!showTooltip)}
          >
            <TooltipActions
              setshowTooltip={setshowTooltip}
              showTooltip={showTooltip}
              tooltipData={tooltipData}
            />
          </div>
        </div>
      </LightDarkContainer>
    </div>
  );
};

export default AttachementListCard;
