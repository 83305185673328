import React, { useEffect, useState } from "react";
import file from "../../../Images/apiIcons/file.svg";
import LightDarkText from "../../Common/LightDarkText";
import { useSelector } from "react-redux";
import { Check, ExternalLoaderSvg } from "../../Common/LightDarkSvg";

const ExternalLoaderItem = ({ element }) => {
  const { copiedItems } = useSelector((state) => state.externalServicesReducer);
  const [isLoading, setisLoading] = useState(
    copiedItems.some((file) => file.file.id === element.id)
  );
  useEffect(() => {
    setisLoading(copiedItems.some((file) => file.file.id === element.id));
  }, [copiedItems]);

  return (
    <LightDarkText
      className={
        "externalLoadingItem radius08 p-2 f14 d-flex alignH w-100 justify-content-between"
      }
    >
      <img src={file} alt="check" className="me-2" />
      <div className="text-ellipsis flex-1 w90 text-start bold">
        {element.name}{" "}
      </div>
      {!isLoading ? <ExternalLoaderSvg /> : <Check />}
    </LightDarkText>
  );
};

export default ExternalLoaderItem;
