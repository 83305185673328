export const ATTRIBUTES_PLAN = `
  monthlyPlan
  consumption_0
  consumption_1
  consumption_2
  monthlyQuota
  date
  name
  societe {
    data {
      id
    }
  }
`;
