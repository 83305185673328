import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../Common/Loading";
import InterventionDetails from "./InterventionDetails";
import InterventionTicketDetails from "./InterventionTicketDetails";
import InterventionHistoriqueDetails from "./InterventionHistoriqueDetails";
import InterventionActions from "../InterventionActions/InterventionActions";

import { getInterventionById } from "../../../REDUX/actions/interventions";

const InterventionSideBar = ({ selectedIntervention, isToggle }) => {
  const dispatch = useDispatch();

  const { intervention } = useSelector((state) => state.interventionsReducer);

  useEffect(() => {
    dispatch(getInterventionById(selectedIntervention));
  }, [selectedIntervention]);

  return intervention ? (
    <div className="d-flex flex-column justify-content-between h100 sideChatListContainer notScrollable">
      <>
        {isToggle === "intervention" && (
          <InterventionDetails intervention={intervention} />
        )}
        {isToggle === "ticket" && (
          <InterventionTicketDetails intervention={intervention} />
        )}
        {isToggle === "history" && (
          <InterventionHistoriqueDetails intervention={intervention} />
        )}
      </>
      {intervention?.attributes?.statut !== 4 &&
        intervention?.attributes?.statut !== 5 && (
          <div className="py-3">
            <InterventionActions
              intervention={intervention}
              ticket={intervention?.attributes?.ticket?.data}
            />
          </div>
        )}
    </div>
  ) : (
    <Loading />
  );
};

export default InterventionSideBar;
