import { gql } from "@apollo/client";
import { DIFFUSION_ATTRIBUTES } from "../Attributes/Alerts";

export const CREATE_DIFFUSION = gql`
  mutation ($titre: String,$description: String, $link: String, $image: ID, $creator: ID,$visibleBy:[ID] ,$departements:[ID],$isFullWidth:Boolean,$expirationDate:Date,$organisation:ID) {
    createDiffusion(
      data: { 
        titre: $titre
        description: $description
        link: $link
        image: $image
        creator: $creator
        departements: $departements
        visibleBy: $visibleBy
        isFullWidth: $isFullWidth
        expirationDate: $expirationDate
        organisation:$organisation

      }
    ) 
    {
      data {
        id
        attributes {
          ${DIFFUSION_ATTRIBUTES}
        }
      }
    }
  }
`;
export const UPDATE_DIFFUSION = gql`
  mutation (    $id: ID!,$titre: String,$description: String, $link: String, $image: ID, $creator: ID,$visibleBy:[ID],$departements:[ID] ,$is_fullWidth:Boolean,$expirationDate:Date) {
    updateDiffusion(id: $id,
      data: { 
        titre: $titre
        description: $description
        link: $link
        image: $image
        creator: $creator
        visibleBy: $visibleBy
        isFullWidth: $is_fullWidth
        expirationDate: $expirationDate
        departements: $departements
      }
    ) 
    {
      data {
        id
        attributes {
          ${DIFFUSION_ATTRIBUTES}
        }
      }
    }
  }
`;

export const DELETE_DIFFUSION = (id) => gql`
mutation {
  deleteDiffusion(id: ${id})
    {
      data {
        id
      }
    }
}
`;
