import moment from "moment";

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkText from "../Common/LightDarkText";
import StatusButton from "../Common/Buttons/StatusButton";

import { handleProspectStatut, renderOriginText } from "../../Utils/utils";
import UserGroupThumbnail from "../Common/UserGroupThumbnail";

const ProspectItem = ({ dataItem, accessor, table = true }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      {accessor === "id" ? (
        <LightDarkText
          className={
            "pointer fitContent custom-ticket-font-size bold text-start"
          }
          color={mode === "light" ? "text-blue-grey" : "text-light-text"}
        >
          #{dataItem[accessor]}
        </LightDarkText>
      ) : accessor === "origin" ? (
        <div className={`${!table && "wContent"}`}>
          {dataItem[accessor] && (
            <StatusButton
              height={"40px"}
              classname={`text-uppercase px-3 ${table && "w-100"}`}
              type={
                dataItem[accessor] === "WEBSITE" || dataItem[accessor] === "CHR"
                  ? "light-blue"
                  : "orange"
              }
              text={renderOriginText(dataItem[accessor])}
              ticketButton={true}
              background={
                dataItem[accessor] === "WEBSITE"
                  ? "#3365FF40"
                  : dataItem[accessor] === "CHR"
                  ? "#3365FF20"
                  : " #FF721B40"
              }
            />
          )}
        </div>
      ) : accessor === "status" ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <LightDarkText className="custom-ticket-font-size py-1 text-white">
                {handleProspectStatut(dataItem[accessor])?.text}
              </LightDarkText>
            </Tooltip>
          }
          show={showTooltip}
        >
          <div
            className={`${!table && "wContent"}`}
            onMouseEnter={() => table && setShowTooltip(true)}
            onMouseLeave={() => table && setShowTooltip(false)}
          >
            {dataItem[accessor] && (
              <StatusButton
                height={"40px"}
                classname={"px-1"}
                type={handleProspectStatut(dataItem[accessor])?.color}
                ticketButton={true}
                text={handleProspectStatut(dataItem[accessor])?.text}
                background={handleProspectStatut(dataItem[accessor])?.bg}
              />
            )}
          </div>
        </OverlayTrigger>
      ) : accessor === "actors" ? (
        <div className="alignH px-2">
          <UserGroupThumbnail
            participants={dataItem.actors}
            modalTitle={`Acteurs du prospect #${dataItem.id}`}
          />
        </div>
      ) : accessor === "dateLivraison" && dataItem[accessor] ? (
        <LightDarkText
          className={`pointer text-ellipsis custom-ticket-font-size bold text-start`}
          color={mode === "light" ? "text-blue-grey" : "text-light-text"}
        >
          {moment(dataItem[accessor]).format("DD.MM.YYYY")}
        </LightDarkText>
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              <LightDarkText className="custom-ticket-font-size py-1 text-white">
                {dataItem[accessor]}
              </LightDarkText>
            </Tooltip>
          }
          show={showTooltip}
        >
          <div
            onMouseEnter={() => table && setShowTooltip(true)}
            onMouseLeave={() => table && setShowTooltip(false)}
          >
            <LightDarkText
              className={`pointer text-ellipsis  custom-ticket-font-size bold text-start`}
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
            >
              {dataItem[accessor]}
            </LightDarkText>
          </div>
        </OverlayTrigger>
      )}
    </>
  );
};
export default ProspectItem;
