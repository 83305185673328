import { userAttributes } from "../general/userFields";

export const ALERT_ATTRIBUTES = `
description
createdAt
url
operators
administrators
collaborators
lectors
seenBy (pagination: { pageSize: 1000 }) {
  data {
    id
    attributes {
      ${userAttributes}
      role {
        data {
          id
          attributes {
            name
          }
        }
      }
    }
  }
}
image {
    data {
          id
          attributes {
              url
              name
              size
              mime
          }
        }
      }
creator {
  data {
    id
    attributes {
      ${userAttributes}
      role {
        data {
          id
          attributes {
            name
          }
        }
      }
      poste {
        data {
          id
          attributes {
            name
          }
        }
      }
    }
  }
}
`;

export const DIFFUSION_ATTRIBUTES = `
titre
description
createdAt
link
isFullWidth
expirationDate

visibleBy{
  data{
    id
    attributes{
      name
      user{
        data{
          id
        }
      }
    }
  }
}
departements {
    data {
          id
          attributes {
              code
              name
              
          }
        }
      }
image {
    data {
          id
          attributes {
              url
              name
              
              mime
          }
        }
      }
creator {
  data {
    id
    attributes {
      ${userAttributes}
      role {
        data {
          id
          attributes {
            name
          }
        }
      }
      poste {
        data {
          id
          attributes {
            name
          }
        }
      }
    }
  }
}
`;
