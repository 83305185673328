import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import UsersList from "../../Components/Clients/UsersList";
import UserSideBar from "../../Components/Clients/UserSideBar";
import UserForm from "../../Components/Clients/UserForm/UserForm";
import SideBar from "../../Components/Common/TableComponents/SideBar";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import { openSideBar as openSideBarAction } from "../../REDUX/actions/sideBar";

import { openModal } from "../../REDUX/actions/modal";
import { closeSideBarCard } from "../../REDUX/actions/general";
import { resetClientFilters } from "../../REDUX/actions/filters";
import { userSideBarTabs } from "../../Components/Societies/SocietyForm/societyFormUtils";
import { getConditionalUsers } from "../../REDUX/actions/clients";
import LightDarkText from "../../Components/Common/LightDarkText";
import { SwitchIcon } from "../../Components/Common/LightDarkSvg";
import LightDarkContainer from "../../Components/Common/LightDarkContainer";
import ToggleArchiveSwitch from "../../Components/Common/ToggleArchiveSwitch";

const ClientsView = ({ clientVue, setClientVue }) => {
  const dispatch = useDispatch();

  const { openSideBar } = useSelector((state) => state.genearlReducer);
  const { canCreateCollabs, isSupervisor, canSeeCollabs } = useSelector(
    (state) => state.roleReducer
  );
  const { selectedUser } = useSelector((state) => state.clientsReducer);
  const { clientArchived } = useSelector((state) => state.filtersReducer);
  const [isToggle, setIsToggle] = useState("details");
  const [searchClients, setsearchClients] = useState("");

  useEffect(() => {
    return () => dispatch(resetClientFilters());
  }, [dispatch]);

  return (
    <div className="container-fluid">
      <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className={`${canCreateCollabs ? "col-10" : "col-12"} h100`}>
          <ListHeader showSocityFilter={false} showCollabsArchive>
            <LightDarkContainer
              colorLight="white"
              className="alignH w-100 gap-4"
            >
              <SearchInput
                placeholder="ID, nom et prénom, adresse, email, téléphone..."
                onChange={(e) => {
                  setsearchClients(e.target.value);
                  dispatch(closeSideBarCard());
                }}
                width={"w100"}
              />
              {canSeeCollabs ? (
                <div
                  className="alignH xbold f12 gap-2"
                  onClick={() => setClientVue(!clientVue)}
                >
                  <LightDarkText
                    color={`${
                      clientVue ? "text-light-text" : "text-light-blue"
                    }`}
                  >
                    Sociétés
                  </LightDarkText>

                  <SwitchIcon value={clientVue} />
                  <LightDarkText
                    color={`${
                      clientVue ? "text-light-blue" : "text-light-text"
                    }`}
                  >
                    Clients
                  </LightDarkText>
                </div>
              ) : null}
              <ToggleArchiveSwitch
                absolute={false}
                totalObject={{ clientArchived: !clientArchived }}
                archived={clientArchived}
              />
            </LightDarkContainer>
          </ListHeader>
        </div>
        {canCreateCollabs && (
          <div className="col-2 ms-2 h100">
            <ColoredButton
              textButton="Ajouter un client"
              color="light-blue"
              height="100"
              callback={() => {
                dispatch(
                  openSideBarAction({
                    content: <UserForm isNew={true} isOp={false} />,
                    type: "w40",
                  })
                );
              }}
            />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between gap-3 p-0">
        <UsersList search={searchClients} />
        {openSideBar && (
          <SideBar
            selectedItem={selectedUser}
            setSelectedItem={() => {}}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            sideBarHeaderInfo
            userSideBar
            tabsData={userSideBarTabs}
            tabsProps={{ isSupervisor }}
          >
            <UserSideBar isToggle={isToggle} />
          </SideBar>
        )}
      </div>
    </div>
  );
};

export default ClientsView;
