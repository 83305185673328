import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSharePointSite } from "../../REDUX/actions/externalServices";
import OneDriveFodlerList from "../../Components/Cloud/OneDrive/OneDriveFodlerList";
import OneDriveFileList from "../../Components/Cloud/OneDrive/OneDriveFileList";
import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import SharePointSiteName from "../../Components/Cloud/OneDrive/SharePointSiteName";
import { ChevronRight } from "../../Components/Common/LightDarkSvg";
import LightDarkText from "../../Components/Common/LightDarkText";

const SharePointSiteView = ({ setActiveTab, activeTab }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sharePointSiteFiles, sharePointSiteFolders, oneDriveLoading } =
    useSelector((state) => state.externalServicesReducer);
  const { documentsRoute } = useSelector((state) => state.organisationsReducer);
  const [searchFiles, setsearchFiles] = useState("");
  useEffect(() => {
    dispatch(getSharePointSite(params.id));
  }, [dispatch, params.id]);
  return oneDriveLoading ? (
    <Loading showLoader />
  ) : (
    <div>
      <div className="container-fluid">
        <div className="d-flex flex-row justify-content-between align-items-center h50px mb-2">
          <div className="col-12 h100">
            <ListHeader showSocityFilter={false} Archives={true}>
              <SearchInput onChange={(e) => setsearchFiles(e.target.value)} />
            </ListHeader>
          </div>
        </div>

        <LightDarkText
          className="alignH gap-3 bold pointer"
          onclickCallback={() => {
            navigate(documentsRoute), setActiveTab(activeTab);
          }}
        >
          {activeTab === "onedrive" ? "OneDrive" : "OneDrive"}
          <ChevronRight darkColor="black" />
          <SharePointSiteName />
        </LightDarkText>

        <OneDriveFodlerList
          search={searchFiles}
          oneDriveFolders={sharePointSiteFolders}
          siteId={params.id}
        />

        <OneDriveFileList
          search={searchFiles}
          oneDriveFiles={sharePointSiteFiles}
          siteId={params.id}
        />
      </div>
    </div>
  );
};

export default SharePointSiteView;
