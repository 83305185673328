import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import SocietyFormHeader from "./SocietyFormHeader";
import SocietyInfosForm from "./SocietyInfosForm";
import {
  checkRequiredSocietyInputs,
  societyFields,
  SocietyFormTabs,
} from "./societyFormUtils";
import {
  CreateSociete,
  UpdateSociete,
  getSocietyForm,
} from "../../../REDUX/actions/societe";
import "react-phone-number-input/style.css";
import LightDarkText from "../../Common/LightDarkText";
import { closeSideBar } from "../../../REDUX/actions/sideBar";
import SocietyFormPlaceHolder from "../../PlaceHolders/Society/SocietyFormPlaceHolder";
import SocietySocialMediaForm from "./SocietySocialMediaForm";
import FeatComingSoon from "../../Common/FeatComingSoon";
import SocietyLinkedSocietiesForm from "./SocietyLinkedSocietiesForm";
import { socialMediaValidationLink } from "../../../Utils/utils";
import { useNavigate } from "react-router-dom";

const SocietyForm = ({ isNew, societyId, isLinked }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mode } = useSelector((state) => state.genearlReducer);

  const { societyForm, societyFormLoading } = useSelector(
    (state) => state.societesReducer
  );
  const { sideBarToggle } = useSelector((state) => state.sideBarReducer);
  const [avatar, setAvatar] = useState();
  const [isToggle, setIsToggle] = useState(sideBarToggle || "infos");
  const [societyInputs, setsocietyInputs] = useState(societyForm);
  const { societyTypesValues } = useSelector(
    (state) => state.sharedDataReducer
  );
  const [currentStep, setCurrentStep] = useState(0);

  const handleSubmitSociety = () => {
    const processedInputs = {
      ...societyInputs,
      facebookLink: socialMediaValidationLink(
        societyInputs.facebookLink,
        "facebookLink"
      ),
      xLink: socialMediaValidationLink(societyInputs.xLink, "xLink"),
      linkedinLink: socialMediaValidationLink(
        societyInputs.linkedinLink,
        "linkedinLink"
      ),
      instagramLink: socialMediaValidationLink(
        societyInputs.instagramLink,
        "instagramLink"
      ),
      tikTokLink: socialMediaValidationLink(
        societyInputs.tikTokLink,
        "tikTokLink"
      ),
      siret: societyInputs.siret || "Non mentionnée",
      siteWeb: societyInputs.siteWeb || "Non mentionnée",
    };

    if (isNew) {
      dispatch(CreateSociete(processedInputs, avatar, navigate));
    } else {
      dispatch(
        UpdateSociete({ ...processedInputs, id: societyId }, avatar, isLinked)
      );
    }
    dispatch(closeSideBar());
  };

  const isAnyInvalidLink = () =>
    Object.keys(societyInputs).some(
      (key) =>
        key.endsWith("Link") &&
        !socialMediaValidationLink(societyInputs[key], key) &&
        societyInputs[key]
    );

  useEffect(() => {
    if (!isNew) {
      dispatch(getSocietyForm(societyId));
    }
  }, []);

  useEffect(() => {
    setsocietyInputs(societyFields(societyForm));
  }, [societyForm]);

  const currentImage =
    societyForm?.attributes?.avatar?.data?.attributes?.image?.data?.attributes
      ?.url;

  const handleNextStep = () => {
    if (isLinked) {
      handleSubmitSociety();
    } else if (currentStep === SocietyFormTabs().length - 1) {
      handleSubmitSociety();
    } else {
      setCurrentStep(currentStep + 1);
      setIsToggle(SocietyFormTabs()[currentStep + 1].toggleValue);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
    setIsToggle(SocietyFormTabs()[currentStep - 1].toggleValue);
  };

  return societyFormLoading || !societyInputs.childCompanies ? (
    <SocietyFormPlaceHolder />
  ) : (
    <div
      className={`${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      } radius05 notScrollable h100 d-flex flex-column justify-content-between`}
    >
      <SocietyFormHeader
        isToggle={isToggle}
        setIsToggle={setIsToggle}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isLinked={isLinked}
      />

      <div className="px-4 flex-1 scrollable py-4">
        {isToggle === "infos" && (
          <SocietyInfosForm
            setAvatar={setAvatar}
            avatar={avatar}
            currentImage={currentImage}
            societyInputs={societyInputs}
            setsocietyInputs={setsocietyInputs}
          />
        )}
        {isToggle === "social_media" && (
          <SocietySocialMediaForm
            setAvatar={setAvatar}
            avatar={avatar}
            currentImage={currentImage}
            societyInputs={societyInputs}
            setsocietyInputs={setsocietyInputs}
          />
        )}
        {isToggle === "link_societies" && (
          <SocietyLinkedSocietiesForm
            societyId={societyId}
            societyInputs={societyInputs}
            setsocietyInputs={setsocietyInputs}
          />
        )}
        {isToggle === "payment" && (
          <FeatComingSoon title="Fonctionnalité de paiement </br> prochainement disponible." />
        )}
        {isToggle === "facturation" && (
          <FeatComingSoon title="Fonctionnalité de facturation </br> prochainement disponible." />
        )}
      </div>
      <div className="alignH justify-content-between py-3 px-4">
        <LightDarkText className="text-start bold custom-ticket-font-size">
          <span className="text-red">*</span>Champs obligatoires
        </LightDarkText>
        <div className="d-flex gap-3 align-items-center">
          {currentStep > 0 && (
            <ColoredButton
              textButton="Précédent"
              color="dark-grey"
              callback={handlePreviousStep}
            />
          )}
          <div className="">
            <ColoredButton
              disabled={
                checkRequiredSocietyInputs(societyInputs, societyTypesValues) ||
                isAnyInvalidLink()
              }
              textButton={
                isLinked
                  ? "Enregistrer"
                  : currentStep === SocietyFormTabs().length - 1
                  ? "Enregistrer"
                  : "Suivant"
              }
              color="light-blue"
              callback={handleNextStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocietyForm;
