import { useSelector } from "react-redux";

import ColoredButton from "../../Common/Buttons/ColoredButton";

const TicketRedirectionButton = ({ ticketId }) => {
  const { ticketRoute } = useSelector((state) => state.organisationsReducer);

  return (
    <div className="mt-4">
      <ColoredButton
        textButton="Voir le ticket"
        color="blue-grey"
        outlined
        callback={() => {
          window.open(`${ticketRoute}/${ticketId}`);
        }}
      />
    </div>
  );
};

export default TicketRedirectionButton;
