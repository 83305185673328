import React, { useState } from "react";
import {
  CardActionsIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
} from "../Common/LightDarkSvg";
import LightDarkText from "../Common/LightDarkText";
import SettingTooltip from "../Common/Tooltip/SettingTooltip";
import Tooltipitem from "../Common/Tooltip/Tooltipitem";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCategory,
  updateCategory,
} from "../../REDUX/actions/form_category";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import { useEffect } from "react";
import { getHighestPosition } from "../../Utils/utils";
const CategoryItem = ({
  item,
  index,
  isNew = false,
  selectedCategory,
  // highestPosition,
  setNewCategory,
  isForm,
  validStep,
  setName,
  updateItem,
  setUpdateItem,
}) => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.formCategoriesReducer);
  const { mode } = useSelector((state) => state.genearlReducer);
  const [showTooltip, setshowTooltip] = useState(false);

  const handleUpdateClick = () => {
    setName(item?.attributes?.name);
    setUpdateItem(item?.id);
    setshowTooltip(false);
  };
  useEffect(() => {
    setUpdateItem(null);
  }, [selectedCategory]);
  const tooltipData = [
    {
      id: 1,
      text: "Modifier",
      icon: <EditIcon className="me-2" />,
      action: () => {
        handleUpdateClick();
      },
    },
    {
      id: 2,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" />,
      action: () =>
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(deleteCategory(item));
            },
            message: "Êtes-vous sur de supprimer cette categorie?",
            message2:
              "En confirmant la suppression, la categorie sera définitivement supprimée.",
            confirmButtonText: "Je confirme la suppression",
          })
        ),
    },
  ];

  const handleDown = () => {
    const itemPosition = item?.attributes?.position;
    const pos = getHighestPosition(categories);

    if (itemPosition < pos) {
      const nextItem = categories.find(
        (el) => el.attributes.position === itemPosition + 1
      );
      const newItems = [
        { id: item.id, position: itemPosition + 1 },
        { id: nextItem.id, position: itemPosition },
      ];
      for (const newItem of newItems) {
        dispatch(updateCategory(newItem));
      }
    }
  };
  const handleUp = () => {
    const itemPosition = item?.attributes?.position;
    if (itemPosition > 1) {
      const previousItem = categories.find(
        (el) => el.attributes.position === itemPosition - 1
      );
      const newItems = [
        { id: item.id, position: itemPosition - 1 },
        { id: previousItem.id, position: itemPosition },
      ];
      for (const newItem of newItems) {
        dispatch(updateCategory(newItem));
      }
    }
  };

  return (
    <div
      className={`d-flex flex-row align-items-center mt-3 pointer z-index-1  `}
    >
      {!isForm && (
        <div className="w10  d-flex flex-column">
          <i
            className="bi bi-chevron-up f18 "
            style={{ color: "#A9BCD3" }}
            onClick={handleUp}
          ></i>
          <i
            className="bi bi-chevron-down f18 "
            style={{ color: "#A9BCD3" }}
            onClick={handleDown}
          ></i>
        </div>
      )}
      <div
        className={` ${
          isForm ? "w-100" : "w90"
        }  d-flex flex-row align-items-center ms-1  py-3 ${
          !isForm && mode === "light" ? "bg-light-gray" : "#181f3a81"
        } rounded ${
          item?.id === selectedCategory?.id &&
          !isNew &&
          `selectedCategory  ${
            mode === "light" ? "bg-light-gray" : "#181f3a81"
          }`
        }`}
      >
        <div
          className="categoryPosition xbold f14 mx-2 "
          style={{
            backgroundColor:
              !isNew && item?.id === selectedCategory?.id
                ? "#354074"
                : validStep?.includes(+item?.attributes?.position)
                ? "#0CCE91"
                : isForm
                ? "#A9BCD3"
                : "#FFFFFF",
            color: isNew
              ? "#354074"
              : item?.id !== selectedCategory?.id &&
                !validStep?.includes(+item?.attributes?.position)
              ? "#354074"
              : "#FFFFFF",
          }}
        >
          {!isNew ? index + 1 : ""}
          {/* {!isNew
            ? item?.attributes?.position
            : highestPosition
            ? highestPosition + 1
            : 1} */}
        </div>
        {isForm ? (
          <LightDarkText
            className={`ms-2 w70 custom-font-size bold text-start`}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {item?.attributes?.name}
          </LightDarkText>
        ) : isNew ? (
          <input
            className={`categoryInput ms-2 w70 custom-font-size bold text-start p-2 radius05 ${
              mode === "light" ? "lightInput" : "darkInput"
            }  border-0 `}
            autoFocus
            onChange={(e) => setName(e.target.value)}
          />
        ) : item?.id === updateItem ? (
          <input
            className={`categoryInput ms-2 w70 f14 bold text-start p-2 radius05 ${
              mode === "light" ? "lightInput" : "darkInput"
            } border-0 `}
            autoFocus
            defaultValue={item?.attributes?.name}
            onChange={(e) => setName(e.target.value)}
          />
        ) : (
          <LightDarkText
            className="ms-2 w70 custom-font-size bold text-start"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {item?.attributes?.name}
          </LightDarkText>
        )}
        {!isForm && (
          <div className="w20 d-flex justify-content-end">
            {isNew ? (
              <div className="me-2">
                <CloseIcon clickCallback={() => setNewCategory(false)} />
              </div>
            ) : item?.id === updateItem ? (
              <div className="me-3">
                <CloseIcon clickCallback={() => setUpdateItem(null)} />
              </div>
            ) : (
              <div className="me-3">
                <CardActionsIcon clickCallback={() => setshowTooltip(true)} />
                <div
                  style={{
                    position: "relative",
                    left: "-100px",
                    top: "5px",
                    zIndex: 500,
                  }}
                >
                  <SettingTooltip
                    showTooltip={showTooltip}
                    setshowTooltip={setshowTooltip}
                  >
                    {tooltipData.map(({ id, text, action, icon }, index) => {
                      return (
                        <Tooltipitem
                          key={id}
                          id={id}
                          text={text}
                          icon={icon}
                          action={action}
                        />
                      );
                    })}
                  </SettingTooltip>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryItem;
