import React from "react";
import LightDarkContainer from "../Common/LightDarkContainer";
import { fileUrl, formatDate } from "../../Utils/utils";
import LightDarkText from "../Common/LightDarkText";
import { Check } from "../Common/LightDarkSvg";
import ColoredButton from "../Common/Buttons/ColoredButton";
import { handledesynchronize } from "../../REDUX/actions/externalServices";
import { useDispatch } from "react-redux";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";

const ServiceCard = ({ externalService }) => {
  const dispatch = useDispatch();
  const handleDeleteExternalService = () => {
    dispatch(
      openConfirmationModal({
        callBack: () => {
          dispatch(handledesynchronize(externalService.id));
        },
        message: `Êtes-vous sur de vouloir désynchroniser ${externalService.type.name}`,

        confirmButtonText: "Je confirme",
      })
    );
  };
  return (
    <LightDarkContainer className="p-3 radius10 d-flex flex-column gap-3 w-100">
      <div className="alignH justify-content-between">
        <div className="alignH gap-3">
          <img
            src={fileUrl(externalService.type.logo.url)}
            alt={externalService.type.name}
          />
          <LightDarkText className={"bold"}>
            {externalService.type.name}
          </LightDarkText>
        </div>
        <Check fill="#0CCE91" />
      </div>
      {/* <LightDarkText className={"f10"}>
        {externalService.type.description}
      </LightDarkText> */}
      <LightDarkText className={"f12 xbold"}>
        Connecté en tant que :
      </LightDarkText>
      <div className={"f12 d-flex flex-column gap-2"}>
        <LightDarkText>
          Nom : <br />
          <span className="bold">{externalService.accountName}</span>
        </LightDarkText>
        <LightDarkText>
          Email : <br />
          <span className="bold">{externalService.accountEmail}</span>
        </LightDarkText>
        <LightDarkText>
          Date de synchronisation : <br />
          <span className="bold">{formatDate(externalService.createdAt)}</span>
        </LightDarkText>
      </div>
      <div className="w30 align-self-end">
        <ColoredButton
          callback={handleDeleteExternalService}
          textButton={"Désynchroniser"}
          color={"light-red"}
          outlined
        />
      </div>
    </LightDarkContainer>
  );
};

export default ServiceCard;
