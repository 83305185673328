import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";

import useWindowWidth from "../../../../Hooks/useWindowWidth";
import { Calendar, GlobalIcon } from "../../../Common/LightDarkSvg";

const EventType = ({ type, eventsList = false, eventSearchCard = false }) => {
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {type && (
        <div
          className={`d-flex align-items-center ${
            !eventsList ? "my-4" : "mt-2"
          }`}
        >
          {type === "Marketing" ? (
            <GlobalIcon
              dark={!eventsList}
              className="me-2"
              small={eventsList}
              light={eventsList || eventSearchCard}
              eventsList={eventsList}
            />
          ) : (
            <Calendar
              sideBar={!eventsList}
              className="me-2"
              small={eventsList}
              light={eventsList || eventSearchCard}
              eventsList={eventsList}
              eventSearchCard={eventSearchCard}
            />
          )}
          <LightDarkText
            className={`
           ${`${screenWidth > 1200 && !eventsList ? "f14" : "f12"}`}
        text-start d-flex align-items-center ${
          eventsList || eventSearchCard ? "text-white" : "bold"
        }`}
            style={{ opacity: `${eventsList && "60%"}` }}
          >
            {type}
          </LightDarkText>
        </div>
      )}
    </>
  );
};

export default EventType;
