import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import LightDarkText from "../Common/LightDarkText";
import LightDarkContainer from "../Common/LightDarkContainer";
import moment from "moment";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DoubleBarChart = ({
  color,
  title,
  color2,
  customHeight,

  stats,
  societyFormChart = false,
  operatorFormChart = false,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const [currStats, setCurrStats] = useState();
  const [labels, setLabels] = useState();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        align: "end",
        labels: {
          usePointStyle: true,
          borderRadius: 50,
          color: "#59638B",
          font: {
            size: 13,
            weight: "regular",
          },
          pointStyle: "circle",
          boxWidth: 12,
          boxHeight: 12,
        },
      },
      tooltip: {
        callbacks: {
          afterLabel: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              return operatorFormChart ? " interventions" : " tickets";
            }
            return label;
          },
        },
      },
    },
    gridLines: {
      display: false,
      drawOnChartArea: false,
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#86909c80",
          font: {
            weight: "regular",
            size: 10,
          },
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: true,
          color: "#86909c20",
          borderColor: "transparent",
        },
      },
    },
  };
  const data = {
    labels: labels?.map((el) => el?.name),
    datasets: [
      {
        label: operatorFormChart ? "Total" : "Ouvert",
        data: currStats?.openTickets,
        backgroundColor: color,
        barThickness: 10,
        maxBarThickness: 20,
        borderRadius: 20,
        hoverBorderWidth: 0,
      },
      {
        label: operatorFormChart ? "Finalisées" : "Résolu",
        data: currStats?.resolvedTickets,
        backgroundColor: color2,
        barThickness: 10,
        borderRadius: 20,
        hoverBorderColor: "black",
        hoverBorderWidth: 0,
      },
    ],
  };
  useEffect(() => {
    if (!stats) return;
    const last12Months = [];
    for (let i = 0; i < 12; i++) {
      const monthIndex = moment().subtract(i, "months").format("MM", "fr");
      const month = moment().subtract(i, "months").format("MMM", "fr");
      const year = moment().subtract(i, "months").format("YYYY", "fr");
      last12Months.push({ name: month, value: monthIndex, year });
    }
    setLabels(last12Months.reverse());
    setCurrStats({
      openTickets: last12Months?.map(({ value, year }) => {
        const stat =
          stats?.openTickets?.find((v) => {
            const currMonth = moment(v.month).format("MM", "FR");
            const currYear = moment(v.month).format("YYYY", "FR");
            return currMonth === value && currYear === year;
          })?.count || 0;

        return stat;
      }),
      resolvedTickets: last12Months?.map(({ value, year }) => {
        const stat =
          stats?.resolvedTickets?.find((v) => {
            const currMonth = moment(v.month).format("MM", "FR");
            const currYear = moment(v.month).format("YYYY", "FR");
            return currMonth === value && currYear === year;
          })?.count || 0;

        return stat;
      }),
    });
  }, [stats]);
  return (
    <LightDarkContainer
      className={`${
        societyFormChart ? "" : "px-3"
      } radius05 pt-4 pb-2 h100 d-flex flex-column justify-content-between`}
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <div
        className={`${
          societyFormChart ? "" : "px-2"
        } alignH justify-content-between mb-2`}
      >
        <LightDarkText
          className={`${
            societyFormChart ? "f13" : "custom-font-size"
          } bold text-start`}
          lightModeText={mode === "light"}
        >
          {title}
        </LightDarkText>
      </div>
      <Bar
        options={options}
        data={data}
        // className="py-2"
        style={{ maxHeight: customHeight }}
      />
    </LightDarkContainer>
  );
};

export default DoubleBarChart;
