import React from "react";
import { Document, Page } from "react-pdf";
import LightDarkText from "../Common/LightDarkText";
import { useSelector } from "react-redux";

const PdfCard = ({ url }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <Document
      file={url}
      loading={
        <div
          className={`d-flex align-items-center justify-content-center ${
            mode === "light" ? "bg-white" : "bg-dark-grey-bg"
          }`}
          style={{ height: "120px", overflow: "hidden" }}
        >
          <LightDarkText
            className="text-center custom-font-size bold"
            color={mode === "light" ? "text-dark-blue" : "text-light-text"}
          >
            Chargement du PDF...
          </LightDarkText>
        </div>
      }
      error={
        <div
          className={`d-flex align-items-center justify-content-center ${
            mode === "light" ? "bg-white" : "bg-dark-grey-bg"
          }`}
          style={{ height: "120px", overflow: "hidden" }}
        >
          <img src={require(`../../Images/pdf.png`)} width={25} />
        </div>
      }
    >
      <Page pageNumber={1} loading={""} error={""} />
    </Document>
  );
};

export default PdfCard;
