import React from "react";
import { useSelector } from "react-redux";

const ConditionalFileIcon = ({ mime }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div
      className={`cloudFileCard d-flex align-items-center justify-content-center radius05 ${
        mode === "light" ? "bg-white" : "bg-dark-grey-bg"
      }`}
    >
      {mime?.includes("sheet") ? (
        <img src={require(`../../Images/Excel.png`)} width={55} />
      ) : mime?.includes("word") ? (
        <img src={require(`../../Images/word.png`)} width={28} />
      ) : mime?.includes("presentationml") ? (
        <img src={require(`../../Images/PowerPoint.png`)} width={28} />
      ) : mime?.includes("video") || mime?.includes("audio") ? (
        <img src={require(`../../Images/video.png`)} width={28} />
      ) : (
        <i
          className={`f24 bi bi-file-earmark-text-fill  ${
            mode === "light" ? "text-dark-blue" : "text-light-blue-grey"
          }`}
        ></i>
      )}
    </div>
  );
};

export default ConditionalFileIcon;
