import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Form.css";
import ConditionItem from "./ConditionItem";
import LightDarkText from "../../../Common/LightDarkText";
import Separator from "./Separator";
import ColoredButton from "../../../Common/Buttons/ColoredButton";
import {
  createNewCondition,
  createOrUpdateCondition,
} from "../../../../REDUX/actions/formCondition";
import { useEffect } from "react";
import { conditionsData } from "../../../../Utils/utils";

const ConditionForm = ({ question, attr }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);

  const [conditionItems, setConditionItems] = useState([{}]);
  const handleAddConditionItem = () => {
    setConditionItems([...conditionItems, {}]);
  };
  useEffect(() => {
    if (attr.conditions.data.length === 0) {
      setConditionItems([{}]);
    } else {
      setConditionItems(conditionsData(attr?.conditions?.data));
    }
  }, [attr]);
  const handleSubmit = () => {
    dispatch(
      createOrUpdateCondition(question, conditionItems, attr?.conditions?.data)
    );
  };

  const updateConditionItem = (index, newData) => {
    const updatedItems = conditionItems.map((item, i) =>
      i === index ? { ...item, ...newData } : item
    );
    setConditionItems(updatedItems);
  };

  const deleteConditionItem = (index) => {
    const updatedItems = conditionItems.filter((_, i) => i !== index);
    setConditionItems(updatedItems);
  };

  const containsEmptyObject = (array) => {
    for (let obj of array) {
      if (Object.keys(obj).length === 0) {
        return false;
      }
    }
    return true;
  };
  return (
    <div style={{ width: "80vw" }}>
      <div
        className="d-flex p-3 flex-column mt-4 w-100"
        style={{ height: "60vh", overflow: "auto" }}
      >
        <div className="w-100">
          <LightDarkText className="text-start f18 bold  ">
            Afficher le champs si...
          </LightDarkText>
        </div>
        {conditionItems.map((data, index) => (
          <ConditionItem
            key={index}
            index={index}
            data={data}
            updateConditionItem={updateConditionItem}
            deleteConditionItem={deleteConditionItem}
          />
        ))}
      </div>
      <div className="p-3">
        <Separator callback={handleAddConditionItem} />
        <div className="d-flex justify-content-between align-items-center mt-5">
          <ColoredButton
            color="light-blue"
            textButton={"submit"}
            disabled={!containsEmptyObject(conditionItems)}
            callback={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ConditionForm;
