import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SyncCloud from "../Cloud/SyncCloud";
import Copiright from "../Dashboard/Copiright";

const Footer = () => {
  const { pathname } = useLocation();
  const { documentsRoute } = useSelector((state) => state.organisationsReducer);
  const { organisationExternalServices } = useSelector(
    (state) => state.sharedDataReducer
  );
  return (
    <div className="h30px alignC">
      {(pathname === documentsRoute ||
        pathname.includes("dossier") ||
        pathname.includes("onedrivefolder") ||
        pathname.includes("sharepointsite")) &&
      organisationExternalServices.length ? (
        <SyncCloud />
      ) : (
        <Copiright />
      )}
    </div>
  );
};

export default Footer;
