import React from "react";
import { useSelector } from "react-redux";

import DateText from "../../Tickets/TicketCommonComponents/DateText";
import TicketInfoBlock from "../../Tickets/RightPanel/TicketInfoBlock";
import TicketTitle from "../../Tickets/TicketCommonComponents/TicketTitle";
import TitleImageBlock from "../../Tickets/TicketCommonComponents/TitleImageBlock";
import TicketDetailIcon from "../../Tickets/TicketCommonComponents/TicketDetailIcon";
import TicketRedirectionButton from "../../Tickets/TicketCommonComponents/TicketRedirectionButton";

import { ticketInfosData } from "../../Tickets/ticketUtils";

import useWindowWidth from "../../../Hooks/useWindowWidth";

const InterventionTicketDetails = ({ intervention }) => {
  const screenWidth = useWindowWidth();

  const { ticketHandleLogic } = useSelector(
    (state) => state.organisationsReducer
  );

  return (
    <div className="d-flex flex-column scrollable">
      <div className="d-flex align-items-center justify-content-between mt-3">
        <TitleImageBlock
          societyName={intervention?.attributes?.societe?.data?.attributes.name}
          avatar={
            intervention?.attributes?.societe?.data?.attributes?.avatar?.data
              ?.attributes?.image?.data?.attributes?.url
          }
          defaultImage="society"
          extraData={
            <DateText
              date={
                intervention?.attributes?.societe?.data?.attributes.createdAt
              }
            />
          }
        />
        <TicketDetailIcon
          icon={
            intervention?.attributes?.ticket?.data?.attributes?.device?.data
              ?.attributes?.logo?.data?.attributes?.icon?.data?.attributes?.url
          }
          name={
            intervention?.attributes?.ticket.data?.attributes?.device.data
              ?.attributes?.name
          }
        />
      </div>
      <TicketTitle
        id={intervention?.attributes?.ticket?.data?.id}
        title={intervention?.attributes?.ticket?.data?.attributes?.name}
        textsize="custom-ticket-font-size"
        color="text-blue-grey"
        className="mt-3 pt-2"
      />
      <TicketInfoBlock
        ticketInfosData={ticketInfosData(
          intervention?.attributes?.ticket.data,
          ticketHandleLogic,
          screenWidth
        )}
        ticket={intervention?.attributes?.ticket.data}
        isLoading={false}
      />
      <TicketRedirectionButton
        ticketId={intervention?.attributes?.ticket?.data?.id}
      />
    </div>
  );
};

export default InterventionTicketDetails;
