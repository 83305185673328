import React from "react";
import StatBox from "./StatBox";
import { SocieteStatSvg, UsersStatSvg } from "../Common/StatisticsSvgs";

const SupervisorGeneralStats = ({ supervisorStats }) => {
  const generalStats = [
    {
      icon: <SocieteStatSvg fill="#3365ff" />,
      count: supervisorStats.activeSocieties,
      title: "Societes actives",
    },
    // {
    //   icon: <SocieteStatSvg />,
    //   count: supervisorStats.archivedSocieties,
    //   title: "Societes archivées",
    // },
    {
      icon: <UsersStatSvg fill1="#ff5d9e" fill2="#ff5d9e" />,
      count: supervisorStats.activeSupervisors,
      title: "Superviseurs actifs",
    },
    // {
    //   icon: <UsersStatSvg fill2="#ff5d9e" />,
    //   count: supervisorStats.archivedSupervisors,
    //   title: "Superviseurs archivés",
    // },

    {
      icon: <UsersStatSvg fill1="#00c486" fill2="#00c486" />,
      count: supervisorStats.activeOperators,
      title: "Opérateurs actifs",
    },
    // {
    //   icon: <UsersStatSvg fill2="#00c486" />,
    //   count: supervisorStats.archivedOperators,
    //   title: "Opérateurs archivés",
    // },
    {
      icon: <UsersStatSvg fill1="#ffbc20" fill2="#ffbc20" />,
      count: supervisorStats.activeClients,
      title: "Clients actifs",
    },
    // {
    //   icon: <UsersStatSvg fill2="#ffbc20" />,
    //   count: supervisorStats.archivedClients,
    //   title: "Clients archivés",
    // },
  ];
  return (
    <div className="grid4">
      {generalStats.map((el, i) => (
        <StatBox
          key={i}
          unit={el.unit}
          Icon={el.icon}
          count={el.count}
          title={el.title}
        />
      ))}
    </div>
  );
};

export default SupervisorGeneralStats;
