import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import UserCard from "./UserCard";
import TableList from "../Common/TableComponents/TableList";

import { sortFunction } from "../../Utils/utils";
import { userTableData } from "../../Utils/tableStaticData";
import EmptyList from "../Common/EmptyList";

const UsersList = ({ search, operatorsList }) => {
  const location = useLocation();

  const { clients, clientsLoading } = useSelector(
    (state) => state.clientsReducer
  );
  const { operateurs, operatorsLoading } = useSelector(
    (state) => state.operateursReducer
  );

  const [sortUsers, setsortUsers] = useState({ champs: "id", asc: false });
  const [filtering, setfiltering] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(
    operatorsList ? operateurs : clients
  );

  const filteredTableListData =
    location.pathname === "/"
      ? userTableData(false).filter((item) => item.alwaysVisible)
      : userTableData(true).filter(
          (item) => !item?.accessor?.includes("stringSocieties")
        );

  useEffect(() => {
    setFilteredUsers(
      sortFunction(
        operatorsList ? operateurs : clients,
        sortUsers.champs,
        sortUsers.asc
      )?.filter(
        (el) =>
          el.attributes.firstName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          el.attributes.lastName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          el?.id?.toString()?.includes(search) ||
          el.attributes.email?.toLowerCase().includes(search.toLowerCase()) ||
          el.attributes.phoneNumber
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          el.attributes.city?.toLowerCase().includes(search.toLowerCase()) ||
          el.attributes.zipCode?.toLowerCase().includes(search.toLowerCase()) ||
          el.attributes.adress?.toLowerCase().includes(search.toLowerCase())
      )
    );
    if (search?.length) {
      setfiltering(true);
    }
  }, [search, operateurs, clients, sortUsers.champs, sortUsers.asc]);

  return (
    <TableList
      headerItems={filteredTableListData}
      setSort={setsortUsers}
      sort={sortUsers}
      loading={clientsLoading || operatorsLoading}
    >
      {(filteredUsers.length === 0 && filtering) ||
      (operatorsList
        ? operateurs.length === 0 && !operatorsLoading
        : clients.length === 0 && !clientsLoading) ? (
        <EmptyList type={operatorsList ? "operator" : "client"} />
      ) : (
        filteredUsers?.map((user, index) => (
          <UserCard
            user={user}
            tableList={filteredTableListData}
            key={user?.id}
            index={index}
            operatorCard={operatorsList}
            isLoading={clientsLoading || operatorsLoading}
          />
        ))
      )}
    </TableList>
  );
};

export default UsersList;
