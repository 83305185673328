import _ from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LightDarkTextEditor from "../../../../Common/LightDarkTextEditor";
import {
  extractLinks,
  formatDate,
  handleFirstLastName,
  urlifyMessage,
} from "../../../../../Utils/utils";
import { ticketUsersToMention } from "../../messageUtils";
import useWindowWidth from "../../../../../Hooks/useWindowWidth";
import { sendMessage } from "../../../../../REDUX/actions/tickets";
import { clearTempUpload } from "../../../../../REDUX/actions/files";
import { useParams } from "react-router-dom";
import LightDarkContainer from "../../../../Common/LightDarkContainer";
import { CloseIcon } from "../../../../Common/LightDarkSvg";

const MessageInput = ({
  setIsReplying,
  responseTo,
  setResponseTo,
  internalResponseTo,
  setInternalResponseTo,
}) => {
  const { id } = useParams();

  const screenWidth = useWindowWidth();
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);

  const { sendMessageLoading, messages, showPrivateMessages } = useSelector(
    (state) => state.messagesReducer
  );
  const { temporarySelected, isConfidentiel } = useSelector(
    (state) => state.filesReducer
  );
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { admins } = useSelector((state) => state.sharedDataReducer);
  const { isSupervisor, isOperator } = useSelector(
    (state) => state.roleReducer
  );
  const [messageToSend, setmessageToSend] = useState("");
  const [mentions, setMentions] = useState([]);

  const handleSendMessage = () => {
    let newMessage = {
      response_to: responseTo,
      internal_response_to: internalResponseTo,
      is_internal:
        (showPrivateMessages || internalResponseTo) &&
        (isSupervisor || isOperator)
          ? true
          : false,
      is_response: responseTo ? true : false,
      content: urlifyMessage(messageToSend.message),
      links: extractLinks(messageToSend.message),
      ticket: ticket.id,
      cloud:
        user?.attributes?.user_org_permissions.data[0].attributes.cloud?.data
          ?.id,
      sender: user.id,
      type: 0,
    };
    const flatMentions = mentions?.map(
      (mention) => mention?.insert?.mention?.id
    );
    dispatch(
      sendMessage(
        newMessage,
        {
          files: temporarySelected,
          isConfidentiel: isConfidentiel,
        },
        undefined,
        true,
        flatMentions
      )
    );
    setResponseTo(null);
    setInternalResponseTo(null);
    dispatch(clearTempUpload());
    if (!internalResponseTo) {
      setIsReplying(null);
    }
  };
  const messageToRespondContent = messages.find((el) => el.id === responseTo)
    ?.attributes?.content;
  const messageToRespondSender = messages.find((el) => el.id === responseTo)
    ?.attributes?.sender?.data;
  const messageToRespondDate = messages.find((el) => el.id === responseTo)
    ?.attributes?.createdAt;
  const messageToRespondInternalContent = messages.find(
    (el) => el.id === internalResponseTo
  )?.attributes?.content;
  const messageToRespondInternalSender = messages.find(
    (el) => el.id === internalResponseTo
  )?.attributes?.sender?.data;
  const messageToRespondInternalDate = messages.find(
    (el) => el.id === internalResponseTo
  )?.attributes?.createdAt;

  return (
    <div
      className="text-start"
      style={{
        paddingBlock: screenWidth < 600 && "10px",
        position: "relative",
      }}
    >
      {responseTo && (
        <LightDarkContainer
          className={`px-3 py-2 response-indication lightDarkTextEditor radius05 text-left d-flex position-relative ${
            mode === "light" ? "lightTextEditor" : "darkTextEditor"
          } ${mode !== "light" ? "dark" : ""}`}
        >
          <div className="d-flex flex-column">
            <div className="d-flex gap-2">
              <span className="bold custom-font-size">
                {handleFirstLastName(messageToRespondSender)}
              </span>
              <span className="bold custom-font-size">
                {formatDate(messageToRespondDate)}
              </span>
            </div>
            <span
              className="response-content custom-ticket-font-size textCrop"
              dangerouslySetInnerHTML={{
                __html: messageToRespondContent,
              }}
            ></span>
          </div>
          <div
            className="position-absolute close-indication"
            onClick={() => setResponseTo(null)}
          >
            <CloseIcon dimension={"20"} />
          </div>
        </LightDarkContainer>
      )}
      {internalResponseTo && (
        <LightDarkContainer
          className={`px-3 py-2 internal response-indication lightDarkTextEditor radius05 text-left d-flex position-relative ${
            mode === "light" ? "lightTextEditor" : "darkTextEditor"
          } ${mode !== "light" ? "dark internal-dark" : "internal-light"}`}
        >
          <div className="d-flex flex-column text-red">
            <div className="d-flex gap-2">
              <span className="bold custom-font-size">
                {handleFirstLastName(messageToRespondInternalSender)}
              </span>
              <span className="bold custom-font-size">
                {formatDate(messageToRespondInternalDate)}
              </span>
            </div>
            <span
              className="response-content custom-ticket-font-size textCrop"
              dangerouslySetInnerHTML={{
                __html: messageToRespondInternalContent,
              }}
            ></span>
          </div>
          <div
            className="position-absolute close-indication"
            onClick={() => setInternalResponseTo(null)}
          >
            <CloseIcon dimension={"20"} />
          </div>
        </LightDarkContainer>
      )}
      <LightDarkTextEditor
        canBePrivate
        internalResponseTo={internalResponseTo}
        responseTo={responseTo}
        height={"30"}
        value={messageToSend}
        setValue={setmessageToSend}
        champs="message"
        showSendButton
        callBack={handleSendMessage}
        disabledBtn={
          messageToSend?.message?.length || temporarySelected?.length
            ? false
            : true
        }
        messageInput={true}
        isLoading={sendMessageLoading}
        autoHeight
        canMention
        mentions={mentions}
        setMentions={setMentions}
        users={_.uniqBy(
          ticketUsersToMention(ticket, user, admins, showPrivateMessages),
          "id"
        )}
        ticketChanged={+id !== +ticket?.id}
        filesAndNoText={
          !messageToSend?.message?.replace(/<(.|\n)*?>/g, "").trim().length &&
          temporarySelected?.length
        }
      />
      {/* <InfoQuestion
        position="absolute"
        htmlMessage={`Retour à la ligne avec <br />
                            "Shift + Entrer" <br />
                            Envoyez votre message <br />
                            avec "Entrer"`}
      /> */}
    </div>
  );
};

export default MessageInput;
