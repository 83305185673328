import React, { useState } from "react";
import LightDarkInput from "../../../Common/LightDarkInput";

const NumberRange = ({ setValue, totalInput }) => {
  const [data] = useState([
    { id: 1, label: "Min" },
    { id: 2, label: "Max" },
  ]);
  return (
    <div className=" mt-3 row">
      {data.map(({ id, label }, index) => {
        return (
          <div className="col-6" key={id}>
            <LightDarkInput
              label={label}
              bgGray={true}
              type="number"
              value={totalInput[`answer-${index}`]}
              required={true}
              setValue={setValue}
              totalInputs={totalInput}
              champs={`answer-${index}`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default NumberRange;
