import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LightDarkContainer from "./LightDarkContainer";
import ToggleArchiveSwitch from "./ToggleArchiveSwitch";
import LightDarkFilterSelect from "./LightDarkFilterSelect";

import {
  handleSelectedDate,
  handleSelectedIntervention,
  handleSelectedOperators,
  handleSelectedProspect,
  handleSelectedSociety,
  handleSelectedTicket,
  toggleProspectAndTickets,
  toggleUnreadTickets,
} from "../../REDUX/actions/filters";

import "./ListHeader.css";
import LightDarkText from "./LightDarkText";
import SwitchItem from "../Layout/Notification/SwitchItem";
import { SwitchIcon } from "./LightDarkSvg";

const ListHeader = ({
  children,
  showSocityFilter = true,
  showTicketFilter = false,
  showInterventionFilter = false,
  isInterventionHeader = false,
  isInvoiceHeader = false,
  filters,
  showProspectFilter,
  type,
  showOperatorsFilter,
  showOperatorsArchive = false,
}) => {
  const ref = useRef();

  const dispatch = useDispatch();

  const location = useLocation();

  const { societes, operators } = useSelector(
    (state) => state.sharedDataReducer
  );
  const {
    ticketsArchived,
    operateursArchived,
    prospectArchived,
    devisArchived,
    commandeArchived,
    showProspectTickets,
    showUnreadTickets,
  } = useSelector((state) => state.filtersReducer);
  const {
    interventionRoute,
    devisRoute,
    prospetsRoute,
    commandesRoute,
    facturesRoute,
    selectedOrganisation,

    societeRoute,
    ticketRoute,
  } = useSelector((state) => state.organisationsReducer);
  const { mode } = useSelector((state) => state.genearlReducer);

  const [values, setValues] = useState({
    societe: [],
    interventionStatut: [],
    operators: [],
    statut: [],
    prospect: [],
    date: null,
  });

  const ticketStatus = [
    { value: 1, label: "en cours", color: "purple" },
    { value: 2, label: "résolus", color: "dark-green" },
  ];

  const ProspectStatus = [
    {
      value: 1,
      label: "En attente",
      filters: [],
    },
    {
      value: 2,
      label: "En attente de confirmation admin",
      filters: ["DRAFT"],
      color: "turquoise",
    },
    {
      value: 3,
      label: "En attente de confirmation commercial",
      filters: ["DRAFT_COMMERCIAL"],
      color: "turquoise",
    },
    {
      value: 4,
      label: "En attente de confirmation revendeur",
      filters: ["DRAFT_SELLER"],
      color: "orange",
    },
  ];

  const DevisStatus = [
    {
      value: 1,
      label: "En attente",
      filters: [],
    },
    {
      value: 2,
      label: "En attente d'affectation revendeur",
      filters: ["NEW"],
      color: "turquoise",
    },
    {
      value: 3,
      label: "En attente de validation client",
      filters: ["NEW_SELLER"],
      color: "green",
    },
  ];

  const CommandeStatus = [
    {
      value: 1,
      label: "Attente de validation",
      filters: ["TO_VALIDATE"],
      color: "yellow",
    },
    {
      value: 2,
      label: "Validé",
      filters: ["VALID"],
      color: "green",
    },
  ];

  const interventionStatus = [
    { value: 1, label: "en attente", color: "yellow" },
    { value: 2, label: "en cours", color: "purple" },
    { value: 3, label: "à valider", color: "light-blue" },
    { value: 4, label: "finalisées", color: "green" },
    { value: 5, label: "refusées", color: "red" },
    { value: 6, label: "en révision", color: "orange" },
  ];

  useEffect(() => {
    if (
      location.pathname.includes(ticketRoute) ||
      location.pathname === interventionRoute ||
      location.pathname === facturesRoute ||
      location.pathname === prospetsRoute ||
      location.pathname === devisRoute ||
      location.pathname === commandesRoute
    ) {
      dispatch(handleSelectedTicket(values?.statut.map((el) => el?.value)));
      dispatch(
        handleSelectedOperators(values?.operators.map((el) => el?.value))
      );
      dispatch(
        handleSelectedIntervention(
          values?.interventionStatut.map((el) => el?.value)
        )
      );
      dispatch(handleSelectedSociety(values?.societe.map((s) => s?.value)));
      dispatch(handleSelectedProspect(values?.prospect.map((s) => s?.filters)));
      dispatch(handleSelectedDate(values?.date));
    } // eslint-disable-next-line
  }, [values, dispatch]);

  useEffect(() => {
    // dispatch(resetTicketFilters(false));
    setValues({
      societe: [],
      interventionStatut: [],
      operators: [],
      statut: [],
      prospect: [],
      date: null,
    });
  }, [dispatch]);
  return (
    <LightDarkContainer
      colorLight="light-mode"
      className="alignH listHeaderContainer w-100 h100"
    >
      {filters || showSocityFilter ? (
        <div
          style={{
            position: "absolute",
            right: "0",
          }}
        >
          <div
            className={`alignH gap-2 justify-content-end ${
              !isInterventionHeader && !isInvoiceHeader ? "pe-4" : "pe-2"
            }`}
          >
            {filters}
            <div className="d-flex align-content-center">
              {showSocityFilter && (
                <LightDarkFilterSelect
                  data={societes}
                  multiple
                  canSelectAll
                  newField="societe"
                  champs="societe"
                  totalInputs={values}
                  setValue={setValues}
                  placeholder="Toutes les sociétés"
                  societySelectInput
                />
              )}
              {showOperatorsFilter && (
                <LightDarkFilterSelect
                  data={operators}
                  multiple
                  canSelectAll
                  newField="operators"
                  champs="operators"
                  totalInputs={values}
                  setValue={setValues}
                  placeholder="Tout les opérateurs"
                  societySelectInput
                />
              )}
              {showTicketFilter && (
                <LightDarkFilterSelect
                  data={ticketStatus}
                  multiple
                  canSelectAll
                  champs="statut"
                  totalInputs={values}
                  setValue={setValues}
                  placeholder="Tous les statuts"
                  noSearch
                  showTicketFilter={showTicketFilter}
                />
              )}
              {showInterventionFilter && (
                <LightDarkFilterSelect
                  data={interventionStatus}
                  multiple
                  canSelectAll
                  champs="interventionStatut"
                  totalInputs={values}
                  setValue={setValues}
                  placeholder="Tous les statuts"
                  noSearch
                  showInterventionFilter={showInterventionFilter}
                />
              )}
            </div>
            {!showInterventionFilter && !isInvoiceHeader && (
              <>
                {" "}
                <div className="alignH gap-2 mx-3">
                  <SwitchItem
                    value={showUnreadTickets}
                    onChange={() => dispatch(toggleUnreadTickets())}
                  />

                  <LightDarkText
                    className={"bold custom-ticket-font-size"}
                    color={mode === "light" ? "text-blue-grey" : "text-white"}
                  >
                    Non lu
                  </LightDarkText>
                </div>
                <ToggleArchiveSwitch
                  absolute={false}
                  totalObject={{ ticketsArchived: !ticketsArchived }}
                  archived={ticketsArchived}
                  type="tickets"
                />
                {selectedOrganisation.attributes.withProspects &&
                  showTicketFilter && (
                    <div
                      className="d-flex align-items-center gap-2 pe-3"
                      onClick={() => {
                        dispatch(toggleProspectAndTickets());
                      }}
                    >
                      <LightDarkText
                        className="f12 bold"
                        color={
                          mode === "light" ? "text-blue-grey" : "text-white"
                        }
                      >
                        SAV
                      </LightDarkText>
                      <SwitchIcon value={showProspectTickets} />
                      <LightDarkText
                        className="f12 bold"
                        color={
                          mode === "light" ? "text-blue-grey" : "text-white"
                        }
                      >
                        Prospects
                      </LightDarkText>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      ) : showProspectFilter ? (
        <div
          className=" d-flex align-items-center"
          style={{
            position: "absolute",
            right: "20px",
          }}
        >
          <div style={{ width: "200px" }}>
            <input
              autoComplete="nope"
              placeholder="Date"
              ref={ref}
              type={"date"}
              className={` rmsc LightDarkInputContainer no-resize text-start t-02 w-100 p-2 radius05 custom-ticket-font-size px-2 py-3 ${
                mode === "light" ? "bg-white" : "bg-dark-grey-bg"
              }`}
              style={{
                border: `1px solid ${
                  mode === "light" ? "#f4f6fd" : "#252c45"
                } `,
                color: mode === "dark" ? "#a9bcd3" : "#354074",
                height: "40px",
              }}
              onChange={(e) => {
                setValues({ ...values, date: e.target.value });
              }}
            />
          </div>
          <LightDarkFilterSelect
            data={
              type === "prospect"
                ? ProspectStatus
                : type === "devis"
                ? DevisStatus
                : CommandeStatus
            }
            multiple={false}
            // canSelectAll
            champs="prospect"
            totalInputs={values}
            setValue={setValues}
            placeholder="Tous les statuts"
            noSearch
            showTicketFilter={showTicketFilter}
          />
          <div className="ps-2">
            {location.pathname === devisRoute && (
              <ToggleArchiveSwitch
                absolute={false}
                totalObject={{ devisArchived: !devisArchived }}
                archived={devisArchived}
              />
            )}
            {location.pathname === prospetsRoute && (
              <ToggleArchiveSwitch
                absolute={false}
                totalObject={{ prospectArchived: !prospectArchived }}
                archived={prospectArchived}
              />
            )}
            {location.pathname === commandesRoute && (
              <ToggleArchiveSwitch
                absolute={false}
                totalObject={{ commandeArchived: !commandeArchived }}
                archived={commandeArchived}
              />
            )}
          </div>
        </div>
      ) : null}
      <div className="d-flex flex-1 gap-4 h100">{children}</div>
      {showOperatorsArchive && (
        <ToggleArchiveSwitch
          totalObject={{ operateursArchived: !operateursArchived }}
          archived={operateursArchived}
        />
      )}
    </LightDarkContainer>
  );
};

export default ListHeader;
