import React from "react";
import LightDarkText from "../Common/LightDarkText";
import { useDispatch, useSelector } from "react-redux";
import check from "../../Images/apiIcons/check.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { loginToOneDrive } from "../../REDUX/actions/externalServices";
import { fileUrl } from "../../Utils/utils";

const SyncCloud = () => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { organisationExternalServices } = useSelector(
    (state) => state.sharedDataReducer
  );

  const { oneDriveSynched } = useSelector(
    (state) => state.externalServicesReducer
  );
  const connectData = {
    oneDrive: {
      callback: oneDriveSynched ? null : () => dispatch(loginToOneDrive()),
      synched: oneDriveSynched,
    },
    Gdrive: { callback: null, synched: false },
    ICloud: { callback: null, synched: false },
    dropBox: { callback: null, synched: false },
  };

  return (
    <LightDarkText
      className="d-flex justify-content-center align-items-center regular custom-ticket-font-size w-100 xbold"
      color={mode === "light" ? "text-blue-grey" : "text-light-blue-grey"}
    >
      Pensez à la synchronisation :{" "}
      <div className="d-flex alignH gap-3 ms-3">
        {organisationExternalServices.map((el) => (
          <OverlayTrigger
            key={el.id}
            placement="top"
            overlay={
              <Tooltip>
                <LightDarkText className="f12 text-center text-white">
                  {el.attributes.name}
                  <br />
                  {el.attributes.available ? "" : "Disponible prochainement"}
                  {connectData[el.attributes.tag].synched
                    ? "Dèja synchronisé"
                    : ""}
                </LightDarkText>
              </Tooltip>
            }
          >
            <div
              className="pointer position-relative h100"
              onClick={connectData[el.attributes.tag].callback}
            >
              <img
                src={fileUrl(el.attributes.logo.data.attributes.url)}
                alt={el.attributes.name}
              />
              {connectData[el.attributes.tag].synched ? (
                <img
                  src={check}
                  alt="check"
                  className="position-absolute"
                  style={{ top: "-4px", right: "-5px" }}
                />
              ) : null}
            </div>
          </OverlayTrigger>
        ))}
      </div>
    </LightDarkText>
  );
};

export default SyncCloud;
