import moment from "moment";
import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";

import { handleFirstLastName } from "../../../../Utils/utils";
import { useState } from "react";

const StatusMessage = ({ color, icon, statut, date, by, autoCreated }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const [byState] = useState(
    statut === "ouvert" && autoCreated
      ? "automatiquement"
      : `par ${handleFirstLastName(by)}`
    // ${
    //   by?.attributes?.poste?.data ||
    //   ("" && `, ${by?.attributes?.poste?.data?.attributes?.name}`)
    // }
  );
  return (
    <div
      className={`radius05 m-auto d-flex my-4 gap-4 align-items-center p-2 example-${color}`}
    >
      <LightDarkText
        className="custom-ticket-font-size bold text-center flex-1"
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        {icon} Ticket {statut} le {moment(date).format("DD/MM/YYYY à HH:mm ")}
        {byState}
      </LightDarkText>
    </div>
  );
};

export default StatusMessage;
