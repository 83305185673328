import React, { useState } from "react";
import { useSelector } from "react-redux";
import EventHeader from "./EventHeader";
import EventSideBarDetails from "./EventSideBarDetails";
import LightDarkContainer from "../../../Common/LightDarkContainer";
import MultiTabsMenu from "../../../Common/MultiTabsMenu";

const EventDetailsContent = ({ eventDetails }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const [isToggle, setIsToggle] = useState("details");
  const eventTabs = [
    {
      title: "Détails de l'événement",
      toggleValue: "details",
      isVisible: true,
    },
    {
      title: "Historique",
      toggleValue: "history",
      isVisible: true,
    },
  ];

  return (
    <LightDarkContainer className="notScrollable h100">
      <MultiTabsMenu
        isToggle={isToggle}
        setIsToggle={setIsToggle}
        allTabs={eventTabs}
        align="center"
        gap="gap-5"
      />

      <div className="notScrollable h100">
        <EventSideBarDetails event={eventDetails} isToggle={isToggle} />
      </div>
    </LightDarkContainer>
  );
};

export default EventDetailsContent;
