import React, { useState } from "react";

import EventSideBarDetails from "../../Calendar/Events/SideBar/EventSideBarDetails";
import EventHeader from "../../Calendar/Events/SideBar/EventHeader";
import LightDarkContainer from "../../Common/LightDarkContainer";
import { CloseIcon } from "../../Common/LightDarkSvg";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../REDUX/actions/modal";

const EventModalInfo = ({ event }) => {
  const dispatch = useDispatch();
  const [isToggle, setIsToggle] = useState("details");

  return (
    <div style={{ height: "600px" }} className="p-3 d-flex flex-column ">
      <div className="alignH justify-content-end">
        <CloseIcon clickCallback={() => dispatch(closeModal())} />
      </div>
      <LightDarkContainer colorLight="white" className="bg-red">
        <EventHeader isToggle={isToggle} setIsToggle={setIsToggle} />
      </LightDarkContainer>
      <div className="scrollable h100 pt-3">
        <EventSideBarDetails event={event} isToggle={isToggle} />
      </div>
    </div>
  );
};

export default EventModalInfo;
