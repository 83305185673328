import { gql } from "@apollo/client";
import { FORM_CATEGORY_ATTRIBUTES } from "../Attributes/formCategory";
export const GET_CATEGORIES = gql`
  query {
    formCategories (pagination: { page: 1, pageSize: 10000 }){
      data {
        id
        attributes {
          ${FORM_CATEGORY_ATTRIBUTES}
        }
      }
    }
  }
`;
