import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TrashIcon } from "../../../Common/LightDarkSvg";
import LightDarkText from "../../../Common/LightDarkText";
import LightDarkInput from "../../../Common/LightDarkInput";
import Separator from "./Separator";

const AnswerItem = ({ totalInput, setValue }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const [divCount, setDivCount] = useState();
  const handleIncrement = () => {
    const newIndex = divCount;
    setDivCount((prevCount) => prevCount + 1);

    setValue((prevTotalInput) => ({
      ...prevTotalInput,
      [`answer-${newIndex}`]: "",
    }));
  };
  const handleDelete = (index) => {
    const updatedTotalInput = { ...totalInput };
    delete updatedTotalInput[`answer-${index}`];
    setValue(updatedTotalInput);
  };
  useEffect(() => {
    if (totalInput) {
      const length = Object.keys(totalInput).length;

      setDivCount(length);
    }
  }, [totalInput]);
  return (
    <div className="w-100 mt-3 mb-3 ">
      <div className="ps-3">
        <LightDarkText className={"text-start bold f12 mb-2"}>
          Réponses <span className="text-red">*</span>
          <div></div>
        </LightDarkText>
        <div
          className="w-100 "
          style={{ maxHeight: "150px", overflow: "auto" }}
        >
          {Object.keys(totalInput).map((inputKey) => {
            const index = parseInt(inputKey.split("-")[1]);
            return (
              <div key={index} className="row align-items-center">
                <div className="col-10">
                  <LightDarkInput
                    bgGray={true}
                    champs={inputKey}
                    setValue={setValue}
                    totalInputs={totalInput}
                  />
                </div>
                <div className="col-2 mt-1" onClick={() => handleDelete(index)}>
                  <TrashIcon />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-4">
        <Separator callback={handleIncrement} />
      </div>
    </div>
  );
};

export default AnswerItem;

// [{id:1,answer:"an1"},{id:2,answer:"an3"}]

// {answer-0:"answer1",answer-1:"an3"}
