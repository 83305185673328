import React, { useRef, useState } from "react";
import { pdfjs } from "react-pdf";
import { useDispatch } from "react-redux";
import { closeModal } from "../../REDUX/actions/modal";
import { formatDate, calculateSize } from "../../Utils/utils";
import ColoredButton from "./Buttons/ColoredButton";
import { CloseIcon } from "./LightDarkSvg";
import LightDarkText from "./LightDarkText";
import PDFComponent from "./PDFComponent";
import useWindowWidth from "../../Hooks/useWindowWidth";
import Thumbnail from "./Thumbnail";
import { saveAs } from "file-saver";

const PreviewModal = ({ fileData }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const [isPdfFile] = useState(fileData.mime.includes("pdf"));
  const containerRef = useRef(null);
  const documentRef = useRef(null);

  return (
    <div className="px-4 pt-4 radius05 pb-4 directionC h100">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="alignH gap-3 flex-1">
          <div>
            <div className="d-flex flex-wrap gap-2">
              <div className={`p-0 alignH gap-2`}>
                {fileData.societyAvatar ? (
                  <Thumbnail
                    apiImg={fileData.societyAvatar}
                    img={"society"}
                    size={screenWidth < 1200 ? 30 : 40}
                    rounded
                  />
                ) : null}
                <div>
                  {fileData.societyName ? (
                    <LightDarkText
                      className={
                        "custom-font-size bolder text-start text-ellipsis "
                      }
                      color={"text-white"}
                    >
                      {fileData.societyName}
                    </LightDarkText>
                  ) : null}
                  <div className="d-flex alignH">
                    <LightDarkText
                      className={
                        "custom-ticket-font-size bold text-start text-wrap"
                      }
                      color={"text-white"}
                    >
                      {fileData.name}
                    </LightDarkText>
                    <LightDarkText
                      className={"custom-ticket-font-size bold text-start ms-2"}
                      color={"text-white"}
                    >
                      ({calculateSize(fileData.size)})
                    </LightDarkText>
                  </div>
                </div>
              </div>
            </div>

            <LightDarkText
              className={"custom-ticket-font-size text-start mt-1"}
              color={"text-white"}
            >
              {formatDate(fileData?.date)}
            </LightDarkText>
          </div>
        </div>
        <div
          className="ps-4 d-flex h-100"
          style={{ zIndex: 40 }}
          onClick={() => dispatch(closeModal())}
        >
          <CloseIcon />
        </div>
      </div>

      <div
        className={`alignC ${
          fileData.mime?.includes("image")
            ? "invoiceFileCard mx-auto flex-grow-1"
            : ""
        }`}
        style={{
          height: `${
            fileData.mime?.includes("image") ? "calc(90vh - 215px)" : "100%"
          }`,
        }}
        ref={containerRef}
      >
        {fileData.mime?.includes("image") ? (
          <img src={fileData.url} alt="" className="w-100 h-100 ob-contain" />
        ) : (
          <PDFComponent pdfFile={fileData.url} documentRef={documentRef} />
        )}
      </div>

      <div
        className={`${
          isPdfFile ? "col-2" : "col-3 mb-3 mt-5"
        } align-self-center`}
      >
        <ColoredButton
          textButton={"Télécharger"}
          color="light-blue"
          callback={() => saveAs(fileData.url, fileData.name)}
        />
      </div>
    </div>
  );
};

export default PreviewModal;
