import { useDispatch, useSelector } from "react-redux";

import AvatarStack from "../Events/EventCommonComponents/AvatarStack";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CalendarForm from "../CalendarForms/CalendarForm";
import { openModal } from "../../../REDUX/actions/modal";
import LightDarkText from "../../Common/LightDarkText";

const CalendarUsers = ({ selectedCalendars }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userReducer);
  const { mode } = useSelector((state) => state.genearlReducer);

  const sharedUsers = [];
  selectedCalendars
    ?.map((el) => el?.attributes?.sharedUsers?.data)
    .forEach((item) => item && sharedUsers.push(...item));

  return (
    <div className="d-flex align-items-center justify-content-end">
      {selectedCalendars?.length === 1 && (
        <>
          {selectedCalendars?.every(
            (el) => el?.attributes?.owner?.data?.id === user?.id
          ) && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip style={{ zIndex: 10000 }}>
                  <LightDarkText className="custom-ticket-font-size py-1 text-white">
                    Partager
                  </LightDarkText>
                </Tooltip>
              }
            >
              <div
                className="bg-light-blue text-white radius50 alignC pointer share-btn"
                style={{
                  height: "32px",
                  width: "32px",
                  zIndex: 6,
                  border: `${
                    mode === "light" ? "2px solid #f4f6fd" : "2px solid #0a122e"
                  }`,
                  marginRight: `${
                    selectedCalendars?.length === 1 &&
                    selectedCalendars[0]?.attributes?.sharedUsers?.data
                      ?.length &&
                    "-14px"
                  }`,
                }}
                onClick={() =>
                  dispatch(
                    openModal({
                      type: "small",
                      content: (
                        <CalendarForm
                          calendar={selectedCalendars[0]}
                          shareCalendar
                        />
                      ),
                    })
                  )
                }
              >
                +
              </div>
            </OverlayTrigger>
          )}

          <AvatarStack
            participants={sharedUsers}
            calendarUsers
            calendar={selectedCalendars[0]}
            border={mode === "light" ? "#f4f6fd" : "#0a122e"}
          />
        </>
      )}
    </div>
  );
};

export default CalendarUsers;
