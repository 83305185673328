import moment from "moment";
import { useSelector } from "react-redux";
import EventSideGroup from "./EventSideGroup";

const EventsList = ({
  upcomingEvents,
  nextMonthEvents,
  afterNextMonthEvents,
  ongoingEvents,
  eventClick = () => {},
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {upcomingEvents?.length ||
      nextMonthEvents?.length ||
      afterNextMonthEvents?.length ||
      ongoingEvents?.length ? (
        <div
          className={`d-flex flex-column gap-2 pb-4 position-relative sideChatListContainer ${
            mode === "light" ? "bg-white" : "bg-dark-grey-bg"
          }`}
          style={{
            height: "calc(100vh - 260px)",
            right: 0,
            top: 0,
            borderLeft: `${
              mode === "light" ? "1px solid #ddd" : "1px solid #252c45"
            }`,
            boxShadow: "-2px 0 1px 0 #CCCCCC34",
            zIndex: 5,
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
            width: "175px",
          }}
        >
          <EventSideGroup
            events={ongoingEvents}
            title={"En cours : "}
            eventClick={eventClick}
          />
          <EventSideGroup
            events={upcomingEvents}
            title={"Prochainement : "}
            eventClick={eventClick}
          />
          <EventSideGroup
            events={nextMonthEvents}
            title={moment().add(1, "M").format("MMMM YYYY")}
            eventClick={eventClick}
          />
          <EventSideGroup
            events={afterNextMonthEvents}
            title={moment().add(2, "M").format("MMMM YYYY")}
            eventClick={eventClick}
          />
        </div>
      ) : null}
    </>
  );
};

export default EventsList;
