import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import MessagePlaceHolder from "../../../../PlaceHolders/MessagePlaceHolder";
import MessageCard from "./MessageCard/MessageCard";

import useOnScreen from "../../../../../Hooks/useScreen";
import { handleAllNotifications } from "../../../../../REDUX/actions/notifications";

const PrivateMessagesList = ({
  responseTo,
  setResponseTo,
  handleFocusToRespond,
  setInternalResponseTo,
}) => {
  const dispatch = useDispatch();
  const { messages, messagesLoading } = useSelector(
    (state) => state.messagesReducer
  );

  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { measureRef } = useOnScreen();
  useEffect(() => {
    if (ticket.attributes.privateNotifications) {
      dispatch(handleAllNotifications(true, "internal", ticket.id));
    }
  }, [ticket]);

  useEffect(() => {
    if (document?.querySelector("#baliseBottomMessage")) {
      document?.querySelector("#baliseBottomMessage").scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [messages[0]?.id]);
  return (
    <div
      className="messagesContainer  position-relative t-02 d-flex flex-column flex-1 overflow-hidden"
      id="scrollBottom"
    >
      {messagesLoading ? (
        <div className="scrollbarY d-flex flex-column justify-content-end h100">
          {["", "", "", ""].map((el, index) => (
            <MessagePlaceHolder key={index} index={index} />
          ))}
        </div>
      ) : (
        <div
          id="messages-scroll"
          className="hide-scrollbar d-flex flex-column-reverse overflow-auto flex-1"
        >
          <div id="baliseBottomMessage"></div>
          {messages
            .filter(
              (el) =>
                el.attributes.is_internal &&
                !el.attributes.internal_response_to.data
            )
            ?.map((message, index) => {
              return (
                <MessageCard
                  setInternalResponseTo={setInternalResponseTo}
                  responseTo={responseTo}
                  handleFocusToRespond={handleFocusToRespond}
                  setResponseTo={setResponseTo}
                  message={message}
                  key={index}
                  ticketId={ticket.id}
                  isArchived={ticket.attributes.archived}
                  measureRef={
                    index === messages?.length - 1 ? measureRef : null
                  }
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default PrivateMessagesList;
