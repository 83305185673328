import React from "react";
import LightDarkContainer from "../../Common/LightDarkContainer";
import { QuestionIcon } from "../../Common/LightDarkSvg";
import LightDarkText from "../../Common/LightDarkText";

const RightInterviews = ({ ticket }) => {
  const handleScroll = (id) => {
    document.querySelector(`#interview-${id}`).scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <div className="d-flex flex-column gap-2 scrollable">
      {ticket.attributes.interview_models.data.map((el) => (
        <LightDarkContainer
          className="text-start f12 radius05 p-2 pointer opacityHover09 bold"
          key={el.id}
          callBack={() =>
            handleScroll(
              el.attributes.messages.data.find(
                (el) => el.attributes.ticket.data.id === ticket.id
              ).id
            )
          }
        >
          <LightDarkText className={"alignH gap-2 pb-2"}>
            <QuestionIcon />
            {el.attributes.name}
          </LightDarkText>
          <LightDarkText className={"f10"}>
            {el.attributes.description}
          </LightDarkText>
        </LightDarkContainer>
      ))}
    </div>
  );
};

export default RightInterviews;
