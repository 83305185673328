import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import SocietyForm from "../../Components/Societies/SocietyForm/SocietyForm";
import SocietyListNew from "../../Components/Societies/NewSociety/SocietyListNew";
import { openSideBar } from "../../REDUX/actions/sideBar";
import ToggleArchiveSwitch from "../../Components/Common/ToggleArchiveSwitch";
import LightDarkContainer from "../../Components/Common/LightDarkContainer";
import LightDarkText from "../../Components/Common/LightDarkText";
import { SwitchIcon } from "../../Components/Common/LightDarkSvg";

const NewSocietyView = ({ setIsToggle, clientVue, setClientVue }) => {
  const dispatch = useDispatch();
  const { canCreateSociety, canSeeCollabs } = useSelector(
    (state) => state.roleReducer
  );

  const { societes } = useSelector((state) => state.societesReducer);
  const { archivedSociety } = useSelector((state) => state.filtersReducer);
  const [searchSociety, setsearchSociety] = useState("");

  return (
    <div className="container-fluid h-100">
      <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className={`${canCreateSociety ? "col-10" : "col-12"} h100`}>
          <ListHeader showSocityFilter={false}>
            <LightDarkContainer
              colorLight="white"
              className="alignH w-100 gap-4"
            >
              <SearchInput
                placeholder="ID, nom, adresse, email, site web, téléphone, membre..."
                onChange={(e) => {
                  setsearchSociety(e.target.value);
                }}
              />
              {canSeeCollabs ? (
                <div
                  className="alignH xbold f12 gap-2"
                  onClick={() => setClientVue(!clientVue)}
                >
                  <LightDarkText
                    color={`${
                      clientVue ? "text-light-text" : "text-light-blue"
                    }`}
                  >
                    Sociétés
                  </LightDarkText>

                  <SwitchIcon value={clientVue} />
                  <LightDarkText
                    color={`${
                      clientVue ? "text-light-blue" : "text-light-text"
                    }`}
                  >
                    Clients
                  </LightDarkText>
                </div>
              ) : null}
              <ToggleArchiveSwitch
                totalObject={{ archivedSociety: !archivedSociety }}
                archived={archivedSociety}
                absolute={false}
              />
            </LightDarkContainer>
          </ListHeader>
        </div>
        {canCreateSociety && (
          <div className="col-2 ms-2 h100">
            <ColoredButton
              height="100"
              textButton="Créer une société"
              color="light-blue"
              callback={() =>
                dispatch(
                  openSideBar({
                    content: <SocietyForm isNew />,
                    title: "Nouvelle société",
                    type: "w40",
                    isToggle: "isToggle value",
                  })
                )
              }
            />
          </div>
        )}
      </div>
      <div
        className={`${
          societes.filter((societe) => societe.attributes.archived === false)
            .length === 0
            ? "h-100 bg-white"
            : ""
        }`}
      >
        {clientVue ? (
          <div></div>
        ) : (
          <SocietyListNew
            searchSociety={searchSociety}
            societes={societes}
            setIsToggle={setIsToggle}
          />
        )}
      </div>
    </div>
  );
};

export default NewSocietyView;
