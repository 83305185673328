import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CloseIcon, DeleteIcon, EditIcon } from "../../Common/LightDarkSvg";
import LightDarkText from "../../Common/LightDarkText";
import TooltipActions from "../../Common/Tooltip/TooltipActions";
import AlertForm from "./AlertForm";

import { closeModal, openModal } from "../../../REDUX/actions/modal";
import { deleteAlert, readAlert } from "../../../REDUX/actions/alerts";
import { openConfirmationModal } from "../../../REDUX/actions/confirmationModal";

const AlertPopupHeader = ({ alert }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { canCreateAlerts } = useSelector((state) => state.roleReducer);

  const [showTooltip, setshowTooltip] = useState(false);

  const tooltipData = [
    {
      id: 1,
      text: "Éditer",
      icon: <EditIcon className="me-2" />,
      action: () => {
        dispatch(
          openModal({
            type: "small",
            content: <AlertForm alert={alert} />,
          })
        );
        setshowTooltip(false);
      },
      condition: true,
    },
    {
      id: 2,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(deleteAlert(alert?.id));
              dispatch(closeModal());
            },
            message: "Êtes-vous sur de supprimer cette annonce?",
            message2:
              "En confirmant la suppression, l'annonce sera définitivement supprimée.",
            confirmButtonText: "Je confirme la suppression",
          })
        );
      },
      condition: true,
    },
  ];

  return (
    <div
      className={`${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      } alignC p-4 w-100 h70px radius05`}
      style={{ position: "absolute", top: 0 }}
    >
      <LightDarkText className="text-center custom-title-font-size bold mx-5 flex-1">
        Ne manquez pas cette annonce !
      </LightDarkText>
      {canCreateAlerts && (
        <TooltipActions
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
        />
      )}
      <CloseIcon
        clickCallback={() => {
          dispatch(closeModal());
          dispatch(readAlert(alert));
        }}
      />
    </div>
  );
};

export default AlertPopupHeader;
