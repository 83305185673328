import moment from "moment";
import { Calendar, ClockIcon } from "../../../Common/LightDarkSvg";
import LightDarkText from "../../../Common/LightDarkText";

const EventDate = ({ color, withIcons = true, dates }) => {
  const datesTimes = {
    startDate: moment(dates.start).format("dddd DD MMMM YYYY"),
    startTime: moment(dates.start).format("HH:mm"),
    endtDate: moment(dates.end).format("dddd DD MMMM YYYY"),
    endTime: moment(dates.end).format("HH:mm"),
    duration:
      moment(dates.end).diff(dates.start, "hours") <= 24
        ? `${moment(dates.end).diff(dates.start, "hours")} heures`
        : `${moment(dates.end).diff(dates.start, "days")} jours`,
  };

  return (
    <div className={`d-flex flex-${withIcons ? "column my-2" : "row"} gap-2`}>
      <LightDarkText
        className={`text-${color} bold custom-ticket-font-size text-start alignH`}
      >
        {withIcons && <Calendar sideBar className="me-2" />}
        Du {datesTimes.startDate}
        {withIcons && (
          <ClockIcon darkColor={"#354074"} size={18} className="mx-2" />
        )}{" "}
        {datesTimes.startTime}
      </LightDarkText>
      <LightDarkText
        className={`text-${color} bold custom-ticket-font-size text-start alignH`}
      >
        {withIcons && <Calendar sideBar className="me-2" />}
        Au {datesTimes.endtDate}
        {withIcons && (
          <ClockIcon darkColor={"#354074"} size={18} className="mx-2" />
        )}{" "}
        {datesTimes.endTime}
      </LightDarkText>
      <LightDarkText
        className={`text-${color} bold custom-ticket-font-size text-start alignH`}
      >
        {withIcons && (
          <ClockIcon darkColor={"#354074"} size={24} className="me-2" />
        )}{" "}
        durée : {datesTimes.duration}
      </LightDarkText>
    </div>
  );
};

export default EventDate;
