import LightDarkText from "../../../../Common/LightDarkText";

import { handleInterventionStatut } from "../../../../Interventions/interventionUtils";
import {
  handleOldInterventionMessages,
  messageType,
} from "../../../ticketUtils";

const InterventionStatus = ({ interventionStatus, message }) => {
  return (
    <LightDarkText
      className="text-start custom-font-size bold"
      color={`text-${messageType(interventionStatus).color}`}
    >
      {handleInterventionStatut(interventionStatus).icon}{" "}
      {message.attributes.oldId
        ? handleOldInterventionMessages(message)
        : `${messageType(interventionStatus).title}`}
    </LightDarkText>
  );
};

export default InterventionStatus;
