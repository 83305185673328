import {
  ADD_DIFFUSION_FAILED,
  ADD_DIFFUSION_SUCCESS,
  DELETE_DIFFUSION_FAILED,
  DELETE_DIFFUSION_SUCCESS,
  GET_DIFFUSION_SUCCESS,
  LOADING_ADD_DIFFUSION,
  UPDATE_DIFFUSION_FAILED,
  UPDATE_DIFFUSION_SUCCESS,
} from "../ActionsType/diffusiontype";

const initialState = {
  alerts: [],
  unreadAlerts: [],
  lastAlert: null,
  alertsLoading: false,
  diffusionLoading: false,
  diffusions: [],
};

const alertsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "ALERTS_LOADING":
      return { ...state, alertsLoading: true };
    case LOADING_ADD_DIFFUSION:
      return { ...state, diffusionLoading: true };
    case "FETCH_ALERTS":
      return {
        ...state,
        alerts: payload.alerts ?? [],
        unreadAlerts: payload?.userUnreadAlerts ?? [],
        lastAlert: payload?.lastAlert ?? null,
        alertsLoading: false,
      };
    case GET_DIFFUSION_SUCCESS:
      return {
        ...state,
        diffusions: payload,
        alertsLoading: false,
      };
    case "CREATE_ALERT":
      return {
        ...state,
        alerts: [payload, ...state.alerts],
        alertsLoading: false,
      };
    case ADD_DIFFUSION_SUCCESS:
      const updatedDiffusions = [payload, ...state.diffusions].slice(0, 4);
      return {
        ...state,
        diffusions: updatedDiffusions,
        diffusionLoading: false,
      };
    case UPDATE_DIFFUSION_SUCCESS:
      return {
        ...state,
        diffusions: state.diffusions.map((diffusion) =>
          +diffusion.id === +payload.id ? payload : diffusion
        ),
        diffusionLoading: false,
      };
    case ADD_DIFFUSION_FAILED:
    case UPDATE_DIFFUSION_FAILED:
    case DELETE_DIFFUSION_FAILED:
      return {
        ...state,
        diffusionLoading: false,
      };
    case DELETE_DIFFUSION_SUCCESS:
      return {
        ...state,
        diffusions: state.diffusions.filter((item) => +item.id !== +payload),
        diffusionLoading: false,
      };
    case "UPDATE_ALERT":
      return {
        ...state,
        alerts: state.alerts.map((alert) =>
          alert.id === payload.id ? payload : alert
        ),
        alertsLoading: false,
      };
    case "READ_ALERT":
      return {
        ...state,
        unreadAlerts: state.unreadAlerts.filter((item) => item.id !== payload),
      };
    case "DELETE_ALERT":
      return {
        ...state,
        alerts: state.alerts.filter((item) => item.id !== payload),
        alertsLoading: false,
      };
    default:
      return state;
  }
};
export default alertsReducer;
