import { imageEndPoint } from "../../../Api/endPoint";
import _ from "lodash";
import defaultUserImg from "../../../Images/temp/user.png";
import maleDefault from "../../../Images/temp/user1.png";
import femaleDefault from "../../../Images/temp/user2.png";
import unknownDefault from "../../../Images/temp/user3.png";
export const handleMentionModule = (canMention, users) => {
  return canMention && users?.length > 0
    ? {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        source: function (searchTerm, renderList, mentionChar) {
          let values;
          if (mentionChar === "@") {
            values = users;
          }
          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values?.length; i++) {
              if (
                ~values[i].value
                  .toLowerCase()
                  .indexOf(searchTerm?.toLowerCase())
              )
                matches.push(values[i]);
            }
            renderList(
              _.uniqBy(matches, (item) => item?.id).reverse(),
              searchTerm
            );
          }
        },
        renderItem: function renderItem(item) {
          return `<img
          src="${
            item?.avatar
              ? imageEndPoint + item?.avatar
              : item.genre === 1
              ? maleDefault
              : item.genre === 2
              ? femaleDefault
              : unknownDefault
          }"
          alt=""
        />${item?.value}`;
        },
        defaultMenuOrientation: "top",
      }
    : {};
};

export const formats = [
  "emoji",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "ordered",
  "list",
  "bullet",
  "indent",
  "link",
  "mention",
  "image",
  "imageBlot",
];

export const emojisCategories = [
  {
    category: "suggested",
    name: "Récemment utilisé",
  },
  {
    category: "smileys_people",
    name: "Visages et personnes",
  },
  {
    category: "animals_nature",
    name: "Animaux et nature",
  },
  {
    category: "food_drink",
    name: "Nourriture et boissons",
  },
  {
    category: "travel_places",
    name: "Voyages et lieux",
  },
  {
    category: "activities",
    name: "Activités",
  },
  {
    category: "objects",
    name: "Objets",
  },
  {
    category: "symbols",
    name: "Symboles",
  },
  {
    category: "flags",
    name: "Drapeaux",
  },
];

export const emojiIcon = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M14.8405 1.83337H7.15884C3.82217 1.83337 1.83301 3.82254 1.83301 7.15921V14.8317C1.83301 18.1775 3.82217 20.1667 7.15884 20.1667H14.8313C18.168 20.1667 20.1572 18.1775 20.1572 14.8409V7.15921C20.1663 3.82254 18.1772 1.83337 14.8405 1.83337ZM7.79134 5.84837C8.73551 5.84837 9.51467 6.61837 9.51467 7.57171C9.51467 8.52504 8.74467 9.29504 7.79134 9.29504C6.83801 9.29504 6.06801 8.50671 6.06801 7.56254C6.06801 6.61837 6.84717 5.84837 7.79134 5.84837ZM10.9997 17.49C8.53384 17.49 6.52634 15.4825 6.52634 13.0167C6.52634 12.375 7.04884 11.8434 7.69051 11.8434H14.2905C14.9322 11.8434 15.4547 12.3659 15.4547 13.0167C15.473 15.4825 13.4655 17.49 10.9997 17.49ZM14.208 9.27671C13.2638 9.27671 12.4847 8.50671 12.4847 7.55337C12.4847 6.60004 13.2547 5.83004 14.208 5.83004C15.1613 5.83004 15.9313 6.60004 15.9313 7.55337C15.9313 8.50671 15.1522 9.27671 14.208 9.27671Z"
    fill="#59638B" />
</svg>`;

export const hiddenEmojis = ["1f1ee-1f1f1"];
