import React, { useEffect } from "react";
import SearchInput from "../../Common/SearchInput";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import { openModal } from "../../../REDUX/actions/modal";
import { useDispatch, useSelector } from "react-redux";
import FaqForm from "../../Faqs/FaqForm/FaqForm";
import { fetchFaqs, fetchFaqsCategories } from "../../../REDUX/actions/faq";

const FaqLayout = ({ onSearch }) => {
  const dispatch = useDispatch();
  const { faqsCategories } = useSelector((state) => state.faqReducer);

  useEffect(() => {
    dispatch(fetchFaqsCategories());
    dispatch(fetchFaqs());
  }, []);

  const { isSupervisor } = useSelector((state) => state.roleReducer);

  const handleSearch = (e) => {
    const value = e.target.value || "";
    onSearch(value);
  };

  return (
    <div className="d-flex flex-row align-items-center justify-content-between h50px">
      <div className={`col h100`}>
        <SearchInput
          placeholder={"Rechercher un élément..."}
          onChange={handleSearch}
          width={"w40"}
        />
      </div>
      {isSupervisor && (
        <div className="col-2 h100">
          <ColoredButton
            height="100"
            textButton={"Créer un élément"}
            color="light-blue"
            callback={() =>
              dispatch(
                openModal({
                  type: "small",
                  content: (
                    <FaqForm
                      categories={faqsCategories.map((el) => ({
                        value: el.id,
                        label: el.attributes.name,
                      }))}
                    />
                  ),
                })
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default FaqLayout;
