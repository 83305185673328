import React, { useState, useEffect } from "react";
import SimulatorListHeader from "./SimulatorListHeader";
import CategoryItem from "./CategoryItem";
import { getHighestPosition } from "../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  SwitchCategory,
  createNewCategory,
  updateCategory,
} from "../../REDUX/actions/form_category";
import { useLocation } from "react-router-dom";
const SimulateurCategoryList = ({
  categories,
  selectedCategory,
  isForm,
  validStep,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { devisRoute, prospetsRoute, configurateurRoute } = useSelector(
    (state) => state.organisationsReducer
  );
  const [newCategory, setNewCategory] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const { mode } = useSelector((state) => state.genearlReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);

  const switchItem = (item) => {
    (location.pathname === configurateurRoute ||
      validStep?.includes(+item?.attributes?.position) ||
      isSupervisor) &&
      dispatch(SwitchCategory(item));
  };
  const [name, setName] = useState();

  const addNewCategory = () => {
    dispatch(
      createNewCategory({
        name: name,
        position: getHighestPosition(categories)
          ? getHighestPosition(categories) + 1
          : 1,
      })
    );
    setNewCategory(false);
  };

  const updateCategoryName = () => {
    dispatch(updateCategory({ id: updateItem, name: name }));
    setUpdateItem(null);
    setName();
  };

  return (
    <div
      className={`h-100 px-3  ${
        mode === "light" ? "bg-white" : "bg-dark-grey-bg"
      }`}
    >
      <SimulatorListHeader
        disabled={!name}
        title={
          !isForm
            ? "Liste des catégories"
            : `Ajouter  ${
                location.pathname === `/${devisRoute}/new`
                  ? "une demande de devis"
                  : location.pathname === `/${prospetsRoute}/new`
                  ? "un prospect"
                  : "un bon de commande"
              } `
        }
        callback={() =>
          newCategory
            ? addNewCategory()
            : updateItem
            ? updateCategoryName()
            : setNewCategory(true)
        }
        value={newCategory || updateItem}
        isForm={isForm}
      />
      <div
        className={`${isForm ? "h90" : "h87"} mt-4 simulateurCategorylist ${
          mode === "light" ? "bg-white" : "bg-dark-grey-bg"
        }`}
      >
        {categories?.map((item, index) => {
          return (
            <div
              className="pe-auto   position-relative "
              key={item?.id}
              onClick={() => switchItem(item)}
            >
              <CategoryItem
                index={index}
                item={item}
                selectedCategory={selectedCategory}
                isForm={isForm}
                validStep={validStep}
                name={name}
                setName={setName}
                updateItem={updateItem}
                setUpdateItem={setUpdateItem}
                createNewCategory={addNewCategory}
              />
              {location.pathname !== configurateurRoute && (
                <div
                  style={{
                    position: "absolute",
                    height: "40px",
                    backgroundColor: "#a9bcd3",
                    width: "1px",
                    left: "28px",
                    top: "52px",
                  }}
                />
              )}
            </div>
          );
        })}
        {newCategory && (
          <CategoryItem
            isNew={true}
            setNewCategory={setNewCategory}
            highestPosition={getHighestPosition(categories)}
            name={name}
            setName={setName}
            updateItem={updateItem}
            setUpdateItem={setUpdateItem}
            createNewCategory={addNewCategory}
          />
        )}
        {isForm && (
          <div className="pe-auto position-relative">
            <div className="d-flex flex-row align-items-center mt-3 pointer z-index-1 position-relative">
              <div className="w-100 d-flex flex-row align-items-center ms-1 py-3 rounded">
                <div
                  className={`categoryPosition xbold ${
                    selectedCategory === "recap"
                      ? "bg-dark-blue text-white"
                      : "bg-light-text text-dark-blue"
                  } f14 mx-2 `}
                  onClick={() => switchItem("recap")}
                >
                  {getHighestPosition(categories) + 1}
                </div>
                <div
                  className={`ms-2 w70 f14 bold text-start t-02 ${
                    mode === "light"
                      ? "text-blue-grey "
                      : "text-light-blue-grey"
                  }`}
                >
                  Valider
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimulateurCategoryList;
