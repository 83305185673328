import React from "react";
import ThumbnailPlaceHolder from "./ThumbnailPlaceHolder";
import "./PlaceHolders.css";
import LightDarkPlaceHolder from "./LightDarkPlaceHolder";
const ThumbnailStackPlaceholder = () => {
  return (
    <div className="alignH gap-2">
      <div className="alignH ThumbnailStackPlaceholder ms">
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} style={{ marginLeft: index === 0 ? "" : "-15px" }}>
            <ThumbnailPlaceHolder smallThumbnail />
          </div>
        ))}
      </div>
      <LightDarkPlaceHolder width={150} height={12} />
    </div>
  );
};

export default ThumbnailStackPlaceholder;
