export const LOADING_COMMANDES = "LOADING_COMMANDES";
export const GET_COMMANDES_SUCCESS = "GET_COMMANDES_SUCCESS";
export const GET_COMMANDES_FAILED = "GET_COMMANDES_FAILED";

export const SET_COMMANDE = "SET_COMMANDE";
export const UNSET_COMMANDE = "UNSET_COMMANDE";

export const UPDATE_COMMANDE_SUCCESS = "UPDATE_COMMANDE_SUCCESS";
export const UPDATE_COMMANDE_FAILED = "UPDATE_COMMANDE_FAILED";

export const CHANGE_FILTER_COMMANDE = "CHANGE_FILTER_COMMANDE";

export const REMOVE_COMMANDE_SUCCESS = "REMOVE_COMMANDE_SUCCESS";

export const ADD_COMMANDE_SUCCESS = "ADD_COMMANDE_SUCCESS";
export const ADD_COMMANDE_FAILED = "ADD_COMMANDE_FAILED";
export const LOADING_ADD_COMMANDE = "LOADING_ADD_COMMANDE";

export const UPDATE_FIELDS_COMMANDE = "UPDATE_FIELDS_COMMANDE";
