import React, { useState } from "react";
import CustomRange from "../../../Common/CustomRange";
import ColoredButton from "../../../Common/Buttons/ColoredButton";
import LightDarkText from "../../../Common/LightDarkText";
import { SwitchIcon } from "../../../Common/LightDarkSvg";
import { handleDuration } from "../../../Tickets/ticketUtils";
import { useDispatch } from "react-redux";
import { updateForfait } from "../../../../REDUX/actions/societe";

const SocietyBundleForm = ({
  societyId,
  planId,
  planInputs,
  setPlanInputs,
  setEditingPlan,
}) => {
  const dispatch = useDispatch();
  const [immediately, setImmediately] = useState(false);

  return (
    <div className="d-flex flex-column gap-3 h100 w-100">
      <div className="alignH justify-content-between">
        <div className="alignH bold">
          <LightDarkText>Modifier le forfait mensuel : </LightDarkText>
          <LightDarkText color={"text-light-text ms-1"}>
            (forfait actuel: {handleDuration(planInputs.monthlyPlan)}/mois)
          </LightDarkText>
        </div>
        <div
          className="alignH xbold f12 gap-3"
          onClick={() => setImmediately(!immediately)}
        >
          <LightDarkText
            color={`${immediately ? "text-light-text" : "text-light-blue"}`}
          >
            À partir du mois suivant
          </LightDarkText>

          <SwitchIcon value={immediately} />
          <LightDarkText
            color={`${immediately ? "text-light-blue" : "text-light-text"}`}
          >
            À partir du mois courant
          </LightDarkText>
        </div>
      </div>
      <div>
        <CustomRange
          min={0}
          max={100}
          label={"Forfait mensuel"}
          totalInputs={planInputs}
          setValue={setPlanInputs}
          champs="monthlyPlan"
          editbundle={true}
        />
        <CustomRange
          min={0}
          max={planInputs.monthlyPlan}
          disabled={planInputs.monthlyPlan === 0}
          label={"Quota mensuel"}
          totalInputs={planInputs}
          setValue={setPlanInputs}
          champs="monthlyQuota"
          editbundle={true}
        />
      </div>
      <div className="alignH justify-content-end gap-3">
        <div className="alignH justify-content-between gap-2">
          <div className="">
            <ColoredButton
              textButton="Confirmer"
              outlined
              color={"light-blue"}
              callback={() =>
                dispatch(
                  updateForfait(societyId, planInputs, immediately, planId)
                ).then(setEditingPlan(false))
              }
            />
          </div>
          <div className="">
            <ColoredButton
              textButton="Annuler"
              outlined
              color={"dark-blue"}
              callback={() => setEditingPlan(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocietyBundleForm;
