import React from "react";
import LightDarkText from "../Common/LightDarkText";
import {
  CardActionsIcon,
  DeleteIcon,
  EditIcon,
  OutLink,
} from "../Common/LightDarkSvg";
import { donwloadDiffusionFile, fileUrl, imageLoader } from "../../Utils/utils";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import { useLocation } from "react-router-dom";
import LightDarkContainer from "../Common/LightDarkContainer";
import DiffusionForm from "./DiffusionForm";
import { openModal } from "../../REDUX/actions/modal";
import { deleteDiffusion } from "../../REDUX/actions/diffusions";
import { Document, Page, pdfjs } from "react-pdf";
import PreviewModal from "../Common/PreviewModal";
import SettingTooltip from "../Common/Tooltip/SettingTooltip";
import Tooltipitem from "../Common/Tooltip/Tooltipitem";

const DiffusionItem = ({ data, id }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const dispatch = useDispatch();
  const location = useLocation();
  const [showTooltip, setshowTooltip] = useState(false);
  const { mode } = useSelector((state) => state.genearlReducer);

  const today = moment().format("YYYY-MM-DD");
  const formattedDate = (date) => {
    return moment(date).format("DD/MM/YYYY [à] HH:mm");
  };
  const tooltipData = [
    {
      id: 1,
      text: "Modifier",
      icon: <EditIcon className="me-2" />,
      action: () =>
        dispatch(
          openModal({
            type: "small",
            content: <DiffusionForm alert={data} id={id} />,
          })
        ),
    },
    {
      id: 2,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" />,
      action: () =>
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(deleteDiffusion(id));
            },
            message: "Êtes-vous sur de supprimer cette diffusion?",
            message2:
              "En confirmant la suppression, la diffusion sera définitivement supprimée.",
            confirmButtonText: "Je confirme la suppression",
          })
        ),
    },
  ];
  return (
    <div
      className={` col-lg-${
        data?.isFullWidth ? "12" : "6"
      }   col-12  my-2 p-2 `}
    >
      <LightDarkContainer
        className="p-2 radius05  col-12 h100 d-flex flex-column justify-content-between"
        colorLight="white"
        colorDark="dark-grey-bg"
      >
        <div className={`d-flex justify-content-between mt-2 `}>
          <div className="w90">
            <LightDarkText
              className={`ms-2 f14 bold text-start 
              `}
              color={
                data?.link
                  ? "text-light-blue"
                  : mode === "light"
                  ? "text-dark-blue"
                  : "text-white"
              }
            >
              <a
                className="clickable-text "
                href={`${data?.link}`}
                target="_blank"
                rel="noreferrer"
              >
                {data?.titre}
              </a>
            </LightDarkText>
            <LightDarkText
              className={`ms-2  f12 bold text-start mt-2`}
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
            >
              {data?.description}
            </LightDarkText>
          </div>
          <div className="w10 d-flex justify-content-end ">
            {location.pathname !== "/" && (
              <CardActionsIcon clickCallback={() => setshowTooltip(true)} />
            )}
            <div
              style={{
                position: "relative",
                right: "120px",
                top: "25px",
                zIndex: 500,
              }}
            >
              <SettingTooltip
                showTooltip={showTooltip}
                setshowTooltip={setshowTooltip}
              >
                {tooltipData.map(({ id, text, action, icon }) => {
                  return (
                    <Tooltipitem
                      key={id}
                      id={id}
                      text={text}
                      icon={icon}
                      action={action}
                    />
                  );
                })}
              </SettingTooltip>
            </div>
          </div>
        </div>
        <div>
          <div
            className="p-2 mt-2 pointer"
            onClick={() => {
              data?.image?.data?.attributes?.mime?.includes("image") ||
              data?.image?.data?.attributes?.mime?.includes("pdf")
                ? dispatch(
                    openModal({
                      type: `${
                        data?.image?.data?.attributes?.mime?.includes("image")
                          ? ""
                          : "pdfFile"
                      }`,
                      content: (
                        <PreviewModal
                          fileData={{
                            name: data?.image?.data?.attributes?.name,
                            date: data?.createdAt,
                            mime: data?.image?.data?.attributes?.mime,
                            url: fileUrl(data?.image?.data?.attributes?.url),
                            size: data?.image?.data?.attributes?.size,
                          }}
                        />
                      ),
                    })
                  )
                : donwloadDiffusionFile(
                    data?.image?.data?.attributes?.url,
                    data?.image?.data?.attributes?.name
                  );
            }}
          >
            {data?.image?.data?.attributes?.mime?.includes("pdf") ? (
              <div
                className="diffusionCard t-02 radius05"
                style={{ overflow: "hidden" }}
              >
                <Document
                  file={fileUrl(data?.image?.data?.attributes?.url)}
                  loading={
                    <div
                      className={`d-flex align-items-center justify-content-center ${
                        mode === "light" ? "bg-white" : "bg-dark-grey-bg"
                      }`}
                      style={{ height: "250px", overflow: "hidden" }}
                    >
                      <LightDarkText
                        className="text-center custom-font-size bold"
                        color={
                          mode === "light"
                            ? "text-dark-blue"
                            : "text-light-text"
                        }
                      >
                        Chargement du PDF...
                      </LightDarkText>
                    </div>
                  }
                  error={
                    <div
                      className={`d-flex align-items-center justify-content-center ${
                        mode === "light" ? "bg-white" : "bg-dark-grey-bg"
                      }`}
                      style={{ height: "120px", overflow: "hidden" }}
                    >
                      <img src={require(`../../Images/pdf.png`)} width={60} />
                    </div>
                  }
                >
                  <Page pageNumber={1} loading={""} error={""} />
                </Document>
              </div>
            ) : data?.image?.data?.attributes?.url?.includes("xl") ? (
              <div
                className={`diffusionCard d-flex align-items-center justify-content-center radius05 ${
                  mode === "light" ? "bg-white" : "bg-dark-grey-bg"
                }`}
                style={{ overflow: "hidden" }}
              >
                <img src={require(`../../Images/Excel.png`)} width={70} />
              </div>
            ) : (
              <img
                src={imageLoader(data?.image?.data?.attributes?.url)}
                alt=""
                loading="lazy"
                className="w-100  ob-contain"
                style={{ maxHeight: "350px" }}
              />
            )}
          </div>
          <div className="alignH justify-content-between">
            <div className="d-flex">
              <LightDarkText
                className={`ms-2 f12 text-light-text  bold text-start mt-2`}
                color="text-light-text"
              >
                {formattedDate(data?.createdAt)}
              </LightDarkText>
              <LightDarkText
                className={`ms-2 f12 text-light-red  bold text-start mt-2`}
              >
                {data?.expirationDate < today && "Expiré"}
              </LightDarkText>
            </div>
            {data?.link && (
              <LightDarkText
                className={`hoverInit text-decoration-underline alignH gap-1 pointer me-2 f18 bold text-start 
              `}
                color={"text-light-blue"}
              >
                <OutLink size="24" color={"#3367ff"} />
                <a
                  className=""
                  href={`${data?.link}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Visitez le lien
                </a>
              </LightDarkText>
            )}
          </div>
        </div>
      </LightDarkContainer>
    </div>
  );
};

export default DiffusionItem;
