import moment from "moment";

import { useSelector } from "react-redux";

import LightDarkText from "./LightDarkText";

import { handleFirstLastName } from "../../Utils/utils";
import TitleImageBlock from "../Tickets/TicketCommonComponents/TitleImageBlock";

const HistoryCard = ({ avatar, user, title, creationDate }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <div className="mb-5">
      <TitleImageBlock
        avatar={avatar}
        defaultImage={`user${user?.attributes?.genre || 3}`}
        userName={handleFirstLastName(user)}
        historyThumbnail={true}
        extraData={
          <>
            <LightDarkText
              className="text-start bold f10 me-2"
              color={mode === "light" ? "text-light-text" : "text-white"}
            >
              {`(${user?.attributes?.stringRole})`}
            </LightDarkText>
            <LightDarkText
              className={"custom-ticket-font-size bold text-start"}
              color={
                user?.attributes?.roleId === 1
                  ? "text-light-blue"
                  : mode === "light"
                  ? "text-blue-grey"
                  : "text-light-text"
              }
            >
              {user?.attributes?.stringPoste}
            </LightDarkText>
            <LightDarkText
              className="text-start custom-ticket-font-size bold mt-4"
              color={mode === "light" ? "text-blue-grey" : "text-white"}
            >
              {title ? title : "a créé l'intervention"} le{" "}
              {moment(creationDate).format("DD/MM/YYYY")}
            </LightDarkText>
          </>
        }
      />
    </div>
  );
};

export default HistoryCard;
