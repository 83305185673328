import "moment/locale/fr";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LightDarkContainer from "../Common/LightDarkContainer";
import StatusButton from "../Common/Buttons/StatusButton";
import LightDarkPlaceHolder from "../PlaceHolders/LightDarkPlaceHolder";
import ThumbnailPlaceHolder from "../PlaceHolders/ThumbnailPlaceHolder";
import { ticketPriority, ticketStatut } from "./ticketUtils";
import useWindowWidth from "../../Hooks/useWindowWidth";
import TicketPrestation from "./TicketRowPartials/TicketPrestation";
import TicketDepartments from "./TicketRowPartials/TicketDepartments";
import TicketDevice from "./TicketRowPartials/TicketDevice";
import TicketGeneralElt from "./TicketRowPartials/TicketGeneralElt";

import ElementId from "../Common/TableComponents/TablePartials/ElementId";
import ElementDate from "../Common/TableComponents/TablePartials/ElementDate";

const TicketCard = ({ index, ticket, tableList, isLoading }) => {
  moment.locale("fr");

  const navigate = useNavigate();
  const screenWidth = useWindowWidth();

  const { ticketRoute } = useSelector((state) => state.organisationsReducer);
  const { ticketHandleLogic } = useSelector(
    (state) => state.organisationsReducer
  );

  const [notRead, setNotRead] = useState(
    ticket?.attributes?.notifications?.data?.attributes?.count > 0
  );
  useEffect(() => {
    setNotRead(ticket?.attributes?.notifications?.data?.attributes?.count > 0);
  }, [ticket?.attributes?.notifications?.data?.attributes?.count]);

  const handleClick = () => {
    navigate(`${ticketRoute}/${ticket.id}`, {
      state: { notification: true },
    });
  };

  return (
    <LightDarkContainer
      key={index}
      className="d-flex w-100 py-2 px-3 pointer"
      colorLight="white"
      colorDark="dark-grey-bg"
      archivedTicket={ticket?.attributes?.archived}
      hover={true}
      cardContainer={true}
      callBack={handleClick}
    >
      {tableList.map(({ widthSideBar, width, id, accessor, alignCenter }) => {
        const columnCol = false ? widthSideBar : width;
        return isLoading ? (
          <div
            className={`${
              accessor === "priority" || accessor === "statut"
                ? "alignC"
                : "alignH"
            } w${columnCol}`}
          >
            {accessor.includes("stringSociety") ? (
              <div className="alignH gap-2 w-100">
                <ThumbnailPlaceHolder />
                <LightDarkPlaceHolder customClass="w60" height={12} />
              </div>
            ) : accessor === "priority" || accessor === "statut" ? (
              <LightDarkPlaceHolder customClass="w85" height={40} />
            ) : (
              <LightDarkPlaceHolder customClass="w85" height={10} />
            )}
          </div>
        ) : (
          <div
            className={`${alignCenter ? "alignC" : "alignH"}
               ${columnCol ? `w${columnCol}` : "flex-1"} p-0`}
            key={id}
          >
            {accessor === "id" ? (
              <ElementId id={ticket?.id} notRead={notRead} />
            ) : accessor === "priority" ? (
              <StatusButton
                height={screenWidth < 1200 ? "30px" : "40px"}
                ticketButton={true}
                type={ticketPriority(ticket.attributes.priority).color}
                text={
                  screenWidth < 1200
                    ? ticketPriority(ticket.attributes.priority).icon
                    : ticketPriority(ticket.attributes.priority).text
                }
                background={ticketPriority(ticket.attributes.priority).bg}
              />
            ) : accessor === "statut" ? (
              <StatusButton
                height={screenWidth < 1200 ? "30px" : "40px"}
                ticketButton={true}
                type={ticketStatut(ticket.attributes.statut).color}
                text={ticketStatut(ticket.attributes.statut).text}
                background={ticketStatut(ticket.attributes.statut).bg}
              />
            ) : accessor === "stringCreatedAt" ? (
              <ElementDate element={ticket} notRead={notRead} />
            ) : accessor === "stringDevice" && ticketHandleLogic !== 2 ? (
              <TicketDevice ticket={ticket} notRead={notRead} />
            ) : accessor === "attributes?.stringPrestation" ? (
              ticketHandleLogic === 2 ? (
                <TicketDepartments ticket={ticket} notRead={notRead} />
              ) : (
                <TicketPrestation ticket={ticket} notRead={notRead} />
              )
            ) : (
              <TicketGeneralElt
                ticket={ticket}
                accessor={accessor}
                notRead={notRead}
              />
            )}
          </div>
        );
      })}
    </LightDarkContainer>
  );
};

export default TicketCard;
