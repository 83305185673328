import { useSelector } from "react-redux";
import LightDarkText from "../../../Common/LightDarkText";
import { handleFirstLastName } from "../../../../Utils/utils";
import moment from "moment";
import { AddedIcon, RemovedIcon } from "../../../Common/LightDarkSvg";

const AddParticipantMessage = ({ message, createdAt,sender }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const formattedDate = (date) => {
    return moment(date).format("DD/MM/YYYY [à] HH:mm");
  };

  return (
    <div
      className={`radius05 m-auto d-flex my-4 gap-4 align-items-center p-2 ${
        message.includes("retiré")
          ? "participant-ticket-removed"
          : "participant-ticket-added"
      }`}
    >
      <LightDarkText
        className="custom-ticket-font-size bold text-start gap-2 flex-1 alignH"
        color={
          mode === "light"
            ? "text-blue-grey"
            : "text-white"
        }
      >
        {message.includes("retiré") ? (
          <RemovedIcon className={"icon-added-removed"} />
        ) : (
          <AddedIcon className={"icon-added-removed"} />
        )}{" "}
        {`${handleFirstLastName(sender)} ${message} le ${formattedDate(
          createdAt
        )}`}
      </LightDarkText>
    </div>
  );
};

export default AddParticipantMessage;
