import React from "react";
import { useSelector } from "react-redux";

import LightDarkText from "../LightDarkText";
import { SortIcon } from "../LightDarkSvg";
import "./TableList.css";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import TableEltPlaceHolder from "./TableEltPlaceHolder";

const TableList = ({
  children,
  headerItems,
  setSort,
  sort,
  hasTooltip = true,
  hasSelect = false,
  loading = false,
}) => {
  const screenWidth = useWindowWidth();

  const { mode, openSideBar } = useSelector((state) => state.genearlReducer);

  return (
    <div
      className={`radius05 p-0 scrollable sideChatListContainer ${
        openSideBar && screenWidth > 992 ? "col-9" : "col-12"
      }`}
      style={{
        backgroundColor: `${mode === "light" ? "white" : "#181f3a"}`,
        overflowX: "hidden",
        height: `${"calc(100vh - 180px)"}`,
      }}
    >
      <div
        className={`d-flex radius05 border border-1 w-100 ${
          mode === "light" ? "bg-white border-white" : "bg-dark-grey-bg"
        }
        ${hasSelect ? "py-3 pe-3" : "p-3"}
        
        `}
        style={{
          position: "sticky",
          top: 0,
          marginRight: hasTooltip && "20px",
        }}
      >
        {hasSelect && <div className="w5"></div>}

        {headerItems.map(
          ({
            id,
            name,
            accessor,
            widthSideBar,
            width,
            sortField,
            alignCenter,
          }) => {
            const columnCol = openSideBar ? widthSideBar : width;
            return (
              <div
                className={`${
                  alignCenter ? "alignC" : "alignH"
                } w${columnCol} p-0`}
                style={{ display: columnCol === 0 && "none" }}
                key={id}
                onClick={() => {
                  sortField &&
                    setSort({
                      champs: sortField,
                      asc: !sort.asc,
                    });
                }}
              >
                <LightDarkText
                  className={`${
                    sortField && "pointer"
                  } fitContent custom-ticket-font-size bold text-start`}
                  color="text-light-text"
                >
                  {name}
                </LightDarkText>
                {accessor !== "actions" && sortField && <SortIcon />}
              </div>
            );
          }
        )}
      </div>
      <div className="innerList">
        {loading ? <TableEltPlaceHolder /> : children}
      </div>
    </div>
  );
};

export default TableList;
