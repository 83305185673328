import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UserForm from "../UserForm/UserForm";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { openModal } from "../../../REDUX/actions/modal";
import { openConfirmationModal } from "../../../REDUX/actions/confirmationModal";
import { userModalData } from "../userModalData";
import { openSideBar } from "../../../REDUX/actions/sideBar";
import { getConditionalUsers } from "../../../REDUX/actions/clients";
import { getUserById } from "../../../REDUX/actions/operateurs";

const UserSideBarActions = ({ isOp }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const { canCreateCollabs } = useSelector((state) => state.roleReducer);
  const { selectedUser } = useSelector((state) => state.clientsReducer);

  return (
    canCreateCollabs && (
      <div className="row justify-content-between mt-3">
        <div
          className={`${
            screenWidth < 1200 && screenWidth > 992
              ? " mt-1  order-1"
              : "col-5 pe-0"
          }`}
        >
          <ColoredButton
            textButton={
              selectedUser.attributes.user_org_permissions.data[0].attributes
                .archived
                ? "Désarchiver"
                : "Archiver"
            }
            outlined
            color="blue-grey"
            callback={() => {
              dispatch(
                openConfirmationModal(userModalData(selectedUser, dispatch))
              );
            }}
          />
        </div>
        <div
          className={`${
            screenWidth < 1200 && screenWidth > 992 ? " mt-2" : "col-7 ps-0"
          }`}
        >
          <ColoredButton
            color="light-blue"
            textButton="Éditer le profil"
            callback={() => {
              dispatch(
                openSideBar({
                  content: (
                    <UserForm
                      isOp={isOp}
                      userId={selectedUser.id}
                      afterSubmitCallback={
                        isOp
                          ? () => {
                              dispatch(getConditionalUsers(false));
                              dispatch(getUserById(selectedUser.id));
                            }
                          : () => {
                              dispatch(getConditionalUsers(true));
                              dispatch(getUserById(selectedUser.id));
                            }
                      }
                    />
                  ),
                  type: "w40",
                })
              );
            }}
          />
        </div>
      </div>
    )
  );
};

export default UserSideBarActions;
