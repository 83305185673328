import { gql } from "@apollo/client";
import { FORM_CATEGORY_ATTRIBUTES } from "../Attributes/formCategory";
export const CREATE_CATEGORY = gql`
  mutation ($name: String, $position: Int) {
    createFormCategory(data: { name: $name, position: $position }) {
      data {
        id
        attributes {
          ${FORM_CATEGORY_ATTRIBUTES}
        }
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation ($id:ID!,$name: String, $position: Int) {
    updateFormCategory(id:$id,data: { name: $name, position: $position }) {
      data {
        id
        attributes {
          ${FORM_CATEGORY_ATTRIBUTES}
        }
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation ($id: ID!) {
    deleteFormCategory(id: $id) {
      data {
        id
      }
    }
  }
`;
