import {
  FacebookIcon,
  GlobalIcon,
  InstagramIcon,
  LinkedInIcon,
  TikTokIcon,
  TwitterIcon,
} from "../../../Common/LightDarkSvg";

const SocialMediaIcons = ({ event, eventPopUp }) => {
  return (
    <>
      {event?.facebook ||
      event?.instagram ||
      event?.linkedIn ||
      event?.twitter ||
      event?.tikTok ||
      event?.global ? (
        <div
          className={`mt-4 d-flex align-items-center ${
            eventPopUp ? "gap-3" : "gap-1"
          }`}
        >
          {event?.facebook && (
            <FacebookIcon
              light={!eventPopUp}
              dark={eventPopUp}
              small={!eventPopUp}
            />
          )}
          {event?.instagram && (
            <InstagramIcon
              light={!eventPopUp}
              dark={eventPopUp}
              small={!eventPopUp}
            />
          )}
          {event?.linkedIn && (
            <LinkedInIcon
              light={!eventPopUp}
              dark={eventPopUp}
              small={!eventPopUp}
            />
          )}
          {event?.twitter && (
            <TwitterIcon
              light={!eventPopUp}
              dark={eventPopUp}
              small={!eventPopUp}
            />
          )}
          {event?.tikTok && (
            <TikTokIcon
              light={!eventPopUp}
              dark={eventPopUp}
              small={!eventPopUp}
            />
          )}
          {event?.global && (
            <GlobalIcon
              light={!eventPopUp}
              dark={eventPopUp}
              small={!eventPopUp}
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default SocialMediaIcons;
