import { gql } from "@apollo/client";
import { userAttributes } from "../general/userFields";

export const LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    login(input: { identifier: $email, password: $password }) {
      jwt
      user {
        id
      }
    }
  }
`;

export const GET_ME = gql`
  query ($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          ${userAttributes}
          role {
            data {
              id
              attributes {
                name
              }
            }
          }

          SocietiesUnderResponsability(pagination: { pageSize: 1000 }) {
            data {
              id
              attributes {
                name
              }
            }
          }
          workIn(pagination: { pageSize: 1000 }) {
            data {
              id
              attributes {
                name
              }
            }
          }
          poste {
            data {
              id
              attributes {
                name
              }
            }
          }
          devices(pagination: { pageSize: 1000 }) {
            data {
              id
              attributes {
                name
              }
            }
          }
          prestations(pagination: { pageSize: 1000 }) {
            data {
              id
              attributes {
                name
              }
            }
          }
          cloud {
            data {
              id
            }
          }
          alerts(pagination: { pageSize: 1000 }) {
            data {
              id
            }
          }
          calendars(pagination: { pageSize: 1000 }) {
            data {
              id
            }
          }
          user_org_permissions(pagination: { pageSize: 1000 }) {
            data {
              id
              attributes {
                role
                name
                organisation {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                departements(pagination: { pageSize: 1000 }) {
                  data {
                    id
                    attributes {
                      name
                      code
                    }
                  }
                }
                cloud {
                  data {
                    id
                  }
                }
                notification_setting {
                  data {
                    id
                    attributes {
                      ticketCreation
                      ticketResolved
                      ticketArchive
                      message
                      intervention
                      cloudShare
                      fileImport
                      collaboratorAdded
                      profileMention
                      packageExceeded
                      prospectActions
                      devisActions
                      commandeActions
                      newProspect
                      newDevis
                      newCommande
                    }
                  }
                }
                SocietiesUnderResponsability(pagination: { pageSize: 1000 }) {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                workIn(pagination: { pageSize: 1000 }) {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                poste {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                preferredLanguage {
                  data {
                    id
                    attributes {
                      name
                      code
                    }
                  }
                }
                devices(pagination: { pageSize: 1000 }) {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                prestations(pagination: { pageSize: 1000 }) {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                cloud {
                  data {
                    id
                  }
                }
              }
            }
          }
          avatar {
            data {
              id
              attributes {
                image {
                  data {
                    id
                    attributes {
                      url
                      name
                      mime
                      createdAt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CHECK = gql`
  mutation {
    UserPermissionsMe {
      data {
        id
      }
    }
  }
`;

export const FORGOTPASSWORD = gql`
  mutation ($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

export const RESETPASSWORD = gql`
  mutation (
    $password: String!
    $passwordConfirmation: String!
    $code: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
    ) {
      jwt
      user {
        id
      }
    }
  }
`;
