import {
  ADD_CATEGORY_FAILED,
  ADD_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  LOADING,
  SWITCH_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
} from "../ActionsType/formCategoryTypes";
import {
  ADD_QUESTION_SUCCESS,
  DELETE_QUESTIONS_SUCCESS,
  UPDATE_QUESTIONS_SUCCESS,
} from "../ActionsType/formQuestionTypes";

const initialState = {
  loading: false,
  categories: [],
  selectedCategory: {},
  questionsList: [],
  error: null,
};

const formCategoriesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING:
      return { ...state, loading: true };
    case GET_CATEGORIES_SUCCESS:
      const sortedArray = payload
        .slice()
        .sort((a, b) => a.attributes?.position - b.attributes?.position);
      return {
        ...state,
        categories: sortedArray,
        selectedCategory: sortedArray[0],
        loading: false,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case SWITCH_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, payload],
        loading: false,
      };

    case ADD_QUESTION_SUCCESS:
      const newQuestion = payload.question;
      const newAnswers = payload.answer;
      const updatedQuestion = {
        ...newQuestion,
        attributes: {
          ...newQuestion.attributes,
          answers: {
            data: [...newQuestion.attributes.answers.data, ...newAnswers],
          },
        },
      };
      const categoryId = updatedQuestion?.attributes?.category?.data.id;

      const updatedCategories = state.categories.map((category) => {
        if (category.id === categoryId) {
          const question = category.attributes.questions.data || [];

          const updatedQuestions = [...question, updatedQuestion];
          return {
            ...category,
            attributes: {
              ...category.attributes,
              questions: { data: updatedQuestions },
            },
          };
        }

        return category;
      });
      const updateSelectedCategory = updatedCategories.find(
        (el) => el.id === categoryId
      );

      return {
        ...state,
        categories: updatedCategories,
        selectedCategory: updateSelectedCategory,
        loading: false,
      };
    case ADD_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case DELETE_QUESTIONS_SUCCESS:
      const questions = state.selectedCategory.attributes.questions.data;
      const newQuestions = questions.filter((el) => el.id !== payload);

      const updatedSelectedCategory = {
        ...state.selectedCategory,
        attributes: {
          ...state.selectedCategory.attributes,
          questions: {
            ...state.selectedCategory.attributes.questions,
            data: newQuestions,
          },
        },
      };
      return {
        ...state,
        loading: false,
        categories: state.categories.map((category) =>
          category.id === updatedSelectedCategory.id
            ? updatedSelectedCategory
            : category
        ),
        selectedCategory: updatedSelectedCategory,
      };
    case DELETE_CATEGORY_SUCCESS:
      const index = state.categories.findIndex(
        (category) => category.id === payload.id
      );
      const previousItem = state.categories[index - 1];
      return {
        ...state,
        loading: false,
        categories: state.categories.filter((item) => item.id !== payload.id),
        selectedCategory: previousItem,
      };

    case UPDATE_QUESTIONS_SUCCESS:
      const updatedQ = payload;
      const idCat = updatedQ?.attributes?.category?.data.id;

      const updatedCat = state.categories.map((category) => {
        if (category.id === idCat) {
          const updatedQuestions = category.attributes.questions.data.map(
            (question) => {
              if (question.id === updatedQ.id) {
                return updatedQ;
              }
              return question;
            }
          );

          return {
            ...category,
            attributes: {
              ...category.attributes,
              questions: { data: updatedQuestions },
            },
          };
        }
        return category;
      });
      const updateSelected = updatedCat.find((el) => el.id === idCat);
      return {
        ...state,
        categories: updatedCat,
        selectedCategory: updateSelected,
      };

    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCategory: payload,
        categories: state.categories
          .map((cat) => (cat.id === payload.id ? payload : cat))
          .sort((a, b) => a.attributes.position - b.attributes.position),
      };
    default:
      return state;
  }
};
export default formCategoriesReducer;
