import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneDrive } from "../../../REDUX/actions/externalServices";
import OneDriveFodlerList from "./OneDriveFodlerList";
import OneDriveFileList from "./OneDriveFileList";
import "./OneDriveSection.css";
import SharePointSiteList from "./SharePointSiteList";

const OneDriveSection = ({ search }) => {
  const dispatch = useDispatch();
  const {
    externalServices,
    oneDriveSynched,
    oneDriveFiles,
    oneDriveFolders,
    oneDriveInitialFetch,
    sharePointSites,
  } = useSelector((state) => state.externalServicesReducer);
  useEffect(() => {
    if (oneDriveSynched && !oneDriveInitialFetch) {
      dispatch(fetchOneDrive());
    }
  }, [externalServices]);

  return (
    oneDriveSynched && (
      <div className="mb-4">
        <OneDriveFodlerList search={search} oneDriveFolders={oneDriveFolders} />

        <OneDriveFileList search={search} oneDriveFiles={oneDriveFiles} />
        {sharePointSites.length ? (
          <SharePointSiteList
            search={search}
            sharePointSites={sharePointSites}
          />
        ) : null}
      </div>
    )
  );
};

export default OneDriveSection;
