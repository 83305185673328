import _ from "lodash";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkContainer from "../../../../Common/LightDarkContainer";
import LightDarkText from "../../../../Common/LightDarkText";
import { CloseIcon } from "../../../../Common/LightDarkSvg";

import useWindowWidth from "../../../../../Hooks/useWindowWidth";

import { unSelectFiles } from "../../../../../REDUX/actions/files";

const MessageSelectedFiles = () => {
  const dispatch = useDispatch();

  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { temporarySelected } = useSelector((state) => state.filesReducer);

  return temporarySelected?.length ? (
    <div
      className="d-flex align-items-center justify-content-start mb-3"
      style={{ overflowX: "scroll" }}
    >
      {temporarySelected?.map((file, index) => (
        <LightDarkContainer
          key={index}
          colorLight="light-mode"
          colorDark="dark-mode"
          className={`my-2 me-2 px-2 py-2 radius05 alignH gap-3 justify-content-between`}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip style={{ zIndex: 500 }}>
                <LightDarkText
                  className={"custom-ticket-font-size bold pointer text-white"}
                >
                  {file?.name}
                </LightDarkText>
              </Tooltip>
            }
          >
            <LightDarkText
              className={
                "text-start custom-ticket-font-size bold pointer text-ellipsis"
              }
              color={mode === "light" ? "text-dark-blue" : "text-light-text"}
            >
              {file?.name}
            </LightDarkText>
          </OverlayTrigger>
          <div>
            <CloseIcon
              dimension={screenWidth < 1200 ? 22 : 24}
              clickCallback={async () => {
                dispatch(unSelectFiles(file));
              }}
            />
          </div>
        </LightDarkContainer>
      ))}
    </div>
  ) : null;
};

export default MessageSelectedFiles;
