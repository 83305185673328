import React from "react";
import { imageLoader } from "../../../Utils/utils";

const SocietyAvatar = ({
  width = "64px",
  height = "64px",
  imageUrl = null,
  className = "",
}) => {
  return (
    <div
      className={`societyAvatar radius05 ${className}`}
      style={{
        width: width,
        height: height,

        backgroundImage: imageUrl
          ? `url(${imageLoader(imageUrl)})`
          : `url(${require(`../../../Images/temp/societySquare.png`)})`,
      }}
    >
      {""}
    </div>
  );
};

export default SocietyAvatar;
