import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../REDUX/actions/modal";
import LightDarkText from "../../Common/LightDarkText";
import LightDarkContainer from "../../Common/LightDarkContainer";
import { CloseIcon } from "../../Common/LightDarkSvg";
import ColoredButton from "../../Common/Buttons/ColoredButton";

import AlertForm from "./AlertForm";
import AlertList from "./AlertList";

import "./Alert.css";

const Alerts = ({ show }) => {
  const dispatch = useDispatch();
  const elRef = useRef(null);

  const { mode } = useSelector((state) => state.genearlReducer);
  const { canCreateAlerts } = useSelector((state) => state.roleReducer);

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      show(false);
    }
  };

  useEffect(() => {
    elRef?.current?.focus();
  }, [elRef]);

  return (
    <div ref={elRef} onBlur={handleBlur} tabIndex={-1}>
      <LightDarkContainer
        colorLight="white"
        colorDark="dark-grey-bg"
        className={`${mode === "light" ? "lightAlertList" : "darkAlertList"}
      alertList text-start d-flex flex-column align-items-center radius05 px-3 pt-3 position-absolute`}
      >
        <div className="pb-3 w-100 d-flex justify-content-between">
          <LightDarkText className="custom-title-font-size bold">
            Nouvelles fonctionnalités
          </LightDarkText>
          <div></div>
          <CloseIcon clickCallback={() => show(false)} />
        </div>
        <div className="w-100 mt-2 sideChatContainer">
          <AlertList />
        </div>
        {canCreateAlerts && (
          <div
            className="col-4 p-0 me-2 position-absolute"
            style={{ right: 0, bottom: 20 }}
          >
            <ColoredButton
              textButton="Créer une annonce"
              alertBtn
              color="light-blue"
              callback={() =>
                dispatch(
                  openModal({
                    type: "small",
                    content: <AlertForm isNew={true} />,
                  })
                )
              }
            />
          </div>
        )}
      </LightDarkContainer>
    </div>
  );
};
export default Alerts;
