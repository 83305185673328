import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FaqLayout from "../../Components/Layout/Faq/FaqLayout";
import FaqItemView from "../FaqItemView/FaqItemView";
import EmptyList from "../../Components/Common/EmptyList";
import LightDarkText from "../../Components/Common/LightDarkText";
import FaqsViewPlaceHolder from "../../Components/PlaceHolders/Faqs/FaqsViewPlaceHolder";
import { normalizeString } from "../../Utils/utils";
import { DeleteIcon } from "../../Components/Common/LightDarkSvg";
import { deleteFaq } from "../../REDUX/actions/faq";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";

const FaqView = () => {
  const dispatch = useDispatch();
  const { faqsLoading, faqsCategories, faq } = useSelector(
    (state) => state.faqReducer
  );
  const { mode } = useSelector((state) => state.genearlReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredCategories = !searchTerm
    ? faqsCategories
    : faqsCategories
        .map((category) => {
          const isCategoryMatch = normalizeString(
            category.attributes.name
          ).includes(normalizeString(searchTerm));

          if (isCategoryMatch) {
            return category;
          }

          const matchingQuestions =
            category.attributes.faq_elements?.data?.filter((faq) =>
              normalizeString(faq.attributes.title).includes(
                normalizeString(searchTerm)
              )
            );

          if (matchingQuestions && matchingQuestions.length > 0) {
            return {
              ...category,
              attributes: {
                ...category.attributes,
                faq_elements: {
                  ...category.attributes.faq_elements,
                  data: matchingQuestions,
                },
              },
            };
          }

          return null;
        })
        .filter(Boolean);

  const hasFaqElements = faqsCategories.some(
    (category) => category.attributes.faq_elements?.data?.length > 0
  );

  const handleDeleteFaq = (faqId) => {
    dispatch(
      openConfirmationModal({
        callBack: () => {
          dispatch(deleteFaq(faqId));
          if (selectedFaq?.id === faqId) {
            setSelectedFaq(null);
          }
        },
        icon: "archive",
        message: "Voulez vous supprimer ce FAQ ?",
        message2: "Cette action est irréversible",
        confirmButtonText: "Supprimer",
      })
    );
  };

  useEffect(() => {
    if (!selectedFaq && faqsCategories.length > 0) {
      const firstCategory = faqsCategories[0];
      const firstFaq = firstCategory.attributes.faq_elements?.data?.[0];
      if (firstFaq) {
        setSelectedFaq(firstFaq);
      }
    }
  }, [faqsCategories]);

  return (
    <div className="h-100">
      <FaqLayout onSearch={setSearchTerm} setSelectedFaq={setSelectedFaq} />
      <div className="row mt-4 h-100">
        {faqsLoading ? (
          <FaqsViewPlaceHolder />
        ) : !hasFaqElements ? (
          <EmptyList type="faq" />
        ) : (
          <div className="d-flex flex-row gap-3 justify-content-between">
            <div className="col-3 bg-white p-5">
              {filteredCategories.map((category) => {
                if (!category.attributes.faq_elements?.data?.length)
                  return null;

                return (
                  <div key={category.id} className="mb-4">
                    <LightDarkText
                      className="text-start f16 bold mb-3 text-uppercase"
                      color={
                        mode === "light" ? "text-dark-blue" : "text-dark-blue"
                      }
                    >
                      {category.attributes.name}
                    </LightDarkText>

                    {category.attributes.faq_elements?.data?.map((faq) => (
                      <div
                        key={faq.id}
                        className={`p-2 mb-2 rounded cursor-pointer transition-all d-flex justify-content-between align-items-center ${
                          selectedFaq?.id === faq.id
                            ? mode === "light"
                              ? "border-start border-4 border-dark-blue bg-light-grey"
                              : "border-start border-4 border-dark-blue bg-light-gray"
                            : ""
                        }`}
                      >
                        <div
                          className="flex-grow-1"
                          onClick={() => setSelectedFaq(faq)}
                        >
                          <LightDarkText
                            className="text-start f14 pointer"
                            color={
                              mode === "light"
                                ? "text-blue-grey"
                                : "text-blue-grey"
                            }
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: faq.attributes.title,
                              }}
                            />
                          </LightDarkText>
                        </div>
                        {isSupervisor && (
                          <div
                            className="ms-2 cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteFaq(faq.id);
                            }}
                          >
                            <DeleteIcon size={16} />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
            <div className="col-9 bg-white h-100">
              {selectedFaq && <FaqItemView faqId={selectedFaq.id} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqView;
