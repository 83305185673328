export const changeDisplayMode = (mode) => async (dispatch) => {
  if (mode === "dark") {
    dispatch({ type: "DARK_MODE" });
  } else {
    dispatch({ type: "LIGHT_MODE" });
  }
};

export const openSideBarCard = () => async (dispatch) => {
  dispatch({ type: "OPEN_SIDE_BAR_CARD" });
};
export const closeSideBarCard = () => async (dispatch) => {
  dispatch({ type: "HIDE_SIDE_BAR_CARD" });
};
