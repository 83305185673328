import React from "react";
import ThumbnailPlaceHolder from "./ThumbnailPlaceHolder";
import LightDarkPlaceHolder from "./LightDarkPlaceHolder";

const ParticipantsPlaceHolder = () => {
  return ["", "", "", "", "", "", "", ""].map((_, index) => (
    <div className="d-flex alignH gap-3 mb-3" key={index}>
      <ThumbnailPlaceHolder smallThumbnail={true} />
      <div className="w70 gap-2 d-flex flex-column">
        <LightDarkPlaceHolder customClass={"py-1"} />
        <LightDarkPlaceHolder customClass={"py-1"} />
        <LightDarkPlaceHolder customClass={"py-1"} />
      </div>
    </div>
  ));
};

export default ParticipantsPlaceHolder;
