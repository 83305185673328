import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { fileUrl } from "../../../Utils/utils";
import LightDarkText from "../../Common/LightDarkText";
import ColoredButton from "../../Common/Buttons/ColoredButton";

import AlertPopupHeader from "./AlertPopupHeader";

const AlertPopUp = ({ alert }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div
      className={`modalContainer ${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      }  pt-0 pb-3 radius05`}
    >
      <div className="h100 d-flex flex-column ">
        <AlertPopupHeader alert={alert} />

        <div className="px-3 mt70px">
          <img
            width="100%"
            src={fileUrl(alert?.attributes?.image?.data?.attributes?.url)}
            alt=""
          />
          {/* <div
            className="radius05 bg-white mb-3"
            style={{
              height: "520px",
              width: "100%",
              backgroundImage: `url(${fileUrl(
                alert?.attributes?.image?.data?.attributes?.url
              )})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div> */}
          <LightDarkText
            className="custom-ticket-font-size bold my-3 p-nomb scrollbarY"
            color={mode === "light" ? "text-blue-grey" : "text-white"}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: alert?.attributes?.description,
              }}
            ></span>
          </LightDarkText>
        </div>
        <div className="align-self-center mt-2">
          {alert?.attributes?.url && (
            <ColoredButton
              textButton="Accéder"
              alertBtn
              outlined
              color="blue-grey"
              link={`${alert?.attributes?.url}`}
            />
          )}
          <LightDarkText
            className={`text-center custom-ticket-font-size ${
              alert?.attributes?.url && "mt-3"
            }`}
            color={mode === "light" ? "text-light-text" : "text-white"}
          >
            {moment(alert?.attributes?.createdAt).format("DD/MM/YYYY")} à{" "}
            {moment(alert?.attributes?.createdAt).format("HH:mm")}
          </LightDarkText>
        </div>
      </div>
    </div>
  );
};

export default AlertPopUp;
