export const INVOICE_ATTRIBUTES = `
number
date
type
TVA
TTC
HT
media {
    data {
          id
          attributes {
              url
              name
              size
              mime
          }
        }
      }
societe {
  data {
    id
    attributes {
      name
        avatar {
    data {
      id
      attributes {
        image {
          data {
            id 
            attributes {
              url
            }
          }
        }
      }
    }
  }
    }
  }
}
`;
