import React from "react";
import LightDarkText from "../../../Common/LightDarkText";
import { useSelector } from "react-redux";

const EventHeader = ({ isToggle, setIsToggle }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <div className={`d-flex justify-content-center align-items-center`}>
      <LightDarkText
        className="text-start custom-font-size bold me-4 pointer clickable-text t-02"
        color={
          isToggle === "details"
            ? "text-light-blue"
            : mode === "light"
            ? "text-blue-grey"
            : "text-light-text"
        }
        onclickCallback={() => setIsToggle("details")}
      >
        Détails de l'événement
        {isToggle === "details" && (
          <div
            className="radius20 mx-4"
            style={{
              borderBottom: "4px solid #3365ff",
              position: "relative",
              top: 13,
            }}
          />
        )}
      </LightDarkText>
      <LightDarkText
        className="text-start custom-font-size bold pointer clickable-text t-02"
        color={
          isToggle === "history"
            ? "text-light-blue"
            : mode === "light"
            ? "text-blue-grey"
            : "text-light-text"
        }
        onclickCallback={() => setIsToggle("history")}
      >
        Historique
        {isToggle === "history" && (
          <div
            className="radius20 mx-2"
            style={{
              borderBottom: "4px solid #3365ff",
              position: "relative",
              top: 13,
            }}
          />
        )}
      </LightDarkText>
    </div>
  );
};

export default EventHeader;
