import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { openModal } from "../../../REDUX/actions/modal";
import { fileUrl } from "../../../Utils/utils";
import Thumbnail from "../../Common/Thumbnail";
import LightDarkText from "../../Common/LightDarkText";
import LightDarkContainer from "../../Common/LightDarkContainer";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import AlertPopUp from "./AlertPopUp";

const AlertCard = ({ alert, index }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <LightDarkContainer
      key={index}
      colorLight="white"
      colorDark="dark-grey-bg"
      className="radius05"
    >
      <div className="w-100 gap-3 d-flex align-items-center justify-content-between">
        <div className="alignH gap-3">
          <Thumbnail platformImg="agilix-logo-nom" size={40} rounded />
          <div>
            <LightDarkText className="text-start bold custom-font-size me-2">
              Équipe Agilix
            </LightDarkText>
          </div>
        </div>
        <div>
          <ColoredButton
            textButton="Plus d'info"
            color="light-blue"
            callback={() =>
              dispatch(
                openModal({
                  type: "alertModal",
                  content: <AlertPopUp alert={alert} />,
                })
              )
            }
          />
        </div>
      </div>
      <LightDarkText
        className="text-start custom-ticket-font-size bold my-3 p-nomb"
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: alert?.attributes?.description,
          }}
        ></span>
      </LightDarkText>
      <img
        width="100%"
        src={fileUrl(alert?.attributes?.image?.data?.attributes?.url)}
        alt=""
      />
      {/* <div
        className="radius05 image-alert bg-white mb-3"
        style={{
          height: "300px",
          backgroundImage: `url(${fileUrl(
            alert?.attributes?.image?.data?.attributes?.url
          )})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div> */}
      <LightDarkText
        className="custom-ticket-font-size"
        color={mode === "light" ? "text-light-text" : "text-white"}
      >
        {moment(alert?.attributes?.createdAt).format("DD/MM/YYYY")} à{" "}
        {moment(alert?.attributes?.createdAt).format("HH:mm")}
      </LightDarkText>
      <div
        className="my-3"
        style={{
          borderTop: `2px solid ${mode === "light" ? "#F4F6FD" : "#252C45"}`,
        }}
      ></div>
    </LightDarkContainer>
  );
};

export default AlertCard;
