import React from "react";
import { useSelector } from "react-redux";
import NotificationManagementList from "./NotificationManagementList";
import LightDarkText from "../../Common/LightDarkText";

const NotificationManagement = ({ values, setValues, role }) => {
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );

  const { mode } = useSelector((state) => state.genearlReducer);

  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <div className={`mt-5 flex-1 d-flex flex-column justify-content-between`}>
      <NotificationManagementList
        role={role}
        values={values}
        list={list(selectedOrganisation)}
        onChange={handleChange}
      />
      <LightDarkText
        className="f12 italic"
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        Les notifications sont essentielles pour vous tenir informé(e) de toutes
        les actions des utilisateurs sur la plateforme. Cependant, veuillez
        noter que les notifications affichées dans le bandeau supérieur de la
        plateforme ne seront pas désactivées.
      </LightDarkText>
    </div>
  );
};

export default NotificationManagement;

const list = (selectedOrg) => [
  {
    label: "Création d'un ticket",
    name: "ticketCreation",
    roles: "all",
    isVisible: true,
  },
  {
    label: "Résolution d'un ticket",
    name: "ticketResolved",
    roles: "all",
    isVisible: true,
  },
  {
    label: "Archivage d'un ticket",
    name: "ticketArchive",
    roles: "all",
    isVisible: true,
  },
  {
    label: "Nouveau message",
    name: "message",
    roles: "all",
    isVisible: true,
  },
  {
    label: "Interventions",
    name: "intervention",
    roles: "all",
    isVisible: true,
  },
  {
    label: "Partage de dossiers ou de fichiers",
    name: "cloudShare",
    roles: "all",
    isVisible: true,
  },
  {
    label: "Importation d'un fichier",
    name: "fileImport",
    roles: "all",
    isVisible: true,
  },
  {
    label: "Ajout d'un collaborateur",
    name: "collaboratorAdded",
    roles: "all",
    isVisible: true,
  },
  {
    label: "Mention de mon profil",
    name: "profileMention",
    roles: "all",
    isVisible: true,
  },
  {
    label: "Dépassement de mon forfait",
    name: "packageExceeded",
    roles: [3, 1],
    isVisible: true,
  },
  {
    label: "Gestion des prospects",
    name: "prospectActions",
    roles: "all",
    isVisible: selectedOrg.attributes.withProspects,
  },
  {
    label: "Gestion des devis",
    name: "devisActions",
    roles: "all",
    isVisible: selectedOrg.attributes.withProspects,
  },
  {
    label: "Gestion des commandes",
    name: "commandeActions",
    roles: "all",
    isVisible: selectedOrg.attributes.withProspects,
  },
  {
    label: "Nouveaux prospects",
    name: "newProspect",
    roles: [1],
    isVisible: selectedOrg.attributes.withProspects,
  },
  {
    label: "Nouveaux devis",
    name: "newDevis",
    roles: [1],
    isVisible: selectedOrg.attributes.withProspects,
  },
  {
    label: "Nouvelles commandes",
    name: "newCommande",
    roles: [1],
    isVisible: selectedOrg.attributes.withProspects,
  },
];
