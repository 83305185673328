import { gql } from "@apollo/client";

export const READ_NOTIFICATION = gql`
  mutation ($id: ID!) {
    updateNotification(id: $id, data: { read: true }) {
      data {
        id
      }
    }
  }
`;

export const UNREAD_NOTIFICATION = gql`
  mutation ($id: ID!) {
    updateNotification(id: $id, data: { read: false }) {
      data {
        id
      }
    }
  }
`;
