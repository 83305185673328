import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSocietyNote,
  getSocietyNotes,
  updateSocietyNote,
} from "../../../../REDUX/actions/societe";
import LightDarkContainer from "../../../Common/LightDarkContainer";
import LightDarkInput from "../../../Common/LightDarkInput";
import ColoredButton from "../../../Common/Buttons/ColoredButton";
import SocietyNoteItem from "./SocietyNoteItem";
import TextAreaInput from "../../../Simulateur/TextAreaInput";
import LighDarkMultiChoice from "../../../Common/LighDarkMultiChoice";
import LightDarkText from "../../../Common/LightDarkText";
import { CloseIcon } from "../../../Common/LightDarkSvg";

const SocietyNotes = () => {
  const dispatch = useDispatch();
  const { societyNotes, societe } = useSelector(
    (state) => state.societesReducer
  );
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const { mode } = useSelector((state) => state.genearlReducer);

  const visibleToData = [
    {
      label: `Opérateurs de ${selectedOrganisation.attributes.name}`,
      value: 2,
      field: "showToOperators",
    },
    {
      label: `Collaborateurs de ${societe.attributes.name}`,
      value: 4,
      field: "showToCollaborators",
    },
    { label: "Invités", value: 5, field: "showToLectors" },
  ];

  const [totalInputs, settotalInputs] = useState({ search: "" });
  const [editedNoteId, setEditedNoteId] = useState(null);
  const [newNote, setNewNote] = useState({
    title: "",
    content: "",
    visibleTo: [],
  });
  const initNote = () => {
    setNewNote({
      title: "",
      content: "",
      visibleTo: [],
    });
    setEditedNoteId(null);
  };
  const [showNewNoteForm, setShowNewNoteForm] = useState(false);

  useEffect(() => {
    dispatch(getSocietyNotes(societe.id));
  }, [societe?.id]);

  return (
    <LightDarkContainer
      colorLight="white"
      className="h100 p-3 d-flex flex-column justify-content-between"
    >
      <LightDarkInput
        totalInputs={totalInputs}
        champs={"search"}
        setValue={settotalInputs}
        placeholder={"Rechercher une note"}
        bgWhite
        customClass="border border-1 text-blue-grey"
      />
      <div className="flex-1 scrollable py-4 gap-4 d-flex flex-column">
        {societyNotes.map((note) => (
          <SocietyNoteItem
            key={note.id}
            visibleToData={visibleToData}
            note={note}
            setShowNewNoteForm={setShowNewNoteForm}
            setNewNote={setNewNote}
            setEditedNoteId={setEditedNoteId}
          />
        ))}
      </div>
      <div className="border-1 border-top pt-3">
        {showNewNoteForm && (
          <div className="d-flex flex-column gap-3 mb-3">
            <div className="alignH justify-content-between">
              <div>
                {editedNoteId ? (
                  <LightDarkText
                    className={"text-start bold f14"}
                    color={mode === "light" ? "text-blue-grey" : "text-white"}
                  >
                    Modifier {newNote.title}
                  </LightDarkText>
                ) : null}
              </div>
              <CloseIcon
                clickCallback={() => {
                  setShowNewNoteForm(false);
                  initNote();
                }}
              />
            </div>
            <div>
              <LightDarkText
                className={"text-start bold custom-ticket-font-size mb-2"}
                color={mode === "light" ? "text-blue-grey" : "text-white"}
              >
                Qui peut voir cette note ?
              </LightDarkText>
              <div className="text-start" style={{ marginTop: "-5px" }}>
                <LighDarkMultiChoice
                  totalInputs={newNote}
                  setValue={setNewNote}
                  data={visibleToData}
                  champs={"visibleTo"}
                />
              </div>
            </div>
            <LightDarkInput
              label={"Titre"}
              totalInputs={newNote}
              champs={"title"}
              setValue={setNewNote}
              placeholder={"Titre"}
            />
            <TextAreaInput
              label="Description"
              totalInput={newNote}
              champs={"content"}
              setValue={setNewNote}
              placeholder={""}
            />
          </div>
        )}

        <ColoredButton
          height="40"
          textButton={editedNoteId ? "Enregistrer" : "Ajouter une note interne"}
          color="light-blue"
          disabled={(!newNote.title || !newNote.content) && showNewNoteForm}
          callback={() =>
            showNewNoteForm
              ? editedNoteId
                ? dispatch(updateSocietyNote(editedNoteId, newNote)).then(
                    () => {
                      setShowNewNoteForm(false);
                      initNote();
                    }
                  )
                : dispatch(createSocietyNote(newNote)).then(() => {
                    setShowNewNoteForm(false);
                    initNote();
                  })
              : setShowNewNoteForm(!showNewNoteForm)
          }
        />
      </div>
    </LightDarkContainer>
  );
};

export default SocietyNotes;
