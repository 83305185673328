import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import OpenRoutes from "./OpenRoutes";
import AuthenticatedRoutes from "./AuthenticatedRoutes";

const Routes = () => {
  const { pathname } = useLocation();

  const { userLoading, user } = useSelector((state) => state.userReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  if (userLoading ? null : !user && !userLoading) {
    return <OpenRoutes />;
  } else {
    return <AuthenticatedRoutes />;
  }
};

export default Routes;
