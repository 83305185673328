import React from "react";
import LightDarkText from "../../../../Common/LightDarkText";
import { useDispatch, useSelector } from "react-redux";
import { handleFirstLastName } from "../../../../../Utils/utils";
import moment from "moment";
import { openSideBar } from "../../../../../REDUX/actions/sideBar";
import InterviewResponses from "./InterviewResponses";

const InterviewMessage = ({ message }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);

  const { mode } = useSelector((state) => state.genearlReducer);
  const formattedDate = (date) => {
    return moment(date).format("DD/MM/YYYY [à] HH:mm");
  };

  return (
    <div
      onClick={() =>
        isSupervisor || +user.id === +message.attributes.sender.data.id
          ? dispatch(
              openSideBar({
                content: (
                  <InterviewResponses
                    interviewModelId={
                      message.attributes.interview.data.attributes.model.data.id
                    }
                    interviewUserId={message.attributes.sender.data.id}
                  />
                ),
                title: `Réponses : ${message.attributes.interview.data.attributes.model.data.attributes.name}`,
                type: "w30",
                isToggle: handleFirstLastName(message.attributes.sender.data),
              })
            )
          : {}
      }
      className={`radius05 m-auto d-flex my-4 gap-4 align-items-center p-2 interviewSmallMessage pointer`}
    >
      <LightDarkText
        className="custom-ticket-font-size bold text-start gap-1 flex-1 text-center"
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        {handleFirstLastName(message.attributes.sender.data)} a répondu au
        sondage
        <span className="xbold">
          {" "}
          {message.attributes.interview?.data?.attributes?.model?.data
            ?.attributes?.name || "Sondage Supprimé"}{" "}
        </span>
        le {formattedDate(message.attributes.createdAt)}
      </LightDarkText>
    </div>
  );
};

export default InterviewMessage;
