import React from "react";
import { useSelector } from "react-redux";
import "./CustomTooltip.css";

const CustomTooltip = ({ children, right = false }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return <span className={`customtooltip ${mode === "light" ? "customtooltip-light" : "customtooltip-dark"} tooltiptext ${right ? "tooltiptextRight" : "tooltiptextLeft"} f13`}>{children}</span>;
};

export default CustomTooltip;
