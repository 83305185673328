import React, { Fragment, useEffect, useState } from "react";
import LightDarkInput from "../../../Common/LightDarkInput";
import Select from "../../Select";
import AnswerItem from "./AnswerItem";
import NumberRange from "./NumberRange";
import ColonneList from "./ColonneList";
import { generateNewFormData } from "./NewFormData";
import Switch from "./Switch";
import TextAreaInput from "../../TextAreaInput";
import { useDispatch, useSelector } from "react-redux";
import ColoredButton from "../../../Common/Buttons/ColoredButton";
import {
  createNewQuestion,
  updateQuestion,
} from "../../../../REDUX/actions/formQuestion";
import FileUploader from "../../FileUploader";

const ComponentMap = {
  select: Select,
  text: LightDarkInput,
  number: LightDarkInput,
  colonne: ColonneList,
};
const ConfigurationForm = ({ question }) => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.formCategoriesReducer);

  const [disabled, setDisabled] = useState(true);
  const [formData, setFormData] = useState(
    question
      ? {
          type: ["title", "subtitle", "description", "image"].includes(
            question?.attributes?.input_type
          )
            ? question?.attributes?.input_type
            : "question",
          input_type: ["title", "subtitle", "description", "image"].includes(
            question?.attributes?.input_type
          )
            ? null
            : question?.attributes?.input_type,
          name: question?.attributes?.name,
          category: question?.attributes?.category?.data?.id,
          col: question?.attributes?.col,
          legend: question?.attributes?.legend,
          position: question?.attributes?.position,
          is_required: question?.attributes?.is_required,
          label_class: question?.attributes?.label_class,
          link: question?.attributes?.link,
          file: null,
        }
      : {
          is_required: true,
        }
  );

  const [answer, setAnswer] = useState({});
  const newFormData = generateNewFormData(categories);
  useEffect(() => {
    if (!question) {
      setFormData({
        type: formData?.type,
        category: formData?.category,
        col: formData?.col,
        is_required: formData?.is_required,
      });
      setAnswer({});
    }
  }, [formData?.type]);

  useEffect(() => {
    const isRequiredFieldMissing = newFormData.some(
      ({ field, required }) => required && !formData[field]
    );

    if (isRequiredFieldMissing) {
      setDisabled(true);
      return;
    }

    const hasRequiredBodyFormData = newFormData.some(
      ({ data, field, required }) =>
        required &&
        formData[field] &&
        data
          ?.find(({ value }) => value === formData.type)
          ?.bodyFormData.some(
            ({ field, required }) => required && !formData[field]
          )
    );

    if (hasRequiredBodyFormData) {
      setDisabled(true);
      return;
    }

    const isAnswerCountInsufficient =
      (formData.type === "question" &&
        ["select", "radio", "checkbox", "multi_select"].includes(
          formData.input_type
        ) &&
        Object.keys(answer).length < 2) ||
      (formData.type === "question" &&
        ["number_range"].includes(formData.input_type) &&
        Object.keys(answer).length < 2);

    setDisabled(isAnswerCountInsufficient);
  }, [formData, answer]);

  const handleSubmit = () => {
    if (question) {
      dispatch(updateQuestion(question, formData, answer));
    } else {
      dispatch(
        createNewQuestion(
          {
            ...formData,
          },
          answer
        )
      );
    }
  };
  useEffect(() => {
    if (question) {
      const answers = question?.attributes?.answers?.data;
      const transformedData = answers.reduce((result, item, index) => {
        result[`answer-${index}`] = item.attributes?.answer;
        return result;
      }, {});
      setAnswer(transformedData);
    }
  }, []);
  return (
    <div
      className="row py-4"
      style={{
        overflowY: "scroll",
        width: "700px",
        maxHeight: "70vh",
      }}
    >
      {newFormData.map(
        ({ id, label, type, col, data, field, ...rest }, index) => {
          const Component = ComponentMap[type];

          if (!Component) return null;
          return (
            <Fragment key={id}>
              <div className={`col-12 col-lg-${col} mt-3 `}>
                <Component
                  label={label}
                  data={data}
                  setValue={setFormData}
                  champs={field}
                  totalInputs={formData}
                  totalInput={formData}
                  value={formData[field]}
                  bgWhite={false}
                  type={type}
                  {...rest}
                />
              </div>

              {data?.map(({ id, value, bodyFormData }) => {
                if (
                  value === formData["type"] &&
                  bodyFormData &&
                  bodyFormData.length > 0
                ) {
                  return bodyFormData?.map((el) => (
                    <div
                      className={`col-12 col-lg-${el.col} mt-2 mx-0`}
                      key={el.id}
                    >
                      {el.type === "select" && (
                        <Select
                          key={el.id}
                          data={el.data}
                          icon={el.icon}
                          placeholder={el.placeholder}
                          label={el.label}
                          bgWhite={false}
                          searchable={false}
                          setValue={setFormData}
                          totalInput={formData}
                          champs={el.field}
                          required={el.required}
                          defaultValue={el.defaultValue}
                        />
                      )}
                      {["text", "number"].includes(el.type) && (
                        <LightDarkInput
                          key={el.id}
                          label={el.label}
                          placeholder={el.label}
                          type={el.type}
                          setValue={setFormData}
                          champs={el.field}
                          totalInputs={formData}
                          required={el.required}
                        />
                      )}
                      {el.type === "textarea" && (
                        <div className="ms-2">
                          <TextAreaInput
                            key={el.id}
                            label={el?.label}
                            bgwhite={false}
                            totalInput={formData}
                            setValue={setFormData}
                            required={el.required}
                            champs={el.field}
                          />
                        </div>
                      )}
                      {el.type === "file" && (
                        <div className="ms-2">
                          <FileUploader
                            id={el.id}
                            key={el.id}
                            label={el.label}
                            champs={el.field}
                            multiple={false}
                            required={el.required}
                            totalInputs={formData}
                            setValue={setFormData}
                          />
                        </div>
                      )}
                    </div>
                  ));
                }

                return null;
              })}

              {index === 1 &&
                formData["type"] === "question" &&
                ["select", "radio", "checkbox", "multi_select"].includes(
                  formData["input_type"]
                ) && <AnswerItem totalInput={answer} setValue={setAnswer} />}
              {index === 1 &&
                formData["type"] === "question" &&
                ["number_range"].includes(formData["input_type"]) && (
                  <NumberRange setValue={setAnswer} totalInput={answer} />
                )}
            </Fragment>
          );
        }
      )}

      <div className="d-flex justify-content-between align-items-center pt-3">
        <Switch
          label={"Champs obligatoire"}
          champs={"is_required"}
          totalInput={formData}
          setValue={setFormData}
        />
        <div>
          <ColoredButton
            color="light-blue"
            textButton={"Ajouter"}
            callback={handleSubmit}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigurationForm;
