import { Modal } from "react-bootstrap";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ConfirmationModal.css";
import { closeConfirmationModal } from "../../../REDUX/actions/confirmationModal";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import LightDarkText from "../../Common/LightDarkText";

const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const {
    open,
    message,
    message2,
    cancelButton,
    confirmButtonText,
    type,
    callBack,
    cancelCallback,
    icon,
  } = useSelector((state) => state.confirmationModalReducer);

  const handleConfirm = () => {
    callBack();
    dispatch(closeConfirmationModal());
  };

  const handleClose = () => {
    dispatch(closeConfirmationModal());

    // if (cancelCallback) {
    //   cancelCallback();
    // }
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      size={"md"}
      centered
      className={`${
        mode === "light" ? "lightconfirmationModal" : "darkconfirmationModal"
      } `}
    >
      <Modal.Body className="text-center my-4">
        <LightDarkText className="custom-title-font-size bold">
          {message}
        </LightDarkText>
        <LightDarkText
          className="custom-ticket-font-size mt-3"
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          {message2}
        </LightDarkText>
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <div className="row container-fluid justify-content-center mb-3">
          <div className="col-3 ">
            <ColoredButton
              textButton={"Annuler"}
              color="blue-grey"
              outlined
              callback={handleClose}
            />
          </div>
          <div className="col-7">
            <ColoredButton
              textButton={confirmButtonText}
              color={"#FF2C2C"}
              background="#FF2C2C26"
              icon="x-square"
              square
              callback={handleConfirm}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
