import { gql } from "@apollo/client";

import { USER_ATTRIBUTES } from "../Attributes/user";
import { USER_ORG_ATTRIBUTES } from "../Attributes/UserOrg";

export const GET_USER_BY_ID = (id, orgId) => gql`
  query {
    usersPermissionsUsers(filters: { id: { eq: ${id} } }) {
      data {
        id
        attributes {
          user_org_permissions (
            filters: {
              organisation: { id: { eq: ${orgId} } }  
            }
          ) {
            data {
              id
              attributes {
                ${USER_ORG_ATTRIBUTES}
              }
            }
          }
          ${USER_ATTRIBUTES}
        }
      }
    }
  }
`;
