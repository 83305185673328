// canAcceptInterventions
// canRejectInterventions
// canHoldForValidationInterventions
// canRequestRevisionInterventions
// canValidateInterventions

export const USER_ORG_ATTRIBUTES = `
role
name
archived
canCreateTickets
ticketAuto
canCreateInterventions
canArchiveTickets
canResolveTickets
canCreateCollabs
canSeeWhoOnline
canCreateInvoices
canSendMessages
canSeeInvoices
canSeeCollabs
showHistory
canCreateProspet
canCreateDevis
canCreateCommande
canCreateDiffusion
canCreateAlerts
canCreateOperators
canSeeOperators
canCreateSociety
canAcceptInterventions
canRejectInterventions
canHoldForValidationInterventions
canRequestRevisionInterventions
canValidateInterventions
canModifySupervisorPermissions
canModifyAdminPermissions
notification_setting {
    data {
        id
        attributes {
            ticketCreation
            ticketResolved
            ticketArchive
            message
            intervention
            cloudShare
            fileImport
            collaboratorAdded
            profileMention
            packageExceeded
            prospectActions
            devisActions
            commandeActions
            newProspect
            newDevis
            newCommande
        }
    }
}
SocietiesUnderResponsability (pagination: { page: 1, pageSize: 100 } ) {
    data {
        id
        attributes {
            name
        }
    }
}
workIn (pagination: { page: 1, pageSize: 100 } ) {
    data {
        id
        attributes {
            name
        }
    }
}
poste {
    data {
        id
        attributes {
            name
        }
    }
}
departements (pagination: { page: 1, pageSize: 100 } ) {
    data {
        id
        attributes {
            name
            code
        }
    }
}
devices (pagination: { page: 1, pageSize: 100 } ) {
    data {
        id
        attributes {
            name
        }
    }
}
prestations {
    data {
        id
        attributes {
            name
        }
    }
}
preferredLanguage {
    data {
      id
      attributes {
        name
        code
      }
    }
  }
`;

export const USER_ORG_ATTRIBUTES_LIGHTER = `
role
name
poste {
    data {
        id
        attributes {
            name
        }
    }
}
organisation {
    data {
        id
        attributes {
            name
        }
    }
}


`;
