import React from "react";
import { useSelector } from "react-redux";

const NotificationTicketTitle = ({ notification }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {notification?.ticket?.data?.id && (
        <>
          <span className="text-light-blue">
            {" "}
            #{notification?.ticket?.data?.id}
          </span>
          <span
            className={`xbold ${
              mode === "light" ? "text-dark-blue" : "text-light-blue-grey"
            } `}
          >
            {` ${notification?.ticket?.data?.attributes?.name} `}
          </span>
        </>
      )}
    </>
  );
};

export default NotificationTicketTitle;
