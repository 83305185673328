import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "../../../Common/LightDarkSvg";
import LightDarkText from "../../../Common/LightDarkText";
import { openModal } from "../../../../REDUX/actions/modal";
import EventModalInfo from "../../../Layout/FunctionModal/EventModalInfo";
import { handleError } from "../../../../REDUX/actions/toastMessage";
import { handleFirstLastName } from "../../../../Utils/utils";
import moment from "moment";

const EventMessage = ({ event, updated, messageId }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const eventClick = () => {
    if (event) {
      dispatch(
        openModal({
          type: "small",
          content: <EventModalInfo event={event} />,
        })
      );
    } else {
      dispatch(
        handleError("L'évènement a été supprimé ou dissocié de ce ticket.")
      );
    }
  };
  return (
    <LightDarkText
      className={`radius05 t02 mt-4 px-4 py-2 custom-ticket-font-size bold  gap-2 pointer clickable-text ${
        mode === "light" ? "bg-light-mode" : "bg-dark-message"
      }`}
      onclickCallback={() => eventClick(event)}
    >
      <Calendar sideBar />
      {`${event?.attributes?.type} le `}

      <span className="bolder" id={`eventMessageId-${messageId}`}>
        {`${moment(event?.attributes?.startDate).format(
          "DD.MM.YYYY"
        )} à ${event?.attributes?.startTime?.substring(0, 5)} : `}
      </span>
      <span className="bolder">{event?.attributes?.name}</span>

      {` a été associé à ce ticket par ${handleFirstLastName(
        event?.attributes?.creator?.data
      )}`}

      <span className="position-absolute badge-event f10 text-dark-grey">
        {updated && "modifié"}
      </span>
    </LightDarkText>
  );
};

export default EventMessage;
