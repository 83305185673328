import React from "react";
import { useSelector } from "react-redux";

const LightDarkPlaceHolder = ({ customClass, width, height }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div
      style={{ width, height }}
      className={`t-02 ${customClass} radius05 ${
        mode === "light" ? "lightPlaceHolder" : "darkPlaceHolder"
      } `}
    />
  );
};

export default LightDarkPlaceHolder;
