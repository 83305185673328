import React from "react";
import { useSelector } from "react-redux";
import SwitchItem from "../../Layout/Notification/SwitchItem";
import LightDarkText from "../../Common/LightDarkText";

const UserPermissionGroup = ({ list, disabled, setValues, values }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  return (
    <div className="col-12">
      <LightDarkText
        className="xbold f16"
        color={mode === "light" ? "text-light-blue" : "text-white"}
      >
        {list.name}
      </LightDarkText>
      <div className="row mt-2">
        {list.permissions.map((item, index) => (
          <div className="col-6 mb-3 text-start" key={index}>
            <SwitchItem
              disabled={disabled}
              {...item}
              value={!!values[item?.name]}
              onChange={handleChange}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserPermissionGroup;
