import {
  GET_QUESTIONS_FAILED,
  GET_QUESTIONS_SUCCESS,
  LOADING,
} from "../ActionsType/formQuestionTypes";

const initialState = {
  loading: false,
  questions: [],
  error: null,
};

const formQuestionsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING:
      return { ...state, loading: true };
    case GET_QUESTIONS_SUCCESS:
      const filtredData = payload?.filter(
        (item) =>
          item.attributes?.input_type !== "title" &&
          item.attributes?.input_type !== "subtitle"
      );
      return {
        ...state,
        questions: filtredData,
        loading: false,
      };
    case GET_QUESTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export default formQuestionsReducer;
