import React, { useEffect, useState } from "react";
import ReactUsersList from "./ReactUsersList";
import { useDispatch, useSelector } from "react-redux";
import {
  addReact,
  removeReact,
} from "../../../../../../../REDUX/actions/messages";

const ReactElement = ({ emo, messageId }) => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const [MyReact, setMyReact] = useState(
    emo.users.filter((el) => el.id?.toString() === user?.id?.toString())
  );
  useEffect(() => {
    setMyReact(
      emo.users.filter((el) => el.id?.toString() === user.id?.toString())
    );
  }, [emo]);

  return (
    <div
      onClick={() => {
        if (MyReact.length) {
          dispatch(removeReact(MyReact[0].emojiId, messageId));
        } else {
          dispatch(
            addReact({
              name: `${messageId} - ${user.attributes.firstName} ${user.attributes.lastName}`,
              url: emo.url,
              message: messageId,
              user: user.id,
              emojiKey: emo.emojiKey,
            })
          );
        }
      }}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      className={`reactElt alignC gap-2 border t radius05 px-2 pointer border ${
        MyReact.length
          ? "border-blue reactEltMeReacted"
          : "border-light-tex bg-white"
      }`}
    >
      {expanded ? <ReactUsersList emo={emo} /> : null}
      <img src={emo.url} className="m-0 emojiListImage" />
      <div className="f14">{emo.number}</div>
    </div>
  );
};

export default ReactElement;
