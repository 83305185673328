const initialState = {
  plans: [],
  currentPlan: undefined,
};

const planReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_CURRENT_PLAN":
      return {
        ...state,
        currentPlan: payload,
      };
    case "UPDATE_CURRENT_PLAN":
      return {
        ...state,
        currentPlan: payload,
        plans: state.plans.map((plan) =>
          +plan?.id === +payload?.id ? payload : plan
        ),
      };
    case "GET_PLANS":
      return { ...state, plans: payload };

    default:
      return state;
  }
};

export default planReducer;
