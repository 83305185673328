import { gql } from "@apollo/client";
import { FORM_QUESTION_ATTRIBUTES } from "../Attributes/formQuestion";

export const GET_QUESTIONS = gql`
  query {
    formQuestions ( 
      pagination: { page: 1, pageSize: 10000 }
      filters: {
        category: {  id:{not:null}}
      }
      ){
      data {
        id
        attributes {
          ${FORM_QUESTION_ATTRIBUTES}
        }
      }
    }
  }
`;

export const GET_QUESTION_BY_ID = (id) => gql`
  query {
    formQuestions(filters: { id: { eq: ${id} } }) {
      data {
        id
        attributes {
          ${FORM_QUESTION_ATTRIBUTES}
        }
      }
    }
  }
`;
