import { userAttributes } from "../general/userFields";
import { ATTRIBUTES_PROSPECT_FIELD } from "./Prospect_field";

export const ATTRIBUTES_PROSPECT = `
origin
note
status
createdAt
archived
document {
    data {
        id
        attributes {
            url
            name
            size
            mime
        }
    }
}
user {
    data {
        id
        attributes {
            ${userAttributes}
        }
    }
}
acteurs {
    data {
        id
        attributes {
            ${userAttributes}
        }
    }
}
prospect_fields  ( pagination: { page: 1, pageSize: 10000 }){
    data{
        id
        attributes{
            ${ATTRIBUTES_PROSPECT_FIELD}
        }
    }
}
`;
