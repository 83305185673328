import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../REDUX/actions/modal";
import LightDarkContainer from "../../Common/LightDarkContainer";
import { CloseIcon } from "../../Common/LightDarkSvg";
import LightDarkText from "../../Common/LightDarkText";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import LightDarkInput from "../../Common/LightDarkInput";

const RenameModal = ({ id, name, callBack, isFile }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const [newName, setnewName] = useState({ name: name });

  return (
    <LightDarkContainer
      className="p-4 radius05 directionC"
      colorLight="white"
      colorDark="dark-mode"
    >
      <div
        className="d-flex justify-content-end mb-2"
        onClick={() => dispatch(closeModal())}
      >
        <CloseIcon />
      </div>
      <LightDarkText className="text-center custom-title-font-size bold mb-4">
        {isFile ? "Renommer le fichier" : "Renommer le dossier"}
      </LightDarkText>

      <div onSubmit={(e) => e.preventDefault()}>
        <LightDarkInput
          label={"Nom de dossier"}
          placeholder="Nouveau nom"
          setValue={setnewName}
          champs="name"
          value={newName.name}
        />
      </div>
      <div className="col-6 align-self-center mb-3 mt-5">
        <ColoredButton
          textButton="Enregister les modifications"
          disabled={!newName.name}
          color="light-blue"
          callback={() => dispatch(callBack(id, newName.name))}
        />
      </div>
    </LightDarkContainer>
  );
};

export default RenameModal;
