import React, { useEffect, useState } from "react";
import { getInterviewUsers } from "../../../../../REDUX/actions/interviews";
import { useDispatch, useSelector } from "react-redux";
import InterviewPlaceHolder from "./InterviewPlaceHolder";
import LightDarkContainer from "../../../../Common/LightDarkContainer";
import InterviewResponsesList from "./InterviewResponsesList";
import MultiTabsMenu from "../../../../Common/MultiTabsMenu";

const InterviewResponses = ({ interviewModelId, interviewUserId }) => {
  const dispatch = useDispatch();
  const { userResponded } = useSelector((state) => state.interviewsReducer);
  const { mode } = useSelector((state) => state.genearlReducer);
  const [isToggle, setIsToggle] = useState(interviewUserId);
  useEffect(() => {
    dispatch(getInterviewUsers(interviewModelId, interviewUserId));
  }, [interviewModelId]);

  return !userResponded.length ? (
    <InterviewPlaceHolder />
  ) : (
    <div
      className={`${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      } radius05 scrollable h100 d-flex flex-column justify-content-between`}
    >
      <div
        className={`d-flex justify-content-between align-items-center tabRadius t-02 bg-white`}
        style={{ height: "50px" }}
      >
        <LightDarkContainer
          colorLight="white"
          colorDark="dark-grey-bg"
          className="d-flex  justify-content-start px-4 w-100 gap-4 align-items-center menuHeaderTabs scrollable"
        >
          <MultiTabsMenu
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            allTabs={userResponded}
            align="start"
          />
        </LightDarkContainer>
      </div>
      <InterviewResponsesList
        interviewUserId={isToggle}
        interviewModelId={interviewModelId}
      />
    </div>
  );
};

export default InterviewResponses;
