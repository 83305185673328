import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import MessageDate from "./MessageDate.jsx";
import MessageCardContainer from "./MessageCardContainer";
import EventMessage from "../../../MessagesTypes/EventMessage.jsx";
import LightDarkText from "../../../../../Common/LightDarkText.jsx";
import InterventionMessage from "../../../MessagesTypes/InterventionMessages/InterventionMessage.jsx";
import InterventionActionMessage from "../../../MessagesTypes/InterventionMessages/InterventionActionMessage.jsx";

import { showTimeMessage } from "../../../../ticketUtils.js";
import { showConditionalMessage } from "../../../messageUtils.js";

import "./MessageCard.css";
import AddParticipantMessage from "../../../MessagesTypes/AddParticipantMessage.jsx";
import MainInterviewMessage from "../../../MessagesTypes/InterviewMessages/MainInterviewMessage.jsx";
import InterviewMessage from "../../../MessagesTypes/InterviewMessages/InterviewMessage.jsx";
import MainProspectMessage from "../../../MessagesTypes/MainProspectMessage.jsx";

const MessageCard = ({
  responseTo,
  setIsReplying,
  setInternalResponseTo,
  handleFocusToRespond,
  message,
  ticketId,
  measureRef,
  isArchived,
  setResponseTo,
}) => {
  const { user } = useSelector((state) => state.userReducer);
  const { mode } = useSelector((state) => state.genearlReducer);

  const [meSending, setMeSending] = useState();
  const [showOriginal, setshowOriginal] = useState(false);
  const [messageContenttoShow, setmessageContenttoShow] = useState(
    showConditionalMessage(message?.attributes, user, showOriginal || meSending)
  );

  useEffect(() => {
    setmessageContenttoShow(
      showConditionalMessage(
        message?.attributes,
        user,
        showOriginal || meSending
      )
    );
  }, [message, showOriginal, meSending]);

  return message.attributes.type === 11 ? (
    <div className="w-100">
      <MainProspectMessage message={message} />
    </div>
  ) : message.attributes.type === 10 ? (
    <div className="w-100">
      <InterviewMessage message={message} />
    </div>
  ) : message.attributes.type === 9 ? (
    <div className="w-100">
      <MainInterviewMessage message={message} />
    </div>
  ) : message.attributes.type === 8 ? (
    <AddParticipantMessage
      sender={message?.attributes?.sender?.data}
      message={message?.attributes?.content}
      createdAt={message?.attributes?.createdAt}
    />
  ) : message.attributes.type === 7 ? (
    <EventMessage
      updated={message?.attributes?.updated}
      event={message.attributes.event.data}
      messageId={message.id}
    />
  ) : message.attributes.content === "" &&
    !message?.attributes?.fichiers?.data?.length &&
    !message.attributes.relatedIntervention?.data?.id ? null : message
      .attributes.relatedIntervention?.data?.id ? (
    message.attributes.type === 1 ? (
      <div
        className={`${
          message.attributes.relatedIntervention?.data?.attributes?.statut !== 1
            ? "d-flex gap-2"
            : ""
        }`}
        ref={measureRef}
      >
        <div className="w-100">
          <InterventionMessage message={message} />
          <div className="text-end">
            <LightDarkText
              color={mode === "light" ? "text-light-text" : "text-white"}
              className={" f10 bold light-message"}
            >
              {showTimeMessage(message)}
            </LightDarkText>
          </div>
        </div>
      </div>
    ) : (
      <InterventionActionMessage message={message} measureRef={measureRef} />
    )
  ) : (
    <div className="directionC" ref={measureRef}>
      <MessageDate message={message} />
      <MessageCardContainer
        responseTo={responseTo}
        setIsReplying={setIsReplying}
        handleFocusToRespond={handleFocusToRespond}
        setResponseTo={setResponseTo}
        setInternalResponseTo={setInternalResponseTo}
        message={message}
        ticketId={ticketId}
        isArchived={isArchived}
        messageContenttoShow={messageContenttoShow}
        showOriginal={showOriginal}
        setshowOriginal={setshowOriginal}
        meSending={meSending}
        setMeSending={setMeSending}
      />
    </div>
  );
};

export default MessageCard;
