import React, { useEffect, useState } from "react";
import LightDarkContainer from "../Common/LightDarkContainer";
import LightDarkText from "../Common/LightDarkText";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { useSelector } from "react-redux";

const DoubleLineChart = ({ color, title, type, stats, color2 }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const [currStats, setCurrStats] = useState();

  const [labels, setLabels] = useState();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    gridLines: {
      display: false,
      drawOnChartArea: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#86909c80",
          font: {
            weight: "regular",
            size: 10,
          },
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: true,
          color: "#86909c20",
          borderColor: "transparent",
        },
      },
    },
  };
  const data = {
    labels: labels?.map((el) => el?.name),
    datasets: [
      {
        label: title,
        data: currStats?.openTickets,
        backgroundColor: color,
        borderColor: color,
      },
      {
        label: title,
        data: currStats?.resolvedTickets,
        backgroundColor: color2,
        borderColor: color2,
      },
    ],
  };

  useEffect(() => {
    if (!stats) return;
    const last12Months = [];
    for (let i = 0; i < 12; i++) {
      const monthIndex = moment().subtract(i, "months").format("MM", "fr");
      const month = moment().subtract(i, "months").format("MMM", "fr");
      const year = moment().subtract(i, "months").format("YYYY", "fr");
      last12Months.push({ name: month, value: monthIndex, year });
    }
    setLabels(last12Months.reverse());
    setCurrStats({
      openTickets: last12Months?.map(({ value, year }) => {
        const stat =
          stats?.openTickets?.find((v) => {
            const currMonth = moment(v.month).format("MM", "FR");
            const currYear = moment(v.month).format("YYYY", "FR");
            return currMonth === value && currYear === year;
          })?.count || 0;

        return stat;
      }),
      resolvedTickets: last12Months?.map(({ value, year }) => {
        const stat =
          stats?.resolvedTickets?.find((v) => {
            const currMonth = moment(v.month).format("MM", "FR");
            const currYear = moment(v.month).format("YYYY", "FR");
            return currMonth === value && currYear === year;
          })?.count || 0;

        return stat;
      }),
    });
  }, [stats]);
  return (
    <LightDarkContainer
      className="px-4 py-3 radius05 h100"
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <div className="alignH justify-content-between mb-1">
        <LightDarkText
          className="text-start bolder custom-font-size"
          lightModeText={mode === "light"}
        >
          {title}
        </LightDarkText>
      </div>
      <div className="alignH justify-content-end mb-3">
        <div className="alignH">
          <div
            className="legendBar mx-1"
            style={{
              backgroundColor: "#FFBC20",
            }}
          />
          <LightDarkText
            className="text-start f10"
            lightModeText={mode === "light"}
          >
            En cours
          </LightDarkText>
        </div>
        <div className="alignH">
          <div
            className="legendBar mx-1"
            style={{
              backgroundColor: "#0CCE91",
            }}
          />
          <LightDarkText
            className="text-start  f10"
            lightModeText={mode === "light"}
          >
            Résolus
          </LightDarkText>
        </div>
      </div>

      <Line
        options={options}
        data={data}
        className="py-2"
        style={{ maxHeight: "180px" }}
      />
    </LightDarkContainer>
  );
};

export default DoubleLineChart;
