import moment from "moment";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ArrowDownIcon,
  ArrowUpIcon,
  FacebookIcon,
  GlobalIcon,
  HandleHistoryIcon,
  InstagramIcon,
  LinkedInIcon,
  TikTokIcon,
  ToggleList,
  TwitterIcon,
} from "../../../Common/LightDarkSvg";
import LightDarkText from "../../../Common/LightDarkText";
import LightDarkInput from "../../../Common/LightDarkInput";
import LighDarkMultiChoice from "../../../Common/LighDarkMultiChoice";
import LightDarkTextEditor from "../../../Common/LightDarkTextEditor";
import ColoredButton from "../../../Common/Buttons/ColoredButton";
import AttachementUploader from "../../../Common/AttachementUploader";
import FileUploader from "../../../Simulateur/FileUploader";

import {
  handleFirstLastName,
  selectFormat,
  urlifyMessage,
} from "../../../../Utils/utils";

import { clearTempUpload } from "../../../../REDUX/actions/files";
import {
  createEvent,
  deleteEvent,
  updateEvent,
} from "../../../../REDUX/actions/calendars";
import { isDefined } from "react-pdf/dist/cjs/shared/utils";
import colors from "./eventColors";
import { closeSideBar } from "../../../../REDUX/actions/sideBar";
import LightDarkContainer from "../../../Common/LightDarkContainer";

const EventForm = ({ isNew, event, fromCalendar }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { mode } = useSelector((state) => state.genearlReducer);

  const {
    canShareTo,
    calendarStatutValues,
    eventRecurrenceValues,
    // eventReminder,
  } = useSelector((state) => state.sharedDataReducer);
  const { tickets } = useSelector((state) => state.ticketsReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);

  const { calendars, selectedCalendars } = useSelector(
    (state) => state.calendarsReducer
  );
  const { temporarySelected } = useSelector((state) => state.filesReducer);

  const [socialMediaDesc, setSocialMediaDesc] = useState({
    facebook: isNew ? false : event.attributes?.facebook,
    instagram: isNew ? false : event.attributes?.instagram,
    linkedIn: isNew ? false : event.attributes?.linkedIn,
    twitter: isNew ? false : event.attributes?.twitter,
    tikTok: isNew ? false : event.attributes?.tikTok,
    global: isNew ? false : event.attributes?.global,
  });

  const [showSocialDetails, setShowSocialDetails] = useState({
    facebook: true,
    instagram: true,
    linkedIn: true,
    twitter: true,
    tikTok: true,
    global: true,
  });
  const [eventColor, setEventColor] = useState(
    isNew ? "#3365FF" : event.attributes?.color
  );
  const [toggleType, setToggleType] = useState(
    isNew || event.attributes?.type === "Rendez-vous" ? true : false
  );
  const [shareInTicket, setShareInTicket] = useState(
    isNew ? false : event?.attributes?.shareInTicket
  );

  const [eventInputs, setEventInputs] = useState(
    isNew
      ? {
          type: "Rendez-vous",
          calendar:
            selectedCalendars?.length === 1
              ? selectedCalendars
              : calendars.length === 1
              ? calendars
              : [],
          name: "",
          startDate: moment(event?.startStr).format("YYYY-MM-DD"),
          endDate: moment(event?.endStr).format("YYYY-MM-DD"),
          startTime: moment(event?.startStr).format("HH:mm"),
          endTime: isDefined(event?.endStr)
            ? moment(event?.endStr).format("HH:mm")
            : moment(event?.endStr).add(1, "hours").format("HH:mm"),
          place: "",
          color: eventColor,
          participants: [],
          validatingUsers: [],
          description: "",
          ticket: [],
          facebook: "",
          facebookFiles: [],
          instagram: "",
          instagramFiles: [],
          linkedIn: "",
          linkedInFiles: [],
          twitter: "",
          twitterFiles: [],
          tikTok: "",
          tiktokFiles: [],
          global: "",
          globalFiles: [],
          statut: [calendarStatutValues[0]],
          recurrence: [eventRecurrenceValues[0]],
          recurrenceEndDate: "",
          // reminder: [],
        }
      : {
          ...event?.attributes,
          calendar: [event.attributes?.calendar.data],
          id: event?.id,
          place: event.attributes?.place ? event?.attributes?.place : "",
          statut: [
            {
              label: calendarStatutValues[event.attributes?.statut - 1]?.label,
              value: event.attributes?.statut,
            },
          ],
          facebookFiles: [],
          participants: event.attributes?.participants.data
            ? event.attributes?.participants.data.map((user) => ({
                label: handleFirstLastName(user),
                value: user?.id,
              }))
            : [],
          oldTicket: event?.attributes?.ticket?.data?.id,
          ticket: event?.attributes?.ticket?.data
            ? [
                {
                  label: event?.attributes?.ticket?.data?.attributes?.name,
                  value: event?.attributes?.ticket?.data?.id,
                },
              ]
            : [],
          validatingUsers: event.attributes?.validatingUsers?.data
            ? event.attributes?.validatingUsers.data.map((user) => ({
                label: handleFirstLastName(user),
                value: user?.id,
              }))
            : [],
          recurrence: eventRecurrenceValues?.filter(
            (el) => el?.value === event?.attributes?.recurrence
          ),
          // reminder: eventReminder?.filter(
          //   (el) => +el?.value === +event?.reminder
          // ),
        }
  );

  const relatedTickets = selectFormat(
    eventInputs?.calendar[0]?.attributes?.societe?.data?.id
      ? tickets.filter(
          (ticket) =>
            +ticket.attributes.societe.data.id ===
            +eventInputs?.calendar[0].attributes.societe.data.id
        )
      : tickets
  );

  const societyUsers = selectFormat(
    isNew
      ? eventInputs?.calendar[0]?.attributes?.sharedUsers?.data
      : event?.attributes?.participants?.data
  ).filter((el) => +el.value !== +user.id);

  useEffect(() => {
    setEventInputs({
      ...eventInputs,
      type: toggleType ? "Rendez-vous" : "Marketing",
      color: eventColor,
    });
  }, [toggleType, eventColor]);

  useEffect(() => {
    if (isNew) {
      setEventInputs({
        ...eventInputs,
        endDate: eventInputs?.startDate,
      });
    }
  }, [eventInputs?.startDate]);

  useEffect(() => {
    if (isNew) {
      setEventInputs({
        ...eventInputs,
        endTime: eventInputs?.startTime,
      });
    }
  }, [eventInputs?.startTime]);

  useEffect(() => {
    if (isNew) {
      setEventInputs({
        ...eventInputs,
        participants:
          eventInputs?.calendar[0]?.attributes?.societe?.data ||
          eventInputs?.calendar[0]?.attributes?.sharedUsers?.data
            ? societyUsers
            : [],
      });
    }
  }, [eventInputs?.calendar]);

  const handleSubmitEvent = () => {
    if (isNew) {
      dispatch(
        createEvent(
          {
            ...eventInputs,
            statut:
              eventInputs?.type === "Rendez-vous"
                ? 0
                : eventInputs.statut[0]?.value,
            startTime: eventInputs.startTime + ":00:00",
            description: urlifyMessage(eventInputs?.description),
            place: urlifyMessage(eventInputs?.place),
            facebook: urlifyMessage(eventInputs?.facebook),
            instagram: urlifyMessage(eventInputs?.instagram),
            linkedIn: urlifyMessage(eventInputs?.linkedIn),
            twitter: urlifyMessage(eventInputs?.twitter),
            tikTok: urlifyMessage(eventInputs?.tikTok),
            global: urlifyMessage(eventInputs?.global),
            endTime: eventInputs.endTime + ":00:00",
            recurrenceEndDate: eventInputs.recurrenceEndDate
              ? eventInputs.recurrenceEndDate
              : undefined,
            creator: user.id,
            calendar: {
              ...eventInputs?.calendar[0],
              id:
                eventInputs?.calendar[0]?.value || eventInputs?.calendar[0]?.id,
              name:
                eventInputs?.calendar[0]?.label ||
                eventInputs?.calendar[0]?.name,
            },
            participants: eventInputs.participants.map((user) => user?.value),
            validatingUsers: eventInputs.validatingUsers.map(
              (user) => user?.value
            ),
            ticket: eventInputs.ticket[0]?.value,
            recurrence: eventInputs.recurrence[0]?.value,
            // reminder: eventInputs?.reminder[0]?.value,
          },
          {
            files: temporarySelected,
            facebookFiles: eventInputs?.facebookFiles,
            instagramFiles: eventInputs?.instagramFiles,
            linkedInFiles: eventInputs?.linkedInFiles,
            twitterFiles: eventInputs?.twitterFiles,
            tiktokFiles: eventInputs?.tiktokFiles,
            globalFiles: eventInputs?.globalFiles,
          },
          shareInTicket
        )
      );
      setEventInputs({ ...eventInputs, facebookFiles: [] });
    } else {
      dispatch(
        updateEvent(
          {
            ...eventInputs,
            id: event?.id,
            previousStatut: event?.attributes?.statut,
            statut: eventInputs.statut[0]?.value,
            startTime: moment(eventInputs.startTime, "HH:mm").format(
              "HH:mm:ss.SSS"
            ),
            endTime: moment(eventInputs.endTime, "HH:mm").format(
              "HH:mm:ss.SSS"
            ),
            description: urlifyMessage(eventInputs?.description),
            place: urlifyMessage(eventInputs?.place),
            facebook: urlifyMessage(eventInputs?.facebook),
            facebookFiles: event?.attributes?.facebookFiles,
            instagram: urlifyMessage(eventInputs?.instagram),
            instagramFiles: event?.attributes?.instagramFiles,
            linkedIn: urlifyMessage(eventInputs?.linkedIn),
            linkedInFiles: event?.attributes?.linkedInFiles,
            twitter: urlifyMessage(eventInputs?.twitter),
            twitterFiles: event?.attributes?.twitterFiles,
            tikTok: urlifyMessage(eventInputs?.tikTok),
            tiktokFiles: event?.attributes?.tiktokFiles,
            global: urlifyMessage(eventInputs?.global),
            globalFiles: event?.attributes?.globalFiles,
            recurrenceEndDate: eventInputs.recurrenceEndDate
              ? eventInputs.recurrenceEndDate
              : undefined,
            calendar: event?.attributes?.calendar?.data,
            participants: eventInputs.participants.map((user) => user?.value),
            validatingUsers: eventInputs.validatingUsers.map(
              (user) => user?.value
            ),

            ticket: eventInputs.ticket[0]?.value,
            recurrence: eventInputs.recurrence[0]?.value,
            // reminder: eventInputs.reminder[0]?.value,
          },
          {
            files: temporarySelected,
            facebookFiles: eventInputs?.facebookFiles,
            instagramFiles: eventInputs?.instagramFiles,
            linkedInFiles: eventInputs?.linkedInFiles,
            twitterFiles: eventInputs?.twitterFiles,
            tiktokFiles: eventInputs?.tiktokFiles,
            globalFiles: eventInputs?.globalFiles,
          },
          shareInTicket
        )
      );
    }

    dispatch(clearTempUpload());

    dispatch(closeSideBar());
  };

  return (
    <LightDarkContainer
      className="px-3 pb-4 scrollable sideChatListContainer"
      colorLight="white"
    >
      <div className="d-flex align-items-center gap-4 mb-4">
        <LightDarkText
          className="text-center custom-ticket-font-size bold"
          color={
            toggleType && mode === "light"
              ? "text-dark-blue"
              : !toggleType && mode !== "light"
              ? "text-light-text"
              : mode === "light"
              ? "text-light-text"
              : "text-white"
          }
        >
          Rendez-vous
        </LightDarkText>
        <div onClick={() => setToggleType(!toggleType)}>
          <ToggleList toggleList={toggleType} />
        </div>
        <LightDarkText
          className="text-center custom-ticket-font-size bold"
          color={
            !toggleType && mode === "light"
              ? "text-dark-blue"
              : toggleType && mode !== "light"
              ? "text-light-text"
              : mode === "light"
              ? "text-light-text"
              : "text-white"
          }
        >
          Marketing
        </LightDarkText>
      </div>
      <div className="mt-5">
        <LightDarkInput
          label="Nom de l'événement"
          placeholder="Nom de l'événement"
          type="text"
          totalInputs={eventInputs}
          setValue={setEventInputs}
          champs="name"
          required
          eventType={eventInputs?.type}
        />
      </div>
      {isNew && (
        <div className="mt-4">
          <LighDarkMultiChoice
            label="Agenda"
            placeholder="Séléctionner un agenda"
            data={
              selectedCalendars && selectedCalendars?.length >= 1
                ? selectedCalendars
                    ?.filter(
                      (calendar) =>
                        isSupervisor ||
                        +calendar?.attributes?.owner?.data?.id === +user?.id ||
                        calendar?.attributes?.sharedUsers?.data?.find(
                          (el) => el.id === user?.id
                        ) ||
                        calendar?.attributes?.societe?.data
                    )
                    .map((calendar) => ({
                      value: calendar?.id,
                      label: calendar?.attributes?.name,
                      attributes: {
                        societe: calendar?.attributes?.societe,
                        sharedUsers: calendar?.attributes?.sharedUsers,
                      },
                    }))
                : calendars
                    ?.filter(
                      (calendar) =>
                        calendar?.attributes?.owner?.data?.id === user?.id ||
                        calendar?.attributes?.sharedUsers?.data?.find(
                          (el) => el.id === user?.id
                        ) ||
                        calendar?.attributes?.societe?.data
                    )
                    .map((calendar) => ({
                      value: calendar?.id,
                      label: calendar?.attributes?.name,
                      attributes: {
                        societe: calendar?.attributes?.societe,
                        sharedUsers: calendar?.attributes?.sharedUsers,
                      },
                    }))
            }
            totalInputs={eventInputs}
            setValue={setEventInputs}
            champs="calendar"
            multiple={false}
            required={true}
          />
        </div>
      )}
      {toggleType && (
        <div className="mt-4">
          <LightDarkInput
            label="Lieu"
            placeholder="Lieu de l'événement"
            type="text"
            totalInputs={eventInputs}
            setValue={setEventInputs}
            champs="place"
          />
        </div>
      )}
      <div className="my-4">
        <LightDarkTextEditor
          height={"100"}
          label={"Description"}
          totalInputs={eventInputs}
          setValue={setEventInputs}
          champs="description"
          value={eventInputs}
          autoHeight
          reformulateText
        />
      </div>
      <div className="row my-4">
        <div className="col-6">
          <LightDarkInput
            label="Date de début"
            type="date"
            totalInputs={eventInputs}
            setValue={setEventInputs}
            champs="startDate"
            required
          />
        </div>
        <div className="col-6">
          <LightDarkInput
            label="Date de fin"
            type="date"
            totalInputs={eventInputs}
            setValue={setEventInputs}
            value={eventInputs?.endDate}
            champs="endDate"
            required
          />
        </div>
        {eventInputs?.endDate < eventInputs?.startDate && (
          <span
            className="field-error f11  mt-1 text-red"
            style={{
              minWidth: "max-content",
            }}
          >
            La date de fin doit être supérieure à celle du début de l'événement
          </span>
        )}
      </div>
      <div className="row">
        <div className="col-6">
          <LightDarkInput
            label="Début"
            type="time"
            totalInputs={eventInputs}
            setValue={setEventInputs}
            champs="startTime"
            required
          />
        </div>
        <div className="col-6">
          <LightDarkInput
            label="Fin"
            type="time"
            totalInputs={eventInputs}
            setValue={setEventInputs}
            value={eventInputs?.endTime}
            champs="endTime"
            required
          />
        </div>
        {eventInputs.endDate === eventInputs.startDate &&
          eventInputs?.endTime < eventInputs?.startTime && (
            <span
              className="field-error f11  mt-1 text-red"
              style={{
                minWidth: "max-content",
              }}
            >
              L'heure de fin doit être supérieure à celle du début de
              l'événement
            </span>
          )}
      </div>
      <div className="my-4">
        <LighDarkMultiChoice
          label="Ajouter une récurrence à cet événement"
          placeholder="Ajouter une récurrence à cet événement"
          data={eventRecurrenceValues}
          totalInputs={eventInputs}
          setValue={setEventInputs}
          champs="recurrence"
          multiple={false}
        />
      </div>
      <div className="my-4">
        <LightDarkInput
          label="Fin de la récurrence"
          type="date"
          totalInputs={eventInputs}
          setValue={setEventInputs}
          champs="recurrenceEndDate"
          disabled={eventInputs.recurrence[0]?.value === 1}
        />
      </div>
      {/* <div className="my-4">
        <LighDarkMultiChoice
          label="Ajouter un rappel à cet événement"
          placeholder="Sélectionner une option"
          data={eventReminder}
          totalInputs={eventInputs}
          setValue={setEventInputs}
          champs="reminder"
          multiple={false}
        />
      </div> */}
      <div className="my-4">
        <LighDarkMultiChoice
          label="Partager à un ou plusieurs utilisateurs"
          placeholder="Sélectionner un ou plusieurs utilisateurs"
          data={canShareTo}
          totalInputs={eventInputs}
          setValue={setEventInputs}
          champs="participants"
          multiple={true}
          calendarForm
        />
      </div>
      <div>
        <LighDarkMultiChoice
          label="Lier l'événement à un ticket"
          placeholder="Sélectionner un ticket"
          data={relatedTickets}
          totalInputs={eventInputs}
          setValue={setEventInputs}
          champs="ticket"
          multiple={false}
          calendarForm
        />
      </div>
      <div className="d-flex align-items-center gap-3 mt-4">
        <HandleHistoryIcon
          shareInTicket={shareInTicket}
          clickCallback={() => setShareInTicket(!shareInTicket)}
        />
        <LightDarkText
          className={"text-start bold custom-ticket-font-size"}
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          Partager l'événement dans le ticket
        </LightDarkText>
      </div>
      {!toggleType && (
        <>
          <div className="mt-4">
            <LightDarkText
              className={"text-start bold custom-ticket-font-size mb-4"}
              color={mode === "light" ? "text-blue-grey" : "text-white"}
            >
              Sélectionner des réseaux sociaux
            </LightDarkText>
            <div className="d-flex align-items-center justify-content-between">
              <FacebookIcon
                clickCallback={() => {
                  setSocialMediaDesc({
                    ...socialMediaDesc,
                    facebook: !socialMediaDesc.facebook,
                  });
                  setShowSocialDetails({
                    ...showSocialDetails,
                    facebook: true,
                    instagram: false,
                    linkedIn: false,
                    twitter: false,
                    tikTok: false,
                    global: false,
                  });
                }}
                clicked={socialMediaDesc.facebook}
              />
              <InstagramIcon
                clickCallback={() => {
                  setSocialMediaDesc({
                    ...socialMediaDesc,
                    instagram: !socialMediaDesc.instagram,
                  });
                  setShowSocialDetails({
                    ...showSocialDetails,
                    facebook: false,
                    instagram: true,
                    linkedIn: false,
                    twitter: false,
                    tikTok: false,
                    global: false,
                  });
                }}
                clicked={socialMediaDesc.instagram}
              />
              <LinkedInIcon
                clickCallback={() => {
                  setSocialMediaDesc({
                    ...socialMediaDesc,
                    linkedIn: !socialMediaDesc.linkedIn,
                  });
                  setShowSocialDetails({
                    ...showSocialDetails,
                    facebook: false,
                    instagram: false,
                    linkedIn: true,
                    twitter: false,
                    tikTok: false,
                    global: false,
                  });
                }}
                clicked={socialMediaDesc.linkedIn}
              />
              <TwitterIcon
                clickCallback={() => {
                  setSocialMediaDesc({
                    ...socialMediaDesc,
                    twitter: !socialMediaDesc.twitter,
                  });
                  setShowSocialDetails({
                    ...showSocialDetails,
                    facebook: false,
                    instagram: false,
                    linkedIn: false,
                    twitter: true,
                    tikTok: false,
                    global: false,
                  });
                }}
                clicked={socialMediaDesc.twitter}
              />
              <TikTokIcon
                clickCallback={() => {
                  setSocialMediaDesc({
                    ...socialMediaDesc,
                    tikTok: !socialMediaDesc.tikTok,
                  });
                  setShowSocialDetails({
                    ...showSocialDetails,
                    facebook: false,
                    instagram: false,
                    linkedIn: false,
                    twitter: false,
                    tikTok: true,
                    global: false,
                  });
                }}
                clicked={socialMediaDesc.tikTok}
              />
              <GlobalIcon
                clickCallback={() => {
                  setSocialMediaDesc({
                    ...socialMediaDesc,
                    global: !socialMediaDesc.global,
                  });
                  setShowSocialDetails({
                    ...showSocialDetails,
                    facebook: false,
                    instagram: false,
                    linkedIn: false,
                    twitter: false,
                    tikTok: false,
                    global: true,
                  });
                }}
                clicked={socialMediaDesc.global}
              />
            </div>
            {socialMediaDesc.facebook && (
              <>
                <div
                  className="mb-3 mt-4"
                  style={{
                    borderBottom: `1px solid ${
                      mode === "light" ? "#3540741F" : "#252C45"
                    }`,
                  }}
                />
                <div className="d-flex align-items-center justify-content-between">
                  <LightDarkText
                    className="my-3 f14 text-start bold d-flex align-items-center gap-2"
                    color={
                      mode === "light" ? "text-dark-blue" : "text-light-text"
                    }
                  >
                    <FacebookIcon dark />
                    Facebook
                  </LightDarkText>
                  {showSocialDetails?.facebook ? (
                    <ArrowUpIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          facebook: false,
                        });
                      }}
                    />
                  ) : (
                    <ArrowDownIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          facebook: true,
                          instagram: false,
                          linkedIn: false,
                          twitter: false,
                          tikTok: false,
                          global: false,
                        });
                      }}
                    />
                  )}
                </div>
                {showSocialDetails.facebook && (
                  <>
                    <LightDarkTextEditor
                      height={"100"}
                      label={"Description"}
                      totalInputs={eventInputs}
                      setValue={setEventInputs}
                      champs="facebook"
                      value={eventInputs}
                      autoHeight
                      required
                      reformulateText
                    />
                    <div className="mt-4">
                      <FileUploader
                        label="Fichiers liés à facebook"
                        champs="facebookFiles"
                        totalInputs={eventInputs}
                        setValue={setEventInputs}
                        multiple
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {socialMediaDesc.instagram && (
              <>
                <div
                  className="my-3"
                  style={{
                    borderBottom: `1px solid ${
                      mode === "light" ? "#3540741F" : "#252C45"
                    }`,
                  }}
                />

                <div className="d-flex align-items-center justify-content-between">
                  <LightDarkText
                    className="my-3 f14 text-start bold d-flex align-items-center gap-2"
                    color={
                      mode === "light" ? "text-dark-blue" : "text-light-text"
                    }
                  >
                    <InstagramIcon dark />
                    Instagram
                  </LightDarkText>
                  {showSocialDetails?.instagram ? (
                    <ArrowUpIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          instagram: false,
                        });
                      }}
                    />
                  ) : (
                    <ArrowDownIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          facebook: false,
                          instagram: true,
                          linkedIn: false,
                          twitter: false,
                          tikTok: false,
                          global: false,
                        });
                      }}
                    />
                  )}
                </div>
                {showSocialDetails.instagram && (
                  <>
                    <LightDarkTextEditor
                      height={"100"}
                      label={"Description"}
                      totalInputs={eventInputs}
                      setValue={setEventInputs}
                      champs="instagram"
                      value={eventInputs}
                      autoHeight
                      reformulateText
                    />
                    <div className="mt-4">
                      <FileUploader
                        label="Fichiers liés à instagram"
                        champs="instagramFiles"
                        totalInputs={eventInputs}
                        setValue={setEventInputs}
                        multiple
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {socialMediaDesc.linkedIn && (
              <>
                <div
                  className="my-3"
                  style={{
                    borderBottom: `1px solid ${
                      mode === "light" ? "#3540741F" : "#252C45"
                    }`,
                  }}
                />

                <div className="d-flex align-items-center justify-content-between">
                  <LightDarkText
                    className="my-3 f14 text-start bold d-flex align-items-center gap-2"
                    color={
                      mode === "light" ? "text-dark-blue" : "text-light-text"
                    }
                  >
                    <LinkedInIcon dark />
                    LinkedIn
                  </LightDarkText>
                  {showSocialDetails?.linkedIn ? (
                    <ArrowUpIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          linkedIn: false,
                        });
                      }}
                    />
                  ) : (
                    <ArrowDownIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          facebook: false,
                          instagram: false,
                          linkedIn: true,
                          twitter: false,
                          tikTok: false,
                          global: false,
                        });
                      }}
                    />
                  )}
                </div>
                {showSocialDetails.linkedIn && (
                  <>
                    <LightDarkTextEditor
                      height={"100"}
                      label={"Description"}
                      totalInputs={eventInputs}
                      setValue={setEventInputs}
                      champs="linkedIn"
                      value={eventInputs}
                      autoHeight
                      reformulateText
                    />
                    <div className="mt-4">
                      <FileUploader
                        label="Fichiers liés à linkedIn"
                        champs="linkedInFiles"
                        totalInputs={eventInputs}
                        setValue={setEventInputs}
                        multiple
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {socialMediaDesc.twitter && (
              <>
                <div
                  className="my-3"
                  style={{
                    borderBottom: `1px solid ${
                      mode === "light" ? "#3540741F" : "#252C45"
                    }`,
                  }}
                />

                <div className="d-flex align-items-center justify-content-between">
                  <LightDarkText
                    className="my-3 f14 text-start bold d-flex align-items-center gap-2"
                    color={
                      mode === "light" ? "text-dark-blue" : "text-light-text"
                    }
                  >
                    <TwitterIcon dark />X
                  </LightDarkText>
                  {showSocialDetails?.twitter ? (
                    <ArrowUpIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          twitter: false,
                        });
                      }}
                    />
                  ) : (
                    <ArrowDownIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          facebook: false,
                          instagram: false,
                          linkedIn: false,
                          twitter: true,
                          tikTok: false,
                          global: false,
                        });
                      }}
                    />
                  )}
                </div>
                {showSocialDetails.twitter && (
                  <>
                    <LightDarkTextEditor
                      height={"100"}
                      label={"Description"}
                      totalInputs={eventInputs}
                      setValue={setEventInputs}
                      champs="twitter"
                      value={eventInputs}
                      autoHeight
                      reformulateText
                    />
                    <div className="mt-4">
                      <FileUploader
                        label="Fichiers liés à X"
                        champs="twitterFiles"
                        totalInputs={eventInputs}
                        setValue={setEventInputs}
                        multiple
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {socialMediaDesc.tikTok && (
              <>
                <div
                  className="my-3"
                  style={{
                    borderBottom: `1px solid ${
                      mode === "light" ? "#3540741F" : "#252C45"
                    }`,
                  }}
                />

                <div className="d-flex align-items-center justify-content-between">
                  <LightDarkText
                    className="my-3 f14 text-start bold d-flex align-items-center gap-2"
                    color={
                      mode === "light" ? "text-dark-blue" : "text-light-text"
                    }
                  >
                    <TikTokIcon dark />
                    TikTok
                  </LightDarkText>
                  {showSocialDetails?.tikTok ? (
                    <ArrowUpIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          tikTok: false,
                        });
                      }}
                    />
                  ) : (
                    <ArrowDownIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          facebook: false,
                          instagram: false,
                          linkedIn: false,
                          twitter: false,
                          tikTok: true,
                          global: false,
                        });
                      }}
                    />
                  )}
                </div>
                {showSocialDetails.tikTok && (
                  <>
                    <LightDarkTextEditor
                      height={"100"}
                      label={"Description"}
                      totalInputs={eventInputs}
                      setValue={setEventInputs}
                      champs="tikTok"
                      value={eventInputs}
                      autoHeight
                      reformulateText
                    />
                    <div className="mt-4">
                      <FileUploader
                        label="Fichiers liés à Tiktok"
                        champs="tiktokFiles"
                        totalInputs={eventInputs}
                        setValue={setEventInputs}
                        multiple
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {socialMediaDesc.global && (
              <>
                <div
                  className="my-3"
                  style={{
                    borderBottom: `1px solid ${
                      mode === "light" ? "#3540741F" : "#252C45"
                    }`,
                  }}
                />

                <div className="d-flex align-items-center justify-content-between">
                  <LightDarkText
                    className="my-3 f14 text-start bold d-flex align-items-center gap-2"
                    color={
                      mode === "light" ? "text-dark-blue" : "text-light-text"
                    }
                  >
                    <GlobalIcon dark />
                    Global
                  </LightDarkText>
                  {showSocialDetails?.global ? (
                    <ArrowUpIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          global: false,
                        });
                      }}
                    />
                  ) : (
                    <ArrowDownIcon
                      dark
                      clickCallback={() => {
                        setShowSocialDetails({
                          ...showSocialDetails,
                          facebook: false,
                          instagram: false,
                          linkedIn: false,
                          twitter: false,
                          tikTok: false,
                          global: true,
                        });
                      }}
                    />
                  )}
                </div>
                {showSocialDetails.global && (
                  <>
                    <LightDarkTextEditor
                      height={"100"}
                      label={"Description"}
                      totalInputs={eventInputs}
                      setValue={setEventInputs}
                      champs="global"
                      value={eventInputs}
                      autoHeight
                      reformulateText
                    />
                    <div className="mt-4">
                      <FileUploader
                        label="Fichiers liés à global"
                        champs="globalFiles"
                        totalInputs={eventInputs}
                        setValue={setEventInputs}
                        multiple
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div
            className="my-3"
            style={{
              borderBottom: `1px solid ${
                mode === "light" ? "#3540741F" : "#252C45"
              }`,
            }}
          />
          <div className="my-4">
            <LighDarkMultiChoice
              label="Statut"
              placeholder="En attente"
              data={calendarStatutValues}
              totalInputs={eventInputs}
              setValue={setEventInputs}
              champs="statut"
              multiple={false}
              noSearch
            />
          </div>
          <div>
            <LighDarkMultiChoice
              label="Validation marketing par"
              placeholder="Sélectionner un ou plusieurs utilisateurs"
              data={[
                {
                  label: handleFirstLastName(user),
                  value: Number(user?.id),
                },
                ...canShareTo,
              ]}
              totalInputs={eventInputs}
              setValue={setEventInputs}
              champs="validatingUsers"
              multiple={true}
              disabled={eventInputs.statut[0]?.value !== 2}
              selectMoreInfo="Personnes pouvant valider l'événement"
            />
          </div>
        </>
      )}
      <div className="mt-4">
        <AttachementUploader label="Fichier(s)" />
      </div>
      <div className="my-4 d-flex align-items-center justify-content-between">
        {colors?.map((color, index) => (
          <div
            key={index}
            className="p-2"
            style={{
              border: `${
                color.label === eventColor ? "2px solid #354074" : "none"
              }`,
              borderRadius: "50%",
            }}
          >
            <div
              className="pointer eventColorPicker"
              style={{
                backgroundColor: `${color?.label}`,
              }}
              onClick={() => setEventColor(color?.label)}
            ></div>
          </div>
        ))}
      </div>
      <div className="w-100 d-flex mt-5 justify-content-between gap-1">
        {!isNew && (
          <div className="col-4">
            <ColoredButton
              textButton="Supprimer"
              color="red"
              callback={() =>
                dispatch(
                  deleteEvent({
                    id: event?.id,
                    type: event.attributes.type,
                    name: event.attributes.name,
                    calendar: event?.attributes?.calendar?.data,
                    ticket: event?.attributes?.ticket?.data?.id,
                  })
                )
              }
            />
          </div>
        )}
        <div className={`col`}>
          <ColoredButton
            textButton={isNew ? "Créer l'événement" : "Modifier l'événement"}
            color="light-blue"
            disabled={
              !eventInputs.name ||
              (!fromCalendar && !eventInputs.calendar?.length) ||
              eventInputs?.endDate < eventInputs?.startDate ||
              (eventInputs.endDate === eventInputs.startDate &&
                eventInputs?.endTime < eventInputs?.startTime)
            }
            callback={() => handleSubmitEvent()}
          />
        </div>
      </div>
      <div className="mt-4">
        <LightDarkText className="text-start bold custom-ticket-font-size">
          <span className="text-red">*</span>Champs obligatoires
        </LightDarkText>
      </div>
    </LightDarkContainer>
  );
};

export default EventForm;
