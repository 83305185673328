import React, { useEffect, useState } from "react";
import SocietyCardNew from "./SocietyCardNew";
import "./SocietyNew.css";
import LightDarkText from "../../Common/LightDarkText";
import { useSelector } from "react-redux";
import EmptyList from "../../Common/EmptyList";
import { normalizeString } from "../../../Utils/utils";

const SocietyListNew = ({
  setIsToggle,
  searchSociety = "",
  societes,
  isLinked = false,
}) => {
  const [filteredSocieties, setFilteredSocieties] = useState(societes);
  const { societe } = useSelector((state) => state.societesReducer);

  useEffect(() => {
    setFilteredSocieties(
      societes.filter(
        (el) =>
          normalizeString(el.id?.toString())?.includes(
            normalizeString(searchSociety)
          ) ||
          normalizeString(el.attributes?.name)?.includes(
            normalizeString(searchSociety)
          ) ||
          normalizeString(el.attributes?.siteWeb)?.includes(
            normalizeString(searchSociety)
          ) ||
          normalizeString(el.attributes?.email)?.includes(
            normalizeString(searchSociety)
          ) ||
          normalizeString(el.attributes?.phoneNumber)?.includes(
            normalizeString(searchSociety)
          ) ||
          normalizeString(el.attributes?.city)?.includes(
            normalizeString(searchSociety)
          ) ||
          normalizeString(el.attributes?.zipCode)?.includes(
            normalizeString(searchSociety)
          ) ||
          normalizeString(el.attributes?.adress)?.includes(
            normalizeString(searchSociety)
          ) ||
          el.attributes?.workInOrg?.data?.some((element) =>
            normalizeString(element.attributes?.name)?.includes(
              normalizeString(searchSociety)
            )
          )
      )
    );
  }, [searchSociety, societes]);

  return (
    <div className="h-100">
      {isLinked && (
        <div className="">
          <LightDarkText className={"text-start mt-4 mx-1 bold f18"}>
            <span className="xbold">{filteredSocieties.length}</span> sociétés
            sont liées avec{" "}
            <span className="xbold">{societe.attributes.name}</span>
          </LightDarkText>
          <LightDarkText className={"text-start mx-1 my-2 f14"}>
            Ajoutez ou retirez facilement des sociétés pour voir instantanément
            comment elles sont connectées entre elles.
          </LightDarkText>
        </div>
      )}
      {filteredSocieties.length === 0 ? (
        <div className="h-100">
          <EmptyList type="societe" showButton={false} />
        </div>
      ) : (
        <div
          className="grid5 scrollable"
          style={{ height: "calc(100vh - 180px)" }}
        >
          {filteredSocieties.map((society) => (
            <SocietyCardNew
              setIsToggle={setIsToggle}
              key={society.id}
              society={society}
              isLinked={isLinked}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SocietyListNew;
