import Api from "../../Api/Api";
import {
  ADD_DEVIS_FAILED,
  ADD_DEVIS_SUCCESS,
  GET_DEVIS_FAILED,
  GET_DEVIS_SUCCESS,
  LOADING_ADD_DEVIS,
  LOADING_DEVIS,
  SET_DEVIS,
  UNSET_DEVIS,
} from "../ActionsType/devisTypes";
import { UPLOAD } from "../Graphql/Mutations/files";
import {
  CREATE_PROSPECT,
  CREATE_PROSPECT_FIELD,
} from "../Graphql/Mutations/prospect";
import { GET_PROSPECT_BY_ID } from "../Graphql/Queries/Prospects";
import store from "../store/store";
import client from "./Client";
import { handleError, handleInfo } from "./toastMessage";

export const GetAllDevis =
  (page, status, sortProspect, search, selectedDate) => async (dispatch) => {
    dispatch({ type: LOADING_DEVIS });
    const devisArchived = store.getState().filtersReducer.devisArchived;
    const organisation =
      store.getState().organisationsReducer.selectedOrganisation;

    if (!organisation.id) {
      return;
    }
    const dateParam = selectedDate ? `&date=${selectedDate}` : "";

    try {
      const { data } = await Api.get(
        `/api/fetchConditionaDevis?organisation=${
          organisation.id
        }&archived=${devisArchived}&page=${page}&status=${status}&sortBy=${
          sortProspect.champs
        }&sortOrder=${
          sortProspect.asc === true ? "asc" : "desc"
        }&search=${search}${dateParam}`
      );
      dispatch({
        type: GET_DEVIS_SUCCESS,
        payload: {
          data: data.prospects.data,
          page: page,
        },
      });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
      dispatch({
        type: GET_DEVIS_FAILED,
      });
    }
  };

export const newDevis = (formData, navigate) => async (dispatch) => {
  dispatch({ type: LOADING_ADD_DEVIS });
  const organisation =
    store.getState().organisationsReducer.selectedOrganisation;
  const role = store.getState().roleReducer.role;
  if (!organisation.id) {
    return;
  }
  try {
    const questions = store.getState().formQuestionsReducer.questions;
    const user = store.getState().userReducer.user;

    const { data } = await client.mutate({
      mutation: CREATE_PROSPECT,
      variables: {
        origin: "INTERNAL",
        status: "NEW",
        user: user.id,
        organisation: organisation.id,
      },
    });

    const findAnswer = (question, answerValue) =>
      question.attributes.answers.data.find(
        (ans) => ans.attributes.answer === answerValue
      );

    for (const questionKey in formData) {
      if (formData.hasOwnProperty(questionKey)) {
        const answerValue = formData[questionKey];

        const question = questions.find(
          (q) => +q.id === parseInt(questionKey.split("-")[1])
        );
        if (question) {
          const inputType = question.attributes.input_type;

          if (["select", "radio"].includes(inputType)) {
            const answer = findAnswer(question, answerValue);
            if (answer) {
              await client.mutate({
                mutation: CREATE_PROSPECT_FIELD,
                variables: {
                  question: parseInt(questionKey.split("-")[1]),
                  form_answers: answer.id,
                  prospect: data.createProspect.data.id,
                },
              });
            }
          } else if (inputType === "file") {
            let media;
            media = await client.mutate({
              mutation: UPLOAD,
              variables: {
                file: answerValue[0],
              },
            });
            const fileId = media?.data?.upload?.data?.id;
            await client.mutate({
              mutation: CREATE_PROSPECT_FIELD,
              variables: {
                question: parseInt(questionKey.split("-")[1]),
                file: fileId,
                prospect: data.createProspect.data.id,
              },
            });
          } else if (["multi_select", "checkbox"].includes(inputType)) {
            const answerIds = [];
            answerValue.forEach((value) => {
              const answer = findAnswer(question, value);

              if (answer) {
                answerIds.push(answer.id);
              }
            });
            await client.mutate({
              mutation: CREATE_PROSPECT_FIELD,
              variables: {
                question: parseInt(questionKey.split("-")[1]),
                form_answers: answerIds,
                prospect: data.createProspect.data.id,
              },
            });
          } else {
            await client.mutate({
              mutation: CREATE_PROSPECT_FIELD,
              variables: {
                question: parseInt(questionKey.split("-")[1]),
                value: answerValue.toString(),
                prospect: data.createProspect.data.id,
              },
            });
          }
        }
      }
    }

    const prosp = await client.query({
      query: GET_PROSPECT_BY_ID(data.createProspect.data.id),
    });
    if (role.role === 1) {
      dispatch({
        type: ADD_DEVIS_SUCCESS,
        payload: prosp.data.prospects.data[0],
      });
    } else {
      dispatch({
        type: ADD_DEVIS_FAILED,
      });
    }
    dispatch(handleInfo("Devis créée", 1, "dark-green"));

    navigate(-1);
  } catch (error) {
    dispatch({
      type: ADD_DEVIS_FAILED,
    });
  }
};

export const setDevis = (data) => async (dispatch) => {
  dispatch({
    type: SET_DEVIS,
    payload: data,
  });
};
export const unsetDevis = () => async (dispatch) => {
  dispatch({
    type: UNSET_DEVIS,
  });
};
