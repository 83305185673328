import React from "react";
import LightDarkPlaceHolder from "../../PlaceHolders/LightDarkPlaceHolder";

const DrawerPlaceHolder = () => {
  return (
    <div className="d-flex flex-column alignC mt-2">
      {["", "", "", "", "", "", "", ""].map((el, index) => (
        <div className="my-3 alignC flex-column" key={index}>
          <LightDarkPlaceHolder
            width={"24px"}
            height={"24px"}
            customClass={"my-2"}
          />
          <LightDarkPlaceHolder width={"50px"} height={"6px"} />
        </div>
      ))}
    </div>
  );
};

export default DrawerPlaceHolder;
