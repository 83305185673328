import { useDispatch, useSelector } from "react-redux";

import { CloseIcon } from "../LightDarkSvg";
import LightDarkText from "../LightDarkText";
import TitleImageBlock from "../../Tickets/TicketCommonComponents/TitleImageBlock";

import { handleFirstLastName } from "../../../Utils/utils";

import { closeSideBarCard } from "../../../REDUX/actions/general";

const SideBarHeader = ({
  sideBarHeaderInfo,
  userSideBar = false,
  selectedItem,
  setSelectedItem,
}) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <div
      className={`p-3 d-flex ${
        sideBarHeaderInfo ? "justify-content-between" : "justify-content-end"
      } ${userSideBar ? "align-items-start" : "align-items-center"}  w-100`}
    >
      {sideBarHeaderInfo && (
        <TitleImageBlock
          societyName={!userSideBar && selectedItem?.attributes?.name}
          userName={userSideBar && handleFirstLastName(selectedItem)}
          defaultImage={
            userSideBar
              ? `user${selectedItem?.attributes?.genre || "3"}`
              : "society"
          }
          avatar={
            selectedItem?.attributes?.avatar?.data?.attributes?.image?.data
              ?.attributes?.url
          }
          extraData={
            userSideBar && (
              <LightDarkText
                className="text-start bold custom-ticket-font-size"
                color={
                  selectedItem?.attributes?.roleId === 1
                    ? "text-light-blue"
                    : mode === "light"
                    ? "text-blue-grey"
                    : "text-light-text"
                }
              >
                {selectedItem?.attributes?.stringRole}
              </LightDarkText>
            )
          }
        />
      )}
      <CloseIcon
        dimension={30}
        clickCallback={() => {
          setSelectedItem();
          dispatch(closeSideBarCard());
        }}
      />
    </div>
  );
};

export default SideBarHeader;
