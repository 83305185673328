import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  archiveProspect,
  updateProspect,
  updateStatus,
} from "../../REDUX/actions/prospect";
import ColoredButton from "../Common/Buttons/ColoredButton";
import LightDarkText from "../Common/LightDarkText";
import TextAreaInput from "../Simulateur/TextAreaInput";
import { Commande } from "../Common/LightDarkSvg";
import Select from "../Simulateur/Select";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { handleUpdateStatus, mapOperator } from "../../Utils/utils";
import { getConditionalUsers } from "../../REDUX/actions/clients";

const DetailsComponents = ({ choosenItem }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { isSupervisor, isClientAdmin } = useSelector(
    (state) => state.roleReducer
  );
  const { operateurs } = useSelector((state) => state.operateursReducer);
  const { clients } = useSelector((state) => state.clientsReducer);
  const screenWidth = useWindowWidth();
  const [note, setNote] = useState({ note: choosenItem?.note });
  const [selectedComm, setSelectedComm] = useState({ commercial: null });
  const [checked, setChecked] = useState(false);
  const [hovered, setHovered] = useState(false);
  useEffect(() => {
    dispatch(getConditionalUsers());
  }, [dispatch]);
  useEffect(() => {
    const selected = mapOperator(
      choosenItem === "DRAFT" ? operateurs : clients
    ).find((el) => el?.label === choosenItem?.actor);
    setSelectedComm({ commercial: selected?.value });
  }, []);

  const handleStatusUpdate = () => {
    dispatch(
      updateProspect(
        choosenItem?.id,
        {
          note: note.note,
          acteurs: [...choosenItem.actorsId, selectedComm?.commercial],
        },
        undefined,
        "prospect"
      )
    );
    dispatch(
      updateStatus(choosenItem?.id, {
        status: handleUpdateStatus(choosenItem?.status)?.newStatus,
      })
    );
    setSelectedComm({ commercial: null });
    setChecked(false);
  };

  const transformToCommande = () => {
    dispatch(
      updateProspect(
        choosenItem?.id,
        {
          note: note.note,
        },
        undefined,
        "prospect"
      )
    );
    dispatch(
      updateStatus(
        choosenItem?.id,
        {
          status: "TO_VALIDATE",
        },
        true
      )
    );
  };

  const cancel = () => {
    dispatch(archiveProspect(choosenItem?.id, true, note.note, "prospect"));
  };
  const ButtonData = [
    {
      id: 1,
      text:
        choosenItem.status !== "DRAFT_SELLER"
          ? "Oui, affecter la demande de devis"
          : "Faire une demande de tarif",
      color: "green",
      icon: null,
      disabled:
        !checked ||
        (choosenItem.status !== "DRAFT_SELLER" && !selectedComm?.commercial),
      action: handleStatusUpdate,
      shouldShow: true,
    },
    {
      id: 2,
      text: "Non, annuler la demande de devis",
      color: "red",
      icon: null,
      shouldShow: true,
      action: cancel,
    },
    {
      id: 3,
      text: "Transformer en bon de commande",
      color: "yellow",
      icon: <Commande color={"#FFFFFF"} hovered={hovered} />,
      disabled: !checked,
      shouldShow: choosenItem.status === "DRAFT_SELLER",
      action: transformToCommande,
    },
  ];
  const filtredCommerc = operateurs.filter((item) =>
    item.attributes?.user_org_permissions[0]?.departements.some(
      (department) => department.name === choosenItem?.department
    )
  );

  const filtredRevendeurs = clients.filter((item) =>
    item.attributes?.user_org_permissions[0]?.departements.some(
      (department) => department.name === choosenItem?.department
    )
  );
  return (
    <div className="d-flex flex-column w-100 h-100">
      <div
        className="mt-2 px-3 showScroll"
        style={{
          height: choosenItem.status === "DRAFT_SELLER" ? "60%" : "70%",
          overflow: "auto",
        }}
      >
        <LightDarkText className={`text-center bold py-3 f18`}>
          {choosenItem.status === "DRAFT_SELLER" ? "Confirmer" : "Attribuer"} le
          devis de {choosenItem?.firstname && choosenItem?.firstname}{" "}
          {choosenItem?.lastname && choosenItem?.lastname}
        </LightDarkText>
        <LightDarkText
          className={`text-center bold py-1 f12 mt-1`}
          color={mode === "light" ? "text-blue-grey" : "text-light-text"}
        >
          {choosenItem.status === "DRAFT_SELLER"
            ? "Confirmation"
            : "Validation"}{" "}
          du devis
        </LightDarkText>
        {filtredCommerc.length === 0 && choosenItem.status === "DRAFT" ? (
          <LightDarkText
            className={`text-center bold py-1 f12 mt-3`}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            Aucun commercial n'existe dans la région {choosenItem?.department}
          </LightDarkText>
        ) : filtredRevendeurs.length === 0 &&
          choosenItem.status === "DRAFT_COMMERCIAL" ? (
          <LightDarkText
            className={`text-center bold py-1 f12 mt-3`}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            Aucun revendeur n'existe dans la région {choosenItem?.department}
          </LightDarkText>
        ) : (
          <>
            <LightDarkText
              className={`text-center bold py-1 f12 mt-1`}
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
            >
              Note interne (ou raison d'annulation)
            </LightDarkText>
            <div className="  mt-3">
              <TextAreaInput
                bgwhite={false}
                placeholder="Note interne (ou raison d’annulation)"
                champs={"note"}
                rows={7}
                totalInput={note}
                setValue={setNote}
              />
            </div>

            {!["KO_DRAFT"].includes(choosenItem.status) ? (
              <>
                {
                  <div className="d-flex align-items-center py-2 ">
                    <div className="d-flex me-2 ">
                      <input
                        type="checkbox"
                        id="check"
                        name="check"
                        value={checked}
                        onChange={(e) => setChecked(!checked)}
                        checked={checked}
                      />
                    </div>
                    <LightDarkText
                      className={`text-start bold py-1 f12 mt-1 lh18 `}
                      color="text-light-text"
                    >
                      {choosenItem.status === "DRAFT_SELLER"
                        ? "En cochant cette case, vous confirmez avoir l'accord du client"
                        : "En cochant cette case, vous allez automatiquement affecter le devis" +
                          (choosenItem.status === "DRAFT"
                            ? " au commercial"
                            : " au revendeur")}
                    </LightDarkText>
                  </div>
                }

                {choosenItem.status !== "DRAFT_SELLER" && (
                  <div className="py-2">
                    <Select
                      data={mapOperator(
                        choosenItem?.status === "DRAFT"
                          ? filtredCommerc
                          : filtredRevendeurs
                      )}
                      label={`Sélectionner un ${
                        choosenItem.status === "DRAFT"
                          ? "commercial"
                          : "revendeur"
                      }`}
                      bgWhite={false}
                      required={true}
                      position="absolute"
                      champs={`commercial`}
                      totalInput={selectedComm}
                      setValue={setSelectedComm}
                      isTop={true}
                    />
                  </div>
                )}
                {choosenItem.status === "DRAFT_SELLER" && isClientAdmin && (
                  <LightDarkText
                    className={`text-start bold py-1 f12  lh18 pb-4 mb-1`}
                    color="text-light-text"
                  >
                    Une demande sera faite aux commerciaux et aux admin afin de
                    verifier la disponibilité du stock
                  </LightDarkText>
                )}
              </>
            ) : null}
          </>
        )}
      </div>
      {isSupervisor ||
      (choosenItem.status === "DRAFT" && filtredCommerc.length !== 0) ||
      (choosenItem.status === "DRAFT_COMMERCIAL" &&
        filtredRevendeurs.length !== 0) ||
      (choosenItem.status === "DRAFT_SELLER" && isClientAdmin) ? (
        <div
          className="w-100 d-flex flex-column justify-content-end"
          style={{
            height: choosenItem.status === "DRAFT_SELLER" ? "40%" : "30%",
          }}
        >
          {ButtonData.map(
            ({ id, text, color, icon, disabled, action, shouldShow }) => {
              if (shouldShow) {
                return (
                  <div
                    key={id}
                    className="m-2"
                    onMouseEnter={() =>
                      id === 3 && !disabled && setHovered(true)
                    }
                    onMouseLeave={() => id === 3 && setHovered(false)}
                  >
                    <ColoredButton
                      textButton={screenWidth > 600 && text}
                      color={color}
                      iconSvg={icon}
                      disabled={disabled}
                      callback={action}
                    />
                  </div>
                );
              }
              return null;
            }
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DetailsComponents;
