import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkText from "../Common/LightDarkText";

import { handleDuration } from "../Tickets/ticketUtils";

const SocietyBundleBar = ({ consumption_0, monthlyPlan, monthlyQuota }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const planConsumption = (consumption_0 / monthlyPlan) * 100;

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          <LightDarkText className="custom-ticket-font-size py-1 text-white me-2">
            {handleDuration(consumption_0 + monthlyQuota || 0)} /{" "}
            {handleDuration(monthlyPlan || 0)}
          </LightDarkText>
        </Tooltip>
      }
    >
      <div>
        <LightDarkText
          className={"custom-ticket-font-size text-start w-100 mt-2"}
          color={mode === "light" ? "text-blue-grey" : "text-light-text"}
        >
          <div
            className={`progress radius05 ${
              mode === "light" ? "bg-light-text" : "bg-light-message"
            } `}
            style={{
              height: "7px",
              width: "100%",
              border: "transparent",
            }}
          >
            <div
              className="progress-bar bg-green"
              role="progressbar"
              style={{
                width: `${(monthlyQuota / monthlyPlan) * 100}%`,
                display: `${!monthlyQuota || !monthlyPlan ? "none" : ""}`,
              }}
              aria-valuenow={Number((monthlyQuota / monthlyPlan) * 100)}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            <div
              className="progress-bar bg-light-blue"
              role="progressbar"
              style={{
                width: `${planConsumption > 0 ? planConsumption : 0}%`,
                display: `${!planConsumption ? "none" : ""}`,
              }}
              aria-valuenow={planConsumption ?? 0}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </LightDarkText>
      </div>
    </OverlayTrigger>
  );
};

export default SocietyBundleBar;
