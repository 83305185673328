import Calendar from "../../Common/MenuIcons/Calendar";
import Mentions from "../../Common/MenuIcons/Mentions";
import MentionsLock from "../../Common/MenuIcons/MentionsLock";
import MessagesIcon from "../../Common/MenuIcons/MessagesIcon";
import MessagesLock from "../../Common/MenuIcons/MessagesLock";
import TaskCheck from "../../Common/MenuIcons/TaskCheck";

export const filterNotificationsByType = (
  isCalendarNotifications,
  notifications
) => {
  const notificationTypes = isCalendarNotifications
    ? [
        "newCalendar",
        "sharedCalendar",
        "newEvent",
        "updateEvent",
        "revisionCreated",
        "eventToValidate",
        "validateEvent",
        "programEvent",
        "acceptEvent",
        "declineEvent",
      ]
    : [
        "Message",
        "Intervention",
        "Ticket_archive",
        "Ticket",
        "Action",
        "Mention",
        "Facture",
        "File",
        "Folder",
        "Folder_update",
        "newCollaborator",
      ];

  const filteredNotifications = notifications.filter((notification) =>
    notificationTypes.includes(notification?.attributes?.type?.toString())
  );

  return filteredNotifications;
};

export const notificationsTab = (role, showPoints) => {
  return [
    {
      title: "Messages Publiques",
      toggleValue: "public",
      isVisible: true,
      hideTab: false,
      Icon: (fill) => <MessagesIcon fill={fill} />,
      showRedPoint: showPoints.showPublicRedPoint,
    },
    {
      title: "Mentions",
      toggleValue: "mentions",
      isVisible: true,
      hideTab: false,
      Icon: (fill) => <Mentions fill={fill} width="20" height="20" />,
      showRedPoint: showPoints.showMentionRedPoint,
    },
    {
      title: "Messages Privées",
      toggleValue: "internalMessages",
      isVisible: role <= 2,
      hideTab: false,
      Icon: (fill) => <MessagesLock fill={fill} />,
      showRedPoint: showPoints.showPrivateMessageRedPoint,
    },
    {
      title: "Mentions Privées",
      toggleValue: "internalMentions",
      isVisible: role <= 2,
      hideTab: false,
      Icon: (fill) => <MentionsLock fill={fill} />,
      showRedPoint: showPoints.showPrivateMentionRedPoint,
    },

    {
      title: "Interventions",
      toggleValue: "interventions",
      isVisible: true,
      hideTab: false,
      Icon: (fill) => <TaskCheck fill={fill} />,
      showRedPoint: showPoints.showInterventionRedPoint,
    },

    {
      title: "Calendriers",
      toggleValue: "calendrier",
      isVisible: true,
      hideTab: false,
      Icon: (fill) => <Calendar fill={fill} width="25" height="26" />,
      showRedPoint: showPoints.showCalendarRedPoint,
    },
  ];
};
