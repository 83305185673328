import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../REDUX/actions/modal";
import {
  moveFile,
  deleteFolder,
  downloadMultipleAgilixFolders,
} from "../../REDUX/actions/cloud";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import useWindowWidth from "../../Hooks/useWindowWidth";
import {
  CloudFolder,
  DeleteIcon,
  Download,
  EditIcon,
  ShareIcon,
} from "../Common/LightDarkSvg";
import LightDarkText from "../Common/LightDarkText";
import RenameCloudItem from "./RenameCloudItem";
import ShareFolder from "./ShareFolder";
import { Spinner } from "react-bootstrap";
import TooltipActions from "../Common/Tooltip/TooltipActions";
import ElementName from "./CloudPartials/ElementName";
import FileSharedSection from "./CloudPartials/FileSharedSection";

const FolderCard = ({ dossier, shared }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const [showTooltip, setshowTooltip] = useState(false);
  const { mode } = useSelector((state) => state.genearlReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { isSupervisor } = useSelector((state) => state.roleReducer);
  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );

  const [isMine, setisMine] = useState(
    +dossier.attributes.cloud.data.id ===
      +user.attributes.user_org_permissions.data[0].attributes.cloud.data.id
  );
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [{ isActive }, drop] = useDrop(() => ({
    accept: "Card",
    drop: (item) => {
      // !shared &&
      dispatch(moveFile(item.id, dossier.id));
    },
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver(),
    }),
  }));

  const handleDeleteFolder = (folderId, message2, message, btnMessage) => {
    dispatch(
      openConfirmationModal({
        callBack: () => dispatch(deleteFolder([folderId])),
        icon: "archive",
        message: message || "Voulez vous supprimer le dossier",
        message2: message2,
        confirmButtonText: btnMessage || "Supprimer le dossier",
      })
    );
  };
  const tooltipData = [
    {
      id: 1,
      text: "Renommer",
      icon: <EditIcon className="me-2" size={24} />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openModal({
            type: "small",
            content: (
              <RenameCloudItem
                id={dossier.id}
                name={dossier?.attributes?.name}
              />
            ),
          })
        );
      },
      condition: isMine,
    },
    {
      id: 2,
      text: "Partager",
      icon: <ShareIcon className="me-2" size={24} />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          openModal({
            type: "small",
            content: (
              <ShareFolder
                folderId={dossier.id}
                folderSharedUsers={dossier?.attributes?.sharedUsers}
                parentFolder={dossier?.attributes?.parentFolder?.data}
              />
            ),
          })
        );
      },
      condition: isMine,
    },
    {
      id: 3,
      text: "Télécharger",
      icon: <Download className="me-2" size={24} />,
      action: () => {
        setshowTooltip(false);
        dispatch(
          downloadMultipleAgilixFolders([
            { id: dossier.id, name: dossier.attributes.name },
          ])
        );
      },
      condition: true,
    },
    {
      id: 4,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" size={24} />,
      action: () => {
        setshowTooltip(false);
        handleDeleteFolder(
          dossier.id,
          `Supprimer le dossier ${dossier?.attributes?.name}?`,
          "êtes vous sûr de vouloir",
          "Supprimer le dossier"
        );
      },
      condition: isMine,
    },
  ];

  return (
    <div
      ref={drop}
      className="folderCard d-flex flex-column justify-content-between p-3 radius05 t-02"
      style={{
        transform: `${isActive ? "scale(1.1)" : ""}`,
        backgroundColor: `${
          isActive && mode === "light"
            ? "#e0e7ff"
            : !isActive && mode === "dark"
              ? "#181f3a"
              : isActive && mode === "dark"
                ? "#354074"
                : "white"
        }`,
      }}
    >
      {loadingDownload ? (
        <div className="alignC mt-5">
          <Spinner size="md" variant="light-text" />
        </div>
      ) : (
        <>
          <div className="d-flex align-items-start justify-content-between">
            <div
              onClick={() =>
                navigate(`${organisationUrl}/dossier/${dossier.id}`)
              }
            >
              <CloudFolder size={screenWidth < 1200 ? 30 : 40} />
            </div>
            <div className="d-flex justify-self-end">
              {shared ? (
                <FileSharedSection file={dossier} isMine={isMine} />
              ) : null}

              {(+user?.attributes?.user_org_permissions?.data[0]?.attributes
                ?.cloud?.data?.id === +dossier?.attributes?.cloud?.data?.id ||
                isSupervisor) && (
                <>
                  <div
                    className="pointer position-relative ms-2"
                    style={{ width: "fit-content" }}
                    onClick={() => setshowTooltip(!showTooltip)}
                  >
                    <TooltipActions
                      setshowTooltip={setshowTooltip}
                      showTooltip={showTooltip}
                      tooltipData={tooltipData}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="d-flex mt-3 mb-2 justify-content-between">
            <div
              className="text-start pointer  w-100"
              onClick={() =>
                navigate(`${organisationUrl}/dossier/${dossier.id}`)
              }
            >
              <ElementName
                name={dossier.attributes.name}
                callBack={() =>
                  navigate(`${organisationUrl}/dossier/${dossier.id}`)
                }
              />
              <div className="d-flex gap-2">
                <LightDarkText
                  className="f10 bold mt-1"
                  color="text-light-text"
                >
                  {dossier.attributes?.fichiers?.data.length || 0}
                  {dossier.attributes?.fichiers?.data.length === 1
                    ? " fichier "
                    : " fichiers "}
                  |
                </LightDarkText>
                <LightDarkText
                  className="f10 bold mt-1"
                  color="text-light-text"
                >
                  {dossier.attributes?.subFolders?.data.length || 0}
                  {dossier.attributes?.subFolders?.data.length === 1
                    ? " dossier "
                    : " dossiers "}
                </LightDarkText>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FolderCard;
