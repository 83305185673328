import Api from "../../Api/Api";
import { CREATE_FILE } from "../Graphql/Mutations/cloud";
import { UPLOAD } from "../Graphql/Mutations/files";
import store from "../store/store";
import client from "./Client";
import { handleInfo } from "./toastMessage";
const color = "light-blue";

export const fetchFaqs = () => {
  return async (dispatch) => {
    const organisationId =
      store.getState().organisationsReducer.selectedOrganisation.id;
    dispatch({ type: "FETCH_FAQS" });
    try {
      const response = await Api.get(
        `/api/fetchConditionalFaq?organisation=${organisationId}`
      );
      dispatch({ type: "FETCH_FAQS_SUCCESS", payload: response.data.data });
    } catch (error) {
      dispatch({ type: "FETCH_FAQS_ERROR" });
      console.error("Error fetching FAQs:", error);
    }
  };
};

export const fetchFaqById = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: "FETCH_FAQ" });
    try {
      const organisationId =
        getState().organisationsReducer.selectedOrganisation.id;
      const response = await Api.get(
        `/api/fetchFaqItem?faqId=${id}&organisation=${organisationId}`
      );
      dispatch({ type: "FETCH_FAQ_SUCCESS", payload: response.data });
    } catch (error) {
      dispatch({ type: "FETCH_FAQ_ERROR" });
      console.error("Error fetching FAQ:", error);
    }
  };
};

export const createFaq = (faqData) => {
  return async (dispatch) => {
    const temporarySelected = store.getState().filesReducer.temporarySelected;
    let fileIds = [];
    if (temporarySelected?.length) {
      let uploadedFiles = [];
      for (let i = 0; i < temporarySelected.length; i++) {
        const file = temporarySelected[i];

        const { data } = await client.mutate({
          mutation: UPLOAD,
          variables: {
            file: file,
          },
        });
        uploadedFiles = [...uploadedFiles, data.upload.data];
      }

      for (let i = 0; i < uploadedFiles.length; i++) {
        const uploadedFile = uploadedFiles[i];

        const createdFichier = await client.mutate({
          mutation: CREATE_FILE,
          variables: {
            file: uploadedFile.id,
            name: uploadedFile.attributes.name,
            confidentiel: true,
          },
        });
        fileIds = [...fileIds, createdFichier.data.createFichier.data.id];
      }
    }
    try {
      const response = await Api.post(
        "/api/faq-elements?populate[fichiers][populate]=*",
        {
          data: {
            ...faqData,
            fichiers: fileIds,
          },
        }
      );
      dispatch(handleInfo("FAQ crée", 1, color));
      dispatch({ type: "CREATE_FAQ_SUCCESS", payload: response.data.data });
      dispatch(fetchFaqs());
      dispatch(fetchFaqsCategories());
    } catch (error) {
      console.error("Error creating FAQ:", error);
    }
  };
};

export const fetchFaqsCategories = () => {
  return async (dispatch, getState) => {
    dispatch({ type: "FETCH_FAQS_CATEGORIES" });
    try {
      const organisationId =
        getState().organisationsReducer.selectedOrganisation.id;
      const response = await Api.get(
        `/api/fetchConditionalFaqCategories?organisation=${organisationId}`
      );

      dispatch({
        type: "FETCH_FAQS_CATEGORIES_SUCCESS",
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({ type: "FETCH_FAQS_CATEGORIES_ERROR" });
      console.error("Error fetching Categories FAQs:", error);
    }
  };
};

export const createFaqCategory = (faqCategoryData) => {
  return async (dispatch) => {
    try {
      const response = await Api.post("/api/faq-categories", {
        data: {
          ...faqCategoryData,
        },
      });

      dispatch({
        type: "CREATE_FAQ_CATEGORY_SUCCESS",
        payload: response.data.data,
      });
      dispatch(fetchFaqsCategories());
    } catch (error) {
      dispatch(handleInfo("Erreur lors de la mise à jour du FAQ", "red"));
      console.error("Error creating FAQ Category:", error);
    }
  };
};

export const updateFaq = (faqId, faqData, existingFileIds) => {
  return async (dispatch) => {
    const temporarySelected = store.getState().filesReducer.temporarySelected;
    let newFileIds = [];

    if (temporarySelected?.length) {
      let uploadedFiles = [];
      for (let i = 0; i < temporarySelected.length; i++) {
        const file = temporarySelected[i];

        const { data } = await client.mutate({
          mutation: UPLOAD,
          variables: {
            file: file,
          },
        });
        uploadedFiles = [...uploadedFiles, data.upload.data];
      }

      for (let i = 0; i < uploadedFiles.length; i++) {
        const uploadedFile = uploadedFiles[i];

        const createdFichier = await client.mutate({
          mutation: CREATE_FILE,
          variables: {
            file: uploadedFile.id,
            name: uploadedFile.attributes.name,
            confidentiel: true,
          },
        });
        newFileIds = [...newFileIds, createdFichier.data.createFichier.data.id];
      }
    }

    try {
      const allFileIds = [...existingFileIds, ...newFileIds];

      const response = await Api.put(
        `/api/faq-elements/${faqId}?populate[fichiers][populate]=*&populate[faq_category][populate]=*`,
        {
          data: {
            ...faqData,
            fichiers: allFileIds,
          },
        }
      );
      dispatch({
        type: "UPDATE_FAQ_SUCCESS",
        payload: response.data.data,
      });
      dispatch(fetchFaqsCategories());
      dispatch(handleInfo("FAQ mis à jour", 1, color));
    } catch (error) {
      dispatch(handleInfo("Erreur lors de la mise à jour du FAQ", "red"));
      console.error("Error updating FAQ:", error);
    }
  };
};

export const deleteFaq = (faqId) => {
  return async (dispatch) => {
    try {
      await Api.delete(`/api/faq-elements/${faqId}`);

      dispatch({
        type: "DELETE_FAQ_SUCCESS",
        payload: faqId,
      });

      dispatch(fetchFaqsCategories());
      dispatch(handleInfo("FAQ supprimé avec succès", 1, "light-blue"));
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      dispatch(handleInfo("Erreur lors de la suppression du FAQ", "red"));
    }
  };
};
