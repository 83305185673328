import LightDarkText from "../../../Common/LightDarkText";
import UserSmallCard from "../../../Clients/UserSmallCard";

const ParticipantsList = ({ participants }) => {
  return (
    <div className="my-4">
      <LightDarkText
        className="f12 text-start bold mb-4"
        color="text-light-text"
      >
        Participants ({participants?.length})
      </LightDarkText>
      {participants?.map((el, index) => (
        <UserSmallCard key={index} responsible={el} />
      ))}
    </div>
  );
};

export default ParticipantsList;
