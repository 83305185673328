import { isValidPhoneNumber } from "react-phone-number-input";

import { validMail } from "../../../Utils/utils";

export const societyFields = (society) => {
  return {
    ...society.attributes,
    society_type: society?.attributes?.society_type?.data
      ? [
          {
            label: society?.attributes?.society_type?.data?.attributes?.name,
            value: society?.attributes?.society_type?.data?.id,
          },
        ]
      : [],
    childCompanies: society?.attributes?.childCompanies?.data
      ? society?.attributes?.childCompanies?.data
          .map((el) => {
            return {
              label: el.attributes?.name,
              value: el.id,
            };
          })
          .filter((el) => el.id !== society.id)
      : [],
  };
};

export const checkRequiredSocietyInputs = (
  societyInputs,
  societyTypesValues
) => {
  return (
    !societyInputs.name ||
    (societyTypesValues?.length !== 0 &&
      !societyInputs?.society_type?.length) ||
    !validMail(societyInputs.email) ||
    // !societyInputs.phoneNumber ||
    (!isValidPhoneNumber(societyInputs.phoneNumber || "") &&
      societyInputs.phoneNumber) ||
    !validMail(societyInputs.emailFacture)
  );
};

export const SocietyFormTabs = () => {
  return [
    {
      title: "Données",
      toggleValue: "infos",
      isVisible: true,
    },
    {
      title: "Réseaux sociaux",
      toggleValue: "social_media",
      isVisible: true,
    },
    {
      title: "Sociétés liées",
      toggleValue: "link_societies",
      isVisible: true,
    },
    {
      title: "Paiement",
      toggleValue: "payment",
      isVisible: true,
    },
    {
      title: "Facturation",
      toggleValue: "facturation",
      isVisible: true,
    },
  ];
};

export const SocietyBundleTabs = (selectedOrganisation) => {
  return [
    {
      title: "Tickets créés",
      toggleValue: "tickets",
      isVisible: !selectedOrganisation.attributes.withClicks,
    },
    {
      title: "Consommation",
      toggleValue: "consumed",
      isVisible: selectedOrganisation.attributes.withForfait,
    },
    {
      title: "Nombre de clic",
      toggleValue: "clics",
      isVisible: selectedOrganisation.attributes.withClicks,
    },
  ];
};

export const SocietySideBarTabs = ({ canSeeCollabs, canSeeOperators }) => {
  return [
    {
      title: "Données",
      toggleValue: "infos",
      isVisible: true,
    },
    {
      title: "Collaborateurs",
      toggleValue: "collaborators",
      isVisible: canSeeCollabs,
    },
    {
      title: "Opérateurs",
      toggleValue: "operators",
      isVisible: canSeeOperators,
    },
  ];
};

export const userSideBarTabs = ({ isSupervisor }) => {
  return [
    {
      title: "Détails",
      toggleValue: "details",
      isVisible: true,
    },
    {
      title: "Historique de connexion",
      toggleValue: "loginLogs",
      isVisible: isSupervisor,
    },
  ];
};
