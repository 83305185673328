const initialState = {
  notifications: [],
  calendarNotifications: [],
  notificationsloading: false,
  showPoints: {},
};
const notificationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "NOTIFICATIONS_LOADING":
      return { ...state, notificationsloading: true };
    case "FETCH_NOTIFICATIONS":
      return {
        ...state,
        notifications: payload ?? [],
        notificationsloading: false,
      };
    case "FETCH_CALENDAR_NOTIFICATIONS":
      return {
        ...state,
        calendarNotifications: payload ?? [],
        notificationsloading: false,
      };
    case "READ_NOTIF":
      return {
        ...state,
        notifications: state.notifications,
      };

    case "UNREAD_NOTIF":
      return {
        ...state,
        notifications: state.notifications,
      };
    case "CHECK_READ_NOTIFICATIONS_POINT":
      return {
        ...state,
        showPoints: payload,
      };
    default:
      return state;
  }
};
export default notificationsReducer;
