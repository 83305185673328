export const userApiAttributes = [
  "email",
  "firstName",
  "genre",
  "lastConnected",
  "connected",
  "lastName",
  "adress",
  "city",
  "phoneNumber",
  "zipCode",
  "archived",
  "birthDate",
  "additionalData",
];
