import { gql } from "@apollo/client";
import { ALERT_ATTRIBUTES } from "../Attributes/Alerts";

export const CREATE_ALERT = gql`
  mutation ($description: String, $url: String, $image: ID, $creator: ID, $administrators:Boolean, $collaborators:Boolean, $operators:Boolean, $lectors:Boolean) {
    createAlert(
      data: { 
        description: $description
        url: $url
        image: $image
        creator: $creator
        administrators: $administrators
        collaborators: $collaborators
        operators: $operators
        lectors: $lectors
      }
    ) 
    {
      data {
        id
        attributes {
          ${ALERT_ATTRIBUTES}
        }
      }
    }
  }
`;

export const UPDATE_ALERT = gql`
  mutation (
    $id: ID!
    $description: String
    $url: String
    $image: ID
    $administrators:Boolean
    $collaborators:Boolean
    $operators:Boolean
    $lectors:Boolean
  ) {
    updateAlert(
      id: $id
      data: {
        description: $description
        url: $url
        image: $image
        administrators: $administrators
        collaborators: $collaborators
        operators: $operators
        lectors: $lectors
      }
    ) 
    {
      data {
        id
        attributes {
          ${ALERT_ATTRIBUTES}
        }
      }
    }
  }
`;

export const READ_ALERT = gql`
  mutation ($id: ID!, $seenBy: [ID]) {
    updateAlert(id: $id, data: { seenBy: $seenBy }) {
      data {
        id
      }
    }
  }
`;

export const DELETE_ALERT = (id) => gql`
mutation {
  deleteAlert(id: ${id})
    {
      data {
        id
      }
    }
}
`;
