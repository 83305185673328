import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";
import { useSelector } from "react-redux";

const TicketDepartments = ({ ticket, notRead }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <div
      className={`alignH custom-ticket-font-size  bold text-start text-ellipsis`}
    >
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            <LightDarkText className="custom-ticket-font-size  py-1 text-white">
              {ticket.attributes.stringDepartements}
            </LightDarkText>
          </Tooltip>
        }
      >
        <div className={`text-ellipsis`}>
          <LightDarkText
            className={`custom-ticket-font-size  bold text-start text-ellipsis ${
              notRead && "xbold "
            }`}
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {ticket.attributes.stringDepartements}
          </LightDarkText>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default TicketDepartments;
