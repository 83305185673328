import React from "react";
import IconButton from "../../Components/Common/Buttons/IconButton";
import { ArrowBackIcon } from "../../Components/Common/LightDarkSvg";
import Simulateur from "../SimulateurView/Simulateur";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetProspectById } from "../../REDUX/actions/prospect";
import { GetAllQuestions } from "../../REDUX/actions/formQuestion";

const ProspectDetailView = () => {
  const dispatch = useDispatch();
  const { prospect } = useSelector((state) => state.prospectReducer);
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    dispatch(GetProspectById(id));
    dispatch(GetAllQuestions());
  }, []);
  return (
    <div>
      <div className="mb-2 d-flex">
        <IconButton
          icon={<ArrowBackIcon />}
          callBack={() => {
            navigate(-1);
          }}
        />
      </div>

      <Simulateur
        isForm={true}
        prospect={prospect}
        isUpdate={true}
        prospectId={id}
      />
    </div>
  );
};

export default ProspectDetailView;
