import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Form.css";
import LightDarkText from "../../../Common/LightDarkText";
import { useEffect } from "react";
const ColonneList = ({ label, totalInput, setValue, champs, required }) => {
  const [selected, setSelected] = useState();
  const { mode } = useSelector((state) => state.genearlReducer);
  const handleChange = (value) => {
    setSelected(value);
    setValue({ ...totalInput, [champs]: 12 / value });
  };
  useEffect(() => {
    if (totalInput) {
      setSelected(12 / totalInput[champs]);
    }
  }, []);
  return (
    <div className="w-100">
      <LightDarkText
        className={"text-start bold f12 mb-2"}
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        {label} {required ? <span className="text-red">*</span> : null}
        <div></div>
      </LightDarkText>
      <div className="w-100 h40 d-flex align-items-center justify-content-between">
        {[1, 2, 3].map((numItems, columnIndex) => (
          <div
            key={columnIndex}
            className={`d-flex colonneContainer ${
              selected === numItems && "selectedColonne"
            }`}
            onClick={() => handleChange(numItems)}
          >
            {[...Array(numItems)].map((_, itemIndex) => (
              <div key={itemIndex} className="colonneItem"></div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColonneList;
