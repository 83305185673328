import React from "react";

import LightDarkContainer from "../LightDarkContainer";
import SideBarHeader from "./SideBarHeader";
import MultiTabsMenu from "../MultiTabsMenu";

const SideBar = ({
  children,
  index,
  sideBarHeaderInfo = false,
  userSideBar = false,
  selectedItem,
  setSelectedItem,
  isToggle,
  setIsToggle,
  tabsData,
  tabsProps,
}) => {
  return (
    <LightDarkContainer
      key={index}
      index={index}
      className="d-flex flex-column justify-content-start w-100 radius05"
      colorLight="white"
      colorDark="dark-grey-bg"
      sideBar
    >
      <SideBarHeader
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        sideBarHeaderInfo={sideBarHeaderInfo}
        userSideBar={userSideBar}
      />
      {tabsData && (
        <MultiTabsMenu
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          allTabs={tabsData(tabsProps)}
          align="center"
          gap="gap-5"
        />
      )}
      <div className="px-3 h100 scrollable">{children}</div>
    </LightDarkContainer>
  );
};

export default SideBar;
