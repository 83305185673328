import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInterviewForm,
  ValidateInterview,
} from "../../../../../REDUX/actions/interviews";
import InterviewPlaceHolder from "./InterviewPlaceHolder";
import LightDarkText from "../../../../Common/LightDarkText";
import InterventionQuestionRespond from "./InterventionQuestionRespond";
import { evaluateCondition } from "./interviewUtils";
import { closeSideBar } from "../../../../../REDUX/actions/sideBar";
import ColoredButton from "../../../../Common/Buttons/ColoredButton";

const InterviewForm = ({ interviewModel }) => {
  const dispatch = useDispatch();
  const { interviewLoading, interviewForm, responseLoading } = useSelector(
    (state) => state.interviewsReducer
  );

  const [step, setStep] = useState(1);
  const [isComplete, setIsComplete] = useState(false);
  const [noMoreModifications, setNoMoreModifications] = useState();

  useEffect(() => {
    dispatch(getInterviewForm(interviewModel.id));
  }, []);

  useEffect(() => {
    if (interviewForm) {
      setNoMoreModifications(interviewForm.attributes.noMoreModifications);
      if (interviewForm.attributes.noMoreModifications) {
        setStep(interviewForm.attributes.interview_questions.data.length);
      }
    }
  }, [interviewForm]);

  useEffect(() => {
    if (
      interviewForm &&
      !responseLoading &&
      step <= interviewForm.attributes.interview_questions.data.length
    ) {
      const currentQuestion =
        interviewForm.attributes.interview_questions.data[step - 1];
      const { shouldSkip } = evaluateCondition(
        currentQuestion,
        interviewForm.attributes.interview_responses.data
      );

      if (shouldSkip) {
        setStep((prev) => prev + 1);
      }
      setIsComplete(
        step >= interviewForm.attributes.interview_questions.data.length
      );
    }
  }, [step, interviewForm, responseLoading]);

  return interviewLoading || !interviewForm ? (
    <InterviewPlaceHolder />
  ) : (
    <div className="p-4 d-flex flex-column justify-content-between h100">
      <div>
        <LightDarkText className="text-start bold f18">
          {`${interviewForm.attributes.name}`}
        </LightDarkText>

        <LightDarkText
          className={
            "custom-ticket-font-size text-blue-grey p-nomb text-start mb-3"
          }
        >
          {interviewForm.attributes.description}
        </LightDarkText>
        <div className="d-flex flex-column gap-3">
          {noMoreModifications && (
            <LightDarkText
              color={"red"}
              className="text-start text-red bold f16"
            >
              Vous ne pouvez plus modifier vos réponses
            </LightDarkText>
          )}
          {interviewForm.attributes.interview_questions.data
            .slice(0, step)
            .filter((question) => {
              const { show } = evaluateCondition(
                question,
                interviewForm.attributes.interview_responses.data
              );
              return show;
            })
            .map((question) => (
              <InterventionQuestionRespond
                noMoreModifications={noMoreModifications}
                question={question}
                key={question.id}
                setStep={setStep}
                step={step}
                modelId={interviewModel.id}
                response={interviewForm.attributes.interview_responses.data.find(
                  (response) =>
                    response.attributes.interview_question.data.id ===
                    question.id
                )}
              />
            ))}
        </div>
      </div>

      {
        <div className="alignC">
          <div className="w60">
            <ColoredButton
              color={"blue"}
              disabled={!isComplete || noMoreModifications}
              textButton={"Valider les réponses"}
              callback={() => {
                dispatch(ValidateInterview());
                dispatch(closeSideBar());
              }}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default InterviewForm;
