import React from "react";
import Loading from "../../Components/Common/Loading";
import SimulateurCategoryList from "../../Components/Simulateur/SimulateurCategoryList";
import SimulateurQuestionList from "../../Components/Simulateur/SimulateurQuestionList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetCategories } from "../../REDUX/actions/form_category";
import { useState } from "react";

const Simulateur = ({
  isUpdate = false,
  isForm = false,
  prospect,
  prospectId,
}) => {
  const dispatch = useDispatch();
  const [validStep, setValidStep] = useState([]);
  const { loading, categories, selectedCategory } = useSelector(
    (state) => state.formCategoriesReducer
  );
  useEffect(() => {
    dispatch(GetCategories());
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          className="d-flex flex-row justify-content-between  "
          style={{
            height: isForm ? "calc(100vh - 160px)" : "calc(100vh - 120px)",
          }}
        >
          <div className="col-4 me-2 overflow-hidden h-100">
            <SimulateurCategoryList
              categories={categories}
              selectedCategory={selectedCategory}
              isForm={isForm}
              isUpdate={isUpdate}
              validStep={validStep}
            />
          </div>
          <div className="col-8 ms-2 overflow-hidden h-100 ">
            <SimulateurQuestionList
              selectedCategory={selectedCategory}
              isForm={isForm}
              prospect={prospect}
              isUpdate={isUpdate}
              prospectId={prospectId}
              validStep={validStep}
              setValidStep={setValidStep}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Simulateur;
