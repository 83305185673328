import { useDispatch } from "react-redux";
import { useRef } from "react";

import { Carousel } from "react-responsive-carousel";

import { ChevronLeft, ChevronRight, CloseIcon } from "./LightDarkSvg";
import PDFComponent from "./PDFComponent";

import { fileUrl } from "../../Utils/utils";

import { closeModal } from "../../REDUX/actions/modal";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./FilesCarousel.css";

const FilesCarousel = ({ firstFile, files }) => {
  const dispatch = useDispatch();

  const documentRef = useRef(null);

  files.forEach(function (item, i) {
    if (+item.id === +firstFile.id) {
      files.splice(i, 1);
      files.unshift(item);
    }
  });

  return (
    <div className="px-4 pt-4 radius05 pb-4 directionC h100">
      <div className="d-flex align-items-center justify-content-end mb-4">
        <CloseIcon clickCallback={() => dispatch(closeModal())} />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <Carousel
          showArrows
          showStatus={false}
          width={700}
          showThumbs={false}
          infiniteLoop
          useKeyboardArrows={true}
          renderArrowPrev={(clickHandler) => {
            return (
              <div
                onClick={clickHandler}
                className="d-flex align-items-center justify-content-center pointer carousel-left-arrow"
              >
                <ChevronLeft darkColor="white" />
              </div>
            );
          }}
          renderArrowNext={(clickHandler) => {
            return (
              <div
                onClick={clickHandler}
                className="d-flex align-items-center justify-content-center pointer carousel-right-arrow"
              >
                <ChevronRight darkColor="white" />
              </div>
            );
          }}
        >
          {files
            ?.filter(
              (file) =>
                file?.attributes?.file?.data?.attributes?.mime?.includes(
                  "image"
                ) ||
                file?.attributes?.file?.data?.attributes?.mime?.includes("pdf")
            )
            ?.map((file, index) =>
              file?.attributes?.file?.data?.attributes?.mime?.includes(
                "image"
              ) ? (
                <div key={index}>
                  <img
                    src={fileUrl(file?.attributes?.file?.data?.attributes?.url)}
                    className="w-100 h-100 ob-contain"
                    alt="event-file"
                  />
                </div>
              ) : (
                <PDFComponent
                  key={index}
                  pdfFile={fileUrl(
                    file?.attributes?.file?.data?.attributes?.url
                  )}
                  documentRef={documentRef}
                  filesCarousel
                />
              )
            )}
        </Carousel>
      </div>
    </div>
  );
};

export default FilesCarousel;
