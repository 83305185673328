import React, { useEffect, useState } from "react";
import "./Form.css";
import { useSelector } from "react-redux";
import LightDarkText from "../../../Common/LightDarkText";
const Switch = ({ label, totalInput, setValue, champs }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const [isOn, setIsOn] = useState(!totalInput[champs]);
  const toggleSwitch = () => {
    setIsOn((prevIsOn) => !prevIsOn);
    setValue({ ...totalInput, [champs]: isOn });
  };

  return (
    <div className="d-flex align-items-center">
      <label className="switch-container">
        <input type="checkbox" checked={!isOn} onChange={toggleSwitch} hidden />
        <span className={`switch-slider ${!isOn ? "off" : "on"}`}></span>
      </label>
      <LightDarkText
        className="text-center f12 bold ms-2"
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        {label}
      </LightDarkText>
    </div>
  );
};

export default Switch;
