import moment from "moment";
import {
  ADD_PROSPECT_FAILED,
  ADD_PROSPECT_SUCCESS,
  GET_PROSPECTS_FAILED,
  GET_PROSPECTS_SUCCESS,
  GET_PROSPECT_FAILED,
  GET_PROSPECT_SUCCESS,
  LOADING_ADD_PROSPECT,
  LOADING_PROSPECT,
  REMOVE_PROSPECT_SUCCESS,
  SET_PROSPECT,
  UNSET_PROSPECT,
  UPDATE_FIELDS_PROSPECT,
  UPDATE_PROSPECT_FAILED,
  UPDATE_PROSPECT_FIELD_SUCCESS,
  UPDATE_PROSPECT_SUCCESS,
} from "../ActionsType/prospectTypes";
import { transformGraphObject, transformObject } from "../../Utils/utils";

const initialState = {
  loadingProspect: false,
  loadingExport: false,
  loadingAdd: false,
  prospects: [],
  transformedArrays: [],
  error: undefined,
  selectedProspect: {},
  prospect: {},
};

const prospectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_PROSPECT:
      return {
        ...state,
        loadingProspect: true,
      };
    case "EXPORT_LOADING":
      return {
        ...state,
        loadingExport: true,
      };
    case "EXPORT_SUCCESS":
    case "EXPORT_FAILED":
      return {
        ...state,
        loadingExport: false,
      };

    case GET_PROSPECTS_SUCCESS:
      const transformedArray = payload.data.map((item) => {
        const transformedItem = {
          id: item?.id,
          origin: item?.attributes?.origin,
          status: item?.attributes?.status,
          date: moment(item?.attributes?.createdAt).format("DD.MM.YYYY"),
          note: item?.attributes?.note,
          archived: item?.attributes?.archived,
          actors: item.attributes?.acteurs?.data,
          actorsId: item.attributes?.acteurs?.data?.map((el) => {
            return +el?.id;
          }),
          actor:
            item.attributes?.acteurs &&
            item?.attributes?.status === "DRAFT_SELLER"
              ? item.attributes?.acteurs?.data[1]?.attributes?.workIn?.data[0]
                  ?.attributes?.name
              : item.attributes?.acteurs?.data[0]?.attributes?.workIn?.data[0]
                  ?.attributes?.name,
        };

        item?.attributes?.prospect_fields?.data.forEach((dataItem) => {
          const question = dataItem?.attributes?.question?.data;
          const accessor = question?.attributes?.accessor;
          if (accessor) {
            transformedItem[accessor] = dataItem?.attributes?.value;
          }
        });

        return transformedItem;
      });
      return {
        ...state,
        prospects:
          payload.page === 1
            ? payload.data
            : [...state.prospects, ...payload.data],
        transformedArrays:
          payload.page === 1
            ? transformedArray
            : [...state.transformedArrays, ...transformedArray],
        loadingProspect: false,
      };
    case GET_PROSPECTS_FAILED:
      return { ...state, loadingProspect: false, error: payload };

    case UPDATE_PROSPECT_SUCCESS:
      return {
        ...state,
        loadingProspect: false,
        prospects: state.prospects.map((prospect) =>
          +prospect.id === +payload.data.id ? payload.data : prospect
        ),
        transformedArrays: state.transformedArrays.map((el) =>
          +el.id === +payload.data.id
            ? payload.withGraph
              ? transformGraphObject(payload.data)
              : transformObject(payload.data)
            : el
        ),
        selectedProspect: payload.withGraph
          ? transformGraphObject(payload.data)
          : transformObject(payload.data),
      };

    case UPDATE_PROSPECT_FAILED:
      return { ...state, loadingProspect: false, error: payload };
    case SET_PROSPECT:
      return { ...state, selectedProspect: payload };
    case UNSET_PROSPECT:
      return { ...state, selectedProspect: {} };
    case REMOVE_PROSPECT_SUCCESS:
      return {
        ...state,
        loadingProspect: false,
        prospects: state.prospects.filter((item) => +item.id !== +payload),
        transformedArrays: state.transformedArrays.filter(
          (item) => +item.id !== +payload
        ),
      };

    case GET_PROSPECT_SUCCESS:
      const transformedObject = {};
      payload?.attributes?.prospect_fields?.data?.forEach((item) => {
        const questionId = item?.attributes?.question?.data?.id;
        const questionType =
          item?.attributes?.question?.data?.attributes?.input_type;
        if (
          [
            "text",
            "date",
            "number",
            "number_range",
            "boolean",
            "textarea",
            "departement",
          ].includes(questionType)
        ) {
          transformedObject[`question-${questionId}`] = item?.attributes?.value;
        } else if (["select", "radio"].includes(questionType)) {
          transformedObject[`question-${questionId}`] =
            item?.attributes?.form_answers?.data[0]?.attributes?.answer;
        } else if (["multi_select", "checkbox"].includes(questionType)) {
          const answers = item?.attributes?.form_answers?.data.map(
            (answerItem) => answerItem?.attributes?.answer
          );
          transformedObject[`question-${questionId}`] = answers;
        } else if (["file"].includes(questionType)) {
          transformedObject[`question-${questionId}`] =
            item?.attributes?.file?.data?.attributes?.name;
        }
      });
      return {
        ...state,
        loadingProspect: false,
        prospect: transformedObject,
      };
    case GET_PROSPECT_FAILED:
      return { ...state, loadingProspect: false, error: payload };

    case UPDATE_PROSPECT_FIELD_SUCCESS:
      const prospectToUpdate = state.prospects.find(
        (el) => +el.id === +payload.id
      );

      const newProspect =
        prospectToUpdate?.attributes?.prospect_fields?.data?.map((pros) =>
          +pros?.id === +payload?.newData?.id ? payload?.newData : pros
        );
      return {
        ...state,
        prospects: state.prospects.map((prospect) =>
          +prospect?.id === +newProspect?.id ? newProspect : prospect
        ),
      };

    case LOADING_ADD_PROSPECT:
      return { ...state, loadingAdd: true };
    case ADD_PROSPECT_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        prospects: [payload, ...state.prospects],
        transformedArrays: [
          transformGraphObject(payload),
          ...state.transformedArrays,
        ],
      };
    case ADD_PROSPECT_FAILED:
      return { ...state, loadingAdd: false };
    case UPDATE_FIELDS_PROSPECT:
      return {
        ...state,
        prospects: state.prospects.map((prospect) =>
          +prospect?.id === +payload?.id ? payload : prospect
        ),
        transformedArrays: state.transformedArrays.map((el) =>
          +el.id === +payload.id ? transformGraphObject(payload) : el
        ),
      };
    default:
      return state;
  }
};

export default prospectReducer;
