import { useSelector } from "react-redux";

import LightDarkInput from "../../Common/LightDarkInput";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";
import AttachementUploader from "../../Common/AttachementUploader";
import LightDarkTextEditor from "../../Common/LightDarkTextEditor";

const TicketInfosForm = ({ isNew, ticketInputs, setticketInputs }) => {
  const { ticketHandleLogic, ticketFields } = useSelector(
    (state) => state.organisationsReducer
  );
  const { isSupervisor } = useSelector((state) => state.roleReducer);
  const {
    departements,
    ticketTypesValues,
    societes,
    devicesPerPrestations,
    prestations,
    priorityValues,
  } = useSelector((state) => state.sharedDataReducer);

  let prestaionRelatedDevicesLabel = "";
  if (
    ticketInputs?.prestation[0]?.label === "Développement" ||
    ticketInputs?.prestation[0]?.label === "Design"
  ) {
    prestaionRelatedDevicesLabel = "Plateforme";
  } else {
    prestaionRelatedDevicesLabel = "Détail";
  }
  return (
    <div>
      <div className="d-flex gap-3 mt-3">
        <div className="d-flex flex-wrap flex-1 w-100">
          <div className="w50 px-1 my-2">
            <LightDarkInput
              label={"Titre du ticket"}
              placeholder="Titre du ticket"
              totalInputs={ticketInputs}
              setValue={setticketInputs}
              champs="name"
              required
            />
          </div>
          {isSupervisor || isNew ? (
            <div className="w50 px-1 my-2">
              <LighDarkMultiChoice
                label="Société"
                data={societes}
                totalInputs={ticketInputs}
                setValue={setticketInputs}
                champs="societe"
                multiple={false}
                required
                societySelectInput
              />
            </div>
          ) : null}
          <div className="w50 px-1 my-2">
            <LighDarkMultiChoice
              label="Priorité"
              data={priorityValues}
              totalInputs={ticketInputs}
              setValue={setticketInputs}
              multiple={false}
              champs="priority"
              required
            />
          </div>
          <div className="w50 px-1 my-2">
            <LighDarkMultiChoice
              label="Type"
              data={ticketTypesValues}
              totalInputs={ticketInputs}
              setValue={setticketInputs}
              multiple={false}
              champs="type"
              required
            />
          </div>
          {ticketHandleLogic === 2 ? (
            <div className={`w-100 px-1 my-2`}>
              <LighDarkMultiChoice
                label={"Départements"}
                data={departements}
                totalInputs={ticketInputs}
                setValue={setticketInputs}
                multiple={true}
                champs="departements"
                required
              />
            </div>
          ) : null}

          <div className={`w50 px-1 my-2`}>
            <LighDarkMultiChoice
              label={"Prestation"}
              data={prestations}
              totalInputs={ticketInputs}
              setValue={setticketInputs}
              multiple={false}
              champs="prestation"
              required
            />
          </div>

          <div className="w50 px-1 my-2">
            <LighDarkMultiChoice
              label={prestaionRelatedDevicesLabel}
              data={devicesPerPrestations}
              totalInputs={ticketInputs}
              setValue={setticketInputs}
              multiple={false}
              champs="device"
              required
            />
          </div>

          {ticketFields.map((field) => (
            <div
              className={`w-${field.attributes.width} px-1 mt-2`}
              key={field.id}
            >
              <LightDarkInput
                label={field.attributes.title}
                placeholder={field.attributes.placeHolder}
                totalInputs={ticketInputs}
                setValue={setticketInputs}
                champs={field.attributes.name}
                required={field.attributes.required}
              />
            </div>
          ))}
        </div>
        {isNew && (
          <div className="w30">
            <AttachementUploader
              flex1
              ticketUploaderHeight
              label={"Pièces jointes"}
            />
          </div>
        )}
      </div>
      <div className="mt-4">
        {isNew && (
          <div className="row mt-4">
            <div className="h50">
              <LightDarkTextEditor
                height={"60"}
                label={"Description"}
                required
                totalInputs={ticketInputs}
                setValue={setticketInputs}
                champs="description"
                value={ticketInputs}
                reformulateText
                autoHeight
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketInfosForm;
