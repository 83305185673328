import React, { useEffect } from "react";
import "./ArtefactoView.css";
function ArtefactoView() {
  useEffect(() => {
    if (window !== undefined) {
      var container = document.querySelector("#unity-container");
      var canvas = document.querySelector("#unity-canvas");
      var loadingBar = document.querySelector("#unity-loading-bar");
      var progressBarFull = document.querySelector("#unity-progress-bar-full");
      var warningBanner = document.querySelector("#unity-warning");

      // Shows a temporary message banner/ribbon for a few seconds, or
      // a permanent error message on top of the canvas if type=='error'.
      // If type=='warning', a yellow highlight color is used.
      // Modify or remove this function to customize the visually presented
      // way that non-critical warnings and error messages are presented to the
      // user.
      function unityShowBanner(msg, type) {
        function updateBannerVisibility() {
          warningBanner.style.display = warningBanner.children.length
            ? "block"
            : "none";
        }
        var div = document.createElement("div");
        div.innerHTML = msg;
        warningBanner.appendChild(div);
        if (type == "error") div.style = "background: red; padding: 10px;";
        else {
          if (type == "warning")
            div.style = "background: yellow; padding: 10px;";
          setTimeout(function () {
            warningBanner.removeChild(div);
            updateBannerVisibility();
          }, 5000);
        }
        updateBannerVisibility();
      }

      var buildUrl = "/Build";
      var loaderUrl = buildUrl + "/WebGL(no_comp).loader.js";
      var config = {
        dataUrl: buildUrl + "/8b420083a07de09d91ef521e46dda4c7.data",
        frameworkUrl: buildUrl + "/525f0f0820597c041d3d8cee793ffedb.js",
        codeUrl: buildUrl + "/429d773eb5733b214a600d11bdca7493.wasm",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "Artefacto",
        productName: "Éléance",
        productVersion: "2.0",
        showBanner: unityShowBanner,
      };

      // By default Unity keeps WebGL canvas render target size matched with
      // the DOM size of the canvas element (scaled by window.devicePixelRatio)
      // Set this to false if you want to decouple this synchronization from
      // happening inside the engine, and you would instead like to size up
      // the canvas DOM size and WebGL render target sizes yourself.
      // config.matchWebGLToCanvasSize = false;

      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        container.className = "unity-mobile";
        // Avoid draining fillrate performance on mobile devices,
        // and default/override low DPI mode on mobile browsers.
        config.devicePixelRatio = 1;
        unityShowBanner("WebGL builds are not supported on mobile devices.");
      } else {
        //canvas.style.width = "960px";
        //canvas.style.height = "600px";
      }

      loadingBar.style.display = "block";

      var script = document.createElement("script");
      script.src = loaderUrl;
      script.onload = () => {
        fetch(buildUrl + "/path.json").then((response) => {
          response.json().then((configJson) => {
            config.dataUrl = `${buildUrl}/${configJson.data}`;
            config.frameworkUrl = `${buildUrl}/${configJson.framework}`;
            config.codeUrl = `${buildUrl}/${configJson.code}`;

            window
              .createUnityInstance(canvas, config, (progress) => {
                progressBarFull.style.width = 100 * progress + "%";
              })
              .then((unityInstance) => {
                window.gameInstance = unityInstance;

                loadingBar.style.display = "none";
              })
              .catch((message) => {
                alert(message);
              });
          });
        });
      };

      document.body.appendChild(script);
    }
  }, []);

  return (
    <div className="h100 w100 radius10">
      <div id="app-container" className="h100 w100 radius10">
        <div id="unity-container" className="unity-desktop radius10">
          <canvas id="unity-canvas"></canvas>
          <div id="unity-loading-bar">
            <div id="unity-loading-container">
              <div id="eleance-logo"></div>
              <p id="unity-loading-label">Chargement du configurateur 3D</p>
              <div id="unity-progress-bar-empty">
                <div id="unity-progress-bar-full"></div>
              </div>
            </div>
          </div>
          <div id="unity-warning"></div>
        </div>
      </div>
      {/* <Unity
        style={{ width: "100%", height: "100%" }}
        unityProvider={unityProvider}
      /> */}
      <div
        id="fileBrowserPopup"
        style={{
          display: "none",
          margin: "-0.4%",
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <img
          src=""
          style={{ position: "absolute", width: "100%", height: "100%" }}
        />

        <div id="fb_popup">
          <img
            id="fb_popup_background"
            src=""
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              margin: "auto",
              width: "502.5px",
              height: "206px",
            }}
          />
          <div>
            <img
              src=""
              style={{
                position: "fixed",
                top: "-146px",
                right: 0,
                bottom: 0,
                left: 0,
                margin: "auto",
                width: "502.5px",
                height: "60.5px",
              }}
            />
            <strong
              id="fb_popup_header_title"
              style={{
                position: "fixed",
                top: "-110px",
                right: "-20px",
                bottom: 0,
                left: 0,
                margin: "auto",
                width: "500px",
                height: "58px",
                color: "white",
              }}
            >
              File Browser
            </strong>
          </div>
          <div id="open_file_form">
            <strong
              id="fb_popup_description_title"
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: 0,
                left: 0,
                margin: "auto",
                width: "500px",
                height: "58px",
                textAlign: "center",
                color: "black",
              }}
            >
              Select file to load or use drag & drop
            </strong>

            <label htmlFor="fileToUpload">
              <img
                src=""
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "250px",
                  bottom: "-80px",
                  left: 0,
                  margin: "auto",
                  width: "193.5px",
                  height: "41px",
                }}
              />
              <strong
                id="fb_popup_select_button_title"
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "250px",
                  bottom: "-100px",
                  left: 0,
                  margin: "auto",
                  width: "193.5px",
                  height: "41px",
                  textAlign: "center",
                  color: "white",
                }}
              >
                Select
              </strong>
            </label>
            <input
              type="file"
              multiple
              name="fileToUpload"
              id="fileToUpload"
              style={{ width: "0px", height: "0px" }}
              onChange={(event) => {
                window.fbLoadFiles(event.target.files);
                return false;
              }}
            />

            <label htmlFor="closePopup">
              <img
                src=""
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "-250px",
                  bottom: "-80px",
                  left: 0,
                  margin: "auto",
                  width: "193.5px",
                  height: "41px",
                }}
              />
              <strong
                id="fb_popup_close_button_title"
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "-245px",
                  bottom: "-100px",
                  left: 0,
                  margin: "auto",
                  width: "193.5px",
                  height: "41px",
                  textAlign: "center",
                  color: "white",
                }}
              >
                Close
              </strong>
            </label>
            <input
              type="button"
              name="closePopup"
              id="closePopup"
              style={{ width: "0px", height: "0px" }}
              onClick={() => window.requestCloseFileBrowserForOpen()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArtefactoView;
