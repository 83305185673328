import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkText from "../../Common/LightDarkText";

import { imageLoader } from "../../../Utils/utils";

const TicketDetailIcon = ({ icon, name }) => {
  const { ticketHandleLogic } = useSelector(
    (state) => state.organisationsReducer
  );
  return (
    ticketHandleLogic === 1 && (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            <LightDarkText className="custom-ticket-font-size py-1 text-white">
              {name}
            </LightDarkText>
          </Tooltip>
        }
      >
        <img src={imageLoader(icon)} />
      </OverlayTrigger>
    )
  );
};

export default TicketDetailIcon;
