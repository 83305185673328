import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";

const EventDescription = ({ description, eventPopUp }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {description ? (
        <LightDarkText
          insertHtml={description}
          color={mode === "light" ? "text-blue-grey" : "text-white"}
          className={`${
            eventPopUp ? "mt-3 text-ellipsis" : ""
          }  bold custom-ticket-font-size text-start p-nomb`}
          style={{
            textWrap: "wrap",
            whiteSpace: "normal",
            height: eventPopUp && "35px",
          }}
        />
      ) : (
        !eventPopUp && (
          <LightDarkText
            className="text-start mt-3 bold custom-ticket-font-size"
            color={mode === "light" ? "text-light-text" : "text-white"}
          >
            Pas de description
          </LightDarkText>
        )
      )}
    </>
  );
};

export default EventDescription;
