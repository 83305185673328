import React, { useState } from "react";
import PieChart from "../Charts/PieChart";
import {
  transformDataToMultiLines,
  transformticketsToStatsPie,
} from "../Charts/chartsUtils";
import MultipleLineChart from "../Charts/MultipleLineChart";
import LightDarkContainer from "../Common/LightDarkContainer";
import { useSelector } from "react-redux";
import LightDarkText from "../Common/LightDarkText";

const TicketLineStats = ({ stats }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const [isToggle, setIsToggle] = useState("Types");

  const openedTicketIdsPerDevice = transformticketsToStatsPie(
    stats?.ticketStats?.ticketByDevice
  );
  const openedTicketIdsPerPrestation = transformticketsToStatsPie(
    stats?.ticketStats?.ticketsByPrestation
  );

  const ticketsPerType = transformticketsToStatsPie(
    stats?.ticketStats?.ticketsByType
  );

  const openedTickets = transformDataToMultiLines(
    stats?.ticketStats?.openedTicket,
    "#3365FF",
    "Créés"
  );
  const resolvedTickets = transformDataToMultiLines(
    stats?.ticketStats?.resolvedTickets,
    "#0CCE91",
    "Résolus"
  );
  const unresolvedTicket = transformDataToMultiLines(
    stats?.ticketStats?.unresolvedTicket,
    "#FFBC20",
    "En cours"
  );

  return (
    <div className={`statisticsLine alignH gap-2`}>
      <div className={`w70 h100 `}>
        <MultipleLineChart
          title="Tickets ouverts"
          dataSets={[openedTickets, resolvedTickets, unresolvedTicket]}
          customToolTip={{
            callbacks: {
              label: function (context) {
                const value = context.raw;
                return ` ${value} tickets`;
              },
            },
          }}
        />
      </div>

      <LightDarkContainer
        className="w30 h100 d-flex flex-column justify-content-between radius10 px-2"
        colorLight="white"
        colorDark="dark-grey-bg"
      >
        {isToggle === "Prestations" && (
          <PieChart
            title={"Répartition des tickets ouverts par prestations"}
            infos={openedTicketIdsPerPrestation}
          />
        )}
        {isToggle === "Device" && (
          <PieChart
            title={"Répartition des tickets ouverts par détails"}
            infos={openedTicketIdsPerDevice}
            width="30"
          />
        )}
        {isToggle === "Types" && (
          <PieChart
            title={"Répartition des tickets ouverts par type"}
            infos={ticketsPerType}
            width="30"
          />
        )}

        <div className="d-flex justify-content-start align-items-start">
          <LightDarkText
            className="text-start custom-font-size bold pointer px-2"
            color={
              isToggle === "Types"
                ? "text-light-blue"
                : mode === "light"
                ? "text-blue-grey"
                : "text-light-blue-grey"
            }
            onclickCallback={() => setIsToggle("Types")}
          >
            Types
            {isToggle === "Types" && (
              <div
                className="radius05"
                style={{
                  marginTop: "10px",
                  borderBottom: "4px solid #3365ff",
                  position: "relative",
                  top: 1,
                }}
              ></div>
            )}
          </LightDarkText>{" "}
          <LightDarkText
            className={`text-start bold custom-font-size px-2 pointer`}
            color={
              isToggle === "Prestations"
                ? "text-light-blue"
                : mode === "light"
                ? "text-blue-grey"
                : "text-light-blue-grey"
            }
            onclickCallback={() => setIsToggle("Prestations")}
          >
            Prestations
            {isToggle === "Prestations" && (
              <div
                className="radius05"
                style={{
                  marginTop: "10px",
                  borderBottom: "4px solid #3365ff",
                  position: "relative",
                  top: 1,
                }}
              ></div>
            )}
          </LightDarkText>
          <LightDarkText
            className="text-start custom-font-size bold pointer px-2"
            color={
              isToggle === "Device"
                ? "text-light-blue"
                : mode === "light"
                ? "text-blue-grey"
                : "text-light-blue-grey"
            }
            onclickCallback={() => setIsToggle("Device")}
          >
            Détails
            {isToggle === "Device" && (
              <div
                className="radius05"
                style={{
                  marginTop: "10px",
                  borderBottom: "4px solid #3365ff",
                  position: "relative",
                  top: 1,
                }}
              ></div>
            )}
          </LightDarkText>
        </div>
      </LightDarkContainer>
    </div>
  );
};

export default TicketLineStats;
