import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSocietyOperators } from "../../../../REDUX/actions/societe";
import UsersSquareListPlaceHolder from "../../../PlaceHolders/Users/UsersSquareListPlaceHolder";
import UsersSquareList from "../../../Users/UsersSquareList";

const SocietyOperators = ({ search }) => {
  const dispatch = useDispatch();

  const { SocietyOperators, societyItemLoading, societe } = useSelector(
    (state) => state.societesReducer
  );
  const { operateursArchived } = useSelector((state) => state.filtersReducer);

  useEffect(() => {
    if (societe) {
      dispatch(getSocietyOperators(false));
    }
  }, [operateursArchived, societe]);

  const [filteredUsers, setFilteredUsers] = useState(SocietyOperators);

  useEffect(() => {
    setFilteredUsers(
      SocietyOperators.filter(
        (el) =>
          el.attributes.user.data.attributes.firstName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          el.attributes.user.data.attributes.lastName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          el.attributes.user.data.id?.toString()?.includes(search) ||
          el.attributes.user.data.attributes.email
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          el.attributes.user.data.attributes.phoneNumber
            ?.toLowerCase()
            .includes(search.toLowerCase())
      )
    );
  }, [search, SocietyOperators, SocietyOperators.length, societe]);

  return societyItemLoading ? (
    <UsersSquareListPlaceHolder />
  ) : (
    <UsersSquareList listOfUserOrgs={filteredUsers} type="operator" />
  );
};

export default SocietyOperators;
