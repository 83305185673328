export const CheckIcon = ({ clickCallback, disabled }) => {
  const fill = disabled ? "#A9BCD3" : "#354074";
  return (
    <div onClick={() => !disabled && clickCallback && clickCallback()}>
      <svg
        width="12"
        height="9"
        viewBox="0 0 12 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 4.5L3.6092 7.23965C4.00328 7.65345 4.66338 7.65345 5.05747 7.23966L11 1"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};
