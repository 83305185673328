import React from "react";
import LightDarkContainer from "../../../Common/LightDarkContainer";
import LightDarkPlaceHolder from "../../../PlaceHolders/LightDarkPlaceHolder";

const EventPlaceHolder = () => {
  return (
    <LightDarkContainer
      colorLight="white"
      className=" my-2 p-3 d-flex flex-column gap-4 scrollable"
    >
      <LightDarkPlaceHolder height={20} width={"30%"} />
      <LightDarkPlaceHolder height={20} width={"50%"} />
      <LightDarkPlaceHolder height={20} width={"50%"} />
      <LightDarkPlaceHolder height={20} width={"50%"} />
      <LightDarkPlaceHolder height={20} width={"50%"} />
      <LightDarkPlaceHolder height={20} width={"50%"} />
      <LightDarkPlaceHolder height={0} width={"50%"} />
      <LightDarkPlaceHolder height={0} width={"50%"} />
      <LightDarkPlaceHolder height={0} width={"50%"} />
      <LightDarkPlaceHolder height={20} width={"50%"} />
      <LightDarkPlaceHolder height={20} width={"30%"} />
      <LightDarkPlaceHolder height={20} width={"50%"} />
      <LightDarkPlaceHolder height={20} width={"50%"} />
      <LightDarkPlaceHolder height={20} width={"50%"} />
      <LightDarkPlaceHolder height={20} width={"50%"} />
      <LightDarkPlaceHolder height={0} width={"50%"} />
      <LightDarkPlaceHolder height={0} width={"50%"} />
      <LightDarkPlaceHolder height={0} width={"50%"} />
      <LightDarkPlaceHolder height={20} width={"50%"} />
    </LightDarkContainer>
  );
};

export default EventPlaceHolder;
