import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getOneDriveToken } from "../../REDUX/actions/externalServices";
import Loading from "../../Components/Common/Loading";

const ExternalSyncView = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const codeParam = searchParams.get("code");
    const serviceParam = searchParams.get("service") || "onedrive";

    if (codeParam) {
      dispatch(getOneDriveToken(codeParam, serviceParam));
    }
  }, [location.search]);

  return (
    <div>
      <Loading showLoader />
    </div>
  );
};

export default ExternalSyncView;
