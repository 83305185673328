import React from "react";
import EventCalendar from "../EventCommonComponents/EventCalendar";
import EventTitle from "../EventCommonComponents/EventTitle";
import EventDate from "../EventCommonComponents/EventDate";
import EventType from "../EventCommonComponents/EventType";
import AvatarStack from "../EventCommonComponents/AvatarStack";

const SearchDropDownElement = ({ event, eventClick }) => {
  return (
    <div
      key={event?.id}
      className="px-3 radius05 d-flex justify-content-between align-items-center pointer"
      style={{
        backgroundColor: `${event?.attributes?.color}`,
        height: "56px",
      }}
      onClick={() => eventClick(event)}
    >
      <div className="d-flex justify-content-between align-items-center gap-3">
        <EventCalendar
          calendar={event?.attributes?.calendar?.data}
          eventSearch
        />
        <div>
          <EventTitle title={event?.attributes?.name} eventMainCard />
          <EventDate
            color={"white"}
            withIcons={false}
            dates={{
              start: `${event.attributes.startDate}T${event.attributes.startTime}`,
              end: `${event.attributes.endDate}T${event.attributes.endTime}`,
            }}
          />
        </div>
      </div>
      <div className="d-flex align-items-center gap-3">
        <EventType type={event?.attributes?.type} eventSearchCard />
        <AvatarStack
          mainEventCard
          eventSearch
          border={event?.attributes?.color}
          calendar={event?.attributes?.calendar?.data}
          participants={event?.attributes?.participants?.data}
        />
      </div>
    </div>
  );
};

export default SearchDropDownElement;
