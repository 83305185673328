import { imageEndPoint } from "../../Api/endPoint";

const initialState = {
  loginLogo: "",
  loginImages: false,
  loginImageSelected: "",
  loginDataLoading: false,
};

const loginViewDataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOGIN_VIEW_DATA_FETCH":
      const loginImages = payload.loginImages.map(
        (el) => `${imageEndPoint}${el.url}`
      );
      return {
        ...state,
        loginDataLoading: false,
        loginImages: loginImages,
        loginImageSelected:
          loginImages[Math.floor(Math.random() * loginImages.length)],
        loginLogo: `${imageEndPoint}${payload.loginLogo.url}`,
      };
    case "LOGIN_VIEW_DATA_LOADING":
      return {
        ...state,
        loginDataLoading: true,
      };
    case "LOGOUT":
      return {
        ...state,
        loginLogo: "",
        loginImages: false,
        loginImageSelected: "",
        loginDataLoading: false,
      };
    case "LOGIN_VIEW_DATA_ERROR":
      const imgMap = [
        "1-Agilix.webp",
        "2-Agilix.webp",
        "3-Agilix.webp",
        "4-Agilix.webp",
        "5-Agilix.webp",
        "6-Agilix.webp",
        "7-Agilix.webp",
        "8-Agilix.webp",
        "9-Agilix.webp",
        "10-Agilix.webp",
        "11-Agilix.webp",
        "12-Agilix.webp",
        "13-Agilix.webp",
        "14-Agilix.webp",
      ];

      const random = imgMap[Math.floor(Math.random() * imgMap.length)];
      return {
        ...state,
        loginLogo: require("../../Images/agilix-logo-nom.png"),
        loginImageSelected: require(`../../Images/loginImages/${random}`),
        loginDataLoading: false,
      };
    default: {
      return state;
    }
  }
};

export default loginViewDataReducer;
