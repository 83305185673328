import { gql } from "@apollo/client";
import { ATTRIBUTES_PROSPECT } from "../Attributes/Prospect";
export const GET_PROSPECTS = gql`
  query ($status:[String],$archived:Boolean) {
    prospects (

      filters: {
        and : 
        [
       { status: { in: $status }},
        {archived : { eq: $archived } }
      ]
      }
    ){
      data {
        id
        attributes {
          ${ATTRIBUTES_PROSPECT}
        }
      }
    }
  }
`;
export const GET_PROSPECT_BY_ID = (id) => gql`
  query {
    prospects(filters: { id: { eq: ${id} } }) {
      data {
        id
        attributes {
          ${ATTRIBUTES_PROSPECT}
        }
      }
    }
  }
`;
