import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LightDarkText from "../../Common/LightDarkText";
import {
  FavoritesIcon,
  FileIcon,
  InterventionsIcon,
  LinksIcon,
  ParticipantsIcon,
  CalendarIcon,
  QuestionMessageIcon,
} from "../../Common/LightDarkSvg";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { handleAllNotifications } from "../../../REDUX/actions/notifications";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NotificationsRedPoint from "../../Common/MenuIcons/NotificationsRedPoint";

const RightPanelMenu = ({ setIsToggle, isToggle, ticket }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { showPrivateMessages } = useSelector((state) => state.messagesReducer);

  const privateLinks = ticket?.attributes?.message_links?.data?.filter(
    (link) => link?.attributes?.message?.data?.attributes?.is_internal
  );

  const publicLinks = ticket?.attributes?.message_links?.data?.filter(
    (link) => !link?.attributes?.message?.data?.attributes?.is_internal
  );

  const privateFavorites = ticket?.attributes?.message_favorites?.data?.filter(
    (el) => el?.attributes?.message?.data?.attributes?.is_internal
  );
  const publicFavorites = ticket?.attributes?.message_favorites?.data?.filter(
    (el) => !el?.attributes?.message?.data?.attributes?.is_internal
  );

  const selectedTabColor = (tabName) => ({
    color:
      isToggle === tabName
        ? showPrivateMessages
          ? "#ff7f7f"
          : "#3365ff"
        : mode === "light"
        ? "#607d8b"
        : "#e0e0e0",
  });

  const renderTooltip = (content) => (
    <Tooltip style={{ zIndex: 1050 }}>
      <LightDarkText className="custom-ticket-font-size py-1 text-white">
        {content}
      </LightDarkText>
    </Tooltip>
  );

  return (
    <div style={{ overflowX: "scroll" }}>
      <div
        className={`alignH justify-content-between gap-3 ${
          screenWidth < 1200 ? "" : "mx-3"
        }`}
      >
        <OverlayTrigger placement="top" overlay={renderTooltip("Fichiers")}>
          <div
            style={selectedTabColor("Fichiers")}
            onClick={() => setIsToggle("Fichiers")}
            className="p-2 position-relative tabStyle"
          >
            <FileIcon />
            {isToggle === "Fichiers" && (
              <div
                className={`${
                  showPrivateMessages ? "redUnderlineIcon" : "underlineIcon"
                } radius05 t-02`}
              />
            )}
          </div>
        </OverlayTrigger>

        <OverlayTrigger placement="top" overlay={renderTooltip("Participants")}>
          <div
            style={selectedTabColor("Participants")}
            onClick={() => setIsToggle("Participants")}
            className="p-2 position-relative tabStyle"
          >
            <ParticipantsIcon />
            {isToggle === "Participants" && (
              <div
                className={`${
                  showPrivateMessages ? "redUnderlineIcon" : "underlineIcon"
                } radius05 t-02`}
              />
            )}
          </div>
        </OverlayTrigger>

        {!showPrivateMessages && (
          <OverlayTrigger
            placement="top"
            overlay={renderTooltip("Interventions")}
          >
            <div
              style={selectedTabColor("Interventions")}
              onClick={() => {
                setIsToggle("Interventions");
                dispatch(
                  handleAllNotifications(true, "interventions", ticket.id)
                );
              }}
              className="p-2 position-relative tabStyle"
            >
              <InterventionsIcon />
              {ticket.attributes.interventionNotifications && (
                <NotificationsRedPoint />
              )}
              {isToggle === "Interventions" && (
                <div
                  className={`${
                    showPrivateMessages ? "redUnderlineIcon" : "underlineIcon"
                  } radius05 t-02`}
                />
              )}
            </div>
          </OverlayTrigger>
        )}

        {((showPrivateMessages && privateLinks?.length > 0) ||
          (!showPrivateMessages && publicLinks?.length > 0)) && (
          <OverlayTrigger placement="top" overlay={renderTooltip("Liens")}>
            <div
              style={selectedTabColor("Links")}
              onClick={() => setIsToggle("Links")}
              className="p-2 position-relative tabStyle"
            >
              <LinksIcon />
              {isToggle === "Links" && (
                <div
                  className={`${
                    showPrivateMessages ? "redUnderlineIcon" : "underlineIcon"
                  } radius05 t-02`}
                />
              )}
            </div>
          </OverlayTrigger>
        )}

        {((showPrivateMessages && privateFavorites?.length > 0) ||
          (!showPrivateMessages && publicFavorites?.length > 0)) && (
          <OverlayTrigger placement="top" overlay={renderTooltip("Favoris")}>
            <div
              style={selectedTabColor("Favorites")}
              onClick={() => setIsToggle("Favorites")}
              className={`className="p-2 position-relative tabStyle`}
            >
              <FavoritesIcon />
              {isToggle === "Favorites" && (
                <div
                  className={`${
                    showPrivateMessages ? "redUnderlineIcon" : "underlineIcon"
                  } radius05 t-02`}
                />
              )}
            </div>
          </OverlayTrigger>
        )}
        {ticket.attributes.events.data.length ? (
          <OverlayTrigger placement="top" overlay={renderTooltip("Événements")}>
            <div
              style={selectedTabColor("Events")}
              onClick={() => setIsToggle("Events")}
              className="p-2 position-relative tabStyle"
            >
              <CalendarIcon />
              {isToggle === "Events" && (
                <div
                  className={`${
                    showPrivateMessages ? "redUnderlineIcon" : "underlineIcon"
                  } radius05 t-02`}
                />
              )}
            </div>
          </OverlayTrigger>
        ) : null}
        {ticket.attributes.interview_models.data.length ? (
          <OverlayTrigger placement="top" overlay={renderTooltip("Sondages")}>
            <div
              style={selectedTabColor("Interviews")}
              onClick={() => setIsToggle("Interviews")}
              className="p-2 position-relative tabStyle"
            >
              <QuestionMessageIcon />
              {isToggle === "Interviews" && (
                <div
                  className={`${
                    showPrivateMessages ? "redUnderlineIcon" : "underlineIcon"
                  } radius05 t-02`}
                />
              )}
            </div>
          </OverlayTrigger>
        ) : null}
      </div>
      <div
        className="mt-1 mb-3 mx-2"
        style={{
          borderBottom: `2px solid ${
            mode === "light"
              ? showPrivateMessages
                ? "#fee2e2"
                : "#F8F9FF"
              : showPrivateMessages
              ? "#fee2e2"
              : "#252C45"
          }`,
        }}
      ></div>
    </div>
  );
};

export default RightPanelMenu;
