import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { generateProspectData, handleUpdateStatus } from "../../Utils/utils";
import LightDarkText from "../Common/LightDarkText";
import ColoredButton from "../Common/Buttons/ColoredButton";
import ProspectItem from "../Prospect/ProspectItem";

import { GetProspectById, updateStatus } from "../../REDUX/actions/prospect";
import { openModal } from "../../REDUX/actions/modal";
import { useNavigate } from "react-router-dom";
import NoteForm from "../Prospect/NoteForm";
import { useEffect } from "react";
import { tableCommandeListData } from "../../Utils/tableStaticData";

const CommandeSideBar = ({ commande }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const navigate = useNavigate();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { role } = useSelector((state) => state.roleReducer);
  const { commandesRoute } = useSelector((state) => state.organisationsReducer);

  const { commandes, selectedCommande } = useSelector(
    (state) => state.commandeReducer
  );

  useEffect(() => {
    dispatch(GetProspectById(commande));
  }, [commande]);
  const choosenProspect = commandes?.find((el) => el.id === commande);
  return (
    <div
      className="d-flex flex-column justify-content-between  sideChatListContainer"
      style={{ height: "95%" }}
    >
      <div
        className="mx-2 showScroll"
        style={{
          height:
            selectedCommande?.status === "KO"
              ? "100%"
              : selectedCommande?.status !== "VALID" && role === 1
              ? "75%"
              : "90%",
          paddingBottom: "10px",
          marginBottom: "5px",
          overflow: "auto",
        }}
      >
        {tableCommandeListData.map(
          (column) =>
            column.accessor &&
            selectedCommande[column.accessor] && (
              <div key={column.id} className="mt-3">
                <LightDarkText
                  className={`text-start bold py-1 ${
                    screenWidth <= 1400 ? "f10" : "custom-ticket-font-size"
                  }`}
                  color={mode === "light" ? "text-light-text" : "text-white"}
                >
                  {column.name}
                </LightDarkText>
                <ProspectItem
                  dataItem={selectedCommande}
                  accessor={column.accessor}
                />
              </div>
            )
        )}
        {selectedCommande?.note && (
          <div className="mt-3 ">
            <LightDarkText
              className={`text-start bold py-1 ${
                screenWidth <= 1400 ? "f10" : "custom-ticket-font-size"
              }`}
              color={mode === "light" ? "text-light-text" : "text-white"}
            >
              Note
            </LightDarkText>
            <LightDarkText
              className={`pointer  custom-ticket-font-size bold text-start text-wrap `}
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
            >
              {selectedCommande?.note}
            </LightDarkText>
          </div>
        )}
        {generateProspectData(choosenProspect)?.map(
          ({ id, question, answer, link }) => {
            return (
              <div key={id} className="mt-3">
                <LightDarkText
                  className={`text-start bold py-1 ${
                    screenWidth <= 1400 ? "f10" : "custom-ticket-font-size"
                  }`}
                  color={mode === "light" ? "text-light-text" : "text-white"}
                >
                  {question}
                </LightDarkText>
                <LightDarkText
                  className={`  custom-ticket-font-size bold text-start text-wrap `}
                  color={
                    mode === "light" ? "text-blue-grey" : "text-light-text"
                  }
                >
                  {link ? (
                    <a
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-oneDriveBlue text-decoration-underline"
                    >
                      {answer}
                    </a>
                  ) : (
                    answer
                  )}
                </LightDarkText>
              </div>
            );
          }
        )}
      </div>
      {["VALID", "TO_VALIDATE"].includes(selectedCommande?.status) && (
        <div
          className={` ${
            selectedCommande?.status === "VALID"
              ? "h10"
              : role === 1
              ? "h20"
              : "h10"
          }  w-100 d-flex flex-column justify-content-end `}
        >
          {selectedCommande?.status !== "VALID" && role === 1 && (
            <div className="col-12">
              <ColoredButton
                textButton={"Valider le bon de commande"}
                color="green"
                callback={() => {
                  dispatch(
                    updateStatus(selectedCommande?.id, {
                      status: handleUpdateStatus(selectedCommande?.status)
                        ?.newStatus,
                    })
                  );
                }}
              />
            </div>
          )}
          <div className="mt-3 mx-1 ms-2 d-flex">
            <div
              className={` ${
                selectedCommande?.status !== "VALID" ? "col-4" : "col-12"
              }`}
            >
              <ColoredButton
                textButton={
                  selectedCommande?.archived ? "Désarchiver" : "Archiver"
                }
                outlined
                color="blue-grey"
                callback={() => {
                  dispatch(
                    openModal({
                      type: "small",
                      content: (
                        <NoteForm
                          id={selectedCommande.id}
                          fullName={
                            selectedCommande?.firstname +
                            " " +
                            selectedCommande?.lastname
                          }
                          oldNote={selectedCommande?.note}
                          type={"commande"}
                          isArchive={true}
                        />
                      ),
                    })
                  );
                }}
              />
            </div>
            {selectedCommande?.status !== "VALID" && (
              <div className="col-8">
                <ColoredButton
                  textButton={"Modifier les informations"}
                  color="light-blue"
                  callback={() => {
                    navigate(`${commandesRoute}/${selectedCommande?.id}`);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CommandeSideBar;
