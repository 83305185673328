import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LightDarkText from "../Common/LightDarkText";
import { useState } from "react";

const RadioInput = ({
  data,
  label,
  required,
  bgWhite,
  champs,
  totalInputs,
  setValue,
  col,
  legend,
  extraCss,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const [selectedRadioValue, setSelectedRadioValue] = useState(null);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setSelectedRadioValue(newValue);
    setValue({ ...totalInputs, [champs]: newValue });
  };

  useEffect(() => {
    if (totalInputs[champs]) {
      setSelectedRadioValue(totalInputs[champs]);
    }
  }, [totalInputs]);
  return (
    <div className={`w-100 `}>
      <LightDarkText
        className={`text-start bold  custom-ticket-font-size mb-2 ${extraCss}`}
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        {label} {required ? <span className="text-red">*</span> : null}
        <div></div>
      </LightDarkText>
      {legend && (
        <LightDarkText
          className={"ms-2 f12 text-start"}
          color={mode === "light" ? "text-blue-grey" : "text-light-text"}
        >
          {legend}
        </LightDarkText>
      )}
      <div
        className={`${col === 4 ? "" : "row"} p-2 radius05   ${
          mode === "light" ? "lightInput" : "darkInput"
        } ${bgWhite && mode === "light" && "bg-white"}`}
      >
        {data?.map((item, index) => {
          const isChecked = item.value === selectedRadioValue;

          return (
            <div
              className="col d-flex my-1 gap-2 align-items-center w-100"
              style={{ minWidth: "150px" }}
              key={index}
            >
              <div style={{ minWidth: "30px" }} className="d-flex">
                <input
                  type="radio"
                  id={item?.value}
                  name={item?.value}
                  value={item?.value}
                  onChange={handleChange}
                  checked={isChecked}
                />
              </div>
              <LightDarkText
                className={"text-start  bold f12  "}
                color={mode === "light" ? "text-dark-blue" : "text-light-text"}
              >
                {item?.label}
              </LightDarkText>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioInput;
