import React, { useState } from "react";
import { useSelector } from "react-redux";
import LightDarkText from "../Common/LightDarkText";
import { useEffect } from "react";

const CheckBoxInput = ({
  data,
  label,
  required,
  totalInputs,
  setValue,
  champs,
  bgWhite,
  col,
  legend,
  extraCss,
}) => {
  const [selected, setSelected] = useState([]);

  const { mode } = useSelector((state) => state.genearlReducer);
  const handleCheckboxChange = (event, index) => {
    const value = event.target.value;
    let newSelected;
    if (selected?.includes(value)) {
      newSelected = selected.filter((item) => item !== value);
      setSelected(newSelected);
    } else {
      newSelected = [...selected, value];
      setSelected([...selected, value]);
    }
    setValue({ ...totalInputs, [champs]: newSelected });
  };
  useEffect(() => {
    if (totalInputs[champs]) {
      setSelected(totalInputs[champs]);
    }
  }, [totalInputs, champs]);

  return (
    <div className="w-100 ">
      <LightDarkText
        className={`text-start bold custom-ticket-font-size mb-2 ${extraCss}`}
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        {label} {required ? <span className="text-red">*</span> : null}
        <div></div>
      </LightDarkText>
      {legend && (
        <LightDarkText
          className={"ms-2 f12 text-start"}
          color={mode === "light" ? "text-blue-grey" : "text-light-text"}
        >
          {legend}
        </LightDarkText>
      )}
      <div
        className={`${col === 4 ? "" : "d-flex"} p-2 radius05   ${
          mode === "light" ? "lightInput" : "darkInput"
        } ${bgWhite && mode === "light" && "bg-white"}`}
      >
        {data?.map((item, index) => {
          const isChecked = selected?.includes(item?.value);
          return (
            <div
              className={`col d-flex my-1 align-items-center ${
                item.checked ? "checked" : ""
              }`}
              key={index}
            >
              <input
                type="checkbox"
                id={item?.value}
                name={item?.value}
                value={item?.value}
                checked={isChecked || false}
                className="me-2"
                onChange={(e) => handleCheckboxChange(e, index)}
              />
              <LightDarkText
                className={"text-start bold f12 "}
                color={mode === "light" ? "text-blue-grey" : "text-white"}
              >
                {item?.label}
              </LightDarkText>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CheckBoxInput;
