import client from "./Client";
import moment from "moment";
import {
  GET_CALENDARS,
  GET_CALENDAR_BY_ID,
  GET_EVENT_BY_ID,
} from "../Graphql/Queries/Calendars";
import { GET_AVATAR_CALENDAR } from "../Graphql/Queries/files";
import {
  CREATE_CALENDAR,
  CREATE_EVENT,
  DELETE_CALENDAR,
  DELETE_EVENT,
  UPDATE_CALENDAR,
  UPDATE_EVENT,
  VALIDATE_EVENT,
} from "../Graphql/Mutations/Calendars";
import {
  CREATE_AVATAR,
  DELETE_FILE,
  UPDATE_AVATAR,
  UPLOAD,
} from "../Graphql/Mutations/files";
import store from "../store/store";
import { handleError, handleInfo } from "./toastMessage";
import { clearTempUpload, uploadFiles } from "./files";
import { createAction } from "./interventions";
import Api from "../../Api/Api";
import { eventActionStatut } from "../../Components/Tickets/ticketUtils";
import { handleFirstLastName } from "../../Utils/utils";
import { sendMessage, updateMessage } from "./tickets";
import { closeSideBar } from "./sideBar";

const color = "light-blue";

export const getAllCalendars = () => async (dispatch) => {
  dispatch({ type: "CALENDARS_LOADING" });
  const user = store.getState().userReducer.user;
  const isSupervisor = store.getState().roleReducer.isSupervisor;
  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  if (!orgId) {
    return;
  }

  try {
    // const newCalendars = await Api.get(
    //   `/api/getConditionalCalendars?organisation=${orgId}`
    // );

    const test = await client.query({
      query: GET_CALENDARS,
      variables: {
        user: isSupervisor ? undefined : user.id,
        orgId: orgId,
      },
    });

    dispatch({
      type: "FETCH_CALENDARS",
      payload: test.data.calendars.data,
    });

    dispatch(getSharedEvents());
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const selectCalendars = (id) => async (dispatch) => {
  dispatch({ type: "CALENDAR_SELECT_LOADING", payload: id });
  const user = store.getState().userReducer.user;
  const isSupervisor = store.getState().roleReducer.isSupervisor;
  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  if (!orgId) {
    return;
  }
  const { data } = await client.query({
    query: GET_CALENDAR_BY_ID(id),
    variables: {
      user: isSupervisor ? undefined : user.id,
      orgId: orgId,
    },
  });

  dispatch({
    type: "SELECT_CALENDARS",
    payload: data.calendars.data[0],
  });
};

export const createCalendar = (newCalendar, avatar) => async (dispatch) => {
  const user = store.getState().userReducer.user;
  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  if (!orgId) {
    return;
  }

  try {
    let avatarId = "";
    if (avatar) {
      const { data: fileData } = await client.mutate({
        mutation: UPLOAD,
        variables: {
          file: avatar,
        },
      });

      const fileId = fileData.upload.data.id;

      const { data: avatarData } = await client.mutate({
        mutation: CREATE_AVATAR,
        variables: {
          image: fileId,
        },
      });

      avatarId = avatarData?.createAvatar?.data?.id;
    }

    const { data } = await client.mutate({
      mutation: CREATE_CALENDAR,
      variables: {
        name: newCalendar.name,
        avatar: avatarId || null,
        owner: user?.id,
        societe: newCalendar.societe,
        sharedUsers: newCalendar.sharedUsers,
        orgId: orgId,
      },
      refetchQueries: true,
    });
    dispatch({ type: "CREATE_CALENDAR", payload: data.createCalendar.data });
    dispatch(handleInfo("Agenda crée", 1, color));
    dispatch(clearTempUpload());
    return data.createCalendardata;
  } catch (error) {
    dispatch(handleError("Erreur de la création de l'agenda", 1));
  }
};

export const updateCalendar =
  ({ calendar, avatar, fetchNewUpdatedEvent }) =>
  async (dispatch) => {
    const user = store.getState().userReducer.user;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    try {
      if (avatar) {
        const { data: currentAvatar } = await client.query({
          query: GET_AVATAR_CALENDAR,
          variables: {
            calendarId: calendar.id,
          },
        });
        const { data: fileData } = await client.mutate({
          mutation: UPLOAD,
          variables: {
            file: avatar,
          },
        });
        const fileId = fileData.upload.data.id;

        if (!currentAvatar?.avatars?.data?.length > 0) {
          await client.mutate({
            mutation: CREATE_AVATAR,
            variables: {
              image: fileId,
              calendar: calendar.id,
            },
          });
        } else {
          const currentAvatarId = currentAvatar?.avatars?.data[0]?.id;
          const currentImageId =
            currentAvatar?.avatars?.data[0]?.attributes?.image?.data?.id;

          currentAvatarId &&
            (await client.mutate({
              mutation: UPDATE_AVATAR,
              variables: {
                id: currentAvatarId,
                imageId: fileId,
              },
            }));

          currentImageId &&
            (await client.mutate({
              mutation: DELETE_FILE,
              variables: {
                id: currentImageId,
              },
            }));
        }
      }

      const { data } = await client.mutate({
        mutation: UPDATE_CALENDAR,
        variables: {
          id: calendar?.id,
          name: calendar.name,
          societe: calendar.societe,
          sharedUsers: calendar.sharedUsers,
          user: user.id,
          orgId: orgId,
        },
        refetchQueries: true,
      });
      dispatch({ type: "UPDATE_CALENDAR", payload: data.updateCalendar.data });

      if (!fetchNewUpdatedEvent) {
        dispatch(handleInfo("Agenda modifé", 1, color));
      }

      dispatch(clearTempUpload());
      return data.updateCalendar;
    } catch (error) {
      dispatch(handleError("Erreur de la modification de l'agenda", 1));
    }
  };

export const deleteCalendar = (id) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_CALENDAR(id),
    });
    dispatch({ type: "DELETE_CALENDAR", payload: data.deleteCalendar.data.id });
    dispatch(handleInfo("Agenda supprimé", 1, color));
  } catch (error) {
    dispatch(handleError("Erreur de la suppression de l'agenda", 1));
  }
};

export const getSharedEvents = () => async (dispatch) => {
  try {
    const { data } = await Api.get("/api/fetchSharedEvents");

    dispatch({
      type: "FETCH_SHARED_EVENTS",
      payload: data.sharedEvents.data || [],
    });
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const getEventById = (id) => async (dispatch) => {
  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  if (!orgId) {
    return;
  }

  try {
    dispatch({ type: "EVENT_LOADING" });

    if (id) {
      const { data } = await client.query({
        query: GET_EVENT_BY_ID,
        variables: {
          id: id,
          orgId: orgId,
        },
      });

      dispatch({
        type: "GET_TICKET_EVENT",
        payload: data?.event?.data,
      });
    }
  } catch (error) {}
};

export const createEvent =
  (newEvent, attachementFiles, shareInTicket = false) =>
  async (dispatch) => {
    const user = store.getState().userReducer.user;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    const attachedFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles
      )
    );

    const facebookFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles.facebookFiles
      )
    );

    const instagramFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.instagramFiles
      )
    );

    const twitterFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.twitterFiles
      )
    );

    const linkedInFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.linkedInFiles
      )
    );

    const tiktokFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.tiktokFiles
      )
    );

    const globalFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.globalFiles
      )
    );

    try {
      const { data } = await client.mutate({
        mutation: CREATE_EVENT,
        variables: {
          name: newEvent.name,
          facebook: newEvent.facebook,
          facebookFiles:
            newEvent.facebookFiles?.data?.length && facebookFiles
              ? [
                  ...newEvent.facebookFiles?.data?.map((el) => el.id),
                  ...facebookFiles,
                ]
              : facebookFiles,
          instagram: newEvent.instagram,
          instagramFiles:
            newEvent.instagramFiles?.data?.length && instagramFiles
              ? [
                  ...newEvent.instagramFiles?.data?.map((el) => el.id),
                  ...instagramFiles,
                ]
              : instagramFiles,
          twitter: newEvent.twitter,
          twitterFiles:
            newEvent.twitterFiles?.data?.length && twitterFiles
              ? [
                  ...newEvent.twitterFiles?.data?.map((el) => el.id),
                  ...twitterFiles,
                ]
              : twitterFiles,
          linkedIn: newEvent.linkedIn,
          linkedInFiles:
            newEvent.linkedInFiles?.data?.length && linkedInFiles
              ? [
                  ...newEvent.linkedInFiles?.data?.map((el) => el.id),
                  ...linkedInFiles,
                ]
              : linkedInFiles,
          tikTok: newEvent.tikTok,
          tiktokFiles:
            newEvent.tiktokFiles?.data?.length && tiktokFiles
              ? [
                  ...newEvent.tiktokFiles?.data?.map((el) => el.id),
                  ...tiktokFiles,
                ]
              : tiktokFiles,
          global: newEvent.global,
          globalFiles:
            newEvent.globalFiles?.data?.length && globalFiles
              ? [
                  ...newEvent.globalFiles?.data?.map((el) => el.id),
                  ...globalFiles,
                ]
              : globalFiles,
          type: newEvent.type,
          startTime: newEvent.startTime,
          endTime: newEvent.endTime,
          startDate: newEvent.startDate,
          endDate: newEvent.endDate,
          place: newEvent.place,
          description: newEvent.description,
          color: newEvent.color,
          statut: newEvent.statut,
          calendar: newEvent.calendar?.id,
          creator: newEvent.creator,
          participants: newEvent.participants,
          validatingUsers: newEvent.validatingUsers,
          ticket: newEvent.ticket,
          recurrence: newEvent.recurrence,
          Reminder: newEvent.reminder ?? 0,
          shareInTicket: shareInTicket,
          recurrenceEndDate: newEvent.recurrenceEndDate ?? undefined,
          fichiers: attachedFiles,
          orgId: orgId,
        },
      });

      dispatch({ type: "CLEAR_UPLOAD" });
      dispatch(handleInfo(`Evénement créé`, 1, color));
      dispatch(
        updateCalendar({
          calendar: newEvent.calendar,
          avatar: null,
          fetchNewUpdatedEvent: true,
        })
      );
      dispatch(
        createAction({
          title: "Evénement créé",
          by: user?.id,
          actionType: 1,
          event: data?.createEvent?.data?.id,
        })
      );
      if (shareInTicket) {
        dispatch(
          sendMessage(
            {
              content: `${newEvent?.type === "Marketing" ? "Événement " : ""}${
                newEvent?.type
              } : ${newEvent?.name} a été créé par ${handleFirstLastName(
                user
              )} le ${moment(
                data?.createEvent?.data?.attributes?.createdAt
              ).format("DD.MM.YYYY")}`,
              ticket: newEvent?.ticket,
              type: 7,
              is_internal: false,
              sender: newEvent.creator,
              event: data?.createEvent?.data?.id,
              is_internal: false,
            },
            undefined,
            null,
            false,
            undefined
          )
        );
      }
    } catch (error) {
      dispatch(handleError("Erreur de la création de l'événement", 1));
    }
  };

const handleMessageEventContent = (newTicket, oldTicket, event, user) => {
  const eventType =
    event?.type === "Marketing" ? "Événement marketing" : "Rendez-vous";
  const name = event?.name;
  const par = handleFirstLastName(user);
  const le = moment().format("DD.MM.YYYY");

  const addContent = `${eventType} : ${name} a été associé à ce ticket par ${par} le ${le}`;
  const removeContent = `${eventType} : ${name} a été dissocié de ce ticket par ${par} le ${le}`;
  if (newTicket === oldTicket && newTicket) {
    // no link changed only event modified
    return [];
  } else {
    if (newTicket && oldTicket) {
      // link changed
      return [
        {
          ticket: oldTicket,
          content: removeContent,
          eventId: null,
        },
        {
          ticket: newTicket,
          content: addContent,
          eventId: event.id,
        },
      ];
    } else if (!oldTicket && newTicket) {
      // new link
      return [
        {
          ticket: newTicket,
          content: addContent,
          eventId: event.id,
        },
      ];
    } else if (oldTicket && !newTicket) {
      // link deleted
      return [
        {
          ticket: oldTicket,
          content: removeContent,
          eventId: null,
        },
      ];
    } else {
      // Event not linked
      return [];
    }
  }
};

export const updateEvent =
  (event, attachementFiles, shareInTicket = false) =>
  async (dispatch) => {
    const user = store.getState().userReducer.user;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    const attachedFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.files
      )
    );

    const facebookFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.facebookFiles
      )
    );

    const instagramFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.instagramFiles
      )
    );

    const twitterFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.twitterFiles
      )
    );

    const linkedInFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.linkedInFiles
      )
    );

    const tiktokFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.tiktokFiles
      )
    );

    const globalFiles = await dispatch(
      uploadFiles(
        {
          cloud:
            user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
              ?.data?.id,
        },
        attachementFiles?.globalFiles
      )
    );
    try {
      await client.mutate({
        mutation: UPDATE_EVENT,
        variables: {
          id: event.id,
          name: event.name,
          facebook: event.facebook,
          facebookFiles:
            event.facebookFiles?.data?.length && facebookFiles
              ? [
                  ...event.facebookFiles?.data?.map((el) => el.id),
                  ...facebookFiles,
                ]
              : facebookFiles,
          instagram: event.instagram,
          instagramFiles:
            event.instagramFiles?.data?.length && instagramFiles
              ? [
                  ...event.instagramFiles?.data?.map((el) => el.id),
                  ...instagramFiles,
                ]
              : instagramFiles,
          twitter: event.twitter,
          twitterFiles:
            event.twitterFiles?.data?.length && twitterFiles
              ? [
                  ...event.twitterFiles?.data?.map((el) => el.id),
                  ...twitterFiles,
                ]
              : twitterFiles,
          linkedIn: event.linkedIn,
          linkedInFiles:
            event.linkedInFiles?.data?.length && linkedInFiles
              ? [
                  ...event.linkedInFiles?.data?.map((el) => el.id),
                  ...linkedInFiles,
                ]
              : linkedInFiles,
          tikTok: event.tikTok,
          tiktokFiles:
            event.tiktokFiles?.data?.length && tiktokFiles
              ? [...event.tiktokFiles?.data?.map((el) => el.id), ...tiktokFiles]
              : tiktokFiles,
          global: event.global,
          globalFiles:
            event.globalFiles?.data?.length && globalFiles
              ? [...event.globalFiles?.data?.map((el) => el.id), ...globalFiles]
              : globalFiles,
          type: event.type,
          startTime: event.startTime,
          endTime: event.endTime,
          startDate: event.startDate,
          endDate: event.endDate,
          place: event.place,
          description: event.description,
          color: event.color,
          statut: event.statut,
          validatingUsers: event.validatingUsers,
          participants: event.participants,
          recurrence: event.recurrence,
          recurrenceEndDate: event.recurrenceEndDate,
          Reminder: event.reminder ?? 0,
          shareInTicket: shareInTicket,
          ticket: event.ticket || null,
          fichiers:
            event.fichiers?.data?.length && attachedFiles
              ? [...event.fichiers?.data?.map((el) => el.id), ...attachedFiles]
              : attachedFiles,
          orgId: orgId,
        },
      });

      if (event?.statut === 2 && event?.previousStatut === 1) {
        dispatch(
          createAction({
            title: "Evénement passé à 'à valider'",
            by: user?.id,
            actionType: 4,
            event: event.id,
          })
        );
      } else if (event?.statut === 4 && event?.previousStatut === 3) {
        dispatch(
          createAction({
            title: "Evénement programmé",
            by: user?.id,
            actionType: 6,
            event: event.id,
          })
        );
      }

      const messagesToSend = handleMessageEventContent(
        event.ticket,
        event.oldTicket,
        event,
        user
      );

      if (shareInTicket) {
        for (let i = 0; i < messagesToSend.length; i++) {
          const message = messagesToSend[i];
          dispatch(
            sendMessage(
              {
                content: message.content,
                ticket: message.ticket,
                event: message.eventId,
                sender: user.id,
                is_internal: false,
                type: 7,
              },
              undefined,
              null,
              false,
              undefined
            )
          );
        }
      }

      dispatch({ type: "CLEAR_UPLOAD" });
      dispatch(handleInfo(`Evénement modifié`, 1, color));
      // dispatch(
      //   updateCalendar({
      //     calendar: event.calendar,
      //     avatar: null,
      //     fetchNewUpdatedEvent: true,
      //   })
      // );
      dispatch(
        createAction({
          title: "Evénement modifié",
          by: user?.id,
          actionType: 2,
          event: event.id,
        })
      );

      if (event?.message?.data) {
        dispatch(
          updateMessage(
            event?.message?.data?.id,
            { content: event.message.data.attributes.content },

            { id: event.ticket },
            undefined,
            undefined
          )
        );
      }
    } catch (error) {
      dispatch(handleError("Erreur de la modification de l'événement", 1));
    }
  };

export const validateEvent = (event) => async (dispatch) => {
  const user = store.getState().userReducer.user;
  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  if (!orgId) {
    return;
  }
  try {
    if (event.statut) {
      await client.mutate({
        mutation: VALIDATE_EVENT,
        variables: {
          id: event.id,
          statut: event.statut,
          orgId: orgId,
        },
      });
      dispatch(
        createAction({
          title: "Evénement validé",
          by: user?.id,
          actionType: 5,
          event: event.id,
        })
      );
      dispatch(handleInfo(`Evénement validé`, 1, color));
    } else if (event?.motif) {
      dispatch(
        createAction({
          title: "Révision créée",
          by: user?.id,
          actionType: 3,
          motif: event.motif,
          event: event.id,
        })
      );
      dispatch(handleInfo(`Révision créée`, 1, color));
    } else if (event?.confirmedParticipants) {
      await client.mutate({
        mutation: VALIDATE_EVENT,
        variables: {
          id: event.id,
          confirmedParticipants: event?.confirmedParticipants,
          orgId: orgId,
        },
      });
      dispatch(
        createAction({
          title: "Rendez-vous accepté",
          by: user?.id,
          actionType: 7,
          event: event.id,
        })
      );
      dispatch(handleInfo(`Rendez-vous accepté`, 1, color));
    } else if (event?.declinedParticipants) {
      await client.mutate({
        mutation: VALIDATE_EVENT,
        variables: {
          id: event.id,
          declinedParticipants: event?.declinedParticipants,
          orgId: orgId,
        },
      });
      dispatch(
        createAction({
          title: "Rendez-vous refusé",
          by: user?.id,
          actionType: 8,
          event: event.id,
        })
      );
      dispatch(handleInfo(`Rendez-vous refusé`, 1, color));
    }

    const { data } = await client.query({
      query: GET_EVENT_BY_ID,
      variables: {
        id: event.id,
        orgId: orgId,
      },
    });

    dispatch({
      type: "FETCH_UPDATE_SHARED_EVENT",
      payload: data.event.data,
    });

    // dispatch(
    //   updateCalendar({
    //     calendar: event.calendar,
    //     avatar: null,
    //     fetchNewUpdatedEvent: true,
    //   })
    // );
  } catch (error) {
    dispatch(handleError("Erreur modification de l'événement", 1));
  }
};

export const deleteEvent = (event) => async (dispatch) => {
  try {
    const user = store.getState().userReducer.user;
    await client.mutate({
      mutation: DELETE_EVENT(event.id),
    });
    if (event.ticket) {
      const eventType =
        event?.type === "Marketing" ? "Événement marketing" : "Rendez-vous";
      const name = event?.name;
      const par = handleFirstLastName(user);
      const le = moment().format("DD.MM.YYYY");
      dispatch(
        sendMessage(
          {
            content: `${eventType} : ${name} a été supprimé par ${par} le ${le}`,
            ticket: event.ticket,
            sender: user.id,
            is_internal: false,
            type: 7,
          },
          undefined,
          null,
          false,
          undefined
        )
      );
    }
    dispatch(handleInfo("Evénement supprimé", 1, color));
    dispatch(closeSideBar());
    dispatch(
      updateCalendar({
        calendar: event.calendar,
        avatar: null,
        fetchNewUpdatedEvent: true,
      })
    );
  } catch (error) {
    dispatch(handleError("Erreur de la suppression de l'événement", 1));
  }
};

export const getCalendarRef = (ref) => async (dispatch) => {
  dispatch({
    type: "GET_CALENDAR_REF",
    payload: ref,
  });
};

export const changeCalendarView = (newView) => async (dispatch) => {
  dispatch({
    type: "CHANGE_CALENDAR_VIEW",
    payload: newView,
  });
};

export const setEventPosition = (data) => async (dispatch) => {
  dispatch({
    type: "SET_EVENT_POSITION",
    payload: data,
  });
};

export const fetchNewCalendar =
  ({ id }) =>
  async (dispatch) => {
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    try {
      if (!id) return;
      const { data } = await client.query({
        query: GET_CALENDAR_BY_ID(id),
      });

      dispatch({
        type: "FETCH_NEW_CALENDAR",
        payload: data.calendars.data[0],
        orgId: orgId,
      });

      dispatch(handleInfo("Nouvel agenda crée", 1, color));
    } catch (error) {}
  };

export const fetchEventAction =
  ({ eventId }) =>
  async (dispatch) => {
    const user = store.getState().userReducer.user;
    const sharedEvents = store.getState().calendarsReducer.sharedEvents;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    try {
      if (eventId) {
        const { data } = await client.query({
          query: GET_EVENT_BY_ID,
          variables: {
            id: eventId,
            orgId: orgId,
          },
        });

        if (
          sharedEvents?.find((e) => +e?.id == +eventId) &&
          data.event.data.attributes?.creator?.data?.id !== user?.id
        ) {
          dispatch({
            type: "FETCH_UPDATE_SHARED_EVENT",
            payload: data.event.data,
          });

          dispatch({
            type: "FETCH_NEW_SHARED_EVENT",
            payload: data.event.data,
          });
        }

        const lastAction =
          data.event.data.attributes?.actions?.data[
            data.event.data?.attributes?.actions?.data?.length - 1
          ];

        if (lastAction?.attributes?.statut === 1) {
          dispatch(
            handleInfo(
              `Nouvel événement ${
                data.event.data?.attributes?.name
              } crée par ${handleFirstLastName(
                lastAction?.attributes?.by?.data
              )}`,
              1,
              color
            )
          );
        } else if (lastAction?.attributes?.statut === 3) {
          dispatch(
            handleInfo(
              `Une nouvelle révision pour l'événement ${
                data.event.data?.attributes?.name
              } a été créée par ${handleFirstLastName(
                lastAction?.attributes?.by?.data
              )}`,
              1,
              color
            )
          );
        } else {
          dispatch(
            handleInfo(
              `L'événement ${
                data.event.data?.attributes?.name
              } ${eventActionStatut(
                lastAction?.attributes?.statut
              )} par ${handleFirstLastName(lastAction?.attributes?.by?.data)}`,
              1,
              color
            )
          );
        }

        // dispatch(
        //   updateCalendar({
        //     calendar: data.event.data?.attributes?.calendar?.data,
        //     avatar: null,
        //     fetchNewUpdatedEvent: true,
        //   })
        // );
      }
    } catch (error) {}
  };
