import React from "react";
import { useSelector } from "react-redux";
import { imageLoader } from "../../Utils/utils";
import "./Thumbnail.css";
import { Spinner } from "react-bootstrap";

const Thumbnail = ({
  loader = false,
  size = 40,
  apiImg,
  img,
  platformImg,
  rounded,
  radius,
  border,
  historyThumbnail = false,
  className = "",
  calendarIcon,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return loader ? (
    <div className="alignC" style={{ height: `${size}px`, width: `${size}px` }}>
      <Spinner size="sm" className="text-light-text" />
    </div>
  ) : (
    <div
      className={`${className} ${
        historyThumbnail &&
        `d-flex align-items-center p-4 justify-content-center ${
          mode === "light" ? "bg-light-mode" : "bg-dark-mode"
        } history-thumbnail`
      }`}
      style={{
        border:
          historyThumbnail &&
          `${mode === "light" ? "1px solid #3540741F" : "1px solid #252C45"}`,
        width: size,
        height: size,
      }}
    >
      <div
        className={` thumbnailContainer ${radius ? "radius10" : ""} ${
          rounded ? "thumbnailRounded" : ""
        }`}
        style={{
          backgroundImage: apiImg
            ? `url(${imageLoader(apiImg)})`
            : img
            ? `url(${require(`../../Images/temp/${img}.png`)})`
            : platformImg
            ? `url(${require(`../../Images/${platformImg}.png`)})`
            : null,
          width: size,
          height: size,
          minWidth: size,
          minHeight: size,
          boxSizing: "border-box",
          backgroundColor: calendarIcon ? "#A9BCD3" : "white",
          backgroundPosition: "center",
          border: border && `2px solid ${border}`,
        }}
      ></div>
    </div>
  );
};

export default Thumbnail;
