import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";
import Thumbnail from "../../../Common/Thumbnail";

import useWindowWidth from "../../../../Hooks/useWindowWidth";

const EventSociety = ({ society }) => {
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {society && (
        <div className="d-flex align-items-center gap-2 mt-4">
          <Thumbnail
            apiImg={
              society?.attributes?.avatar?.data?.attributes?.image?.data
                ?.attributes?.url
            }
            img="society"
            size={24}
            rounded
          />
          <LightDarkText
            className={`${screenWidth > 1200 ? "f14" : "f12"} text-start bold`}
          >
            {society?.attributes?.name}
          </LightDarkText>
        </div>
      )}
    </>
  );
};

export default EventSociety;
