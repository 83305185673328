import { resetSelectedDevices } from "./sharedData";

export const openModal = (data) => async (dispatch) => {
  dispatch({
    type: "OPEN_MODAL",
    payload: data,
  });
};

export const closeModal = () => async (dispatch) => {
  dispatch(resetSelectedDevices());
  dispatch({
    type: "CLOSE_MODAL",
  });
};
