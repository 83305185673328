import moment from "moment";
import {
  ADD_DEVIS_FAILED,
  ADD_DEVIS_SUCCESS,
  GET_DEVIS_FAILED,
  GET_DEVIS_SUCCESS,
  LOADING_DEVIS,
  LOADING_ADD_DEVIS,
  REMOVE_DEVIS_SUCCESS,
  SET_DEVIS,
  UNSET_DEVIS,
  UPDATE_DEVIS_FAILED,
  UPDATE_DEVIS_SUCCESS,
  UPDATE_FIELDS_DEVIS,
} from "../ActionsType/devisTypes";
import { UPDATE_DEVIS_FIELD_SUCCESS } from "../ActionsType/prospectTypes";
import { transformGraphObject, transformObject } from "../../Utils/utils";

const initialState = {
  loading: false,
  loadingAddDevis: false,
  devis: [],
  error: undefined,
  selectedDevis: {},
  transformedArrays: [],
};

const devisReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_DEVIS:
      return {
        ...state,
        loading: false,
      };
    case GET_DEVIS_SUCCESS:
      const transformedArray = payload.data.map((item) => {
        const transformedItem = {
          id: item?.id,
          origin: item?.attributes?.origin,
          status: item?.attributes?.status,
          date: moment(item?.attributes?.createdAt).format("DD.MM.YYYY"),
          note: item?.attributes?.note,
          archived: item?.attributes?.archived,
          actors: item.attributes?.acteurs?.data,
          actorsId: item.attributes?.acteurs?.data?.map((el) => {
            return +el?.id;
          }),
          actor:
            item.attributes?.acteurs && item.attributes?.status === "NEW_SELLER"
              ? item.attributes?.acteurs?.data[1]?.attributes?.workIn?.data[0]
                  ?.attributes?.name
              : item.attributes?.acteurs?.data[0]?.attributes?.workIn?.data[0]
                  ?.attributes?.name,
          document: item?.attributes?.document?.data,
        };

        item?.attributes?.prospect_fields?.data.forEach((dataItem) => {
          const question = dataItem?.attributes?.question?.data;
          const accessor = question?.attributes?.accessor;

          if (accessor) {
            transformedItem[accessor] = dataItem?.attributes?.value;
          }
        });

        return transformedItem;
      });
      return {
        ...state,
        devis:
          payload.page === 1 ? payload.data : [...state.devis, ...payload.data],
        transformedArrays:
          payload.page === 1
            ? transformedArray
            : [...state.transformedArrays, ...transformedArray],
        loading: false,
      };
    case GET_DEVIS_FAILED:
      return { ...state, loading: false, error: payload };

    case UPDATE_DEVIS_SUCCESS:
      return {
        ...state,
        loading: false,
        devis: state.devis.map((prospect) =>
          +prospect.id === +payload.data.id ? payload : prospect
        ),
        transformedArrays: state.transformedArrays.map((el) =>
          +el.id === +payload.data.id
            ? payload.withGraph
              ? transformGraphObject(payload.data)
              : transformObject(payload.data)
            : el
        ),
        selectedDevis: payload.withGraph
          ? transformGraphObject(payload.data)
          : transformObject(payload.data),
      };

    case UPDATE_DEVIS_FAILED:
      return { ...state, loading: false, error: payload };
    case REMOVE_DEVIS_SUCCESS:
      return {
        ...state,
        loading: false,
        devis: state.devis.filter((item) => +item.id !== +payload),
        transformedArrays: state.transformedArrays.filter(
          (item) => +item.id !== +payload
        ),
      };

    case UPDATE_DEVIS_FIELD_SUCCESS:
      const prospectToUpdate = state.devis.find((el) => +el.id === +payload.id);

      const newProspect =
        prospectToUpdate?.attributes?.prospect_fields?.data?.map((pros) =>
          +pros?.id === +payload?.newData?.id ? payload?.newData : pros
        );
      return {
        ...state,
        devis: state.devis.map((devis) =>
          +devis?.id === +newProspect?.id ? newProspect : devis
        ),
      };
    case SET_DEVIS:
      return { ...state, selectedDevis: payload };
    case UNSET_DEVIS:
      return { ...state, selectedDevis: {} };

    case LOADING_ADD_DEVIS:
      return { ...state, loadingAddDevis: true };
    case ADD_DEVIS_SUCCESS:
      return {
        ...state,
        loadingAddDevis: false,
        devis: [payload, ...state.devis],
        transformedArray: [
          transformGraphObject(payload),
          ...state.transformedArray,
        ],
      };
    case ADD_DEVIS_FAILED:
      return { ...state, loadingAddDevis: false };
    case UPDATE_FIELDS_DEVIS:
      return {
        ...state,
        devis: state.devis.map((prospect) =>
          +prospect?.id === +payload?.id ? payload : prospect
        ),
        transformedArrays: state.transformedArrays.map((el) =>
          +el.id === +payload.id ? transformGraphObject(payload) : el
        ),
      };
    default:
      return state;
  }
};

export default devisReducer;
