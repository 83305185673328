import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkText from "../../../../Common/LightDarkText";
import { DiscountIcon } from "../../../../Common/LightDarkSvg";

const InterventionFree = () => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          <LightDarkText className="custom-ticket-font-size py-1 text-white">
            Intervention non comptabilisée
          </LightDarkText>
        </Tooltip>
      }
    >
      <div className="alignH gap-2 custom-ticket-font-size text-start">
        <LightDarkText
          className={"position-relative customtooltip pointer"}
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          <DiscountIcon darkColor="#59638b" />
        </LightDarkText>
        <LightDarkText
          className="regular"
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          Hors forfait
        </LightDarkText>
      </div>
    </OverlayTrigger>
  );
};

export default InterventionFree;
