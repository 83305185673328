import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import LightDarkText from "../../Common/LightDarkText";
import { handleFirstLastName } from "../../../Utils/utils";
import UserThumbnail from "../../Common/UserThumbnail";

const RightEvents = ({ ticket }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const ticketEvents = ticket?.attributes?.events?.data || [];

  const handleScroll = (id) => {
    const element = document.querySelector(`#eventMessageId-${id}`);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="d-flex flex-column gap-2 scrollable">
      {ticketEvents.map((el) => (
        <div key={el.id} className="text-start border-bottom pb-2 opacityHover">
          <LightDarkText className="d-flex align-items-center gap-2 f12">
            <UserThumbnail
              userId={
                el?.attributes?.message?.data?.attributes?.sender?.data?.id
              }
              connected={
                el?.attributes?.message?.data?.attributes?.sender?.data
                  ?.attributes?.connected
              }
              lastConnected={
                el?.attributes?.message?.data?.attributes?.sender?.data
                  ?.attributes?.lastConnected
              }
              apiImg={
                el?.attributes?.creator?.data?.attributes?.avatar?.data
                  ?.attributes?.image?.data?.attributes?.url
              }
              img={`user${
                el?.attributes?.message?.data?.attributes?.sender?.data
                  ?.attributes?.genre || "3"
              }`}
              size={40}
            />
            <div className="d-flex flex-column f12">
              <span className="bold">{el?.attributes?.name}</span>
              <span className="text-light-text f10">
                {moment(el?.attributes?.startDate).format("DD/MM/YYYY HH:mm")} -{" "}
                {moment(el?.attributes?.endDate).format("DD/MM/YYYY HH:mm")}
              </span>
            </div>
          </LightDarkText>
          <div className="alignH justify-content-between mt-2">
            <LightDarkText
              color={mode === "light" ? "text-light-text" : "text-white"}
              className="text-start bold f10 pointer"
              onclickCallback={() => {
                handleScroll(el?.attributes?.message?.data?.id);
              }}
            >
              Créer par {handleFirstLastName(el?.attributes?.creator?.data)}
            </LightDarkText>
            <LightDarkText
              className="text-end xbold f10 pointer textBlueHover"
              onclickCallback={() => {
                handleScroll(el?.attributes?.message?.data?.id);
              }}
            >
              voir le message
            </LightDarkText>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RightEvents;
