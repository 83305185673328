import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFiles,
  selectFilesIntervention,
} from "../../REDUX/actions/files";
import LightDarkText from "./LightDarkText";
import "./AttachementUploader.css";

const AttachementUploader = ({
  label,
  required,
  flex1 = false,
  ticketUploaderHeight,
  ticketUploader = false,
  multiple = true,
  textColor = null,
  borderColor = null,
  bgColor = null,
}) => {
  const dispatch = useDispatch();

  const { temporarySelected } = useSelector((state) => state.filesReducer);

  const { mode } = useSelector((state) => state.genearlReducer);
  const { percentComplete, statut, uploaderInputPlaceHolder } = useSelector(
    (state) => state.filesReducer
  );

  const selectTemporaryFiles = (files) => {
    dispatch(selectFilesIntervention(files));

    dispatch(selectFiles(files));
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // setHighlight(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // setHighlight(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // setHighlight(false);

    selectTemporaryFiles(e.dataTransfer.files);
  };

  return (
    <div
      className={`${
        !ticketUploader && "h100"
      } d-flex flex-column justify-content-end`}
    >
      <div className="directionC">
        <LightDarkText
          className={"text-start bold custom-ticket-font-size mb-2 w-100 t-02"}
          color={
            textColor
              ? textColor
              : mode === "light"
              ? "text-blue-grey"
              : "text-white"
          }
        >
          {label} {required ? <span className="text-red">*</span> : null}
        </LightDarkText>
      </div>
      <label
        htmlFor="attachUploaderButton"
        className={`t-02 attachUploadLabel ${flex1 ? "flex-1" : ""} ${
          mode === "light" ? "lightAttachUploadLabel" : `darkAttachUploadLabel`
        }  alignC`}
        style={{
          height: `${ticketUploaderHeight ? "225px" : "75px"}`,
          width: "100%",
          borderColor: borderColor ? borderColor : null,
          backgroundColor: bgColor ? bgColor : null,
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div
          className={`attachUploaderLoadingbar`}
          style={{ width: percentComplete + "%" }}
        ></div>
        <input
          type="file"
          multiple={multiple}
          className="attachUploaderButton"
          id="attachUploaderButton"
          onChange={(e) => selectTemporaryFiles(e.target.files)}
        />
        <div
          className={`text-center px-1 attachFilesText text-break custom-ticket-font-size`}
        >
          {statut ? statut : uploaderInputPlaceHolder}
        </div>
      </label>
    </div>
  );
};

export default AttachementUploader;
