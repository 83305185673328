import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";
import { getValueByAccessor } from "../../../Utils/utils";
import Thumbnail from "../../Common/Thumbnail";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { useSelector } from "react-redux";

const TicketGeneralElt = ({ ticket, accessor, notRead }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const screenWidth = useWindowWidth();
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          <LightDarkText className={`custom-ticket-font-size  py-1 text-white`}>
            {getValueByAccessor(ticket, accessor)}
          </LightDarkText>
        </Tooltip>
      }
    >
      <div
        className={`d-flex align-items-center  gap-2 w-100 ${
          accessor === "attributes?.name" && "pe-3"
        }`}
      >
        {accessor.includes("stringSociety") && (
          <Thumbnail
            img={"society"}
            apiImg={
              ticket.attributes?.societe?.data?.attributes?.avatar?.data
                ?.attributes?.image?.data?.attributes?.url
            }
            rounded
            size={screenWidth < 1200 ? 30 : 40}
          />
        )}
        <LightDarkText
          className={`custom-ticket-font-size text-ellipsis ${
            accessor.includes("stringSociety") ? "bolder" : "bold"
          } text-start ${notRead && "xbold "}`}
          color={
            accessor.includes("stringSociety") && mode === "light"
              ? "text-dark-blue"
              : accessor.includes("stringSociety") && mode !== "light"
              ? "text-white"
              : mode === "light"
              ? "text-blue-grey"
              : "text-light-text"
          }
        >
          {getValueByAccessor(ticket, accessor)}
        </LightDarkText>
      </div>
    </OverlayTrigger>
  );
};

export default TicketGeneralElt;
