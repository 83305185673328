import client from "./Client";
import { GET_FACTURES } from "../Graphql/Queries/Factures";
import { CREATE_INVOICE, DELETE_INVOICE } from "../Graphql/Mutations/Factures";
import { UPLOAD } from "../Graphql/Mutations/files";
import { handleError, handleInfo } from "./toastMessage";
import store from "../store/store";
import { clearTempUpload } from "./files";
const color = "light-blue";

export const getAllInvoices = () => async (dispatch) => {
  dispatch({ type: "FACTURES_LOADING" });
  const selectedSociety = store.getState().filtersReducer.selectedSociety;
  const user = store.getState().userReducer.user;

  try {
    const { data } = await client.query({
      query: GET_FACTURES,
      variables: {
        societe: !selectedSociety?.length > 0 ? undefined : selectedSociety,
      },
    });

    const userInvoices = [];
    for (let society of user.attributes?.user_org_permissions?.data[0]
      ?.attributes?.workIn?.data) {
      for (let invoice of data.factures?.data) {
        if (invoice.attributes?.societe?.data.id === society.id) {
          userInvoices.push(invoice);
        }
      }
    }

    dispatch({
      type: "FETCH_INVOICES",
      payload:
        user?.attributes?.user_org_permissions?.data[0]?.attributes.role === 2
          ? userInvoices
          : data.factures?.data || [],
    });
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const CreateInvoice = (newInvoice) => async (dispatch) => {
  const temporarySelected = store.getState().filesReducer.temporarySelected;
  const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
  if (!orgId) {
    return;
  }

  const { data } = await client.mutate({
    mutation: UPLOAD,
    variables: {
      file: temporarySelected[0],
    },
  });
  const fileId = data.upload.data.id;

  try {
    const { data } = await client.mutate({
      mutation: CREATE_INVOICE,
      variables: {
        number: newInvoice.number,
        societe: newInvoice.societe,
        type: newInvoice.type,
        date: newInvoice.date,
        HT: parseFloat(newInvoice.HT),
        TVA: parseFloat(newInvoice.TVA),
        TTC: parseFloat(newInvoice.TTC),
        media: fileId,
        orgId: orgId,
      },
      refetchQueries: true,
    });
    dispatch({ type: "INVOICE_CREATED", payload: data.createFacture.data });
    dispatch(handleInfo("Facture ajoutée", 1, color));
    dispatch(clearTempUpload());
    return data.createFacture.data;
  } catch (error) {
    dispatch(handleError("Erreur ajout de la facture", 1));
  }
};

export const deleteInvoice = (id) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_INVOICE(id),
    });
    dispatch({ type: "DELETE_INVOICE", payload: data.deleteFacture.data.id });
    dispatch(handleInfo("Facture supprimée", 1, color));
  } catch (error) {
    dispatch(handleError("Erreur de la suppression de la facture", 1));
  }
};
