import { useSelector } from "react-redux";

import LightDarkText from "../../LightDarkText";

import { creationDate } from "../../../Tickets/ticketUtils";

const ElementDate = ({ element, notRead = false }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="text-ellipsis">
      <LightDarkText
        className={`custom-ticket-font-size  bold text-start text-ellipsis ${
          notRead && "xbold"
        }`}
        color={mode === "light" ? "text-blue-grey" : "text-light-text"}
      >
        {creationDate(element)}
      </LightDarkText>
    </div>
  );
};

export default ElementDate;
