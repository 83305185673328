import React from "react";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkPlaceHolder from "../LightDarkPlaceHolder";
import ThumbnailPlaceHolder from "../ThumbnailPlaceHolder";
import ThumbnailStackPlaceholder from "../ThumbnailStackPlaceholder";

const UsersSquareListPlaceHolder = () => {
  return (
    <div className="alignH flex-wrap justify-content-between scrollable grid4">
      {Array.from({ length: 5 }).map((_, index) => (
        <LightDarkContainer
          colorLight="white"
          key={index}
          className="userSquarePlaceDimension my-2 d-flex flex-column justify-content-between radius05 p-3 "
        >
          <div className="alignH justify-content-between">
            <div className="alignH gap-2">
              <LightDarkPlaceHolder height={25} width={60} />
              <LightDarkPlaceHolder height={25} width={100} />
            </div>
            <LightDarkPlaceHolder height={30} width={30} />
          </div>
          <div className="alignH gap-2">
            <ThumbnailPlaceHolder />
            <div className="alignH flex-column gap-2">
              <LightDarkPlaceHolder height={12} width={130} />
              <LightDarkPlaceHolder height={12} width={130} />
            </div>
          </div>
          <div>
            <ThumbnailStackPlaceholder />
          </div>
        </LightDarkContainer>
      ))}
    </div>
  );
};

export default UsersSquareListPlaceHolder;
