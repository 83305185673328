import React from "react";
import { useDispatch } from "react-redux";
import LightDarkInput from "./LightDarkInput";
import { useEffect } from "react";
import { handleCheckEmail } from "../../REDUX/actions/organisations";

const EmailInput = ({
  clientInputs,
  client,
  setclientInputs,
  isNew,
  setemailInfo,
  emailInfo,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const checkEmail = async () => {
      if (
        clientInputs.email &&
        (isNew || clientInputs.email !== client?.attributes?.email)
      ) {
        const check = await dispatch(handleCheckEmail(clientInputs.email));

        setemailInfo(check);
      }
    };
    checkEmail();
  }, [clientInputs.email]);

  return (
    <div className={`position-relative`}>
      <LightDarkInput
        placeholder={"E-mail"}
        label={"E-mail"}
        totalInputs={clientInputs}
        setValue={setclientInputs}
        champs="email"
        required
      />

      <span className="f11 position-absolute mt-1 text-red mb-3">
        {emailInfo.notValid ? (
          "Adresse mail non valide"
        ) : emailInfo.alreadyUsed ? (
          <span className="text-light-blue bold">
            L'adresse email est déjà enregistrée dans une organisation
          </span>
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export default EmailInput;
