import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../REDUX/actions/modal";
import { getCloud } from "../../REDUX/actions/cloud";
import FilesList from "../../Components/Cloud/FilesList";
import FolderList from "../../Components/Cloud/FolderList";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import CloudImportForm from "../../Components/Cloud/CloudImportForm";
import Loading from "../../Components/Common/Loading";
import SearchInput from "../../Components/Common/SearchInput";
import useWindowWidth from "../../Hooks/useWindowWidth";
import LazyAttachementList from "../../Components/Cloud/LazyAttachementList";
import MenuTabs from "../../Components/Common/MenuTabs";
import _ from "lodash";
import OneDriveSection from "../../Components/Cloud/OneDrive/OneDriveSection";

const CloudView = ({ activeTab, setActiveTab }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const [searchFiles, setsearchFiles] = useState("");

  const { user } = useSelector((state) => state.userReducer);
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const {
    fichiers,
    dossiers,
    sharedFolders,
    sharedFiles,
    cloudLoading,
    refetchCloud,
  } = useSelector((state) => state.cloudReducer);
  const { mode } = useSelector((state) => state.genearlReducer);

  const { oneDriveSynched } = useSelector(
    (state) => state.externalServicesReducer
  );

  const cloudTabs = [
    {
      title: "Dossiers",
      toggleValue: "folders",
      isVisible: true,
    },
    {
      title: "Fichiers",
      toggleValue: "files",
      isVisible: true,
    },
    {
      title: "Pièces jointes",
      toggleValue: "attachments",
      isVisible: true,
    },
    oneDriveSynched && {
      title: "One Drive",
      toggleValue: "onedrive",
      isVisible: true,
    },
  ].filter(Boolean);

  useEffect(() => {
    if (refetchCloud && (activeTab === "folders" || activeTab === "files")) {
      dispatch(getCloud());
    }
  }, [user?.id, dispatch, selectedOrganisation?.id]);

  const debounceSearch = _.debounce((value) => {
    setsearchFiles(value);
  }, 300);

  const renderTabContent = () => {
    switch (activeTab) {
      case "onedrive":
        return oneDriveSynched ? (
          <div className="">
            <OneDriveSection search={searchFiles} />
          </div>
        ) : null;
      case "folders":
        return (
          <div className="">
            <FolderList
              dossiers={[...dossiers, ...sharedFolders]
                .filter(
                  (el) =>
                    !el?.attributes?.isSubFolder ||
                    +user?.attributes?.user_org_permissions?.data[0]?.attributes
                      ?.cloud?.data?.id !== +el?.attributes?.cloud?.data?.id
                )
                .sort((a, b) => b?.id - a?.id)}
              search={searchFiles}
            />
          </div>
        );
      case "files":
        return (
          <div className="">
            <FilesList
              fichiers={[...fichiers, ...sharedFiles].sort(
                (a, b) => b?.id - a?.id
              )}
              search={searchFiles}
            />
          </div>
        );
      case "attachments":
        return (
          <div className="">
            <LazyAttachementList search={searchFiles} />
          </div>
        );
      default:
        return null;
    }
  };

  return cloudLoading ? (
    <Loading showLoader />
  ) : (
    <div className="container-fluid">
      <div
        className="d-flex flex-row align-items-center gap-2 mb-2"
        style={{ height: "50px" }}
      >
        <div className="col">
          <div
            className={`radius05 d-flex justify-content-between align-items-center t-02 ${
              mode === "dark" ? "bg-dark-grey-bg" : "bg-white"
            }`}
            style={{
              height: "50px",
              overflowX: "scroll",
              whiteSpace: "nowrap",
            }}
          >
            <div className="d-flex justify-content-start gap-4 align-items-center mx-5">
              {cloudTabs.map((tab, index) => (
                <div
                  key={index}
                  className={`cursor-pointer ${
                    activeTab === tab.toggleValue ? "active-tab" : ""
                  }`}
                  onClick={() => setActiveTab(tab.toggleValue)}
                >
                  <MenuTabs
                    tabTitle={tab.title}
                    toggleValue={tab.toggleValue}
                    isToggle={activeTab}
                    setIsToggle={setActiveTab}
                    isVisible={tab.isVisible}
                    isdashboardTabs
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col" style={{ height: "50px" }}>
          <SearchInput
            onChange={(e) => debounceSearch(e.target.value)}
            width={"w100"}
            placeholder="Rechercher..."
          />
        </div>
        {activeTab !== "attachments" &&
          activeTab !== "onedrive" &&
          activeTab !== "folders" && (
            <div style={{ height: "50px" }}>
              <ColoredButton
                height="100"
                textButton={screenWidth > 600 && "Importer un document"}
                icon={screenWidth < 600 && "plus"}
                color="light-blue"
                callback={() =>
                  dispatch(
                    openModal({ type: "small", content: <CloudImportForm /> })
                  )
                }
              />
            </div>
          )}
      </div>
      <div
        id="fileBody"
        className="ps-1"
        style={{ height: "calc(80vh)", overflowY: "auto", overflowX: "hidden" }}
      >
        <div className="container-fluid p-0">{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default CloudView;

// MenuTabs
