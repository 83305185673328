import moment from "moment";
import { useSelector } from "react-redux";

import { EventStatusIcon } from "../../../Common/LightDarkSvg";
import LightDarkText from "../../../Common/LightDarkText";

import {
  handleFirstLastName,
  showRoleNameByUser,
} from "../../../../Utils/utils";
import UserThumbnail from "../../../Common/UserThumbnail";

const EventHistoryList = ({ actions }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <div className="d-flex mt-5">
      <div
        style={{
          position: "relative",
          left: 25,
          borderLeft: `1px solid ${mode === "light" ? "#3540741F" : "#252C45"}`,
        }}
      />
      <div style={{ zIndex: 2 }}>
        {actions?.map((action, index) => (
          <div key={index}>
            <div className="alignH justify-content-between">
              <div className="d-flex gap-3 flex-1 mb-5">
                <UserThumbnail
                  userId={action?.attributes?.by?.data?.id}
                  connected={
                    action?.attributes?.by?.data?.attributes?.connected
                  }
                  lastConnected={
                    action?.attributes?.by?.data?.attributes?.lastConnected
                  }
                  apiImg={
                    action?.attributes?.by?.data?.attributes?.avatar?.data
                      ?.attributes?.image?.data?.attributes?.url
                  }
                  img={`user${
                    action?.attributes?.by?.data?.attributes?.genre || "3"
                  }`}
                  size={40}
                  historyThumbnail
                />
                <div>
                  <LightDarkText className={"f14 bolder text-start"}>
                    {handleFirstLastName(action.attributes?.by.data)}
                  </LightDarkText>
                  <LightDarkText
                    className="text-start bold custom-font-size"
                    color={
                      mode === "light" ? "text-blue-grey" : "text-light-text"
                    }
                  >
                    {showRoleNameByUser(action.attributes?.by.data?.attributes)}
                  </LightDarkText>
                  {action.attributes?.statut === 1 ? (
                    <LightDarkText
                      className="text-start custom-ticket-font-size bold mt-3"
                      color={mode === "light" ? "text-blue-grey" : "text-white"}
                    >
                      A créé l'événement le{" "}
                      {moment(action.attributes?.createdAt).format(
                        "DD/MM/YYYY"
                      )}
                    </LightDarkText>
                  ) : action.attributes?.statut === 2 ? (
                    <LightDarkText
                      className="text-start custom-ticket-font-size bold mt-3"
                      color={mode === "light" ? "text-blue-grey" : "text-white"}
                    >
                      A apporté des modifications le{" "}
                      {moment(action.attributes?.createdAt).format(
                        "DD/MM/YYYY"
                      )}
                    </LightDarkText>
                  ) : action.attributes?.statut === 3 ? (
                    <>
                      <LightDarkText className="text-start custom-ticket-font-size bold mt-3">
                        A créé une révision le{" "}
                        {moment(action.attributes?.createdAt).format(
                          "DD/MM/YYYY"
                        )}{" "}
                        pour le motif suivant:
                      </LightDarkText>
                      <LightDarkText
                        color={
                          mode === "light" ? "text-blue-grey" : "text-white"
                        }
                        insertHtml={action?.attributes?.motif}
                        className="text-start custom-ticket-font-size bold"
                      />
                    </>
                  ) : action.attributes?.statut === 4 ? (
                    <LightDarkText
                      className="text-start custom-ticket-font-size bold mt-3"
                      color={mode === "light" ? "text-blue-grey" : "text-white"}
                    >
                      A créé un nouveau statut de l'événement de "{" "}
                      <EventStatusIcon status={1} small />
                      En attente" à " <EventStatusIcon status={2} small />A
                      valider" le{" "}
                      {moment(action.attributes?.createdAt).format(
                        "DD/MM/YYYY"
                      )}
                    </LightDarkText>
                  ) : action.attributes?.statut === 5 ? (
                    <>
                      <LightDarkText
                        className="text-start custom-ticket-font-size bold mt-3"
                        color={
                          mode === "light" ? "text-blue-grey" : "text-white"
                        }
                      >
                        A accepté l'événement le{" "}
                        {moment(action.attributes?.createdAt).format(
                          "DD/MM/YYYY"
                        )}
                        . Le statut a été modifié à "{" "}
                        <EventStatusIcon status={3} small />
                        Validé"
                      </LightDarkText>
                    </>
                  ) : action.attributes?.statut === 6 ? (
                    <>
                      <LightDarkText
                        className="text-start custom-ticket-font-size bold mt-3"
                        color={
                          mode === "light" ? "text-blue-grey" : "text-white"
                        }
                      >
                        A créé un nouveau statut de l'événement de "{" "}
                        <EventStatusIcon status={3} small />
                        Validé" à " <EventStatusIcon status={4} small />
                        Programmé" le{" "}
                        {moment(action.attributes?.createdAt).format(
                          "DD/MM/YYYY"
                        )}
                      </LightDarkText>
                    </>
                  ) : action.attributes?.statut === 7 ? (
                    <LightDarkText
                      className="text-start custom-ticket-font-size bold mt-3"
                      color={mode === "light" ? "text-blue-grey" : "text-white"}
                    >
                      A accepté l'invitation à l'événement le{" "}
                      {moment(action.attributes?.createdAt).format(
                        "DD/MM/YYYY"
                      )}
                      .
                    </LightDarkText>
                  ) : (
                    action.attributes?.statut === 8 && (
                      <LightDarkText
                        className="text-start custom-ticket-font-size bold mt-3"
                        color={
                          mode === "light" ? "text-blue-grey" : "text-white"
                        }
                      >
                        A refusé l'invitation à l'événement le{" "}
                        {moment(action.attributes?.createdAt).format(
                          "DD/MM/YYYY"
                        )}
                        .
                      </LightDarkText>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventHistoryList;
