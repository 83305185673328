import React from "react";
import { closeModal } from "../../REDUX/actions/modal";
import LightDarkInput from "../Common/LightDarkInput";
import { useDispatch } from "react-redux";
import ColoredButton from "../Common/Buttons/ColoredButton";
import { useState } from "react";
import { renameFolder } from "../../REDUX/actions/cloud";
import { CloseIcon } from "../Common/LightDarkSvg";
import LightDarkContainer from "../Common/LightDarkContainer";
import LightDarkText from "../Common/LightDarkText";
import { renameFile } from "../../REDUX/actions/files";

const RenameCloudItem = ({ id, name, isFile = false, folderId }) => {
  const [newName, setnewName] = useState({ name: name });

  const dispatch = useDispatch();
  const handleRename = () => {
    if (isFile) {
      dispatch(renameFile(newName.name, id, folderId));
    } else {
      dispatch(renameFolder(newName.name, id));
    }

    dispatch(closeModal());
  };
  return (
    <LightDarkContainer
      className="p-4 radius05 directionC"
      colorLight="white"
      colorDark="dark-mode"
    >
      <div
        className="d-flex justify-content-end mb-2"
        onClick={() => dispatch(closeModal())}
      >
        <CloseIcon />
      </div>
      <LightDarkText className="text-center custom-title-font-size bold mb-4">
        {isFile ? "Renommer le fichier" : "Renommer le dossier"}
      </LightDarkText>

      <div onSubmit={(e) => e.preventDefault()}>
        <LightDarkInput
          label={"Nom de dossier"}
          placeholder="Nouveau nom"
          totalInputs={newName}
          setValue={setnewName}
          champs="name"
          value={newName.name}
        />
      </div>
      <div className="col-6 align-self-center mb-3 mt-5">
        <ColoredButton
          textButton="Enregister les modifications"
          disabled={!newName.name}
          color="light-blue"
          callback={handleRename}
        />
      </div>
    </LightDarkContainer>
  );
};

export default RenameCloudItem;
