import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";

import Thumbnail from "./Thumbnail";
import LightDarkText from "./LightDarkText";
import { CloseIcon, SelectArrow } from "./LightDarkSvg";

import useWindowWidth from "../../Hooks/useWindowWidth";

import "./LighDarkMultiChoice.css";

const LightDarkFilterSelect = ({
  data = [],
  multiple = true,
  createNew,
  canSelectAll,
  noSearch,
  totalInputs,
  setValue,
  champs,
  hideArrow = false,
  showTicketFilter = false,
  showInterventionFilter = false,
  societySelectInput = false,
  calendarSelect,
  placeholder = "Choisir...",
  bgWhite = false,
}) => {
  const screenWidth = useWindowWidth();
  const container = useRef(null);
  const selectedOptionRef = useRef(null);

  const [direction, setDirection] = useState("top");
  const [sortedData, setSortedData] = useState([]);

  const { mode } = useSelector((state) => state.genearlReducer);

  const handleChange = (selected) => {
    if (multiple) {
      setValue({
        ...totalInputs,
        [champs]: selected.filter((el) => el?.value && el?.label),
      });
      setSortedData([
        ...selected,
        ...data.filter((el) => !selected.find((s) => s?.value === el?.value)),
      ]);
    } else {
      const lastItem = selected?.filter((el) => el?.value && el?.label)[
        selected?.length - 1
      ];
      setValue({
        ...totalInputs,
        [champs]: lastItem ? [lastItem] : [],
      });
    }
  };

  useEffect(() => {
    if (!container?.current) return;
    const containerHeight =
      container.current.getBoundingClientRect().height * data?.length ?? 0;
    const top = container.current.getBoundingClientRect().bottom;
    const availableHeight = window.screen.height - top;
    if (availableHeight < containerHeight + 100) {
      setDirection("bottom");
    } else {
      setDirection("top");
    }
  }, [data?.length]);

  return (
    <>
      {data?.length === 1 ? (
        <div
          className="d-flex align-items-center ps-2 pe-3 py-1 radius05 w-100 "
          style={{
            border: `1px solid ${mode === "light" ? "#f4f6fd" : "#252c45"} `,
          }}
        >
          <Thumbnail rounded apiImg={data[0]?.avatar} img="society" size={32} />
          <LightDarkText className={"f12 ms-4"}>{data[0].label}</LightDarkText>
        </div>
      ) : (
        <div
          className={`${
            calendarSelect && screenWidth < 1580
              ? "w-100 min-w-100 max-w-100"
              : calendarSelect && screenWidth >= 1580
              ? "w-100 min-w-200 max-w-200"
              : screenWidth > 600 &&
                !calendarSelect &&
                "w-100 min-w-200 max-w-200"
          } ms-2 select-${direction} ${bgWhite && "bg-white p-1 radius05"}`}
          style={{ width: screenWidth < 600 && "40px" }}
          onClick={(e) => e.stopPropagation()}
          ref={container}
        >
          <MultiSelect
            options={sortedData?.length ? sortedData : data}
            ArrowRenderer={() => (hideArrow ? null : <SelectArrow />)}
            value={totalInputs[champs]}
            onChange={(e) => handleChange(e)}
            labelledBy="Select"
            isCreatable={createNew}
            disableSearch={noSearch}
            hasSelectAll={multiple && canSelectAll}
            ClearSelectedIcon={null}
            closeOnChangedValue={!multiple}
            ClearIcon={<CloseIcon />}
            onMenuToggle={(toggle) => {
              toggle &&
                selectedOptionRef?.current?.scrollIntoView({ block: "center" });
            }}
            ItemRenderer={({ checked, option, onClick, disabled }) => (
              <div
                className={`item-renderer position-relative ${
                  disabled ? "disabled" : ""
                }`}
                ref={
                  option?.value === totalInputs[champs][0]?.value
                    ? selectedOptionRef
                    : undefined
                }
              >
                <input
                  type="checkbox"
                  onChange={onClick}
                  checked={checked}
                  tabIndex={-1}
                  disabled={disabled}
                  hidden
                />
                {societySelectInput &&
                  option?.label !== "Séléctionner tout" &&
                  option?.label !== "Désélectionner tout" &&
                  option?.label !== "Séléctionner tout (Filtré)" && (
                    <Thumbnail
                      rounded
                      apiImg={option?.avatar}
                      img="society"
                      size={20}
                      className={"me-2"}
                    />
                  )}
                {option?.value && (
                  <span className="text-start me-1">
                    {showTicketFilter
                      ? "Tickets"
                      : showInterventionFilter && "Interventions"}
                  </span>
                )}
                <span
                  className={`${
                    option?.color && `text-${option?.color}`
                  } text-start`}
                >
                  {option?.label}
                </span>
              </div>
            )}
            className={`${
              mode === "light" ? "multichoiceLight" : "multichocieDark"
            } radius10 h40 multichoiceContainer filters `}
            overrideStrings={{
              allItemsAreSelected: "Tous les éléments sont séléctionnés",
              clearSearch: "Effacer la recherche",
              clearSelected: "Effacer séléctionnés",
              noOptions: "Pas de resultat",
              search: "Recherche",
              selectAll:
                totalInputs[champs]?.length === data?.length
                  ? "Désélectionner tout"
                  : "Séléctionner tout",
              selectAllFiltered: "Séléctionner tout (Filtré)",
              selectSomeItems: screenWidth > 600 && placeholder,
              create: "Créer",
            }}
          />
        </div>
      )}
    </>
  );
};

export default LightDarkFilterSelect;
