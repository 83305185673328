import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { handleFirstLastName } from "../../../Utils/utils";
import { SharedFolder } from "../../Common/LightDarkSvg";
import { useSelector } from "react-redux";

const FileSharedSection = ({ file, isMine }) => {
  const { user } = useSelector((state) => state.userReducer);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id={`tooltip-${file?.id}`}>
          {+user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
            ?.data?.id === +file?.attributes?.cloud?.data?.id
            ? `Partagé avec ${file?.attributes?.sharedUsers?.data?.map(
                (el) => ` ${handleFirstLastName(el)}`
              )} `
            : `Partagé par 
                  ${handleFirstLastName(
                    file?.attributes?.cloud?.data?.attributes?.user?.data
                  )} ${
                file?.attributes?.sharedUsers?.data?.filter(
                  (el) => +el.id !== +user.id
                ).length
                  ? "avec"
                  : ""
              }${file?.attributes?.sharedUsers?.data
                ?.filter((el) => +el.id !== +user.id)
                .map((el) => ` ${handleFirstLastName(el)}`)}`}
        </Tooltip>
      }
    >
      <button className="pe-0 bg-transparent border-0 outline-none">
        <SharedFolder color={isMine ? "darkBlue" : ""} />
      </button>
    </OverlayTrigger>
  );
};

export default FileSharedSection;
