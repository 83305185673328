import { useDispatch, useSelector } from "react-redux";

import ColoredButton from "../../Common/Buttons/ColoredButton";
import CalendarForm from "../CalendarForms/CalendarForm";
import CalendarUsers from "./CalendarUsers";
import CalendarCard from "./CalendarCard";

import { openModal } from "../../../REDUX/actions/modal";
import { useRef } from "react";

const CalendarsList = ({ calendars }) => {
  const dispatch = useDispatch();
  const calendarListRef = useRef(null);

  const scrollLeft = () => {
    calendarListRef.current.scrollBy({ left: -400, behavior: "smooth" }); // Scroll 100px to the left
  };

  const scrollRight = () => {
    calendarListRef.current.scrollBy({ left: 400, behavior: "smooth" }); // Scroll 100px to the right
  };

  const { mode } = useSelector((state) => state.genearlReducer);
  const { selectedCalendars } = useSelector((state) => state.calendarsReducer);
  return (
    <div className="d-flex align-items-center justify-content-between mb-2 showScroll">
      <div
        className={`d-flex align-items-center justify-content-start col-12 ${
          calendars?.length && "gap-3"
        }`}
      >
        <div>
          <ColoredButton
            icon="chevron-left"
            color={mode === "light" ? "white" : "dark-grey-bg"}
            iconColor={mode === "light" ? "blue-grey" : "light-text"}
            callback={() => scrollLeft()}
          />
        </div>
        <div
          ref={calendarListRef}
          id="calendar-list"
          className="alignH gap-3"
          style={{
            overflowX: "scroll",
          }}
        >
          {calendars.map((calendar, index) => (
            <div className="alignH" key={index}>
              <CalendarCard index={index} calendar={calendar} />
              {selectedCalendars.some((el) => el.id === calendar.id) ? (
                <CalendarUsers selectedCalendars={[calendar]} />
              ) : null}
            </div>
          ))}
        </div>
        <div>
          <ColoredButton
            icon="chevron-right"
            color={mode === "light" ? "white" : "dark-grey-bg"}
            iconColor={mode === "light" ? "blue-grey" : "light-text"}
            callback={() => scrollRight()}
          />
        </div>
        <div className="d-flex">
          <ColoredButton
            icon="plus"
            color={mode === "light" ? "white" : "dark-grey-bg"}
            iconColor={mode === "light" ? "blue-grey" : "light-text"}
            callback={() =>
              dispatch(
                openModal({
                  type: "small",
                  content: <CalendarForm isNew />,
                })
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarsList;
