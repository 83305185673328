import { useSelector } from "react-redux";

import { LocationIcon } from "../../../Common/LightDarkSvg";
import LightDarkText from "../../../Common/LightDarkText";

import useWindowWidth from "../../../../Hooks/useWindowWidth";

const EventLocation = ({ place, eventMainCard, eventPopUp, sideBar }) => {
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {place && (
        <div
          className={`d-flex align-items-center ${
            eventMainCard ? "my-2" : "my-4"
          }`}
        >
          <LocationIcon
            small={!eventPopUp && !sideBar}
            lightColor={!eventPopUp && !sideBar && "white"}
          />
          <LightDarkText
            insertHtml={place}
            className={`
           ${`${screenWidth > 1200 ? "f14" : "f12"}`}
        text-start  d-flex align-items-center ${
          eventPopUp || sideBar ? "bold" : "text-white regular"
        }`}
          />
        </div>
      )}
    </>
  );
};

export default EventLocation;
