import React from "react";
import LightDarkText from "../../../../Common/LightDarkText";
import { useSelector } from "react-redux";

const InterviewInformations = ({ interviewModel }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="alignH justify-content-start gap-4 bold">
      {interviewModel.attributes.mandatory && (
        <div className="alignH gap-4 custom-ticket-font-size text-start">
          <LightDarkText
            color={mode === "light" ? "text-blue-grey" : "text-white"}
          >
            Obligatoire
          </LightDarkText>

          <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#59638B" />
          </svg>
        </div>
      )}

      <div className="alignH gap-4 custom-ticket-font-size text-start">
        <LightDarkText
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          {interviewModel.attributes.interview_questions.data.length} Questions
        </LightDarkText>

        <svg
          width="4"
          height="4"
          viewBox="0 0 4 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="2" cy="2" r="2" fill="#59638B" />
        </svg>
      </div>
      <div className="alignH gap-4 custom-ticket-font-size text-start">
        <LightDarkText
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          {
            interviewModel.attributes.interviews.data.filter(
              (el) => el.attributes.responded
            ).length
          }
          /{interviewModel.attributes.interviews.data.length} Réponses
        </LightDarkText>
      </div>
    </div>
  );
};

export default InterviewInformations;
