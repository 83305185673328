import client from "./Client";
import { GET_USER_BY_ID } from "../Graphql/Queries/user";

import Api from "../../Api/Api";

import store from "../store/store";

import { handleError } from "./toastMessage";
import QueryString from "qs";
import moment from "moment";

export const getConditionalUsers =
  (isClient = true, withLoading = true) =>
  async (dispatch) => {
    if (withLoading) {
      if (isClient) {
        dispatch({ type: "CLIENTS_LOADING" });
      } else {
        dispatch({ type: "OPERATORS_LOADING" });
      }
    }
    const clientArchived = store.getState().filtersReducer.clientArchived;
    const operateursArchived =
      store.getState().filtersReducer.operateursArchived;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    try {
      const { data } = await Api.get(
        `/api/users-permissions/user/getConditionalUsers?organisation=${orgId}&archived=${
          isClient ? clientArchived : operateursArchived
        }`
      );

      if (isClient) {
        dispatch({
          type: "FETCH_CLIENTS",
          payload: data.clients,
        });
      } else {
        dispatch({
          type: "FETCH_OPERATORS",
          payload: data.operators,
        });
      }
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const getNewClient =
  ({ id }) =>
  async (dispatch) => {
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    const { data } = await client.query({
      query: GET_USER_BY_ID(id, orgId),
    });
    dispatch({
      type: "FETCH_NEW_CLIENT",
      payload: data.usersPermissionsUsers.data[0],
    });
  };

const groupByDay = (data) => {
  const result = {};

  data.forEach((item) => {
    // Extract the date from the createdAt timestamp using Moment.js
    const date = moment(item.attributes.createdAt).format("YYYY-MM-DD");

    // Initialize the day in the result object if not already present
    if (!result[date]) {
      result[date] = { logs: [] };
    }

    // Add the current log to the corresponding day
    result[date].logs.push({
      id: item.id,
      duration: item.attributes.duration,
      createdAt: item.attributes.createdAt,
    });
  });

  // Format the result to the desired output structure
  return Object.keys(result).map((day) => ({
    day,
    logs: result[day].logs,
  }));
};

export const getHistoryLog = (id, intervalInputs) => async (dispatch) => {
  const startInput = intervalInputs.startDate
    ? { createdAt: { $gte: intervalInputs.startDate } }
    : {};
  const endInput = intervalInputs.endDate
    ? { createdAt: { $lte: intervalInputs.endDate } }
    : {};
  try {
    dispatch({ type: "CLEAR_HISTORY_LOGS" });
    const query = QueryString.stringify({
      fields: ["duration", "createdAt"],
      filters: {
        duration: { $notNull: true, $gte: 60000 },
        user: { id: { $eq: id } },
        ...startInput,
        ...endInput,
      },
      sort: "id:desc",
    });

    const historyLog = await Api.get(`/api/history-logs?${query}`);

    dispatch({
      type: "FETCH_HISTORY_LOGS",
      payload: groupByDay(historyLog.data.data),
    });
  } catch (error) {
    dispatch(handleError("Historique non disponible", 1));
  }
};
