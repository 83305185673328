import React from "react";
import LightDarkText from "../../Common/LightDarkText";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EventCalendar from "./EventCommonComponents/EventCalendar";
import moment from "moment";
import EventTitle from "./EventCommonComponents/EventTitle";
import EventDate from "./EventCommonComponents/EventDate";
import EventType from "./EventCommonComponents/EventType";
import { useSelector } from "react-redux";
import { handleFirstLastName } from "../../../Utils/utils";

const EventSideGroup = ({ events, title, eventClick }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <div>
      {events?.length ? (
        <LightDarkText
          className="text-start f14 bold mb-2 pt-4 px-2 text-capitalize"
          //   color={mode === "light" ? "text-dark-blue" : "text-light-text"}
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: `${mode === "light" ? "white" : "#181f3a"}`,
            zIndex: 3,
          }}
        >
          {title}
        </LightDarkText>
      ) : null}
      <div className="d-flex flex-column align-items-center gap-2">
        {events?.map((event) => (
          <OverlayTrigger
            placement="left"
            key={event?.id}
            overlay={
              <Tooltip
                key={event?.id}
                style={{
                  zIndex: `${
                    event?.attributes?.participants?.data?.length ? 100 : -100
                  }`,
                }}
              >
                <LightDarkText className="custom-ticket-font-size py-1 text-white">
                  {`Evénement partagé avec ${event?.attributes?.participants?.data?.map(
                    (el) => ` ${handleFirstLastName(el)}`
                  )}`}
                </LightDarkText>
              </Tooltip>
            }
          >
            <div
              key={event?.id}
              className="p-2 radius05 pointer"
              style={{
                backgroundColor: `${event?.attributes?.color}`,
                width: "139px",
              }}
              onClick={() => eventClick(event)}
            >
              <div className="d-flex align-items-center gap-2 mb-2">
                <EventCalendar
                  calendar={event?.attributes?.calendar?.data}
                  eventsList
                />
                <LightDarkText className="text-start text-white f12">
                  {moment(event?.attributes?.startDate).format("ddd DD/MM")}
                </LightDarkText>
              </div>
              <div>
                <EventTitle title={event?.attributes?.name} eventsList />
                <EventType type={event?.attributes?.type} eventsList />
              </div>
            </div>
          </OverlayTrigger>
        ))}
      </div>
    </div>
  );
};

export default EventSideGroup;
