import React from "react";
import MessageContent from "./MessageContent";
import UserThumbnail from "../../../../../../Common/UserThumbnail";
import { useSelector } from "react-redux";
import InternMessageContainer from "../InternMessageContainer";

const MessageCardContainer = ({
  responseTo,
  setIsReplying,
  setInternalResponseTo,
  handleFocusToRespond,
  setResponseTo,
  message,
  ticketId,
  isArchived,
  messageContenttoShow,
  showOriginal,
  setshowOriginal,
  meSending,
  setMeSending,
}) => {
  // const [showAllMessages, setShowAllMessages] = useState(false);
  // const { mode } = useSelector((state) => state.genearlReducer);
  const { isSupervisor, isOperator } = useSelector(
    (state) => state.roleReducer
  );

  const internalMessages = message?.attributes?.internal_responses?.data || [];
  // const displayedMessages = showAllMessages
  //   ? internalMessages
  //   : internalMessages.slice(-5);

  return (
    <div className="d-flex flex-column px-2">
      <div
        id={`messageId-${message.id}`}
        className={`d-flex gap-2 w80 mb-3 ${
          meSending ? "flex-row-reverse align-self-end" : ""
        }`}
      >
        <div className="align-self-end mb-4">
          <UserThumbnail
            userId={message.attributes.sender?.data?.id}
            connected={message.attributes.sender?.data?.attributes?.connected}
            lastConnected={
              message.attributes.sender?.data?.attributes?.lastConnected
            }
            apiImg={
              message.attributes.sender?.data?.attributes?.avatar?.data
                ?.attributes?.image?.data?.attributes?.url
            }
            img={`user${
              message.attributes.sender?.data?.attributes?.genre || "3"
            }`}
            size={40}
          />
        </div>
        <MessageContent
          responseTo={responseTo}
          setIsReplying={setIsReplying}
          handleFocusToRespond={handleFocusToRespond}
          setResponseTo={setResponseTo}
          setInternalResponseTo={setInternalResponseTo}
          ticketId={ticketId}
          isArchived={isArchived}
          message={message}
          messageContenttoShow={messageContenttoShow}
          showOriginal={showOriginal}
          setshowOriginal={setshowOriginal}
          meSending={meSending}
          setMeSending={setMeSending}
        />
      </div>

      {/* {(isOperator || isSupervisor) && internalMessages.length > 5 && (
        <LightDarkText
          color={mode === "light" ? "text-light-text" : "text-white"}
          className={`f10 bold w80 ${
            meSending ? "flex-row-reverse align-self-end" : ""
          } pointer text-decoration-underline`}
          onclickCallback={() => setShowAllMessages((prev) => !prev)}
        >
          {showAllMessages
            ? "Masquer les messages précédents"
            : "Afficher les messages précédents"}
        </LightDarkText>
      )} */}

      {(isOperator || isSupervisor) &&
        internalMessages.map((el) => (
          <InternMessageContainer
            key={el.id}
            internMessage={el}
            message={message}
            ticketId={ticketId}
            meSending={meSending}
            setMeSending={setMeSending}
          />
        ))}
    </div>
  );
};

export default MessageCardContainer;
