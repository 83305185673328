const initialState = {
  open: false, // false,
  type: "archive", //null,
  message: null, // null,
  message2: null, //null
  cancelButton: true,
  confirmButtonText: null, //null
  callBack: null,
  cancelCallback: null,
  icon: "archive",
};

const confirmationModalReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "OPEN_CONFIRMATION_MODAL":
      return {
        ...state,
        open: true,
        type: payload.type,
        message: payload.message,
        message2: payload.message2,
        cancelButton: payload.cancelButton,
        confirmButtonText: payload.confirmButtonText,
        callBack: payload.callBack,
        cancelCallback: payload.cancelCallback,
        icon: payload.icon,
      };
    case "CLOSE_CONFIRMATION_MODAL":
      return { ...state, open: false, message: null, cancelButton: true, confirmButtonText: null, callBack: null, type: null };
    default:
      return state;
  }
};

export default confirmationModalReducer;
