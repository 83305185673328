import { handleuserRoleName } from "../../Utils/utils";

const initialState = {
  role: null,
  roleName: null,
  isSupervisor: false,
  isOperator: false,
  isClientAdmin: false,
  isCollab: false,
  isLecteur: false,
  canCreateTickets: false,
  canCreateInterventions: false,
  canArchiveTickets: false,
  canResolveTickets: false,
  canCreateCollabs: false,
  freelance: false,
  canCreateInvoices: false,
  canSendMessages: false,
  canSeeInvoices: false,
  canSeeCollabs: false,
  canSeeOperators: false,
  canCreateProspet: false,
  canCreateDevis: false,
  canCreateCommande: false,
  canCreateDiffusion: false,
  canModifySupervisorPermissions: false,
  canModifyAdminPermissions: false,
};

const roleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_ORGANISATION":
      return {
        ...state,
        role: null,
        roleName: null,
        isSupervisor: false,
        isOperator: false,
        isClientAdmin: false,
        isCollab: false,
        isLecteur: false,
      };

    case "FETCH_INITIAL_DATA":
      let userRole = payload.userRole.data.attributes;
      const permissions = {
        role: userRole.role,
        roleName: handleuserRoleName(userRole.role),
        canCreateSociety: userRole.canCreateSociety,
        canCreateTickets: userRole.canCreateTickets,
        canCreateInterventions: userRole.canCreateInterventions,
        canValidateInterventions: userRole.canValidateInterventions,
        canArchiveTickets: userRole.canArchiveTickets,
        canResolveTickets: userRole.canResolveTickets,
        canCreateCollabs: userRole.canCreateCollabs,
        canSeeWhoOnline: userRole.canSeeWhoOnline,
        canCreateInvoices: userRole.canCreateInvoices,
        canSendMessages: userRole.canSendMessages,
        canSeeInvoices: userRole.canSeeInvoices,
        canSeeCollabs: userRole.canSeeCollabs,
        canCreateOperators: userRole.canCreateOperators,
        canSeeOperators: userRole.canSeeOperators,
        canCreateAlerts: userRole.canCreateAlerts,
        canCreateProspet: userRole.canCreateProspet,
        canCreateDevis: userRole.canCreateDevis,
        canCreateCommande: userRole.canCreateCommande,
        canCreateDiffusion: userRole.canCreateDiffusion,
        canModifySupervisorPermissions: userRole.canModifySupervisorPermissions,
        canModifyAdminPermissions: userRole.canModifyAdminPermissions,
        canAcceptInterventions: userRole.canAcceptInterventions,
        canRejectInterventions: userRole.canRejectInterventions,
        canHoldForValidationInterventions:
          userRole.canHoldForValidationInterventions,
        canRequestRevisionInterventions:
          userRole.canRequestRevisionInterventions,
      };
      if (userRole.role === 1) {
        return {
          ...state,
          isSupervisor: true,
          isOperator: false,
          isClientAdmin: false,
          isCollab: false,
          isLecteur: false,
          ...permissions,
        };
      } else if (userRole.role === 2) {
        return {
          ...state,

          isOperator: true,
          isSupervisor: false,
          isClientAdmin: false,
          isCollab: false,
          isLecteur: false,
          ...permissions,
        };
      } else if (userRole.role === 3) {
        return {
          ...state,

          isClientAdmin: true,
          isSupervisor: false,
          isOperator: false,
          isCollab: false,
          isLecteur: false,
          ...permissions,
        };
      } else if (userRole.role === 4) {
        return {
          ...state,

          isCollab: true,
          isSupervisor: false,
          isOperator: false,
          isClientAdmin: false,
          isLecteur: false,
          ...permissions,
        };
      } else if (userRole.role === 5) {
        return {
          ...state,

          isLecteur: true,
          isSupervisor: false,
          isOperator: false,
          isClientAdmin: false,
          isCollab: false,
          ...permissions,
        };
      }
      break;
    default:
      return state;
  }
};

export default roleReducer;
