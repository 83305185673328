import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import LightDarkText from "../../Common/LightDarkText";

import { readAlert } from "../../../REDUX/actions/alerts";

const AlertTooltip = ({ show }) => {
  //   const dispatch = useDispatch();

  const elRef = useRef(null);
  const { mode } = useSelector((state) => state.genearlReducer);

  //   const { unreadAlerts } = useSelector((state) => state.alertsReducer);

  //   const handleBlur = (e) => {
  //     if (!e.currentTarget.contains(e.relatedTarget)) {
  //       show(true);
  //       unreadAlerts.map((alert) => dispatch(readAlert(alert)));
  //     }
  //   };
  //   useEffect(() => {
  //     elRef?.current?.focus();
  //   }, [elRef]);

  return (
    <div
      ref={elRef}
      //   onBlur={handleBlur}
      tabIndex={1}
      className={`newAlertTooltip radius05 py-2 ${
        mode === "light" ? "bg-white" : "bg-dark-grey-bg"
      }`}
      style={{
        position: "absolute",
        right: 55,
        top: 0,
        width: "170px",
      }}
    >
      <LightDarkText className="f12 bold" color="text-light-text">
        Dernières fonctionnalités
      </LightDarkText>
    </div>
  );
};

export default AlertTooltip;
