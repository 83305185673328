import React from "react";
import "./Pprod.css";
import logoIconColor from "../../Images/org_agilix.svg";
import LightDarkText from "../Common/LightDarkText";

const Pprod = ({ espace }) => {
  return (
    <LightDarkText className="pprodSection mt-2 f30" color="text-blue-grey">
      <img
        className="align-self-center"
        src={logoIconColor}
        alt="logo-icon"
        width={48}
      />
      {espace}
    </LightDarkText>
  );
};

export default Pprod;
