import React, { useEffect, useState } from "react";
import LightDarkText from "../../../../Common/LightDarkText";
import { useDispatch, useSelector } from "react-redux";
import InterviewInformations from "./InterviewInformations";
import ColoredButton from "../../../../Common/Buttons/ColoredButton";
import { openSideBar } from "../../../../../REDUX/actions/sideBar";
import InterviewForm from "./InterviewForm";
import InterviewInfoSideBar from "./InterviewInfoSideBar";

const MainInterviewMessage = ({ message }) => {
  const dispatch = useDispatch();
  const { isSupervisor } = useSelector((state) => state.roleReducer);
  const { user } = useSelector((state) => state.userReducer);

  const [interviewModel, setInterviewModel] = useState(
    message.attributes.interview_model.data
  );
  useEffect(() => {
    setInterviewModel(message.attributes.interview_model.data);
  }, [message]);

  return (
    interviewModel && (
      <div
        className="w-100 d-flex flex-column gap-3 align-items-start mt-4 mb-2 messageCardMessage p-4 radius05 position-relative interview-message interviewMainMessage"
        id={`interview-${message.id}`}
      >
        <LightDarkText className={"bold text-start custom-font-size"}>
          Sondage
        </LightDarkText>
        <LightDarkText className="text-start bold custom-font-size">
          <span className="text-light-blue">#{interviewModel.id}</span>
          {` | ${interviewModel.attributes.name}`}
        </LightDarkText>

        <LightDarkText
          className={"custom-ticket-font-size text-blue-grey p-nomb text-start"}
        >
          {interviewModel.attributes.description}
        </LightDarkText>
        <InterviewInformations interviewModel={interviewModel} />
        <div className="alignC w-100 mt-2">
          {interviewModel.attributes.interviews.data.some(
            (interview) => +interview.attributes.userId === +user.id
          ) && (
            <div className="w40">
              <ColoredButton
                color={"#0CCE91"}
                background="#0CCE9126"
                icon="check-square"
                square
                textButton="Répondre"
                callback={() => {
                  dispatch(
                    openSideBar({
                      content: (
                        <InterviewForm interviewModel={interviewModel} />
                      ),
                      title: `Répondre au sondage`,
                      type: "w40",
                      isToggle: "isToggle value",
                    })
                  );
                }}
              />
            </div>
          )}
          {isSupervisor && (
            <div className="w40">
              <ColoredButton
                color={"#0CCE91"}
                background="#0CCE9126"
                icon="check-square"
                square
                textButton="Plus d'information"
                callback={() => {
                  dispatch(
                    openSideBar({
                      content: (
                        <InterviewInfoSideBar interviewModel={interviewModel} />
                      ),
                      title: `Sondage`,
                      type: "w30",
                      isToggle: "isToggle value",
                    })
                  );
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default MainInterviewMessage;
