import React from "react";
import LightDarkPlaceHolder from "../LightDarkPlaceHolder";

const FaqsViewPlaceHolder = () => {
  return (
    <div className="d-flex flex-row gap-3 justify-content-between h-100">
      <div className="col-3 h-100 bg-white p-5">
        <div className="mb-4">
          <LightDarkPlaceHolder customClass={"w-50 py-2 mb-3"} />
          <div className="d-flex flex-column gap-2">
            <LightDarkPlaceHolder customClass={"w-100 py-2"} />
            <LightDarkPlaceHolder customClass={"w-100 py-2"} />
            <LightDarkPlaceHolder customClass={"w-100 py-2"} />
          </div>
        </div>
        <div className="mb-4">
          <LightDarkPlaceHolder customClass={"w-50 py-2 mb-3"} />
          <div className="d-flex flex-column gap-2">
            <LightDarkPlaceHolder customClass={"w-100 py-2"} />
            <LightDarkPlaceHolder customClass={"w-100 py-2"} />
          </div>
        </div>
      </div>

      <div className="col-9 h-100 bg-white p-5">
        <LightDarkPlaceHolder customClass={"w-75 py-3 mb-4"} />
        <div className="d-flex flex-column gap-3">
          <LightDarkPlaceHolder customClass={"w-100 py-2"} />
          <LightDarkPlaceHolder customClass={"w-100 py-2"} />
          <LightDarkPlaceHolder customClass={"w-100 py-2"} />
        </div>

        <div className="mt-5">
          <LightDarkPlaceHolder customClass={"w-50 py-2 mb-3"} />
          <div className="d-flex flex-column gap-3">
            <LightDarkPlaceHolder customClass={"w-100"} height={150} />
            <LightDarkPlaceHolder customClass={"w-100"} height={150} />
            <LightDarkPlaceHolder customClass={"w-100"} height={150} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqsViewPlaceHolder;
