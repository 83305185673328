import { io } from "socket.io-client";
import { endPoint } from "../../Api/endPoint";
import { checkReadNotifications } from "./notifications";
import {
  deleteOtherMessage,
  getAllTickets,
  getTicketItem,
  refetchTicket,
  updateTicketArchive,
} from "./tickets";
import { fetchCreatedAction, fetchNewIntervention } from "./interventions";
import { getNewFile, getNewFolder, getNewFolderFile } from "./cloud";
import { getNewCompany } from "./societe";
import { getAllInvoices } from "./facture";
import { getNewClient } from "./clients";
import { fetchEventAction, fetchNewCalendar } from "./calendars";

import { checkPageStatus } from "../../Utils/utils";
import { GetAllDevis } from "./devis";
import { GetAllCommandes } from "./commande";
import { GetAllProspects } from "./prospect";
import { refetchMessage } from "./messages";

let MyIO = null;

export const socketConnect = () => async (dispatch, getState) => {
  try {
    MyIO = io(endPoint, {
      reconnection: true,
      autoConnect: true,
      pingTimeout: 25000,
      pingInterval: 5000,
    });

    const userId = getState().userReducer.user?.id;
    const {
      ticketRoute,
      clientsRoute,
      facturesRoute,
      documentsRoute,
      calendarRoute,
      selectedOrganisation,
    } = getState().organisationsReducer;

    MyIO.open();
    MyIO.on("connected", async ({ socketId }) => {
      dispatch({ type: "CONNECTED_SOCKET", payload: true });
      MyIO.id = socketId;
      MyIO.emit("save", {
        data: { socketId: MyIO.id, userId },
      });
      dispatch({ type: "CONNECT_SOCKET", payload: { MyIO: MyIO } });
    });
    MyIO.on("disconnect", () => {
      dispatch({ type: "CONNECTED_SOCKET", payload: false });
      // dispatch(socketConnect());
      // dispatch({ type: "CLEAR_SOCKET" });
    });

    MyIO.on("newMessage", async ({ data }) => {
      console.log(`${ticketRoute}/${data.ticketId}`);
      console.log(getState().socketReducer.route);
      if (
        `${ticketRoute}/${data.ticketId}` === getState().socketReducer.route
      ) {
        dispatch(getTicketItem(data.ticketId, () => {}, false));
      }
      if (data.organisation === selectedOrganisation.id) {
        dispatch(checkReadNotifications());
        dispatch(refetchTicket(data.ticketId));
      }
      checkPageStatus(data, ticketRoute, `${ticketRoute}/${data.ticketId}`);
    });
    MyIO.on("updatedMessage", async ({ data }) => {
      if (
        `${ticketRoute}/${data.ticketId}` === getState().socketReducer.route
      ) {
        dispatch(refetchMessage(data.id));
      }
    });
    MyIO.on("deletedMessage", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        dispatch(deleteOtherMessage(data));
      }
    });
    MyIO.on("newTicket", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        dispatch(checkReadNotifications());
        dispatch(getAllTickets(false));
      }

      checkPageStatus(data, ticketRoute);
    });
    MyIO.on("newTicketUpdated", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        if (`${ticketRoute}/${data.id}` === getState().socketReducer.route) {
          dispatch(getTicketItem(data.id, () => {}, false));
        } else {
          dispatch(getAllTickets(false));
        }
      }
    });
    MyIO.on("toggleTicketArchive", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        dispatch(checkReadNotifications());
        dispatch(updateTicketArchive(data));
      }
      checkPageStatus(data, ticketRoute);
    });
    MyIO.on("newIntervention", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        if (
          `${ticketRoute}/${data.ticketId}` === getState().socketReducer.route
        ) {
          dispatch(getTicketItem(data.ticketId, () => {}, false));
        }
        dispatch(checkReadNotifications());
        dispatch(fetchNewIntervention(data));
      }
      checkPageStatus(data, ticketRoute);
    });

    MyIO.on("newAction", async ({ data }) => {
      const route =
        data?.notificationType === "Action" ? ticketRoute : calendarRoute;
      if (data.organisation === selectedOrganisation.id) {
        if (`${route}/${data.ticketId}` === getState().socketReducer.route) {
          dispatch(getTicketItem(data.ticketId, () => {}, false));
        }

        dispatch(checkReadNotifications());
        dispatch(fetchEventAction(data));
        dispatch(fetchCreatedAction(data));
      }
      checkPageStatus(data, route);
    });

    MyIO.on("newCollaborator", async ({ data }) => {
      //////////////////////////////////////////////////
      dispatch(checkReadNotifications());
      dispatch(getNewClient(data));
      checkPageStatus(data, clientsRoute);
    });

    MyIO.on("newFacture", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        dispatch(getAllInvoices());
        dispatch(checkReadNotifications());
      }
      checkPageStatus(data, facturesRoute);
    });

    MyIO.on("fileShared", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        dispatch(checkReadNotifications());
        dispatch(getNewFile(data));
      }
      checkPageStatus(data, documentsRoute);
    });

    MyIO.on("folderShared", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        dispatch(checkReadNotifications());
        dispatch(getNewFolder(data));
      }
      checkPageStatus(data);
    });

    MyIO.on("folderUpdated", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        dispatch(checkReadNotifications());
        dispatch(getNewFolderFile(data));
      }
      checkPageStatus(data);
    });

    MyIO.on("newCompany", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        dispatch(getNewCompany(data));
      }
    });

    //update prospect status
    MyIO.on("statusUpdate", async () => {
      dispatch(GetAllDevis(1, [], { champs: "id", asc: false }, ""));
      dispatch(GetAllCommandes(1, [], { champs: "id", asc: false }, ""));
      dispatch(GetAllProspects(1, [], { champs: "id", asc: false }, ""));
    });

    MyIO.on("newMention", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        dispatch(checkReadNotifications());
      }
      checkPageStatus(data, ticketRoute);
    });

    MyIO.on("newCalendar", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        dispatch(checkReadNotifications());
        dispatch(fetchNewCalendar(data));
      }
      checkPageStatus(data, calendarRoute);
    });

    MyIO.on("calendarShared", async ({ data }) => {
      if (data.organisation === selectedOrganisation.id) {
        dispatch(checkReadNotifications());
        dispatch(fetchNewCalendar(data));
      }
      checkPageStatus(data, calendarRoute);
    });

    MyIO.on("elementCopied", async ({ data }) => {
      dispatch({ type: "ITEM_COPIED", payload: data });
    });
    MyIO.on("newReact", async ({ data }) => {
      if (
        data.organisation === selectedOrganisation.id &&
        `${ticketRoute}/${data.ticketId}` === getState().socketReducer.route
      ) {
        dispatch(refetchMessage(data.messageId));
        if (data.type === "create") {
          checkPageStatus(
            data,
            `${ticketRoute}`,
            `${ticketRoute}/${data.ticketId}`
          );
        }
      }
    });
  } catch (error) {
    dispatch({ type: "CLEAR_SOCKET" });
  }
};

export const handleDisconnectUser = () => async (dispatch, getState) => {
  const userId = getState().userReducer.user?.id;

  MyIO.emit("userDisconnected", {
    data: { userId },
  });
};

export const updateRoute = (pathname) => async (dispatch) => {
  dispatch({ type: "HIDE_SIDE_BAR_CARD" });
  dispatch({ type: "CLEAR_UPLOAD" });
  dispatch({ type: "UPDATE_ROUTE", payload: pathname });
};
