import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AlerteNew, NotificationNew } from "../../Common/LightDarkSvg";
import Alert from "../Alert/Alert";
import AlertTooltip from "../Alert/AlertToolTip";
import IconButton from "../../Common/Buttons/IconButton";

import { filterNotificationsByType } from "../Notification/notificationsUtils";

import { readAlert } from "../../../REDUX/actions/alerts";
import { openSideBar } from "../../../REDUX/actions/sideBar";
import NotificationsSideBar from "../Notification/NotificationsSideBar";

const HeaderIcons = ({ showNewAlertTooltip, setShowNewAlertTooltip }) => {
  const dispatch = useDispatch();

  const { unreadAlerts } = useSelector((state) => state.alertsReducer);
  const { notifications, showPoints } = useSelector(
    (state) => state.notificationsReducer
  );
  const [showAlert, setShowAlert] = useState(false);
  const [showNotification, setshowNotification] = useState(false);

  return (
    <div className="d-flex gap-3">
      <div className="position-relative">
        <IconButton
          icon={
            <AlerteNew clicked={showAlert} newAlerts={unreadAlerts.length} />
          }
          callBack={() => {
            setShowAlert(!showAlert);
            unreadAlerts.map((alert) => dispatch(readAlert(alert)));
          }}
          showAlertNotification={showAlert}
        />
        {showNewAlertTooltip && <AlertTooltip show={setShowNewAlertTooltip} />}

        {showAlert && <Alert show={setShowAlert} />}
      </div>
      <div className="position-relative">
        <IconButton
          icon={
            <NotificationNew
              clicked={showNotification}
              newNotifs={showPoints.showGeneralRedPoint}
            />
          }
          callBack={() => {
            dispatch(
              openSideBar({
                content: (
                  <NotificationsSideBar
                    show={setshowNotification}
                    notifications={filterNotificationsByType(
                      false,
                      notifications
                    )}
                  />
                ),
                title: "Notifications",
                type: "w40",
                sideBarToggle: "public",
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default HeaderIcons;
