export const FORM_ANSWER_ATTRIBUTES = `

            answer
           
           
          

`;

export const FORM_QUESTION_ATTRIBUTES = `

            name
            legend
            position
            status
            col
            input_type
            label_class
            is_required
            link
            accessor
            category {
                data{
                    id
                    attributes{
                        position
                        name
                    }
                }
            }
            answers {
                data{
                    
                    id
                    attributes{
                        ${FORM_ANSWER_ATTRIBUTES}
                    }
                }
            }
            conditions {
                data{
                    
                    id
                    attributes{
                       answer
                       condition{
                        data{
                            id
                            attributes{
                                name
                            }
                        }
                       }
                    }
                }
            }
            image {
                data{
                    id
                    attributes{
                        url
                    }
                }
            }
          

`;

// [
//   {
//     id: 1,
//     origin: "web",
//     fields: {
//       data: [
//         {
//           id: 1,
//           attributes: {
//             value: "mustapha",
//             question: {
//               data: {
//                 id: 1,
//                 attributes: { name: "prenom", accessor: "firstname" },
//               },
//             },
//           },
//         },
//         {
//             id: 2,
//             attributes: {
//               value: "majedd",
//               question: {
//                 data: {
//                   id: 1,
//                   attributes: { name: "nom", accessor: "lastname" },
//                 },
//               },
//             },
//           },
//           {
//             id: 2,
//             attributes: {
//               value: "select",
//               question: {
//                 data: {
//                   id: 1,
//                   attributes: { name: "nom", accessor: null },
//                 },
//               },
//             },
//           },
//       ],
//     },
//   },
//   {
//     id: 2,
//     origin: "intern",
//     fields: {
//       data: [
//         {
//           id: 1,
//           attributes: {
//             value: "heey",
//             question: {
//               data: {
//                 id: 1,
//                 attributes: { name: "prenom", accessor: "firstname" },
//               },
//             },
//           },
//         },
//         {
//             id: 2,
//             attributes: {
//               value: "heelloo",
//               question: {
//                 data: {
//                   id: 1,
//                   attributes: { name: "nom", accessor: "lastname" },
//                 },
//               },
//             },
//           },
//           {
//             id: 2,
//             attributes: {
//               value: "dede",
//               question: {
//                 data: {
//                   id: 1,
//                   attributes: { name: "nom", accessor: null },
//                 },
//               },
//             },
//           },
//       ],
//     },
//   },
// ];

// [
//     {
//       id: 1,
//       firstname: "mustapha",
//       lastname: "majedd",

//       origin: "web",

//     },
//     {
//       id: 2,
//       firstname: "heey",
//       lastname: "heelloo",

//       origin: "Interne",

//     },
//   ];
