import { gql } from "@apollo/client";
import { INVOICE_ATTRIBUTES } from "../Attributes/Factures";

export const CREATE_INVOICE = gql`
  mutation ($number: Long, $societe: ID, $date: Date, $type: ENUM_FACTURE_TYPE, $HT: Float, $TVA: Float, $TTC: Float, $media: [ID], $orgId: ID!) {
    createFacture(
      data: { 
          number: $number
          societe: $societe
          type: $type
          date: $date
          media: $media
          HT: $HT
          TVA: $TVA
          TTC: $TTC
          organisation: $orgId
      }
    ) 
    {
      data {
        id
        attributes {
          ${INVOICE_ATTRIBUTES}
        }
      }
    }
  }
`;

export const DELETE_INVOICE = (id) => gql`
mutation  {
  deleteFacture(id: ${id})
  {
    data{
     id 
    }
    
  }
}
`;
