import React from "react";

import MessageTime from "./MessageTime";
import MessageSender from "./MessageSender";

const MessageCardFooter = ({ message, setMeSending, note, showFullDate }) => {
  return (
    <div className={`d-flex justify-content-between ${note ? "mt-2" : "mt-4"}`}>
      <MessageSender
        sender={message?.attributes?.sender?.data}
        message={message}
        setMeSending={setMeSending}
      />

      <MessageTime message={message} showFullDate={showFullDate} />
    </div>
  );
};

export default MessageCardFooter;
