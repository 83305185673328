import {
  FORM_ANSWER_ATTRIBUTES,
  FORM_QUESTION_ATTRIBUTES,
} from "./formQuestion";

export const ATTRIBUTES_PROSPECT_FIELD = `
value
form_answers{
    data {
        id
        attributes {
          ${FORM_ANSWER_ATTRIBUTES}
        }
      }
}
file{
  data {
    id
    attributes {
        url
        name
        size
        mime
    }
  }
}
question{
    data {
        id
        attributes {
          ${FORM_QUESTION_ATTRIBUTES}
        }
      }
}


`;
