import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import FactureForm from "../../Components/Factures/FacturesForm";
import FacturesList from "../../Components/Factures/FacturesList";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";

import useWindowWidth from "../../Hooks/useWindowWidth";

import { openModal } from "../../REDUX/actions/modal";

const FacturesView = () => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const { canCreateInvoices } = useSelector((state) => state.roleReducer);

  const [searchFactures, setsearchFactures] = useState("");

  return (
    <div className="container-fluid">
      <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className="col-10 h100">
          <ListHeader isInvoiceHeader>
            <SearchInput
              placeholder="N°, société..."
              onChange={(e) => setsearchFactures(e.target.value)}
            />
          </ListHeader>
        </div>
        {canCreateInvoices && (
          <div className="col-2 ms-2 h100">
            <ColoredButton
              height="100"
              textButton={screenWidth > 600 && "Importer une facture"}
              color="light-blue"
              icon={screenWidth < 600 && "plus"}
              callback={() =>
                dispatch(
                  openModal({
                    type: "small",
                    content: <FactureForm />,
                  })
                )
              }
            />
          </div>
        )}
      </div>
      <FacturesList searchInvoice={searchFactures} />
    </div>
  );
};

export default FacturesView;
