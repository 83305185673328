import React, { useEffect, useState } from "react";
import LightDarkText from "../../../../Common/LightDarkText";
import { inputSelectTypes, inputTextTypes } from "./interviewUtils";
import InterviewTextInput from "./InterviewInputs/InterviewTextInput";
import { useDispatch } from "react-redux";
import { createResponse } from "../../../../../REDUX/actions/interviews";
import InterviewSelectInput from "./InterviewInputs/InterviewSelectInput";

const InterventionQuestionRespond = ({
  question,
  step,
  setStep,
  modelId,
  response,
  noMoreModifications,
}) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(
    response
      ? inputSelectTypes.includes(question.attributes.type)
        ? (response?.attributes?.response_options?.data || []).map((el) => {
            return { label: el.attributes.value, value: el.id };
          }) || []
        : response.attributes.value || null
      : inputSelectTypes.includes(question.attributes.type)
      ? []
      : null
  );

  useEffect(() => {
    setValue(
      response
        ? inputSelectTypes.includes(question.attributes.type)
          ? (response?.attributes?.response_options?.data || []).map((el) => {
              return { label: el.attributes.value, value: el.id };
            }) || []
          : response.attributes.value || null
        : inputSelectTypes.includes(question.attributes.type)
        ? []
        : null
    );
  }, [response]);

  const handleSubmit = () => {
    dispatch(createResponse(question, value, modelId, response?.id));

    setStep(step + 1);
  };
  return (
    <div className="f12">
      <LightDarkText
        className={`f14 bold ${
          question.attributes.order !== step && !noMoreModifications
            ? "pointer"
            : ""
        }`}
        color={"text-light-blue xbold"}
      >
        {`${question.attributes.logicOrder} - ${question.attributes.title}`}{" "}
        {question.attributes.required && <span className="text-red">*</span>}
      </LightDarkText>
      {inputTextTypes.includes(question.attributes.type) ? (
        <InterviewTextInput
          question={question}
          value={value}
          setValue={setValue}
          handleSubmit={handleSubmit}
          step={step}
          setStep={setStep}
          disabled={noMoreModifications}
        />
      ) : null}
      {inputSelectTypes.includes(question.attributes.type) ? (
        <InterviewSelectInput
          question={question}
          value={value}
          setValue={setValue}
          handleSubmit={handleSubmit}
          step={step}
          setStep={setStep}
          disabled={noMoreModifications}
        />
      ) : null}
    </div>
  );
};

export default InterventionQuestionRespond;
