import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./Charts.css";
import LightDarkContainer from "../Common/LightDarkContainer";
import { useSelector } from "react-redux";

const PieChart = ({
  title,
  infos,
  tootltipLabel = "tickets",
  cutout = "90%",
  showInside = false,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  ChartJS.register(ArcElement, Tooltip);
  const insideLabelPlugin = {
    id: "insideLabelPlugin",
    afterDatasetDraw(chart) {
      const {
        ctx,
        data: { datasets },
      } = chart;
      const dataset = datasets[0];

      chart.getDatasetMeta(0).data.forEach((arc, index) => {
        const { x, y } = arc.tooltipPosition();
        const label = chart.data.labels[index];
        const value = dataset.data[index];

        const maxLabelLength = 20; // Maximum characters per line
        const lines =
          label.length > maxLabelLength
            ? label.match(new RegExp(`.{1,${maxLabelLength}}`, "g")) // Split into chunks
            : [label];
        ctx.save();
        ctx.font = "bold 14px Poppins";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#ffffff";

        // Draw the label
        lines.forEach((line, i) => {
          ctx.fillText(line, x, y - 10 - (lines.length - 1 - i) * 14); // Offset for each line
        });

        // Draw the value
        ctx.fillText(value, x, y + 10);
        ctx.restore();
      });
    },
  };

  const options = {
    cutout: cutout,
    maintainAspectRatio: false,
    label: true,
    plugins: {
      title: {
        display: true,
        text: title,
        align: "start",
        margin: 20,
        color: mode === "light" ? "#59638b" : "white",
        font: {
          weight: "bold",
          size: 18,
        },
      },
      legend: {
        display: true,
        position: "right",
        align: "center",
        fullSize: true,

        labels: {
          padding: 15,
          color: mode === "light" ? "#59638b" : "white",
          usePointStyle: true,
          boxHeight: 8,
          font: {
            weight: "bold",
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return ` ${context.label?.toUpperCase()}: ${
              context.formattedValue
            } ${tootltipLabel}`;
          },
        },
      },
    },
  };
  const data = {
    labels: infos?.map((el) => el.name),
    datasets: [
      {
        data: infos?.map((el) => el.value),
        backgroundColor: infos?.map((el) => el.color),
        borderColor: infos?.map((el) => el.color),
        hoverBorderWidth: 0,
        hoverBorderColor: infos?.map((el) => el.color),
        borderWidth: 3,
        borderColor: mode === "light" ? "white" : "#181f3a81",
        borderRadius: 8,
      },
    ],
  };

  return (
    <LightDarkContainer
      className="py-4 radius10 px-4 h100"
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <Doughnut
        options={options}
        data={data}
        className="PieChartChart"
        plugins={showInside ? [insideLabelPlugin] : []}
      />
    </LightDarkContainer>
  );
};

export default PieChart;
