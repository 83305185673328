import React from "react";
import {
  CardActionsIcon,
  HorzontalLines,
  VerticalPoints,
} from "../LightDarkSvg";
import SettingTooltip from "./SettingTooltip";
import Tooltipitem from "./Tooltipitem";

const TooltipActions = ({
  showTooltip,
  setshowTooltip,
  tooltipData,
  isVertical = false,
  isHorizontal = false,
  left,
}) => {
  return (
    tooltipData.some((item) => item.condition === true) && (
      <div className="customindex">
        {isHorizontal ? (
          <HorzontalLines
            clickCallback={(e) => {
              e.stopPropagation();
              setshowTooltip(!showTooltip);
            }}
          />
        ) : isVertical ? (
          <VerticalPoints
            showTooltip={showTooltip}
            clickCallback={(e) => {
              e.stopPropagation();
              setshowTooltip(!showTooltip);
            }}
          />
        ) : (
          <CardActionsIcon
            clickCallback={(e) => {
              e.stopPropagation();
              setshowTooltip(!showTooltip);
            }}
          />
        )}
        <div
          style={{
            position: left ? "fixed" : "relative",
            zIndex: 500,
            left: left || null,
          }}
        >
          {showTooltip && (
            <SettingTooltip
              showTooltip={showTooltip}
              setshowTooltip={setshowTooltip}
            >
              {tooltipData
                .filter((item) => item.condition)
                .map(({ id, text, action, icon, disabled, toolTipText }) => {
                  return (
                    <Tooltipitem
                      toolTipText={toolTipText}
                      disabled={disabled}
                      key={id}
                      id={id}
                      text={text}
                      action={action}
                      icon={icon}
                      lastIndex={tooltipData.length}
                    />
                  );
                })}
            </SettingTooltip>
          )}
        </div>
      </div>
    )
  );
};

export default TooltipActions;
