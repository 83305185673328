import { transformTicketData } from "../../Utils/utils";

const initialState = {
  tickets: [],
  ticketsLoading: false,
  ticketLoading: false,
  ticket: {},
  openedTicketId: "",
  expandedChatList: true,
  filteredTickets: [],
};

const ticketsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "EXPANDED_CHAT_LIST":
      return { ...state, expandedChatList: payload };
    case "RESET_ORGANISATION":
      return { ...initialState };
    case "REMOVE_FAVORITE_MESSAGE":
      let updatedTicket = state.ticket;
      updatedTicket.attributes.message_favorites.data =
        state.ticket.attributes.message_favorites.data.filter(
          (el) => el.id !== payload
        );

      return { ...state, ticket: updatedTicket };

    case "ADD_FAVORITE_MESSAGE":
      let newTicket = state.ticket;
      newTicket.attributes.message_favorites.data = [
        payload,
        ...state.ticket.attributes.message_favorites.data,
      ];

      return { ...state, ticket: newTicket };
    case "TICKET_CREATED": {
      if (!payload) {
        return {
          ...state,
          ticketsLoading: false,
        };
      }
      return {
        ...state,
        tickets: [transformTicketData(payload), ...state.tickets],
        ticketsLoading: false,
      };
    }

    case "FETCH_TICKETS":
      let tickets = payload.map((ticket) => {
        return transformTicketData(ticket);
      });

      return {
        ...state,
        tickets: tickets,
        ticketsLoading: false,
      };

    case "HANDLE_FILTER_TICKETS":
      return {
        ...state,
        filteredTickets: payload,
      };
    case "REFETCH_TICKETS":
      return {
        ...state,
        tickets: state.tickets.map((el) =>
          el.id === payload.id ? transformTicketData(payload) : el
        ),
        ticketsLoading: false,
      };
    case "FETCH_TICKET_ITEM_LOADING": {
      return { ...state, ticketLoading: true };
    }

    case "FETCH_TICKET_ITEM": {
      return {
        ...state,
        tickets: state.tickets.map((item) =>
          +item?.id === +payload?.id ? transformTicketData(payload) : item
        ),
        ticket: transformTicketData(payload),
        ticketsLoading: false,
        ticketLoading: false,
        openedTicketId: payload.id,
      };
    }

    // case "FETCH_UPDATED_TICKET_ITEM": {
    //   const ticket = payload?.ticket;
    //   const user = payload?.user;
    //   let isFound = false;

    //   const updatedTickets = state.tickets.map((item) => {
    //     if (+item?.id === +ticket?.id) {
    //       isFound = true;
    //       return transformTicketData(ticket);
    //     }
    //     return item;
    //   });

    //   if (!isFound && user?.attributes?.role?.data?.id === "6") {
    //     updatedTickets.unshift(transformTicketData(ticket));
    //   }

    //   return {
    //     ...state,
    //     tickets: updatedTickets,
    //     ticket:
    //       +ticket?.id === +state?.ticket?.id
    //         ? transformTicketData(ticket)
    //         : state?.ticket,
    //     ticketsLoading: false,
    //     openedTicketId: ticket.id,
    //   };
    // }
    case "FETCH_NEW_TICKET":
      return {
        ...state,
        tickets: [transformTicketData(payload), ...state.tickets],
      };

    case "UPDATE_TICKET_INTERVIENTS": {
      if (!state?.ticket?.attributes?.interventions) return state;
      let isFound = false;
      const updatedTicket = state?.ticket;
      updatedTicket.attributes.interventions.data =
        updatedTicket?.attributes?.interventions?.data?.map((item) => {
          if (+item?.id === +payload?.id) {
            isFound = true;
            return payload;
          }
          return item;
        });

      if (!isFound) {
        updatedTicket.attributes.interventions.data = [
          ...updatedTicket?.attributes?.interventions?.data,
          payload,
        ];
      }

      return {
        ...state,
        ticket: updatedTicket,
      };
    }

    case "UPDATE_TICKET_FILES": {
      const updatedTicket = state?.ticket;
      updatedTicket.attributes.fichiers.data = [
        ...updatedTicket?.attributes?.fichiers?.data,
        ...payload,
      ];
      return {
        ...state,
        ticket: updatedTicket,
      };
    }

    case "TICKETS_LOADING":
      return { ...state, ticketsLoading: true };
    // case "UPDATE_TICKET":
    //   return {
    //     ...state,
    //     tickets: state.tickets.map((ticket) =>
    //       ticket.id === payload.id ? transformTicketData(payload) : ticket
    //     ),
    //     ticket: transformTicketData(payload),
    //     ticketsLoading: false,
    //   };

    case "DELETE_TICKET_ARCHIVED": {
      return { ...state, tickets: payload };
    }

    default:
      return state;
  }
};
export default ticketsReducer;
