export const handleQuestionType = (question) => {
  switch (question.attributes.type) {
    case "text":
      return "Texte";
    case "number":
      return "Numérique";

    case "email":
      return "Email";

    case "date":
      return "Date";

    case "radio":
      return "Choix unique";

    case "multiSelect":
      return "Multi-Select";

    case "file":
      return "Fichier";

    case "image":
      return "Image";

    default:
      return "Non mentionné";
  }
};

const checkCondition = (operator, value1, value2) => {
  switch (operator) {
    case "EQUAL":
      return value1 === value2;
    case "NOT_EQUAL":
      return value1 !== value2;
    case "GREATER_THAN":
      return value1 > value2;
    case "LESS_THAN":
      return value1 < value2;
    case "INCLUDES":
      return value1.includes(value2);
    case "GREATER_THAN_OR_EQUAL":
      return value1 >= value2;
    case "LESS_THAN_OR_EQUAL":
      return value1 <= value2;
    case "CASE_SENSITIVE_EQUAL":
      return value1.localeCompare(value2) === 0;
    case "CASE_SENSITIVE_NOT_EQUAL":
      return value1.localeCompare(value2) !== 0;
    case "CASE_INSENSITIVE_EQUAL":
      return (
        value1.localeCompare(value2, undefined, { sensitivity: "base" }) === 0
      );
    case "CASE_INSENSITIVE_NOT_EQUAL":
      return (
        value1.localeCompare(value2, undefined, { sensitivity: "base" }) !== 0
      );
    default:
      return false;
  }
};

export const evaluateCondition = (question, responses) => {
  if (!question.attributes.conditions.data.length) {
    return { show: true, shouldSkip: false };
  }

  for (let i = 0; i < question.attributes.conditions.data.length; i++) {
    const condition = question.attributes.conditions.data[i];

    const responseToCompare = responses.find(
      (el) =>
        el.attributes.interview_question.data.id ===
        condition.attributes.compareTo.data.id
    );

    if (
      !checkCondition(
        condition.attributes.compareOperator.data.attributes.code,
        condition.attributes.comparedValue,
        responseToCompare?.attributes?.value
      )
    ) {
      return { show: false, shouldSkip: true };
    }
  }

  return { show: true, shouldSkip: false };
};

export const inputTextTypes = ["text", "number", "date", "email"];
export const inputSelectTypes = ["radio", "multiSelect"];
