export const societyModel = {
  name: "",
  siret: "",
  email: "",
  adress: "",
  phoneNumber: "",
  city: "",
  zipCode: "",
  archived: false,
  emailFacture: "",
  siteWeb: "",
  monthlyPlan: 0,
  monthlyQuota: 0,
  archive: false,
  society_type: [],
};
