import React, { useEffect } from "react";
import useUpload from "../../Hooks/useUpload";
import { imageLoader } from "../../Utils/utils";

import uploadIcon from "./Icons/upload-icon.svg";

import "./Uploader.css";

const Uploader = ({
  id,
  name,
  placeholder,
  defaultImage,
  setState,
  state,
  squareUploader = false,
}) => {
  const { file, preview, handleChange, initialState } = useUpload();

  useEffect(() => {
    setState && file && setState(file);
  }, [file, setState]);

  useEffect(() => {
    if (!file) {
      initialState(state);
    }
  }, [initialState, state, file]);

  return (
    <div className={`alignC flex-column`}>
      <label
        htmlFor={id}
        className={`uploadContainer border ${
          squareUploader ? "squaredUploader" : "roundedUploader"
        }  alignC flex-column px-3 overflow-hidden position-relative cursor-pointer ${
          file || defaultImage ? "has-preview" : ""
        } ${file || defaultImage ? "" : "bg-dark-message"}`}
        style={{
          border: `${file || defaultImage ? "" : "1px dashed #414a6e"}`,
        }}
      >
        <img src={uploadIcon} alt="" className="z-index-1 icon" />
        <input
          type="file"
          name={name}
          id={id}
          hidden
          onChange={handleChange}
          accept="image/*"
        />
        {(defaultImage || preview) && (
          <img
            src={
              state && !preview ? null : preview ?? imageLoader(defaultImage)
            }
            alt=""
            className="preview"
          />
        )}
        <span className="mt-2 text-white text-center custom-ticket-font-size z-index-1 medium text">
          {file || defaultImage ? "Modifier la photo de profil" : placeholder}
        </span>
      </label>
    </div>
  );
};

export default Uploader;
