import React, { useEffect } from "react";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import { useDispatch, useSelector } from "react-redux";
import UserForm from "../../Clients/UserForm/UserForm";
import SocietyForm from "../SocietyForm/SocietyForm";
import { openSideBar } from "../../../REDUX/actions/sideBar";
import {
  getSocietyCollaborateurs,
  getSocietyItem,
  getSocietyOperators,
} from "../../../REDUX/actions/societe";
import { useParams } from "react-router-dom";

const SocietyItemRightButtons = ({ isToggle }) => {
  const { canCreateCollabs, canCreateOperators } = useSelector(
    (state) => state.roleReducer
  );
  const params = useParams();

  useEffect(() => {
    dispatch(getSocietyItem(params.societyId));
  }, [params.societyId]);

  const { societe } = useSelector((state) => state.societesReducer);

  const dispatch = useDispatch();

  return (
    <div className="h50px w-100 w-100">
      {isToggle === "linkedSocieties" && (
        <ColoredButton
          height="100"
          textButton={"Lier une société"}
          color="light-blue"
          callback={() =>
            dispatch(
              openSideBar({
                content: <SocietyForm societyId={societe.id} isLinked={true} />,
                title: `Éditer la société ${societe.attributes.name}`,
                type: "w40",
                sideBarToggle: "link_societies",
              })
            )
          }
        />
      )}
      {isToggle === "ops" && canCreateOperators && (
        <ColoredButton
          height="100"
          textButton={"Ajouter un opérateur"}
          color="light-blue"
          callback={() =>
            dispatch(
              openSideBar({
                content: (
                  <UserForm
                    isNew
                    isOp={true}
                    afterSubmitCallback={() => {
                      dispatch(getSocietyOperators(false));
                    }}
                  />
                ),
                type: "w40",
              })
            )
          }
        />
      )}
      {isToggle === "collab" && canCreateCollabs && (
        <ColoredButton
          height="100"
          textButton={"Ajouter un collaborateur"}
          color="light-blue"
          callback={() =>
            dispatch(
              openSideBar({
                content: (
                  <UserForm
                    isNew
                    afterSubmitCallback={() => {
                      dispatch(getSocietyCollaborateurs(false));
                    }}
                  />
                ),
                type: "w40",
              })
            )
          }
        />
      )}
    </div>
  );
};

export default SocietyItemRightButtons;
