import React from "react";
import SocietyAvatar from "./SocietyAvatar";
import LightDarkContainer from "../../Common/LightDarkContainer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LinkedSociety = ({ society }) => {
  const navigate = useNavigate();
  const { societeRoute } = useSelector((state) => state.organisationsReducer);

  return (
    <LightDarkContainer
      colorLight="white"
      className="linkedSocietyContainer  gap-2 d-flex flex-column radius05 pointer border"
    >
      {society.attributes.childCompanies.data.map((childCompany) => (
        <div
          onClick={() => navigate(`${societeRoute}/${childCompany.id}`)}
          key={childCompany.id}
          className="alignC gap-2 f12 text-blue-grey xbold tooltip-item p-2"
        >
          <div className="w20">
            <SocietyAvatar
              imageUrl={
                childCompany.attributes.avatar.data?.attributes?.image?.data
                  ?.attributes?.url
              }
              width="32px"
              height="32px"
            />
          </div>
          <div className="flex-1 text-start">
            {childCompany.attributes.name}
          </div>
        </div>
      ))}
    </LightDarkContainer>
  );
};

export default LinkedSociety;
