import React from "react";
import "./Loading.css";

const Loading = ({ showLoader }) => {
  return showLoader ? (
    <div className="Loadingcontainer alignC">
      <div className="loader"></div>
      <img src="/loadingLogo.svg" className="loaderInnerLogo" alt="" />
    </div>
  ) : null;
};

export default Loading;
