import { populateUser } from "../../Utils/utils";

const initialState = {
  operateurs: [],
  operatorsLoading: false,
};

const operateursReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "OPERATORS_LOADING":
      return { ...state, operatorsLoading: true };
    case "FETCH_OPERATORS":
      let operateurs = payload?.map((operateur) => {
        return {
          ...operateur,
          attributes: {
            ...operateur.attributes,
            ...populateUser(operateur, false),
          },
        };
      });
      return { ...state, operateurs: operateurs, operatorsLoading: false };

    default:
      return state;
  }
};
export default operateursReducer;
