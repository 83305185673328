import { gql } from "@apollo/client";
import { CALENDAR_ATTRIBUTES } from "../Attributes/Calendars";
import { EVENT_ATTRIBUTES } from "../Attributes/Events";
import { USER_ORG_ATTRIBUTES_LIGHTER } from "../Attributes/UserOrg";
import { userAttributes } from "../general/userFields";

export const GET_CALENDARS = gql`
  query ($user: [ID],$orgId: ID!) {
    calendars(
      pagination: { page: 1, pageSize: 100 }
      sort: ["name:asc"]
      filters: { 
        and : 
        [
          {   
            or: [
              { owner: { id: { in: $user } } } 
              { sharedUsers: { id: { in: $user } } } 
            ]
          },
          {organisation : { id: { eq: $orgId } } }
        ]
      }
    ) {
      data {
        id
        attributes {
          ${CALENDAR_ATTRIBUTES}
          sharedUsers (pagination: {page: 1, pageSize: 100}) {
            data {
              id
              attributes {
                ${userAttributes}
                user_org_permissions (
                  filters: {
                    organisation: { id: { eq: $orgId} }  
                  }
                ) 
                {
                  data {
                    id
                    attributes {
                      ${USER_ORG_ATTRIBUTES_LIGHTER}
                    }
                  }
                }
                avatar {
                  data {
                    id
                    attributes {
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          events(
            pagination: {page: 1, pageSize: 10000}
            filters: { 
              and : 
                [
                  {   
                    or: [
                      { creator: { id: { in: $user } } } 
                      { participants: { id : { in: $user } } } 
                      { calendar: { owner: { id: { in: $user } } } }
                    ]
                  },
                  {organisation : { id: { eq: $orgId } } }
                ]
            }
          ) {
              data {
                id
                attributes {
                  ${EVENT_ATTRIBUTES}
                }
              }
            }
        }
      }
    }
  }
`;

export const GET_CALENDAR_BY_ID = (id) => gql`
  query ($user: [ID],$orgId: ID!) {
    calendars(filters: { id: { eq: ${id} }
      and : 
        [
          {   
            or: [
              { owner: { id: { in: $user } } } 
              { sharedUsers: { id: { in: $user } } } 
            ]
          },
          {organisation : { id: { eq: $orgId } } }
        ]
    }) {
        data {
          id
          attributes {
            ${CALENDAR_ATTRIBUTES}
            sharedUsers (pagination: {page: 1, pageSize: 100}) {
              data {
                id
                attributes {
                  ${userAttributes}
                  user_org_permissions (
                    filters: {
                      organisation: { id: { eq: $orgId} }  
                    }
                  ) 
                    {
                      data {
                        id
                        attributes {
                          ${USER_ORG_ATTRIBUTES_LIGHTER}
                        }
                      }
                    }
                  avatar {
                    data {
                      id
                      attributes {
                        image {
                          data {
                            id
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            events(
              pagination: {page: 1, pageSize: 10000}
              filters: { 
                or: [
                  { creator: { id: { in: $user } } } 
                  { participants: { id : { in: $user } } } 
                  { calendar: { owner: { id: { in: $user } } } }
                ]
              }
            ) {
                data {
                  id
                  attributes {
                    ${EVENT_ATTRIBUTES}
                  }
                }
              }     
          }
      }
    }
  }
`;

export const GET_EVENT_BY_ID = gql`
  query ($id: ID!,$orgId: ID!) {
    event(id: $id) {
      data {
        id
        attributes {
          ${EVENT_ATTRIBUTES}
        }
      }
    }
  }
`;
