import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ColoredButton from "../../../Common/Buttons/ColoredButton";
import LightDarkText from "../../../Common/LightDarkText";
import { CloseIcon } from "../../../Common/LightDarkSvg";

import { closeModal } from "../../../../REDUX/actions/modal";
import { validateEvent } from "../../../../REDUX/actions/calendars";
import LightDarkTextEditor from "../../../Common/LightDarkTextEditor";

const EventRevision = ({ event }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);

  const [revisionInput, setRevisionInput] = useState({ motif: "" });

  return (
    <div
      className={`p-4 directionC radius05 ${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      }`}
    >
      <div
        className="d-flex justify-content-end"
        onClick={() => dispatch(closeModal())}
      >
        <CloseIcon />
      </div>
      <div className="px-2 mb-4">
        <LightDarkText className="text-center custom-title-font-size bold mx-5">
          Demander une révision
        </LightDarkText>
      </div>
      <LightDarkTextEditor
        height={"100"}
        totalInputs={revisionInput}
        setValue={setRevisionInput}
        champs="motif"
        value={revisionInput}
        placeholder="Veuillez noter la raison de cette demande de révision"
        autoHeight
      />
      <div className="col-3 align-self-center mt-4">
        <ColoredButton
          color="light-blue"
          textButton="Valider"
          callback={() => {
            dispatch(
              validateEvent({
                id: event?.id,
                calendar: event?.calendar?.data,
                motif: revisionInput?.motif,
              })
            );
            dispatch(closeModal());
          }}
        />
      </div>
    </div>
  );
};

export default EventRevision;
