import Api from "../../Api/Api";
import { inputSelectTypes } from "../../Components/Tickets/TicketMessages/MessagesTypes/InterviewMessages/interviewUtils";
import { getTicketItem } from "./tickets";

export const getInterviewForm =
  (modelId, withLoading = true) =>
  async (dispatch, getState) => {
    try {
      withLoading && dispatch({ type: "INTERVIEW_LOADING" });
      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      const ticketId = getState().ticketsReducer.ticket.id;

      if (!orgId) {
        return;
      }
      const { data } = await Api.get(
        `/api/getInterviewForm?organisation=${orgId}&ticketId=${ticketId}&modelId=${modelId}`
      );

      dispatch({ type: "FETCH_INTERVIEW", payload: data.data });
    } catch (error) {}
  };

export const getInterviewInformations = (modelId) => async (dispatch) => {
  try {
    dispatch({ type: "INTERVIEW_LOADING" });
    const { data } = await Api.get(`/api/getInterviewInformations/${modelId}`);

    dispatch({ type: "FETCH_INTERVIEW_INFORMATIONS", payload: data.data });
  } catch (error) {}
};

const formatResponse = (question, value) => {
  return inputSelectTypes.includes(question.attributes.type)
    ? {
        value: value.map((el) => el.label).join(", "),
        response_options: value.map((el) => el.value),
      }
    : { value: value, response_options: [] };
};

export const createResponse =
  (question, value, modelId, existingResponseId) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "RESPONDING_LOADING" });
      const formattedValue = formatResponse(question, value);

      const interviewForm = getState().interviewsReducer.interviewForm;
      const user = getState().userReducer.user;
      const orgId = getState().organisationsReducer.selectedOrganisation.id;

      if (existingResponseId) {
        await Api.post(`/api/handleDeleteResponse?organisation=${orgId}`, {
          data: {
            existingResponseId: existingResponseId,
            questionId: question.id,
            interviewId: interviewForm.id,
          },
        });
      }

      await Api.post(`/api/interview-responses`, {
        data: {
          name: `Interview : ${interviewForm.attributes.name} - Question ${question.attributes.title} - User ${user.attributes.firstName} ${user.attributes.lastName}`,
          ...formattedValue,
          user: user.id,
          interview: interviewForm.id,
          organisation: orgId,
          interview_question: question.id,
        },
      });

      dispatch(getInterviewForm(modelId, false));
    } catch (error) {}
  };

export const ValidateInterview = () => async (dispatch, getState) => {
  try {
    const interviewForm = getState().interviewsReducer.interviewForm;
    const ticket = getState().ticketsReducer.ticket;
    const orgId = getState().organisationsReducer.selectedOrganisation.id;

    await Api.post(`/api/handleValidateInterview?organisation=${orgId}`, {
      data: {
        interviewId: interviewForm.id,
      },
    });

    dispatch(getTicketItem(ticket.id, () => {}, false));
  } catch (error) {}
};

export const getInterviewResponses =
  (modelId, interviewUserId) => async (dispatch, getState) => {
    try {
      dispatch({ type: "INTERVIEW_LOADING" });
      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      const ticketId = getState().ticketsReducer.ticket.id;

      if (!orgId) {
        return;
      }
      const { data } = await Api.get(
        `/api/getInterviewResponses?organisation=${orgId}&ticketId=${ticketId}&modelId=${modelId}&interviewUserId=${interviewUserId}`
      );

      dispatch({ type: "FETCH_INTERVIEW_RESPONSE", payload: data.data });
    } catch (error) {}
  };

export const getInterviewUsers =
  (modelId, interviewUserId) => async (dispatch, getState) => {
    try {
      dispatch({ type: "INTERVIEW_LOADING" });
      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      const ticketId = getState().ticketsReducer.ticket.id;

      if (!orgId) {
        return;
      }
      const { data } = await Api.get(
        `/api/getInterviewUsers?organisation=${orgId}&ticketId=${ticketId}&modelId=${modelId}&interviewUserId=${interviewUserId}`
      );

      dispatch({ type: "FETCH_INTERVIEW_USERS", payload: data.data.data });
    } catch (error) {}
  };
