import { resetSelectedDevices } from "./sharedData";

export const openSideBar = (data) => async (dispatch) => {
  dispatch({
    type: "OPEN_SIDE_BAR",
    payload: data,
  });
};

export const setSideBarToggel = (sideBarToggle) => async (dispatch) => {
  dispatch({
    type: "SET_SIDE_BAR_TOGGEL",
    payload: sideBarToggle,
  });
};

export const closeSideBar = () => async (dispatch) => {
  dispatch(resetSelectedDevices());
  dispatch({ type: "RESET_SIDE_BAR_FORM" });
  dispatch({
    type: "CLOSE_SIDE_BAR",
  });
};
