import client from "./Client";

import {
  CREATE_AVATAR,
  DELETE_FILE,
  UPDATE_AVATAR,
  UPLOAD,
} from "../Graphql/Mutations/files";
import { GET_AVATAR_SOCIETE, GET_AVATAR_USER } from "../Graphql/Queries/files";

export const uploadFile = (file, userId, societeId) => async (dispatch) => {
  try {
    if (!file) return;
    dispatch({ type: "UPLOAD_FILE_LOAD" });
    const { data: currentAvatar } = await client.query({
      query: userId ? GET_AVATAR_USER : societeId ? GET_AVATAR_SOCIETE : null,
      variables: {
        userId,
        societeId,
      },
    });
    const { data } = await client.mutate({
      mutation: UPLOAD,
      variables: {
        file: file,
      },
    });
    const fileId = data.upload.data.id;

    if (!currentAvatar?.avatars?.data?.length > 0) {
      await client.mutate({
        mutation: CREATE_AVATAR,
        variables: {
          image: fileId,
          user: userId,
          societe: societeId,
        },
      });
      return;
    } else {
      const currentAvatarId = currentAvatar?.avatars?.data[0]?.id;
      const currentImageId =
        currentAvatar?.avatars?.data[0]?.attributes?.image?.data?.id;

      currentAvatarId &&
        (await client.mutate({
          mutation: UPDATE_AVATAR,
          variables: {
            id: currentAvatarId,
            imageId: fileId,
          },
        }));

      currentImageId &&
        (await client.mutate({
          mutation: DELETE_FILE,
          variables: {
            id: currentImageId,
          },
        }));
    }
    dispatch({ type: "UPLOAD_FILE_SUCC" });
  } catch (error) {
    dispatch({ type: "UPLOAD_FILE_FAIL", payload: error });
  }
};
