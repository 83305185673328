import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LightDarkText from "../../Common/LightDarkText";
import { Spinner } from "react-bootstrap";
import oneDriveLogo from "../../../Images/apiIcons/oneDrive.svg";
import sharePointLogo from "../../../Images/apiIcons/sharePoint.svg";
import SelectCloudBox from "../SelectCloudBox";
import CloudFileCard from "./CloudFileCard";

const OneDriveFileList = ({ search, oneDriveFiles, siteId = null }) => {
  const { oneDriveLoading } = useSelector(
    (state) => state.externalServicesReducer
  );

  const [filteredFiles, setFilteredFiles] = useState(oneDriveFiles);
  useEffect(() => {
    setFilteredFiles(
      oneDriveFiles.filter((el) =>
        el.name?.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, oneDriveFiles]);

  const [selected, setselected] = useState([]);

  return (
    <div className="position-relative text-dark-blue mt-5">
      <LightDarkText className="custom-title-font-size  mb-3 bold text-start alignH gap-2">
        <div
          className="d-flex gap-3 alignC"
          style={{ width: "20px", height: "30px" }}
        >
          {oneDriveLoading ? (
            <Spinner size="sm" />
          ) : (
            <img
              src={siteId ? sharePointLogo : oneDriveLogo}
              alt={"oneDrive"}
            />
          )}
        </div>
        Fichiers {siteId ? "SharePoint" : "OneDrive"}
      </LightDarkText>
      <SelectCloudBox
        key={2}
        selected={selected}
        setselected={setselected}
        allItems={filteredFiles.map((el) => {
          return {
            name: el.name,
            id: el.id,
            mimeType: el.file.mimeType,
            url: el["@microsoft.graph.downloadUrl"],
          };
        })}
        type="oneDriveFile"
      />
      <div className="d-flex flex-wrap gap-4 ps-1 pt-4">
        {filteredFiles.map((file, index) => (
          <CloudFileCard
            name={file.name}
            id={file.id}
            mimeType={file.file.mimeType}
            size={file.size}
            creationDate={file.createdDateTime}
            url={file["@microsoft.graph.downloadUrl"]}
            key={file.id}
            index={index}
            file={file}
            setselected={setselected}
            selected={selected}
            type={"oneDrive"}
            siteId={siteId}
          />
        ))}
      </div>
    </div>
  );
};

export default OneDriveFileList;
