import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import FacturesCard from "./FacturesCard";
import TableList from "../Common/TableComponents/TableList";

import { sortFunction } from "../../Utils/utils";
import { invoiceTableData } from "../../Utils/tableStaticData";

import "./Factures.css";

const FacturesList = ({ searchInvoice }) => {
  const { factures, factureLoading } = useSelector(
    (state) => state.facturesReducer
  );
  const { selectedSociety } = useSelector((state) => state.filtersReducer);

  const [sortFactures, setsortFactures] = useState({
    champs: "id",
    asc: false,
  });
  const [filteredInvoices, setFilteredInvoices] = useState(factures);

  useEffect(() => {
    if (selectedSociety?.length > 0) {
      setFilteredInvoices(
        sortFunction(factures, sortFactures.champs, sortFactures.asc).filter(
          (el) =>
            selectedSociety?.includes(el?.attributes?.societe?.data?.id) &&
            (el.id?.includes(searchInvoice) ||
              el.attributes?.number?.toString().includes(searchInvoice) ||
              el.attributes.societe?.data?.attributes?.name
                ?.toLowerCase()
                .includes(searchInvoice?.toLowerCase()))
        )
      );
    } else {
      setFilteredInvoices(
        sortFunction(factures, sortFactures.champs, sortFactures.asc).filter(
          (el) =>
            el.id?.includes(searchInvoice) ||
            el.attributes?.number?.toString().includes(searchInvoice) ||
            el.attributes.societe?.data?.attributes?.name
              ?.toLowerCase()
              .includes(searchInvoice?.toLowerCase())
        )
      );
    }
  }, [
    searchInvoice,
    factures,
    sortFactures.champs,
    sortFactures.asc,
    selectedSociety,
  ]);

  return (
    // <>
    //   {factureLoading ? (
    //     <Loading />
    //   ) : (
    //     <div
    //       className="p-0 radius05 sideChatListContainer"
    //       style={{
    //         overflowX: "hidden",
    //         backgroundColor: `${mode === "light" ? "white" : "#181f3a"}`,

    //         height: "calc(100vh - 180px)",
    //         overflowY: `${factures.length <= 2 && `hidden`}`,
    //       }}
    //     >
    //       <div
    //         className={`row py-3 px-4 t-02 ${
    //           mode === "light" ? "bg-white" : "bg-dark-grey-bg"
    //         }`}
    //         style={{
    //           position: "sticky",
    //           top: 0,
    //         }}
    //       >
    //         {screenWidth > 600 && (
    //           <div className="alignH col-1 p-0 custom-ticket-font-size bold text-start">
    //             <LightDarkText
    //               className={"pointer fitContent"}
    //               darkModeText
    //               listHeaderText
    //               onclickCallback={() =>
    //                 setsortFactures({
    //                   champs: "number",
    //                   asc: !sortFactures.asc,
    //                 })
    //               }
    //             >
    //               Numéro
    //             </LightDarkText>
    //             <SortIcon
    //               clickCallback={() =>
    //                 setsortFactures({
    //                   champs: "number",
    //                   asc: !sortFactures.asc,
    //                 })
    //               }
    //             />
    //           </div>
    //         )}
    //         <div
    //           className={`${screenWidth < 600 ? "col-3" : "col-2"} p-0 alignH`}
    //         >
    //           <LightDarkText
    //             className={
    //               "pointer fitContent custom-ticket-font-size bold text-start"
    //             }
    //             darkModeText
    //             listHeaderText
    //             onclickCallback={() =>
    //               setsortFactures({
    //                 champs: "stringSociety",
    //                 asc: !sortFactures.asc,
    //               })
    //             }
    //           >
    //             Société
    //           </LightDarkText>
    //           <SortIcon
    //             clickCallback={() =>
    //               setsortFactures({
    //                 champs: "stringSociety",
    //                 asc: !sortFactures.asc,
    //               })
    //             }
    //           />
    //         </div>
    //         <div
    //           className={`${
    //             screenWidth < 600 ? "col-3" : "col-1 "
    //           }  alignH me-5 w15 p-0 custom-ticket-font-size bold text-start`}
    //         >
    //           <LightDarkText
    //             className={"pointer fitContent"}
    //             darkModeText
    //             listHeaderText
    //             onclickCallback={() =>
    //               setsortFactures({ champs: "type", asc: !sortFactures.asc })
    //             }
    //           >
    //             Type
    //           </LightDarkText>
    //           <SortIcon
    //             clickCallback={() =>
    //               setsortFactures({ champs: "type", asc: !sortFactures.asc })
    //             }
    //           />
    //         </div>
    //         <div
    //           className={`${
    //             screenWidth < 600 ? "col-3" : "col-2 "
    //           }  alignH  p-0 custom-ticket-font-size bold text-start`}
    //         >
    //           <LightDarkText
    //             className={"pointer fitContent"}
    //             darkModeText
    //             listHeaderText
    //             onclickCallback={() =>
    //               setsortFactures({ champs: "HT", asc: !sortFactures.asc })
    //             }
    //           >
    //             {screenWidth < 992 && screenWidth > 600
    //               ? " Montant HT"
    //               : screenWidth < 600
    //               ? "HT"
    //               : " Montant hors taxe"}
    //           </LightDarkText>
    //           <SortIcon
    //             clickCallback={() =>
    //               setsortFactures({ champs: "HT", asc: !sortFactures.asc })
    //             }
    //           />
    //         </div>
    //         <div
    //           className={`${
    //             screenWidth < 600 ? "col-1" : "col-2 "
    //           } alignH p-0 custom-ticket-font-size bold text-start`}
    //         >
    //           <LightDarkText
    //             className={"pointer fitContent"}
    //             darkModeText
    //             listHeaderText
    //             onclickCallback={() =>
    //               setsortFactures({ champs: "TVA", asc: !sortFactures.asc })
    //             }
    //           >
    //             TVA
    //           </LightDarkText>
    //           <SortIcon
    //             clickCallback={() =>
    //               setsortFactures({ champs: "TVA", asc: !sortFactures.asc })
    //             }
    //           />
    //         </div>
    //         {screenWidth > 600 && (
    //           <>
    //             <div className="alignH col-2 p-0 custom-ticket-font-size bold text-start">
    //               <LightDarkText
    //                 className={"pointer fitContent"}
    //                 darkModeText
    //                 listHeaderText
    //                 onclickCallback={() =>
    //                   setsortFactures({ champs: "TTC", asc: !sortFactures.asc })
    //                 }
    //               >
    //                 Montant TTC
    //               </LightDarkText>
    //               <SortIcon
    //                 clickCallback={() =>
    //                   setsortFactures({ champs: "TTC", asc: !sortFactures.asc })
    //                 }
    //               />
    //             </div>
    //           </>
    //         )}
    //       </div>
    //       {factures.length === 0 || filteredInvoices.length === 0 ? (
    //         <EmptyList text="Vous n'avez pas de factures" color="light-blue" />
    //       ) : (
    //         filteredInvoices.map((el, index) => (
    //           <FacturesCard facture={el} key={index} index={index} />
    //         ))
    //       )}
    //     </div>
    //   )}
    // </>
    <TableList
      headerItems={invoiceTableData}
      setSort={setsortFactures}
      sort={sortFactures}
      loading={factureLoading}
    >
      {filteredInvoices.map((invoice, index) => (
        <FacturesCard
          key={index}
          index={index}
          invoice={invoice}
          tableList={invoiceTableData}
        />
      ))}
    </TableList>
  );
};

export default FacturesList;
