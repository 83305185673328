const initialState = {
  factures: [],
  factureLoading: false,
};

const facturesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FACTURES_LOADING":
      return { ...state, factureLoading: true };
    case "FETCH_INVOICES":
      let factures = payload.map((facture) => {
        return {
          ...facture,
          attributes: {
            ...facture.attributes,
            stringSociety: facture.attributes.societe.data.attributes.name,
          },
        };
      });
      return { ...state, factures: factures, factureLoading: false };
    case "INVOICE_CREATED":
      return {
        ...state,
        factures: [
          ...state.factures,
          {
            ...payload,
            attributes: {
              ...payload.attributes,
              stringSociety: payload.attributes.societe.data.attributes.name,
            },
          },
        ],
        factureLoading: false,
      };
    case "DELETE_INVOICE":
      return {
        ...state,
        factures: state.factures.filter((item) => item.id !== payload),
        factureLoading: false,
      };
    default:
      return state;
  }
};
export default facturesReducer;
