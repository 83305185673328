import React from "react";
import { useSelector } from "react-redux";

import LightDarkText from "../Common/LightDarkText";
import ColoredButton from "../Common/Buttons/ColoredButton";

import "./Simulateur.css";

const SimulatorListHeader = ({
  title,
  callback,
  position,
  isForm,
  value,
  disabled,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="d-flex   flex-row justify-content-between  align-items-center  pt-3 ">
      <LightDarkText className=" f18 bold d-flex flex-row  align-items-center">
        {position && (
          <div
            className="categoryPosition xbold f14 bg-light-gray "
            style={{
              color: "#354074",
            }}
          >
            {position}
          </div>
        )}

        {title}
      </LightDarkText>
      {!isForm && (
        <div className="iconContainer me-3">
          <ColoredButton
            icon={value ? "check" : "plus"}
            color={value ? "green" : "light-blue"}
            callback={callback}
            disabled={value && disabled}
          />
        </div>
      )}
    </div>
  );
};

export default SimulatorListHeader;
