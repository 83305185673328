import moment from "moment";
import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";

import { handleFirstLastName } from "../../../../Utils/utils";

const FooterDetails = ({ event }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <LightDarkText
      className="custom-ticket-font-size text-start bold mt-4"
      color={mode === "light" ? "text-light-text" : "text-white"}
      style={{ textWrap: "wrap" }}
    >
      {event?.type === "Marketing" && "Événement"} {event?.type} organisé par{" "}
      {handleFirstLastName(event?.creator?.data)} le{" "}
      {moment(event?.createdAt).format("DD/MM/YYYY")}
    </LightDarkText>
  );
};

export default FooterDetails;
