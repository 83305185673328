import { handleError } from "@apollo/client/link/http/parseAndCheckHttpResponse";
import client from "./Client";
import { GET_CURRENT_PLAN, GET_PLANS } from "../Graphql/Queries/Plan";

export const getCurrentPlan = (societeId, date) => async (dispatch) => {
  try {
    if (!societeId) return;
    const { data } = await client.query({
      query: GET_CURRENT_PLAN,
      variables: {
        filters: {
          societe: { id: { eq: societeId } },
          date: { eq: date },
        },
      },
    });

    dispatch({ type: "GET_CURRENT_PLAN", payload: data?.plans?.data[0] });
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const getPlans = (societeId) => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: GET_PLANS,
      variables: {
        filters: {
          societe: { id: { eq: societeId } },
        },
      },
    });

    dispatch({ type: "GET_PLANS", payload: data?.plans?.data });
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};
