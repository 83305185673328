import React, { useEffect, useState } from "react";
import NotificationList from "./NotificationList";
import { useDispatch, useSelector } from "react-redux";

import { notificationsTab } from "./notificationsUtils";
import LightDarkContainer from "../../Common/LightDarkContainer";
import MenuTabIcons from "../../Common/MenuTabIcons";
import { checkReadNotifications } from "../../../REDUX/actions/notifications";

const NotificationsSideBar = () => {
  const dispatch = useDispatch();
  const { sideBarToggle } = useSelector((state) => state.sideBarReducer);
  const [isToggle, setIsToggle] = useState(sideBarToggle || "public");
  const { showPoints } = useSelector((state) => state.notificationsReducer);
  const { mode } = useSelector((state) => state.genearlReducer);
  const { role } = useSelector((state) => state.roleReducer);
  useEffect(() => {
    dispatch(checkReadNotifications());
  }, []);

  return (
    <div
      className={`${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      } radius05 scrollable h100 d-flex flex-column`}
    >
      <div
        className={`d-flex justify-content-between align-items-center tabRadius t-02 bg-white`}
        style={{ height: "50px" }}
      >
        <LightDarkContainer
          colorLight="white"
          colorDark="dark-grey-bg"
          className="d-flex justify-content-between px-4 w-100 gap-2 align-items-center menuHeaderTabs"
          style={{ whiteSpace: "nowrap" }}
        >
          {notificationsTab(role, showPoints)
            .filter((tab) => tab.isVisible && !tab.hideTab)
            .map((tab) => (
              <div key={tab.toggleValue}>
                <MenuTabIcons
                  Icon={tab.Icon}
                  tabTitle={tab.title}
                  toggleValue={tab.toggleValue}
                  isToggle={isToggle}
                  isVisible={true}
                  setIsToggle={() => setIsToggle(tab.toggleValue)}
                  showRedPoint={tab.showRedPoint}
                  bigNotification={true}
                />
              </div>
            ))}
        </LightDarkContainer>
      </div>

      <div className="scrollable h100">
        <NotificationList type={isToggle} />
      </div>
    </div>
  );
};

export default NotificationsSideBar;
