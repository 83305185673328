import React from "react";
import LightDarkText from "../../Common/LightDarkText";
import UserThumbnail from "../../Common/UserThumbnail";
import { useSelector } from "react-redux";
import { formatDate, handleFirstLastName } from "../../../Utils/utils";

const RightLinks = ({ ticket }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const handleScroll = (id) => {
    document.querySelector(`#messageId-${id}`).scrollIntoView({
      behavior: "smooth",
    });
  };
  const { showPrivateMessages } = useSelector((state) => state.messagesReducer);

  const linksList = showPrivateMessages
    ? ticket?.attributes?.message_links?.data?.filter(
        (link) => link?.attributes?.message?.data?.attributes?.is_internal
      )
    : ticket?.attributes?.message_links?.data.filter(
        (link) => !link?.attributes?.message?.data?.attributes?.is_internal
      );
  return (
    <div className="d-flex flex-column gap-4 scrollable">
      {linksList
        ?.filter(
          (el) =>
            el.attributes.type === "URL" ||
            el.attributes.type === "unknown" ||
            el.attributes.type === "email"
        )
        .map((el) => (
          <div
            key={el.id}
            className="text-start border-bottom pb-2 opacityHover"
          >
            <LightDarkText className="d-flex alignH gap-2 f12 ">
              <UserThumbnail
                userId={
                  el?.attributes?.message?.data?.attributes?.sender?.data?.id
                }
                connected={
                  el?.attributes?.message?.data?.attributes?.sender?.data
                    ?.attributes?.connected
                }
                lastConnected={
                  el?.attributes?.message?.data?.attributes?.sender?.data
                    ?.attributes?.lastConnected
                }
                apiImg={
                  el?.attributes?.message?.data?.attributes?.sender?.data
                    ?.attributes?.avatar?.data?.attributes?.image?.data
                    ?.attributes?.url
                }
                img={`user${
                  el?.attributes?.message?.data?.attributes?.sender?.data
                    ?.attributes?.genre || "3"
                }`}
                size={40}
              />
              {el?.attributes?.type === "URL" ||
              el?.attributes?.type === "unknown" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={el?.attributes?.link}
                  className="text-decoration-underline bold textBlueHover"
                >
                  {el?.attributes?.displayText || el?.attributes?.link}
                </a>
              ) : el?.attributes?.type === "email" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`mailto:${el?.attributes?.link}`}
                  className="text-decoration-underline bold textBlueHover"
                >
                  {el?.attributes?.link}
                </a>
              ) : null}
            </LightDarkText>
            <div className="alignH justify-content-between mt-2">
              <LightDarkText
                color={mode === "light" ? "text-light-text" : "text-white"}
                className="text-start bold f10 pointer"
                onclickCallback={() => {
                  handleScroll(el?.attributes?.message?.data?.id);
                }}
              >
                Ajouté par{" "}
                {handleFirstLastName(
                  el?.attributes?.message?.data?.attributes?.sender?.data
                )}{" "}
                Le {formatDate(el?.attributes?.createdAt)}
              </LightDarkText>
              <LightDarkText
                className="text-end xbold f10 pointer textBlueHover"
                onclickCallback={() => {
                  handleScroll(el?.attributes?.message?.data?.id);
                }}
              >
                voir le message
              </LightDarkText>
            </div>
          </div>
        ))}
    </div>
  );
};

export default RightLinks;
