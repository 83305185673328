import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import DrawerPlaceHolder from "./DrawerPlaceHolder";
import LightDarkContainer from "../../Common/LightDarkContainer";

import { imageLoader } from "../../../Utils/utils";

import "./Drawer.css";

const Drawer = () => {
  const location = useLocation();

  const [position, setposition] = useState(0);

  const { initialLoading, tabs, organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );

  useEffect(() => {
    if (tabs) {
      let selected = tabs.filter(
        (el) =>
          el.attributes.url.split("/")[1] === location.pathname.split("/")[2]
      )[0];
      if (
        location.pathname.split("/")[2] === "dossier" ||
        location.pathname.split("/")[2]?.includes("onedrive") ||
        location.pathname.split("/")[2]?.includes("sharepoint")
      ) {
        selected = tabs.filter(
          (el) => el.attributes.type.name === "Documents"
        )[0];
      }
      if (selected) {
        setposition(tabs.indexOf(selected));
      } else {
        setposition(0);
      }
    }
  }, [location.pathname, tabs]);
  return (
    <div className="animatedDrawer">
      <LightDarkContainer
        className="drawerContainer"
        colorDark="dark-grey-bg"
        colorLight="white"
      >
        {initialLoading || !tabs ? (
          <DrawerPlaceHolder />
        ) : (
          <div className="logoNavigations mt-3 d-flex flex-column flex-1">
            {tabs.map((el, index) => {
              return (
                <div className="alignH position-static" key={index}>
                  <Link
                    to={`${organisationUrl}${el.attributes.url}`}
                    className={`drawLink t-02 alignC py-1 ${
                      position === index ? `text-light-blue` : "text-light-text"
                    } gap-0 bolder  f10`}
                    key={index}
                  >
                    <img
                      width={26}
                      height={26}
                      className={`${position === index ? "DrawerBlue" : ""}`}
                      src={imageLoader(
                        el.attributes.logo.data.attributes.icon.url
                      )}
                      alt="icone-menu"
                    />
                    <div className="mt-1 w-100 h10px alignC">
                      <div className="drawerTextInner t-02">
                        {el.attributes.displayDrawer}
                      </div>
                    </div>
                  </Link>
                  {index === 0 ? (
                    <div
                      style={{
                        transform: `translateY(${
                          position * 70 - 5
                        }px) translateX(4px)`,
                      }}
                      className="Drawerselector t-02"
                    ></div>
                  ) : null}
                </div>
              );
            })}
          </div>
        )}
      </LightDarkContainer>
    </div>
  );
};

export default Drawer;
