import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "./LightDarkText";
import NotificationsRedPoint from "./MenuIcons/NotificationsRedPoint";
import { useSelector } from "react-redux";

const MenuTabIcons = ({
  tabTitle,
  Icon = null,
  toggleValue,
  isToggle,
  setIsToggle,
  isVisible,
  showRedPoint = false,
  bigNotification = false,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const selectedTabColor = (tabName) =>
    isToggle === tabName ? "#3365ff" : mode === "light" ? "#a9bcd3" : "#e0e0e0";
  return isVisible ? (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ zIndex: 1050 }}>
          <LightDarkText className="custom-ticket-font-size py-1 text-white">
            {tabTitle}
          </LightDarkText>
        </Tooltip>
      }
    >
      <div
        onClick={() => setIsToggle(toggleValue)}
        className="p-2 test position-relative tabStyle"
      >
        {showRedPoint ? <NotificationsRedPoint big={true} /> : null}
        {Icon(selectedTabColor(toggleValue))}
        {isToggle === toggleValue && <div className="underlineIcon radius05" />}
      </div>
    </OverlayTrigger>
  ) : null;
};

export default MenuTabIcons;
