import React, { useState } from "react";
import { useSelector } from "react-redux";

import { CloseIcon } from "../Common/LightDarkSvg";
import LightDarkText from "../Common/LightDarkText";
import EmptyList from "../Common/EmptyList";
import SocietyList from "../Societies/SocietyList";
import UsersList from "../Clients/UsersList";
import InterventionsList from "../Interventions/InterventionsList";
import DiffusionItem from "../Diffusion/DiffusionItem";
import SideChatCard from "../Tickets/TicketsList/TicketCard";

import { sortFunction } from "../../Utils/utils";

import useWindowWidth from "../../Hooks/useWindowWidth";

import "./Overview.css";

const OverviewTab = () => {
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { tickets } = useSelector((state) => state.ticketsReducer);
  const { isSupervisor, isClientAdmin } = useSelector(
    (state) => state.roleReducer
  );
  const { diffusions } = useSelector((state) => state.alertsReducer);
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );
  const [societySelected, setSeocietySelected] = useState(false);
  const [selectedSociety, setselectedSociety] = useState("");
  const [selectedItem, setSelectedItem] = useState();

  const clickOnSocietyCard = (society) => {
    setselectedSociety(society);
    setSeocietySelected(true);
  };

  const filteredTickets = societySelected
    ? tickets
        .filter(
          (ticket) =>
            ticket?.attributes?.societe?.data?.id === selectedSociety.id
        )
        .slice(0, 20)
    : tickets.slice(0);

  return (
    <div className={`scrollable`}>
      {selectedOrganisation?.attributes?.hasDiffusion && diffusions.length ? (
        <div className="my-2">
          <LightDarkText
            className={`text-start custom-title-font-size bold mb-2`}
          >
            Diffusion interne
          </LightDarkText>
          <div className="row mt-2 p-0">
            {diffusions.map((item) => {
              return <DiffusionItem data={item?.attributes} key={item.id} />;
            })}
          </div>
        </div>
      ) : null}
      <div className="overviewTabContainer  d-flex justify-content-between">
        <div
          className={`w70  h100 scrollable d-flex flex-column justify-content-between`}
        >
          {isSupervisor || isClientAdmin ? (
            <div className="h50 d-flex flex-column">
              <LightDarkText className="text-start custom-title-font-size bold mb-2">
                Dernières Sociétés
              </LightDarkText>
              <div className="scrollable">
                <SocietyList
                  searchSociety=""
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  clickOnSocietyCard={clickOnSocietyCard}
                />
              </div>
            </div>
          ) : (
            <div className="h100 d-flex flex-column">
              <LightDarkText className="text-start custom-title-font-size bold mb-2">
                Dernières Interventions
              </LightDarkText>
              <div className="scrollable">
                <InterventionsList
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            </div>
          )}

          {isSupervisor || isClientAdmin ? (
            <div
              className={`h50 ${
                screenWidth < 992 ? "" : "d-flex justify-content-between gap-3 "
              }`}
            >
              {isSupervisor && (
                <div className="h100 d-flex flex-column w50">
                  <LightDarkText className="text-start custom-title-font-size bold mt-3 mb-1">
                    Derniers Opérateurs
                  </LightDarkText>
                  <div className="scrollable">
                    <UsersList search="" operatorsList />
                  </div>
                </div>
              )}
              {isSupervisor || isClientAdmin ? (
                <div className="h100 d-flex flex-column w50">
                  <LightDarkText className="text-start custom-title-font-size bold mt-3 mb-1">
                    Derniers {isSupervisor ? "Clients" : "Collaborateurs"}
                  </LightDarkText>
                  <div className="scrollable">
                    <UsersList search="" />
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {/* SEPARATEUR */}
        <div className="d-flex alignC w2">
          <div
            style={{
              backgroundColor: mode === "light" ? "white" : "#252C45",
              width: "2px",
              height: "100%",
            }}
          ></div>
        </div>
        {/* SEPARATEUR */}
        <div className={`flex-1 d-flex flex-column `}>
          <div className="d-flex align-items-center justify-content-between">
            <LightDarkText className="text-start custom-title-font-size bold mb-2">
              Derniers Tickets
            </LightDarkText>
          </div>
          {societySelected && (
            <div
              className="p-1 d-flex align-items-center justify-content-between my-3 radius05 "
              style={{
                backgroundColor: "#A9BCD325",
                border: "1px solid #A9BCD3",
                width: "fit-content",
              }}
            >
              <LightDarkText className="bold custom-ticket-font-size ms-1">
                {selectedSociety?.attributes?.name}
              </LightDarkText>
              <CloseIcon
                dimension="23"
                clickCallback={() => setSeocietySelected(false)}
              />
            </div>
          )}

          <div
            className="sideChatListContainer scrollable"
            style={{
              overflowX: "hidden",
              height: screenWidth < 992 ? "calc(100vh-100px)" : "100%",
            }}
          >
            {filteredTickets.length !== 0 ? (
              <div className="h100 d-flex flex-column justify-content-start">
                {sortFunction(filteredTickets, "stringCreatedAt", false).map(
                  (ticket, index) => (
                    <SideChatCard ticket={ticket} key={index} />
                  )
                )}
              </div>
            ) : (
              <EmptyList type="ticket" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
