import React, { useState } from "react";
import LightDarkText from "../../../Common/LightDarkText";
import { useSelector } from "react-redux";
import ColoredButton from "../../../Common/Buttons/ColoredButton";
import {
  handleProspectStatut,
  renderOriginText,
} from "../../../../Utils/utils";
import { useNavigate } from "react-router-dom";

const MainProspectMessage = ({ message }) => {
  const navigate = useNavigate();
  const { isSupervisor, canCreateProspet } = useSelector(
    (state) => state.roleReducer
  );
  const { prospetsRoute } = useSelector((state) => state.organisationsReducer);
  const [linkedProspect] = useState(message.attributes.prospect.data);

  return (
    linkedProspect && (
      <div
        className="w-100 d-flex flex-column gap-3 align-items-start mt-4 mb-2 messageCardMessage p-4 radius05 position-relative interview-message message-yellow"
        id={`interview-${message.id}`}
      >
        <LightDarkText className={"bold text-start f18 xbold"}>
          Prospect{" "}
          <span className="text-light-blue"> #{linkedProspect.id}</span>
        </LightDarkText>
        <LightDarkText className="custom-ticket-font-size text-blue-grey p-nomb text-start text-capitalize xbold">
          Status : {handleProspectStatut(linkedProspect.attributes.status).text}
        </LightDarkText>

        <LightDarkText
          className={
            "custom-ticket-font-size text-blue-grey p-nomb text-start text-capitalize xbold"
          }
        >
          Origine : {renderOriginText(linkedProspect.attributes.origin)}
        </LightDarkText>
        <div
          className={`message-content-${message?.id} text-start custom-ticket-font-size p-nomb`}
          dangerouslySetInnerHTML={{
            __html: message.attributes.content,
          }}
        ></div>
        <div className="alignC w-100 mt-2">
          {(isSupervisor || canCreateProspet) && (
            <div className="w50">
              <ColoredButton
                color={"#0CCE91"}
                background="#0CCE9126"
                icon="check-square"
                square
                textButton="Modifier le prospect"
                callback={() =>
                  navigate(`${prospetsRoute}/${linkedProspect?.id}`)
                }
              />
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default MainProspectMessage;
