import React from "react";
import LightDarkText from "../../Common/LightDarkText";
import { useSelector } from "react-redux";
import { SwitchIcon } from "../../Common/LightDarkSvg";

const SwitchItem = ({
  label,
  name,
  value = true,
  onChange = () => {},
  disabled = false,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="d-flex justify-content-between align-items-center">
      <LightDarkText
        className="text-start custom-ticket-font-size bolder"
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        {label}
      </LightDarkText>
      <div
        className="d-flex align-content-center"
        onClick={() => (disabled ? null : onChange(name, !value))}
      >
        <SwitchIcon disabled={disabled} value={value} />
      </div>
    </div>
  );
};

export default SwitchItem;
