import { userAttributes } from "../general/userFields";

export const USER_ATTRIBUTES = `
${userAttributes}
departements {
  data {
    id
    attributes {
      name
    }
  }
}
role {
  data {
    id
    attributes {
      name
    }
  }
}
poste {
  data {
    id
    attributes {
      name
    }
  }
}
workIn (pagination: { page: 1, pageSize: 100 } ) {
  data {
    id
    attributes {
      name
    }
  }
}
SocietiesUnderResponsability (pagination: { page: 1, pageSize: 100 } ) {
  data {
    id
    attributes {
      name
    }
  }
}
avatar {
  data {
    id
    attributes {
      image {
        data {
          id 
          attributes {
            url
          }
        }
      }
    }
  }
}
`;
