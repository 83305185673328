import React from "react";
import { useSelector } from "react-redux";

const ThumbnailPlaceHolder = ({ smallThumbnail }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div
      className={`t-02 ${
        mode === "light" ? "lightPlaceHolder" : "darkPlaceHolder"
      } ${smallThumbnail ? "smallThumPlaceholder" : "largeThumPlaceholder"}`}
    ></div>
  );
};

export default ThumbnailPlaceHolder;
