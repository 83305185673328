import React from "react";
import "./LoadingButton.css";

const LoadingButton = () => {
  return (
    <div className="LoadingButtoncontainer">
      <div className="LoadingButtonloader">
        <div className="LoadingButtonloader--dot"></div>
        <div className="LoadingButtonloader--dot"></div>
        <div className="LoadingButtonloader--dot"></div>
        <div className="LoadingButtonloader--dot"></div>
        <div className="LoadingButtonloader--dot"></div>
        <div className="LoadingButtonloader--dot"></div>
      </div>
    </div>
  );
};

export default LoadingButton;
