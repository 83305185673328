import React from "react";

const MessagesLock = ({ fill = "#a9bcd3", width = "30", height = "30" }) => {
  return (
    <svg
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 41.4 35.7"
      //   style="enable-background:new 0 0 41.4 35.7;"
    >
      <path
        d="M18.4704 16.83L18.8604 19.99C18.9604 20.82 18.0704 21.4 17.3604 20.97L13.9004 18.91C13.6604 18.77 13.6004 18.47 13.7304 18.23C14.2304 17.31 14.5004 16.27 14.5004 15.23C14.5004 11.57 11.3604 8.59 7.50038 8.59C6.71038 8.59 5.94038 8.71 5.22038 8.95C4.85038 9.07 4.49038 8.73 4.58038 8.35C5.49038 4.71 8.99038 2 13.1704 2C18.0504 2 22.0004 5.69 22.0004 10.24C22.0004 12.94 20.6104 15.33 18.4704 16.83Z"
        fill={fill}
      />
      <path
        d="M13 15.2298C13 16.4198 12.56 17.5198 11.82 18.3898C10.83 19.5898 9.26 20.3598 7.5 20.3598L4.89 21.9098C4.45 22.1798 3.89 21.8098 3.95 21.2998L4.2 19.3298C2.86 18.3998 2 16.9098 2 15.2298C2 13.4698 2.94 11.9198 4.38 10.9998C5.27 10.4198 6.34 10.0898 7.5 10.0898C10.54 10.0898 13 12.3898 13 15.2298Z"
        fill={fill}
      />

      <path
        fill={fill}
        d="M36.6,20v2c-0.4-0.1-0.9-0.1-1.4-0.1V20c0-3-0.8-5-5-5s-5,2-5,5v1.9c-0.5,0-1,0-1.4,0.1v-2
	c0-2.7,0.7-6.4,6.4-6.4S36.6,17.2,36.6,20z"
      />
      <path
        fill={fill}
        d="M36.6,21.9c-0.4-0.1-0.9-0.1-1.4-0.1h-9.9c-0.5,0-1,0-1.4,0.1c-2.4,0.3-3.1,1.5-3.1,4.6v1.9
	c0,3.8,0.9,4.7,4.7,4.7H35c3.8,0,4.7-0.9,4.7-4.7v-1.9C39.7,23.4,39,22.2,36.6,21.9z M27.2,28.2c-0.2,0.2-0.4,0.3-0.7,0.3
	c-0.1,0-0.2,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.1,0-0.2,0.1-0.4c0-0.1,0.1-0.2,0.2-0.3
	c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.2,0.8-0.1,1,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4C27.4,27.8,27.3,28,27.2,28.2z
	 M31.1,27.9c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.1-0.2-0.2-0.2-0.3
	c0-0.1-0.1-0.2-0.1-0.4c0-0.3,0.1-0.5,0.3-0.7c0.3-0.3,1-0.3,1.3,0c0.2,0.2,0.3,0.4,0.3,0.7C31.2,27.6,31.2,27.8,31.1,27.9z
	 M34.7,28.2c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.4-0.3,1-0.3,1.3,0
	c0,0,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2C35,27.8,34.9,28,34.7,28.2z"
      />
    </svg>
  );
};

export default MessagesLock;
