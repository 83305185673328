import React from "react";
import UserSquareCard from "./UserSquareCard";
import EmptyList from "../Common/EmptyList";

const UsersSquareList = ({ listOfUserOrgs, type }) => {
  return listOfUserOrgs.length === 0 ? (
    <EmptyList type={type} />
  ) : (
    <div className="grid4 scrollable">
      {listOfUserOrgs.map((userOrg) => (
        <UserSquareCard key={userOrg.id} userOrg={userOrg} type={type} />
      ))}
    </div>
  );
};

export default UsersSquareList;
