const initialState = {
  faqs: [],
  faqsLoading: false,
  faq: {},
  faqsCategories: [],
  faqsCategoriesLoading: false,
  faqLoading: false,
};

const faqReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // fetch faqs cases
    /************************************************** */
    case "FETCH_FAQS":
      return {
        ...state,
        faqsLoading: true,
      };

    case "FETCH_FAQS_SUCCESS":
      return {
        ...state,
        faqs: payload,
        faqsLoading: false,
      };

    case "FETCH_FAQS_ERROR":
      return {
        ...state,
        faqsLoading: false,
      };
    /************************************************ */

    // fetch faq cases
    /************************************************** */
    case "FETCH_FAQ":
      return {
        ...state,
        faqLoading: true,
      };
    case "FETCH_FAQ_SUCCESS":
      return {
        ...state,
        faq: payload,
        faqLoading: false,
      };
    case "FETCH_FAQ_ERROR":
      return {
        ...state,
        faqLoading: false,
      };
    /************************************************ */

    // fetch faqs categories cases
    /************************************************** */
    case "FETCH_FAQS_CATEGORIES":
      return {
        ...state,
        faqsCategoriesLoading: true,
      };

    case "FETCH_FAQS_CATEGORIES_SUCCESS":
      return {
        ...state,
        faqsCategories: payload,
        faqsCategoriesLoading: false,
      };

    case "FETCH_FAQS_CATEGORIES_ERROR":
      return {
        ...state,
        faqsCategoriesLoading: false,
      };

    /************************************************ */

    case "CREATE_FAQ_CATEGORY_SUCCESS":
      return {
        ...state,
        faqsCategories: [payload, ...state.faqsCategories],
        faqsCategoriesLoading: false,
      };

    case "CREATE_FAQ_SUCCESS":
      return {
        ...state,
        faq: payload,
        faqs: [payload, ...state.faqs],
      };

    case "UPDATE_FAQ_SUCCESS":
      return {
        ...state,
        faq: payload,
        faqs: state.faqs.map((faq) => (faq.id === payload.id ? payload : faq)),
      };

    case "DELETE_FAQ_SUCCESS":
      return {
        ...state,
        faqs: state.faqs.filter((faq) => faq.id !== payload),
        faq: state.faq?.id === payload ? null : state.faq,
      };

    default:
      return state;
  }
};

export default faqReducer;
