import React from "react";
import flag from "../../Images/french-flag.png";
import longLogo from "../../Images/agilix_lon_logo.png";

const LoginCopyRights = ({ color = "text-blue-grey" }) => {
  return (
    <div className="rights">
      <div
        className={`${color} bold  text-center regular custom-ticket-font-size`}
      >
        <div className="mb-3">
          <img src={longLogo} alt="" width={150} />
        </div>
        Logiciel édité avec &#9829; par{" "}
        <a href="https://agilix.io" target="_blank" rel="noreferrer">
          Agilix.io
        </a>
        <img src={flag} width="20" height="20" className="ms-1" />
        <p>Tous droits réservés © Agilix France 2025</p>
      </div>
    </div>
  );
};

export default LoginCopyRights;
