import React from "react";

import { useSelector } from "react-redux";
import LightDarkText from "../Common/LightDarkText";

const TextAreaInput = ({
  label,
  required,
  bgWhite,
  totalInput,
  champs,
  setValue,
  rows = 4,
  extraLabel,
  placeholder = "placeholder",
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const handleChange = (value) => {
    setValue({ ...totalInput, [champs]: value });
  };

  return (
    <div className="w-100">
      <LightDarkText
        className={" d-flex text-start bold custom-ticket-font-size mb-2"}
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        {label} {required ? <span className="text-red">*</span> : null}
        {extraLabel && (
          <div className="text-light-text ms-1"> {extraLabel}</div>
        )}
      </LightDarkText>
      <div className="w-100">
        <textarea
          className={`${mode === "light" ? "lightInput" : "darkInput"} ${
            bgWhite && mode === "light" && "bg-white"
          }
          w-100 textAreaInput p-2 f12 bold `}
          rows={rows}
          maxLength={300}
          placeholder={placeholder}
          defaultValue={totalInput ? totalInput[champs] : null}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default TextAreaInput;
