const initialState = {
  open: false,
  content: null,
  modaltype: "small",
  preventClose: true,
};

const modalReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "OPEN_MODAL":
      return {
        ...state,
        open: true,
        content: payload.content,
        modaltype: payload.type,
        preventClose: payload.preventClose,
      };
    case "CLOSE_MODAL":
      return { ...state, open: false, content: false, closable: true };
    default:
      return state;
  }
};

export default modalReducer;
