import React from "react";
import EventForm from "./EventForm";

const AddEditEventContent = ({ isNew, fromCalendar, eventDetails }) => {
  return (
    <EventForm isNew={isNew} fromCalendar={fromCalendar} event={eventDetails} />
  );
};

export default AddEditEventContent;
