import Api from "../../Api/Api";
import {
  CREATE_AVATAR,
  DELETE_FILE,
  UPDATE_AVATAR,
  UPLOAD,
} from "../Graphql/Mutations/files";
import { GET_SOCIETE_ITEM } from "../Graphql/Queries/Societes";
import { GET_AVATAR_SOCIETE } from "../Graphql/Queries/files";
import store from "../store/store";
import client from "./Client";
import { selectCalendars } from "./calendars";
import { handleError, handleInfo } from "./toastMessage";
const color = "light-blue";

export const getAllSocietes = () => async (dispatch) => {
  try {
    dispatch({ type: "SOCIETE_LOADING" });
    const archivedSociety = store.getState().filtersReducer.archivedSociety;
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    const { data } = await Api.get(
      `/api/fetchConditionalSocieties?organisation=${orgId}&archived=${archivedSociety}`
    );

    dispatch({ type: "FETCH_SOCIETE", payload: data.societes.data });
    dispatch({ type: "FETCH_SHARED_SOCIETY", payload: data.societes.data });
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const getNewCompany =
  ({ id }) =>
  async (dispatch) => {
    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }
    try {
      const archivedSociety = store.getState().filtersReducer?.archivedSociety;
      const { data } = await client.query({
        query: GET_SOCIETE_ITEM(id, orgId),
      });
      dispatch({
        type: "FETCH_NEW_SHARED_COMPANY",
        payload: data.societe.data,
      });

      if (!archivedSociety) {
        dispatch({ type: "FETCH_NEW_COMPANY", payload: data.societe.data });
      }

      dispatch(handleInfo("Société créée", 1, color));
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const CreateSociete =
  (newSociete, avatar, navigate) => async (dispatch) => {
    try {
      const user = store.getState().userReducer.user;
      const societeRoute = store.getState().organisationsReducer.societeRoute;
      const orgId =
        store.getState().organisationsReducer.selectedOrganisation.id;
      if (!orgId) {
        return;
      }

      let avatarId = "";
      if (avatar) {
        const { data: fileData } = await client.mutate({
          mutation: UPLOAD,
          variables: {
            file: avatar,
          },
        });

        const fileId = fileData.upload.data.id;

        const { data: avatarData } = await client.mutate({
          mutation: CREATE_AVATAR,
          variables: {
            image: fileId,
          },
        });

        avatarId = avatarData?.createAvatar?.data?.id;
      }

      const { data } = await Api.post(`/api/societes`, {
        data: {
          ...newSociete,
          archived: false,
          avatar: avatarId || null,
          organisation: orgId,
          creator: user?.id,
          society_type: newSociete.society_type[0]?.value || null,
          childCompanies:
            newSociete?.childCompanies?.map((el) => el.value) || null,
        },
      });

      const societeId = data.data.id;

      if (societeId) {
        dispatch(getAllSocietes());
        navigate(`${societeRoute}/${societeId}`);
      }

      dispatch(handleInfo("Société créée", 1, color));

      dispatch({ type: "SOCIETY_CREATED", payload: undefined });
    } catch (error) {
      dispatch(handleError("Erreur ajout de la société", 1));
    }
  };

export const UpdateSociete =
  (Societe, avatar, isLinked) => async (dispatch, getState) => {
    try {
      if (avatar) {
        const { data: currentAvatar } = await client.query({
          query: GET_AVATAR_SOCIETE,
          variables: {
            societeId: Societe.id,
          },
        });
        const { data: fileData } = await client.mutate({
          mutation: UPLOAD,
          variables: {
            file: avatar,
          },
        });
        const fileId = fileData.upload.data.id;

        if (!currentAvatar?.avatars?.data?.length > 0) {
          await client.mutate({
            mutation: CREATE_AVATAR,
            variables: {
              image: fileId,
              societe: Societe.id,
            },
          });
        } else {
          const currentAvatarId = currentAvatar?.avatars?.data[0]?.id;
          const currentImageId =
            currentAvatar?.avatars?.data[0]?.attributes?.image?.data?.id;

          currentAvatarId &&
            (await client.mutate({
              mutation: UPDATE_AVATAR,
              variables: {
                id: currentAvatarId,
                imageId: fileId,
              },
            }));

          currentImageId &&
            (await client.mutate({
              mutation: DELETE_FILE,
              variables: {
                id: currentImageId,
              },
            }));
        }
      }

      await Api.put(`/api/societes/${Societe.id}`, {
        data: {
          name: Societe.name,
          siret: Societe.siret?.toString() || "",
          phoneNumber: Societe.phoneNumber,
          adress: Societe.adress,
          archived: Societe.archived,
          city: Societe.city,
          zipCode: Societe.zipCode,
          email: Societe.email,
          siteWeb: Societe.siteWeb,
          emailFacture: Societe.emailFacture,
          monthlyPlan: Societe.monthlyPlan,
          monthlyQuota: Societe.monthlyQuota,
          society_type: Societe?.society_type
            ? Societe?.society_type[0]?.value
            : null,
          childCompanies: Societe?.childCompanies?.map((el) => el.value),
          tikTokLink: Societe.tikTokLink,
          xLink: Societe.xLink,
          facebookLink: Societe.facebookLink,
          instagramLink: Societe.instagramLink,
          linkedinLink: Societe.linkedinLink,
        },
      });

      const actualRoute = getState().socketReducer.route;

      // await dispatch(uploadFile(avatar, null, data?.updateSociete.data?.id));
      const { societeRoute } = getState().organisationsReducer;

      if (actualRoute === `${societeRoute}/${Societe.id}`) {
        dispatch(getSocietyItem(Societe.id, false));
      }
      if (isLinked) {
        dispatch(getSocietyLinkedSocieties(false));
      }
      dispatch(getAllSocietes());

      dispatch(handleInfo("Société mise à jour", 1, color));
    } catch (error) {
      dispatch(handleError("Erreur mise à jour société", 1));
    }
  };

export const getSocietyStats =
  (societyId, withLoading = true) =>
  async (dispatch) => {
    try {
      withLoading && dispatch({ type: "SOCIETE_ITEM_LOADING" });
      const organisation =
        store.getState().organisationsReducer.selectedOrganisation;

      const data = await Api.get(
        `/api/users-permissions/user/getConditionalStats?organisation=${
          organisation.id
        }&societies=${[societyId]}`
      );
      dispatch({ type: "SOCIETE_STATS_SUCCESS", payload: data?.data });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const getSocietyItem =
  (societyId, withLoading = true) =>
  async (dispatch) => {
    try {
      withLoading && dispatch({ type: "SOCIETE_ITEM_LOADING" });

      const orgId =
        store.getState().organisationsReducer.selectedOrganisation.id;
      if (!orgId) {
        return;
      }

      const { data } = await Api.get(
        `/api/fetchSocietyItem?id=${societyId}&organisation=${orgId}`
      );

      dispatch({ type: "FETCH_SOCIETE_ITEM", payload: data });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const updateForfait =
  (societyId, forfaitData, immediately, planId) => async (dispatch) => {
    try {
      const orgId =
        store.getState().organisationsReducer.selectedOrganisation.id;
      if (!orgId) {
        return;
      }

      await Api.put(`/api/societes/${societyId}`, {
        data: forfaitData,
      });

      if (immediately) {
        await Api.put(`/api/plans/${planId}`, {
          data: forfaitData,
        });
      }
      dispatch(getSocietyStats(societyId, false));
      dispatch(getSocietyItem(societyId, false));
      dispatch(getAllSocietes());
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const getSocietyCollaborateurs =
  (withLoading = true) =>
  async (dispatch, getState) => {
    try {
      withLoading && dispatch({ type: "SOCIETE_ITEM_LOADING" });

      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      const societyId = getState().societesReducer.societe.id;
      const clientArchived = getState().filtersReducer.clientArchived;
      if (!orgId) {
        return;
      }

      const { data } = await Api.get(
        `/api/getSocietyCollaborators?id=${societyId}&organisation=${orgId}&archived=${clientArchived}`
      );

      dispatch({ type: "FETCH_SOCIETE_COLLABORATORS", payload: data.data });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const getSocietyOperators =
  (withLoading = true) =>
  async (dispatch, getState) => {
    try {
      withLoading && dispatch({ type: "SOCIETE_ITEM_LOADING" });

      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      const societyId = getState().societesReducer.societe.id;
      const operateursArchived = getState().filtersReducer.operateursArchived;
      if (!orgId) {
        return;
      }

      const { data } = await Api.get(
        `/api/getSocietyOperators?id=${societyId}&organisation=${orgId}&archived=${operateursArchived}`
      );

      dispatch({ type: "FETCH_SOCIETE_OPERATORS", payload: data.data });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const getSocietyForm = (societyId) => async (dispatch) => {
  try {
    dispatch({ type: "SOCIETE_FROM_LOADING" });

    const orgId = store.getState().organisationsReducer.selectedOrganisation.id;
    if (!orgId) {
      return;
    }

    const { data } = await Api.get(
      `/api/getSocietyForm?id=${societyId}&organisation=${orgId}`
    );

    dispatch({ type: "FETCH_SOCIETE_FORM", payload: data });
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const getSocietyLinkedSocieties =
  (withLoading = true) =>
  async (dispatch, getState) => {
    try {
      withLoading && dispatch({ type: "SOCIETE_ITEM_LOADING" });
      const archivedSociety = getState().filtersReducer.archivedSociety;
      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      const societyId = getState().societesReducer.societe.id;
      if (!orgId) {
        return;
      }

      const { data } = await Api.get(
        `/api/getLinkedSocieties?id=${societyId}&organisation=${orgId}&archived=${archivedSociety}`
      );

      dispatch({ type: "FETCH_LINKED_SOCIETIES", payload: data.data });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const getSocietyCalendars =
  (withLoading = true) =>
  async (dispatch, getState) => {
    try {
      withLoading && dispatch({ type: "SOCIETE_ITEM_LOADING" });

      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      const societyId = getState().societesReducer.societe.id;
      if (!orgId) {
        return;
      }

      const { data } = await Api.get(
        `/api/getSocietyCalendars?id=${societyId}&organisation=${orgId}`
      );

      if (data.data[0]?.id) {
        dispatch(selectCalendars(data.data[0]?.id));
      }

      dispatch({ type: "FETCH_SOCIETES_CALENDARS", payload: data.data });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const getSocietyNotes =
  (withLoading = true) =>
  async (dispatch, getState) => {
    try {
      withLoading && dispatch({ type: "SOCIETE_ITEM_LOADING" });

      const orgId = getState().organisationsReducer.selectedOrganisation.id;
      const societyId = getState().societesReducer.societe.id;
      if (!orgId) {
        return;
      }

      const { data } = await Api.get(
        `/api/getSocietyNotes?id=${societyId}&organisation=${orgId}`
      );

      dispatch({ type: "FETCH_SOCIETE_NOTES", payload: data.data });
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };

export const deleteSocietyNote = (id) => async (dispatch) => {
  try {
    await Api.delete(`/api/society-notes/${id}`);

    dispatch(getSocietyNotes(false));
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const createSocietyNote = (newNote) => async (dispatch, getState) => {
  try {
    const orgId = getState().organisationsReducer.selectedOrganisation.id;
    const user = getState().userReducer.user;
    const societyId = getState().societesReducer.societe.id;
    if (!orgId) {
      return;
    }

    await Api.post(`/api/society-notes`, {
      data: {
        organisation: orgId,
        societe: societyId,
        creator: user.id,
        content: newNote.content,
        title: newNote.title,
        showToCollaborators: newNote.visibleTo.some((el) => el.value === 4),
        showToOperators: newNote.visibleTo.some((el) => el.value === 2),
        showToLectors: newNote.visibleTo.some((el) => el.value === 5),
        showToMeOnly: newNote.visibleTo.length === 0,
      },
    });

    dispatch(getSocietyNotes(false));
  } catch (error) {
    dispatch(handleError("Erreur réseau", 1));
  }
};

export const updateSocietyNote =
  (noteId, newNote) => async (dispatch, getState) => {
    try {
      const orgId = getState().organisationsReducer.selectedOrganisation.id;

      if (!orgId) {
        return;
      }

      await Api.put(`/api/society-notes/${noteId}`, {
        data: {
          content: newNote.content,
          title: newNote.title,
          showToCollaborators: newNote.visibleTo.some((el) => el.value === 4),
          showToOperators: newNote.visibleTo.some((el) => el.value === 2),
          showToLectors: newNote.visibleTo.some((el) => el.value === 5),
          showToMeOnly: newNote.visibleTo.length === 0,
        },
      });

      dispatch(getSocietyNotes(false));
    } catch (error) {
      dispatch(handleError("Erreur réseau", 1));
    }
  };
