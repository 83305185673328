import React from "react";
import { useSelector } from "react-redux";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";

const SocietyLinkedSocietiesForm = ({
  societyId,
  societyInputs,
  setsocietyInputs,
}) => {
  const { societes } = useSelector((state) => state.sharedDataReducer);

  return (
    <div>
      <div className="">
        <LighDarkMultiChoice
          label="Société(s)"
          data={societes.filter((el) => el.value !== societyId)}
          totalInputs={societyInputs}
          setValue={setsocietyInputs}
          champs={"childCompanies"}
          userSocietySelectInput
        />
      </div>
    </div>
  );
};

export default SocietyLinkedSocietiesForm;
