import { useSelector } from "react-redux";

import LightDarkText from "../../../../Common/LightDarkText";

const InterventionTitle = ({ id, title }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <LightDarkText
      className="text-start bold custom-font-size"
      color={mode === "light" ? "text-blue-grey" : "text-white"}
    >
      <span className="text-light-blue">#{id}</span>
      {` | ${title}`}
    </LightDarkText>
  );
};

export default InterventionTitle;
