import { GET_DEPARTEMENTS_SUCCESS } from "../ActionsType/departementTypes";
import { GET_DEPARTEMENS } from "../Graphql/Queries/Departement";
import client from "./Client";

export const GetAllDepatements = () => async (dispatch) => {
  try {
    // const organisation =
    //   store.getState().organisationsReducer.selectedOrganisation;

    // if (!organisation.id) {
    //   return;
    // }
    // const dd = await Api.get(
    //   `/api/fetchConditionalDepartment?organisation=${organisation.id}`
    // );
    const { data } = await client.query({
      query: GET_DEPARTEMENS,
    });
    dispatch({
      type: GET_DEPARTEMENTS_SUCCESS,
      payload: data.departements.data,
    });
  } catch (error) {}
};
