import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  BarElement,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import LightDarkContainer from "../Common/LightDarkContainer";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  convertMilliseconds,
  convertMillisecondsToHours,
} from "../../Utils/utils";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const MultipleBarChart = ({
  dataSets,
  title,
  labelDuration = "months",
  showY = false,
  customToolTip = {},
  customYCallback = function (value) {
    return "";
  },
}) => {
  const [labels, setLabels] = useState();
  const { mode } = useSelector((state) => state.genearlReducer);

  const options = {
    responsive: true,
    plugins: {
      tooltip: customToolTip,
      title: {
        display: true,
        text: title,
        align: "start",
        color: mode === "light" ? "#59638b" : "white",
        font: {
          weight: "bold",
          size: 15,
        },
      },
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          color: mode === "light" ? "#59638b" : "white",
          usePointStyle: true,
          boxHeight: 8,
          font: {
            size: 14,
          },
        },
      },
    },
    gridLines: {
      display: false,
      drawOnChartArea: false,
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#86909c80",
          font: {
            weight: "regular",
            size: 10,
          },
        },
      },
      y: {
        ticks: {
          display: showY,
          color: "#86909c80",
          font: {
            weight: "regular",
            size: 10,
          },
          callback: function (value) {
            return `${customYCallback(value)}`;
          },
        },
        grid: {
          display: true,
          color: "#86909c20",
          borderColor: "transparent",
        },
      },
    },
  };

  useEffect(() => {
    const updateLabels = () => {
      const labels = [];
      if (labelDuration === "days") {
        for (let i = 0; i < 30; i++) {
          const day = moment().subtract(i, "days").format("DD-MM");
          labels.push(day);
        }
      } else if (labelDuration === "months") {
        for (let i = 0; i < 12; i++) {
          const month = moment().subtract(i, "months").format("MMM-YY");
          labels.push(month);
        }
      } else if (labelDuration === "years") {
        for (let i = 0; i < 10; i++) {
          const year = moment().subtract(i, "years").format("YYYY");
          labels.push(year);
        }
      }
      setLabels(labels.reverse());
    };

    updateLabels();
  }, [labelDuration, dataSets]);

  return (
    <LightDarkContainer
      className="px-4 py-3 radius05 h100 radius10"
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <Bar
        options={options}
        data={{
          labels: labels,
          datasets: dataSets,
        }}
        className="py-2"
        style={{ maxHeight: "340px" }}
      />
    </LightDarkContainer>
  );
};

export default MultipleBarChart;
