import React from "react";
import { useSelector } from "react-redux";
import LightDarkPlaceHolder from "./LightDarkPlaceHolder";
import ThumbnailPlaceHolder from "./ThumbnailPlaceHolder";

const SideChatTicketPlaceHolder = ({ expandedChatList = true }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return ["", "", "", "", "", "", "", "", "", ""].map((_, index) => (
    <div
      key={index}
      className={`d-flex flex-column ${
        expandedChatList
          ? "justify-content-between px-3 py-4"
          : "justify-content-center px-2 py-3"
      }  radius05 mb-4 ${mode === "light" ? "bg-white" : "bg-dark-grey-bg"}`}
      style={{ height: `${expandedChatList ? "134px" : "76px"}` }}
    >
      <div className="row h100 w-100 m-0 justify-content-between">
        <div
          className={`col-7 h40 d-flex gap-2 p-0 ${
            expandedChatList
              ? "align-items-center"
              : "justify-content-center w-100"
          }`}
        >
          <ThumbnailPlaceHolder smallThumbnail={true} />
          {expandedChatList && (
            <div className="directionC col-9 align-items-start">
              <LightDarkPlaceHolder customClass={"py-1 w90 mb-2"} />
              <LightDarkPlaceHolder customClass={"py-1 w70"} />
            </div>
          )}
        </div>

        {expandedChatList && (
          <LightDarkPlaceHolder
            customClass={"h40 col-4 p-0 justify-content-end"}
          />
        )}
      </div>
      {expandedChatList && <LightDarkPlaceHolder customClass={"py-1 "} />}
    </div>
  ));
};

export default SideChatTicketPlaceHolder;
