import React from "react";
import IconButton from "../../../../../Common/Buttons/IconButton";
import LightDarkText from "../../../../../Common/LightDarkText";

const InterviewSubmitButton = ({
  matchRegex,
  requirementMet,
  handleSubmit,
}) => {
  return (
    <div>
      <IconButton
        icon={
          <div className="bg-light-blue h100 alignC radius10 px-4 gap-1">
            <LightDarkText color={"text-white"}>{"Suivant"}</LightDarkText>
            {/* <ChevronRight /> */}
          </div>
        }
        disabled={!matchRegex || !requirementMet}
        callBack={handleSubmit}
      />
    </div>
  );
};

export default InterviewSubmitButton;
