import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import frLocale from "@fullcalendar/core/locales/fr";

import { useDispatch, useSelector } from "react-redux";

import EventCard from "../Events/EventCard/EventCard";

import { handleEventDuration, handleEventRecurrence } from "../calendarUtils";

import { updateEvent } from "../../../REDUX/actions/calendars";
import AddEditEventContent from "../Events/SideBar/AddEditEventContent";
import { openSideBar } from "../../../REDUX/actions/sideBar";

function renderEventContent(eventInfo) {
  return <EventCard eventInfo={eventInfo} />;
}

const CalendarBody = ({ calendarRef, events }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { currentView } = useSelector((state) => state.calendarsReducer);

  const t = events?.map((event) => ({
    extendedProps: { ...event?.attributes, id: event?.id },
    title: event?.attributes?.name,
    description: event?.attributes?.description,
    color: event?.attributes?.color,
    start: `${event?.attributes?.startDate}T${event?.attributes?.startTime}`,
    end: `${event?.attributes?.endDate}T${event?.attributes?.endTime}`,
    rrule:
      event?.attributes.recurrence !== 1 && event?.attributes.recurrence
        ? {
            dtstart: `${event?.attributes?.startDate}T${event?.attributes?.startTime}`,
            freq: handleEventRecurrence(event?.attributes.recurrence),
            byweekday:
              event?.attributes.recurrence === 2 ? [0, 1, 2, 3, 4] : null,
            until: event?.attributes?.recurrenceEndDate,
          }
        : null,
    duration: handleEventDuration(
      `${event?.attributes?.startDate}T${event?.attributes?.startTime}`,
      `${event?.attributes?.endDate}T${event?.attributes?.endTime}`
    ),
  }));

  const udpdateEventDate = (info) => {
    const eventData = {
      id: info?.event?.extendedProps?.id,
      startDate: moment(info?.event?.startStr).format("YYYY-MM-DD"),
      endDate: moment(info?.event?.endStr || info?.event?.startStr).format(
        "YYYY-MM-DD"
      ),
      startTime:
        info?.view?.type === "timeGridWeek" ||
        info?.view?.type === "timeGridDay"
          ? moment(info?.event?.startStr).format("HH:mm:ss.SSS")
          : info?.event?.extendedProps?.attributes?.startTime,
      endTime:
        info?.view?.type === "timeGridWeek" ||
        info?.view?.type === "timeGridDay"
          ? moment(info?.event?.endStr || info?.event?.startStr).format(
              "HH:mm:ss.SSS"
            )
          : info?.event?.extendedProps?.attributes?.endTime,
      ...info?.event?.extendedProps?.attributes,
    };
    dispatch(updateEvent(eventData));
  };

  const headerFormats = {
    timeGridDay: {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    },
    timeGridWeek: { weekday: "short", day: "numeric", month: "numeric" },
    dayGridMonth: { weekday: "short" },
  };

  return (
    <div className="h100">
      <FullCalendar
        viewClassNames={mode === "light" ? "light-calendar" : "dark-calendar"}
        ref={calendarRef}
        locales={[frLocale]}
        locale="fr"
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          rrulePlugin,
        ]}
        headerToolbar={false}
        dayHeaderFormat={
          headerFormats[localStorage?.getItem("calendarView") || currentView]
        }
        initialView={localStorage?.getItem("calendarView") || currentView}
        firstDay={1}
        defaultRangeSeparator="-"
        titleRangeSeparator="-"
        height="auto"
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: false,
          hour12: false,
        }}
        dayMaxEvents={2}
        allDaySlot={false}
        weekends={true}
        events={t}
        eventInteractive={true}
        eventColor="#0cce91"
        displayEventEnd={true}
        editable={true}
        slotEventOverlap={true}
        eventDurationEditable={false}
        eventDrop={(eventDropInfo) => udpdateEventDate(eventDropInfo)}
        selectable={true}
        select={(info) =>
          dispatch(
            openSideBar({
              content: (
                <AddEditEventContent
                  fromCalendar={true}
                  isNew={true}
                  eventDetails={info}
                />
              ),
              title: "Ajouter un événement",
              type: "w30",
              isToggle: "isToggle value",
            })
          )
        }
        eventContent={renderEventContent}
      />
    </div>
  );
};

export default CalendarBody;
