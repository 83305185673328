export const checkRequiredTicketInputs = (
  isNew,
  ticketInputs,
  ticketHandleLogic,
  ticketFields,
  prestations,
  devicesPerPrestations,
  societes
) => {
  const anyEmptyRequiredField = ticketFields.some(
    //Check if there is an empty additional field : Demande Eleance
    (field) =>
      field.attributes.required &&
      (!ticketInputs[field.attributes.name] ||
        ticketInputs[field.attributes.name].trim() === "")
  );

  return isNew
    ? anyEmptyRequiredField ||
        !ticketInputs.name ||
        !ticketInputs.description?.replace(/<(.|\n)*?>/g, "").trim().length ||
        !ticketInputs.statut.length ||
        (!ticketInputs.departements.length && ticketHandleLogic === 2) ||
        (!ticketInputs.prestation.length && prestations.length !== 1) ||
        (!ticketInputs.device.length && devicesPerPrestations.length !== 1) ||
        !ticketInputs.type.length ||
        (!ticketInputs.societe.length && societes.length !== 1) ||
        !ticketInputs.priority.length
    : !ticketInputs.name ||
        !ticketInputs.priority.length ||
        !ticketInputs.type.length;
};
