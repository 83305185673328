import React, { useEffect } from "react";
import LightDarkText from "../Common/LightDarkText";
import { CloseIcon } from "../Common/LightDarkSvg";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../REDUX/actions/modal";
import TextAreaInput from "../Simulateur/TextAreaInput";
import { useState } from "react";
import LightDarkInput from "../Common/LightDarkInput";
import FileUploader from "../Simulateur/FileUploader";
import Select from "../Simulateur/Select";
import { mapCloudData, mapDepData } from "../../Utils/utils";
import { GetAllDepatements } from "../../REDUX/actions/departement";
import ColoredButton from "../Common/Buttons/ColoredButton";
import Switch from "../Simulateur/questions/QuestionForm/Switch";
import { getCloud } from "../../REDUX/actions/cloud";
import LighDarkMultiChoice from "../Common/LighDarkMultiChoice";
import {
  createDiffusion,
  updateDiffusion,
} from "../../REDUX/actions/diffusions";

const DiffusionForm = ({ id, alert }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { diffusionLoading } = useSelector((state) => state.alertsReducer);
  const { departments } = useSelector((state) => state.departmentReducer);

  const { fichiers } = useSelector((state) => state.cloudReducer);
  const { ticketHandleLogic } = useSelector(
    (state) => state.organisationsReducer
  );
  const [errorMessage, setErrorMessage] = useState();
  //16/9 resolution
  const [formData, setFormData] = useState(
    alert
      ? {
          title: alert?.titre,
          description: alert?.description,
          link: alert?.link,
          expirationDate: alert?.expirationDate,
          is_fullWidth: alert?.isFullWidth,
          document: alert?.image?.data?.id,
          department: mapDepData(alert?.departements?.data, true) ?? [],
          prestations: [],
        }
      : {
          department: [],
          document: [],
          prestations: [],
        }
  );
  useEffect(() => {
    dispatch(GetAllDepatements());
    dispatch(getCloud());
  }, []);
  const validateForm = () => {
    let valid = true;
    if (!formData?.document?.length && formData?.image?.length === 0) {
      setErrorMessage("Merci de choisir un document ou un fichier.");
      valid = false;
    }
    if (formData?.document?.length && formData?.image?.length > 0) {
      setErrorMessage(
        "Vous pouvez uniquement soit importer  un fichier soit sélectionner un document."
      );
      valid = false;
    }
    if (formData?.expirationDate) {
      const selectedDate = new Date(formData?.expirationDate);
      const today = new Date();
      if (selectedDate < today) {
        setErrorMessage(
          "S'il vous plaît, sélectionnez une date d'expiration valide."
        );
        valid = false;
      }
    }
    return valid;
  };
  const handleChange = (value) => {
    if (errorMessage) {
      setErrorMessage();
    }
    setFormData(value);
  };
  const handleSubmit = () => {
    if (validateForm()) {
      if (id) {
        dispatch(updateDiffusion(formData, id));
      } else {
        dispatch(createDiffusion(formData));
      }
    }
  };
  return (
    <div
      className={`modalContainer ${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      } p-4 radius05 sideChatListContainer`}
      style={{
        height: "780px",
        width: `600px`,
      }}
    >
      <div className="d-flex justify-content-between">
        <LightDarkText className="text-center custom-title-font-size bold w-100 mt-4">
          {id ? "Modifier la diffusion" : "Ajouter une diffusion"}
        </LightDarkText>
        <div
          className="justify-self-end"
          onClick={() => dispatch(closeModal())}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="mx-2">
        <div className="mt-4">
          <LightDarkInput
            label={"Titre"}
            placeholder={"titre"}
            bgWhite={false}
            champs={`title`}
            maxLength={50}
            totalInputs={formData}
            setValue={handleChange}
          />
        </div>
        <div className="mt-4">
          <TextAreaInput
            label={"Description"}
            extraLabel={"(300 caractères maximum)"}
            required={false}
            bgwhite={false}
            champs={`description`}
            totalInput={formData}
            setValue={handleChange}
            placeholder="description"
          />
        </div>
        <div className="mt-4">
          <FileUploader
            label={"Image"}
            champs={`image`}
            extraLabel={"(Résolution optimale 16:9)"}
            totalInputs={formData}
            setValue={handleChange}
            required={true}
          />
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div className="col-5">
            <LightDarkInput
              label={"Lien"}
              placeholder={"lien"}
              bgWhite={false}
              champs={`link`}
              totalInputs={formData}
              setValue={handleChange}
            />
          </div>
          {!alert?.image?.data?.attributes ? (
            <div className="col-5">
              <Select
                data={mapCloudData(fichiers)}
                label={"Document"}
                bgWhite={false}
                position="absolute"
                placeholder={"Sélectionner un document"}
                champs={`document`}
                totalInput={formData}
                setValue={handleChange}
                required={true}
              />
            </div>
          ) : null}
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div className="col-5 ">
            {ticketHandleLogic === 2 ? (
              <LighDarkMultiChoice
                label="Département"
                data={mapDepData(departments, true)}
                totalInputs={formData}
                setValue={handleChange}
                champs="department"
                multiple
              />
            ) : (
              <LighDarkMultiChoice
                label={"Prestation"}
                data={[]}
                totalInputs={formData}
                setValue={handleChange}
                multiple
                champs="prestations"
              />
            )}
          </div>
          <div className="col-5  ">
            <LightDarkInput
              label={"Valable jusqu’au"}
              placeholder={"date"}
              bgWhite={false}
              champs={`expirationDate`}
              type="date"
              totalInputs={formData}
              setValue={handleChange}
            />
          </div>
        </div>
        <div className="mt-5">
          {errorMessage && (
            <span className="field-error f11  mt-1 text-red d-flex">
              {errorMessage}
            </span>
          )}
        </div>
        <div className={`row`}>
          <LightDarkText
            className={"text-start bold custom-ticket-font-size mb-2"}
          >
            <span className="text-red">*</span>Veuillez remplir au moins l'un
            des deux champs
          </LightDarkText>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <Switch
            label={"Afficher sur toute la largeur de la page"}
            champs={"is_fullWidth"}
            totalInput={formData}
            setValue={handleChange}
          />
          <div className="w30">
            <ColoredButton
              color="light-blue"
              textButton={"Valider"}
              callback={handleSubmit}
              disabled={
                formData?.document?.length === 0 &&
                !formData?.image &&
                !alert?.image?.data?.attributes
              }
              loading={diffusionLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiffusionForm;
