import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import MessagesRightPanel from "./RightPanel/MessagesRightPanel";
import TicketChat from "./TicketMessages/TicketChat";
import TicketsList from "./TicketsList/TicketsList";

import { scrollTo } from "../../Utils/utils";

const TicketItemContainer = () => {
  const { ticket, expandedChatList } = useSelector(
    (state) => state.ticketsReducer
  );

  useEffect(() => {
    scrollTo(`chat${ticket.id}`);
  }, [ticket?.id]);

  return (
    (ticket?.attributes ||
      !ticket?.attributes?.notifications?.data?.length) && (
      <div
        className="container-fluid d-flex "
        style={{
          height: "calc(100vh - 170px)",
        }}
      >
        <TicketsList />
        <div
          className={`${expandedChatList ? "col-6 px-4" : "col-8 px-4"} t-02`}
        >
          <TicketChat />
        </div>
        <MessagesRightPanel />
      </div>
    )
  );
};

export default TicketItemContainer;
