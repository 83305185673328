import React from "react";
import ThumbnailPlaceHolder from "../../PlaceHolders/ThumbnailPlaceHolder";

const OrganisationLoading = () => {
  return (
    <div className="d-flex flex-column gap-2 mt-1 pointer">
      {["", "", "", ""].map((el, index) => (
        <ThumbnailPlaceHolder key={index} index={index} />
      ))}
    </div>
  );
};

export default OrganisationLoading;
