import { useSelector } from "react-redux";

import LightDarkText from "../../../../../../Common/LightDarkText";

import { showDateMessage, showTimeMessage } from "../../../../../ticketUtils";

const MessageTime = ({ message, showFullDate }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <LightDarkText
      color={mode === "light" ? "text-light-text" : "text-white"}
      className="f10 bold"
    >
      {showFullDate ? showDateMessage(message) : null}{" "}
      {showTimeMessage(message)}
    </LightDarkText>
  );
};

export default MessageTime;
