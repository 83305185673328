import React from "react";
import LightDarkPlaceHolder from "../LightDarkPlaceHolder";

const SocietyInfoPlaceHolder = () => {
  return (
    <div className="w-100 px-4 py-3 d-flex flex-column justify-content-between h100 gap-5">
      <div className="alignC">
        <LightDarkPlaceHolder width={72} height={72} />
      </div>
      <div className="d-flex flex-column gap-2 w60">
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
      </div>
      <div className="d-flex flex-column gap-2 w60">
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
      </div>
      <div className="d-flex flex-column gap-2 w60">
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
      </div>
      <div className="d-flex flex-column gap-2 w60">
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
      </div>
      <div className="d-flex flex-column gap-2 w60">
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
      </div>
      <div className="d-flex flex-column gap-2 w60">
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
        <LightDarkPlaceHolder customClass={"w-100 py-1"} />
      </div>
      <div className="alignH gap-3">
        <LightDarkPlaceHolder width={30} height={30} />
        <LightDarkPlaceHolder width={30} height={30} />
        <LightDarkPlaceHolder width={30} height={30} />
        <LightDarkPlaceHolder width={30} height={30} />
        <LightDarkPlaceHolder width={30} height={30} />
      </div>
    </div>
  );
};

export default SocietyInfoPlaceHolder;
