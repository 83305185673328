import moment from "moment";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LightDarkFilterSelect from "../../Common/LightDarkFilterSelect";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import LightDarkText from "../../Common/LightDarkText";

import {
  changeCalendarView,
  getCalendarRef,
} from "../../../REDUX/actions/calendars";

const CalendarHeader = ({ calendarRef }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);

  const [date, setDate] = useState(
    moment(calendarRef.current?.getApi()?.getDate())
  );
  const CalendarGridType = [
    { label: "Jour", value: 1, gridValue: "timeGridDay" },
    { label: "Semaine", value: 2, gridValue: "timeGridWeek" },
    { label: "Mois", value: 3, gridValue: "dayGridMonth" },
  ];
  const [calendarInputs, setCalendarInputs] = useState({
    gridType: localStorage?.getItem("calendarView")
      ? CalendarGridType.filter(
          (el) => el.gridValue === localStorage?.getItem("calendarView")
        )
      : [],
  });

  const calApi = calendarRef.current?.getApi();
  useEffect(() => {
    const calApi = calendarRef.current?.getApi();
    if (calApi) {
      dispatch(getCalendarRef(calApi));
    }
  }, []);

  useEffect(() => {
    if (calApi) {
      if (calendarInputs?.gridType[0]?.value === 1) {
        calApi.changeView("timeGridDay");
        dispatch(changeCalendarView("timeGridDay"));
      } else if (calendarInputs?.gridType[0]?.value === 3) {
        calApi.changeView("dayGridMonth");
        dispatch(changeCalendarView("dayGridMonth"));
      } else {
        calApi.changeView("timeGridWeek");
        dispatch(changeCalendarView("timeGridWeek"));
      }
      setDate(moment(calApi.getDate()));
    }
  }, [calendarInputs]);

  useEffect(() => {
    const calApi = calendarRef.current?.getApi();
    if (calApi) {
      setDate(moment(calApi?.getDate()));
    }
  }, [calendarRef]);

  const handleDateChange = (direction) => {
    if (calApi) {
      if (direction === "prev") {
        calApi.prev();
      } else if (direction === "next") {
        calApi.next();
      } else if (direction === "today") {
        calApi.today();
      } else if (direction === "day") {
        calApi.changeView("timeGridDay");
      } else if (direction === "month") {
        calApi.changeView("dayGridMonth");
      } else {
        calApi.changeView("timeGridWeek");
      }
      setDate(moment(calApi.getDate()));
    }
  };
  return (
    <div
      ref={calendarRef}
      className="d-flex align-items-center justify-content-between"
    >
      <LightDarkText
        className={`bold w20 f18 text-uppercase radius05 p-2 w10 ${
          mode === "light" ? "bg-light-mode" : "bg-dark-mode"
        }`}
      >
        {moment(calendarRef.current?.getApi()?.currentData?.currentDate).format(
          "MMMM YYYY"
        )}
      </LightDarkText>

      <div className=" gap-3 d-flex align-items-center justify-content-end flex-1">
        <div>
          <LightDarkFilterSelect
            data={CalendarGridType}
            totalInputs={calendarInputs}
            setValue={setCalendarInputs}
            multiple={false}
            champs="gridType"
            noSearch
            calendarSelect
            placeholder="Semaine"
          />
        </div>
        <div>
          <ColoredButton
            textButton="Revenir à aujourd'hui"
            outlined
            color={mode === "light" ? "blue-grey" : "light-blue-grey"}
            border
            callback={() => handleDateChange("today")}
          />
        </div>
        <div
          className={`py-1 w20 me-2 px-3 d-flex align-items-center justify-content-between radius05`}
          style={{
            border: `1px solid ${mode === "light" ? "#F4F6FD" : "#252c45"}`,
            minWidth: "fit-content",
          }}
        >
          <i
            className="bi bi-caret-left-fill f20 text-light-text pointer t-02 clickable-text"
            onClick={() => handleDateChange("prev")}
          ></i>

          <LightDarkText
            className="f12 bold px-3"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {calendarRef.current?.getApi()?.currentData?.viewTitle}
          </LightDarkText>

          <i
            className="bi bi-caret-right-fill f20 text-light-text pointer t-02 clickable-text"
            onClick={() => handleDateChange("next")}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default CalendarHeader;
