import React from "react";
import ThumbnailPlaceHolder from "../../PlaceHolders/ThumbnailPlaceHolder";
import LightDarkPlaceHolder from "../../PlaceHolders/LightDarkPlaceHolder";

const TableEltPlaceHolder = () => {
  return Array.from({ length: 10 }).map((_, index) => (
    <div className="alignH gap-3 my-2" key={index}>
      <div className="w5 ms-3">
        <ThumbnailPlaceHolder />
      </div>
      <div className="alignH flex-1 gap-5 ms-3">
        <LightDarkPlaceHolder width={"20%"} height={10} />
        <LightDarkPlaceHolder width={"20%"} height={10} />
        <LightDarkPlaceHolder width={"20%"} height={10} />
        <LightDarkPlaceHolder width={"20%"} height={10} />
      </div>
    </div>
  ));
};

export default TableEltPlaceHolder;
