import React from "react";
import { useSelector } from "react-redux";

import InterventionLogic1 from "../InterventionActions/InterventionLogics/InterventionLogic1";
import InterventionLogic2 from "../InterventionActions/InterventionLogics/InterventionLogic2";

const InterventionActions = ({ intervention, ticket }) => {
  const { selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );

  return selectedOrganisation.attributes.interventionLogic === 1 ? (
    <InterventionLogic1 intervention={intervention} ticket={ticket} />
  ) : (
    <InterventionLogic2 intervention={intervention} ticket={ticket} />
  );
};

export default InterventionActions;
