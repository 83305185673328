import React from "react";

const Mentions = ({ fill = "#a9bcd3", width = "24", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10V25C0 30.5229 4.47715 35 10 35H25C30.5228 35 35 30.5229 35 25V10C35 4.47715 30.5228 0 25 0H10ZM21.2679 6.41335C18.7947 5.57381 16.1206 5.53008 13.6213 6.2883C11.1219 7.04652 8.92281 8.5686 7.33288 10.6407C5.74295 12.7129 4.84204 15.231 4.75668 17.8415C4.67132 20.4519 5.40581 23.0236 6.85698 25.1951C8.30815 27.3667 10.4031 29.0292 12.8476 29.9491C15.2921 30.869 17.9633 31 20.4861 30.3238C21.1529 30.1451 21.5486 29.4597 21.3699 28.7928C21.1912 28.126 20.5057 27.7303 19.8389 27.9091C17.8207 28.45 15.6837 28.3452 13.7281 27.6093C11.7725 26.8734 10.0965 25.5434 8.93558 23.8061C7.77465 22.0688 7.18706 20.0116 7.25534 17.9232C7.32363 15.8348 8.04436 13.8203 9.3163 12.1626C10.5882 10.5049 12.3475 9.28722 14.347 8.68064C16.3465 8.07407 18.4858 8.10905 20.4644 8.78069C22.4429 9.45232 24.1614 10.7269 25.3785 12.4253C26.5956 14.1237 27.25 16.1607 27.25 18.2502V20.1252C27.25 20.6225 27.0525 21.0994 26.7008 21.451C26.3492 21.8027 25.8723 22.0002 25.375 22.0002C24.8777 22.0002 24.4008 21.8027 24.0492 21.451C23.6975 21.0994 23.5 20.6225 23.5 20.1252V18.2572C23.5 18.2549 23.5 18.2525 23.5 18.2502C23.5 18.2479 23.5 18.2456 23.5 18.2432V14.5002C23.5 13.8099 22.9404 13.2502 22.25 13.2502C21.9236 13.2502 21.6264 13.3753 21.4038 13.5802C20.2996 12.5973 18.8445 12.0002 17.25 12.0002C13.7982 12.0002 11 14.7984 11 18.2502C11 21.702 13.7982 24.5002 17.25 24.5002C19.0199 24.5002 20.618 23.7645 21.7551 22.5823C21.909 22.809 22.0849 23.0223 22.2814 23.2188C23.1019 24.0393 24.2147 24.5002 25.375 24.5002C26.5353 24.5002 27.6481 24.0393 28.4686 23.2188C29.2891 22.3983 29.75 21.2855 29.75 20.1252V18.2502C29.75 15.6384 28.932 13.0921 27.4106 10.9691C25.8893 8.84606 23.7412 7.25289 21.2679 6.41335ZM21 18.2556C21 18.2538 21 18.252 21 18.2502C21 18.2484 21 18.2466 21 18.2448C20.9971 16.1762 19.3193 14.5002 17.25 14.5002C15.1789 14.5002 13.5 16.1791 13.5 18.2502C13.5 20.3213 15.1789 22.0002 17.25 22.0002C19.3193 22.0002 20.9971 20.3242 21 18.2556Z"
        fill={fill}
      />
    </svg>
  );
};

export default Mentions;
