const initialState = {
  open: false,
  content: null,
  sideBartype: "", //Side bar width ex : w30
  sideBarTitle: null,
  sideBarToggle: null,
};

const sideBarReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "OPEN_SIDE_BAR":
      return {
        ...state,
        open: true,
        content: payload?.content,
        sideBartype: payload?.type || "w50",
        sideBarTitle: payload?.title,
        sideBarToggle: payload.sideBarToggle,
      };
    case "CLOSE_SIDE_BAR":
      return { ...state, open: false, content: false, closable: true };
    case "SET_SIDE_BAR_TOGGEL":
      return { ...state, sideBarToggle: payload.sideBarToggle };
    default:
      return state;
  }
};

export default sideBarReducer;
