export const openConfirmationModal = (data) => async (dispatch) => {
  dispatch({
    type: "OPEN_CONFIRMATION_MODAL",
    payload: data,
  });
};

export const closeConfirmationModal = () => async (dispatch) => {
  dispatch({
    type: "CLOSE_CONFIRMATION_MODAL",
  });
};
