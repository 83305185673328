import React from "react";
import { useSelector } from "react-redux";
import "./LightDarkContainer.css";

const LightDarkContainer = ({
  children,
  colorDark = "dark-mode",
  colorLight = "light-mode",
  className = "",
  formContainer,
  cardContainer = false,
  overviewContainer,
  hover = false,
  active = false,
  clientSocietyCard = false,
  callBack,
  resolvedTicket = false,
  archivedTicket = false,
  index,
  style,
  sideBar,
  id = "",
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  return (
    <div
      id={id}
      tabIndex={index}
      onClick={callBack}
      className={`${className} ${
        hover ? (mode === "light" ? "lightHover" : "darkHover") : ""
      } ${active ? "active" : ""}
       ${
         mode === "light" && !resolvedTicket && !archivedTicket
           ? `bg-${colorLight}`
           : mode === "dark" &&
             !resolvedTicket &&
             !archivedTicket &&
             `bg-${colorDark}`
       } commonModeContainer me-0`}
      style={{
        ...style,
        backgroundColor: `${
          resolvedTicket ? "#0cce9126" : archivedTicket && "#59638b12"
        }`,
        borderTop: `${
          cardContainer &&
          ` 2px solid ${mode === "light" ? "#F8F9FF" : "#252C45"}`
        }`,
        width: `${overviewContainer ? "32%" : clientSocietyCard && "70px"}`,
        height: `${
          clientSocietyCard
            ? "70px"
            : overviewContainer
            ? "84px"
            : formContainer
            ? "50px"
            : sideBar && "calc(100vh - 180px)"
        }`,
      }}
    >
      {children}
    </div>
  );
};

export default LightDarkContainer;
