import React from "react";
import emptyAgilix from "../../Images/emptyAgilix.png";
import LightDarkText from "./LightDarkText";

const FeatComingSoon = ({ small = false, title = "" }) => {
  return (
    <div className="h100 alignC flex-column gap-3">
      <img src={emptyAgilix} alt="agilix" width={small ? 80 : 250} />
      <LightDarkText className={`${small ? "f18" : "f22"} xbold text-center`}>
        <span dangerouslySetInnerHTML={{ __html: title }}></span>
      </LightDarkText>
    </div>
  );
};

export default FeatComingSoon;
