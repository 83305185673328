import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../REDUX/actions/modal";
import { createAlert, updateAlert } from "../../../REDUX/actions/alerts";
import { clearTempUpload } from "../../../REDUX/actions/files";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { validURL } from "../../../Utils/utils";
import LightDarkText from "../../Common/LightDarkText";
import { CloseIcon } from "../../Common/LightDarkSvg";
import LightDarkTextEditor from "../../Common/LightDarkTextEditor";
import LightDarkInput from "../../Common/LightDarkInput";
import LighDarkMultiChoice from "../../Common/LighDarkMultiChoice";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import AttachementUploader from "../../Common/AttachementUploader";

const AlertForm = ({ isNew, alert }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const { user } = useSelector((state) => state.userReducer);
  const { mode } = useSelector((state) => state.genearlReducer);
  const { temporarySelected } = useSelector((state) => state.filesReducer);

  const seenBy = [
    { label: "Administrateurs", userRole: "1", value: 1 },
    { label: "Collaborateurs", userRole: "2", value: 2 },
    { label: "Opérateurs", userRole: "3", value: 3 },
    { label: "Lecteurs", userRole: "4", value: 4 },
  ];
  const alertRelatedUsers = [
    alert?.attributes?.administrators && {
      label: "Administrateurs",
      userRole: "1",
      value: 1,
    },
    alert?.attributes?.collaborators && {
      label: "Collaborateurs",
      userRole: "2",
      value: 2,
    },
    alert?.attributes?.operators && {
      label: "Opérateurs",
      userRole: "3",
      value: 3,
    },
    alert?.attributes?.lectors && {
      label: "Lecteurs",
      userRole: "4",
      value: 4,
    },
  ];

  const [alertInputs, setAlertInputs] = useState(
    isNew
      ? {
          description: "",
          url: "",
          creator: user.id,
          seenBy: [],
        }
      : {
          ...alert?.attributes,
          seenBy: alertRelatedUsers?.filter((el) => el),
        }
  );
  const handleSubmitAlert = () => {
    if (isNew) {
      dispatch(
        createAlert({
          ...alertInputs,
          administrators: alertInputs.seenBy.find((el) => el.userRole === "1")
            ? true
            : false,
          collaborators: alertInputs.seenBy.find((el) => el.userRole === "2")
            ? true
            : false,
          operators: alertInputs.seenBy.find((el) => el.userRole === "3")
            ? true
            : false,
          lectors: alertInputs.seenBy.find((el) => el.userRole === "4")
            ? true
            : false,
        })
      );
    } else {
      dispatch(
        updateAlert({
          ...alertInputs,
          id: alert?.id,
          administrators: alertInputs.seenBy.find((el) => el.userRole === "1")
            ? true
            : false,
          collaborators: alertInputs.seenBy.find((el) => el.userRole === "2")
            ? true
            : false,
          operators: alertInputs.seenBy.find((el) => el.userRole === "3")
            ? true
            : false,
          lectors: alertInputs.seenBy.find((el) => el.userRole === "4")
            ? true
            : false,
        })
      );
    }

    dispatch(clearTempUpload());
    dispatch(closeModal());
  };

  const handleCancelAlert = () => {
    dispatch(clearTempUpload());
    dispatch(closeModal());
  };

  return (
    <div
      className={`modalContainer ${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      } p-4 radius05 sideChatListContainer`}
      style={{
        height: "780px",
        width: `${screenWidth > 600 ? "600px" : "calc(100vw - 50px)"}`,
      }}
    >
      <div className="d-flex justify-content-between">
        <LightDarkText className="text-center custom-title-font-size bold w-100 mt-3">
          {isNew ? "Créer une nouvelle annonce" : "Modifier l'annonce"}
        </LightDarkText>
        <div className="justify-self-end" onClick={() => handleCancelAlert()}>
          <CloseIcon />
        </div>
      </div>
      <div
        className="px-2 d-flex flex-column justify-content-between mt-4 h90"
        onSubmit={(e) => e.preventDefault()}
      >
        <div>
          <div>
            <LightDarkTextEditor
              label={"Description de l'annonce"}
              height={"100"}
              required
              totalInputs={alertInputs}
              setValue={setAlertInputs}
              champs="description"
              value={alertInputs}
              alertTextEditor
            />
          </div>
          <div className="row mt-4">
            <AttachementUploader
              label={"Image"}
              required={isNew}
              multiple={false}
            />
          </div>
          <div className="row mt-4">
            <div className="col-6">
              <LightDarkInput
                label="URL"
                type="url"
                placeholder="lien de l'annonce"
                totalInputs={alertInputs}
                setValue={setAlertInputs}
                champs="url"
              />
            </div>
            <div className="col-6">
              <LighDarkMultiChoice
                label="Vu par"
                data={seenBy}
                totalInputs={alertInputs}
                setValue={setAlertInputs}
                multiple={true}
                champs="seenBy"
                required
              />
            </div>
          </div>
          <div className="row mt-5">
            <LightDarkText
              className={"text-start bold custom-ticket-font-size"}
            >
              <span className="text-red">*</span>Champs obligatoires
            </LightDarkText>
          </div>
        </div>
        <div className="w-100 row justify-content-center">
          <div className={`${isNew ? "col-5" : "col-7"}`}>
            <ColoredButton
              textButton={`${
                isNew ? "Programmer l'annonce" : "Enregistrer les modifications"
              }`}
              color="light-blue"
              disabled={
                alertInputs.description?.replace(/<(.|\n)*?>/g, "").trim()
                  .length === 0 ||
                (alertInputs.url && !validURL(alertInputs.url)) ||
                !alertInputs.seenBy.length ||
                (isNew && !temporarySelected?.length > 0)
              }
              callback={() => handleSubmitAlert()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertForm;
