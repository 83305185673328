import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListHeader from "../../Components/Common/ListHeader";
import SearchInput from "../../Components/Common/SearchInput";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import TableList from "../../Components/Common/TableComponents/TableList";
import SideBar from "../../Components/Common/TableComponents/SideBar";

import { useEffect } from "react";

import { tableListData } from "../../Utils/tableStaticData";
import ProspectColumn from "../../Components/Prospect/ProspectColumn";
import { useNavigate } from "react-router-dom";
import { closeSideBarCard } from "../../REDUX/actions/general";
import { resetProspectFilters } from "../../REDUX/actions/filters";
import ProspectSideBar from "../../Components/Prospect/ProspectSideBar";
import { GetAllProspects, exporterTous } from "../../REDUX/actions/prospect";
import useOnScreen from "../../Hooks/useScreen";
import Loading from "../../Components/Common/Loading";
import _ from "lodash";
import { donwloadDiffusionFile } from "../../Utils/utils";
import moment from "moment";
import { openModal } from "../../REDUX/actions/modal";
import ExportModal from "../../Components/Prospect/ExportModal";
import MultipleArchiveButton from "../../Components/Commande/MultipleArchiveButton";

const ProspectView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [selectedProspects, setSelectedProspects] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [searchProspect, setSearchProspect] = useState("");
  const { prospetsRoute } = useSelector((state) => state.organisationsReducer);
  const { canCreateProspet } = useSelector((state) => state.roleReducer);
  const { measureRef, isIntersecting, observer } = useOnScreen();
  const { openSideBar } = useSelector((state) => state.genearlReducer);
  const { selectedProspect, prospectArchived, selectedDate } = useSelector(
    (state) => state.filtersReducer
  );
  const { transformedArrays, loadingProspect } = useSelector(
    (state) => state.prospectReducer
  );
  const filteredTableListData = openSideBar
    ? tableListData.filter((item) => item.alwaysVisible)
    : tableListData;

  const [sortProspect, setsortProspect] = useState({
    champs: "id",
    asc: false,
  });

  const debounceSearch = _.debounce((value) => {
    setSearchProspect(value);
  }, 500);

  useEffect(() => {
    dispatch(
      GetAllProspects(
        page,
        selectedProspect,
        sortProspect,
        searchProspect,
        selectedDate
      )
    );
  }, [
    page,
    prospectArchived,
    selectedProspect,
    sortProspect.champs,
    sortProspect.asc,
    searchProspect,
  ]);
  useEffect(() => {
    setPage(1);
  }, [
    prospectArchived,
    selectedProspect,
    sortProspect.champs,
    sortProspect.asc,
    searchProspect,
    selectedDate,
  ]);
  useEffect(() => {
    if (isIntersecting) {
      setPage((page) => page + 1);
    }
  }, [isIntersecting, dispatch, observer]);

  useEffect(() => {
    return () => {
      dispatch(resetProspectFilters());
      dispatch(closeSideBarCard());
    };
  }, [dispatch]);

  return (
    <div className="container-fluid">
      <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className={`col h100`}>
          <ListHeader
            showProspectFilter={true}
            showSocityFilter={false}
            type="prospect"
          >
            <SearchInput
              placeholder={"prénom, nom, email..."}
              onChange={(e) => {
                dispatch(closeSideBarCard());

                debounceSearch(e.target.value);
              }}
            />
          </ListHeader>
        </div>
        {canCreateProspet ? (
          <div className="col-1 ms-1 fitContent h100">
            <ColoredButton
              height="100"
              textButton={
                selectedProspects.length === 0
                  ? "Exporter tout"
                  : selectedProspects.length === 1
                  ? "Exporter le fichier"
                  : "Exporter les fichiers"
              }
              color="light-blue"
              loading={false}
              callback={() => {
                dispatch(
                  openModal({
                    type: "small",
                    content: (
                      <ExportModal
                        handleSubmit={(type) =>
                          dispatch(
                            exporterTous(
                              type,
                              prospectArchived
                                ? ["KO_DRAFT"]
                                : ["DRAFT", "DRAFT_SELLER", "DRAFT_COMMERCIAL"],
                              selectedProspects,
                              prospectArchived,
                              (value) => {
                                const time = moment(Date.now()).format(
                                  "DD-MM-YYYY-hh-mm"
                                );
                                donwloadDiffusionFile(
                                  value,
                                  `prospect-${time}`
                                );
                              }
                            )
                          )
                        }
                      />
                    ),
                  })
                );
              }}
            />
          </div>
        ) : null}{" "}
        {canCreateProspet ? (
          <MultipleArchiveButton
            setSelectedItems={setSelectedProspects}
            selectedItems={selectedProspects}
            archived={prospectArchived}
            type={"prospects"}
          />
        ) : null}
        {canCreateProspet ? (
          <div className="col-2 ms-1 h100">
            <ColoredButton
              height="100"
              textButton={"Ajouter un prospect"}
              color="light-blue"
              callback={() => {
                navigate(`${prospetsRoute}/new`);
              }}
            />
          </div>
        ) : null}
      </div>
      <div className="d-flex justify-content-between gap-3 p-0">
        <TableList
          headerItems={filteredTableListData}
          setSort={setsortProspect}
          sort={sortProspect}
          type={"prospet"}
          hasSelect
          loading={false}
        >
          {transformedArrays?.map((dataItem, index) => (
            <ProspectColumn
              key={dataItem?.id}
              dataItem={dataItem}
              filteredTableListData={filteredTableListData}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              index={index}
              measureRef={null}
              selectedProspects={selectedProspects}
              setSelectedProspects={setSelectedProspects}
            />
          ))}
          <div ref={measureRef} className="opacity-0">
            lazy pointer
          </div>
          {loadingProspect && (
            <div className=" position-relative ">
              <Loading />
            </div>
          )}
        </TableList>
        {openSideBar && (
          <SideBar setSelectedItem={setSelectedItem}>
            <ProspectSideBar prospectId={selectedItem} />
          </SideBar>
        )}
      </div>
    </div>
  );
};

export default ProspectView;
