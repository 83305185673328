import React, { useEffect, useState } from "react";
import UserThumbnail from "../../../../../../Common/UserThumbnail";
import InternMessageContent from "../InternMessageContainer/InternMessageContent";
import { InternNoteIcon } from "../../../../../../Common/LightDarkSvg";
import "./InternMessage.css";
import { showConditionalMessage } from "../../../../messageUtils";
import { useSelector } from "react-redux";

const InternMessageContainer = ({
  internMessage,
  ticketId,
  message,
  meSending,
}) => {
  const { user } = useSelector((state) => state.userReducer);
  const [internMeSending, setInternMeSending] = useState();

  const [showInternOriginal, setShowInternOriginal] = useState(false);
  const [internMessageContentToShow, setInternMessageContentToShow] = useState(
    showConditionalMessage(
      internMessage?.attributes,
      user,
      showInternOriginal || internMeSending
    )
  );
  useEffect(() => {
    setInternMessageContentToShow(
      showConditionalMessage(
        internMessage?.attributes,
        user,
        showInternOriginal || internMeSending
      )
    );
  }, [internMessage, showInternOriginal, internMeSending]);
  return (
    <div className={`d-flex w80 ${meSending && "align-self-end"} gap-2 px-2`}>
      <InternNoteIcon />
      <div
        id={`messageId-${internMessage.id}`}
        className={`d-flex gap-2  flex-1 mb-3 ${
          meSending ? "flex-row-reverse" : ""
        }`}
      >
        <div className="align-self-end mb-4">
          <UserThumbnail
            userId={internMessage.attributes.sender?.data?.id}
            connected={
              internMessage.attributes.sender?.data?.attributes?.connected
            }
            lastConnected={
              internMessage.attributes.sender?.data?.attributes?.lastConnected
            }
            apiImg={
              internMessage.attributes.sender?.data?.attributes?.avatar?.data
                ?.attributes?.image?.data?.attributes?.url
            }
            img={`user${
              internMessage.attributes.sender?.data?.attributes?.genre || "3"
            }`}
            size={30}
          />
        </div>
        <InternMessageContent
          message={message}
          ticketId={ticketId}
          internMessage={internMessage}
          internMessageContentToShow={internMessageContentToShow}
          internMeSending={internMeSending}
          setInternMeSending={setInternMeSending}
          showInternOriginal={showInternOriginal}
          setShowInternOriginal={setShowInternOriginal}
        />
      </div>
    </div>
  );
};

export default InternMessageContainer;
