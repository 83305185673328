import React from "react";
import {
  Tickets,
  InresolvedTickets,
  Interventions,
} from "../Common/LightDarkSvg";

import StatBox from "./StatBox";

const TicketsGeneralStats = ({ tickets, interventions, hours }) => {
  const ticketOverview = [
    {
      icon: <Tickets />,
      number: tickets,
      title: "Tickets créés",
    },
    {
      icon: <InresolvedTickets />,
      number: interventions,
      title: "Interventions créées",
    },
    {
      icon: <Interventions />,
      number: hours,
      title: "D'interventions traitées",
      unit: " Heures",
    },
  ];
  return (
    <div className="d-flex justify-content-between">
      {ticketOverview.map((el, i) => (
        <div key={i} className="w32">
          <StatBox
            unit={el.unit}
            Icon={el.icon}
            count={el.number}
            title={el.title}
          />
        </div>
      ))}
    </div>
  );
};

export default TicketsGeneralStats;
