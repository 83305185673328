import Api from "../../Api/Api";

export const fetchLoginViewData = (name) => async (dispatch) => {
  try {
    dispatch({ type: "LOGIN_VIEW_DATA_LOADING" });
    const { data } = await Api.get(`/api/fetchLoginViewData?name=${name}`);
    dispatch({ type: "LOGIN_VIEW_DATA_FETCH", payload: data });
  } catch (error) {
    dispatch({ type: "LOGIN_VIEW_DATA_ERROR" });
  }
};
