import { userAttributes } from "../general/userFields";
import { ATTRIBUTES_EQUIPE } from "./Societies";
import { USER_ORG_ATTRIBUTES } from "./UserOrg";

export const CALENDAR_ATTRIBUTES = `
name

societe {
    data {
        id
        attributes {
            name
            
            
            tickets (pagination: {page: 1, pageSize: 100}) {
                data {
                  id
                  attributes {
                    name
                    archived
                  }
                }
              }
            avatar {
                data {
                    id
                    attributes {
                        image {
                            data {
                                id 
                                attributes {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
avatar {
    data {
        id
        attributes {
            image {
                data {
                    id
                    attributes {
                        url
                    }
                }
            }
        }
    }
}
`;
