import {
  handleFirstLastName,
  populateUser,
  showMultipleSocieties,
  showRoleNameByUser,
} from "../../Utils/utils";
import _ from "lodash";

const initialState = {
  postes: [],
  devices: [],
  societes: [],
  canBeAddedToTickets: [],
  admins: [],
  canShareTo: [],
  prestations: [],
  languages: [],
  priorityValues: [
    { value: 1, label: "⏳ Faible" },
    { value: 2, label: "🙌 Normal" },
    { value: 3, label: "🔥 Urgent" },
  ],
  ticketTypesValues: [
    { value: 1, label: "Évolution" },
    { value: 2, label: "Assistance" },
  ],
  ticketStatutValues: [
    { value: 1, label: "En Cours" },
    { value: 2, label: "Résolu" },
  ],
  invoiceTypesValues: [
    { value: 1, label: "Facture" },
    { value: 2, label: "Avoir" },
  ],
  TVARate: [
    { value: 1, label: "20" },
    { value: 2, label: "10" },
    { value: 3, label: "5.5" },
    { value: 4, label: "2.1" },
    { value: 5, label: "8.5" },
    { value: 6, label: "1.75" },
    { value: 7, label: "1.05" },
    { value: 8, label: "0.90" },
  ],
  devicesPerPrestations: [],
  calendarTypesValues: [
    { value: 1, label: "Marketing" },
    { value: 2, label: "Rendez-vous" },
  ],
  calendarStatutValues: [
    { value: 1, label: "🔜 En attente" },
    { value: 2, label: "⏳ À valider" },
    { value: 3, label: "✅ Validé" },
    { value: 4, label: "🕓 Programmé" },
  ],
  eventRecurrenceValues: [
    { value: 1, label: "Une seule fois" },
    { value: 2, label: "Jours de la semaine" },
    { value: 3, label: "Tous les jours" },
    { value: 4, label: "Toutes les semaines" },
    { value: 5, label: "Tous les mois" },
    { value: 6, label: "Tous les ans" },
  ],
  eventReminder: [
    { value: 1, label: "Une heure avant l'événement" },
    { value: 2, label: "4 heures avant l'événement" },
    { value: 3, label: "24 heures avant l'événement" },
    { value: 4, label: "48 heures avant l'événement" },
  ],
  organisationExternalServices: [],
};

const sharedDataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "INITIAL_LOADING":
      return { ...state, initialLoading: true };
    case "RESET_ORGANISATION":
      return { ...state, initialLoading: true };
    case "FETCH_INITIAL_DATA":
      return {
        ...state,
        languages: payload?.languages?.data?.map((language) => {
          return {
            value: language.id,
            label: `${language.attributes.name} (${language.attributes.code})`,
          };
        }),
        organisationExternalServices: payload.externalServicesTypes.data,

        postes: payload?.postes?.data
          ?.map((poste) => {
            return { value: poste.id, label: poste.attributes.name };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),

        ticketTypesValues: payload?.ticketTypes?.data
          ?.map((type) => {
            return { value: +type.id, label: type.attributes.name };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
        societyTypesValues: payload?.societyTypes?.data
          ?.map((type) => {
            return { value: +type.id, label: type.attributes.name };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
        departements: payload?.departements?.data?.map((departement) => {
          return {
            value: +departement.id,
            label: `${departement.attributes.code}-${departement.attributes.name}`,
          };
        }),
        devices: payload.devices?.data
          .map((device) => {
            return {
              value: +device.id,
              label: device.attributes.name,
              prestation: device?.attributes?.prestation?.id,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
        operators: payload?.canShareTo?.data
          .filter(
            (op) =>
              op.attributes.user_org_permissions.data[0].attributes.role === 2
          )
          .map((operator) => {
            return {
              value: operator.id,
              label: handleFirstLastName(operator),
              avatar: operator.attributes?.avatar?.image?.url,
              ...operator,
            };
          }),
        admins: payload.canShareTo.data
          .filter(
            (admin) =>
              admin.attributes.user_org_permissions.data[0].attributes.role ===
              1
          )
          .map((admin) => {
            return {
              value: admin.id,
              label: handleFirstLastName(admin),
              avatar: admin?.attributes?.avatar?.image?.url,
              id: admin.id,
              genre: admin.attributes.genre,
              role: admin.attributes.user_org_permissions.data[0].attributes
                .role,
              attributes: {
                ...admin.attributes,
                ...populateUser(admin),
              },
            };
          }),

        //   .map((client) => {
        //     return {
        //       value: client.id,
        //       label: handleFirstLastName(client),
        //     };
        //   }),
        canShareTo: payload.canShareTo.data.map((user) => {
          let userSociety;
          const userRole =
            +user?.attributes?.user_org_permissions?.data[0]?.attributes?.role;
          if (userRole === 1 || userRole === 2) {
            userSociety =
              user?.attributes?.user_org_permissions?.data[0]?.attributes
                ?.organisation?.data?.attributes?.name;
          } else {
            userSociety = showMultipleSocieties(user);
          }

          return {
            value: user.id,
            label: handleFirstLastName(user) + `${` - ${userSociety}`}`,

            role: showRoleNameByUser(user.attributes),
            poste:
              user?.attributes?.user_org_permissions?.data[0]?.attributes?.poste
                ?.data?.attributes?.name || "Pas de poste",
            avatar: user?.attributes?.avatar?.image?.url,
            alertValue:
              user?.attributes?.role?.data?.id === "4" ||
              user?.attributes?.role?.data?.id === "5"
                ? "4"
                : user?.attributes?.role?.data?.id,
          };
        }),
        prestations: payload.prestations?.data
          .map((prestation) => {
            return { value: +prestation.id, label: prestation.attributes.name };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
        initialLoading: false,
      };

    case "FETCH_SHARED_SOCIETY":
      return {
        ...state,
        initialLoading: false,
        societes: payload
          .sort((a, b) => a.attributes.name - b.attributes.name)
          .map((society) => {
            return {
              value: society.id,
              monthlyPlan: society.attributes.monthlyPlan,
              monthlyQuota: society.attributes.monthlyQuota,

              label:
                society.attributes.name ||
                society.attributes.email.split("@")[0],
              avatar:
                society?.attributes?.avatar?.data?.attributes?.image?.data
                  ?.attributes?.url,
              societyRelatedUsers: (society?.attributes?.workInOrg?.data ||
                society?.attributes?.responsibleOperatorsOrg?.data) && [
                ...society?.attributes?.workInOrg?.data?.map((el) => ({
                  label: `${handleFirstLastName(
                    el?.attributes?.user?.data
                  )} - ${showMultipleSocieties(el?.attributes?.user?.data)}`,
                  value: el?.attributes?.user?.data?.id,
                })),
                ...society?.attributes?.responsibleOperatorsOrg?.data?.map(
                  (el) => ({
                    label:
                      handleFirstLastName(el?.attributes?.user?.data) +
                      " - " +
                      el?.attributes?.user?.data?.attributes
                        ?.user_org_permissions?.data[0]?.attributes
                        ?.organisation?.data?.attributes?.name,
                    value: el?.attributes?.user?.data?.id,
                  })
                ),
              ],
            };
          }),
      };
    case "ADD_OPERATOR_SOCIETY":
      const operatorSociety = payload
        .sort((a, b) => a.attributes.name - b.attributes.name)
        .map((society) => {
          return {
            value: society.id,
            label: society.attributes.name,
            avatar:
              society?.attributes?.avatar?.data?.attributes?.image?.data
                ?.attributes?.url,
          };
        });
      return {
        ...state,
        initialLoading: false,
        societes: _.uniqBy([...state.societes, ...operatorSociety], "value"),
      };
    case "FETCH_NEW_SHARED_COMPANY": {
      return {
        ...state,
        initialLoading: false,
        societes: [...state?.societes, payload]
          .sort(
            (a, b) =>
              (a?.attributes?.name || a?.label) -
              (b?.attributes?.name || b?.label)
          )
          .map((society) => {
            return {
              value: society?.id || society?.value,
              label: society?.attributes?.name || society?.label,
              avatar:
                society?.attributes?.avatar?.data?.attributes?.image?.data
                  ?.attributes?.url || society?.avatar,
            };
          }),
      };
    }
    case "ADD_POSTE":
      return {
        ...state,
        initialLoading: false,
        postes: [
          { value: payload.id, label: payload.attributes.name },
          ...state.postes,
        ],
      };
    case "FETCH_OPERATORS":
      return {
        ...state,
        canBeAddedToTickets: payload
          .filter((el) => el.attributes.user_org_permissions[0].role === 2)
          .map((operator) => {
            return {
              value: operator.id,
              label: `${handleFirstLastName(operator)} - ${
                operator?.attributes?.user_org_permissions[0]?.organisation
                  ?.name
              }`,
            };
          }),
      };

    case "ADD_DEVICE":
      return {
        ...state,
        initialLoading: false,
        devices: [
          ...state.devices,
          {
            value: payload.id,
            label: payload.attributes.name,
            prestations: payload.attributes?.prestations?.data?.map(
              (prestation) => prestation?.id
            ),
          },
        ],
      };

    case "DEVICES_PER_PRESTATIONS": {
      const selectedDevices = [];
      payload?.forEach((prestation) =>
        selectedDevices.push(
          ...state?.devices?.filter(
            (device) => +device?.prestation === +prestation?.value
          )
        )
      );
      return {
        ...state,
        initialLoading: false,
        devicesPerPrestations: selectedDevices,
      };
    }
    case "RESET_PER_PRESTATIONS": {
      return { ...state, initialLoading: false, devicesPerPrestations: [] };
    }

    default:
      return state;
  }
};
export default sharedDataReducer;
