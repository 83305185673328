import React from "react";
import {
  handleError,
  handleInfo,
  handleWelcome,
} from "../../../REDUX/actions/toastMessage";
import { useDispatch, useSelector } from "react-redux";
import { Toast, ToastContainer } from "react-bootstrap";
import "./ToastMessage.css";

const ToastMessage = ({}) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { info, infoMessage, error, errorMessage, color } = useSelector(
    (state) => state.toastMessagesReducer
  );

  return (
    <>
      <ToastContainer className="myNotif">
        <Toast
          show={info}
          delay={6000}
          autohide
          onClose={() => dispatch(handleInfo(null, 0))}
          bg="transparent"
        >
          <Toast.Body
            className={`custom-font-size xbold radius05 ${
              mode === "light" ? "bg-light-mode" : "bg-dark-grey-bg"
            } text-start py-4 px-3 text-${color} gradient-text d-flex align-items-center justify-content-center`}
          >
            <i className={`bi bi-check-circle-fill me-3 f26 text-${color}`}></i>
            <div
              dangerouslySetInnerHTML={{
                __html: infoMessage,
              }}
            ></div>
          </Toast.Body>
        </Toast>
        <Toast
          show={error}
          delay={5000}
          autohide
          onClose={() => dispatch(handleError(null, 0))}
          bg="transparent"
        >
          <Toast.Body
            className={`custom-font-size xbold radius05 ${
              mode === "light" ? "bg-light-mode" : "bg-dark-grey-bg"
            } text-start py-4 text-danger d-flex align-items-center justify-content-center`}
          >
            <i className="bi bi-x-circle-fill f26 me-4 ms-4"></i>
            <div
              dangerouslySetInnerHTML={{
                __html: errorMessage,
              }}
            ></div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default ToastMessage;
