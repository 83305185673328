import React, { useEffect, useState } from "react";
import LightDarkContainer from "../Common/LightDarkContainer";
import { handleFirstLastName, handleuserRoleName } from "../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import TooltipActions from "../Common/Tooltip/TooltipActions";
import UserThumbnail from "../Common/UserThumbnail";
import LightDarkText from "../Common/LightDarkText";
import SocietyStack from "../Societies/NewSociety/SocietyStack";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import { toggleUserArchive } from "../../REDUX/actions/operateurs";
import {
  getSocietyCollaborateurs,
  getSocietyOperators,
} from "../../REDUX/actions/societe";
import { useParams } from "react-router-dom";
import { DeleteIcon, EditIcon } from "../Common/LightDarkSvg";
import { openSideBar } from "../../REDUX/actions/sideBar";
import UserForm from "../Clients/UserForm/UserForm";

const UserSquareCard = ({ userOrg, type }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [userOrgUser, setUserOrgUser] = useState(userOrg.attributes.user.data);

  useEffect(() => {
    setUserOrgUser(userOrg.attributes.user.data);
  }, [userOrg]);

  const [showTooltip, setshowTooltip] = useState(false);
  const [archiveMessage] = useState(
    `#${userOrgUser.id} ${userOrgUser.attributes.firstName} ${
      userOrgUser.attributes.lastName
    } a été ${userOrg.attributes.archived ? "désarchivé" : "Archivé"}(e)`
  );

  const { canCreateCollabs, canCreateOperators } = useSelector(
    (state) => state.roleReducer
  );
  const { isSupervisor, isClientAdmin } = useSelector(
    (state) => state.roleReducer
  );

  const { operateursArchived, clientArchived } = useSelector(
    (state) => state.filtersReducer
  );

  const editTooltipAction = () => {
    dispatch(
      openSideBar({
        content: (
          <UserForm
            userId={userOrgUser.id}
            isOp={userOrg.attributes.role <= 2}
            afterSubmitCallback={
              userOrg.attributes.role <= 2
                ? () => {
                    dispatch(getSocietyOperators(false));
                  }
                : () => {
                    dispatch(getSocietyCollaborateurs(false));
                  }
            }
          />
        ),
        title: `Modifier l'utilisateur ${handleFirstLastName(userOrgUser)}`,
        type: "w50",
        isToggle: "isToggle value",
      })
    );
    setshowTooltip(false);
  };

  const tooltipData = [
    {
      id: 2,
      text: "Éditer",
      icon: <EditIcon className={"me-2"} />,
      action: () => editTooltipAction(),
      condition: canCreateCollabs || isClientAdmin,
    },
    {
      id: 3,
      text: userOrg.attributes.archived ? "Désarchiver" : "Archiver",
      icon: <DeleteIcon className={"me-2"} />,
      action: (e) => {
        e.stopPropagation();
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(
                toggleUserArchive(
                  userOrgUser.id,
                  userOrg.attributes.archived,
                  archiveMessage
                )
              ).then(() =>
                dispatch(
                  getSocietyCollaborateurs(
                    params.societyId,
                    clientArchived,
                    false
                  )
                )
              );
            },
            message: `Êtes-vous sur de vouloir ${
              userOrg.attributes.archived ? "désarchiver" : "archiver"
            } ce client?`,
            message2: `#${userOrgUser.id} ${userOrgUser.attributes.firstName} ${userOrgUser.attributes.lastName}`,
            confirmButtonText: "Je confirme",
          })
        );
        setshowTooltip(false);
      },
      condition: (canCreateCollabs || isClientAdmin) && type === "client",
    },
    {
      id: 4,
      text: userOrg.attributes.archived ? "Désarchiver" : "Archiver",
      icon: <DeleteIcon className={"me-2"} />,
      action: (e) => {
        e.stopPropagation();
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(
                toggleUserArchive(
                  userOrgUser.id,
                  userOrg.attributes.archived,
                  archiveMessage
                )
              ).then(() =>
                dispatch(
                  getSocietyOperators(
                    params.societyId,
                    operateursArchived,
                    false
                  )
                )
              );
            },
            message: `Êtes-vous sur de vouloir ${
              userOrg.attributes.archived ? "désarchiver" : "archiver"
            } cet opérateur?`,
            message2: `#${userOrgUser.id} ${userOrgUser.attributes.firstName} ${userOrgUser.attributes.lastName}`,
            confirmButtonText: "Je confirme",
          })
        );
        setshowTooltip(false);
      },
      condition: canCreateOperators && type === "operator",
    },
  ];

  return (
    <LightDarkContainer
      colorLight={"white"}
      className={`userSquarePlaceDimension my-2 radius05 p-3 d-flex flex-column ${
        canCreateCollabs || isClientAdmin ? "pointer" : ""
      }`}
      callBack={
        canCreateCollabs || isClientAdmin ? () => editTooltipAction() : null
      }
    >
      <div className="alignH justify-content-between">
        <div className="alignH gap-2">
          <div className="text-light-message f10 customIdContainer radius05 alignC">
            #{userOrgUser.id}
          </div>
          <div className="f10 radius05 alignC customRoleContainer px-2 text-light-blue bold">
            {handleuserRoleName(userOrg.attributes.role)}
          </div>
          {userOrg.attributes.archived && (
            <div className="text-light-message f10 customIdContainer bg-light-grey radius05 alignC px-5">
              Archivé
            </div>
          )}
        </div>
        <TooltipActions
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
        />
      </div>
      <div className="alignH flex-1 gap-3">
        <UserThumbnail
          userId={userOrgUser.id}
          connected={userOrgUser.attributes.connected}
          lastConnected={userOrgUser.attributes.lastConnected}
          size={68}
          bordered
          apiImg={
            userOrgUser.attributes.avatar?.data?.attributes?.image?.data
              ?.attributes?.url
          }
          img={`user${userOrgUser.attributes.genre || "3"}`}
        />
        <div className="f14 text-start">
          <LightDarkText className=" xbold">
            {handleFirstLastName(userOrgUser)}
          </LightDarkText>
          <LightDarkText className={"custom-ticket-font-size bold"}>
            {userOrg.attributes.poste?.data?.attributes?.name ||
              "Non mentionné"}
          </LightDarkText>
        </div>
      </div>{" "}
      {isSupervisor && type === "client" ? (
        <div className="text-start">
          <SocietyStack societes={userOrg.attributes.workIn.data} />
        </div>
      ) : null}
      {isSupervisor && type === "operator" && !userOrg.attributes.archived ? (
        <div className="text-start">
          <SocietyStack
            societes={userOrg.attributes.SocietiesUnderResponsability.data}
          />
        </div>
      ) : null}
    </LightDarkContainer>
  );
};

export default UserSquareCard;
