import moment from "moment";
import { useSelector } from "react-redux";

import LightDarkText from "../../Common/LightDarkText";
import {
  showMultipleDevices,
  showPreferredLanguage,
  showmultipleDepartements,
} from "../../../Utils/utils";
import { generateUserAdditionalFields } from "../usersUtils";

const UserSideBarDetails = ({ operatorSideBar }) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const { languages } = useSelector((state) => state.sharedDataReducer);
  const { ticketHandleLogic, selectedOrganisation, userAdditionalFields } =
    useSelector((state) => state.organisationsReducer);
  const { selectedUser } = useSelector((state) => state.clientsReducer);

  const userDetails = [
    {
      label: "Date de naissance",
      value: selectedUser?.attributes?.birthDate
        ? moment(selectedUser.attributes.birthDate).format("DD.MM.YYYY")
        : "Pas de date de naissance",
      isMail: false,
      isVisible: true,
    },
    {
      label: "Poste",
      value: selectedUser?.attributes?.stringPoste,
      isMail: false,
      isVisible: true,
    },
    {
      label: ticketHandleLogic === 2 ? "Département(s)" : "Compétence(s)",
      value:
        ticketHandleLogic === 2
          ? showmultipleDepartements(
              selectedUser?.attributes?.user_org_permissions?.data[0].attributes
                ?.departements?.data
            )
          : showMultipleDevices(
              selectedUser?.attributes?.user_org_permissions?.data[0].attributes
                ?.prestations?.data
            ),
      isMail: false,
      isVisible: operatorSideBar && selectedUser?.attributes?.roleId === 2,
    },
    {
      label: "Spécialité(s)",
      value: showMultipleDevices(
        selectedUser?.attributes?.user_org_permissions.data[0].attributes
          ?.devices?.data
      ),
      isMail: false,
      isVisible:
        operatorSideBar &&
        ticketHandleLogic !== 2 &&
        selectedUser?.attributes?.roleId === 2,
    },
    {
      label: "E-mail",
      value: selectedUser?.attributes?.email,
      isMail: true,
      isVisible: true,
    },
    {
      label: "Société(s)",
      value: selectedUser?.attributes?.stringSocieties,
      isMail: false,
      isVisible: selectedUser?.attributes?.roleId !== 1,
    },
    {
      label: "Langue préférée",
      value: showPreferredLanguage(selectedUser),
      isVisible: languages.length,
      isMail: false,
    },
    {
      label: "Téléphone",
      value: selectedUser?.attributes?.phoneNumber
        ? selectedUser?.attributes?.phoneNumber
        : "Pas de téléphone",
      isMail: false,
      isVisible: true,
    },
    {
      label: "Adresse",
      value:
        !selectedUser?.attributes?.adress &&
        !selectedUser?.attributes?.city &&
        !selectedUser?.attributes?.zipCode
          ? "Pas d'adresse"
          : `${
              selectedUser?.attributes?.adress
                ? selectedUser?.attributes?.adress
                : ""
            } ${
              selectedUser?.attributes?.city
                ? selectedUser?.attributes?.city
                : ""
            }
            ${
              selectedUser?.attributes?.zipCode
                ? selectedUser?.attributes?.zipCode
                : ""
            }`,
      isMail: false,
      isVisible: true,
    },
    ...generateUserAdditionalFields(
      userAdditionalFields,
      selectedUser.attributes.additionalData,
      selectedOrganisation.id
    ).map((el) => {
      return {
        label: el.label,
        value: el.value,
        isVisible: true,
      };
    }),
  ];

  return (
    <div className="d-flex flex-column gap-3 mt-4">
      {userDetails?.map((information, index) =>
        information?.isVisible ? (
          <div key={index}>
            <LightDarkText
              className="text-start bold custom-ticket-font-size"
              color={mode === "light" ? "text-light-text" : "text-white"}
            >
              {information?.label}
            </LightDarkText>
            <LightDarkText
              className={`text-break text-start bold custom-ticket-font-size
             ${
               !information?.value.includes("Pas de") &&
               information?.isMail &&
               "text-decoration-underline pointer"
             }`}
              color={
                !information?.value.includes("Pas de") && information?.isMail
                  ? "text-light-blue"
                  : mode === "light"
                  ? "text-blue-grey"
                  : "text-light-text"
              }
              onclickCallback={() =>
                information?.isMail &&
                window.open(`mailto:${selectedUser?.attributes.email}`)
              }
            >
              {information?.value}
            </LightDarkText>
          </div>
        ) : null
      )}
    </div>
  );
};

export default UserSideBarDetails;
