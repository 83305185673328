const InterventionDescription = ({ description }) => {
  return (
    <div
      className="custom-ticket-font-size text-blue-grey p-nomb text-start"
      style={{ wordBreak: "break-word" }}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

export default InterventionDescription;
