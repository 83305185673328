import React, { useEffect } from "react";
import { getSocietyLinkedSocieties } from "../../../../REDUX/actions/societe";
import SocietyListNew from "../SocietyListNew";
import { useDispatch, useSelector } from "react-redux";
import EmptyList from "../../../Common/EmptyList";

const SocietyLinkedSocieties = ({ setIsToggle }) => {
  const dispatch = useDispatch();
  const { SocietyLinkedSocieties, societe } = useSelector(
    (state) => state.societesReducer
  );

  useEffect(() => {
    dispatch(getSocietyLinkedSocieties());
  }, [societe]);
  return SocietyLinkedSocieties.length === 0 ? (
    <EmptyList type="societe" showButton={false} />
  ) : (
    <SocietyListNew
      societes={SocietyLinkedSocieties}
      isLinked
      setIsToggle={setIsToggle}
    />
  );
};

export default SocietyLinkedSocieties;
