import React from "react";
import LightDarkText from "../../Common/LightDarkText";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import sharePointLogo from "../../../Images/apiIcons/sharePoint.svg";

const SharePointSiteName = () => {
  const navigate = useNavigate();
  const { oneDriveLoading, sharePointSiteItem } = useSelector(
    (state) => state.externalServicesReducer
  );
  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );
  return (
    <LightDarkText
      className="text-start f20 xbold t-02 alignH gap-3 my-3 pointer"
      onclickCallback={() =>
        navigate(`${organisationUrl}/sharepointsite/${sharePointSiteItem.id}`)
      }
    >
      <div className="" style={{ width: "40px", height: "40px" }}>
        {oneDriveLoading ? (
          <Spinner size="sm" />
        ) : (
          <img src={sharePointLogo} alt={"oneDrive"} />
        )}
      </div>
      {sharePointSiteItem.displayName}
    </LightDarkText>
  );
};

export default SharePointSiteName;
