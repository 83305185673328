import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { closeModal } from "../../../REDUX/actions/modal";
import {
  handleFirstLastName,
  showMultipleSocieties,
} from "../../../Utils/utils";
import LightDarkText from "../../Common/LightDarkText";
import { CloseIcon } from "../../Common/LightDarkSvg";
import UserThumbnail from "../../Common/UserThumbnail";
import { generateUserAdditionalFields } from "../../Clients/usersUtils";

const UserModalInfo = ({ responsible }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { userAdditionalFields, selectedOrganisation } = useSelector(
    (state) => state.organisationsReducer
  );

  return (
    <div
      className={`radius05 py-4 px-4 ${
        mode === "light" ? "white" : "dark-grey-bg"
      } `}
      style={{ width: "600px" }}
    >
      <div className="d-flex justify-content-between">
        <div className="alignH gap-4 ">
          <UserThumbnail
            userId={responsible?.id}
            connected={responsible?.attributes?.connected}
            lastConnected={responsible?.attributes?.lastConnected}
            size={75}
            apiImg={
              responsible?.attributes?.avatar?.data?.attributes?.image?.data
                ?.attributes?.url
            }
            img={`user${responsible?.attributes?.genre || "3"}`}
          />
          <div>
            <LightDarkText className="bolder custom-title-font-size">
              {handleFirstLastName(responsible)}
            </LightDarkText>
            <LightDarkText
              className="bold custom-font-size"
              color={
                responsible.attributes?.roleId === 1
                  ? "text-light-blue"
                  : mode === "light"
                  ? "text-blue-grey"
                  : "text-light-text"
              }
            >
              {responsible.attributes.stringRole}
            </LightDarkText>
          </div>
        </div>{" "}
        <CloseIcon clickCallback={() => dispatch(closeModal())} />
      </div>
      <div className={` alignH justify-content-between mt-5`}>
        <div className={`col-6`}>
          <LightDarkText
            className="bold custom-ticket-font-size"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            Date de naissance
          </LightDarkText>
          <LightDarkText className="bold custom-ticket-font-size">
            {responsible.attributes.birthDate
              ? moment(responsible.attributes.birthDate).format("DD.MM.YYYY")
              : "Pas de date de naissance"}
          </LightDarkText>
        </div>
        <div className={`col-6`}>
          <LightDarkText
            className="bold custom-ticket-font-size"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            Poste
          </LightDarkText>
          <LightDarkText className="bold custom-ticket-font-size">
            {responsible?.attributes?.poste?.data
              ? responsible?.attributes?.poste?.data?.attributes?.name
              : "Pas de poste"}
          </LightDarkText>
        </div>
      </div>
      <div className={` alignH justify-content-between mt-4`}>
        <div className={`col-6`}>
          <LightDarkText
            className="bold custom-ticket-font-size"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            E-mail
          </LightDarkText>
          <LightDarkText className="bold custom-ticket-font-size">
            {responsible.attributes.email}
          </LightDarkText>
        </div>

        <div className={`col-6`}>
          <LightDarkText
            className="bold custom-ticket-font-size"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            Téléphone
          </LightDarkText>
          <LightDarkText className="bold custom-ticket-font-size">
            {responsible.attributes.phoneNumber
              ? responsible.attributes.phoneNumber
              : "Pas de téléphone"}
          </LightDarkText>
        </div>
      </div>
      <div className="alignH justify-content-between mt-4 w-100">
        <div className="col-6">
          <LightDarkText
            className="bold custom-ticket-font-size"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            Adresse
          </LightDarkText>
          <LightDarkText className="bold custom-ticket-font-size">
            {!responsible.attributes.adress &&
            !responsible.attributes.city &&
            !responsible.attributes.zipCode
              ? "Pas d'adresse"
              : `${
                  responsible.attributes.adress
                    ? responsible.attributes.adress
                    : ""
                } ${
                  responsible.attributes.city ? responsible.attributes.city : ""
                }
            ${
              responsible.attributes.zipCode
                ? responsible.attributes.zipCode
                : ""
            }`}
          </LightDarkText>
        </div>

        <div className="col-6">
          <LightDarkText
            className="bold custom-ticket-font-size"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            Liées à
          </LightDarkText>
          <LightDarkText className="bold custom-ticket-font-size">
            {showMultipleSocieties(responsible, "3")}
          </LightDarkText>
        </div>
      </div>
      <div className="alignH justify-content-between  w-100 flex-wrap">
        {generateUserAdditionalFields(
          userAdditionalFields,
          responsible.attributes.additionalData,
          selectedOrganisation.id
        ).map((el, index) => (
          <div className={`col-6 mt-4`} key={index}>
            <LightDarkText
              className="bold custom-ticket-font-size"
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
            >
              {el.name}
            </LightDarkText>
            <LightDarkText className="bold custom-ticket-font-size">
              {el.value}
            </LightDarkText>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserModalInfo;
