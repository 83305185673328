import React, { useState } from "react";
import {
  CardActionsIcon,
  DeleteIcon,
  EditIcon,
  LinkConditionIcon,
} from "../Common/LightDarkSvg";
import SettingTooltip from "../Common/Tooltip/SettingTooltip";
import Tooltipitem from "../Common/Tooltip/Tooltipitem";
import { useDispatch, useSelector } from "react-redux";
import ConditionForm from "./questions/QuestionForm/ConditionForm";
import { openModal } from "../../REDUX/actions/modal";
import {
  deleteQuestion,
  duplicateQuestion,
  updateQuestionPosition,
} from "../../REDUX/actions/formQuestion";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import QuestionForm from "./questions/QuestionForm/QuestionForm";
import { getHighestPosition } from "../../Utils/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../Common/LightDarkText";

const QuestionContainer = ({ children, col, question, isForm }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { selectedCategory } = useSelector(
    (state) => state.formCategoriesReducer
  );
  const [showTooltip, setshowTooltip] = useState(false);

  const tooltipData = [
    {
      id: 1,
      text: "Modifier",
      icon: <EditIcon className="me-2" />,
      action: () => {
        dispatch(openModal({ content: <QuestionForm question={question} /> }));
        setshowTooltip(false);
      },
    },
    {
      id: 2,
      text: "Conditions",
      action: () => {
        dispatch(
          openModal({
            content: (
              <ConditionForm
                question={question.id}
                attr={question.attributes}
              />
            ),
          })
        ),
          setshowTooltip(false);
      },
    },
    {
      id: 3,
      text: "Dupliquer",
      action: () => {
        dispatch(duplicateQuestion(question));
        setshowTooltip(false);
      },
    },
    {
      id: 4,
      text: "Supprimer",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(deleteQuestion(question));
            },
            message: "Êtes-vous sur de supprimer cette question?",
            message2:
              "En confirmant la suppression, la question sera définitivement supprimée.",
            confirmButtonText: "Je confirme la suppression",
          })
        );
        setshowTooltip(false);
      },
    },
  ];
  const handleDown = () => {
    const itemPosition = question?.attributes?.position;
    const pos = getHighestPosition(
      selectedCategory?.attributes?.questions?.data
    );

    if (itemPosition < pos) {
      const nextItem = selectedCategory?.attributes?.questions?.data?.find(
        (el) => el?.attributes?.position === itemPosition + 1
      );
      const newItems = [
        { id: question.id, position: itemPosition + 1 },
        { id: nextItem.id, position: itemPosition },
      ];
      for (const newItem of newItems) {
        dispatch(updateQuestionPosition(newItem.id, newItem.position));
      }
    }
  };
  const handleUp = () => {
    const itemPosition = question?.attributes?.position;
    if (itemPosition > 1) {
      const previousItem = selectedCategory?.attributes?.questions?.data?.find(
        (el) => el?.attributes?.position === itemPosition - 1
      );
      const newItems = [
        { id: question?.id, position: itemPosition - 1 },
        { id: previousItem?.id, position: itemPosition },
      ];
      for (const newItem of newItems) {
        dispatch(updateQuestionPosition(newItem.id, newItem.position));
      }
    }
  };

  return (
    <form className={` col-12 col-lg-${col}  my-2  `}>
      <div
        className={`  ${
          mode === "light" && isForm
            ? "bg-white"
            : mode === "light" && !isForm && "bg-light-gray "
        } 
       
        ${!isForm && "p-3"}
        d-flex flex-row  justify-content-between  align-items-center  radius05 w-100 `}
        style={{
          backgroundColor:
            mode === "dark" && !isForm && "rgba(89, 99, 139, 0.25)",
        }}
      >
        <div className={`${"w90"}  `}>
          <div className="w-100 d-flex flex-row align-items-center ">
            {!isForm && (
              <div className=" d-flex flex-column me-2 ">
                <i
                  className="bi bi-chevron-up f18 pointer"
                  style={{ color: "#A9BCD3" }}
                  onClick={handleUp}
                ></i>
                <i
                  className="bi bi-chevron-down f18 pointer"
                  style={{ color: "#A9BCD3" }}
                  onClick={handleDown}
                ></i>
              </div>
            )}
            <div className={`${isForm ? "w-100" : "w-100"}`}>{children}</div>
          </div>
        </div>
        {question?.attributes?.conditions?.data?.length > 0 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                <LightDarkText
                  className={`custom-ticket-font-size  py-1 text-white`}
                >
                  Lié avec{" "}
                  {
                    question?.attributes?.conditions?.data[0]?.attributes
                      ?.condition?.data?.attributes?.name
                  }
                </LightDarkText>
              </Tooltip>
            }
          >
            <div className="pointer">
              <LinkConditionIcon />
            </div>
          </OverlayTrigger>
        )}
        {!isForm && (
          <div className=" d-flex justify-content-end ">
            <CardActionsIcon clickCallback={() => setshowTooltip(true)} />
            <div
              style={{
                position: "relative",
                left: "-120px",
                top: "25px",
                zIndex: 500,
              }}
            >
              <SettingTooltip
                showTooltip={showTooltip}
                setshowTooltip={setshowTooltip}
              >
                {tooltipData.map(({ id, text, action, icon }, index) => {
                  return (
                    <Tooltipitem
                      key={id}
                      id={id}
                      text={text}
                      icon={icon}
                      action={action}
                    />
                  );
                })}
              </SettingTooltip>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default QuestionContainer;
