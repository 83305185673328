import React from "react";
import { useSelector } from "react-redux";

import "./IconButton.css";

const IconButton = ({
  icon,
  callBack,
  showAlertNotification,
  disabled = false,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <button
      disabled={disabled}
      className={`icon-btn click-animation radius05 border-0 p-0 custom-ticket-font-size xbold t-02 ${
        mode === "light" && showAlertNotification
          ? "light-button-clicked"
          : mode === "light"
          ? "bg-white"
          : "bg-dark-grey-bg"
      }`}
      onClick={callBack}
    >
      {icon}
    </button>
  );
};

export default IconButton;
