import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { creationDate, ticketPriority } from "../ticketUtils";
import LightDarkContainer from "../../Common/LightDarkContainer";
import StatusButton from "../../Common/Buttons/StatusButton";
import { RedMessagepoint } from "../../Common/LightDarkSvg";
import LightDarkText from "../../Common/LightDarkText";
import Thumbnail from "../../Common/Thumbnail";

import TicketTitle from "../TicketCommonComponents/TicketTitle";

const SideChatCard = ({ ticket }) => {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const screenWidth = useWindowWidth();
  const ticketRef = useRef(null);

  moment.locale("fr");

  const { mode } = useSelector((state) => state.genearlReducer);
  const { expandedChatList, openedTicketId } = useSelector(
    (state) => state.ticketsReducer
  );
  const { showPrivateMessages } = useSelector((state) => state.messagesReducer);
  const { ticketRoute } = useSelector((state) => state.organisationsReducer);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (!state?.notification) return;
      ticketRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);

    return () => clearTimeout(timeout);
  }, [state?.notification, ticket, openedTicketId]);

  const [notRead, setNotRead] = useState(
    ticket?.attributes?.notifications?.data?.attributes?.count > 0
  );
  useEffect(() => {
    setNotRead(ticket?.attributes?.notifications?.data?.attributes?.count > 0);
  }, [ticket?.attributes?.notifications?.data?.attributes?.count]);

  const handleClick = () => {
    navigate(`${ticketRoute}/${ticket.id}`);
  };

  return (
    <div ref={+ticket.id === +id ? ticketRef : null}>
      <LightDarkContainer
        id={`chat${ticket.id}`}
        callBack={handleClick}
        className={`t-02 radius05 position-relative t-02 ${
          screenWidth < 992 ? "me-4" : ""
        } ${
          !expandedChatList
            ? "py-3 px-2"
            : screenWidth < 1200
            ? " px-2 py-2"
            : " px-3 py-4"
        }  mb-4 pointer`}
        style={{
          border:
            +ticket.id === +id
              ? showPrivateMessages
                ? "2px solid #ff7f7f"
                : "2px solid #3365ff"
              : " 2px solid transparent",
          boxShadow: +ticket.id === +id && "0px 6px 20px rgba(7, 13, 36, 0.15)",
        }}
        colorLight="white"
        colorDark="dark-grey-bg"
        resolvedTicket={ticket?.attributes?.statut === 2}
        archivedTicket={ticket?.attributes?.archived}
        hover={true}
      >
        {notRead ? (
          <RedMessagepoint
            className="ms-1 mt-1"
            style={{ position: "absolute", left: 0, top: 0 }}
            color="#FF2C2C"
          />
        ) : (
          <RedMessagepoint
            className="ms-1 mt-1"
            style={{ position: "absolute", left: 0, top: 0 }}
            color={`${mode === "light" ? "#F4F6FD" : "#252C45"}`}
          />
        )}
        <div
          className={`row w-100 justify-content-between m-0 ${
            screenWidth < 992 ? "gap-1" : ""
          }`}
        >
          <div
            className={`${
              screenWidth < 992 ? "col-9" : "col-7"
            } d-flex gap-2 p-0
            ${
              expandedChatList
                ? "align-items-center"
                : "justify-content-center w-100"
            }
          `}
          >
            <div>
              <Thumbnail
                rounded
                apiImg={
                  ticket.attributes?.societe?.data?.attributes?.avatar?.data
                    ?.attributes?.image?.data?.attributes?.url
                }
                img="society"
                size={screenWidth < 1200 ? 30 : 40}
              />
            </div>

            {expandedChatList && (
              <div className=" directionC overflow-hidden align-items-start">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      <LightDarkText className="custom-ticket-font-size py-1 text-white">
                        {ticket.attributes.societe.data?.attributes.name}
                      </LightDarkText>
                    </Tooltip>
                  }
                >
                  <div className="col-12">
                    <LightDarkText
                      className={`custom-font-size bolder text-start text-ellipsis`}
                    >
                      {ticket.attributes.societe.data?.attributes.name}
                    </LightDarkText>
                  </div>
                </OverlayTrigger>
                <LightDarkText
                  className={`custom-ticket-font-size text-start ${
                    notRead && "xbold"
                  }`}
                  color="text-light-text"
                >
                  {creationDate(ticket)}
                </LightDarkText>
              </div>
            )}
          </div>
          {expandedChatList && screenWidth > 992 && (
            <div
              className={`${
                screenWidth < 992 ? "col-12 mt-1" : "col-5"
              } p-0 d-flex justify-content-end `}
            >
              <StatusButton
                height={screenWidth < 1200 ? "30px" : "40px"}
                type={ticketPriority(ticket.attributes.priority).color}
                text={
                  screenWidth < 1200
                    ? ticketPriority(ticket.attributes.priority).icon
                    : ticketPriority(ticket.attributes.priority).text
                }
                background={ticketPriority(ticket.attributes.priority).bg}
                ticketButton={true}
              />
            </div>
          )}
        </div>
        {expandedChatList && (
          <TicketTitle
            id={ticket?.id}
            title={ticket?.attributes?.name}
            textsize="custom-ticket-font-size"
            className="mt-3 pt-2"
            notRead={notRead}
          />
        )}
      </LightDarkContainer>
    </div>
  );
};

export default SideChatCard;
