import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../REDUX/actions/modal";
import { shareFile } from "../../REDUX/actions/cloud";
import LightDarkContainer from "../Common/LightDarkContainer";
import LightDarkText from "../Common/LightDarkText";
import ColoredButton from "../Common/Buttons/ColoredButton";
import LighDarkMultiChoice from "../Common/LighDarkMultiChoice";
import { CloseIcon } from "../Common/LightDarkSvg";
import {
  handleFirstLastName,
  showMultipleSocieties,
  showPoste,
  showRoleNameByUser,
} from "../../Utils/utils";

import UserSmallCard from "../Clients/UserSmallCard";

const ShareFile = ({ fileId, fileSharedUsers, fileFolder, inFolder }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { canShareTo } = useSelector((state) => state.sharedDataReducer);
  const [sharedUsers, setSharedUsers] = useState({
    sharedUsers:
      fileSharedUsers?.data?.map((el) => {
        const userRole =
          +el?.attributes?.user_org_permissions?.data[0]?.attributes?.role;
        if (userRole === 1 || userRole === 2) {
          return {
            value: el?.id,
            label: `${handleFirstLastName(el)} - ${
              el?.attributes?.user_org_permissions?.data[0]?.attributes
                ?.organisation?.data?.attributes?.name
            }`,
            role: showRoleNameByUser(el.attributes),
            avatar:
              el?.attributes?.avatar?.data?.attributes?.image?.data?.attributes
                ?.url,
            poste: showPoste(el.attributes),
          };
        } else {
          return {
            value: el?.id,
            label: `${handleFirstLastName(el)} - ${showMultipleSocieties(el)}`,
            role: showRoleNameByUser(el.attributes),
            avatar:
              el?.attributes?.avatar?.data?.attributes?.image?.data?.attributes
                ?.url,
            poste: showPoste(el.attributes),
          };
        }
      }) ?? [],
  });
  const handleSubmit = () => {
    dispatch(
      shareFile(
        fileId,
        sharedUsers.sharedUsers.map((user) => user.value),

        fileFolder?.id
      )
    );
    dispatch(closeModal());
  };

  return (
    <LightDarkContainer
      className="p-4 radius05 directionC"
      colorLight="white"
      colorDark="dark-mode"
    >
      <div
        className="d-flex justify-content-end mb-2"
        onClick={() => dispatch(closeModal())}
      >
        <CloseIcon />
      </div>
      <LightDarkText className="text-center custom-title-font-size bold mb-4">
        Partager le fichier
      </LightDarkText>
      <div onSubmit={(e) => e.preventDefault()}>
        <LighDarkMultiChoice
          label="Partager avec"
          data={
            !inFolder
              ? canShareTo.filter((el) => el.value !== user.id)
              : canShareTo.filter(
                  (el) =>
                    el.value !== user.id &&
                    fileFolder?.attributes?.sharedUsers?.data?.every(
                      (user) => user.id !== el.value
                    )
                )
          }
          totalInputs={sharedUsers}
          setValue={setSharedUsers}
          champs="sharedUsers"
          placeholder="Sélectionner un ou plusieurs utilisateur(s)"
        />
      </div>
      {sharedUsers?.sharedUsers?.length !== 0 && (
        <div
          className={`my-3 radius05 px-3 sideChatListContainer overflow-y-auto ${
            mode === "light" ? "bg-light-mode" : "bg-dark-message"
          }`}
          style={{
            height: `${
              sharedUsers?.sharedUsers?.length <= 2
                ? "100%"
                : sharedUsers?.sharedUsers?.length == 3
                ? "160px"
                : "200px"
            }`,
          }}
        >
          {sharedUsers?.sharedUsers?.map((el, index) => (
            <UserSmallCard
              key={index}
              responsible={el}
              // fileId={fileId}
              // sharedUsers={sharedUsers}
              // inFileListUser={true}
            />
          ))}
        </div>
      )}
      <div className="col-6 align-self-center mb-3 mt-5">
        <ColoredButton
          textButton="Enregister les modifications"
          // disabled={!sharedUsers.sharedUsers?.length > 0}
          color="light-blue"
          callback={handleSubmit}
        />
      </div>
    </LightDarkContainer>
  );
};

export default ShareFile;
