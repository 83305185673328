import React, { useEffect, useState } from "react";
import "./MessagesReacts.css";
import { HappyEmoji, StarEmoji } from "../../../../../../Common/LightDarkSvg";
import EmojiPicker from "emoji-picker-react";
import {
  emojisCategories,
  hiddenEmojis,
} from "../../../../../../Common/TextEditor/textEditorUtils";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavoriteMessage,
  addReact,
  removeFavoriteMessage,
} from "../../../../../../../REDUX/actions/messages";
import { groupEmojisByUrl } from "../../../../messageUtils";
import ReactElement from "./ReactElement";

const MessagesReacts = ({ message, meSending }) => {
  const dispatch = useDispatch();
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { user } = useSelector((state) => state.userReducer);
  const [openPicker, setOpenPicker] = useState(false);
  const [reacts, setreacts] = useState(
    groupEmojisByUrl(message.attributes.reacts.data)
  );
  const [isFavorite, setIsFavorite] = useState(
    message.attributes.message_favorites.data.length > 0
  );

  useEffect(() => {
    setreacts(groupEmojisByUrl(message.attributes.reacts.data));
    setIsFavorite(message.attributes.message_favorites.data.length > 0);
  }, [message.attributes.reacts.data]);

  const handleReaction = (e) => {
    setOpenPicker(false);
    dispatch(
      addReact({
        name: `${message.id} - ${user.attributes.firstName} ${user.attributes.lastName}`,
        url: e.imageUrl,
        message: message.id,
        user: user.id,
        emojiKey: e.unified,
      })
    );
  };
  const handleFavoriteMessage = () => {
    if (isFavorite) {
      dispatch(
        removeFavoriteMessage(
          message.attributes.message_favorites.data[0]?.id,
          message.id
        )
      );
    } else {
      dispatch(
        addFavoriteMessage({
          name: `${message.id} - ${user.attributes.firstName} ${user.attributes.lastName}`,
          message: message.id,
          user: user.id,
          ticket: ticket.id,
        })
      );
    }
  };
  return (
    <div
      className={`messagesReacts d-flex align-items-center gap-1 justify-content-end  ${
        meSending ? "flex-row-reverse ms-m1" : ""
      }`}
    >
      <OutsideClickHandler onOutsideClick={() => setOpenPicker(false)}>
        <EmojiPicker
          open={openPicker}
          height={360}
          className="message-react-picker-container"
          reactionsDefaultOpen={true}
          onReactionClick={handleReaction}
          onEmojiClick={handleReaction}
          searchPlaceholder="Recherche"
          categories={emojisCategories}
          hiddenEmojis={hiddenEmojis}
          previewConfig={{
            showPreview: false,
          }}
          reactions={[
            "1f44d",
            "1f44e",
            "1f64f",
            "2764-fe0f",
            "1f602",
            "2705",
            "274c",
          ]}
        />
      </OutsideClickHandler>
      <div className="reactList d-flex alignH gap-2">
        {reacts.map((emo, index) => (
          <ReactElement emo={emo} key={index} messageId={message.id} />
        ))}
      </div>
      <div className="messageReactElt radius05 bg-white alignC pointer">
        <HappyEmoji callBack={() => setOpenPicker(!openPicker)} />
      </div>
      <div
        className={`messageReactElt favoriteSvg radius05 bg-white alignC pointer ${
          isFavorite ? "favoriteMessage" : ""
        }`}
      >
        <StarEmoji
          callBack={handleFavoriteMessage}
          fill={isFavorite ? "#f7d000" : null}
          stroke={isFavorite ? "#f7d000" : "#414A6E"}
        />
      </div>
    </div>
  );
};

export default MessagesReacts;
