import React from "react";

const MessagesIcon = ({ fill = "#a9bcd3", width = "24", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.3241 29.4525L33.0066 34.9825C33.1816 36.435 31.6241 37.45 30.3816 36.6975L24.3266 33.0925C23.9066 32.8475 23.8016 32.3225 24.0291 31.9025C24.9041 30.2925 25.3766 28.4725 25.3766 26.6525C25.3766 20.2475 19.8816 15.0325 13.1266 15.0325C11.7441 15.0325 10.3966 15.2425 9.13665 15.6625C8.48915 15.8725 7.85915 15.2775 8.01665 14.6125C9.60915 8.2425 15.7341 3.5 23.0491 3.5C31.5891 3.5 38.5016 9.9575 38.5016 17.92C38.5016 22.645 36.0691 26.8275 32.3241 29.4525Z"
        fill={fill}
      />
      <path
        d="M22.75 26.6512C22.75 28.7337 21.98 30.6587 20.685 32.1812C18.9525 34.2812 16.205 35.6288 13.125 35.6288L8.5575 38.3412C7.7875 38.8137 6.8075 38.1662 6.9125 37.2737L7.35 33.8262C5.005 32.1987 3.5 29.5912 3.5 26.6512C3.5 23.5712 5.145 20.8587 7.665 19.2487C9.2225 18.2337 11.095 17.6562 13.125 17.6562C18.445 17.6562 22.75 21.6812 22.75 26.6512Z"
        fill={fill}
      />
    </svg>
  );
};

export default MessagesIcon;
