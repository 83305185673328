import React from "react";
import OrganisationItem from "./OrganisationItem";
import { useSelector } from "react-redux";
import OrganisationLoading from "./OrganisationLoading";

const OrganisationsList = () => {
  const { organisations, organisationLoading } = useSelector(
    (state) => state.organisationsReducer
  );

  return organisationLoading ? (
    <OrganisationLoading />
  ) : (
    <div className="organisationList scrollable mt-3 border-bottom border-top">
      {organisations.map((organisation, index) => (
        <OrganisationItem organisation={organisation} key={index} />
      ))}
    </div>
  );
};

export default OrganisationsList;
