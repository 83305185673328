import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LightDarkText from "../../Common/LightDarkText";
import { Spinner } from "react-bootstrap";
import sharePointLogo from "../../../Images/apiIcons/sharePoint.svg";
import SharePointSiteCard from "./SharePointSiteCard";

const SharePointSiteList = ({ sharePointSites, search }) => {
  const { oneDriveLoading } = useSelector(
    (state) => state.externalServicesReducer
  );

  const [filteredSites, setFilteredSites] = useState(sharePointSites);
  useEffect(() => {
    setFilteredSites(
      sharePointSites.filter((el) =>
        el.name?.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, sharePointSites]);
  return (
    <div className="mt-5 position-relative">
      <LightDarkText className="custom-title-font-size mt-5  bold text-start alignH gap-2">
        <div
          className="d-flex gap-3 alignC"
          style={{ width: "20px", height: "30px" }}
        >
          {oneDriveLoading ? (
            <Spinner size="sm" />
          ) : (
            <img src={sharePointLogo} alt={"oneDrive"} />
          )}
        </div>
        Sites SharePoint
      </LightDarkText>

      <div className="d-flex flex-wrap gap-4 ps-1 pt-4">
        {filteredSites.map((site) => (
          <SharePointSiteCard key={site.id} site={site} />
        ))}
      </div>
    </div>
  );
};

export default SharePointSiteList;
