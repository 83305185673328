import React from "react";
import { useSelector } from "react-redux";

import LightDarkText from "../../LightDarkText";
import { RedMessagepoint } from "../../LightDarkSvg";

const ElementId = ({ id, notRead = false }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="alignH gap-1">
      {notRead ? (
        <RedMessagepoint color="#FF2C2C" />
      ) : (
        <RedMessagepoint
          color={`${mode === "light" ? "#F4F6FD" : "#252C45"}`}
        />
      )}
      <LightDarkText
        className={`custom-ticket-font-size text-start ${
          notRead ? "xbold" : "bold"
        }`}
        color={mode === "light" ? "text-blue-grey" : "text-light-text"}
      >
        #{id}
      </LightDarkText>
    </div>
  );
};

export default ElementId;
