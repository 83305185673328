import React from "react";
import LightDarkPlaceHolder from "./LightDarkPlaceHolder";
import ThumbnailPlaceHolder from "./ThumbnailPlaceHolder";

const MessagePlaceHolder = ({ index }) => {
  return (
    <div
      className={`t-02 my-4 d-flex align-items-end  gap-4 ${
        index % 2 ? "flex-row" : "flex-row-reverse"
      }`}
    >
      <div className="flex-1">
        <LightDarkPlaceHolder customClass={"messageBodPlaceHolder"} />
      </div>
      <ThumbnailPlaceHolder smallThumbnail={true} />
    </div>
  );
};

export default MessagePlaceHolder;
