import React from "react";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkText from "../../Common/LightDarkText";

import { formatLinks, imageLoader } from "../../../Utils/utils";

const InfoLabel = ({ label, value, isLink = false, icon }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="d-flex align-items-center w-100 gap-1">
      <LightDarkText
        className="f12 text-start bold wContent"
        color={mode === "light" ? "text-blue-grey" : "text-white"}
      >
        {label}
      </LightDarkText>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            <LightDarkText className="f12 text-start text-white">
              {value}
            </LightDarkText>
          </Tooltip>
        }
      >
        <div style={{ maxWidth: "100%", overflow: "hidden" }}>
          <LightDarkText
            className={`f12 text-start bold text-ellipsis ${
              isLink && "pointer clickable-text t-02 text-decoration-underline"
            }`}
            color={mode === "light" ? "text-dark-blue" : "text-light-text"}
            onclickCallback={() => isLink && window.open(`//${value}`)}
          >
            {icon && <img className="me-1" src={imageLoader(icon)} />}
            {isLink ? formatLinks(value) : value}
          </LightDarkText>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default InfoLabel;
