import { OverlayTrigger, Tooltip } from "react-bootstrap";

import JSZip from "jszip";
import { saveAs } from "file-saver";

import { imageEndPoint } from "../../../../Api/endPoint";

import { useDispatch, useSelector } from "react-redux";

import LightDarkContainer from "../../../Common/LightDarkContainer";
import LightDarkText from "../../../Common/LightDarkText";
import FilesCarousel from "../../../Common/FilesCarousel";
import { Download, EyeIcon } from "../../../Common/LightDarkSvg";

import { openModal } from "../../../../REDUX/actions/modal";

import { donwloadFile } from "../../../../Utils/utils";
import AttachmentsList from "../../../Common/AttachmentsList";

const EventRelatedFiles = ({ files, socialMediaFiles }) => {
  const dispatch = useDispatch();

  const { mode } = useSelector((state) => state.genearlReducer);

  const downloadFilesAsZip = async (files) => {
    const zip = new JSZip();

    for (const file of files) {
      const fileUrl =
        imageEndPoint + file?.attributes?.file?.data?.attributes?.url;
      const fileName = file?.attributes?.file?.data?.attributes?.name;

      const response = await fetch(fileUrl);
      if (response.ok) {
        const fileData = await response.blob();
        zip.file(fileName, fileData);
      }
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });

    saveAs(zipBlob);
  };

  return (
    <>
      <div className="mt-4 d-flex align-items-center justify-content-between">
        <LightDarkText className=" f12 text-start bold" color="text-light-text">
          {socialMediaFiles ? socialMediaFiles : "Fichiers"} ({files?.length})
        </LightDarkText>
        <LightDarkText
          className=" f12 text-start bold clickable-text pointer"
          color="text-light-text"
          onclickCallback={() => downloadFilesAsZip(files)}
        >
          Tout télécharger
        </LightDarkText>
      </div>
      <AttachmentsList files={files} eventAttachedFiles />
    </>
  );
};

export default EventRelatedFiles;
