import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkText from "../../../../Common/LightDarkText";
import { ClockIcon } from "../../../../Common/LightDarkSvg";

import { handleDuration } from "../../../ticketUtils";

const InterventionDuration = ({ duration }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return duration > 0 ? (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          <LightDarkText className="custom-ticket-font-size py-1 text-white">
            Durée
          </LightDarkText>
        </Tooltip>
      }
    >
      <div className="alignH gap-2 custom-ticket-font-size text-start">
        <LightDarkText
          className={"position-relative customtooltip pointer"}
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          <ClockIcon darkColor="#59638b" />
        </LightDarkText>
        <LightDarkText
          className="regular"
          color={mode === "light" ? "text-blue-grey" : "text-white"}
        >
          {handleDuration(duration || 0)}
        </LightDarkText>
      </div>
    </OverlayTrigger>
  ) : (
    <LightDarkText
      className={"position-relative custom-ticket-font-size text-start"}
      color={mode === "light" ? "text-blue-grey" : "text-white"}
    >
      🤝 Inclus
    </LightDarkText>
  );
};

export default InterventionDuration;
