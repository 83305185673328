import { useSelector } from "react-redux";

import LightDarkText from "../../../Common/LightDarkText";
import { Calendar, GlobalIcon } from "../../../Common/LightDarkSvg";

import useWindowWidth from "../../../../Hooks/useWindowWidth";

export const EventTitle = ({
  title,
  type,
  eventMainCard,
  eventsList,
  eventsideBar,
  eventDescription,
}) => {
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <>
      {!eventMainCard && !eventsList && !eventsideBar && (
        <div
          className={`py-2 px-2 radius05 mb-3`}
          style={{
            backgroundColor: `${mode === "light" ? "#35407425" : "#A9BCD325"}`,
            width: "fit-content",
          }}
        >
          <LightDarkText
            className="d-flex align-items-center text-start bold f12"
            color={mode === "light" ? "text-dark-blue" : "text-light-text"}
          >
            {type === "Marketing" ? (
              <GlobalIcon small dark className="me-1" />
            ) : (
              <Calendar eventCard="#354074" className="me-1" />
            )}

            {type}
          </LightDarkText>
        </div>
      )}
      <LightDarkText
        className={`bold text-start ${
          eventMainCard || eventsList
            ? `text-white ${screenWidth > 1200 ? "f14" : "f12"} `
            : `${
                screenWidth > 1200 && !eventsideBar
                  ? "f18"
                  : screenWidth > 1200 && eventsideBar
                  ? "f24 mt-4"
                  : eventsideBar
                  ? "f18 mt-4"
                  : "f14"
              }`
        } ${!eventDescription && !eventMainCard && !eventsList && "mb-3"} ${
          eventMainCard && "text-ellipsis"
        }`}
        style={
          eventMainCard && {
            height: "20px",
            textWrap: "wrap",
            whiteSpace: "normal",
          }
        }
        lightModeText={!eventMainCard && !eventsList}
        darkModeText
        color={
          eventMainCard && eventsList
            ? "text-white"
            : mode === "light" && !eventMainCard && !eventsList
            ? "text-dark-blue"
            : !eventMainCard && !eventsList && "text-light-text"
        }
      >
        {title}
      </LightDarkText>
    </>
  );
};

export default EventTitle;
