import React from "react";
import LightDarkText from "../../../../Common/LightDarkText";
import { handleQuestionType } from "./interviewUtils";
import LightDarkContainer from "../../../../Common/LightDarkContainer";

const InterviewQuestionInfo = ({ question }) => {
  return (
    <LightDarkContainer className="f12 p-3 radius10">
      <LightDarkText className={"f14 bold"} color={"text-light-blue xbold"}>
        {`${question.attributes.order} - ${question.attributes.title}`}
      </LightDarkText>
      <LightDarkText>
        <span className="bold ms-4">Type :</span> {handleQuestionType(question)}
      </LightDarkText>
      {question.attributes.options.data.length ? (
        <LightDarkText className={"bold ms-4"}>
          Options :
          {question.attributes.options.data.map((option, index) => (
            <div className="ms-4" key={option.id}>
              {index + 1} - {option.attributes.value}
            </div>
          ))}
        </LightDarkText>
      ) : null}
      <LightDarkText>
        <span className="bold ms-4">Obligatoire :</span>{" "}
        {question.attributes.required ? "Oui" : "Non"}
      </LightDarkText>
      {question.attributes.conditions.data.length ? (
        <LightDarkText>
          <span className="bold ms-4">Conditions affiché si :</span>{" "}
          {question.attributes.conditions.data.map((condition) => (
            <div className="ms-4" key={condition.id}>
              <span className="text-light-blue xbold">
                {condition.attributes.compareTo.data.attributes.order} -
              </span>
              <span>
                {" "}
                {condition.attributes.compareTo.data.attributes.title}
              </span>{" "}
              <span className="xbold">
                {condition.attributes.compareOperator.data.attributes.label}
              </span>{" "}
              <span>{condition.attributes.comparedValue}</span>
            </div>
          ))}
        </LightDarkText>
      ) : (
        <LightDarkText>
          <span className="bold ms-4">Sans condition</span>
        </LightDarkText>
      )}
      {question.attributes.interview_regex ? (
        <LightDarkText>
          <span className="bold ms-4">Valeurs acceptés :</span>{" "}
          {question.attributes.interview_regex.data.attributes.label}
        </LightDarkText>
      ) : null}
    </LightDarkContainer>
  );
};

export default InterviewQuestionInfo;
