import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateInvoice } from "../../REDUX/actions/facture";
import { closeModal } from "../../REDUX/actions/modal";
import { clearTempUpload } from "../../REDUX/actions/files";
import AttachementUploader from "../Common/AttachementUploader";
import ColoredButton from "../Common/Buttons/ColoredButton";
import LightDarkInput from "../Common/LightDarkInput";
import LighDarkMultiChoice from "../Common/LighDarkMultiChoice";
import LightDarkText from "../Common/LightDarkText";
import { CloseIcon } from "../Common/LightDarkSvg";
import useWindowWidth from "../../Hooks/useWindowWidth";

const FactureForm = () => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const { mode } = useSelector((state) => state.genearlReducer);
  const { societes, invoiceTypesValues, TVARate } = useSelector(
    (state) => state.sharedDataReducer
  );

  const { temporarySelected } = useSelector((state) => state.filesReducer);

  const [invoiceInputs, setInvoiceInputs] = useState({
    number: null,
    type: [],
    date: "",
    societe: societes.length === 1 ? societes : [],
    HT: 300,
    TVA: [{ value: 1, label: "20" }],
    TTC: 360,
  });

  const TVAValue = (invoiceInputs["HT"] * invoiceInputs["TVA"][0]?.label) / 100;

  const TTCValue = Number(invoiceInputs["HT"]) + Number(TVAValue);

  const handleSubmitInvoice = () => {
    dispatch(
      CreateInvoice({
        ...invoiceInputs,
        societe: invoiceInputs.societe[0]?.value,
        type: invoiceInputs.type[0]?.value === 2 ? "Avoir" : "Facture",
        TVA: invoiceInputs.TVA[0]?.label,
        TTC: TTCValue,
      })
    );
    dispatch(closeModal());
  };
  const handleCancelInvoice = () => {
    dispatch(clearTempUpload());
    dispatch(closeModal());
  };

  return (
    <div
      className={`${
        mode === "light" ? "bg-white" : "bg-dark-mode"
      } p-4 radius05 sideChatListContainer`}
      style={{
        height: "690px",
        width: `${screenWidth > 600 ? "600px" : "calc(100vw - 50px)"}`,
      }}
    >
      <div className="d-flex justify-content-between">
        <LightDarkText
          className="text-center custom-title-font-size bold w-100 mt-3"
          lightModeText={mode === "light"}
          darkModeText={mode === "light"}
        >
          Importer une facture
        </LightDarkText>
        <div className="justify-self-end" onClick={() => handleCancelInvoice()}>
          <CloseIcon />
        </div>
      </div>
      <div className="px-2" onSubmit={(e) => e.preventDefault()}>
        <div className="mt-5">
          <div className="row">
            <div className={`${screenWidth < 600 ? "col-12" : "col-6"}`}>
              <LightDarkInput
                label={"Numéro de facture"}
                placeholder="#1939040"
                totalInputs={invoiceInputs}
                type="number"
                setValue={setInvoiceInputs}
                champs="number"
                required
              />
            </div>
            <div className={`${screenWidth < 600 ? "col-12 mt-4" : "col-6"}`}>
              <LighDarkMultiChoice
                label="Type"
                data={invoiceTypesValues ?? []}
                totalInputs={invoiceInputs}
                setValue={setInvoiceInputs}
                champs="type"
                multiple={false}
                required
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className={`${screenWidth < 600 ? "col-12" : "col-6"}`}>
              <LighDarkMultiChoice
                label="Société"
                data={societes}
                totalInputs={invoiceInputs}
                setValue={setInvoiceInputs}
                multiple={false}
                champs="societe"
                placeholder="Séléctionner une société"
                required
                societySelectInput
              />
            </div>
            <div className={`${screenWidth < 600 ? "col-12 mt-4" : "col-6"}`}>
              <LightDarkInput
                label={"Date"}
                type="date"
                placeholder="01.01.2022"
                totalInputs={invoiceInputs}
                setValue={setInvoiceInputs}
                champs="date"
                required
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className={`${screenWidth < 600 ? "col-12 mt-1" : "col-6"}`}>
              <LightDarkInput
                label={"Montant hors taxe"}
                placeholder="300€"
                totalInputs={invoiceInputs}
                type="number"
                setValue={setInvoiceInputs}
                champs="HT"
                required
              />
            </div>
            <div className={`${screenWidth < 600 ? "col-12 mt-4" : "col-6"}`}>
              <LighDarkMultiChoice
                label="TVA"
                data={TVARate}
                totalInputs={invoiceInputs}
                setValue={setInvoiceInputs}
                multiple={false}
                champs="TVA"
                placeholder="20%"
                required
              />
            </div>
          </div>
          <div className="row mt-4">
            <div
              className="d-flex align-items-center"
              style={{ cursor: "default" }}
            >
              <LightDarkText
                className={"text-start bold custom-ticket-font-size"}
                darkModeText={mode === "light"}
              >
                Montant TTC:
              </LightDarkText>
              <LightDarkText
                className={`custom-ticket-font-size ms-2`}
                lightModeText
                darkModeText
              >
                {TTCValue ? `${TTCValue}€` : "360€"}
              </LightDarkText>
            </div>
          </div>
          <div className="row mt-4">
            <div className="">
              <AttachementUploader
                label={"Importation de la facture"}
                required
                multiple={false}
              />
            </div>
          </div>
          <div className="row mt-5 mb-4">
            <LightDarkText
              className={"text-start bold custom-ticket-font-size mb-2"}
              lightModeText={mode === "light"}
              darkModeText={mode === "light"}
            >
              <span className="text-red">*</span>Champs obligatoires
            </LightDarkText>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-3 p-0">
              <ColoredButton
                textButton={"Annuler"}
                outlined
                color="blue-grey"
                callback={() => handleCancelInvoice()}
              />
            </div>
            <div className="col-5 p-0">
              <ColoredButton
                disabled={
                  !invoiceInputs.number ||
                  invoiceInputs.societe.length === 0 ||
                  !invoiceInputs.type ||
                  !invoiceInputs.date ||
                  !invoiceInputs.HT ||
                  invoiceInputs.TVA.length === 0 ||
                  temporarySelected?.length === 0
                }
                textButton={"Importer la facture"}
                color="light-blue"
                callback={() => handleSubmitInvoice()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactureForm;
