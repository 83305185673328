import React, { useEffect } from "react";
import Simulateur from "../SimulateurView/Simulateur";
import IconButton from "../../Components/Common/Buttons/IconButton";
import { ArrowBackIcon } from "../../Components/Common/LightDarkSvg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GetAllQuestions } from "../../REDUX/actions/formQuestion";

const ProspectForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(GetAllQuestions());
  }, []);
  return (
    <div>
      <div className="mb-2 d-flex">
        <IconButton
          icon={<ArrowBackIcon />}
          callBack={() => {
            navigate(-1);
          }}
        />
      </div>
      <Simulateur isForm={true} />
    </div>
  );
};

export default ProspectForm;
