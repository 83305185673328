const initialState = {
  calendars: [],
  selectedCalendars: [],
  calendarLoading: false,
  eventLoading: false,
  sharedEvents: [],
  selectedEvent: null,
  ticketEvent: null,
  eventCardWidth: null,
  position: { x: 0, y: 0 },
  calendarRef: null,
  calendarSelectLoader: [],
  currentView: "timeGridWeek",
};

const calendarsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "CALENDARS_LOADING":
      return { ...state, calendarLoading: true };

    case "FETCH_CALENDARS":
      return {
        ...state,
        calendars: payload,
        selectedCalendars: [payload[0]],
        calendarLoading: false,
      };

    case "FETCH_SHARED_EVENTS":
      return {
        ...state,
        sharedEvents: payload,
      };

    case "FETCH_NEW_SHARED_EVENT":
      return {
        ...state,
        sharedEvents: state.sharedEvents?.find((el) => +el?.id === +payload?.id)
          ? state.sharedEvents
          : [...state?.sharedEvents, payload],
      };

    case "FETCH_UPDATE_SHARED_EVENT":
      return {
        ...state,
        sharedEvents: state.sharedEvents.map((event) =>
          +event.id === +payload.id ? payload : event
        ),
      };

    case "FETCH_NEW_CALENDAR":
      return {
        ...state,
        calendars: [payload, ...state.calendars],
      };
    case "CALENDAR_SELECT_LOADING":
      return {
        ...state,
        calendarSelectLoader: [...state.calendarSelectLoader, payload],
      };
    case "SELECT_CALENDARS":
      return {
        ...state,
        calendarSelectLoader: state.calendarSelectLoader.filter(
          (el) => el === payload
        ),
        selectedCalendars: state.selectedCalendars?.find(
          (el) => el?.id == payload?.id
        )
          ? state.selectedCalendars?.filter((el) => el?.id !== payload?.id)
          : [...state?.selectedCalendars, payload],
      };

    case "CREATE_CALENDAR":
      return {
        ...state,
        calendars: [payload, ...state.calendars],
        calendarLoading: false,
      };

    case "UPDATE_CALENDAR":
      return {
        ...state,
        calendars: state.calendars.map((calendar) =>
          calendar.id === payload.id ? payload : calendar
        ),
        selectedCalendars: state.selectedCalendars.map((calendar) =>
          calendar.id === payload.id ? payload : calendar
        ),
        calendarLoading: false,
      };

    case "DELETE_CALENDAR":
      return {
        ...state,
        calendars: state.calendars.filter((item) => item.id !== payload),
        selectedCalendars: state.selectedCalendars.filter(
          (item) => item.id !== payload
        ),
        calendarLoading: false,
      };

    case "GET_CALENDAR_REF":
      return {
        ...state,
        calendarRef: payload,
      };

    case "CHANGE_CALENDAR_VIEW":
      localStorage.setItem("calendarView", payload);
      return {
        ...state,
        currentView: payload,
      };
    case "EVENT_LOADING":
      return {
        ...state,
        eventLoading: true,
      };
    case "GET_TICKET_EVENT": {
      return {
        ...state,
        ticketEvent: payload,
        eventLoading: false,
      };
    }

    case "SET_EVENT_POSITION":
      return {
        ...state,
        position: payload.position,
        selectedEvent: payload.event,
        eventCardWidth: payload.eventCardWidth,
      };
    default:
      return state;
  }
};
export default calendarsReducer;
