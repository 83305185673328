import React from "react";
import LightDarkText from "../Common/LightDarkText";
import { useDispatch, useSelector } from "react-redux";
import ColoredButton from "../Common/Buttons/ColoredButton";
import ProspectItem from "./ProspectItem";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { useNavigate } from "react-router-dom";
import { openModal } from "../../REDUX/actions/modal";
import NoteForm from "./NoteForm";

const InformationComponent = ({ tableListData, choosenItem, extraInfo }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { prospetsRoute, devisRoute } = useSelector(
    (state) => state.organisationsReducer
  );

  const screenWidth = useWindowWidth();
  const navigate = useNavigate();
  const archiverItem = () => {
    const itemType = ["DRAFT", "DRAFT_COMMERCIAL", "DRAFT_SELLER"].includes(
      choosenItem?.status
    )
      ? "prospect"
      : "devis";

    dispatch(
      openModal({
        type: "small",
        content: (
          <NoteForm
            id={choosenItem.id}
            fullName={choosenItem?.firstname + " " + choosenItem?.lastname}
            oldNote={choosenItem?.note}
            type={itemType}
            isArchive={true}
          />
        ),
      })
    );
  };

  return (
    <div className="d-flex flex-column w-100 h-100 ">
      <div
        className="px-3 showScroll"
        style={{
          height: ["KO_DRAFT", "KO_NEW"].includes(choosenItem?.status)
            ? "100%"
            : "80%",
          paddingBottom: "20px",
          marginTop: "10px",
          overflow: "auto",
        }}
      >
        {tableListData.map(
          (column, index) =>
            column.accessor &&
            choosenItem[column?.accessor] && (
              <div key={index} className="mt-3">
                <LightDarkText
                  className={`text-start bold  py-1 ${
                    screenWidth <= 1400 ? "f10" : "custom-ticket-font-size"
                  }`}
                  color={mode === "light" ? "text-light-text" : "text-white"}
                >
                  {column?.name}
                </LightDarkText>
                <ProspectItem
                  dataItem={choosenItem}
                  accessor={column?.accessor}
                  table={false}
                />
              </div>
            )
        )}
        {choosenItem?.phone && (
          <div className="mt-3 ">
            <LightDarkText
              className={`text-start bold py-1 ${
                screenWidth <= 1400 ? "f10" : "custom-ticket-font-size"
              }`}
              color={mode === "light" ? "text-light-text" : "text-white"}
            >
              Téléphone
            </LightDarkText>
            <LightDarkText
              className={`pointer  custom-ticket-font-size bold text-start text-wrap `}
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
            >
              {choosenItem?.phone}
            </LightDarkText>
          </div>
        )}
        {extraInfo?.map(({ id, question, answer, link }) => {
          return (
            <div key={id} className="mt-3 ">
              <LightDarkText
                className={`text-start bold py-1 ${
                  screenWidth <= 1400 ? "f10" : "custom-ticket-font-size"
                }`}
                color={mode === "light" ? "text-light-text" : "text-white"}
              >
                {question}
              </LightDarkText>
              <LightDarkText
                className={`  custom-ticket-font-size bold text-start text-wrap `}
                color={mode === "light" ? "text-blue-grey" : "text-light-text"}
              >
                {link ? (
                  <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="text-oneDriveBlue text-decoration-underline"
                  >
                    {answer}
                  </a>
                ) : (
                  answer
                )}
              </LightDarkText>
            </div>
          );
        })}
      </div>
      {!["KO_DRAFT", "KO_NEW"].includes(choosenItem?.status) && (
        <div className="h20 w-100 d-flex flex-column justify-content-end">
          <ColoredButton
            textButton={screenWidth > 600 && "Modifier les informations"}
            icon={screenWidth < 600 && "plus"}
            color="light-blue"
            callback={() => {
              navigate(
                `${
                  ["NEW_SELLER", "NEW"].includes(choosenItem?.status)
                    ? devisRoute
                    : prospetsRoute
                }/${choosenItem?.id}`
              );
            }}
          />
          <div className="mt-3" />
          <ColoredButton
            textButton={choosenItem?.archived ? "Désarchiver" : "Archiver"}
            outlined
            color="blue-grey"
            callback={archiverItem}
          />
        </div>
      )}
    </div>
  );
};

export default InformationComponent;
