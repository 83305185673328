import React from "react";

const OrganisationNotification = ({ length }) => {
  return (
    <div className="notificationOrganisation ">
      {/* {length > 10 ? "+9" : length} */}
    </div>
  );
};

export default OrganisationNotification;
