import { FORM_QUESTION_ATTRIBUTES } from "./formQuestion";

export const FORM_CATEGORY_ATTRIBUTES = `
name
position
status
questions ( pagination: { page: 1, pageSize: 10000 }){
    data{
        id
        attributes{
           ${FORM_QUESTION_ATTRIBUTES}

        }
    }
}
`;
