import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfigurationForm from "./ConfigurationForm";
import LightDarkText from "../../../Common/LightDarkText";
import { closeModal } from "../../../../REDUX/actions/modal";
import { CloseIcon } from "../../../Common/LightDarkSvg";

const QuestionForm = ({ question }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const [listData] = useState([
    {
      id: 1,
      label: "Configuration",
      component: <ConfigurationForm question={question} />,
    },
    // {
    //   id: 2,
    //   label: "Conditions du champs",
    //   component: <ConditionForm />,
    // },
  ]);
  const [isToggle, setIsToggle] = useState(listData[0]);

  return (
    <div className="w-100 p-3 position-relative" style={{}}>
      <div className="d-flex justify-content-between mb-2 ">
        <LightDarkText className="text-center f18 bold ">Champs</LightDarkText>
        <div
          className="d-flex justify-content-end mb-3"
          onClick={() => dispatch(closeModal())}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="d-flex justify-content-start align-items-center mb-3">
        {listData.map((item) => {
          return (
            <LightDarkText
              key={item?.id}
              className="text-start f12 bold me-4 clickable-text t-02 pointer"
              color={
                isToggle === item
                  ? "text-light-blue"
                  : mode === "light"
                  ? "text-blue-grey"
                  : "text-light-text"
              }
              onclickCallback={() => setIsToggle(item)}
            >
              {item?.label}
              {isToggle === item && (
                <div
                  className="radius20 mx-3"
                  style={{
                    borderBottom: "4px solid #3365ff",
                    position: "relative",
                    top: 8,
                  }}
                ></div>
              )}
            </LightDarkText>
          );
        })}
      </div>
      <div>{isToggle.component}</div>
    </div>
  );
};

export default QuestionForm;
