import React from "react";
import { useSelector } from "react-redux";

const LightDarkText = ({
  children,
  className,
  onclickCallback,
  insertHtml = null,
  style,
  color,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const intColor = color
    ? color
    : mode === "light"
    ? "text-dark-blue"
    : "text-white";
  return insertHtml ? (
    <div
      style={style}
      className={`${className} ${intColor}`}
      dangerouslySetInnerHTML={{ __html: insertHtml }}
      onClick={onclickCallback}
    />
  ) : (
    <div
      style={style}
      className={`${className} ${intColor}`}
      onClick={onclickCallback}
    >
      {children}
    </div>
  );
};

export default LightDarkText;
