import _ from "lodash";

import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CloseIcon } from "../../Common/LightDarkSvg";
import ColoredButton from "../../Common/Buttons/ColoredButton";
import LightDarkTextEditor from "../../Common/LightDarkTextEditor";

import { ticketUsersToMention } from "./messageUtils";

import { closeModal } from "../../../REDUX/actions/modal";
import { updateMessage } from "../../../REDUX/actions/tickets";

const UpdateMessage = ({ message }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { admins } = useSelector((state) => state.sharedDataReducer);
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const { user } = useSelector((state) => state.userReducer);

  const [newMessage, setNewMessage] = useState({
    content: message.attributes.content,
  });
  const [mentions, setMentions] = useState();
  const [oldMentions] = useState(
    message.attributes.mentions.data.map((mention) => mention.id?.toString())
  );

  const handleUpdateMessage = (messageId, newMessage) => {
    const newMentions = mentions?.map(
      (mention) => mention?.insert?.mention?.id
    );
    dispatch(
      updateMessage(
        messageId,
        {
          ...newMessage,
          is_internal: message?.attributes?.is_internal,
          parent_id: message?.attributes?.internal_response_to?.data?.id,
        },

        ticket,
        newMentions,
        oldMentions
      )
    );
    dispatch(closeModal());
  };

  return (
    <div
      className={`directionC py-3 px-4 radius05 ${
        mode === "light" ? "bg-white" : ""
      }`}
    >
      <div
        className="align-self-end mb-4"
        onClick={() => dispatch(closeModal())}
      >
        <CloseIcon />
      </div>
      <LightDarkTextEditor
        internalResponseTo={message?.attributes?.is_internal}
        label={"Modifier message"}
        placeholder="Nouveau message"
        height={"100"}
        value={newMessage}
        setValue={setNewMessage}
        champs="content"
        autoHeight
        canMention
        mentions={mentions}
        setMentions={setMentions}
        users={_.uniqBy(ticketUsersToMention(ticket, user, admins), "id")}
      />

      <div className="row justify-content-center mt-5 w-100">
        <div className="col-2 me-4">
          <ColoredButton
            textButton={"Annuler"}
            color="blue-grey"
            outlined
            callback={() => dispatch(closeModal())}
          />
        </div>
        <div className="col-4">
          <ColoredButton
            textButton={"Valider"}
            color="light-blue"
            callback={() => handleUpdateMessage(message.id, newMessage)}
            disabled={newMessage.content === "<p><br></p>"}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateMessage;
