import React, { useEffect, useState } from "react";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import TooltipActions from "../../Common/Tooltip/TooltipActions";
import { calculateSize, formatDate } from "../../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import PdfCard from "../PdfCard";
import PreviewModal from "../../Common/PreviewModal";
import { openModal } from "../../../REDUX/actions/modal";
import { saveAs } from "file-saver";
import ConditionalFileIcon from "../ConditionalFileIcon";
import {
  CopyAgilix,
  DeleteIcon,
  Download,
  EditIcon,
  EyeIcon,
  InternalCheck,
  ShareIcon,
} from "../../Common/LightDarkSvg";
import { openConfirmationModal } from "../../../REDUX/actions/confirmationModal";
import {
  copyFileInAgilix,
  deleteOneDriveFolder,
} from "../../../REDUX/actions/externalServices";
import FileSharedSection from "../CloudPartials/FileSharedSection";
import RenameCloudItem from "../RenameCloudItem";
import ShareFile from "../ShareFile";
import { deleteFile } from "../../../REDUX/actions/cloud";
import { useDrag } from "react-dnd";
import ElementName from "../CloudPartials/ElementName";

const CloudFileCard = ({
  file,
  name,
  id,
  mimeType,
  size,
  url,
  creationDate,
  setselected,
  selected,
  shared,
  type,
  siteId = null,
}) => {
  const dispatch = useDispatch();
  const [showTooltip, setshowTooltip] = useState(false);
  const [isSelected, setisSelected] = useState(false);
  const { user } = useSelector((state) => state.userReducer);

  const OpenPreview = () => {
    mimeType?.includes("image") || mimeType?.includes("pdf")
      ? dispatch(
          openModal({
            type: "pdfFile",
            content: (
              <PreviewModal
                fileData={{
                  url: url,
                  name: name,
                  size: size,
                  mime: mimeType,
                  date: creationDate,
                }}
              />
            ),
          })
        )
      : saveAs(url, name);
  };
  const handleSelect = () => {
    if (isSelected) {
      setisSelected(!isSelected);
      setselected(selected.filter((el) => el.id !== id));
    } else {
      setisSelected(!isSelected);

      setselected([...selected, { id, name, mimeType, url }]);
    }
  };

  useEffect(() => {
    setisSelected(selected.filter((el) => el.id === id).length);
  }, [selected]);

  const [collected, drag, dragPreview] = useDrag(() => ({
    type: "Card",
    item: { id: id },
  }));

  const tooltips = {
    oneDrive: [
      {
        id: 1,
        text: "Voir",
        icon: <EyeIcon className="me-2" size={24} />,
        action: () => {
          setshowTooltip(false);
          OpenPreview();
        },
        condition: mimeType?.includes("image") || mimeType?.includes("pdf"),
      },
      {
        id: 3,
        text: "Télécharger",
        icon: <Download className="me-2" size={24} />,
        action: () => {
          setshowTooltip(false);
          saveAs(url, name);
        },
        condition: true,
      },
      {
        id: 2,
        text: "Copier dans Agilix",
        icon: <CopyAgilix className="me-2" />,
        action: () => {
          dispatch(copyFileInAgilix([{ id, url, name, mimeType }]));
        },
        condition: true,
      },

      {
        id: 5,
        text: "Supprimer de onedrive",
        icon: <DeleteIcon className="me-2" size={24} />,
        action: () => {
          setshowTooltip(false);
          dispatch(
            openConfirmationModal({
              callBack: () => dispatch(deleteOneDriveFolder(id)),
              icon: "archive",
              message: "Voulez vous supprimer le fichier",
              message2: "Le fichier sera supprimé définitivement de OneDrive.",
              confirmButtonText: "Supprimer le fichier",
            })
          );
        },
        condition: !siteId,
      },
    ],
    agilix: [
      {
        id: 1,
        text: "Voir",
        icon: <EyeIcon className="me-2" size={24} />,
        action: () => {
          setshowTooltip(false);
          OpenPreview();
        },
        condition: mimeType.includes("image") || mimeType.includes("pdf"),
      },
      {
        id: 2,
        text: "Partager",
        icon: <ShareIcon className="me-2" size={24} />,
        action: () => {
          setshowTooltip(false);
          dispatch(
            openModal({
              type: "small",
              content: (
                <ShareFile
                  fileId={id}
                  fileSharedUsers={file?.attributes?.sharedUsers}
                />
              ),
            })
          );
        },
        condition:
          +user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
            ?.data?.id === +file?.attributes?.cloud?.data?.id,
      },
      {
        id: 3,
        text: "Télécharger",
        icon: <Download className="me-2" size={24} />,
        action: () => {
          setshowTooltip(false);
          saveAs(url, name);
        },
        condition: true,
      },
      {
        id: 4,
        text: "Renommer",
        icon: <EditIcon className="me-2" size={24} />,
        action: () => {
          setshowTooltip(false);
          dispatch(
            openModal({
              type: "small",
              content: <RenameCloudItem id={id} name={name} isFile={true} />,
            })
          );
        },
        condition:
          +user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
            ?.data?.id === +file?.attributes?.cloud?.data?.id,
      },
      {
        id: 5,
        text: "Supprimer",
        icon: <DeleteIcon className="me-2" size={24} />,
        action: () => {
          setshowTooltip(false);
          dispatch(
            openConfirmationModal({
              callBack: () => dispatch(deleteFile(file)),
              icon: "archive",
              message: "Voulez vous vraiment Supprimer le fichier",
              message2: `#${id} ${name}`,
              confirmButtonText: "Supprimer le fichier",
            })
          );
        },
        condition:
          +user?.attributes?.user_org_permissions?.data[0]?.attributes?.cloud
            ?.data?.id === +file?.attributes?.cloud?.data?.id,
      },
    ],
  };

  return (
    <LightDarkContainer
      colorDark="dark"
      className={`pointer cloudFileCardContainer radius05 t-02 position-relative ${
        isSelected ? "ContainerSelected" : ""
      }`}
    >
      <div className="blueFileFilter radius05" ref={drag}></div>
      <div
        onClick={handleSelect}
        className={`SelectBoxCloud radius05 t-02 alignC `}
      >
        <InternalCheck />
      </div>
      <div
        ref={shared || type !== "agilix" ? null : drag}
        className="cloudFileCard position-relative t-02"
        onClick={OpenPreview}
      >
        <div className={`shadowSelect t-02 `}></div>
        {mimeType.includes("image") ? (
          <div
            style={{
              backgroundImage: `url("${encodeURI(url)}")`,
              backgroundPosition: "center",
            }}
            className={`coudImageInner t-02 w-100 h-100 alignC radius05`}
            alt="image"
          ></div>
        ) : mimeType.includes("pdf") ? (
          <PdfCard url={url} />
        ) : (
          <ConditionalFileIcon mime={mimeType} />
        )}
      </div>

      <div className="d-flex mt-2 justify-content-between">
        <ElementName name={name} callBack={OpenPreview} />

        <div
          className="d-flex align-items-center position-relative pointer"
          onClick={() => setshowTooltip(!showTooltip)}
        >
          <TooltipActions
            setshowTooltip={setshowTooltip}
            showTooltip={showTooltip}
            tooltipData={tooltips[type]}
            isHorizontal
          />
          {shared ? <FileSharedSection file={file} /> : null}
        </div>
      </div>

      <div className="f10 text-start text-dark-grey mt-1 alignH justify-content-between">
        <LightDarkText className="f10 text-start" color="text-light-text">
          {formatDate(creationDate)}
        </LightDarkText>
        <LightDarkText className="f10 text-start" color="text-light-text">
          {calculateSize(size)}
        </LightDarkText>
      </div>
    </LightDarkContainer>
  );
};

export default CloudFileCard;
