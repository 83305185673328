import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LightDarkContainer from "../LightDarkContainer";
import LightDarkText from "../LightDarkText";
import { CloseIcon } from "../LightDarkSvg";

import useWindowWidth from "../../../Hooks/useWindowWidth";
import { unSelectFiles } from "../../../REDUX/actions/files";

const ExistingAndSelectedFiles = ({ existingFiles, setExistingFiles }) => {
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { temporarySelected } = useSelector((state) => state.filesReducer);

  const renderFile = (file, isExisting = false) => (
    <LightDarkContainer
      key={isExisting ? `existing-${file.id}` : `selected-${file.id}`}
      colorLight="light-mode"
      colorDark="dark-mode"
      className={`my-2 me-2 px-2 py-2 radius05 alignH gap-3 justify-content-between`}
    >
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip style={{ zIndex: 500 }}>
            <LightDarkText
              className={"custom-ticket-font-size bold pointer text-white"}
            >
              {isExisting
                ? file.attributes.file.data.attributes.name
                : file.name}
            </LightDarkText>
          </Tooltip>
        }
      >
        <LightDarkText
          className={
            "text-start custom-ticket-font-size bold pointer text-ellipsis"
          }
          color={mode === "light" ? "text-dark-blue" : "text-light-text"}
        >
          {isExisting ? file.attributes.file.data.attributes.name : file.name}
        </LightDarkText>
      </OverlayTrigger>
      <div>
        <CloseIcon
          dimension={screenWidth < 1200 ? 22 : 24}
          clickCallback={() => {
            if (isExisting) {
              setExistingFiles((prevFiles) =>
                prevFiles.filter((f) => f.id !== file.id)
              );
            } else {
              dispatch(unSelectFiles(file));
            }
          }}
        />
      </div>
    </LightDarkContainer>
  );

  const hasFiles = existingFiles?.length > 0 || temporarySelected?.length > 0;

  if (!hasFiles) return null;

  return (
    <div className="mb-4">
      <div
        className="d-flex align-items-center justify-content-start"
        style={{ overflowX: "scroll" }}
      >
        {existingFiles?.map((file) => renderFile(file, true))}
        {temporarySelected?.map((file) => renderFile(file, false))}
      </div>
    </div>
  );
};

export default ExistingAndSelectedFiles;
