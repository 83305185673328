import { useDispatch, useSelector } from "react-redux";

import MessageInput from "./MessageInput";
import MessageSelectedFiles from "./MessageSelectedFiles";
import { interviewResponsePending } from "../../messageUtils";
import LightDarkText from "../../../../Common/LightDarkText";
import { useEffect } from "react";
import InterviewForm from "../../MessagesTypes/InterviewMessages/InterviewForm";
import { openSideBar } from "../../../../../REDUX/actions/sideBar";

const MessageInputContainer = ({
  setIsReplying,
  responseTo,
  setResponseTo,
  internalResponseTo,
  setInternalResponseTo,
}) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { user } = useSelector((state) => state.userReducer);
  const { canSendMessages, role } = useSelector((state) => state.roleReducer);
  const { showPrivateMessages } = useSelector((state) => state.messagesReducer);
  const { ticket } = useSelector((state) => state.ticketsReducer);
  const handleScroll = (id) => {
    document.querySelector(`#interview-${id}`).scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const remainingModels = interviewResponsePending(
      ticket.attributes.interviews,
      user
    );
    if (remainingModels[0]?.attributes?.model?.data) {
      dispatch(
        openSideBar({
          content: (
            <InterviewForm
              interviewModel={remainingModels[0]?.attributes?.model?.data}
            />
          ),
          title: `Répondre au sondage`,
          type: "w40",
          isToggle: "isToggle value",
        })
      );
    }
  }, [ticket]);

  return !ticket.attributes.archived && canSendMessages ? (
    interviewResponsePending(ticket.attributes.interviews, user, role)
      .length === 0 ? (
      <>
        <div
          className="my-1"
          style={{
            borderBottom: `2px solid ${
              mode === "light"
                ? showPrivateMessages
                  ? "#fee2e2"
                  : "#F8F9FF"
                : showPrivateMessages
                ? "#fee2e2"
                : "#252C45"
            }`,
          }}
        />
        <MessageInput
          setIsReplying={setIsReplying}
          responseTo={responseTo}
          setResponseTo={setResponseTo}
          internalResponseTo={internalResponseTo}
          setInternalResponseTo={setInternalResponseTo}
        />
        <MessageSelectedFiles />
      </>
    ) : (
      <div className="my-3 radius05 py-4 bold interviewBlockMessage">
        <LightDarkText>
          Vous devez répondre avant au sondage suivants :{" "}
        </LightDarkText>
        {interviewResponsePending(ticket.attributes.interviews, user).map(
          (interview) => (
            <LightDarkText
              onclickCallback={() =>
                handleScroll(
                  interview.attributes.model.data.attributes.messages.data.find(
                    (el) => el.attributes.ticket.data.id === ticket.id
                  ).id
                )
              }
              className={"pointer textBlueHover"}
              key={interview.id}
            >
              {" "}
              {interview.attributes.model.data.attributes.name}
            </LightDarkText>
          )
        )}
      </div>
    )
  ) : null;
};

export default MessageInputContainer;
