import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getValueByAccessor, handleFirstLastName } from "../../Utils/utils";
import LightDarkContainer from "../Common/LightDarkContainer";
import LightDarkText from "../Common/LightDarkText";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useWindowWidth from "../../Hooks/useWindowWidth";

import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";
import { getUserById } from "../../REDUX/actions/operateurs";
import TooltipActions from "../Common/Tooltip/TooltipActions";
import { DeleteIcon, EyeIcon } from "../Common/LightDarkSvg";
import { openSideBarCard } from "../../REDUX/actions/general";
import { userModalData } from "./userModalData";
import ThumbnailPlaceHolder from "../PlaceHolders/ThumbnailPlaceHolder";
import LightDarkPlaceHolder from "../PlaceHolders/LightDarkPlaceHolder";
import UserThumbnail from "../Common/UserThumbnail";
import moment from "moment";

const UserCard = ({ user, index, tableList, operatorCard, isLoading }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const screenWidth = useWindowWidth();

  const { mode, openSideBar } = useSelector((state) => state.genearlReducer);
  const { canCreateCollabs, canCreateOperators } = useSelector(
    (state) => state.roleReducer
  );
  const { clientsRoute, operatorsRoute, societeRoute } = useSelector(
    (state) => state.organisationsReducer
  );
  const { selectedUser } = useSelector((state) => state.clientsReducer);

  const [showTooltip, setshowTooltip] = useState(false);

  const showUserDetails = async () => {
    if (
      location.pathname.includes(operatorsRoute) ||
      location.pathname.includes(clientsRoute) ||
      location.pathname.includes(societeRoute)
    ) {
      dispatch(getUserById(user?.id));
      dispatch(openSideBarCard());
    }
  };

  const tooltipData = [
    {
      id: 1,
      text: "Voir les détails",
      icon: <EyeIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        showUserDetails();
      },
      condition: true,
    },
    {
      id: 2,
      text: user.attributes.user_org_permissions[0]?.archived
        ? "Désarchiver"
        : "Archiver",
      icon: <DeleteIcon className="me-2" />,
      action: () => {
        setshowTooltip(false);
        dispatch(openConfirmationModal(userModalData(user, dispatch)));
      },
      condition: canCreateCollabs || canCreateOperators,
    },
  ];
  return (
    <LightDarkContainer
      key={index}
      index={index}
      className={`${mode === "light" ? "lightListCard" : "darkListCard"} ${
        +selectedUser?.id === +user?.id &&
        location.pathname === (operatorCard ? operatorsRoute : clientsRoute) &&
        (mode === "light" ? "lightListCardFocus" : "darkListCardFocus")
      } d-flex py-2 px-3 w100 pointer justify-content-between`}
      colorLight="white"
      colorDark="dark-grey-bg"
      hover={true}
      cardContainer={true}
    >
      <div className=" d-flex w-100 " style={{ overflow: "hidden" }}>
        {tableList?.map(({ widthSideBar, width, id, accessor }) => {
          const columnCol = openSideBar ? widthSideBar : width;

          return isLoading ? (
            <div key={id} className={`alignH  w${columnCol} p-0`}>
              {accessor.includes("firstName") ? (
                <div className="alignH gap-2 w-100">
                  <ThumbnailPlaceHolder smallThumbnail />
                  <LightDarkPlaceHolder customClass="w60" height={12} />
                </div>
              ) : (
                <LightDarkPlaceHolder customClass="w85" height={10} />
              )}
            </div>
          ) : (
            <div
              className={`alignH w${columnCol} p-0 text-ellipsis`}
              key={id}
              onClick={() => {
                showUserDetails();
              }}
            >
              {accessor.includes("adress") ? (
                (user.attributes.adress ||
                  user.attributes.city ||
                  user.attributes.zipCode) && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <LightDarkText className="custom-ticket-font-size py-1 text-white">
                          {`${user.attributes.adress}, `}
                          {user.attributes.zipCode &&
                            `${user.attributes.zipCode}, `}
                          {user.attributes.city && `${user.attributes.city}`}
                        </LightDarkText>
                      </Tooltip>
                    }
                  >
                    <div className="text-ellipsis pe-3">
                      <LightDarkText
                        color={
                          mode === "light"
                            ? "text-blue-grey"
                            : "text-light-text"
                        }
                        className={
                          "custom-ticket-font-size bold text-start text-ellipsis"
                        }
                      >
                        {user.attributes.adress &&
                          `${user.attributes.adress}, `}
                        {user.attributes.zipCode &&
                          `${user.attributes.zipCode}, `}
                        {user.attributes.city && `${user.attributes.city}`}
                      </LightDarkText>
                    </div>
                  </OverlayTrigger>
                )
              ) : (
                <div className={` d-flex align-items-center gap-3 w95`}>
                  {accessor.includes("firstName") && (
                    <UserThumbnail
                      userId={user.id}
                      connected={user?.attributes?.connected}
                      lastConnected={user?.attributes?.lastConnected}
                      size={screenWidth < 1200 ? 30 : 40}
                      apiImg={
                        user?.attributes?.avatar?.data?.attributes?.image?.data
                          ?.attributes?.url
                      }
                      img={`user${user?.attributes?.genre || "3"}`}
                    />
                  )}
                  <LightDarkText
                    className={`custom-ticket-font-size  ${
                      accessor.includes("firstName") ? "bolder" : "bold"
                    } text-start text-ellipsis`}
                    color={
                      accessor.includes("firstName") && mode === "light"
                        ? "text-dark-blue"
                        : accessor.includes("firstName") && mode !== "light"
                        ? "text-white"
                        : mode === "light"
                        ? "text-blue-grey"
                        : "text-light-text"
                    }
                  >
                    {accessor.includes("id") ? "#" : null}
                    {accessor.includes("firstName")
                      ? handleFirstLastName(user)
                      : accessor.includes("lastConnected")
                      ? user.attributes.connected
                        ? "En ligne"
                        : user.attributes.lastConnected
                        ? `En ligne il y a ${moment
                            .duration(
                              moment().diff(user.attributes.lastConnected)
                            )
                            .humanize()}`
                        : "Hors ligne"
                      : getValueByAccessor(user, accessor)}
                  </LightDarkText>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {location.pathname.includes(operatorsRoute) ||
      location.pathname.includes(clientsRoute) ||
      location.pathname.includes(societeRoute) ? (
        <div className="d-flex align-items-center justify-content-center">
          <TooltipActions
            setshowTooltip={setshowTooltip}
            showTooltip={showTooltip}
            tooltipData={tooltipData}
          />
        </div>
      ) : null}
    </LightDarkContainer>
  );
};

export default UserCard;
