import { createRef } from "react";
import { useSelector } from "react-redux";

import CalendarHeader from "./CalendarHeader";
import CalendarBody from "./CalendarBody";

import "./Calendar.css";

const Calendar = ({
  selectedCalendars,
  events,
  sharedEvents,
  upcomingEvents,
  nextMonthEvents,
  afterNextMonthEvents,
}) => {
  const calendarRef = createRef();

  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div
      className={`position-relative d-flex flex-column ps-3 ${
        mode === "light" ? "bg-white" : "bg-dark-grey-bg"
      }`}
      style={{
        overflowX: "hidden",
        height: "calc(100vh - 260px)",
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
        borderRadius: `${
          upcomingEvents || nextMonthEvents || afterNextMonthEvents ? "5px" : ""
        }`,
        width: `${
          upcomingEvents || nextMonthEvents || afterNextMonthEvents
            ? "100%"
            : "100%"
        }`,
      }}
    >
      <div
        className={`pt-3 pb-1 w-100 ${
          mode === "light" ? "bg-white" : "bg-dark-grey-bg"
        }`}
        style={{ position: "sticky", top: 0, zIndex: 6 }}
      >
        <CalendarHeader
          calendarRef={calendarRef}
          selectedCalendars={selectedCalendars}
          sharedEvents={sharedEvents}
        />
      </div>
      <CalendarBody
        calendarRef={calendarRef}
        events={[...events, ...sharedEvents]}
      />
    </div>
  );
};

export default Calendar;
