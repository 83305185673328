import { Modal } from "react-bootstrap";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../REDUX/actions/modal";

import "./FunctionModal.css";

const FunctionModal = () => {
  const dispatch = useDispatch();
  const { open, content, preventClose, modaltype } = useSelector(
    (state) => state.modalReducer
  );
  const { mode } = useSelector((state) => state.genearlReducer);

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      show={open}
      onHide={preventClose ? undefined : handleClose}
      contentClassName={
        modaltype === "small"
          ? "my-modal"
          : modaltype === "alertModal" && "alertModal"
      }
      dialogClassName={modaltype === "small" && "modal-type"}
      size={modaltype}
      centered
      fullscreen={modaltype === "pdfFile"}
      style={{ overflowY: "hidden" }}
      className={`${
        mode === "light" && modaltype !== "pdfFile"
          ? "lightconfirmationModal"
          : mode === "dark" && modaltype !== "pdfFile"
          ? "darkconfirmationModal"
          : "pdfModal"
      }`}
    >
      {content}
    </Modal>
  );
};

export default FunctionModal;
