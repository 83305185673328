export const userAttributes = `
email
firstName
genre
lastConnected
connected
lastName
adress
city
blocked
phoneNumber
zipCode
archived
birthDate
additionalData
`;
