import { useState } from "react";
import { useSelector } from "react-redux";
import LightDarkText from "../../Common/LightDarkText";
import DropDownProfileMenu from "../DropDownMenu/DropDownProfileMenu";

import { handleFirstLastName, showPoste } from "../../../Utils/utils";
import UserThumbnail from "../../Common/UserThumbnail";

const UserProfile = () => {
  const { user } = useSelector((state) => state.userReducer);
  const { mode } = useSelector((state) => state.genearlReducer);

  const [showMenuDropDown, setshowMenuDropDown] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setshowMenuDropDown(!showMenuDropDown);
        }}
        className={`alignH gap-3 text-start pointer t-02 clickable-text position-relative py-1 ps-2 pe-5 radius05
              ${mode === "light" ? "bg-white" : "bg-dark-grey-bg"}
              `}
        style={{ minWidth: "max-content" }}
      >
        <UserThumbnail
          userId={user?.id}
          connected={user?.attributes?.connected}
          lastConnected={user?.attributes?.lastConnected}
          size={40}
          apiImg={
            user?.attributes?.avatar?.data?.attributes?.image?.data?.attributes
              ?.url
          }
          img={`user${user?.attributes?.genre || "3"}`}
        />
        <div>
          <LightDarkText className="xbold custom-font-size">
            {handleFirstLastName(user)}
          </LightDarkText>
          <LightDarkText
            className="bolder custom-ticket-font-size"
            color={mode === "light" ? "text-blue-grey" : "text-light-text"}
          >
            {showPoste(user.attributes)}
          </LightDarkText>
        </div>
      </div>
      {showMenuDropDown && <DropDownProfileMenu show={setshowMenuDropDown} />}
    </>
  );
};

export default UserProfile;
