import { userAttributes } from "../general/userFields";
import { USER_ORG_ATTRIBUTES_LIGHTER } from "./UserOrg";

export const SHARED_USERS_ATTRIBUTES = `
sharedUsers {
  data {
    id
    attributes {
      ${userAttributes}
      user_org_permissions (
        filters: {
          organisation: { id: { eq: $orgId} }  
        }
      ) 
      {
        data {
          id
          attributes {
            ${USER_ORG_ATTRIBUTES_LIGHTER}
          }
        }
      }
      avatar {
        data {
          id
          attributes {
            image {
              data {
                id 
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const ATTRIBUTES_FOLDER = `
name
isSubFolder
path
parentFolder {
  data {
    id
    attributes {
      parentFolder{
        data{
          id
          attributes{
            isSubFolder
          }
        }
      }
      isSubFolder
      name
      ${SHARED_USERS_ATTRIBUTES}
    }
  }
}
subFolders {
  data {
    id
    attributes {
      name
      path
      isSubFolder
      parentFolder {
        data {
          id
          attributes {
            isSubFolder
            name
            ${SHARED_USERS_ATTRIBUTES}
          }
        }
      }
      ${SHARED_USERS_ATTRIBUTES}
      fichiers(pagination: { page: 1, pageSize: 1000 }) {
  data {
    id
    attributes {
      createdAt
      ${SHARED_USERS_ATTRIBUTES}
      file {
        data {
          id
          attributes {
            url
            name
            mime
            createdAt
          }
        }
      }
      folder {
        data {
          id
          attributes {
            cloud {
              data {
                id
              }
            }
            ${SHARED_USERS_ATTRIBUTES}
          }
        }
      }
      cloud {
        data {
          id
          attributes {
            user {
              data {
                id 
                attributes {
                  ${userAttributes}
                }
              }
            }
          }
        }
      }
    }
  }
}
      cloud {
        data {
          id
          attributes {
            user {
              data {
                id 
                attributes {
                  ${userAttributes}
                }
              }
            }
          }
        }
      }
    }
  }
}
fichiers(pagination: { page: 1, pageSize: 1000 }) {
  data {
    id
    attributes {
      createdAt
      name
      ${SHARED_USERS_ATTRIBUTES}
      file {
        data {
          id
          attributes {
            url
            name
            mime
            createdAt
          }
        }
      }
      folder {
        data {
          id
          attributes {
            cloud {
              data {
                id
              }
            }
            ${SHARED_USERS_ATTRIBUTES}
          }
        }
      }
      cloud {
        data {
          id
          attributes {
            user {
              data {
                id 
                attributes {
                  ${userAttributes}
                }
              }
            }
          }
        }
      }
    }
  }
}
cloud {
  data {
    id
    attributes {
      user {
        data {
          id 
          attributes {
            ${userAttributes}
          }
        }
      }
    }
  }
}
${SHARED_USERS_ATTRIBUTES}

`;

export const ATTRIBUTES_FILES = `
      createdAt
      name
      ${SHARED_USERS_ATTRIBUTES}
      file {
        data {
          id
          attributes {
            url
            name
            mime
            createdAt
          }
        }
      }
      cloud {
        data {
          id
          attributes {
            user {
              data {
                id 
                attributes {
                  ${userAttributes}
                }
              }
            }
          }
        }
      }
      folder {
        data {
          id
          attributes {
            cloud {
              data {
                id
              }
            }
            ${SHARED_USERS_ATTRIBUTES}
          }
        }
      }
`;
