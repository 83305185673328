import React, { useState } from "react";
import { useSelector } from "react-redux";
import LightDarkContainer from "../../Common/LightDarkContainer";
import MessageInputContainer from "./TicketChatContainer/MessageInputContainer";
import MessagesList from "./TicketChatContainer/MessagesList/MessagesList";
import ChatHeader from "./TicketChatContainer/ChatHeader";

import "./TicketChat.css";
import PrivateMessagesList from "./TicketChatContainer/MessagesList/PrivateMessagesList";

const TicketChat = () => {
  const { ticket, ticketsLoading } = useSelector(
    (state) => state.ticketsReducer
  );

  const { showPrivateMessages } = useSelector((state) => state.messagesReducer);

  const { canSendMessages } = useSelector((state) => state.roleReducer);

  const [responseTo, setResponseTo] = useState(null);
  const [internalResponseTo, setInternalResponseTo] = useState(null);
  const [isReplying, setIsReplying] = useState(null);

  const handleFocusToRespond = () => {
    const editorElement = document.querySelector(`.ql-editor`);
    if (editorElement) {
      editorElement.focus();
    }
  };

  return ticketsLoading || !ticket.attributes ? null : (
    <LightDarkContainer
      className={`px-4 d-flex flex-column justify-content-between radius05 h100 
      ${!canSendMessages && "pb-3"}`}
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <ChatHeader />

      {!showPrivateMessages ? (
        <MessagesList
          setResponseTo={setResponseTo}
          responseTo={responseTo}
          setInternalResponseTo={setInternalResponseTo}
          isReplying={isReplying}
          setIsReplying={setIsReplying}
          handleFocusToRespond={handleFocusToRespond}
        />
      ) : (
        <PrivateMessagesList
          setInternalResponseTo={setInternalResponseTo}
          setResponseTo={setResponseTo}
          responseTo={responseTo}
          handleFocusToRespond={handleFocusToRespond}
        />
      )}

      <MessageInputContainer
        setIsReplying={setIsReplying}
        responseTo={responseTo}
        setResponseTo={setResponseTo}
        internalResponseTo={internalResponseTo}
        setInternalResponseTo={setInternalResponseTo}
      />
    </LightDarkContainer>
  );
};

export default TicketChat;
