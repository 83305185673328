import React from "react";
import "./TransparentButton.css";

const TransparentButton = ({ children, callback, remove = false }) => {
  return (
    <button
      className={`${
        remove ? "transparentButtonRemove" : "transparentButtonRegular"
      } transparentButton radius05 h40 t-02 f14 bold alignC px-4 text-dark-blue`}
      onClick={callback}
    >
      {children}
    </button>
  );
};

export default TransparentButton;
