import React from "react";
import LightDarkInput from "../../Common/LightDarkInput";
import { socialMediaValidationLink } from "../../../Utils/utils";

const SocietySocialMediaForm = ({ societyInputs, setsocietyInputs }) => {
  const socialMediaLinks = [
    {
      label: "Facebook",
      placeholder: "https://facebook.com/your-profile",
      champs: "facebookLink",
      key: "facebookLink",
    },
    {
      label: "Instagram",
      placeholder: "https://instagram.com/your-profile",
      champs: "instagramLink",
      key: "instagramLink",
    },
    {
      label: "Linkedin",
      placeholder: "https://linkedin.com/in/your-profile",
      champs: "linkedinLink",
      key: "linkedinLink",
    },
    {
      label: "X",
      placeholder: "https://x.com/your-profile",
      champs: "xLink",
      key: "xLink",
    },
    {
      label: "TikTok",
      placeholder: "https://tiktok.com/@your-profile",
      champs: "tikTokLink",
      key: "tikTokLink",
    },
  ];

  return (
    <div className="mt-4 d-flex flex-column gap-3">
      {socialMediaLinks.map(({ label, placeholder, champs, key }) => (
        <div key={key}>
          <LightDarkInput
            placeholder={placeholder}
            label={label}
            totalInputs={societyInputs}
            setValue={setsocietyInputs}
            champs={champs}
          />
          {societyInputs[key] &&
            !socialMediaValidationLink(societyInputs[key], key) && (
              <span className="field-error f11 mt-1 text-red">{`Le lien ${label} que vous avez saisi n'est pas valide`}</span>
            )}
        </div>
      ))}
    </div>
  );
};

export default SocietySocialMediaForm;
