import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CalendarsList from "../../Components/Calendar/CalendarsList/CalendarsList";
import SearchDropDownList from "../../Components/Calendar/Events/EventCard/SearchDropDownList";
import ColoredButton from "../../Components/Common/Buttons/ColoredButton";
import EventsList from "../../Components/Calendar/Events/EventsList";
import SearchInput from "../../Components/Common/SearchInput";
import ListHeader from "../../Components/Common/ListHeader";
import EmptyList from "../../Components/Common/EmptyList";
import Loading from "../../Components/Common/Loading";
import Calendar from "../../Components/Calendar";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { sortFunction } from "../../Utils/utils";
import {
  getAllCalendars,
  selectCalendars,
} from "../../REDUX/actions/calendars";
import splitEvents from "./splitEvents";
import { openSideBar } from "../../REDUX/actions/sideBar";
import AddEditEventContent from "../../Components/Calendar/Events/SideBar/AddEditEventContent";
import EventDetailsContent from "../../Components/Calendar/Events/SideBar/EventDetailsContent";

const CalendarView = () => {
  const screenWidth = useWindowWidth();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { role } = useSelector((state) => state.roleReducer);

  const {
    calendars,
    selectedCalendars,
    calendarLoading,
    sharedEvents,
    calendarRef,
  } = useSelector((state) => state.calendarsReducer);

  useEffect(() => {
    if (user && role) {
      dispatch(getAllCalendars());
    }
  }, [user, dispatch, role]);

  const [search, setSearch] = useState("");

  const events = [];
  let allEvents = [];

  if (sharedEvents) {
    allEvents = [...allEvents, ...sharedEvents];
  }

  selectedCalendars
    ?.map((el) => el?.attributes?.events?.data)
    .forEach((item) => item && events.push(...item));

  calendars
    ?.map((el) => el?.attributes?.events?.data)
    .forEach((item) => item && allEvents.push(...item));

  const [filteredEvents, setFilteredEvents] = useState([allEvents]);
  const [filteredCalendars, setFilteredCalendars] = useState(calendars);

  const {
    ongoingEvents,
    upcomingEvents,
    nextMonthEvents,
    afterNextMonthEvents,
  } = splitEvents(events);

  useEffect(() => {
    setFilteredEvents(
      sortFunction(allEvents, "name", true).filter(
        (el) =>
          el?.attributes?.name?.toLowerCase().includes(search?.toLowerCase()) ||
          el?.attributes?.calendar?.data?.attributes?.name
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
          el?.attributes?.calendar?.data?.attributes?.societe?.data?.attributes?.name
            ?.toLowerCase()
            .includes(search?.toLowerCase())
      )
    );
    setFilteredCalendars(
      sortFunction(calendars, "name", true).filter((el) =>
        el.attributes?.name?.toLowerCase().includes(search?.toLowerCase())
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const eventClick = (event) => {
    if (
      !events?.find((e) => e?.id == event?.attributes?.calendar?.data?.id) &&
      !selectedCalendars?.find(
        (el) => el?.id == event?.attributes?.calendar?.data?.id
      )
    ) {
      dispatch(selectCalendars(event?.attributes?.calendar?.data?.id));
    }
    if (calendarRef) {
      calendarRef.gotoDate(event?.attributes?.startDate);
      dispatch(
        openSideBar({
          content: (
            <EventDetailsContent
              fromCalendar={true}
              eventDetails={event}
              isNew={false}
            />
          ),
          type: "w30",
        })
      );
      setSearch("");
    }
  };
  return calendarLoading ? (
    <Loading showLoader={true} />
  ) : (
    <div className=" h100 d-flex flex-column" style={{ position: "relative" }}>
      <div className="d-flex flex-row align-items-center justify-content-between h50px mb-2">
        <div className={`${calendars?.length ? "col-10" : "col-12"} h100`}>
          <ListHeader showSocityFilter={false}>
            <SearchInput
              type="search"
              calendarSearch
              placeholder="Agenda, Evénement..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              width={"w100"}
            />
          </ListHeader>
        </div>
        {calendars?.length ? (
          <div className="col-2 ms-2 h100">
            <ColoredButton
              height="100"
              textButton={screenWidth > 600 && "Ajouter un événement"}
              color="light-blue"
              icon={screenWidth < 600 && "plus"}
              callback={() =>
                dispatch(
                  openSideBar({
                    content: (
                      <AddEditEventContent fromCalendar={false} isNew={true} />
                    ),
                    title: "Ajouter un événement",
                    type: "w30",
                    isToggle: "isToggle value",
                  })
                )
              }
            />
          </div>
        ) : null}
      </div>

      {search && (
        <SearchDropDownList
          events={filteredEvents}
          calendars={filteredCalendars}
          eventClick={eventClick}
          setSearch={setSearch}
        />
      )}
      <CalendarsList calendars={calendars} />
      {calendars?.length || sharedEvents.length ? (
        <div className="d-flex flex-1 calendarViewContainer">
          <Calendar
            selectedCalendars={selectedCalendars}
            sharedEvents={sharedEvents}
            events={events}
            upcomingEvents={upcomingEvents}
            nextMonthEvents={nextMonthEvents}
            afterNextMonthEvents={afterNextMonthEvents}
          />

          <EventsList
            eventClick={eventClick}
            ongoingEvents={ongoingEvents}
            upcomingEvents={upcomingEvents}
            nextMonthEvents={nextMonthEvents}
            afterNextMonthEvents={afterNextMonthEvents}
          />
        </div>
      ) : (
        <EmptyList type="calendar" />
      )}
    </div>
  );
};

export default CalendarView;
