import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInterviewInformations } from "../../../../../REDUX/actions/interviews";
import InterviewPlaceHolder from "./InterviewPlaceHolder";
import LightDarkText from "../../../../Common/LightDarkText";
import InterviewQuestionInfo from "./InterviewQuestionInfo";

const InterviewInfoSideBar = ({ interviewModel }) => {
  const dispatch = useDispatch();
  const { interviewLoading, interviewInformations } = useSelector(
    (state) => state.interviewsReducer
  );

  useEffect(() => {
    dispatch(getInterviewInformations(interviewModel.id));
  }, []);

  return interviewLoading || !interviewInformations ? (
    <InterviewPlaceHolder />
  ) : (
    <div className="p-4 notScrollable h100 d-flex flex-column">
      <div>
        <LightDarkText className="text-start bold f18">
          <span className="text-light-blue">{interviewInformations.id}</span>
          {` | ${interviewInformations.attributes.name}`}
        </LightDarkText>
        <LightDarkText
          className={
            "custom-ticket-font-size text-blue-grey p-nomb text-start mb-3"
          }
        >
          {interviewInformations.attributes.description}
        </LightDarkText>
        {interviewInformations.attributes.forRoles.data.length ? (
          <LightDarkText
            className={
              "custom-ticket-font-size text-blue-grey p-nomb text-start mb-3"
            }
          >
            <span className="bold">Utilisateurs concernées :</span>
            {interviewInformations.attributes.forRoles.data.map((role) => (
              <div className="ms-4" key={role.id}>
                {role.attributes.name}
              </div>
            ))}
          </LightDarkText>
        ) : null}
      </div>
      <div className="d-flex flex-column gap-3 scrollable pb-5">
        {interviewInformations.attributes.interview_questions.data.map(
          (question) => (
            <InterviewQuestionInfo question={question} key={question.id} />
          )
        )}
      </div>
    </div>
  );
};

export default InterviewInfoSideBar;
