import React from "react";

const EyeSlash = ({ fill = "#a9bcd3", width = "24", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.224 16.0641C36.7165 15.2591 36.174 14.5065 35.614 13.8065C34.9665 12.984 33.7415 12.9141 33.0065 13.6491L27.7565 18.8991C28.1415 20.0541 28.2115 21.3841 27.8615 22.7666C27.249 25.2341 25.254 27.2291 22.7865 27.8416C21.404 28.1916 20.074 28.1216 18.919 27.7366C18.919 27.7366 16.4165 30.239 14.614 32.0415C13.739 32.9165 14.019 34.4566 15.1915 34.9116C17.064 35.6291 19.0065 35.9966 21.0015 35.9966C24.1165 35.9966 27.144 35.0866 29.909 33.3891C32.7265 31.6391 35.264 29.0665 37.3115 25.794C38.974 23.1515 38.8865 18.7066 37.224 16.0641Z"
        fill={fill}
      />
      <path
        d="M24.5361 17.4661L17.4661 24.5361C16.5736 23.6261 15.9961 22.3661 15.9961 21.0011C15.9961 18.2536 18.2361 15.9961 21.0011 15.9961C22.3661 15.9961 23.6261 16.5736 24.5361 17.4661Z"
        fill={fill}
      />
      <path
        d="M31.9375 10.0639L26.005 15.9964C24.7275 14.7014 22.96 13.9314 21 13.9314C17.08 13.9314 13.93 17.0989 13.93 21.0014C13.93 22.9614 14.7175 24.7289 15.995 26.0064L10.08 31.9389H10.0625C8.12 30.3639 6.335 28.3514 4.8125 25.9714C3.0625 23.2239 3.0625 18.7614 4.8125 16.0139C6.8425 12.8289 9.3275 10.3264 12.0925 8.61141C14.8575 6.93141 17.885 6.00391 21 6.00391C24.9025 6.00391 28.6825 7.43891 31.9375 10.0639Z"
        fill={fill}
      />
      <path
        d="M26.0056 21.0006C26.0056 23.7481 23.7656 26.0056 21.0006 26.0056C20.8956 26.0056 20.8081 26.0056 20.7031 25.9706L25.9706 20.7031C26.0056 20.8081 26.0056 20.8956 26.0056 21.0006Z"
        fill={fill}
      />
      <path
        d="M38.0966 3.90156C37.5716 3.37656 36.7141 3.37656 36.1891 3.90156L3.90156 36.2066C3.37656 36.7316 3.37656 37.5891 3.90156 38.1141C4.16406 38.3591 4.49656 38.4991 4.84656 38.4991C5.19656 38.4991 5.52906 38.3591 5.79156 38.0966L38.0966 5.79156C38.6391 5.26656 38.6391 4.42656 38.0966 3.90156Z"
        fill={fill}
      />
    </svg>
  );
};

export default EyeSlash;
