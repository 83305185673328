import React from "react";
import LightDarkPlaceHolder from "./LightDarkPlaceHolder";

const RightInterventionPlaceHolder = () => {
  return ["", "", "", "", "", "", "", ""].map((_, index) => (
    <div className="d-flex justify-content-between gap-3 py-3" key={index}>
      <div className="d-flex flex-column w30 gap-2">
        <LightDarkPlaceHolder customClass={""} />
        <LightDarkPlaceHolder customClass={""} />
        <LightDarkPlaceHolder customClass={""} />
      </div>
      <div className="w50">
        <LightDarkPlaceHolder customClass={"h100 py-3"} />
      </div>
    </div>
  ));
};

export default RightInterventionPlaceHolder;
