import React from "react";
import "./ExternalLoader.css";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import { useSelector } from "react-redux";

import ExternalLoaderItem from "./ExternalLoaderItem";
const ExternalLoader = () => {
  const { showExternalLoader, externalItems, externalLoaderTitle } =
    useSelector((state) => state.externalServicesReducer);
  return showExternalLoader ? (
    <LightDarkContainer
      className="externalLoaderContainer radius10  py-2"
      colorLight={"white"}
    >
      <div className="d-flex px-4">
        <LightDarkText className={"xbold f14 mb-4 pt-2 "}>
          {externalLoaderTitle}
        </LightDarkText>
      </div>
      <div className="externalLoadingList d-flex flex-column align-items-start w-100 gap-3 pt-4 px-4">
        {externalItems.map((el) => (
          <ExternalLoaderItem key={el.id} element={el} />
        ))}
      </div>
    </LightDarkContainer>
  ) : null;
};

export default ExternalLoader;
