import { useCallback, useEffect, useState } from "react";

const useUpload = () => {
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();

  const handleChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const currentFile = e.target.files[0];
      setFile(currentFile);
    }
  };

  const initialState = useCallback((state) => {
    setFile(state);
  }, []);

  useEffect(() => {
    if (!file) {
      setPreview();
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  return { file, preview, handleChange, initialState };
};

export default useUpload;
