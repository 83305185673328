import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IconButton from "../../../../../Common/Buttons/IconButton";
import { ChevronRight } from "../../../../../Common/LightDarkSvg";
import LightDarkText from "../../../../../Common/LightDarkText";
import InterviewSubmitButton from "./InterviewSubmitButton";

const InterviewTextInput = ({
  question,
  handleSubmit,
  value,
  setValue,
  step,
  setStep,
  disabled,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  const [matchRegex, setMatchRegex] = useState(true);
  const [requirementMet, setRequirementMet] = useState(false);

  useEffect(() => {
    if (question.attributes.interview_regex) {
      if (value) {
        setMatchRegex(
          new RegExp(
            question.attributes.interview_regex.data.attributes.value
          ).test(value)
        );
      }
    } else {
      setMatchRegex(true);
    }
    if (question.attributes.required) {
      if (value) {
        setRequirementMet(true);
      } else {
        setRequirementMet(false);
      }
    } else {
      setRequirementMet(true);
    }
  }, [value]);

  return (
    <div className="w100  h40 mt-2">
      <div className="alignH gap-2">
        {question.attributes.icon ? (
          <i className="alignC f30 bi-check h100" />
        ) : null}
        <input
          className={`LightDarkInputContainer lightInput px-2 w-100 radius05 h40 ${
            mode === "light" ? "lightInput" : "darkInput"
          }`}
          disabled={disabled}
          placeholder={question.attributes.placeHolder}
          type={question.attributes.type}
          required={question.attributes.required}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          onFocus={() =>
            step !== question.attributes.order && !disabled
              ? setStep(question.attributes.order)
              : {}
          }
        />
        {step === question.attributes.order && (
          <InterviewSubmitButton
            matchRegex={matchRegex}
            requirementMet={requirementMet}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
      {question.attributes.interview_regex && value ? (
        matchRegex ? null : (
          <div className="text-red mt-1 f10">
            Valeurs acceptés :{" "}
            {question.attributes.interview_regex.data.attributes.label}
          </div>
        )
      ) : null}
    </div>
  );
};

export default InterviewTextInput;
