import moment from "moment";

import {
  ADD_COMMANDE_FAILED,
  ADD_COMMANDE_SUCCESS,
  GET_COMMANDES_FAILED,
  GET_COMMANDES_SUCCESS,
  LOADING_COMMANDES,
  LOADING_ADD_COMMANDE,
  REMOVE_COMMANDE_SUCCESS,
  SET_COMMANDE,
  UNSET_COMMANDE,
  UPDATE_COMMANDE_FAILED,
  UPDATE_COMMANDE_SUCCESS,
  UPDATE_FIELDS_COMMANDE,
} from "../ActionsType/commandeTypes";
import { transformGraphObject, transformObject } from "../../Utils/utils";

const initialState = {
  loading: false,
  loadingAddCommande: false,
  transformedArrays: [],
  commandes: [],
  error: undefined,
  selectedCommande: {},
};

const commandeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_COMMANDES:
      return {
        ...state,
        loading: false,
      };
    case GET_COMMANDES_SUCCESS:
      const transformedArray = payload.data.map((item) => {
        const transformedItem = {
          id: item?.id,
          origin: item?.attributes?.origin,
          status: item?.attributes?.status,
          note: item?.attributes?.note,
          date: moment(item?.attributes?.createdAt).format("DD.MM.YYYY"),
          archived: item?.attributes?.archived,
          actors: item.attributes?.acteurs?.data,
          actorsId: item.attributes?.acteurs?.data?.map((el) => {
            return +el?.id;
          }),
          actor:
            item.attributes?.acteurs &&
            item.attributes?.acteurs?.data[0]?.attributes?.workIn?.data[0]
              ?.attributes?.name,
        };

        item?.attributes?.prospect_fields?.data.forEach((dataItem) => {
          const question = dataItem?.attributes?.question?.data;
          const accessor = question?.attributes?.accessor;

          if (accessor) {
            transformedItem[accessor] = dataItem?.attributes?.value;
          }
        });

        return transformedItem;
      });
      return {
        ...state,
        commandes:
          payload.page === 1
            ? payload.data
            : [...state.commandes, ...payload.data],
        transformedArrays:
          payload.page === 1
            ? transformedArray
            : [...state.transformedArrays, ...transformedArray],
        loading: false,
      };

    case UPDATE_COMMANDE_SUCCESS:
      return {
        ...state,
        loading: false,
        commandes: state.commandes.map((prospect) =>
          +prospect.id === +payload.data.id ? payload : prospect
        ),
        transformedArrays: state.transformedArrays.map((el) =>
          +el.id === +payload.data.id
            ? payload.withGraph
              ? transformGraphObject(payload.data)
              : transformObject(payload.data)
            : el
        ),
        selectedCommande: payload.withGraph
          ? transformGraphObject(payload.data)
          : transformObject(payload.data),
      };

    case UPDATE_COMMANDE_FAILED:
      return { ...state, loading: false, error: payload };

    case GET_COMMANDES_FAILED:
      return { ...state, loading: false, error: payload };
    case SET_COMMANDE:
      return { ...state, selectedCommande: payload };
    case UNSET_COMMANDE:
      return { ...state, selectedCommande: {} };

    case REMOVE_COMMANDE_SUCCESS:
      return {
        ...state,
        loading: false,
        commandes: state.commandes.filter((item) => +item.id !== +payload),
        transformedArrays: state.transformedArrays.filter(
          (item) => +item.id !== +payload
        ),
      };

    case LOADING_ADD_COMMANDE:
      return { ...state, loadingAddCommande: true };
    case ADD_COMMANDE_SUCCESS:
      return {
        ...state,
        loadingAddCommande: false,
        commandes: [payload, ...state.commandes],
        transformedArrays: [
          transformGraphObject(payload),
          ...state.transformedArrays,
        ],
      };
    case ADD_COMMANDE_FAILED:
      return { ...state, loadingAddCommande: false };

    case UPDATE_FIELDS_COMMANDE:
      return {
        ...state,
        commandes: state.commandes.map((prospect) =>
          +prospect?.id === +payload?.id ? payload : prospect
        ),
        transformedArrays: state.transformedArrays.map((el) =>
          +el.id === +payload.id ? transformGraphObject(payload) : el
        ),
      };

    default:
      return state;
  }
};

export default commandeReducer;
