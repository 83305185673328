import { closeModal } from "../../REDUX/actions/modal";
import { toggleUserArchive } from "../../REDUX/actions/operateurs";
import { handleFirstLastName } from "../../Utils/utils";

export const userModalData = (user, dispatch) => {
  const userOrg = user.attributes?.user_org_permissions?.data
    ? user.attributes?.user_org_permissions?.data[0]?.attributes
    : user.attributes.user_org_permissions[0];
  const role = userOrg.role === 1 || userOrg.role === 2 ? "operator" : "client";

  return {
    callBack: () => {
      dispatch(toggleUserArchive(user?.id, userOrg.archived, role));
      dispatch(closeModal());
    },
    message: userOrg.archived
      ? `Êtes-vous sur de vouloir désarchiver cet utilisateur?`
      : `Êtes-vous sur de vouloir archiver l'utilisateur?`,
    message2: `#${user.id} ${handleFirstLastName(user)}`,
    confirmButtonText: userOrg.archived
      ? "Désarchiver l'utilisateur"
      : "Archiver l'utilisateur",
  };
};
