import { populateUser } from "../../Utils/utils";

const initialState = {
  interventions: [],
  interventionLoading: false,
  intervention: null,
  interventionSideBar: false,
};

const interventionsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "INTERVENTIONS_LOADING":
      return { ...state, interventionLoading: true };
    case "INTERVENTION_CREATED":
      return {
        ...state,
        interventions: [
          {
            ...payload,
            attributes: {
              ...payload.attributes,
              stringTicketId: payload?.attributes?.ticket?.data?.id,
              stringTicketName:
                payload?.attributes?.ticket?.data?.attributes?.name,
              stringSociety:
                payload?.attributes?.societe?.data?.attributes?.name,
              stringCreatedAt: payload?.attributes?.oldCreatedAt
                ? payload?.attributes?.oldCreatedAt
                : payload?.attributes?.createdAt,
            },
          },
          ...state.interventions,
        ],
      };
    case "INTERVENTION_UPDATED": {
      const updatedIntervention = {
        ...payload,
        attributes: {
          ...payload.attributes,
          stringTicketId: payload?.attributes?.ticket?.data?.id,
          stringTicketName: payload?.attributes?.ticket?.data?.attributes?.name,
          stringSociety: payload?.attributes?.societe?.data?.attributes?.name,
          stringCreatedAt: payload?.attributes?.oldCreatedAt
            ? payload?.attributes?.oldCreatedAt
            : payload?.attributes?.createdAt,
        },
      };
      return {
        ...state,
        intervention: updatedIntervention,
        interventions: state.interventions.map((intervention) =>
          +intervention.id === +payload.id ? updatedIntervention : intervention
        ),
      };
    }
    case "DELETE_INTERVENTION":
      return {
        ...state,
        interventions: state.interventions.filter(
          (item) => item.id !== payload
        ),
        interventionLoading: false,
      };
    case "SHOW_INTERVENTION_SIDEBAR":
      return {
        ...state,
        interventionSideBar: true,
        intervention: {
          ...payload,
          attributes: {
            ...payload.attributes,
            creator: {
              ...payload?.attributes?.creator,
              data: {
                ...payload?.attributes?.creator?.data,
                attributes: {
                  ...payload?.attributes?.creator?.data?.attributes,
                  ...populateUser(payload.attributes.creator?.data, true, 2),
                },
              },
            },
            actions: payload?.attributes?.actions?.data?.map((action) => {
              return {
                ...action,
                attributes: {
                  ...action?.attributes,
                  by: {
                    ...action?.attributes?.data?.by,
                    data: {
                      ...action?.attributes?.by?.data,
                      attributes: {
                        ...action?.attributes?.by?.data?.attributes,
                        ...populateUser(action?.attributes?.by?.data),
                      },
                    },
                  },
                },
              };
            }),
          },
        },
      };
    case "FETCH_ALL_INTERVENTIONS":
      let interventions = payload.map((intervention) => {
        return {
          ...intervention,
          attributes: {
            ...intervention.attributes,
            stringTicketId: intervention?.attributes?.ticket?.data?.id,
            stringTicketName:
              intervention?.attributes?.ticket?.data?.attributes?.name,
            stringSociety:
              intervention?.attributes?.societe?.data?.attributes?.name,
            stringCreatedAt: intervention?.attributes?.oldCreatedAt
              ? intervention?.attributes?.oldCreatedAt
              : intervention?.attributes?.createdAt,
          },
        };
      });
      return {
        ...state,

        interventions: interventions,
        interventionLoading: false,
      };
    case "FETCH_NEW_INTERVENTION":
      return {
        ...state,
        interventions: [
          {
            ...payload,
            attributes: {
              ...payload.attributes,
              stringTicketId: payload?.attributes?.ticket?.data?.id,
              stringTicketName:
                payload?.attributes?.ticket?.data?.attributes?.name,
              stringSociety:
                payload?.attributes?.societe?.data?.attributes?.name,
              stringCreatedAt: payload?.attributes?.oldCreatedAt
                ? payload?.attributes?.oldCreatedAt
                : payload?.attributes?.createdAt,
            },
          },
          ...state.interventions,
        ],
      };
    case "FETCH_UPDATED_INTERVENTION": {
      const updatedIntervention = {
        ...payload,
        attributes: {
          ...payload.attributes,
          stringTicketId: payload?.attributes?.ticket?.data?.id,
          stringTicketName: payload?.attributes?.ticket?.data?.attributes?.name,
          stringSociety: payload?.attributes?.societe?.data?.attributes?.name,
          stringCreatedAt: payload?.attributes?.oldCreatedAt
            ? payload?.attributes?.oldCreatedAt
            : payload?.attributes?.createdAt,
        },
      };
      return {
        ...state,
        interventions: state.interventions?.map((intervention) => {
          return +intervention.id === +updatedIntervention.id
            ? updatedIntervention
            : intervention;
        }),
        intervention:
          updatedIntervention?.id === state.intervention?.id
            ? updatedIntervention
            : state.intervention,
      };
    }
    default:
      return state;
  }
};
export default interventionsReducer;
