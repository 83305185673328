export const clientRoles = [
  {
    value: 3,
    label: "Administrateur",
  },
  {
    value: 4,
    label: "Collaborateur",
  },
  {
    value: 5,
    label: "Invité",
  },
];

export const operatorRoles = [
  {
    value: 2,
    label: "Opérateur",
  },
  {
    value: 1,
    label: "Superviseur",
  },
];

export const genres = [
  {
    value: 1,
    label: "Homme",
  },
  {
    value: 2,
    label: "Femme",
  },
  {
    value: 3,
    label: "Non spécifié",
  },
];
