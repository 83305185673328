import React from "react";
import QuestionContainer from "./QuestionContainer";
import RadioInput from "./RadioInput";
import TextAreaInput from "./TextAreaInput";
import LightDarkInput from "../Common/LightDarkInput";
import LightDarkText from "../Common/LightDarkText";
import { answerData, boolData, mapDepData } from "../../Utils/utils";
import Select from "./Select";
import { useDispatch, useSelector } from "react-redux";
import { imageEndPoint } from "../../Api/endPoint";
import CheckBoxInput from "./CheckBoxInput";
import MultiSelect from "./MultiSelect";
import { useEffect } from "react";
import { GetAllDepatements } from "../../REDUX/actions/departement";
import FileUploader from "./FileUploader";
import CustomRange from "../Common/CustomRange";

const QuestionItem = ({
  item,
  isForm,
  formData,
  setFormData,
  validationErrors,
}) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.genearlReducer);
  const { departments } = useSelector((state) => state.departmentReducer);
  useEffect(() => {
    dispatch(GetAllDepatements());
  }, []);

  return (
    <QuestionContainer
      col={item?.attributes?.col}
      question={item}
      isForm={isForm}
    >
      {item?.attributes?.input_type === "title" && (
        <>
          <LightDarkText
            color="text-light-blue"
            className={"ms-2 bold f18 text-start"}
          >
            {item?.attributes?.name}
          </LightDarkText>
          {item?.attributes?.legend && (
            <LightDarkText
              className={"ms-2 f12 text-start"}
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
            >
              {item?.attributes?.legend}
            </LightDarkText>
          )}
        </>
      )}
      {item?.attributes?.input_type === "subtitle" && (
        <>
          <LightDarkText
            color="text-light-blue"
            className={`${item?.attributes?.label_class} ms-2 text-start`}
          >
            {item?.attributes?.name}
          </LightDarkText>
          {item?.attributes?.legend && (
            <LightDarkText
              className={"ms-2 f12 text-start"}
              color={mode === "light" ? "text-blue-grey" : "text-light-text"}
            >
              {item?.attributes?.legend}
            </LightDarkText>
          )}
        </>
      )}
      {item?.attributes?.input_type === "boolean" && (
        <>
          <div className="ms-2 ">
            <Select
              data={boolData}
              label={item?.attributes?.name}
              extraCss={item?.attributes?.label_class}
              bgWhite={!isForm}
              link={item?.attributes?.link}
              position="absolute"
              totalInput={formData}
              setValue={setFormData}
              champs={`question-${item?.id}`}
              required={item?.attributes?.is_required}
            />
            {validationErrors[`question-${item?.id}`] && (
              <span className="field-error f11  mt-1 text-red d-flex">
                {validationErrors[`question-${item?.id}`]}
              </span>
            )}
          </div>
        </>
      )}
      {item?.attributes?.input_type === "description" && (
        <>
          <LightDarkText
            className={"ms-2 f14 text-start"}
            color={mode === "light" ? "text-blue-grey" : "text-white"}
          >
            {item?.attributes?.name}
          </LightDarkText>
        </>
      )}
      {item?.attributes?.input_type === "radio" && (
        <div className="ms-2 me-2">
          <RadioInput
            data={answerData(item?.attributes?.answers?.data)}
            label={item?.attributes?.name}
            required={item?.attributes?.is_required}
            extraCss={item?.attributes?.label_class}
            link={item?.attributes?.link}
            legend={item?.attributes?.legend}
            bgWhite={!isForm}
            totalInputs={formData}
            setValue={setFormData}
            champs={`question-${item?.id}`}
            col={item?.attributes?.col}
          />
          {validationErrors[`question-${item?.id}`] && (
            <span className="field-error f11  mt-1 text-red d-flex">
              {validationErrors[`question-${item?.id}`]}
            </span>
          )}
        </div>
      )}
      {item?.attributes?.input_type === "checkbox" && (
        <div className="ms-2 me-2">
          <CheckBoxInput
            data={answerData(item?.attributes?.answers?.data)}
            label={item?.attributes?.name}
            required={item?.attributes?.is_required}
            link={item?.attributes?.link}
            extraCss={item?.attributes?.label_class}
            totalInputs={formData}
            setValue={setFormData}
            champs={`question-${item?.id}`}
            bgWhite={!isForm}
            col={item?.attributes?.col}
          />
          {validationErrors[`question-${item?.id}`] && (
            <span className="field-error f11  mt-1 text-red d-flex">
              {validationErrors[`question-${item?.id}`]}
            </span>
          )}
        </div>
      )}

      {item?.attributes?.input_type === "select" && (
        <div className="ms-2 me-3">
          <Select
            data={answerData(item?.attributes?.answers?.data)}
            label={item?.attributes?.name}
            bgWhite={!isForm}
            extraCss={item?.attributes?.label_class}
            required={item?.attributes?.is_required}
            link={item?.attributes?.link}
            position="absolute"
            champs={`question-${item?.id}`}
            totalInput={formData}
            setValue={setFormData}
          />
          {validationErrors[`question-${item?.id}`] && (
            <span className="field-error f11  mt-1 text-red d-flex">
              {validationErrors[`question-${item?.id}`]}
            </span>
          )}
        </div>
      )}
      {item?.attributes?.input_type === "departement" && (
        <div className="ms-2 me-3">
          <Select
            data={mapDepData(departments)}
            label={item?.attributes?.name}
            bgWhite={!isForm}
            extraCss={item?.attributes?.label_class}
            required={item?.attributes?.is_required}
            link={item?.attributes?.link}
            position="absolute"
            placeholder={"Sélectionner un département"}
            champs={`question-${item?.id}`}
            totalInput={formData}
            setValue={setFormData}
          />
          {validationErrors[`question-${item?.id}`] && (
            <span className="field-error f11  mt-1 text-red d-flex">
              {validationErrors[`question-${item?.id}`]}
            </span>
          )}
        </div>
      )}
      {item?.attributes?.input_type === "multi_select" && (
        <div className="ms-2 me-3">
          <MultiSelect
            data={answerData(item?.attributes?.answers?.data)}
            label={item?.attributes?.name}
            bgWhite={!isForm}
            link={item?.attributes?.link}
            extraCss={item?.attributes?.label_class}
            champs={`question-${item?.id}`}
            totalInput={formData}
            setValue={setFormData}
            required={item?.attributes?.is_required}
          />
          {validationErrors[`question-${item?.id}`] && (
            <span className="field-error f11  mt-1 text-red d-flex">
              {validationErrors[`question-${item?.id}`]}
            </span>
          )}
        </div>
      )}
      {item?.attributes?.input_type === "textarea" && (
        <div className="ms-2">
          <TextAreaInput
            label={item?.attributes?.name}
            required={item?.is_required}
            bgWhite={!isForm}
            link={item?.attributes?.link}
            champs={`question-${item?.id}`}
            totalInput={formData}
            setValue={setFormData}
            placeholder={item?.attributes?.name}
          />
          {validationErrors[`question-${item?.id}`] && (
            <span className="field-error f11  mt-1 text-red d-flex">
              {validationErrors[`question-${item?.id}`]}
            </span>
          )}
        </div>
      )}
      {item?.attributes?.input_type === "image" && (
        <div className="ms-2">
          <LightDarkText
            className={"text-start bold custom-ticket-font-size mb-2"}
            darkModeText={mode === "light"}
          >
            {item?.attributes?.name}
            <div></div>
          </LightDarkText>
          <img
            src={imageEndPoint + item?.attributes?.image?.data?.attributes?.url}
            alt=""
            loading="lazy"
            className=" ob-cover"
            style={{ maxWidth: "100%" }}
          />
        </div>
      )}
      {item?.attributes?.input_type === "file" && (
        <div className="ms-2">
          <FileUploader
            id={item?.id}
            label={item?.attributes?.name}
            required={item?.attributes?.is_required}
            champs={`question-${item?.id}`}
            totalInputs={formData}
            setValue={setFormData}
          />
          {formData[`question-${item?.id}`] && (
            <LightDarkText className="f10 mt-1 text-start bold">
              {formData[`question-${item?.id}`][0]?.name
                ? formData[`question-${item?.id}`][0]?.name
                : formData[`question-${item?.id}`]}
            </LightDarkText>
          )}
          {validationErrors[`question-${item?.id}`] && (
            <span className="field-error f11  mt-1 text-red d-flex">
              {validationErrors[`question-${item?.id}`]}
            </span>
          )}
        </div>
      )}
      {item?.attributes?.input_type === "number_range" && (
        <div className="ms-2">
          <LightDarkInput
            min={parseInt(
              item?.attributes?.answers?.data[0]?.attributes?.answer
            )}
            max={parseInt(
              item?.attributes?.answers?.data[1]?.attributes?.answer
            )}
            label={item?.attributes?.name}
            placeholder={item?.attributes?.legend || item?.attributes?.name}
            required={item?.attributes?.is_required}
            bgWhite={!isForm}
            type={"number"}
            link={item?.attributes?.link}
            champs={`question-${item?.id}`}
            totalInputs={formData}
            setValue={setFormData}
            value={formData[`question-${item?.id}`]}
            label_class={item?.attributes?.label_class}
          />
          {/* <LightDarkInput
            
            type="number"
            totalInputs={formData}
            champs={`question-${item?.id}`}
            setValue={setFormData}
            
          /> */}
          {/* <CustomRange
            min={parseInt(
              item?.attributes?.answers?.data[0]?.attributes?.answer
            )}
            max={parseInt(
              item?.attributes?.answers?.data[1]?.attributes?.answer
            )}
            label={item?.attributes?.name}
            totalInputs={formData}
            setValue={setFormData}
            champs={`question-${item?.id}`}
            editbundle={true}
            unit={"mm"}
            bold
            isString
            step={1}
          /> */}
        </div>
      )}

      {["text", "date", "number"].includes(item?.attributes?.input_type) && (
        <div className="ms-2 ">
          <LightDarkInput
            label={item?.attributes?.name}
            placeholder={item?.attributes?.legend || item?.attributes?.name}
            required={item?.attributes?.is_required}
            bgWhite={!isForm}
            type={item?.attributes?.input_type}
            link={item?.attributes?.link}
            champs={`question-${item?.id}`}
            totalInputs={formData}
            setValue={setFormData}
            value={formData[`question-${item?.id}`]}
            label_class={item?.attributes?.label_class}
          />
          {validationErrors[`question-${item?.id}`] && (
            <span className="field-error f11  mt-1 text-red d-flex">
              {validationErrors[`question-${item?.id}`]}
            </span>
          )}
        </div>
      )}
    </QuestionContainer>
  );
};

export default QuestionItem;
