import { useSelector } from "react-redux";

import LightDarkText from "../../../../../Common/LightDarkText";

import { showDateMessage } from "../../../../ticketUtils";

const MessageDate = ({ message }) => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <div className="alignC my-4">
      <LightDarkText
        className={`radius05 px-4 py-2 custom-ticket-font-size bold light-message ${
          mode === "light" ? "bg-light-mode" : "bg-dark-message"
        }`}
        color={mode === "light" ? "text-blue-grey" : "text-light-text"}
      >
        {showDateMessage(message)}
      </LightDarkText>
    </div>
  );
};

export default MessageDate;
