import { gql } from "@apollo/client";
import { ATTRIBUTES_DEPARTEMENT } from "../Attributes/Departements";

export const GET_DEPARTEMENS = gql`
  query {
    departements(
      pagination: { page: 1, pageSize: 10000 }
      sort: ["createdAt:asc"]
      ) {
      data {
        id
        attributes {
          ${ATTRIBUTES_DEPARTEMENT}
        }
      }
    }
  }
`;
