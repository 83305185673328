import React from "react";
import ThumbnailPlaceHolder from "../../PlaceHolders/ThumbnailPlaceHolder";
import LightDarkPlaceHolder from "../../PlaceHolders/LightDarkPlaceHolder";

const NotificationsPlaceHolder = () => {
  return (
    <>
      {Array.from({ length: 30 }).map((_, index) => (
        <div className="alignH gap-3 p-4" key={index}>
          <ThumbnailPlaceHolder smallThumbnail={false} />
          <div className="flex-1 d-flex flex-column gap-1">
            <LightDarkPlaceHolder customClass="w100" height={12} />
            <LightDarkPlaceHolder customClass="w100" height={12} />
            <LightDarkPlaceHolder customClass="w20" height={12} />
          </div>
        </div>
      ))}
    </>
  );
};

export default NotificationsPlaceHolder;
