import React, { useState } from "react";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import oneDriveLogo from "../../../Images/apiIcons/sharePoint.svg";
import { EyeIcon } from "../../Common/LightDarkSvg";
import TooltipActions from "../../Common/Tooltip/TooltipActions";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../Utils/utils";
import ElementName from "../CloudPartials/ElementName";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";

const SharePointSiteCard = ({ site }) => {
  const navigate = useNavigate();
  const [showTooltip, setshowTooltip] = useState(false);
  const { organisationUrl } = useSelector(
    (state) => state.organisationsReducer
  );
  const tooltipData = [
    {
      id: 1,
      text: "Voir",
      icon: <EyeIcon className="me-2" size={24} />,
      action: () => navigate(`${organisationUrl}/sharepointsite/${site.id}`),
      condition: true,
    },
  ];
  return (
    <LightDarkContainer
      colorDark="dark"
      colorLight="white"
      className={`folderCard position-relative d-flex flex-column justify-content-between  radius05 t-02 position-relative`}
    >
      <div className="position-relative d-flex justify-content-between align-items-start m-3">
        <img
          src={oneDriveLogo}
          className="pointer"
          alt="oneDrive"
          width={40}
          onClick={() =>
            navigate(`${organisationUrl}/sharepointsite/${site.id}`)
          }
        />
        <TooltipActions
          setshowTooltip={setshowTooltip}
          showTooltip={showTooltip}
          tooltipData={tooltipData}
        />
      </div>
      <div className="alignH justify-content-between m-3">
        <div className="d-flex flex-column align-items-start text-start pointer w-100">
          <ElementName
            name={site.displayName}
            callBack={() =>
              navigate(`${organisationUrl}/sharepointsite/${site.id}`)
            }
          />
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{site.description}</Tooltip>}
          >
            <div className={"f10 bold text-ellipsis w-100"}>
              {site.description || ""}
            </div>
          </OverlayTrigger>

          <LightDarkText className={"f10 text-light-text"}>
            Créé le : {formatDate(site.createdDateTime)}
          </LightDarkText>
        </div>
      </div>
    </LightDarkContainer>
  );
};

export default SharePointSiteCard;
