import React from "react";
import SwitchItem from "./SwitchItem";

const NotificationManagementList = ({ role, values, list, onChange }) => {
  return (
    <div className="row">
      {list.map(
        (item, index) =>
          (item?.roles === "all" || item?.roles?.includes(role)) &&
          item.isVisible && (
            <div className="col-6 mb-3 pb-2" key={index}>
              <SwitchItem
                {...item}
                value={!!values[item?.name]}
                onChange={onChange}
              />
            </div>
          )
      )}
    </div>
  );
};

export default NotificationManagementList;
