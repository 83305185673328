import React, { useState } from "react";
import emptyAgilix from "../../Images/emptyAgilix.png";
import LightDarkContainer from "./LightDarkContainer";
import LightDarkText from "./LightDarkText";
import { useDispatch, useSelector } from "react-redux";
import TicketForm from "../Tickets/TicketForm/TicketForm";
import { openModal } from "../../REDUX/actions/modal";
import ColoredButton from "./Buttons/ColoredButton";
import InterventionForm from "../Interventions/InterventionForm/InterventionForm";
import UserForm from "../Clients/UserForm/UserForm";
import SocietyForm from "../Societies/SocietyForm/SocietyForm";
import CalendarForm from "../Calendar/CalendarForms/CalendarForm";
import { useLocation } from "react-router-dom";
import DiffusionForm from "../Diffusion/DiffusionForm";
import { openSideBar } from "../../REDUX/actions/sideBar";
import FaqForm from "../Faqs/FaqForm/FaqForm";

const EmptyList = ({ type = "ticket", showButton = true }) => {
  const {
    canCreateTickets,
    canCreateInterventions,
    canCreateCollabs,
    canCreateOperators,
    canCreateSociety,
    isSupervisor,
    isOperator,
    canCreateDiffusion,
  } = useSelector((state) => state.roleReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const { faqsCategories } = useSelector((state) => state.faqReducer);

  const { dashboardRoute } = useSelector((state) => state.organisationsReducer);
  const [small] = useState(dashboardRoute === location.pathname);
  const [clientName] = useState(
    isSupervisor || isOperator ? "client" : "collaborateur"
  );
  const empltyListType = {
    ticket: {
      title: "Aucun ticket trouvé",
      description:
        "Le ticket recherché est introuvable. Veuillez vérifier la formulation ou créer un nouveau ticket",
      buttonText: "Créer un nouveau ticket",
      showButton: showButton && canCreateTickets,
      buttonCallback: () =>
        dispatch(
          openModal({
            type: "small",
            content: <TicketForm isNew={true} />,
          })
        ),
    },
    intervention: {
      title: "Aucune intervention trouvée",
      description:
        "L'intervention recherchée est introuvable. Veuillez vérifier la formulation ou créer une nouvelle intervention",
      buttonText: "Créer une nouvelle intervention",
      showButton: showButton && canCreateInterventions,
      buttonCallback: () =>
        dispatch(
          openModal({
            type: "small",
            preventClose: true,
            content: <InterventionForm isNew={true} />,
          })
        ),
    },
    client: {
      title: `Aucun ${clientName} trouvé`,
      description: `Le ${clientName} recherché est introuvable. Veuillez vérifier la formulation ou ajouter un ${clientName} `,
      buttonText: `Ajouter un ${clientName}`,
      showButton: showButton && canCreateCollabs,
      buttonCallback: () =>
        dispatch(
          openSideBar({
            content: <UserForm isNew={true} isOp={false} />,
          })
        ),
    },
    operator: {
      title: "Aucun opérateur trouvé",
      description:
        "L'opérateur recherché est introuvable. Veuillez vérifier la formulation ou ajouter un nouvel opérateur",
      buttonText: "Ajouter un nouvel opérateur",
      showButton: showButton && canCreateOperators,
      buttonCallback: () =>
        dispatch(
          openSideBar({
            content: <UserForm isNew={true} isOp={true} />,
          })
        ),
    },
    societe: {
      title: "Aucune société trouvée",
      description:
        "La société recherchée est introuvable. Veuillez vérifier la formulation ou ajouter une nouvelle société",
      buttonText: "Ajouter une nouvelle société",
      showButton: showButton && canCreateSociety,
      buttonCallback: () =>
        dispatch(
          openSideBar({
            content: <SocietyForm isNew />,
            title: "Nouvelle société",
            type: "w40",
            isToggle: "isToggle value",
          })
        ),
    },
    societeArchived: {
      title: "Aucune société archivée trouvée",
    },
    calendar: {
      title: "Aucun calendrier trouvé",
      description:
        "Le calendrier recherché est introuvable. Veuillez vérifier la formulation ou ajouter un nouveau calendrier",
      buttonText: "Ajouter un nouveau calendrier",
      showButton: showButton,
      buttonCallback: () =>
        dispatch(
          openModal({
            type: "small",
            content: <CalendarForm isNew />,
          })
        ),
    },
    pj: {
      title: "Aucune pièce jointe trouvée",
      description:
        "La pièce jointe recherchée est introuvable. Veuillez vérifier la formulation",

      showButton: false,
    },
    services: {
      title: "Aucun service externe trouvé",
      description: "",

      showButton: false,
    },
    diffusion: {
      title: "Aucune diffusion trouvée",
      description: "",
      buttonCallback: () =>
        dispatch(
          openModal({
            type: "small",
            content: <DiffusionForm />,
          })
        ),
      buttonText: "Ajouter une diffusion",
      showButton: canCreateDiffusion,
    },
    notifications: {
      title: "Vous n'avez aucune notification",

      showButton: false,
    },
    faq: {
      title: "Aucune FAQ trouvée",
      description:
        "Aucune FAQ n'a été créée. Vous pouvez en créer une nouvelle.",
      buttonText: "Créer un élément",
      showButton: showButton && isSupervisor,
      buttonCallback: () =>
        dispatch(
          openModal({
            type: "small",
            content: (
              <FaqForm
                categories={faqsCategories.map((el) => ({
                  value: el.id,
                  label: el.attributes.name,
                }))}
              />
            ),
          })
        ),
    },
  };

  return (
    <LightDarkContainer colorLight="white" className={`h100 alignC w-100`}>
      <div className={`alignC flex-column text-center gap-3 f26`}>
        <img src={emptyAgilix} alt="agilix" width={small ? 80 : 250} />
        <LightDarkText className={`${small ? "f18" : "f28"} xbold`}>
          {empltyListType[type].title}
        </LightDarkText>
        {empltyListType[type].description && (
          <LightDarkText className={`${small ? "w80 f12" : "w60 f16"}  `}>
            {empltyListType[type].description}
          </LightDarkText>
        )}
        {empltyListType[type].showButton && (
          <div className="w70" style={{ maxWidth: "300px" }}>
            <ColoredButton
              textButton={empltyListType[type].buttonText}
              icon={"plus"}
              height="40"
              color="light-blue"
              callback={empltyListType[type].buttonCallback}
            />
          </div>
        )}
      </div>
    </LightDarkContainer>
  );
};

export default EmptyList;
