import React from "react";
import LightDarkContainer from "./LightDarkContainer";
import MenuTabs from "./MenuTabs";

const MultiTabsMenu = ({
  allTabs,
  isToggle,
  setIsToggle,
  fontSize = "f12",
  borderPosition = 13,
  align = "between",
  gap = "gap-4",
  px = "px-4",
}) => {
  return (
    <LightDarkContainer
      colorLight="white"
      colorDark="dark-grey-bg"
      className={`d-flex justify-content-${align} ${gap} ${px} w-100 align-items-center menuHeaderTabs`}
      style={{ height: "50px", overflowX: "scroll", whiteSpace: "nowrap" }}
    >
      {allTabs
        .filter((tab) => tab.isVisible && !tab.hideTab)
        .map((tab) => (
          <div key={tab.toggleValue}>
            <MenuTabs
              tabTitle={tab.title}
              toggleValue={tab.toggleValue}
              isToggle={isToggle}
              isVisible={tab.isVisible}
              setIsToggle={() => setIsToggle(tab.toggleValue)}
              fontSize={fontSize}
              borderPosition={borderPosition}
            />
          </div>
        ))}
    </LightDarkContainer>
  );
};

export default MultiTabsMenu;
