import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSocietyCalendars } from "../../../../REDUX/actions/societe";
import SocietyCalendarPlaceholder from "../../../PlaceHolders/Society/SocietyCalendarPlaceholder";
import CalendarView from "../../../../Views/CalendarView/CalendarView";

const SocietyCalendar = () => {
  const dispatch = useDispatch();

  const { societyItemLoading, societe } = useSelector(
    (state) => state.societesReducer
  );

  useEffect(() => {
    dispatch(getSocietyCalendars());
  }, [societe?.id]);
  return (
    <div className="h100 w100 notScrollable">
      {" "}
      {societyItemLoading ? (
        <SocietyCalendarPlaceholder />
      ) : (
        <div className="w-100 scrollable">
          <CalendarView />
        </div>
      )}
    </div>
  );
};

export default SocietyCalendar;
