import React from "react";
import LightDarkPlaceHolder from "../LightDarkPlaceHolder";

const SocietyCalendarPlaceholder = () => {
  return (
    <div className="d-flex flex-column gap-2">
      <LightDarkPlaceHolder width={"100%"} height={60} />
      <LightDarkPlaceHolder width={"100%"} height={"80vh"} />
    </div>
  );
};

export default SocietyCalendarPlaceholder;
