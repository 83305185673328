import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import logoIconColor from "../../../Images/org_agilix.svg";
import LightDarkContainer from "../../Common/LightDarkContainer";
import "./OrganisationBar.css";
import plusLogo from "../../../Images/org/add.svg";
import BottomDrawer from "./BottomDrawer";
import OrganisationsList from "./OrganisationsList";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LightDarkText from "../../Common/LightDarkText";

const OrganisationBar = () => {
  const { mode } = useSelector((state) => state.genearlReducer);

  return (
    <LightDarkContainer
      className={`OrgBarContainer alignH flex-column ${
        mode === "light" && "lightOrgBarContainer"
      }`}
      colorLight="white"
    >
      <div className="mt-2">
        <Link to={"/"}>
          <img
            className="align-self-center"
            src={logoIconColor}
            alt="logo-icon"
            width={48}
          />
        </Link>
        <OrganisationsList />
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip style={{ zIndex: 3 }}>
              <LightDarkText className="custom-font-size py-2 text-white">
                Création de votre espace de travail prochainement disponible
              </LightDarkText>
            </Tooltip>
          }
        >
          <div className={`organisationIcon pointer alignC my-4`}>
            <img
              className="orgLogo"
              src={plusLogo}
              alt={"Ajouter votre espace"}
            />
          </div>
        </OverlayTrigger>
      </div>
      <BottomDrawer />
    </LightDarkContainer>
  );
};

export default OrganisationBar;
