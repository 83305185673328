import React from "react";
import { useSelector } from "react-redux";

import { SearchIcon } from "./LightDarkSvg";

import useWindowWidth from "../../Hooks/useWindowWidth";

const SearchInput = ({
  type = "text",
  value,
  placeholder = "Search...",
  calendarUsers = false,
  onChange,
  width,
}) => {
  const { mode } = useSelector((state) => state.genearlReducer);
  const screenWidth = useWindowWidth();

  return (
    <label
      className={`d-flex align-items-center w-100 radius05 h100 ${
        calendarUsers ? "ps-2" : "ps-4"
      }
  
       ${
         mode === "light" && !calendarUsers
           ? "lightSearchContainer"
           : mode === "light" && calendarUsers
           ? "bg-light-mode"
           : "darkSearchContainer"
       }`}
    >
      <SearchIcon size={screenWidth < 1200 ? 18 : 22} />
      <form
        className="alignH"
        autoComplete="new-password"
        style={{ width: "100%" }}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          autoComplete="nope"
          type={type}
          placeholder={placeholder}
          value={value}
          className={`${
            mode === "light" && !calendarUsers
              ? "lightSearchInput"
              : mode === "light" && calendarUsers
              ? "bg-light-mode"
              : "darkSearchInput"
          } listSearchinput text-start h100 ${
            width ? width : "w50"
          } radius05 custom-font-size ps-2`}
          onChange={onChange}
          style={{ width: "100%" }}
        />
      </form>
    </label>
  );
};

export default SearchInput;
