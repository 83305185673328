import React from "react";
import ColoredButton from "../Common/Buttons/ColoredButton";
import { archiveMultipleProspect } from "../../REDUX/actions/prospect";
import { useDispatch } from "react-redux";
import { openConfirmationModal } from "../../REDUX/actions/confirmationModal";

const MultipleArchiveButton = ({
  selectedItems,
  setSelectedItems,
  archived,
  type,
}) => {
  const dispatch = useDispatch();
  const singleName =
    type === "prospects" ? "prospect" : type === "devis" ? "devis" : "commande";
  const isFeminine = type === "commandes";
  const handleArchiveAll = () => {
    if (["prospects", "devis", "commandes"].includes(type)) {
      dispatch(
        openConfirmationModal({
          callBack: () => {
            dispatch(archiveMultipleProspect(selectedItems, archived, type));
            setSelectedItems([]);
          },
          message: `Êtes-vous sur de vouloir ${
            archived ? "désarchiver" : "archiver"
          } ${
            selectedItems.length === 0
              ? `${isFeminine ? "toutes" : "tous"} les ${type}`
              : `${selectedItems.length} ${
                  selectedItems.length === 1 ? singleName : type
                }`
          }`,
          message2: ``,
          confirmButtonText: "Archiver",
        })
      );
    }
  };

  return (
    <div className="col-1 ms-1 fitContent h100">
      <ColoredButton
        height="100"
        textButton={
          selectedItems.length === 0
            ? `${archived ? "Désarchiver tout" : "Archiver tout"}`
            : selectedItems.length === 1
            ? `${archived ? "Désarchiver le fichier" : "Archiver le fichier"}`
            : `${
                archived ? "Désarchiver les fichiersr" : "Archiver les fichiers"
              }`
        }
        color="orange"
        callback={handleArchiveAll}
      />
    </div>
  );
};

export default MultipleArchiveButton;
