import JSZip from "jszip";
import { fileUrl, formatDate } from "../../Utils/utils";
import { saveAs } from "file-saver";
import moment from "moment";

export const extractPreviewData = (fichier) => {
  const date = fichier?.attributes?.file.data?.attributes?.createdAt;
  const mime = fichier?.attributes?.file.data?.attributes?.mime;
  const url = fileUrl(fichier?.attributes?.file.data?.attributes.url);
  const name = fichier?.attributes?.file?.data?.attributes?.name;

  return { date, mime, url, name };
};

export const downloadFilesAsZip = async (files) => {
  const zip = new JSZip();

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    let url = file.url;
    let fileName = file.name;

    const response = await fetch(url);
    const data = await response.blob();
    zip.file(fileName, data);
  }

  const zipContent = await zip.generateAsync({ type: "blob" });

  saveAs(zipContent, formatDate(moment()) + "agilix.zip");
};
