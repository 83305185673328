import { populateUser } from "../../Utils/utils";

const initialState = {
  clients: [],
  clientsLoading: false,
  selectedUser: {},
  historyLogs: [],
};

const clientsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "CLIENTS_LOADING":
      return { ...state, clientsLoading: true };
    case "FETCH_CLIENTS":
      let clients =
        payload?.map((client) => {
          return {
            ...client,
            attributes: {
              ...client.attributes,
              ...populateUser(client, false),
            },
          };
        }) || [];
      return { ...state, clients: clients, clientsLoading: false };
    case "FETCH_NEW_CLIENT": {
      return {
        ...state,
        clients: [
          {
            ...payload,
            attributes: {
              ...payload.attributes,
              ...populateUser(payload),
            },
          },
          ...state.clients,
        ],
      };
    }
    case "FETCH_USER_BY_ID":
      return {
        ...state,
        selectedUser: {
          ...payload,
          attributes: {
            ...payload.attributes,
            ...populateUser(payload),
          },
        },
      };
    case "FETCH_HISTORY_LOGS":
      return { ...state, historyLogs: payload };
    case "CLEAR_HISTORY_LOGS":
      return { ...state, historyLogs: [] };
    default:
      return state;
  }
};
export default clientsReducer;
