import React from "react";
import LightDarkPlaceHolder from "../LightDarkPlaceHolder";

const SocietyFormPlaceHolder = () => {
  return (
    <div className="w-100 px-4 py-3 d-flex flex-column justify-content-between h100 gap-3">
      <div className="d-flex gap-2 w100">
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
      </div>
      <div className="alignC">
        <LightDarkPlaceHolder width={120} height={120} />
      </div>
      <div className="d-flex gap-2 w100">
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
      </div>
      <div className="d-flex gap-2 w100">
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
      </div>
      <div className="d-flex gap-2 w100">
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
      </div>
      <div className="d-flex gap-2 w100">
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
      </div>
      <div className="d-flex gap-2 w100">
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
      </div>
      <div className="d-flex gap-2 w100">
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
      </div>
      <div className="d-flex gap-2 w100">
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
        <LightDarkPlaceHolder customClass={"w-100"} height={48} />
      </div>
    </div>
  );
};

export default SocietyFormPlaceHolder;
