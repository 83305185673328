import { gql } from "@apollo/client";
import { ALERT_ATTRIBUTES, DIFFUSION_ATTRIBUTES } from "../Attributes/Alerts";

export const GET_ALERTS = gql`
  query($administrators:Boolean, $collaborators:Boolean, $operators:Boolean, $lectors:Boolean) {
    alerts(
      pagination: { page: 1, pageSize: 10000 }
      sort: ["createdAt:desc"]
      filters: {
        or : [
          {administrators: { eq: $administrators }},
          {collaborators: { eq: $collaborators }},
          {operators: { eq: $operators }},
          {lectors: { eq: $lectors }},
        ]
      }
    ) {
      data {
        id
        attributes {
          ${ALERT_ATTRIBUTES}
        }
      }
    }
  }
`;
export const GET_DIFFUSION = gql`
  query {
    alerts(
      pagination: { page: 1, pageSize: 4 }
      sort: ["createdAt:desc"]) 
      {
      data {
        id
        attributes {
          ${DIFFUSION_ATTRIBUTES}
        }
      }
    }
  }
`;
