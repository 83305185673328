import { CategoryData } from "../../../../Utils/utils";
import { MessageQuestionIcon, PenIcon } from "../../../Common/LightDarkSvg";

export const generateNewFormData = (categories) => {
  const NewFormData = [
    {
      id: 1,
      label: "Catégorie",
      type: "select",
      field: "category",
      col: 6,
      placeholder: "Catégorie",
      required: true,
      icon: <PenIcon />,
      data: CategoryData(categories, true),
    },
    {
      id: 2,
      label: "Type du champs",
      type: "select",
      col: 6,
      field: "type",
      placeholder: "Question",
      defaultValue: "Titre",
      required: true,
      icon: <MessageQuestionIcon />,
      data: [
        {
          id: 3,
          value: "title",
          label: "Titre",
          bodyFormData: [
            {
              id: 4,
              label: "Titre",
              type: "text",
              field: "name",
              required: true,

              col: 12,
            },
            {
              id: 5,
              label: "Legende",
              type: "text",
              field: "legend",
              col: 12,
            },
          ],
        },
        {
          id: 6,
          value: "subtitle",
          label: "Sous titre",
          bodyFormData: [
            {
              id: 7,
              label: "Sous Titre",
              type: "text",
              field: "name",
              required: true,

              col: 6,
            },
            {
              id: 8,
              label: "Affichage",
              type: "select",
              placeholder: "select",
              field: "label_class",
              col: 6,
              data: [
                { id: 9, value: "sub_standard", label: "Standard" },
                { id: 10, value: "sub_italique", label: "Italique" },
                { id: 11, value: "sub_gras", label: "Gras" },
              ],
            },
            {
              id: 12,
              label: "Legende",
              type: "text",
              field: "legend",
              col: 12,
            },
          ],
        },
        {
          id: 13,
          value: "description",
          label: "Description",
          bodyFormData: [
            {
              id: 14,
              label: "Description",
              type: "textarea",
              field: "name",
              required: true,

              col: 12,
            },
          ],
        },
        {
          id: 15,
          value: "image",
          label: "Image",
          bodyFormData: [
            {
              id: 16,
              label: "Nom de l'image",
              type: "text",
              field: "name",
              required: true,

              col: 12,
            },
            {
              id: 17,
              label: "File",
              type: "file",
              field: "file",
              col: 12,
            },
          ],
        },
        {
          id: 18,
          value: "question",
          label: "Question",
          bodyFormData: [
            {
              id: 19,
              label: "Nom ",
              type: "text",
              field: "name",
              required: true,

              col: 6,
            },
            {
              id: 20,
              label: "Affichage",
              type: "select",
              placeholder: "select",
              field: "label_class",
              col: 6,
              data: [
                { id: 21, value: "ques_label", label: "Label" },
                { id: 22, value: "ques_title", label: "Titre" },
                { id: 23, value: "ques_gras", label: "Gras" },
              ],
            },
            {
              id: 24,
              label: "Legende",
              type: "text",
              field: "legend",
              col: 6,
            },
            {
              id: 25,
              label: "Lien",
              type: "text",
              field: "link",
              col: 6,
            },
            {
              id: 26,
              label: "Type de réponse",
              type: "select",
              col: 12,
              placeholder: "select type",
              required: true,

              icon: <PenIcon />,
              field: "input_type",
              data: [
                { id: 27, value: "text", label: "Champs libre" },
                {
                  id: 28,
                  value: "number",
                  label: "Champs numerique sans restrictions",
                },
                {
                  id: 29,
                  value: "number_range",
                  label: "Champs numerique avec restrictions",
                },
                { id: 30, value: "textarea", label: "Champs large" },
                {
                  id: 31,
                  value: "select",
                  label: "Liste déroulante simple",
                },
                {
                  id: 32,
                  value: "multi_select",
                  label: "Liste déroulante multiple",
                },
                { id: 33, value: "radio", label: "Radio" },
                { id: 34, value: "boolean", label: "Oui ou Non" },
                { id: 35, value: "checkbox", label: "Checkbox" },
                {
                  id: 36,
                  value: "file",
                  label: "Importation de fichier",
                },
                { id: 37, value: "date", label: "Calendrier" },
                { id: 38, value: "departement", label: "Departement" },
              ],
            },
          ],
        },
      ],
    },

    {
      id: 41,
      label: "Position",
      type: "number",
      field: "position",
      required: true,

      col: 4,
    },

    {
      id: 40,
      label: "Colonne",
      type: "colonne",
      field: "col",
      required: true,

      col: 8,
    },
  ];
  return NewFormData;
};
