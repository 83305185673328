import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import LightDarkText from "../Common/LightDarkText";
import LightDarkContainer from "../Common/LightDarkContainer";
import moment from "moment";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
);

const MultipleLineChart = ({
  dataSets,
  title,
  showLabel = true,
  months = 12,
  customToolTip = {},
  showY,
}) => {
  const [labels, setLabels] = useState();
  const { mode } = useSelector((state) => state.genearlReducer);

  const options = {
    responsive: true,
    plugins: {
      tooltip: customToolTip,
      title: {
        display: true,
        text: title,
        align: "start",
        color: mode === "light" ? "#59638b" : "white",
        font: {
          weight: "bold",
          size: 15,
        },
      },
      legend: {
        display: showLabel,
        position: "top",
        align: "end",
        labels: {
          color: mode === "light" ? "#59638b" : "white",
          usePointStyle: true,
          boxHeight: 8,
          font: {
            size: 14,
          },
        },
      },
    },
    gridLines: {
      display: false,
      drawOnChartArea: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#86909c80",
          font: {
            weight: "regular",
            size: 10,
          },
        },
      },
      y: {
        ticks: {
          display: showY,
          color: "#86909c80",
          font: {
            weight: "regular",
            size: 10,
          },
        },
        grid: {
          display: true,
          color: "#86909c20",
          borderColor: "transparent",
        },
      },
    },
  };

  useEffect(() => {
    const last12Months = [];
    for (let i = 0; i < months; i++) {
      const month = moment().subtract(i, "months").format("MMM-YY");
      last12Months.push(month);
    }
    setLabels(last12Months.reverse());
  }, [dataSets]);

  return (
    <LightDarkContainer
      className="px-4 py-3 radius05 h100 radius10"
      colorLight="white"
      colorDark="dark-grey-bg"
    >
      <Line
        options={options}
        data={{
          labels: labels,
          datasets: dataSets,
        }}
        className="py-2"
        style={{ maxHeight: "300px" }}
      />
    </LightDarkContainer>
  );
};

export default MultipleLineChart;
