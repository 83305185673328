import { gql } from "@apollo/client";
import {
  FORM_ANSWER_ATTRIBUTES,
  FORM_QUESTION_ATTRIBUTES,
} from "../Attributes/formQuestion";

export const CREATE_QUESTION = gql`
  mutation ($name: String, $position: Int,$input_type:String,$is_required:Boolean,$col:Int,$legend:String,$label_class:String,$category:ID,$image:ID,$link:String) {
    createFormQuestion(data: { name: $name,link:$link, position: $position,input_type:$input_type,is_required:$is_required,col:$col,legend:$legend,label_class:$label_class,category:$category,image:$image }) {
      data {
        id
        attributes {
          ${FORM_QUESTION_ATTRIBUTES}
        }
      }
    }
  }
`;

export const CREATE_ANSWER = gql`
  mutation ($question:ID!,$answer:String) {
    createFormAnswer(data: { question:$question,answer:$answer }) {
      data {
        id
        attributes {
          ${FORM_ANSWER_ATTRIBUTES}
        }
      }
    }
  }
`;

export const UPDATE_ANSWER = gql`
  mutation ($id:ID!,$answer:String) {
    updateFormAnswer(id:$id,data: { answer:$answer }) {
      data {
        id
        attributes {
          ${FORM_ANSWER_ATTRIBUTES}
        }
      }
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation ($id: ID!) {
    deleteFormQuestion(id: $id) {
      data {
        id
      }
    }
  }
`;

export const DELETE_ANSWER = gql`
  mutation ($id: ID!) {
    deleteFormAnswer(id: $id) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation($id:ID!,$name: String, $position: Int,$input_type:String,$is_required:Boolean,$col:Int,$legend:String,$label_class:String,$category:ID,$link:String,$image:ID) {
    updateFormQuestion(
        id: $id,
        data: { 
          name: $name, position: $position,input_type:$input_type,is_required:$is_required,col:$col,legend:$legend,label_class:$label_class,category:$category,link:$link,image:$image
        }
    ) {
      data {
        id
        attributes{
          ${FORM_QUESTION_ATTRIBUTES}
        }
      }
    }
  }
`;
