import React, { useState } from "react";
import LightDarkContainer from "../../Common/LightDarkContainer";
import LightDarkText from "../../Common/LightDarkText";
import SocietyAvatar from "./SocietyAvatar";
import TooltipActions from "../../Common/Tooltip/TooltipActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserGroupThumbnail from "../../Common/UserGroupThumbnail";
import SocietyBundleBar from "../SocietyBundleBar";
import { handleDuration } from "../../Tickets/ticketUtils";
import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  LinkSocietySVG,
} from "../../Common/LightDarkSvg";
import LinkedSociety from "./LinkedSociety";
import OutsideClickHandler from "react-outside-click-handler";
import { openSideBar } from "../../../REDUX/actions/sideBar";
import SocietyForm from "../SocietyForm/SocietyForm";
import { UpdateSociete } from "../../../REDUX/actions/societe";
import { openConfirmationModal } from "../../../REDUX/actions/confirmationModal";
import { closeModal } from "../../../REDUX/actions/modal";
import CustomListTooltip from "../../Common/CustomListTooltip/CustomListTooltip";
import CopyIcon from "../../Common/MenuIcons/CopyIcon";
import { handleInfo } from "../../../REDUX/actions/toastMessage";

const SocietyCardNew = ({ society, isLinked, setIsToggle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showTooltip, setshowTooltip] = useState(false);
  const { canCreateSociety, isClientAdmin } = useSelector(
    (state) => state.roleReducer
  );
  const { societeRoute } = useSelector((state) => state.organisationsReducer);
  const [showLinkedSocieties, setShowLinkedSocieties] = useState(false);

  const tooltipData = [
    {
      id: 1,
      text: "Voir les détails",
      icon: <EyeIcon className="me-2" />,
      action: (e) => {
        setIsToggle("overview");
        e.stopPropagation();
        navigate(`${societeRoute}/${society.id}`);
        setshowTooltip(false);
      },
      condition: true,
    },
    {
      id: 2,
      text: "Éditer",
      icon: <EditIcon className="me-2" />,
      action: (e) => {
        e.stopPropagation();
        dispatch(
          openSideBar({
            content: <SocietyForm societyId={society.id} isLinked={isLinked} />,
            title: `Éditer la société ${society.attributes.name}`,
            type: "w40",
            isToggle: "isToggle value",
          })
        );
        setshowTooltip(false);
      },
      condition: canCreateSociety || isClientAdmin,
    },
    {
      id: 3,
      text: society.attributes.archived ? "Désarchiver" : "Archiver",
      icon: <DeleteIcon className="me-2" />,
      action: (e) => {
        e.stopPropagation();
        dispatch(
          openConfirmationModal({
            callBack: () => {
              dispatch(
                UpdateSociete({
                  id: society.id,
                  archived: society.attributes.archived ? false : true,
                })
              );
              dispatch(closeModal());
            },
            message: `Voulez vous vraiment ${
              society.attributes.archived ? "désarchiver" : "archiver"
            } la société?`,
            message2: `#${society.id} ${society.attributes.name}`,
            confirmButtonText: society.attributes.archived
              ? "Désarchiver la société"
              : "Archiver la société",
          })
        );
        setshowTooltip(false);
      },
      condition: canCreateSociety || isClientAdmin,
    },
    ...(society.attributes.parentCompany?.data
      ? [
          {
            id: 4,
            text: society.attributes.parentCompany.data.attributes.name,
            icon: (
              <SocietyAvatar
                imageUrl={
                  society.attributes.parentCompany.data.attributes.avatar?.data
                    ?.attributes?.image?.data?.attributes?.url
                }
                width="24px"
                height="24px"
                className="me-2"
              />
            ),
            action: (e) => {
              e.stopPropagation();
            },
            condition: true,
            isParent: true,
          },
        ]
      : []),
    {
      id: 5,
      text: "Copier le lien",
      icon: <CopyIcon width="18" height="18" className="me-2" />,
      action: (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(
          `${window.location.origin}${societeRoute}/${society.id}`
        );
        setshowTooltip(false);
        dispatch(handleInfo("Lien copié", 1, "green"));
      },
      condition: true,
    },
  ];
  return (
    <LightDarkContainer
      colorLight="white"
      className="societyCardContainer my-2 d-flex flex-column justify-content-between pointer"
      callBack={() => {
        setIsToggle("overview");
        navigate(`${societeRoute}/${society.id}`);
      }}
    >
      <LightDarkText className={"d-flex justify-content-between p-4"}>
        <div className="w20  d-flex flex-column gap-1 position-relative">
          <div className="text-light-message f14 customIdContainer radius05 alignC">
            #{society.id}
          </div>
          {society.attributes.childCompanies?.data.length > 0 ? (
            <CustomListTooltip
              tooltipContent={society.attributes.childCompanies.data.map(
                (el) => ({
                  text: el.attributes.name,
                  icon: (
                    <SocietyAvatar
                      imageUrl={
                        el.attributes.avatar?.data?.attributes?.image?.data
                          ?.attributes?.url
                      }
                      width="32px"
                      height="32px"
                    />
                  ),
                })
              )}
            >
              <div
                className="text-light-message f10 childSocietiesAttachedContainer radius05 alignC gap-1 xbold pointer"
                onClick={() => setShowLinkedSocieties(true)}
              >
                <LinkSocietySVG />
                <div>{society.attributes.childCompanies.data.length}</div>
              </div>
            </CustomListTooltip>
          ) : null}
          {showLinkedSocieties && (
            <OutsideClickHandler
              onOutsideClick={() => setShowLinkedSocieties(false)}
            >
              <LinkedSociety society={society} />
            </OutsideClickHandler>
          )}
        </div>
        <div className="flex-1 alignC">
          <SocietyAvatar
            imageUrl={
              society.attributes.avatar?.data?.attributes?.image?.data
                ?.attributes?.url
            }
            width="80px"
            height="80px"
          />
        </div>
        <div className="w20 d-flex  justify-content-end">
          <TooltipActions
            setshowTooltip={setshowTooltip}
            showTooltip={showTooltip}
            tooltipData={tooltipData}
          />
        </div>
      </LightDarkText>
      <LightDarkText className={"alignC flex-column p-4 societyCardMiddle"}>
        <div className="f14 bold mb-4">{society.attributes.name}</div>
        <UserGroupThumbnail
          participants={society.attributes.workInOrg.data.map(
            (el) => el.attributes.user.data
          )}
          modalTitle={"Liste des collaborateurs"}
          participantsRole={"collaborateurs"}
          showNumber={10}
        />
      </LightDarkText>
      <LightDarkContainer
        colorLight="light-gray"
        className={
          "bg-red bottomSocietyCard alignC f12 text-light-text bold px-3"
        }
      >
        {society.attributes.monthlyPlan > 0 &&
        society.attributes.monthlyQuota > 0 ? (
          <div className="w-100">
            <div className="alignH justify-content-between">
              <div>Forfait Mensuel</div>
              <div>
                <span className="text-light-blue ">
                  {handleDuration(
                    society.attributes.plans.data[0].attributes.consumption_0 +
                      society.attributes.monthlyQuota
                  )}
                </span>
                <span>/{handleDuration(society.attributes.monthlyPlan)}</span>
              </div>
            </div>
            <SocietyBundleBar
              consumption_0={
                society.attributes.plans.data[0].attributes.consumption_0
              }
              monthlyPlan={society.attributes.monthlyPlan}
              monthlyQuota={society.attributes.monthlyQuota}
            />
          </div>
        ) : (
          "Aucun forfait"
        )}
      </LightDarkContainer>
    </LightDarkContainer>
  );
};

export default SocietyCardNew;
